import React, { ReactElement, SyntheticEvent, useContext, useState } from 'react';
import { IconButton, Tooltip, useTheme } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { ViewRoute } from 'routes';
import { AppContext, AppContextT } from 'context';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { ActionsMenu, Item } from 'components/ActionsMenu/ActionsMenu';

export type ConfigurationsMenuProps = {
    viewPermissions?: string[];
    routes: ViewRoute[];
};

export const ConfigurationsMenu = (props: ConfigurationsMenuProps): ReactElement => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();

    const history = useHistory();

    const appContext = useContext<AppContextT>(AppContext);
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const hostname = window && window.location && window.location.hostname;
    let brand1 = theme.palette.custom.fxFlowBrand1;
    let brand2 = theme.palette.custom.fxFlowBrand2;
    switch (true) {
        case hostname.includes('stellcap'):
            brand1 = theme.palette.custom.stellcapBrand1;
            brand2 = theme.palette.custom.stellcapBrand2;
            break;
        case hostname.includes('citadel'):
            brand1 = theme.palette.custom.citadelBrand1;
            brand2 = theme.palette.custom.citadelBrand2;
            break;
        case hostname.includes('arcimpex'):
            brand1 = theme.palette.custom.acrImpexBrand1;
            brand2 = theme.palette.custom.acrImpexBrand2;
            break;
        default:
            brand1 = theme.palette.custom.fxFlowBrand1;
            brand2 = theme.palette.custom.fxFlowBrand2;
    }

    const mainColor =
        appContext.currentContext?.partyType == 'CLIENT'
            ? brand2
            : appContext.currentContext?.partyType == 'PROCESSING_ORG'
            ? brand1
            : theme.palette.custom.fxFlowBrand1;

    let configurationAppRoutes: ViewRoute | undefined = undefined;

    for (const route of props.routes) {
        if (route.name === 'Configuration') {
            configurationAppRoutes = route;
            break;
        }
    }

    //Filter based on permissions
    const filteredConfigView = props.viewPermissions?.reduce((newConfig: ViewRoute[], viewPermission: string) => {
        for (const configView of configurationAppRoutes?.views || []) {
            if (configView.permission === viewPermission) {
                newConfig.push(configView);
                break;
            }
        }
        return newConfig;
    }, []);

    const menuItems: Item[] = [] as Item[];
    let key = 0;
    for (const configView of filteredConfigView || []) {
        key++;
        menuItems.push({
            id: key.toString(),
            text: configView.name,
            onClick: (event: SyntheticEvent<HTMLElement, Event> | undefined) => {
                event?.preventDefault();
                history.push(configView.path || '/');
            },
        });
    }
    return (
        <React.Fragment>
            <Tooltip placement={'top'} title={'Configurations'}>
                <IconButton
                    aria-label="Open drawer"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(event.currentTarget ? event.currentTarget : undefined);
                    }}
                    disabled={menuItems.length === 0}
                >
                    <Settings
                        className={css({
                            color: mainColor.contrastText,
                            opacity: 0.6,
                        })}
                    />
                </IconButton>
            </Tooltip>
            <ActionsMenu
                id={'ConfigurationsMenu/configuration'}
                title={'Configuration'}
                anchorElement={anchorEl}
                onClose={() => setAnchorEl(undefined)}
                items={menuItems}
            />
        </React.Fragment>
    );
};
