import React, { ReactElement, useEffect, useState } from 'react';
import { displayAmount, processUnixDateForViewing } from 'utils/';
import { Criteria, CriteriaType, Query, QueryOrderT } from 'popcorn-js/search';
import { Invoice } from 'popcorn-js/invoice';
import { Recordkeeper as CounterpartyRecordkeeper } from 'popcorn-js/counterparty/recordkeeper';
import { Recordkeeper as InvoiceRecordkeeper } from 'popcorn-js/invoice/recordkeeper';
import Table from 'components/Table/Table';
import { Direction, FindRequest, FindResponse } from 'popcorn-js';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { useServiceSync } from 'hooks/useService';
import { Counterparty } from 'popcorn-js/counterparty';
import { Big } from 'big.js';

const initialPageSize = 5;
const invoiceDirections = [
    {
        value: Direction.PAYABLE,
        label: Direction.PAYABLE,
    },
    {
        value: Direction.RECEIVABLE,
        label: Direction.RECEIVABLE,
    },
];
const safeRender = (accessor: string, formatter = (value: unknown) => value) => (rowData: Invoice) => {
    try {
        return formatter(rowData[accessor as keyof Invoice]);
    } catch (e) {
        return '-';
    }
};

const initialQuery = {
    sortBy: [],
    order: [],
    limit: initialPageSize,
    offset: 0,
};

export const ExposureSelection = (props: {
    defaultFilter: Criteria;
    onInvoiceDeselect: (invoice: Invoice) => void;
    onInvoiceSelect: (invoice: Invoice) => void;
    selectedInvoices: Invoice[];
    onViewInvoiceDetail: (invoice: Invoice) => void;
}): ReactElement => {
    const { defaultFilter, onInvoiceDeselect, onInvoiceSelect, selectedInvoices, onViewInvoiceDetail } = props;
    const [criteria, setCriteria] = useState<Criteria>(defaultFilter);
    const [query, setQuery] = useState<Query>(initialQuery);
    const [loading, setLoading] = useState<boolean>(false);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [totalInvoices, setTotalInvoices] = useState<number>(0);
    const [, setErrorMessage] = useState<string | undefined>(undefined);

    const [invoiceFind] = useServiceSync<FindRequest, FindResponse<Invoice>>(InvoiceRecordkeeper.find);
    const [counterpartyFind] = useServiceSync<FindRequest, FindResponse<Counterparty>>(CounterpartyRecordkeeper.find);

    useEffect(() => {
        findInvoices().finally();
    }, []);

    const findInvoiceCounterparties = async (_invoices: Invoice[]): Promise<Counterparty[]> => {
        setLoading(true);
        const counterpartyFindCrit: Criteria = _invoices
            .filter((b) => b.counterpartyId !== '')
            .map((b) => ({ type: CriteriaType.ExactCriterion, field: 'id', text: b.counterpartyId }));

        const counterpartyFindResponse =
            counterpartyFindCrit.length > 0
                ? await counterpartyFind({ criteria: counterpartyFindCrit })
                : { records: [] };

        setLoading(false);
        return counterpartyFindResponse.records;
    };
    const findInvoices = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await invoiceFind({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            const _counterparties = await findInvoiceCounterparties(result.records);
            setInvoices(
                result.records.map((i: Invoice) => ({
                    ...i,
                    counterparty: _counterparties.find((c: Counterparty) => c.id === i.counterpartyId)?.name,
                })),
            );
            setTotalInvoices(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };
    const handleSelection = (invoice: Invoice) => {
        if (invoice === undefined || invoice.id === undefined) {
            return;
        }
        if (selectedInvoices.find((i: Invoice) => i.id === invoice.id)) {
            onInvoiceDeselect(invoice);
        } else {
            onInvoiceSelect(invoice);
        }
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['dueDate'],
            order: ['asc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findInvoices(criteria, newQuery);
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findInvoices(criteria, newQuery);
    };
    const handleChangeSorting = (sortBy: string, order: QueryOrderT) => {
        const newQuery = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        findInvoices(criteria, newQuery);
    };
    const handleFilterChange = (newCrit: Criteria) => {
        const newQuery = {
            ...query,
            offset: 0,
        };
        setQuery(newQuery);
        setCriteria(newCrit);
        findInvoices(newCrit, newQuery);
    };

    const generateCounterpartyOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await counterpartyFind({
                criteria,
                query: { sortBy: ['dueDate'], order: ['asc'], limit: 20, offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    return (
        <StandardCard
            cardHeaderProps={{
                itemsLeft: [
                    {
                        id: 'SettlementInstruction/ExposureSelection/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Invoices',
                    },
                ],
            }}
        >
            <Table
                columns={[
                    {
                        title: 'Counterparty',
                        disableSort: true,
                        filter: {
                            asyncOptionsFetcher: generateCounterpartyOptions,
                            displayAccessor: 'name',
                            valueAccessor: 'id',
                            type: CriteriaType.TextCriterion,
                            disablePadding: true,
                            width: 120,
                        },
                        width: '120',
                        render: (rowData: Invoice) => rowData.counterparty,
                        field: 'counterpartyId',
                    },
                    {
                        title: 'Number',
                        field: 'number',
                        filter: { type: CriteriaType.TextCriterion, width: 120, disablePadding: true },
                        width: '120',
                    },
                    {
                        title: 'Order Number',
                        field: 'orders',
                        filter: { type: CriteriaType.TextCriterion, width: 120, disablePadding: true },
                        width: '120',
                        render: (rowData: Invoice) => rowData.orders?.join(', '),
                    },
                    {
                        title: 'Direction',
                        field: 'direction',
                        filter: {
                            type: CriteriaType.TextCriterion,
                            options: invoiceDirections,
                            displayAccessor: 'value',
                            valueAccessor: 'value',
                            width: 120,
                            disablePadding: true,
                        },
                        width: '110',
                    },
                    {
                        title: 'Due Date',
                        field: 'dueDate',
                        render: safeRender('dueDate', processUnixDateForViewing),
                        filter: { type: CriteriaType.DateCriterion, width: 130, disablePadding: true },
                        width: '130',
                    },
                    {
                        title: 'Unpaid Amount',
                        disableSort: true,
                        render: (inv: Invoice) => displayAmount((inv?.originalAmountDue || 0) - (inv?.paidAmount || 0)),
                        width: '110',
                    },
                ]}
                count={totalInvoices || 0}
                data={invoices}
                defaultColConfig={[
                    { header: 'Counterparty', visible: true },
                    { header: 'Number', visible: true },
                    { header: 'Order Number', visible: true },
                    { header: 'Direction', visible: true },
                    { header: 'Unpaid Amount', visible: true },
                    { header: 'Due Date', visible: true },
                ]}
                showCheckboxes
                showFilterRow={true}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                loading={loading}
                onChangeSorting={handleChangeSorting}
                onFilterChange={handleFilterChange}
                order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                rowDoubleClickAction={(rowData: Invoice) => {
                    onViewInvoiceDetail(rowData);
                }}
                rowsPerPage={query.limit}
                rowsPerPageOptions={[5]}
                sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                tableID={'exposureSelectionTable'}
                title={'Invoices'}
                onRowCheck={handleSelection}
                rowClickAction={handleSelection}
                selected={selectedInvoices}
            />
        </StandardCard>
    );
};
