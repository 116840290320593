import { AuditEntry } from 'popcorn-js/audit';

export enum PartyType {
    SYSTEM = 'SYSTEM',
    CLIENT = 'CLIENT',
    PROCESSING_ORG = 'PROCESSING_ORG',
    PROCESSING_BANK = 'PROCESSING_BANK',
}

export type Client = {
    id: string;
    partyCode: string;
    parentPartyCode: string;
    enable?: boolean;
    name: string;
    currencyPairs: string[];
    defaultCurrency?: string;
    localCurrency?: string;
    financialYearEnd?: string;
    auditEntry: AuditEntry;
    hedgingPolicy?: string;
    import?: boolean;
    export?: boolean;
    portfolioManager?: string;
    relationshipManager?: string;
    subsidiaries?: string[];
    customTranslationProcesses?: CustomTranslationProcess[];
    type?: ClientType;
    description?: string;
    idPassportNumber?: string;
    products?: string[];
    tradeCategory?: TradeCategory;
    monthlyFee?: number;
    transactionFees?: TransactionFee[];
    status?: ClientStatus;
};

export type TransactionFee = {
    bank: string;
    intermediaryMargins?: Fields;
    adminFees?: Fields;
    billingType: BillingType;
};

export type Fields = {
    newDeal?: number;
    cancellation?: number;
    swap?: number;
    option?: number;
};

export type CustomTranslationProcess = {
    name: string;
    reference: string;
    inputLocation: string;
    outputLocation: string;
};

export type ProcessingOrg = {
    id: string;
    partyCode: string;
    parentPartyCode: string;
    enable?: boolean;
    name: string;
    currencyPairs: string[];
    auditEntry: AuditEntry;
    traders?: string[];
    sarbAuthNumber?: string;
    financialYearEnd?: string;
};

export type System = {
    id: string;
    partyCode: string;
    name: string;
    currencyPairs: string[];
    auditEntry: AuditEntry;
};

export type ProcessingBank = {
    id: string;
    name: string;
    parentPartyCode: string;
    partyCode: string;
    auditEntry: AuditEntry;
    currencyPairs: string[];
    contacts?: Contact[];
    billingAddress?: BillingAddress;
};

export type Contact = {
    role: string;
    name: string;
    email: string;
    mobile: string;
};

export type BillingAddress = {
    invoiceBank: boolean;
    address1: string;
    address2: string;
    suburb: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
    description: string;
    vatNumber: string;
};

export const IsClient = (obj: unknown): obj is Client => {
    return true;
};

export type PartyT = Client | ProcessingOrg | ProcessingBank;

export type Party = System | ProcessingOrg | Client | ProcessingBank;

export enum ClientType {
    IndividualType = 'INDIVIDUAL',
    CompanyType = 'COMPANY',
    CISType = 'CIS',
    GroupType = 'GROUP',
}

export enum ClientStatus {
    ClientActive = 'ACTIVE',
    ClientInActive = 'IN-ACTIVE',
    ClientClosed = 'CLOSED',
}

export enum BillingType {
    BankBilling = 'BANK',
    ClientBilling = 'CLIENT',
}

export enum TradeCategory {
    NewDeal = 'NEW DEAL',
    Cancellation = 'CANCELLATION',
    Swap = 'SWAP',
    Option = 'OPTION',
}
