import React, { ReactElement, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { COLOR, HeaderField } from 'components/HeaderField/HeaderField';
import { ScaleLoader as Spinner } from 'react-spinners';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import { AppContext, AppContextT } from 'context';
import { displayAmount } from './util';
import { GeneratePositionReportForCurrencyResponse } from 'popcorn-js/report';
import { Currency } from 'popcorn-js/currency';

export const CurrencyPositionTotals = (props: {
    currency: Currency;
    response: GeneratePositionReportForCurrencyResponse | undefined;
    loading: boolean;
    error: string | undefined;
}): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const appContext = useContext<AppContextT>(AppContext);
    const localCurrency = appContext.localCurrency?.isoCode;
    const { loading, response, error, currency } = props;

    if (loading) {
        return (
            <div className={classes.currencyPositionTotalsCardWrapper}>
                <div className={classes.loadingLayout}>
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.currencyPositionTotalsCardWrapper}>
                <div className={classes.loadingLayout}>
                    <ErrorIcon className={classes.errorIcon} />
                    <div className={classes.errorText}>Failed to load report</div>
                </div>
            </div>
        );
    }
    return (
        <div className={classes.currencyPositionTotalsCardWrapper}>
            <div className={classes.currencyPositionTotalsCardLayout}>
                <HeaderField
                    heading={'Total Net Exposure'}
                    value={displayAmount(currency.isoCode, response?.currencyPosition || 0, 'code')}
                    color={COLOR.YELLOW}
                    helperText={'Total Net Exposure across Import and Exports (incl CFC)'}
                />
                <HeaderField
                    heading={'Total Realised P/L'}
                    value={displayAmount(localCurrency, response?.totalRealisedPnL.withCostingRate || 0, 'code')}
                    color={COLOR.GREY}
                    helperText={'Realised P/L on Settled Import and Export invoices, excl. ACM'}
                />
                <HeaderField
                    heading={'Total Unrealised P/L'}
                    value={displayAmount(localCurrency, response?.totalUnrealisedPnL.withCostingRate || 0, 'code')}
                    color={COLOR.GREY}
                    helperText={'Unrealised P&L on Outstanding Import & Export orders / invoices and FEC Open Balances'}
                />
                <HeaderField
                    heading={'Total P/L'}
                    value={displayAmount(localCurrency, response?.totalPnL.withCostingRate || 0, 'code')}
                    color={COLOR.YELLOW}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    currencyPositionTotalsCardWrapper: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        width: '182px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        marginRight: '3px',
        overflow: 'hidden',
    },
    currencyPositionTotalsCardLayout: {
        flexGrow: 1,
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    loadingLayout: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));
