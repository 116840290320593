import { makeStyles } from '@material-ui/styles';
import React, { ReactElement, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { CFCAccountSummaries } from 'views/CFCDepositManagementStation/CFCAccountSummaries';
import CFCTable from './CFCDeposits';

const CFCDepositManagementStation = (): ReactElement => {
    const classes = useStyles();
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
    useEffect(() => {
        setReloadTrigger(false);
    }, [reloadTrigger]);
    return (
        <div className={classes.root}>
            {!reloadTrigger && (
                <div id="cfcStationRoot" className={classes.container}>
                    <Grid container direction={'row'} spacing={1}>
                        <Grid item className={classes.containerGrid}>
                            <CFCAccountSummaries setReloadTriggerMain={() => setReloadTrigger(true)} />
                        </Grid>
                        <Grid item className={classes.containerGrid}>
                            <CFCTable
                                setReloadTriggerMain={() => setReloadTrigger(true)}
                                reloadTriggerState={reloadTrigger}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};
const useStyles = makeStyles(() => ({
    root: {
        height: 'calc(100vh - 100px)',
        overflowY: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    bottomColumnLayout: {
        display: 'grid',
        gridTemplateColumns: '294px 580px 390px',
    },
    container: {
        margin: '0 auto',
    },
    containerGrid: {
        width: '1160px',
        margin: '0 auto',
    },
}));
export default CFCDepositManagementStation;
