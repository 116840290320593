/* eslint-disable @typescript-eslint/no-explicit-any */
import { Category, File } from 'components/upload';
import { Invoice, InvoiceType } from 'popcorn-js/invoice';
import XLSX from 'xlsx';
import moment, { now } from 'moment';
import { SystemDateFormat } from 'constants/formats';

export const fields = [
    {
        name: 'externalReference',
        type: 'string',
    },
    {
        name: 'number',
        type: 'string',
    },
    {
        name: 'counterparty',
        type: 'string',
    },
    {
        name: 'originalAmountDue',
        type: 'float',
    },

    {
        name: 'currency',
        type: 'string',
        objField: 'currencyIsoCode',
    },
    {
        name: 'costCurrency',
        type: 'string',
        optional: true,
        objField: 'costCurrencyIsoCode',
        displayHeader: 'Cost Currency',
    },
    {
        name: 'costingRate',
        type: 'float',
    },
    {
        name: 'captureRate',
        type: 'float',
        optional: true,
    },
    {
        name: 'dueDate',
        type: 'date',
        optional: true,
    },
    {
        name: 'shippingDate',
        optional: true,
        type: 'date',
    },
    {
        name: 'shipmentReference',
        type: 'string',
        optional: true,
    },
    {
        name: 'notes',
        type: 'string',
        optional: true,
        objField: 'notes',
    },
    {
        name: 'status',
        type: 'string',
        optional: true,
        objField: 'status',
    },
    {
        name: 'partyCode',
        type: 'string',
        optional: true,
    },
    {
        name: 'issueDate',
        type: 'date',
        optional: true,
    },
    {
        name: 'notes',
        optional: true,
        type: 'string',
    },
    {
        name: 'type',
        type: 'string',
    },
    {
        name: 'financialYear',
        type: 'string',
    },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const newFromFields = (uploadObj: any, props: any): any => {
    const invoice: Invoice = {} as Invoice;
    invoice.id = uploadObj.id;
    invoice.number = uploadObj.number;
    invoice.externalReference = uploadObj.externalReference;
    invoice.counterparty = uploadObj.counterparty;
    invoice.originalAmountDue = uploadObj.originalAmountDue;
    invoice.currency = uploadObj.currency;
    invoice.costingRate = uploadObj.costingRate;
    invoice.dueDate = uploadObj.dueDate;
    invoice.notes = uploadObj.notes;
    invoice.partyCode = uploadObj.partyCode;
    invoice.issueDate = uploadObj.issueDate;
    invoice.direction = uploadObj.direction;
    invoice.type = uploadObj.type;
    invoice.financialYear = uploadObj.financialYear;
    invoice.costCurrency = props.localCurrency;

    return invoice;
};

export const downloadTemplate = (): void => {
    /* create dummy data */
    const data = [
        {
            /* A */ ExternalReference: 'ABC123',
            /* B */ Number: 'ABC123',
            /* C */ Counterparty: 'CreditorABC',
            /* D */ OriginalAmountDue: 100000.0,
            /* E */ Currency: 'USD',
            /* F */ CostingRate: 15.15,
            /* G */ DueDate: new Date(),
            /* H */ IssueDate: new Date(),
            /* I */ Notes: '',
        },
        {
            /* A */ ExternalReference: 'XYZ321',
            /* B */ Number: 'ABC124',
            /* C */ Counterparty: 'CreditorXYZ',
            /* D */ OriginalAmountDue: 200000.0,
            /* E */ Currency: 'EUR',
            /* F */ CostingRate: 16.15,
            /* G */ DueDate: new Date(),
            /* H */ IssueDate: new Date(),
            /* I */ Notes: '',
        },
    ];

    /* create a new workbook */
    const workbook = XLSX.utils.book_new();

    /* create the worksheet */
    const worksheet = XLSX.utils.json_to_sheet(data);

    /* set some formatting */
    worksheet['A1']['v'] = 'External Reference';
    worksheet['B1']['v'] = 'Number';
    worksheet['C1']['v'] = 'Counterparty';
    worksheet['D1']['v'] = 'Original Amount Due';
    worksheet['E1']['v'] = 'Currency';
    worksheet['F1']['v'] = 'Costing Rate';
    worksheet['G1']['v'] = 'Due Date';
    worksheet['H1']['v'] = 'Issue Date';
    worksheet['I1']['v'] = 'Notes';

    worksheet['D2']['z'] = '#,##0.00';
    worksheet['D3']['z'] = '#,##0.00';

    /* add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoices');

    /* download the workbook */
    XLSX.writeFile(workbook, 'invoice-template.xlsx');
};

const setTypes = (rowObjects: any[], category: Category): any[] => {
    const newRowObjects = [];
    for (const rowObj of rowObjects) {
        const value = Number(rowObj['Original Amount Due'].replace(',', ''));
        if (!rowObj['Issue Date']) {
            rowObj['Issue Date'] = moment(now()).format(SystemDateFormat);
        }
        if (!rowObj['Due Date']) {
            rowObj['Due Date'] = moment(rowObj['Issue Date']).add(60, 'days').format(SystemDateFormat);
        }
        switch (category) {
            case Category.PurchaseNotes:
                if (value > 0) {
                    newRowObjects.push({
                        ...rowObj,
                        Type: InvoiceType.PurchaseDebitNoteType,
                        'Financial Year': 'CURRENT',
                    });
                } else if (value < 0) {
                    newRowObjects.push({
                        ...rowObj,
                        'Original Amount Due': rowObj['Original Amount Due'].replace('-', ''),
                        'Financial Year': 'CURRENT',
                        Type: InvoiceType.PurchaseCreditNoteType,
                    });
                } else {
                    throw new Error(
                        '"Original Amount Due" is 0 for "' +
                            rowObj['Number'] +
                            '". Please remove the entry, or correct it.',
                    );
                }
                break;
            case Category.SalesNotes:
                if (value > 0) {
                    newRowObjects.push({
                        ...rowObj,
                        'Financial Year': 'CURRENT',
                        Type: InvoiceType.SalesDebitNoteType,
                    });
                } else if (value < 0) {
                    newRowObjects.push({
                        ...rowObj,
                        'Original Amount Due': rowObj['Original Amount Due'].replace('-', ''),
                        Type: InvoiceType.SalesCreditNoteType,
                        'Financial Year': 'CURRENT',
                    });
                } else {
                    throw new Error(
                        '"Original Amount Due" is 0 for "' +
                            rowObj['Number'] +
                            '". Please remove the entry, or correct it.',
                    );
                }
                break;
            case Category.Purchase:
                if (value > 0) {
                    newRowObjects.push({
                        ...rowObj,
                        Type: InvoiceType.PurchaseInvoiceType,
                        'Financial Year': 'CURRENT',
                    });
                } else if (value < 0) {
                    newRowObjects.push({
                        ...rowObj,
                        'Original Amount Due': rowObj['Original Amount Due'].replace('-', ''),
                        'Financial Year': 'CURRENT',
                        Type: InvoiceType.PurchaseCreditNoteType,
                    });
                } else {
                    throw new Error(
                        '"Original Amount Due" is 0 for "' +
                            rowObj['Number'] +
                            '". Please remove the entry, or correct it.',
                    );
                }
                break;
            case Category.Sales:
                if (value > 0) {
                    newRowObjects.push({
                        ...rowObj,
                        'Financial Year': 'CURRENT',
                        Type: InvoiceType.SalesInvoiceType,
                    });
                } else if (value < 0) {
                    newRowObjects.push({
                        ...rowObj,
                        'Original Amount Due': rowObj['Original Amount Due'].replace('-', ''),
                        Type: InvoiceType.SalesCreditNoteType,
                        'Financial Year': 'CURRENT',
                    });
                } else {
                    throw new Error(
                        '"Original Amount Due" is 0 for "' +
                            rowObj['Number'] +
                            '". Please remove the entry, or correct it.',
                    );
                }
                break;
            default:
                throw new Error('unsupported category of invoice');
        }
    }
    return newRowObjects;
};

/**
 * parseFile THIS IS WHERE IT ALL STARTS: The Excel file is parsed here.
 */
export const parseFile = (file: File, category: Category, onSuccess: (rowObjects: any[]) => void): void => {
    let rowObjects = [];
    switch (file.ext) {
        case 'xlsx':
        case 'xls':
            let workbook = undefined;
            let worksheet = undefined;
            // Try parse data to workbook
            try {
                workbook = XLSX.read(file.data, { type: 'binary' });
            } catch (e) {
                throw new Error(`unable to read file data to workbook\n${e}`);
            }
            // Try get worksheet
            try {
                worksheet = workbook.Sheets[workbook.SheetNames[0]];
            } catch (e) {
                throw new Error(`unable to get first worksheet of given workbook\n${e}`);
            }
            // Try convert contents of worksheet to object array
            try {
                rowObjects = XLSX.utils.sheet_to_json(worksheet, { raw: false });
            } catch (e) {
                throw new Error('unable to convert sheet to row objects');
            }
            break;
        default:
            throw new Error(`Files With Extension ${file.ext} are not supported`);
    }

    // Check if there are any rows in file provided
    if (!(rowObjects.length > 0)) {
        throw new Error('no rows in workbook supplied');
    }

    onSuccess(setTypes(rowObjects, category));
};
