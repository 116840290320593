import { BillingType, ProcessingBank, TransactionFee } from 'popcorn-js/party';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { StyledSelect } from 'components/Select/StyledSelect';
import { Collapse } from '@material-ui/core';
import { Cell } from 'components/Cell/Cell';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { AppContext, AppContextT } from 'context';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { objectCopy } from 'utils';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { ValueType } from 'react-select';

export const TransactionFees = (props: {
    transactionFee: TransactionFee;
    transactionFees: TransactionFee[];
    index: number;
    onChange: (field: string, value: unknown, index: number) => void;
    handleRemove: (index: number) => void;
    saved: boolean;
}): ReactElement => {
    const { transactionFee, transactionFees, index, onChange, handleRemove, saved } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [open, setOpen] = useState<boolean>(true);
    const [original, setOriginal] = useState<TransactionFee>({} as TransactionFee);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);
    const [bankOptions, setBankOptions] = useState<{ value: string; label: string }[]>([]);
    const [billingType, setBillingType] = useState<BillingType>(BillingType.ClientBilling);
    const [bankChange, setBankChange] = useState<boolean>(false);
    const [bankDisplay, setBankDisplay] = useState<string | undefined>(undefined);

    const billingOptions: { value: string; label: string }[] = [];
    Object.values(BillingType).map((billing: BillingType) => {
        billingOptions.push({
            label: billing,
            value: billing,
        });
    });

    const inputProps = {
        disableUnderline: true,
        readOnly: true,
    };

    const cancelChanges = () => {
        if (original.bank == '') {
            handleRemove(index);
        } else {
            transactionFee.bank = original.bank;
            transactionFee.billingType = original.billingType;

            onChange('adminFees', original.adminFees, index);
            onChange('bank', original.bank, index);
            onChange('billingType', original.billingType, index);
            onChange('intermediaryMargins', original.intermediaryMargins, index);

            setBankName();
            setBillingType(original.billingType);

            setEditMode(false);
        }
    };

    const showDeleteConfirmation = async () => {
        setConfirmationMethod(() => async () => {
            handleRemove(index);
            handleHideAlert();
        });
        setWarningMessage(`You are about to remove ${bankDisplay}.\nDo you want to continue?`);
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const setBankName = () => {
        if (appContext.processingBanks) {
            for (const bank of appContext.processingBanks) {
                if (bank.partyCode === transactionFee.bank) {
                    setBankDisplay(bank.name);
                }
            }
        }
    };

    useEffect(() => {
        setOriginal(objectCopy(transactionFee));
        if (transactionFee.bank == '') {
            setEditMode(true);
        } else {
            setBankName();
        }

        let tempOptions: { value: string; label: string }[] = [];
        appContext.processingBanks?.map((bank: ProcessingBank) => {
            tempOptions.push({
                label: bank.name,
                value: bank.partyCode,
            });
        });
        for (const transFee of transactionFees) {
            if (transFee.bank === transactionFee.bank) {
                continue;
            }
            tempOptions = tempOptions.filter((bank) => bank.value !== transFee.bank);
        }
        setBankOptions(
            tempOptions.sort((a, b) => {
                const nameA = a.label.toUpperCase();
                const nameB = b.label.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }),
        );

        if (transactionFee.billingType === 'BANK') {
            setBillingType(BillingType.BankBilling);
        } else {
            setBillingType(BillingType.ClientBilling);
        }
    }, []);

    useEffect(() => {
        setBankName();

        if (transactionFee.billingType === 'BANK') {
            setBillingType(BillingType.BankBilling);
        } else {
            setBillingType(BillingType.ClientBilling);
        }
    }, [transactionFee]);

    useEffect(() => {
        if (saved && transactionFee.bank != '') {
            setOriginal(objectCopy(transactionFee));
            setEditMode(false);
        }
    }, [saved]);

    useEffect(() => {
        if (bankChange && transactionFee.bank && appContext.processingBanks) {
            for (const bank of appContext.processingBanks) {
                if (transactionFee.bank == bank.partyCode && bank.billingAddress?.invoiceBank) {
                    onChange('billingType', BillingType.BankBilling, index);
                } else if (transactionFee.bank == bank.partyCode) {
                    onChange('billingType', BillingType.ClientBilling, index);
                }
            }
        }
        setBankChange(false);
    }, [bankChange]);

    return (
        <div className={classes.transactionFee}>
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            id: 'Pricing/bank/label',
                            type: ITEM_VARIATION.ELEMENT,
                            element: (
                                <div>
                                    <span className={classes.transactionFeeLabel}>Bank:</span>
                                </div>
                            ),
                        },
                        {
                            id: 'Pricing/bank/dropdown',
                            type: ITEM_VARIATION.ELEMENT,
                            element: (
                                <div className={classes.bank}>
                                    {(() => {
                                        if (!editMode) {
                                            return (
                                                <div className={classes.text}>
                                                    <BaseTextField
                                                        id="Pricing/bank/textField"
                                                        InputProps={inputProps}
                                                        value={bankDisplay}
                                                        fullWidth
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <StyledSelect
                                                    onChange={(
                                                        value: ValueType<{ value: string; label: string }, false>,
                                                    ) => {
                                                        onChange('bank', value?.value, index);
                                                        setBankDisplay(value?.label);
                                                        setBankChange(true);
                                                    }}
                                                    options={bankOptions}
                                                    value={{
                                                        label: bankDisplay || '',
                                                        value: bankDisplay || '',
                                                    }}
                                                    readOnly
                                                />
                                            );
                                        }
                                    })()}
                                </div>
                            ),
                        },
                    ],
                    itemsRight: [
                        {
                            id: 'Pricing/bank/cancel',
                            type: ITEM_VARIATION.ICON_BUTTON,
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Cancel changes',
                            onClick: cancelChanges,
                            hide: !editMode,
                        },
                        {
                            id: 'Pricing/bank/delete',
                            type: ITEM_VARIATION.ICON_BUTTON,
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete Transaction Fee',
                            onClick: showDeleteConfirmation,
                            hide: editMode,
                        },
                        {
                            id: 'Pricing/bank/edit',
                            type: ITEM_VARIATION.ICON_BUTTON,
                            icon: ACTION_BUTTON_TYPE.EDIT,
                            helpText: 'Edit Transaction Fee',
                            onClick: () => setEditMode(true),
                            hide: editMode,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'Pricing/bank/collapse',
                            icon: ACTION_BUTTON_TYPE.COLLAPSE,
                            helpText: 'Collapse',
                            onClick: () => setOpen(false),
                            hide: !open,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'Pricing/bank/expand',
                            icon: ACTION_BUTTON_TYPE.EXPAND,
                            helpText: 'Expand',
                            onClick: () => setOpen(true),
                            hide: open,
                        },
                    ],
                }}
            >
                <Collapse in={open}>
                    <div className={classes.transactionFeeCardWrapper}>
                        <div className={classes.pricing}>
                            <div className={classes.row} /*---- Row 1 ----*/>
                                <Cell
                                    variant={'heading'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                    }}
                                    height={'40px'}
                                    width={'150px'}
                                />
                                <Cell
                                    variant={'heading'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                        left: { weight: 'normal', color: 'default' },
                                        right: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'40px'}
                                    text={'New Deal'}
                                />
                                <Cell
                                    variant={'heading'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                        right: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'40px'}
                                    text={'Cancellation'}
                                />
                                <Cell
                                    variant={'heading'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                        right: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'40px'}
                                    text={'SWAP'}
                                />
                                <Cell
                                    variant={'heading'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'40px'}
                                    text={'Option'}
                                />
                            </div>
                            <div className={classes.row /*---- Row 2 ----*/}>
                                <Cell
                                    variant={'heading'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'right'}
                                    height={'45px'}
                                    width={'150px'}
                                    text={'Intermediary Margin'}
                                />
                                <Cell
                                    variant={'smallValue'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                        left: { weight: 'normal', color: 'default' },
                                        right: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'45px'}
                                    text={
                                        transactionFee.intermediaryMargins?.newDeal
                                            ? transactionFee.intermediaryMargins?.newDeal.toFixed(4)
                                            : '-'
                                    }
                                    prefix={transactionFee.intermediaryMargins?.newDeal ? '' : '-'}
                                    editable={editMode}
                                    field={'intermediaryMargins.newDeal'}
                                    index={index}
                                    editField={onChange}
                                />
                                <Cell
                                    variant={'smallValue'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                        right: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'45px'}
                                    text={
                                        transactionFee.intermediaryMargins?.cancellation
                                            ? transactionFee.intermediaryMargins?.cancellation.toFixed(4)
                                            : '-'
                                    }
                                    prefix={transactionFee.intermediaryMargins?.cancellation ? '' : '-'}
                                    editable={editMode}
                                    field={'intermediaryMargins.cancellation'}
                                    index={index}
                                    editField={onChange}
                                />
                                <Cell
                                    variant={'smallValue'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                        right: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'45px'}
                                    text={
                                        transactionFee.intermediaryMargins?.swap
                                            ? transactionFee.intermediaryMargins?.swap.toFixed(4)
                                            : '-'
                                    }
                                    prefix={transactionFee.intermediaryMargins?.swap ? '' : '-'}
                                    editable={editMode}
                                    field={'intermediaryMargins.swap'}
                                    index={index}
                                    editField={onChange}
                                />
                                <Cell
                                    variant={'smallValue'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'45px'}
                                    text={
                                        transactionFee.intermediaryMargins?.option
                                            ? transactionFee.intermediaryMargins?.option.toFixed(4)
                                            : '-'
                                    }
                                    prefix={transactionFee.intermediaryMargins?.option ? '' : '-'}
                                    editable={editMode}
                                    field={'intermediaryMargins.option'}
                                    index={index}
                                    editField={onChange}
                                />
                            </div>
                            <div className={classes.row /*---- Row 3 ----*/}>
                                <Cell
                                    variant={'heading'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'right'}
                                    height={'45px'}
                                    width={'150px'}
                                    text={'Admin Fee'}
                                />
                                <Cell
                                    variant={'smallValue'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                        left: { weight: 'normal', color: 'default' },
                                        right: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'45px'}
                                    text={
                                        transactionFee.adminFees?.newDeal
                                            ? `ZAR ${transactionFee.adminFees?.newDeal.toFixed(2)}`
                                            : '-'
                                    }
                                    prefix={transactionFee.adminFees?.newDeal ? 'ZAR' : '-'}
                                    editable={editMode}
                                    field={'adminFees.newDeal'}
                                    index={index}
                                    editField={onChange}
                                />
                                <Cell
                                    variant={'smallValue'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                        right: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'45px'}
                                    text={
                                        transactionFee.adminFees?.cancellation
                                            ? `ZAR ${transactionFee.adminFees?.cancellation.toFixed(2)}`
                                            : '-'
                                    }
                                    prefix={transactionFee.adminFees?.cancellation ? 'ZAR' : '-'}
                                    editable={editMode}
                                    field={'adminFees.cancellation'}
                                    index={index}
                                    editField={onChange}
                                />
                                <Cell
                                    variant={'smallValue'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                        right: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'45px'}
                                    text={
                                        transactionFee.adminFees?.swap
                                            ? `ZAR ${transactionFee.adminFees?.swap.toFixed(2)}`
                                            : '-'
                                    }
                                    prefix={transactionFee.adminFees?.swap ? 'ZAR' : '-'}
                                    editable={editMode}
                                    field={'adminFees.swap'}
                                    index={index}
                                    editField={onChange}
                                />
                                <Cell
                                    variant={'smallValue'}
                                    border={{
                                        bottom: { weight: 'normal', color: 'default' },
                                    }}
                                    align={'left'}
                                    width={'150px'}
                                    height={'45px'}
                                    text={
                                        transactionFee.adminFees?.option
                                            ? `ZAR ${transactionFee.adminFees?.option.toFixed(2)}`
                                            : '-'
                                    }
                                    prefix={transactionFee.adminFees?.option ? 'ZAR' : '-'}
                                    editable={editMode}
                                    field={'adminFees.option'}
                                    index={index}
                                    editField={onChange}
                                />
                            </div>
                        </div>
                        <div className={classes.billingType}>
                            <span className={classes.billingTypeText}>Billing Type</span>
                            {(() => {
                                if (!editMode) {
                                    return (
                                        <div className={classes.text}>
                                            <BaseTextField
                                                id="Pricing/bank/billingType"
                                                InputProps={inputProps}
                                                value={billingType}
                                                fullWidth
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <StyledSelect
                                            onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                                                onChange('billingType', value?.value, index);
                                            }}
                                            options={billingOptions}
                                            value={{
                                                label: transactionFee.billingType || '',
                                                value: transactionFee.billingType || '',
                                            }}
                                            readOnly
                                        />
                                    );
                                }
                            })()}
                        </div>
                    </div>
                </Collapse>
            </StandardCard>
            <NotificationSweetAlert
                onClose={handleHideAlert}
                errorMessage={errorMessage}
                warningMessage={warningMessage}
                onConfirm={confirmationMethod}
                autoFormat={false}
            />
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    transactionFee: {
        margin: '3px 0px 12px 0px',
    },
    transactionFeeLabel: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: theme.palette.custom.text.body,
        margin: '0px 10px 0px 12px',
    },
    bank: {
        width: '300px',
    },
    transactionFeeCardWrapper: {
        height: '160px',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    pricing: {
        width: '80%',
        backgroundColor: theme.palette.custom.paperExtended.paper5,
        paddingTop: '15px',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    billingType: {
        width: '20%',
        padding: '12px 12px 0px 12px',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
    },
    billingTypeText: {
        fontSize: '14px',
        color: theme.palette.custom.text.body,
    },
    text: {
        paddingTop: '7px',
    },
}));
