import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { Collapse, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import RefinitivLogo from 'assets/img/RefinitivLogoWhite.png';
import { Refresh } from '@material-ui/icons';
import { format } from 'date-fns';
import Accounts from './Accounts';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import { useServiceSync } from 'hooks/useService';
import { Account, GenerateAccountsReportRequest, GenerateAccountsReportResponse } from 'popcorn-js/cfcDeposit/handler';
import { ActionButton } from 'components/ActionButton/ActionButton';

export const CFCAccountSummaries = (props: { setReloadTriggerMain: () => void }): ReactElement => {
    const classes = useStyles();
    const { setReloadTriggerMain } = props;
    const formatTime = format(new Date(), 'kk:mm');
    const [open, setOpen] = useState<boolean>(false);
    const { cfcDepositHandler } = useContext<ServiceContextT>(ServiceContext);
    const [reportResponse, setReportResponse] = useState<Account[] | undefined>();

    const [generateReport] = useServiceSync<GenerateAccountsReportRequest, GenerateAccountsReportResponse>(
        cfcDepositHandler.GenerateAccountsReport,
    );
    useEffect(() => {
        generateReport({}).then((r) => {
            setReportResponse(r.accounts);
        });
    }, []);
    return (
        <StandardCard
            cardHeaderProps={{
                itemsLeft: [
                    {
                        id: 'CFCDepositManagementStation/Summaries/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'CFC Account Summaries',
                    },
                ],
                itemsRight: [
                    {
                        id: 'CFCDepositManagementStation/Summaries/refinitive-logo',
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <div className={classes.elementWrapper}>
                                <div className={classes.row}>
                                    <div className={classes.refinitivLogoWrapper}>
                                        <img alt="Refinitive Logo" src={RefinitivLogo} />
                                    </div>
                                </div>
                            </div>
                        ),
                    },
                    {
                        type: ITEM_VARIATION.ELEMENT,
                        id: 'cfcSummary/refresh',
                        element: (
                            <div className={classes.timeRefreshWrapper}>
                                <div className={classes.time}>{formatTime}</div>
                                <div className={classes.refreshPosition}>
                                    <ActionButton
                                        id="refresh"
                                        onClick={() => setReloadTriggerMain()}
                                        icon={<Refresh />}
                                    />
                                </div>
                            </div>
                        ),
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'CFCDepositManagementStation/Summaries/collapse',
                        icon: ACTION_BUTTON_TYPE.COLLAPSE,
                        helpText: 'Collapse',
                        onClick: () => setOpen(false),
                        hide: !open,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'CFCDepositManagementStation/Summaries/expand',
                        icon: ACTION_BUTTON_TYPE.EXPAND,
                        helpText: 'Expand',
                        onClick: () => setOpen(true),
                        hide: open,
                    },
                ],
            }}
        >
            <Collapse in={!open}>
                {/*Collapsed state*/}
                {reportResponse?.map((acc: Account) => (
                    <Accounts account={acc} key={acc.name} collapsedState={true} />
                ))}
            </Collapse>
            <Collapse in={open}>
                {/*Expanded State*/}
                {reportResponse?.map((acc: Account) => (
                    <Accounts account={acc} key={acc.name} collapsedState={false} />
                ))}
            </Collapse>
        </StandardCard>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    positionCardContentRoot: {
        backgroundColor: theme.palette.background.paper,
        height: '325px',
        padding: 0,
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: 'auto auto 1fr auto',
        '&:last-child': {
            padding: 0,
        },
        overFlow: 'hidden',
    },
    positionValuesLoading: {
        gridColumn: '1/4',
        padding: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px 0px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.light,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
    refinitivLogoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0px 10px 0px 0px',
        height: '12px',
        width: '60px',
        alignSelf: 'center',
    },
    elementWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        height: '40px',
    },
    timeRefreshWrapper: {
        width: '66px',
        height: '22px',
        background: theme.palette.custom.paperExtended.paper6,
        borderRadius: '6px',
        padding: theme.spacing(0.5),
        fontSize: '12px',
        display: 'flex',
    },
    time: {
        margin: '5px 0px 5px 0px',
        fontWeight: 'bold',
    },
    refreshPosition: {
        margin: '0px 25px 0px 0px',
        display: 'flex',
    },
}));
