import React, { ReactElement } from 'react';
import { Button, Dialog, Grid, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        fontWeight: 300,
        letterSpacing: '0.4px',
        overflow: 'hidden',
        backgroundColor: 'rgba(29, 42, 54, 0.4)',
    },
    header: {},
    button: {
        backgroundColor: theme.palette.background.paper,
        width: '140px',
    },
    contentArea: {
        backgroundColor: 'rgba(29, 42, 54, 0.4)',
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '1.35',
        letterSpacing: '1.1px',
        color: theme.palette.text.primary,
        paddingTop: '8px',
        paddingLeft: '16px',
    },
}));

const ErrorProcessingFile = (props: { onAwayClick: () => void; show: boolean; errorMessage: string }): ReactElement => {
    const { onAwayClick, show, errorMessage } = props;

    const classes = useStyles();

    return (
        <Dialog
            className={classes.root}
            onClose={() => {
                if (onAwayClick) onAwayClick();
            }}
            open={show}
        >
            <Grid container direction={'column'} spacing={1}>
                <Grid className={classes.header} item>
                    <div className={classes.title}>Error While Processing File</div>
                </Grid>
                <Grid className={classes.contentArea} item>
                    <Grid container direction={'column'} spacing={1}>
                        <Grid item>{errorMessage}</Grid>
                        <Grid item>
                            <Button
                                onClick={() => {
                                    if (onAwayClick) onAwayClick();
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ErrorProcessingFile;
