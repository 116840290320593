import React, { ReactElement, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import CashProjectionButton from 'views/Client/CashFlowGraph/CashProjectionButton';
import graphLogo from 'assets/img/graph-image.png';
import { AppContext, AppContextT } from 'context';
import { viewViewCashFlowGraph } from 'popcorn-js/permissions';
import MainCashFlowGraphLayout from 'views/Client/CashFlowGraph/MainCashFlowGraphLayout';
import Dialog from '@material-ui/core/Dialog';
import ReportsButton from './CashFlowGraph/ReportsButton';

const CashProjectionCard = (): ReactElement => {
    const classes = useCashProjectionCardStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [showCashFlowGraph, setShowCashFlowGraph] = useState(false);

    const canViewCashFlowGraph = () => {
        try {
            return appContext.viewPermissions?.includes(viewViewCashFlowGraph);
        } catch (e) {
            console.error("error getting party's local currency", e);
        }
    };

    return (
        <React.Fragment>
            <Dialog
                PaperProps={{ classes: { root: classes.dialog } }}
                fullWidth
                maxWidth={'lg'}
                onClose={() => setShowCashFlowGraph(false)}
                open={showCashFlowGraph}
            >
                {appContext.localCurrency !== undefined && (
                    <MainCashFlowGraphLayout
                        localCurrency={appContext.localCurrency}
                        onClose={() => setShowCashFlowGraph(false)}
                    />
                )}
            </Dialog>
            <div className={classes.cashProtectionCardWrapper}>
                <div className={classes.cashProtectionCardLayout}>
                    <div className={classes.cashProjectionSectionHeader}>CASH PROJECTION (ZAR)</div>
                    <div className={classes.buttonLayout}>
                        <img alt="logo" src={graphLogo} />
                    </div>
                    <div className={classes.buttonWrapper}>
                        <CashProjectionButton
                            disabled={!canViewCashFlowGraph}
                            onClick={() => setShowCashFlowGraph(true)}
                        />
                        <ReportsButton />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const useCashProjectionCardStyles = makeStyles((theme: CustomTheme) => ({
    cashProtectionCardWrapper: {
        width: '517px',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '3px',
    },
    cashProtectionCardLayout: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        rowGap: '30px',
    },
    cashProjectionSectionHeader: {
        color: theme.palette.text.primary,
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: 'bold',
        alignSelf: 'flex-start',
    },
    dialog: {
        overflow: 'hidden',
    },
    buttonLayout: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        justifySelf: 'flex-end',
        width: '35px',
        marginTop: '10px',
    },
}));

export default CashProjectionCard;
