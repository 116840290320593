import React, { ReactElement } from 'react';
import Popover from '@material-ui/core/Popover';
import { useStyletron } from 'styletron-react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import SettingsIcon from '@material-ui/icons/Settings';
import { paletteDefault } from './palette';
import createPalette from '@material-ui/core/styles/createPalette';
import { CustomPalette } from './custom';
import { PaletteEditor } from './PaletteEditor';
import { BaseProvider, createTheme } from 'baseui';
import { CustomBaseUIThemeT } from './basewebStyleUtils';

export const ThemeWrapper = (props: { children: ReactElement; editMode: boolean }): ReactElement => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [palette, setPalette] = React.useState<CustomPalette>({ ...createPalette({}), ...paletteDefault });

    const [css] = useStyletron();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(open ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const theme = createMuiTheme({ palette });

    // base web theme
    // first create standard baseweb theme to avoid runtime errors

    const primitives = {
        // TODO match with guideline
    };
    const overrides = {
        // TODO match with guideline
    };
    const baseWebStandardTheme = createTheme(primitives, overrides);

    // merging custom theme from guidelines with standard theme

    const customTheme: CustomBaseUIThemeT = {
        ...palette,
        ...baseWebStandardTheme,
    };

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <div
                    className={css({
                        position: 'absolute',
                        right: '10px',
                        bottom: '10px',
                        zIndex: 999999,
                        width: 'auto',
                        height: 'auto',
                    })}
                >
                    <Fab
                        className={css({ visibility: props.editMode ? 'visible' : 'hidden' })}
                        color={'primary'}
                        size={'small'}
                        aria-label={'add'}
                        onClick={handleClick}
                    >
                        <SettingsIcon />
                    </Fab>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <PaletteEditor onChange={(p: CustomPalette) => setPalette(p)} palette={palette} />
                    </Popover>
                </div>
                <BaseProvider theme={customTheme}>{props.children}</BaseProvider>
            </MuiThemeProvider>
        </>
    );
};
