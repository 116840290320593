import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { Backdrop, CircularProgress, Dialog, Grid, makeStyles, Snackbar } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';
import { ComponentLevelLoader } from 'components/Loader/ComponentLevelLoader';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { generateMainLayout, ParentAllocation, TradeValues } from './index';
import { Trade, TradeDirection, TradeType } from 'popcorn-js/tradeV2';
import { MemoizedManageParentTrades } from 'components/tradeV2/ticket/ManageParentTrades';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { AppContext, AppContextT } from 'context';
import Big from 'big.js';
import _ from 'lodash';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { Alert } from './styledComponents';
import Ticket from './Ticket';

const ParentTicket = (props: {
    tradeValues?: TradeValues;
    tradeType: TradeType;
    initialDirection?: TradeDirection;
    closeTicket: () => void;
    show: boolean;
    parentTrades: Trade[];
    currencyPairToTrade?: CurrencyPair;
    optionExercise: boolean;
    autoFillMode: boolean;
}): ReactElement => {
    const {
        tradeValues,
        tradeType,
        initialDirection,
        show,
        closeTicket,
        parentTrades,
        currencyPairToTrade,
        autoFillMode,
        optionExercise,
    } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

    // parent trade props
    const firstParent = useMemo(() => (parentTrades.length > 0 ? parentTrades[0] : undefined), [parentTrades]);
    const firstParentCurrencyPairName: string = useMemo(
        () => firstParent?.currencyPair || currencyPairToTrade?.name || 'USD/ZAR',
        [firstParent],
    );
    const allCurrencyPairs = appContext.currencyPairs || ([] as CurrencyPair[]);
    const firstParentCurrencyPair =
        allCurrencyPairs.find((ccy) => ccy.name == firstParentCurrencyPairName) || currencyPairToTrade;

    // other states
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [allocationAmountsConfirmed, setAllocationAmountsConfirmed] = useState<boolean>(false);
    const initialTotalParentAllocation = parentTrades.reduce((acc, trd) => acc.add(trd.availableBalance || 0), Big(0));
    const [totalParentAllocation, setTotalParentAllocation] = useState<Big>(initialTotalParentAllocation);
    const [parentAllocations, setParentAllocations] = useState<ParentAllocation[]>(
        parentTrades.map(
            (trd): ParentAllocation => {
                return {
                    id: trd.id || '',
                    parentExternalReference: trd.externalReference || '',
                    parentAvailableBalance: Big(trd.availableBalance || 0).toFixed(2),
                    parentDirection: trd.direction || '',
                    parentType: trd.type || '',
                    amount: trd.availableBalance?.toFixed(2) || '',
                    parentDealRate: trd.dealRate?.toFixed(4) || '',
                    parentMaturityDate: trd.maturityDate || new Date(),
                    parentBank: trd.bank || '',
                };
            },
        ),
    );

    const { title, showParentTradesSection } = useMemo(() => {
        return generateMainLayout(tradeType);
    }, [tradeType]);

    const handleParentAmountChange = (parentId: string, value: string) => {
        setLoading(true);
        const newTransactionParents = _.cloneDeep(parentAllocations);
        const i = newTransactionParents.findIndex((_t: ParentAllocation) => _t.id === parentId);
        if (i == -1) {
            return;
        }

        const parent = newTransactionParents[i];
        let valueBig = new Big(value || 0);

        if (valueBig.gt(parent.parentAvailableBalance || 0)) {
            valueBig = Big(parent.parentAvailableBalance || 0);
        }

        newTransactionParents[i] = {
            ...newTransactionParents[i],
            amount: valueBig.eq(0) ? '' : valueBig.toFixed(2),
        };

        const totalParentAllocation = newTransactionParents.reduce(
            (total, current) => total.add(current.amount || 0),
            Big(0),
        );
        setParentAllocations(newTransactionParents);
        setTotalParentAllocation(totalParentAllocation);
        setLoading(false);
    };
    const handlerAlertClose = () => {
        setError(undefined);
    };

    return (
        <Dialog fullScreen onClose={() => closeTicket()} open={show} classes={{ paper: classes.paper }}>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        overflow: 'hidden',
                    },
                }}
                open={loading}
            >
                <div className={classes.loadingLayout}>
                    <ComponentLevelLoader color={'white'} isLoading={loading} />
                </div>
            </Dialog>
            <BaseAppBar
                title={`Capture ${title}`}
                onClose={closeTicket}
                showCloseButton
                showActionButton
                buttonDisabled={buttonDisabled}
                actionButtonOnClick={() => setConfirmOpen(true)}
                buttonText={'Record Trade Info'}
            />
            <div className={classes.root}>
                <div style={{ margin: '0 auto' }}>
                    <Grid container direction={'row'} spacing={1}>
                        <div className={classes.workspace}>
                            <Grid item style={{ width: '1366px', marginLeft: '0px' }}>
                                <div className={classes.standardRow}>
                                    {showParentTradesSection ? (
                                        <MemoizedManageParentTrades
                                            transactionsParents={parentAllocations}
                                            totalAllocatedAmount={totalParentAllocation}
                                            allocationsChange={handleParentAmountChange}
                                            currencyCode={firstParentCurrencyPair?.baseCurrency || ''}
                                            allocationConfirmed={allocationAmountsConfirmed}
                                        />
                                    ) : (
                                        <div />
                                    )}
                                </div>
                                <div className={classes.standardRow}>
                                    {showParentTradesSection && !allocationAmountsConfirmed ? (
                                        <div className={classes.confirmButtonWrapper}>
                                            <BaseButton
                                                id={'button'}
                                                variant={VARIANT.CONTAINED}
                                                size={SIZE.MEDIUM}
                                                text={'Confirm Allocated Amount'}
                                                color={COLOR.ACTION}
                                                onClick={() => setAllocationAmountsConfirmed(true)}
                                            />
                                        </div>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </Grid>
                            <Grid item style={{ width: '1366px', marginLeft: '0px' }}>
                                {!showParentTradesSection || allocationAmountsConfirmed ? (
                                    <Ticket
                                        tradeValues={tradeValues}
                                        tradeType={tradeType}
                                        parentTrades={parentTrades}
                                        currencyPairToTrade={currencyPairToTrade}
                                        initialDirection={initialDirection}
                                        parentAllocation={parentAllocations}
                                        totalParentAllocation={totalParentAllocation}
                                        confirmOpen={confirmOpen}
                                        closeTicket={closeTicket}
                                        closeConfirm={() => setConfirmOpen(false)}
                                        setButtonDisabled={setButtonDisabled}
                                        autoFillMode={autoFillMode}
                                        optionExercise={optionExercise}
                                    />
                                ) : (
                                    <div />
                                )}
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </div>
            <Backdrop open={false} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar autoHideDuration={3000} onClose={() => undefined} open={false}>
                <>
                    <Alert onClose={handlerAlertClose} severity="error">
                        Load error: {error}
                    </Alert>
                </>
            </Snackbar>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    loadingLayout: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    root: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    workspace: {
        flexGrow: 1,
    },
    captureInformation: { height: '100%' },
    tradeDetailsHeader: {
        display: 'flex',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    buttonCell: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tradesColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-end',
        justifyItems: 'center',
        width: '50%',
        rowGap: theme.spacing(2),
    },
    tradesRow: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    standardRow: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        columnGap: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: 10,
        backgroundColor: HexToRGBA(theme.palette.background.default, 0.8),
    },
    confirmButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
    },
}));

export default ParentTicket;
