import { Identifier, marshalIdentifier } from 'popcorn-js/search/identifier';
import { jsonRPC } from 'utils/jsonrpc';
import { ApiUser } from '.';
import config from 'react-global-configuration';
import { FindRequest, FindResponse } from 'popcorn-js/';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { Criteria } from 'popcorn-js/search';

export type UpdateRequest = {
    id?: Identifier | string;
    apiUser?: ApiUser;
};
export type UpdateResponse = {
    apiUser: ApiUser;
};

export type CreateRequest = {
    apiUser?: ApiUser;
};
export type CreateResponse = {
    apiUser: ApiUser;
};

export type DeleteForeverRequest = {
    identifier?: Identifier | string;
};
export type DeleteForeverResponse = null;

export const Recordkeeper = {
    ServiceProviderName: 'APIUser-Recordkeeper',
    create(request: CreateRequest): Promise<CreateResponse> {
        return jsonRPC<CreateRequest, CreateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Create`,
            request,
        });
    },
    update(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.id ? marshalIdentifier(request.id as Identifier) : undefined;
        return jsonRPC<UpdateRequest, UpdateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Update`,
            request: {
                ...request,
                id: serializedIdentifier,
            },
        });
    },
    deleteForever(request: DeleteForeverRequest): Promise<DeleteForeverResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteForeverRequest, DeleteForeverResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.DeleteForever`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    find(request: FindRequest): Promise<FindResponse<ApiUser>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<FindRequest, FindResponse<ApiUser>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
};
