import React, { ReactElement } from 'react';
import { Popover } from '@material-ui/core';

export const StyledPopper = (props: {
    onClickAway: () => void;
    anchorEl: HTMLElement | undefined;
    children?: React.ReactElement;
}): ReactElement => {
    const { anchorEl, onClickAway, children } = props;

    // TODO add pointers
    // TODO add border
    return (
        <React.Fragment>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                onClose={onClickAway}
                open={!!anchorEl}
            >
                <div>{children}</div>
            </Popover>
        </React.Fragment>
    );
};
