import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UploadClientData } from './UploadClientData';
import { Grid } from '@material-ui/core';

export const UploadClientDataStation = (): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div id="uploadClientDataStationRoot" className={classes.container}>
                <Grid container direction={'row'} spacing={1}>
                    <Grid item className={classes.containerGrid}>
                        <UploadClientData />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        height: 'calc(100vh - 100px)',
        overflowY: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        //backgroundColor: 'purple',
    },
    container: {
        margin: '0 auto',
    },
    containerGrid: {
        width: '1180px',
        margin: '0 auto',
        //backgroundColor: 'orange',
    },
}));
