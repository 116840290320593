import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseButton, VARIANT, SIZE, COLOR } from 'components/BaseButton';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '160px',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonWrapper: {
        justifySelf: 'center',
        fontSize: '14px',
    },
}));

interface CashProjectionButtonProps {
    disabled: boolean;
    onClick: (e?: string) => void;
}

const CashProjectionButton: React.FC<CashProjectionButtonProps> = (props: CashProjectionButtonProps) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.buttonWrapper}>
                <BaseButton
                    id={'CashProjectionButton/View'}
                    disabled={props.disabled}
                    variant={VARIANT.CONTAINED}
                    color={COLOR.ACTION}
                    size={SIZE.MEDIUM}
                    onClick={(): void => props.onClick()}
                    text={'VIEW GRAPH'}
                />
            </div>
        </div>
    );
};

export default CashProjectionButton;
