import React, { ReactElement, FunctionComponent } from 'react';
import { useTheme, InputBaseComponentProps } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { NumberFormatProps } from 'react-number-format';
import { NumberFormatCustom } from './NumberFormatCustom';
import { BaseTextField, BaseTextFieldProps } from './BaseTextField';

export const BaseNumberField = (
    props: { overridePrefixColor?: boolean } & BaseTextFieldProps & NumberFormatProps,
): ReactElement => {
    const { prefix, label, InputProps, overridePrefixColor, ...rest } = props;
    const theme = useTheme<CustomTheme>();

    return (
        <BaseTextField
            {...rest}
            InputProps={{
                ...InputProps,
                inputComponent: (NumberFormatCustom as unknown) as FunctionComponent<InputBaseComponentProps>,
                startAdornment: prefix ? (
                    <span
                        style={{
                            color: overridePrefixColor ? undefined : theme.palette.text.primary,
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            marginRight: '3px',
                            marginBottom: '2px',
                        }}
                    >
                        {prefix}
                    </span>
                ) : (
                    ''
                ),
            }}
            label={label}
        />
    );
};
