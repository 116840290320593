/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react';
import { DragSource } from 'react-dnd';
import { Card, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        width: 200,
        height: 25,
        cursor: 'grab',
        paddingLeft: 4,
    },
}));

const listItemSource = {
    beginDrag(props: any) {
        return props.listObject;
    },
};

function collect(connect: any, monitor: any) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

const ListItem = (props: {
    accessor: any;
    listObject: any;
    connectDragSource?: (props: any) => ReactElement;
    draggingStarted?: (props: any) => void;
    draggingStopped?: (props: any) => void;
    isDragging?: boolean;
}): ReactElement => {
    const { connectDragSource, isDragging, accessor, listObject } = props;

    const classes = useStyles();

    return connectDragSource ? (
        connectDragSource(
            <div>
                {isDragging ? (
                    <></>
                ) : (
                    <>
                        <Card>
                            <div className={classes.root}>{listObject[accessor]}</div>
                        </Card>
                    </>
                )}
            </div>,
        )
    ) : (
        <div />
    );
};

const StyledListItem = DragSource('listItem', listItemSource, collect)(ListItem);

export const ItemTypes = {
    LISTITEM: 'listItem',
};

export default StyledListItem;
