import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { AppContextT, AppContext } from 'context';
import { useStyletron } from 'styletron-react';
import { CurrencyPositionCard } from './CurrencyPosition';
import Portfolio from './Portfolio';

export const Dashboard = (): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);
    const [css] = useStyletron();
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
    useEffect(() => {
        setReloadTrigger(false);
    }, [reloadTrigger]);
    // get all currencies relevant to the party, currencies that is either the quote or base currency among all the
    // currency pairs assigned to the party
    const partyCurrencies = appContext.currencies?.filter((ap) => {
        return (
            ap.isoCode !== appContext.localCurrency?.isoCode &&
            appContext.assignedCurrencyPairs?.find(
                (acp) => acp.baseCurrency === ap.isoCode || acp.quoteCurrency === ap.isoCode,
            )
        );
    });

    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                height: 'calc(100vh - 100px)',
                overflowY: 'scroll',
                justifyContent: 'flex-start',
                alignItems: 'center',
            })}
        >
            <div
                className={css({
                    width: '1090px',
                })}
            >
                {!reloadTrigger && <Portfolio />}
            </div>
            {(partyCurrencies || []).map((currency, idx) => {
                return (
                    <div
                        key={idx}
                        className={css({
                            width: '1090px',
                        })}
                    >
                        {!reloadTrigger && (
                            <CurrencyPositionCard
                                currency={currency}
                                key={idx}
                                setReloadTriggerMain={() => setReloadTrigger(true)}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Dashboard;
