import React, { ReactElement } from 'react';
import { CardHeader, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import classNames from 'classnames';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        padding: 0,
    },
    selectedRowRoot: {
        padding: 0,
        background: theme.palette.primary.main,
    },
    action: {
        alignSelf: 'unset',
        margin: '0px',
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: '20px',
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
        margin: theme.spacing(3),
    },
    titlePrimaryContrastColor: {
        color: theme.palette.primary.contrastText,
    },
}));

export enum COLOR {
    PRIMARY = 'PRIMARY',
}

export enum VARIANT {
    TABSUSAGE = 'TABSUSAGE',
}

export const StyledCardHeader = (props: {
    color?: COLOR;
    action?: ReactElement | ReactElement[];
    title?: string | ReactElement;
    variant?: VARIANT;
}): ReactElement => {
    const { action, variant, title, color } = props;
    const classes = useStyles({});
    return (
        <CardHeader
            classes={{
                action: classes.action,
            }}
            className={classNames({
                [classes.root]: color === undefined,
                [classes.selectedRowRoot]: color === COLOR.PRIMARY,
            })}
            titleTypographyProps={{
                className: classNames({
                    [classes.title]: variant === undefined,
                    [classes.titlePrimaryContrastColor]: color === COLOR.PRIMARY,
                }),
                variant: 'h6',
            }}
            action={action}
            title={title}
        />
    );
};
