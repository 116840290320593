import { AuditEntry } from 'popcorn-js/audit';

export type Role = {
    id?: string;
    name?: string;
    partyCode?: string;
    permissions?: string[];
    auditEntry?: AuditEntry;
};

export const copy = (role: Role): Role => ({
    ...role,
    permissions: role.permissions ? [...role.permissions] : [],
});
