import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { RateItem } from './rate';
import { ServiceProvider } from '../index';

export type RateSubscription = {
    currencyPairName: string;
    date: number;
};

export type PriceSubscriptionSucceeded = {
    currencyPairName: string;
    date: number;
    askForwardPoints: number;
    bidForwardPoints: number;
    askSpotPrice: number;
    bidSpotPrice: number;
    askRate: number;
    bidRate: number;
};

export type RetrieveRateRequest = {
    rateSubscription: RateSubscription;
    ratesContract: string;
};

export type RetrieveRateResponse = {
    priceSubscriptionSucceeded: PriceSubscriptionSucceeded;
};

export type GetRateCacheRequest = {
    /** none */
};

export type GetRateCacheResponse = {
    spotCache: RateItem[];
    forwardCache: RateItem[];
};

export interface Handler extends ServiceProvider {
    RetrieveRate(request: RetrieveRateRequest): Promise<RetrieveRateResponse>;
    GetRateCache(request: GetRateCacheRequest): Promise<GetRateCacheResponse>;
}

const DefaultHandler: Handler = {
    ServiceProviderName: 'Rates-Handler',
    RetrieveRate(request: RetrieveRateRequest): Promise<RetrieveRateResponse> {
        return jsonRPC<RetrieveRateRequest, RetrieveRateResponse>({
            url: config.get('pricingURL'),
            method: `${DefaultHandler.ServiceProviderName}.RetrieveRate`,
            request,
        });
    },
    GetRateCache(request: GetRateCacheRequest): Promise<GetRateCacheResponse> {
        return jsonRPC<GetRateCacheRequest, GetRateCacheResponse>({
            url: config.get('pricingURL'),
            method: `${DefaultHandler.ServiceProviderName}.GetRateCache`,
            request,
        });
    },
};

export default DefaultHandler;
