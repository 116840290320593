import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { ComponentLevelError } from 'components/Error/Error';
import { AppContext, AppContextT } from 'context';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { Criteria, CriteriaType } from 'popcorn-js/search';
import { Handler as OptionHandler } from 'popcorn-js/options/handler';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { Option } from 'popcorn-js/options';
import { OptionsFields } from 'components/Option/OptionsFields';

export const History = (props: { option?: Option; onHide?: () => void; open: boolean }): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [option, setTrade] = useState<Option | undefined>(props.option);
    const [history, setHistory] = useState<Option[]>([]);

    const currencyPairs = appContext.currencyPairs || ([] as CurrencyPair[]);

    useEffect(() => {
        if (props.option) {
            load(props.option).finally();
        }
    }, [props.option]);

    const load = async (i: Option) => {
        try {
            setIsLoading(true);
            const _history = await retrieveHistory(i);
            setTrade(modifyFields(i));
            setHistory(modifyMultiple(_history));
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    const retrieveHistory = async (trd: Option): Promise<Option[]> => {
        const criteria: Criteria = [{ type: CriteriaType.ExactCriterion, field: 'id', text: trd.id }];
        const retrieveResponse = await OptionHandler.RetrieveHistory({
            criteria: criteria,
            query: { sortBy: ['auditEntry.version'], order: ['desc'] },
        });
        return retrieveResponse.history;
    };

    const modifyMultiple = (trades: Option[]): Option[] => {
        return trades.map((t: Option) => modifyFields(t));
    };

    const modifyFields = (option: Option): Option => {
        return {
            ...option,
            currencyPair: (currencyPairs.find((c: CurrencyPair) => c.name === option.currencyPair) || {}).name,
        };
    };

    if (!option) {
        return (
            <div>
                <Typography color={'error'}>
                    <b>Error</b>
                </Typography>
                <ComponentLevelError errorMessage={'no option provided '} />;
            </div>
        );
    }

    return (
        <HistoryLayout
            entity={option}
            entityFields={OptionsFields}
            entityHistory={history}
            entityName={'Option'}
            loading={isLoading}
            onHide={props.onHide}
            open={props.open}
        />
    );
};
