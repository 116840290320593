import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Backdrop, CircularProgress, Dialog, makeStyles, Snackbar } from '@material-ui/core';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { CustomTheme } from 'theme/custom';
import { Alert } from 'components/tradeV2/ticket/styledComponents';
import { InitProps, optionValid, OptionValues } from './index';
import Big from 'big.js';
import { AppContext, AppContextT } from 'context';
import { getMidDay, HexToRGBA } from 'utils';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { parseISO } from 'date-fns';
import { CriteriaType, CriterionFilterType } from 'popcorn-js/search';
import { TradingDayException } from 'popcorn-js/tradingDayException';
import { useServiceSync } from 'hooks/useService';
import { FindRequest, FindResponse } from 'popcorn-js';
import { RetrieveRateRequest, RetrieveRateResponse } from 'popcorn-js/rick/handler';
import { OptionEditorPanel } from 'components/Option/Tickets/OptionEditorPanel';
import { ConfirmOptionDetails } from 'components/Option/Tickets/ConfirmOptionDetail';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import { Add } from '@material-ui/icons';
import { OptionDirection, OptionType } from 'popcorn-js/options';
import { Client } from 'popcorn-js/party';
import { useTransaction } from './useTransaction';
import { PartyType } from 'popcorn-js/tradeV2';

const RecordOptionTicket = (props: {
    closeTicket: () => void;
    show: boolean;
    currencyPairToTrade?: CurrencyPair;
    initialDirection: OptionDirection;
    initialType: OptionType;
}): ReactElement => {
    const { show, closeTicket, currencyPairToTrade, initialType, initialDirection } = props;

    const isMounted = useRef(false);
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const { tradingDayExceptionRecordkeeper, ratesHandler } = useContext<ServiceContextT>(ServiceContext);

    // common fields
    const [capturedSpotRate, setCapturedSpotRate] = useState<Big | undefined>();

    // general state(s)
    const [expanded, setExpanded] = useState<string | undefined>();
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [canRecord, setCanRecord] = useState<boolean>(false);
    const [nonTradingDays, setNonTradingDays] = useState<Date[]>([]);
    const [ratesLoading, setRatesLoading] = useState<boolean>(true);
    const [findTDEsLoading, setFindTDEsLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string | undefined>();
    const [marginNotesRequired, setMarginNotesRequired] = useState<boolean>(false);
    const [intermediaryMarginRequired, setIntermediaryMarginRequired] = useState<boolean>(false);
    const [billingTypeRequired, setBillingTypeRequired] = useState<boolean>(false);
    const [conditionalFieldsRequired, setConditionalFieldsRequired] = useState<boolean>(false);

    const initProps: InitProps = {
        processingBank: appContext.processingBanks ? appContext.processingBanks[0] : undefined,
        currencyPair: currencyPairToTrade,
        direction: initialDirection,
        type: initialType,
        trader: (appContext.party as Client).portfolioManager || '',
        setExpanded,
        nonTradingDays,
    };
    const { options, dispatch } = useTransaction(initProps);

    const [findTDEs] = useServiceSync<FindRequest, FindResponse<TradingDayException>>(
        tradingDayExceptionRecordkeeper?.find,
    );
    const [retrieveRate] = useServiceSync<RetrieveRateRequest, RetrieveRateResponse>(ratesHandler?.RetrieveRate);
    // this effect executes when the component mounts
    useEffect(() => {
        if (!isMounted.current) {
            // update the isMounted reference when the component is mounted for the first time
            isMounted.current = true;

            findTDEs({
                criteria: [
                    { type: CriteriaType.ExactCriterion, field: 'currency', text: currencyPairToTrade?.baseCurrency },
                    { type: CriteriaType.ExactCriterion, field: 'currency', text: currencyPairToTrade?.quoteCurrency },
                ],
                filterType: CriterionFilterType.Or,
            })
                .then((r) =>
                    setNonTradingDays(r.records.map((tde: TradingDayException) => getMidDay(parseISO(tde.date || '')))),
                )
                .catch((e) => setError('trading day exceptions: ' + (e.message || e)))
                .finally(() => setFindTDEsLoading(false));
            retrieveRate({
                rateSubscription: {
                    currencyPairName: currencyPairToTrade?.name || '',
                    date: 0,
                },
                ratesContract:
                    appContext.partyType == PartyType.CLIENT && appContext.parentPartyCode
                        ? appContext.parentPartyCode
                        : appContext.party.partyCode,
            })
                .then((r) =>
                    setCapturedSpotRate(
                        Big(r.priceSubscriptionSucceeded.askSpotPrice)
                            .plus(Big(r.priceSubscriptionSucceeded.bidSpotPrice))
                            .div(2),
                    ),
                )
                .catch((e) => setError('rate: ' + e.message))
                .finally(() => setRatesLoading(false));
            return;
        }
    }, []);
    // this effect executes when any trade changes - the optionValid function is evaluated against
    // all options to determine if the transaction can be recorded
    useEffect(() => {
        setCanRecord(
            options.map((t) => optionValid(t)).reduce((_v, v) => _v && v, options.length > 0) &&
                !conditionalFieldsRequired,
        );
    }, [options, conditionalFieldsRequired]);
    return (
        <Dialog fullScreen onClose={() => closeTicket()} open={show} classes={{ paper: classes.paper }}>
            <BaseAppBar
                title={`Capture Options`}
                onClose={closeTicket}
                showCloseButton
                showActionButton
                buttonDisabled={!canRecord}
                actionButtonOnClick={() => setConfirmOpen(true)}
                buttonText={'Record Option Info'}
            />
            <div className={classes.content}>
                <div className={classes.workspace}>
                    <div className={classes.tradesRow}>
                        <div className={classes.tradesColumn}>
                            {options.map((_t: OptionValues, _i: number) => (
                                <OptionEditorPanel
                                    key={_t.uuid + '-panel'}
                                    uuid={_t.uuid || ''}
                                    currencyPairToTrade={currencyPairToTrade}
                                    index={_i}
                                    dispatch={dispatch}
                                    option={_t}
                                    expanded={expanded}
                                    nonTradingDays={nonTradingDays}
                                    disabled={loading}
                                    onExpand={() => setExpanded(expanded === _t.uuid ? undefined : _t.uuid)}
                                    onRemove={() => {
                                        setExpanded(expanded === _t.uuid ? undefined : expanded);
                                        _t.uuid && dispatch.removeOption(_t.uuid);
                                    }}
                                    marginNotesRequired={marginNotesRequired}
                                    setMarginNotesRequired={setMarginNotesRequired}
                                    intermediaryMarginRequired={intermediaryMarginRequired}
                                    setIntermediaryMarginRequired={setIntermediaryMarginRequired}
                                    billingTypeRequired={billingTypeRequired}
                                    setBillingTypeRequired={setBillingTypeRequired}
                                    setConditionalFieldsRequired={setConditionalFieldsRequired}
                                />
                            ))}
                            <div className={classes.buttonCell}>
                                <BaseButton
                                    id={`OptionsTicket/Option/add-option`}
                                    disabled={loading}
                                    variant={VARIANT.OUTLINED}
                                    color={COLOR.WHITE}
                                    size={SIZE.MEDIUM}
                                    onClick={() => {
                                        setExpanded(undefined);
                                        setLoading(true);
                                        setTimeout(() => setLoading(false), 1000);
                                        dispatch.addOption();
                                    }}
                                    text={'Option'}
                                    icon={<Add />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {confirmOpen && (
                <ConfirmOptionDetails
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    onFinished={() => {
                        setConfirmOpen(false);
                        closeTicket();
                    }}
                    options={options}
                    capturedSpotRate={capturedSpotRate}
                    traderOrganisation={'traderOrganisation'}
                />
            )}
            <Backdrop open={ratesLoading || findTDEsLoading} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar autoHideDuration={3000} onClose={() => setError(undefined)} open={!!error}>
                <>
                    <Alert onClose={() => setError(undefined)} severity="error">
                        Load error: {error}
                    </Alert>
                </>
            </Snackbar>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(2),
    },
    backdrop: {
        zIndex: 10,
        backgroundColor: HexToRGBA(theme.palette.background.default, 0.8),
    },
    workspace: {
        flexGrow: 1,
    },
    standardRow: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        columnGap: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    tradesRow: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    tradesColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-end',
        justifyItems: 'center',
        rowGap: theme.spacing(2),
        // width: 'fit-content',
        width: '600px',
    },
    buttonCell: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
}));

export default RecordOptionTicket;
