/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useCallback, SyntheticEvent } from 'react';
import { withStyles, TextField, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { Crop75 as EmptyIcon, Clear } from '@material-ui/icons';
import { BlankCriterion, CriteriaType, TextCriterion } from 'popcorn-js/search';
import { HexToRGBA } from 'utils';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import _ from 'lodash';

const styles = (theme: CustomTheme) => ({
    input: {
        color: theme.palette.text.primary,
        fontSize: 12,
        '::placeholder': {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    notchedOutline: {
        borderWidth: '2px',
        borderColor: `${theme.palette.background.paper} !important`,
        boxShadow: `0 2px 2px 0 ${HexToRGBA('#000000', 1)}`,
    },
});

const TextFilter = (props: {
    fieldID: string;
    onChange: (props: any) => void;
    filterConfig?: any;
    initValue?: any;
    showBorder?: boolean;
    value?: any;
    classes: any;
    id: string;
}): ReactElement => {
    const { filterConfig, classes, initValue } = props;

    const [css] = useStyletron();

    const [value, setValue] = useState<string | undefined>(initValue?.text);
    const [blankFieldSelected, setBlankFieldSelected] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);

    const BLANK_CRITERION: BlankCriterion = {
        type: CriteriaType.BlankCriterion,
        field: props.fieldID,
    };

    const update = useCallback(
        _.debounce((v: string | undefined) => {
            if (!v) {
                setValue('');
                props.onChange(undefined);
                return;
            }
            setValue(v);
            const newValue: TextCriterion = {
                type: CriteriaType.TextCriterion,
                field: props.fieldID,
                text: v,
            };
            props.onChange(newValue);
        }, 500),
        [props.onChange],
    );

    const onChange = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const v = (event.target as HTMLInputElement | HTMLTextAreaElement).value;
        if (v !== value) {
            update(v);
            setValue(v);
        }
    };

    return (
        <div
            style={{
                height: '100%',
                display: 'grid',
                justifyItems: 'center',
                gridTemplateRows: '1fr',
                width: filterConfig?.width ? `${filterConfig.width}px` : '175px',
                minWidth: filterConfig?.width ? `${filterConfig.width}px` : '175px',
            }}
        >
            <div
                style={{
                    alignSelf: 'center',
                    width: '100%',
                }}
            >
                <TextField
                    id={`text-filter-${props.id}`}
                    size={'small'}
                    margin={'dense'}
                    disabled={blankFieldSelected}
                    style={{ width: filterConfig?.width || '100%' }}
                    variant={'outlined'}
                    onChange={onChange}
                    value={value}
                    InputProps={{
                        onMouseEnter: () => setHover(true),
                        onMouseLeave: () => setHover(false),
                        classes: {
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                            input: classes.input,
                        },
                        placeholder: blankFieldSelected ? '[blank fields]' : '',
                        endAdornment: (
                            <>
                                {hover && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Search blank fields">
                                            <IconButton
                                                size={'small'}
                                                className={css({ zIndex: 10 })}
                                                onClick={() => {
                                                    setBlankFieldSelected(true);
                                                    setValue('');
                                                    props.onChange && props.onChange(BLANK_CRITERION);
                                                }}
                                            >
                                                <EmptyIcon fontSize={'small'} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Reset">
                                            <IconButton
                                                size={'small'}
                                                className={css({ zIndex: 10 })}
                                                onClick={() => {
                                                    setBlankFieldSelected(false);
                                                    update(initValue?.text || '');
                                                }}
                                            >
                                                <Clear fontSize={'small'} />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export const StyledText = withStyles(styles as any, { withTheme: true })(TextFilter);
