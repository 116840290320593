import { restRPC } from 'utils/restrpc';
import {
    ImportExport,
    TradeAmendInput,
    TradeDirection,
    TradeRecordInput,
    TradeRecordInputWithACMFlag,
    TradeStatus,
    TradeType,
} from 'popcorn-js/tradeV2/index';
import { FindRequest, FindResponse, RetrieveHistoryRequestNew, RetrieveHistoryResponse } from 'popcorn-js/index';
import { Trade } from 'popcorn-js/tradeV2';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { Criteria } from 'popcorn-js/search';
import { Identifier, marshalIdentifier } from 'popcorn-js/search/identifier';

export type RetrieveRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type RetrieveResponse = {
    trade: Trade;
};

export type RecordExtensionRequest = {
    trades: TradeRecordInput[];
    cancellationTrades: TradeRecordInput[];
};

export type RecordDrawdownRequest = {
    trades: TradeRecordInput[];
    cancellationTrades: TradeRecordInput[];
};

export type RecordSpotForwardRequest = {
    trade: TradeRecordInput;
};

export type RecordCancellationRequest = {
    cancellationTrade: TradeRecordInput;
};

export type RecordACMRequest = {
    trade: TradeRecordInput;
    tradeType: TradeType;
};

export type RecordResponse = {
    /* */
};

export type AmendRequest = {
    identifier?: Identifier | string;
    trade: TradeAmendInput;
};

export type DeleteTransactionForeverRequest = {
    identifier?: Identifier | string;
};

export type RecordSwapRequest = {
    buyTrade?: TradeRecordInputWithACMFlag;
    sellTrade?: TradeRecordInputWithACMFlag;
};

export type DefaultRevenueRequest = {
    trade: Trade;
};

export type DefaultRevenueResponse = {
    trade: Trade;
};

export type AutoFillTradeDetails = {
    externalReference: string;
    direction: string;
    currencyPair: string;
    type: string;
    cancellation: boolean;
    processingOrgPartyCode: string;
};

export type AutoFillTradeFieldsRequest = {
    tradeDetails: AutoFillTradeDetails;
};

export type AutoFillTradeFieldsResponse = {
    trade: Trade;
    matchingTrade: boolean;
    allFieldsFilled: boolean;
    validated: boolean;
    revenueCalculated: boolean;
};

export interface HandlerI {
    ServiceProviderName: string;

    RecordSwap(request: RecordSwapRequest): Promise<RecordResponse>;

    RecordSpot(request: RecordSpotForwardRequest): Promise<RecordResponse>;

    RecordForward(request: RecordSpotForwardRequest): Promise<RecordResponse>;

    RecordExtension(request: RecordExtensionRequest): Promise<RecordResponse>;

    RecordDrawdown(request: RecordDrawdownRequest): Promise<RecordResponse>;

    RecordCancellation(request: RecordCancellationRequest): Promise<RecordResponse>;

    RecordACM(request: RecordACMRequest): Promise<RecordResponse>;

    Amend(request: AmendRequest): Promise<RecordResponse>;

    DeleteTransactionForever(request: DeleteTransactionForeverRequest): Promise<RecordResponse>;

    Find(request: FindRequest): Promise<FindResponse<Trade>>;

    Retrieve(request: RetrieveRequest): Promise<RetrieveResponse>;

    RetrieveHistory(request: RetrieveHistoryRequestNew): Promise<RetrieveHistoryResponse<Trade>>;

    CalculateDefaultRevenue(request: DefaultRevenueRequest): Promise<DefaultRevenueResponse>;

    AutoFillTradeFields(request: AutoFillTradeFieldsRequest): Promise<AutoFillTradeFieldsResponse>;
}

export const Handler: HandlerI = {
    ServiceProviderName: 'TradeV2-Handler',
    RecordSwap(request: RecordSwapRequest): Promise<RecordResponse> {
        return restRPC<RecordSwapRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.RecordSwap`,
            request,
        });
    },
    RecordSpot(request: RecordSpotForwardRequest): Promise<RecordResponse> {
        return restRPC<RecordSpotForwardRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.RecordSpot`,
            request,
        });
    },
    RecordForward(request: RecordSpotForwardRequest): Promise<RecordResponse> {
        return restRPC<RecordSpotForwardRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.RecordForward`,
            request,
        });
    },
    RecordExtension(request: RecordExtensionRequest): Promise<RecordResponse> {
        return restRPC<RecordExtensionRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.RecordExtension`,
            request,
        });
    },
    RecordDrawdown(request: RecordDrawdownRequest): Promise<RecordResponse> {
        return restRPC<RecordDrawdownRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.RecordDrawdown`,
            request,
        });
    },
    RecordCancellation(request: RecordCancellationRequest): Promise<RecordResponse> {
        return restRPC<RecordCancellationRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.RecordCancellation`,
            request,
        });
    },
    RecordACM(request: RecordACMRequest): Promise<RecordResponse> {
        return restRPC<RecordACMRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.RecordACM`,
            request,
        });
    },
    Amend(request: AmendRequest): Promise<RecordResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<AmendRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.Amend`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    DeleteTransactionForever(request: DeleteTransactionForeverRequest): Promise<RecordResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<DeleteTransactionForeverRequest, RecordResponse>({
            method: `${Handler.ServiceProviderName}.DeleteTransactionForever`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    Find(request: FindRequest): Promise<FindResponse<Trade>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindRequest, FindResponse<Trade>>({
            method: `${Handler.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    Retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<RetrieveRequest, RetrieveResponse>({
            method: `${Handler.ServiceProviderName}.Retrieve`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    RetrieveHistory(request: RetrieveHistoryRequestNew): Promise<RetrieveHistoryResponse<Trade>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<RetrieveHistoryRequestNew, RetrieveHistoryResponse<Trade>>({
            method: `${Handler.ServiceProviderName}.RetrieveHistory`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    CalculateDefaultRevenue(request: DefaultRevenueRequest): Promise<DefaultRevenueResponse> {
        return restRPC<DefaultRevenueRequest, DefaultRevenueResponse>({
            method: `${Handler.ServiceProviderName}.CalculateDefaultRevenue`,
            request: {
                ...request,
            },
        });
    },
    AutoFillTradeFields(request: AutoFillTradeFieldsRequest): Promise<AutoFillTradeFieldsResponse> {
        return restRPC<AutoFillTradeFieldsRequest, AutoFillTradeFieldsResponse>({
            method: `${Handler.ServiceProviderName}.AutoFillTradeFields`,
            request: {
                ...request,
            },
        });
    },
};

export const MockHandler: HandlerI = {
    ServiceProviderName: 'MockHandler',
    Find: (): Promise<FindResponse<Trade>> => {
        return new Promise<FindResponse<Trade>>((resolve) => {
            resolve({
                records: ([
                    {
                        id: '200001',
                        number: '2626',
                        counterpartyId: '',
                        counterparty: 'Go Bananas',
                        externalReference: '265ff4',
                        tradingPartyCode: '26547',
                        cancellation: false,
                        transactionID: 'jajlfljfla',
                        type: TradeType.SWAP,
                        parents: [],
                        acmParents: [],
                        date: undefined,
                        status: TradeStatus.OPEN,
                        currencyPair: 'USD/ZAR',
                        processingOrgPartyCode: 'partyCode',
                        importExport: ImportExport.IMPORT,
                        acm: true,
                        financialYear: '2020',
                        bank: 'Nedbank',
                        direction: TradeDirection.BUY,
                        maturityDate: undefined,
                        tradeDate: undefined,
                        availableBalance: 210552,
                        acmBalance: 23015,
                        capturedSpotRate: 2.0001,
                        interbankRate: 3.0017,
                        bankRate: 14.0514,
                        quoteAmount: 2547,
                        notionalAmount: 89271,
                        spotPrice: 123456,
                        forwardPoints: 12.0005,
                        allInRate: 5.0314,
                        effectiveRate: 1.2005,
                        netCashFlow: 95745,
                        realisedPnl: 632547,
                        trader: 'Florah',
                        traderOrganisation: 'theOrganisation',
                        portfolioManager: 'Manager',
                        relationshipManager: 'Someone',
                        notes: 'Nonsense',
                    },
                ] as unknown) as Trade[],
                total: 1,
            });
        });
    },
    RecordSwap: (): Promise<RecordResponse> => {
        return new Promise<RecordResponse>((resolve) => {
            resolve({});
        });
    },
    RecordSpot(): Promise<RecordResponse> {
        return new Promise<RecordResponse>((resolve) => {
            resolve({});
        });
    },
    RecordForward(): Promise<RecordResponse> {
        return new Promise<RecordResponse>((resolve) => {
            resolve({});
        });
    },
    RecordExtension(): Promise<RecordResponse> {
        return new Promise<RecordResponse>((resolve) => {
            resolve({});
        });
    },
    RecordDrawdown(): Promise<RecordResponse> {
        return new Promise<RecordResponse>((resolve) => {
            resolve({});
        });
    },
    RecordCancellation(): Promise<RecordResponse> {
        return new Promise<RecordResponse>((resolve) => {
            resolve({});
        });
    },
    RecordACM(): Promise<RecordResponse> {
        return new Promise<RecordResponse>((resolve) => {
            resolve({});
        });
    },
    Amend(): Promise<RecordResponse> {
        return new Promise<RecordResponse>((resolve) => {
            resolve({});
        });
    },
    DeleteTransactionForever(): Promise<RecordResponse> {
        return new Promise<RecordResponse>((resolve) => {
            resolve({});
        });
    },
    Retrieve(): Promise<RetrieveResponse> {
        return new Promise<RetrieveResponse>((resolve) => {
            resolve({} as RetrieveResponse);
        });
    },
    RetrieveHistory(): Promise<RetrieveHistoryResponse<Trade>> {
        return new Promise<RetrieveHistoryResponse<Trade>>((resolve) => {
            resolve({} as RetrieveHistoryResponse<Trade>);
        });
    },
    CalculateDefaultRevenue(): Promise<DefaultRevenueResponse> {
        return new Promise<DefaultRevenueResponse>((resolve) => {
            resolve({} as DefaultRevenueResponse);
        });
    },
    AutoFillTradeFields(): Promise<AutoFillTradeFieldsResponse> {
        return new Promise<AutoFillTradeFieldsResponse>((resolve) => {
            resolve({} as AutoFillTradeFieldsResponse);
        });
    },
};
