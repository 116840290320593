import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from 'theme/custom';
import { Cancel } from '@material-ui/icons';

interface CreateButtonProps {
    disabled: boolean;
    count: number;
    onClick: () => void;
}

const SelectedRowsButton: React.FC<CreateButtonProps> = (props: CreateButtonProps) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.wrapperText}>{props.count + ' Rows Selected'}</div>
            <div className={classes.wrapper}>
                <Cancel fontSize={'small'} onClick={props.onClick} />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '20px',
        height: '25px',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderWidth: '1.7px',
    },
    wrapper: {
        paddingLeft: '8px',
        paddingRight: '2px',
        paddingTop: '2px',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        fontSize: '12px',
    },
    wrapperText: {
        paddingLeft: '8px',
        paddingTop: '4px',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.primary.main,
        fontSize: '12px',
    },
}));

export default SelectedRowsButton;
