import React, { ReactElement, useState, useEffect, ChangeEvent } from 'react';
import { Dialog, TextField } from '@material-ui/core';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { useServiceSync } from 'hooks/useService';
import { FindRequest, FindResponse } from 'popcorn-js';
import { RoleTemplate } from 'popcorn-js/roleTemplate';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Handler as TemplateRecordkeeper } from 'popcorn-js/roleTemplate/handler';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';

export const NewFromTemplate = (props: {
    onSubmit: (t: RoleTemplate | undefined) => void;
    closeDialog: () => void;
    show: boolean;
}): ReactElement => {
    const { onSubmit, closeDialog, show } = props;

    const [roleTemplates, setRoleTemplates] = useState<RoleTemplate[]>([] as RoleTemplate[]);
    const [selected, setSelected] = useState<RoleTemplate | undefined>();

    const [roleTemplateFind] = useServiceSync<FindRequest, FindResponse<RoleTemplate>>(TemplateRecordkeeper.find);

    useEffect(() => {
        roleTemplateFind({}).then((response) => setRoleTemplates(response.records));
    }, []);

    return (
        <Dialog open={show}>
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            id: 'RoleConfiguration/NewFromTemplate/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Create from template',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'RoleConfiguration/NewFromTemplate/close',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Close',
                            onClick: closeDialog,
                        },
                    ],
                }}
            >
                <div
                    style={{
                        margin: '20px',
                    }}
                >
                    <Autocomplete
                        id={'RoleConfiguration/NewFromTemplate/select'}
                        options={roleTemplates}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={(_: ChangeEvent<unknown>, option: RoleTemplate | null) =>
                            setSelected(option || undefined)
                        }
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Role template" variant="outlined" />}
                    />
                    <div
                        style={{
                            textAlign: 'center',
                            margin: '15px',
                        }}
                    >
                        <BaseButton
                            id={'RoleConfiguration/NewFromTemplate/create'}
                            disabled={!selected}
                            onClick={() => onSubmit(selected)}
                            color={COLOR.ACTION}
                            variant={VARIANT.CONTAINED}
                            size={SIZE.MEDIUM}
                            text={'Select'}
                        />
                    </div>
                </div>
            </StandardCard>
        </Dialog>
    );
};
