import { CustomTheme } from 'theme/custom';
import { makeStyles } from '@material-ui/core';

type CSSProperties = React.CSSProperties & {
    '&:hover'?: React.CSSProperties;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectStyles = (theme: CustomTheme) => ({
    valueContainer: (styles: React.CSSProperties): React.CSSProperties => ({
        ...styles,
        border: 'none',
        padding: '0 4px',
    }),
    dropdownIndicator: (styles: React.CSSProperties): React.CSSProperties => ({
        ...styles,
        padding: '6px',
    }),
    option: (styles: React.CSSProperties, { isSelected }: { isSelected: boolean }): CSSProperties => ({
        ...styles,
        border: 'none',
        backgroundColor: isSelected ? theme.palette.primary.light : theme.palette.background.default,
        '&:hover': {
            backgroundColor: theme.palette.action.active,
        },
    }),
    control: (styles: CSSProperties, { menuIsOpen }: { menuIsOpen: boolean }): CSSProperties => {
        return {
            ...styles,
            minHeight: '1px',
            padding: '0',
            borderRadius: '0',
            border: 'none',
            backgroundColor: 'none',
            borderBottom: `1px solid ${menuIsOpen ? theme.palette.custom.text.label : '#4B4D5E'}`,
            boxShadow: 'none',
            '&:hover': {
                border: 'none',
                borderBottom: `1px solid ${menuIsOpen ? theme.palette.custom.text.label : 'white'}`,
            },
            color: theme.palette.text.primary,
        };
    },
    menu: (styles: CSSProperties): CSSProperties => ({
        ...styles,
        backgroundColor: theme.palette.background.default,
    }),
    input: (styles: CSSProperties): CSSProperties => ({
        ...styles,
        padding: '0',
        margin: '0',
        color: theme.palette.text.primary,
    }),
    singleValue: (styles: CSSProperties): CSSProperties => ({
        ...styles,
        color: theme.palette.text.primary,
    }),
});

export const useStyles = makeStyles((theme: CustomTheme) => ({
    fieldUnderline: {
        '&:before': {
            borderBottom: `1px solid ${theme.palette.text.secondary}`,
        },
    },
}));
