import React, {
    Dispatch,
    ReactElement,
    ChangeEvent,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
    useEffect,
    useRef,
} from 'react';
import { ACTION_BUTTON_TYPE, Item, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { Collapse, IconButton, makeStyles, useTheme } from '@material-ui/core';
import { StandardCard } from 'components/Card/Card';
import { CustomTheme } from 'theme/custom';
import { KeyboardReturnSharp, Warning } from '@material-ui/icons';
import { TradeValues } from './index';
import { BillingType, Trade, TradeType } from 'popcorn-js/tradeV2';
import { LightTextField } from './styledComponents';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { AppContext, AppContextT } from 'context';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { useServiceSync } from 'hooks/useService';
import {
    AutoFillTradeFieldsRequest,
    AutoFillTradeFieldsResponse,
    DefaultRevenueRequest,
    DefaultRevenueResponse,
    Handler,
} from 'popcorn-js/tradeV2/handler';
import { Actions } from './index';
import { parseISO } from 'date-fns';
import { Currency } from 'popcorn-js/currency';
import { debounce } from 'lodash';

export const TradePanelCard = ({
    children,
    disabled,
    showSimpleTradesHeader,
    index,
    trade,
    tradeType,
    uuid,
    onExpand,
    expanded,
    onRemove,
    showComplexTradesHeader,
    onClose,
    initAutoFill,
    optionExercise,
    tradeDispatch,
    changeAutoFillState,
    setRevenueFieldsInactive,
    changeAutoFillSuccessState,
    autoFillSuccessState,
    setIconButtonVisible,
    requireMarginField,
    allowRevenueCalculation,
}: {
    uuid: string;
    disabled?: boolean;
    showSimpleTradesHeader?: boolean;
    valid?: boolean;
    index?: number;
    children: ReactNode;
    onExpand?: () => void;
    expanded?: string;
    onRemove?: () => void;
    trade: TradeValues;
    tradeType: TradeType;
    showComplexTradesHeader?: boolean;
    onClose: () => void;
    initAutoFill: boolean;
    optionExercise?: boolean;
    tradeDispatch: Actions;
    changeAutoFillState: Dispatch<SetStateAction<boolean>>;
    setRevenueFieldsInactive: Dispatch<SetStateAction<boolean>>;
    changeAutoFillSuccessState: Dispatch<SetStateAction<boolean>>;
    autoFillSuccessState: boolean;
    setIconButtonVisible: Dispatch<SetStateAction<boolean>>;
    requireMarginField: Dispatch<SetStateAction<boolean>>;
    allowRevenueCalculation: boolean;
}): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const appContext = useContext<AppContextT>(AppContext);
    const clientPartyCode = appContext.party.partyCode;
    const [autoFillMode, setAutoFillMode] = useState<boolean>(initAutoFill);
    const [revenueCalculated, setRevenueCalculated] = useState<boolean>(false); // for manual capture
    const [newTrade, setNewTrade] = useState<Trade>();
    // notifications
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [confirmationMethod, setConfirmationMethod] = useState<undefined | (() => void)>(undefined);
    const [cancelMethod, setCancelMethod] = useState<undefined | (() => void)>(undefined);
    const currencyPairs = appContext.assignedCurrencyPairs || ([] as CurrencyPair[]);
    const currencies = appContext.currencies || [];

    // services
    const [autoFill] = useServiceSync<AutoFillTradeFieldsRequest, AutoFillTradeFieldsResponse>(
        Handler.AutoFillTradeFields,
    );
    const [CalculateDefaultRevenue] = useServiceSync<DefaultRevenueRequest, DefaultRevenueResponse>(
        Handler.CalculateDefaultRevenue,
    );

    const disableRevenueFields = () => {
        if (!allowRevenueCalculation) {
            tradeDispatch.setBillingType(uuid, '');
            setRevenueFieldsInactive(true);
            setRevenueCalculated(false);
            requireMarginField(false);
            tradeDispatch.updateMarginNotesRequirement(false);
        }
    };

    // AutoFill service call
    const autoFillFields = async () => {
        try {
            const result = await autoFill({
                tradeDetails: {
                    externalReference: trade.externalReference ? trade.externalReference : '',
                    direction: trade.direction,
                    currencyPair: trade.currencyPair?.name ? trade.currencyPair.name : '',
                    type: trade.tradeType,
                    cancellation: trade.cancellation,
                    processingOrgPartyCode: appContext.parentPartyCode || '',
                },
            });
            if (result.matchingTrade) {
                setWarningMessage(
                    'The external reference number is matched to an existing trade. Click\nCancel to exit trade capture, or Continue to change the external\nreference number',
                );
                setCancelMethod(() => () => {
                    setWarningMessage(undefined);
                    setCancelMethod(undefined);
                    onClose();
                });
                setConfirmationMethod(() => () => {
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                });
            } else if (!result.allFieldsFilled) {
                setWarningMessage(
                    'The system was unable to retrieve some/all of the fields. Click\nCancel to exit trade capture, or Continue to capture trade fields',
                );
                setCancelMethod(() => () => {
                    setWarningMessage(undefined);
                    setCancelMethod(undefined);
                    onClose();
                });
                setConfirmationMethod(() => () => {
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setAutoFillMode(false);
                    changeAutoFillState(false);
                    setRevenueFieldsInactive(false);
                });
            } else if (!result.validated) {
                setWarningMessage(
                    'The selected direction and/or currency is incorrect. Click Cancel to\nexit trade capture, or Continue to proceed with the auto-correction.',
                );
                setCancelMethod(() => () => {
                    setWarningMessage(undefined);
                    setCancelMethod(undefined);
                    onClose();
                });
                setConfirmationMethod(() => () => {
                    if (!result.revenueCalculated) {
                        setWarningMessage(
                            'Failed to retrieve pricing information. Click Cancel to\nexit trade capture, or Continue to input pricing fields.',
                        );
                        setConfirmationMethod(() => () => {
                            setWarningMessage(undefined);
                            setCancelMethod(() => () => {
                                setSuccessMessage(undefined);
                            });
                            setConfirmationMethod(undefined);
                            setAutoFillMode(false);
                            setNewTrade(result.trade);
                            changeAutoFillState(false);
                            changeAutoFillSuccessState(true);
                            disableRevenueFields();
                            setSuccessMessage('Autofill success!');
                        });
                    } else {
                        setCancelMethod(() => () => {
                            setSuccessMessage(undefined);
                        });
                        setWarningMessage(undefined);
                        setAutoFillMode(false);
                        setNewTrade(result.trade);
                        changeAutoFillState(false);
                        changeAutoFillSuccessState(true);
                        disableRevenueFields();
                        setSuccessMessage('Autofill success!');
                    }
                });
            } else {
                setCancelMethod(() => () => {
                    setWarningMessage(undefined);
                    setCancelMethod(undefined);
                    onClose();
                });
                if (!result.revenueCalculated) {
                    setWarningMessage(
                        'Failed to retrieve pricing information. Click Cancel to\nexit trade capture, or Continue to input pricing fields.',
                    );
                    setConfirmationMethod(() => () => {
                        setWarningMessage(undefined);
                        setCancelMethod(() => () => {
                            setSuccessMessage(undefined);
                        });
                        setConfirmationMethod(undefined);
                        setAutoFillMode(false);
                        setNewTrade(result.trade);
                        changeAutoFillState(false);
                        changeAutoFillSuccessState(true);
                        disableRevenueFields();
                        setSuccessMessage('Autofill success!');
                    });
                } else {
                    setCancelMethod(() => () => {
                        setSuccessMessage(undefined);
                    });
                    setWarningMessage(undefined);
                    setAutoFillMode(false);
                    setNewTrade(result.trade);
                    changeAutoFillState(false);
                    changeAutoFillSuccessState(true);
                    disableRevenueFields();
                    setSuccessMessage('Autofill success!');
                }
            }
        } catch (e) {
            setWarningMessage(
                'The external reference number cannot be matched to an existing\nunmatched bank confirmation. Click Cancel to change the external\nreference number or Continue to capture trade with the same\nexternal reference number.',
            );
            setCancelMethod(() => () => {
                setWarningMessage(undefined);
                setCancelMethod(undefined);
            });
            setConfirmationMethod(() => () => {
                setWarningMessage(undefined);
                setConfirmationMethod(undefined);
                setAutoFillMode(false);
                changeAutoFillState(false);
                setRevenueFieldsInactive(false);
                disableRevenueFields();
            });
        }
    };

    useEffect(() => {
        if (
            newTrade &&
            newTrade.tradeDate &&
            newTrade.maturityDate &&
            newTrade.notionalAmount &&
            newTrade.quoteAmount &&
            newTrade.direction &&
            newTrade.currencyPair &&
            newTrade.bank
        ) {
            tradeDispatch.autoFillDates(uuid, [
                parseISO((newTrade.tradeDate as unknown) as string),
                parseISO((newTrade.maturityDate as unknown) as string),
            ]);
            if (trade.direction != newTrade.direction) {
                tradeDispatch.setDirection(uuid, newTrade.direction);
            }
            const currencyPair = currencyPairs.find((c) => c.name === newTrade.currencyPair) as CurrencyPair;
            const localCurrency = appContext?.localCurrency as Currency;
            const fxCurrency = currencies.find((c) => c.isoCode === currencyPair.baseCurrency) as Currency;
            if (currencyPair && trade.currencyPair?.name != newTrade.currencyPair) {
                tradeDispatch.setCurrencyPair(uuid, currencyPair);
                newTrade.direction === 'BUY'
                    ? tradeDispatch.setBuyCurrency(uuid, fxCurrency)
                    : tradeDispatch.setBuyCurrency(uuid, localCurrency);
                newTrade.direction === 'SELL'
                    ? tradeDispatch.setSellCurrency(uuid, fxCurrency)
                    : tradeDispatch.setSellCurrency(uuid, localCurrency);
            }
            tradeDispatch.setNotionalAmount(uuid, newTrade.notionalAmount.toFixed(2));
            tradeDispatch.setQuoteAmount(uuid, newTrade.quoteAmount.toFixed(2));
            tradeDispatch.setBank(uuid, newTrade.bank);
            // Revenue fields if available and/or revenue calculation is allowed
            if (allowRevenueCalculation) {
                tradeDispatch.setBankRate(uuid, newTrade.bankRate ? newTrade.bankRate.toFixed(6) : '');
                tradeDispatch.setIntermediaryMargin(
                    uuid,
                    newTrade.intermediaryMargin ? newTrade.intermediaryMargin.toFixed(4) : '',
                    true,
                );
                if (newTrade.billingType) {
                    if (newTrade.billingType === BillingType.ClientBilling) {
                        tradeDispatch.setBillingType(uuid, BillingType.ClientBilling);
                    } else if (newTrade.billingType === BillingType.BankBilling) {
                        tradeDispatch.setBillingType(uuid, BillingType.BankBilling);
                    } else {
                        tradeDispatch.setBillingType(uuid, '');
                    }
                }
                tradeDispatch.setClientFee(uuid, newTrade.clientFee ? newTrade.clientFee.toFixed(2) : '');
                tradeDispatch.setAdminFee(uuid, newTrade.adminFee ? newTrade.adminFee.toFixed(2) : '');
                tradeDispatch.setBilledToBank(uuid, newTrade.billedToBank ? newTrade.billedToBank.toFixed(2) : '');
            }
        }
    }, [autoFillSuccessState]);

    // Calculate revenue for manual capture
    const calculateDefaultRevenue = async (requestTrade: Trade) => {
        try {
            const result = await CalculateDefaultRevenue({
                trade: requestTrade,
            });
            if (result.trade) {
                setNewTrade(result.trade);
                tradeDispatch.setBillingType(uuid, result.trade.billingType || '', true);
                tradeDispatch.setAdminFee(uuid, result.trade.adminFee?.toFixed(2) || '0');
                tradeDispatch.setBilledToBank(uuid, result.trade.billedToBank?.toFixed(2) || '0');
                tradeDispatch.setClientFee(uuid, result.trade.clientFee?.toFixed(2) || '0');
                tradeDispatch.setBankRate(uuid, result.trade.bankRate?.toFixed(6) || '0');
                tradeDispatch.setIntermediaryMargin(uuid, result.trade.intermediaryMargin?.toFixed(4) || '0', true);
                setRevenueFieldsInactive(false);
                setRevenueCalculated(true);
                setIconButtonVisible(false);
            }
        } catch (e) {
            setRevenueFieldsInactive(true);
            tradeDispatch.setBillingType(uuid, '');
            tradeDispatch.setAdminFee(uuid, '');
            tradeDispatch.setBilledToBank(uuid, '');
            tradeDispatch.setClientFee(uuid, '');
            tradeDispatch.setBankRate(uuid, '');
            tradeDispatch.setIntermediaryMargin(uuid, '', false);
            setRevenueCalculated(false);
            requireMarginField(false);
            tradeDispatch.updateMarginNotesRequirement(false);
            setIconButtonVisible(true);
        }
    };

    const debouncedServiceCall = useRef(
        debounce((requestTrade: Trade) => {
            calculateDefaultRevenue(requestTrade).finally();
        }, 2000),
    ).current;

    // Trigger the CalculateDefaultRevenue service on field changes
    useEffect(() => {
        if (
            trade &&
            Number(trade.dealRate) > 0 &&
            trade.bank &&
            trade.notionalAmount &&
            allowRevenueCalculation &&
            !optionExercise
        ) {
            const requestTrade: Trade = {
                notionalAmount: Number(trade.notionalAmount),
                dealRate: Number(trade.dealRate),
                bank: trade.bank,
                tradingPartyCode: clientPartyCode,
                type: trade.tradeType,
                cancellation: trade.cancellation,
            };
            calculateDefaultRevenue(requestTrade).finally();
        }
    }, [trade.bank, !autoFillSuccessState, !autoFillMode]);
    useEffect(() => {
        if (
            trade &&
            Number(trade.dealRate) > 0 &&
            trade.bank &&
            trade.notionalAmount &&
            allowRevenueCalculation &&
            !optionExercise
        ) {
            const requestTrade: Trade = {
                notionalAmount: Number(trade.notionalAmount),
                dealRate: Number(trade.dealRate),
                bank: trade.bank,
                tradingPartyCode: clientPartyCode,
                type: trade.tradeType,
                cancellation: trade.cancellation,
            };
            debouncedServiceCall(requestTrade);
        }
    }, [trade.notionalAmount, trade.quoteAmount, !autoFillSuccessState, !autoFillMode]);

    useEffect(() => {
        debouncedServiceCall.cancel();
    }, [debouncedServiceCall]);

    // Update the requirement for the margin notes field
    useEffect(() => {
        if (allowRevenueCalculation) {
            if ((autoFillSuccessState || revenueCalculated) && !optionExercise) {
                const needed =
                    trade.marginNotes === '' &&
                    (Number(trade.intermediaryMargin).toFixed(4) !== Number(newTrade?.intermediaryMargin).toFixed(4) ||
                        Number(trade.adminFee).toFixed(2) !== Number(newTrade?.adminFee).toFixed(2) ||
                        trade.billingType != newTrade?.billingType);
                requireMarginField(needed);
                tradeDispatch.updateMarginNotesRequirement(needed);
            }
        }
    }, [trade.intermediaryMargin, trade.billingType, trade.adminFee, trade.marginNotes, revenueCalculated]);

    const externalRefItem: Item[] = [
        {
            type: ITEM_VARIATION.ELEMENT,
            element: (
                <div>
                    <span className={classes.referenceTitle}>{getExternalRefText(showSimpleTradesHeader)}</span>
                </div>
            ),
            id: 'externalRefTitle',
            hide: expanded !== uuid,
        },
        {
            type: ITEM_VARIATION.ELEMENT,
            element: (
                <div>
                    <LightTextField
                        error={!!trade.fieldErrors.externalReference}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            tradeDispatch.updateTradeExtRef(uuid, event.target.value);
                        }}
                        disabled={autoFillSuccessState}
                        value={trade.externalReference}
                        style={{ paddingTop: '4px' }}
                        onKeyPress={(event) => {
                            if (event.key == 'Enter' && autoFillMode && trade && trade.externalReference) {
                                autoFillFields().finally();
                            }
                        }}
                        InputProps={{
                            endAdornment:
                                autoFillMode && trade && trade.externalReference ? (
                                    <IconButton
                                        tabIndex={-1}
                                        size={'small'}
                                        onClick={() => {
                                            if (autoFillMode) {
                                                autoFillFields().finally();
                                            }
                                        }}
                                        disabled={!autoFillMode}
                                    >
                                        <KeyboardReturnSharp />
                                    </IconButton>
                                ) : (
                                    ''
                                ),
                        }}
                    />
                </div>
            ),
            id: 'externalReference',
            hide: expanded !== uuid,
        },
    ];

    const itemsLeft: Item[] = showSimpleTradesHeader
        ? [
              {
                  type: ITEM_VARIATION.ELEMENT,
                  element: (
                      <div>
                          <span className={classes.title}>Trade Details</span>
                      </div>
                  ),
                  id: 'TradePanelCardV2/external-reference',
              },
          ]
        : showComplexTradesHeader
        ? [
              {
                  type: ITEM_VARIATION.TITLE,
                  text: getTitle(trade.cancellation || false, tradeType, expanded === uuid, index),
                  id: 'title',
                  variant: 'title2',
              },
              {
                  type: ITEM_VARIATION.ELEMENT,
                  element: (
                      <div className={classes.collapsedTitle}>
                          <span className={classes.collapsedInfo}>
                              {trade.externalReference ? truncate(trade.externalReference) : '-'}
                          </span>
                          <span className={classes.collapsedInfo}>{trade.dealRate ? trade.dealRate : '-'}</span>
                          <span className={classes.collapsedInfo}>
                              {trade.cancellation
                                  ? trade.sellAmount
                                      ? `${trade.sellCurrency?.isoCode} ${trade.sellAmount}`
                                      : '-'
                                  : trade.buyAmount
                                  ? `${trade.buyCurrency?.isoCode} ${trade.buyAmount}`
                                  : '-'}
                          </span>
                      </div>
                  ),
                  id: 'TradePanel/external-ref-collapsed',
                  hide: expanded === uuid,
              },
              ...externalRefItem,
          ]
        : [
              {
                  type: ITEM_VARIATION.TITLE,
                  text: 'Cancellation',
                  id: 'cancellationTitle',
                  variant: 'title2',
              },
          ];

    const itemsRight: Item[] = showSimpleTradesHeader
        ? externalRefItem
        : showComplexTradesHeader
        ? [
              {
                  type: ITEM_VARIATION.ELEMENT,
                  element: <Warning style={{ color: theme.palette.error.main }} />,
                  hide: trade.valid,
                  id: 'warning',
              },
              {
                  type: ITEM_VARIATION.ICON_BUTTON,
                  icon: ACTION_BUTTON_TYPE.DELETE_PERMANENTLY,
                  onClick: () => {
                      onRemove ? onRemove() : undefined;
                  },
                  disabled,
                  id: 'remove',
                  hide: !onRemove,
              },
              {
                  type: ITEM_VARIATION.ICON_BUTTON,
                  icon: expanded === uuid ? ACTION_BUTTON_TYPE.COLLAPSE : ACTION_BUTTON_TYPE.EXPAND,
                  onClick: () => onExpand && onExpand(),
                  disabled,
                  id: 'expand',
                  hide: !onExpand,
              },
          ]
        : externalRefItem;

    return (
        <StandardCard
            cardHeaderProps={{
                fatter: true,
                itemsLeft: itemsLeft,
                itemsRight: itemsRight,
            }}
        >
            <Collapse in={expanded === uuid} className={classes.content}>
                {expanded === uuid && children}
            </Collapse>
            <NotificationSweetAlert
                onClose={cancelMethod}
                onConfirm={confirmationMethod}
                successMessage={successMessage}
                warningMessage={warningMessage}
            />
        </StandardCard>
    );
};

const getTitle = (cancellation: boolean, tradeType: TradeType, expanded: boolean, index?: number): string => {
    if (cancellation) {
        return expanded ? `Cancellation Leg ${(index || 0) + 1}` : `Can ${(index || 0) + 1}`;
    }
    switch (tradeType) {
        case TradeType.DRAWDOWN:
            return expanded ? `Drawdown Leg ${(index || 0) + 1}` : `Dd ${(index || 0) + 1}`;
        case TradeType.EXTENSION:
            return expanded ? `Extension Leg ${(index || 0) + 1}` : `Ext ${(index || 0) + 1}`;
        case TradeType.CANCELLATION:
            return `Cancellation Leg`;
        default:
            return 'Unknown';
    }
};

const truncate = (t: string): string => {
    if (t.length > 6) {
        return `${t.substr(0, 2)}...${t.substr(t.length - 4, 4)}`;
    }
    return t;
};

const getExternalRefText = (showSimpleTradesHeader: boolean | undefined): string => {
    return showSimpleTradesHeader == true ? 'External Reference:' : 'Ext. Ref.:';
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    collapsedInfo: {
        color: theme.palette.text.secondary,
        fontSize: '14px',
        marginRight: theme.spacing(2),
    },
    collapsedTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        color: theme.palette.custom.paperExtended.paper4,
        marginRight: '10px',
        marginLeft: theme.spacing(3),
    },
    referenceTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        color: theme.palette.secondary.main,
        marginRight: '10px',
    },
    dropdownContainer: {
        paddingBottom: theme.spacing(1),
    },
}));
