/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Dialog, FormControl, makeStyles } from '@material-ui/core';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { CustomTheme } from 'theme/custom';
import { CFCDeposit } from 'popcorn-js/cfcDeposit';
import { StyledSelect } from 'components/Select/StyledSelect';

import { ImportExport } from 'popcorn-js';
import { getMidDay, HexToRGBA } from 'utils';
import { useService } from 'hooks/useService';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { parseISO } from 'date-fns';
import { AppContext, AppContextT } from 'context';
import moment, { now } from 'moment';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { Criteria, CriteriaType } from 'popcorn-js/search';
import { Recordkeeper as InvoiceRecordkeeper } from 'popcorn-js/invoice/recordkeeper';
import { StyledAsyncSelect } from 'components/Select/StyledAsyncSelect';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import { SystemDateFormat } from 'constants/formats';

export const AddNewCFC = (props: { closeDialog: () => void; show: boolean }): ReactElement => {
    const { closeDialog, show } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [cfcDeposit, setCFCDeposit] = useState<CFCDeposit>({} as CFCDeposit);
    const [invalidFields, setInvalidFields] = useState<Record<string, string | undefined>>();
    const [creating, setCreating] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [defaultInvoiceOptions, setDefaultInvoiceOptions] = useState<{ value: string; label: string }[]>([]);
    const { cfcDepositHandler } = useContext<ServiceContextT>(ServiceContext);
    const handleChange = (field: string, value: string | number | Date | undefined) => {
        setCFCDeposit({
            ...cfcDeposit,
            [field]: value,
        });

        setInvalidFields({
            ...invalidFields,
            [field]: undefined,
        });
    };
    const handleHideAlert = () => {
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        closeDialog();
    };
    const [{ response: createResponse, error: createError }, setCreateRequest] = useService(
        undefined,
        cfcDepositHandler.Create,
    );
    useEffect(() => {
        if (createError && createError !== '' && creating) {
            setErrorMessage(createError);
        }
        if (createResponse && createResponse.cfcDeposit && createResponse.cfcDeposit.id) {
            setSuccessMessage('CFC Account successfully created');
        }
    }, [createError, creating, createResponse]);
    useEffect(() => {
        if (creating && cfcDeposit) {
            setCreateRequest({ cfcDeposit });
            setCreating(false);
        }
    }, [cfcDeposit, setCreating, creating, setCreateRequest]);
    const onSave = async () => {
        const _cfcDeposit = { ...cfcDeposit };
        (_cfcDeposit as Record<string, unknown>)['partyCode'] = appContext.party.partyCode;
        (_cfcDeposit as Record<string, unknown>)['financialYear'] = 'CURRENT';
        setCFCDeposit(_cfcDeposit);
        setCreating(true);
    };
    const generateInvoiceOptions = async (inputValue: string): Promise<{ value: string; label: string }[]> => {
        const criteria: Criteria = [
            {
                type: CriteriaType.TextCriterion,
                text: inputValue,
                field: 'externalReference',
            },
            {
                type: CriteriaType.TextCriterion,
                text: 'Export',
                field: 'importExport',
            },
            {
                type: CriteriaType.ExactCriterion,
                text: appContext.party?.partyCode || '',
                field: 'partyCode',
            },
        ];
        const query = {
            offset: 0,
            limit: 10,
        };

        try {
            const findResponse = await InvoiceRecordkeeper.find({ criteria, query });
            return (findResponse.records || []).map((b: any) => ({
                value: b.id,
                label: b.externalReference,
            }));
        } catch (e) {
            throw e.message || e;
        }
    };
    const initiateLinking = async () => {
        const result = await generateInvoiceOptions('');
        setDefaultInvoiceOptions(result);
    };
    return (
        <Dialog maxWidth={'lg'} fullWidth={true} open={show}>
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            id: 'SettlementInstruction/AddNewSI/title',
                            type: ITEM_VARIATION.TITLE,
                            text: `Add CFC Deposit`,
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/AddNewSI/close',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Close',
                            onClick: closeDialog,
                        },
                    ],
                }}
            >
                <div className={classes.container}>
                    <div className={classes.item}>
                        <BaseTextField
                            id="accountName"
                            label="Account Name"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                handleChange('accountName', e.target.value)
                            }
                            value={cfcDeposit.accountName}
                        />
                    </div>
                    <div className={classes.item}>
                        <div className={classes.selectLabel}>Deposit Date</div>
                        <BaseTextField
                            id="depositDate"
                            type={'date'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                handleChange('depositDate', getMidDay(parseISO(e.target.value)))
                            }
                            value={
                                cfcDeposit.depositDate
                                    ? moment(cfcDeposit.depositDate).format(SystemDateFormat)
                                    : moment(now()).format(SystemDateFormat)
                            }
                        />
                    </div>
                    <div className={classes.item} onClick={initiateLinking}>
                        <FormControl
                            aria-describedby="invoiceExternalReference"
                            className={classes.select}
                            id="invoiceExternalReference"
                        >
                            <sup className={classes.selectLabel}>Invoice External Ref.</sup>
                            <StyledAsyncSelect
                                defaultOptions={defaultInvoiceOptions}
                                loadOptions={generateInvoiceOptions}
                                onChange={(selected: { value: string; label: string }) =>
                                    handleChange('invoiceExternalReference', selected.label)
                                }
                                value={{
                                    value: cfcDeposit.invoiceExternalReference || '',
                                    label: cfcDeposit.invoiceExternalReference || '',
                                }}
                                noOptionsMessage={'Start Typing...'}
                            />
                        </FormControl>
                    </div>
                    <div className={classes.item}>
                        <FormControl aria-describedby="importExport" className={classes.select} id="importExport">
                            <sup className={classes.selectLabel}>Portfolio</sup>
                            <StyledSelect
                                onChange={(e): void => {
                                    handleChange('importExport', e?.value);
                                }}
                                options={portfolioOptions}
                                value={{
                                    label: cfcDeposit.importExport || '',
                                    value: cfcDeposit.importExport || '',
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className={classes.item}>
                        <BaseTextField
                            id="fxAmount"
                            label="FX Amount"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                handleChange('fxAmount', e.target.value)
                            }
                            value={cfcDeposit.fxAmount}
                        />
                    </div>
                    <div className={classes.item}>
                        <BaseTextField
                            id="reference"
                            label="Reference (Optional)"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                handleChange('reference', e.target.value)
                            }
                            value={cfcDeposit.reference}
                        />
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'left',
                        marginTop: '10px',
                        marginLeft: '25px',
                        marginBottom: '25px',
                    }}
                >
                    <BaseButton
                        id={'CreateSIButton/Create'}
                        disabled={false}
                        variant={VARIANT.CONTAINED}
                        color={COLOR.DEFAULT}
                        size={SIZE.SMALL}
                        onClick={onSave}
                        text={'Create'}
                    />
                </div>
            </StandardCard>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                successMessage={successMessage}
            />
        </Dialog>
    );
};
const useStyles = makeStyles((theme: CustomTheme) => ({
    item: {
        margin: theme.spacing(),
        height: '56px',
        flex: 1,
        paddingTop: 0,
    },
    container: {
        marginLeft: '20px',
        display: 'flex',
    },
    select: {
        width: '100%',
        height: '100%',
        paddingTop: 0,
    },
    selectLabel: {
        fontSize: 12,
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
}));

const portfolioOptions = [
    {
        value: ImportExport.IMPORT,
        label: ImportExport.IMPORT,
    },
    {
        value: ImportExport.EXPORT,
        label: ImportExport.EXPORT,
    },
];
