import React from 'react';
import { ScaleLoader as Spinner } from 'react-spinners';
import { ReactElement } from 'react';
import { StyleObject } from 'styletron-react';

// ComponentLevelLoader is a loader than can be implemented nested within components. It does not render over the whole of the viewport.
// 100% width and default height of 200px
export const ComponentLevelLoader = (props: {
    color?: string;
    isLoading?: boolean;
    style?: StyleObject;
}): ReactElement | null => {
    if (props.isLoading) {
        return (
            <div
                style={{
                    height: '200px',
                    ...props.style,
                    zIndex: 100,
                    position: 'relative',
                    display: 'grid',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <Spinner color={props.color || '#FFF'} loading={props.isLoading} />
                </div>
            </div>
        );
    }
    return null;
};
