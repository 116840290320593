import React, { ChangeEvent, ReactElement, useContext } from 'react';
import { Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { AppContext, AppContextT } from 'context';
import classNames from 'classnames';

const useStyles = makeStyles((theme: CustomTheme) => ({
    primaryBackground: {
        backgroundColor: theme.palette.custom.stellcapBrand1.main,
    },
    secondaryBackground: {
        backgroundColor: theme.palette.custom.stellcapBrand2.main,
    },
    reducedHeight: {
        height: '42px',
        minHeight: '42px',
    },
}));

export enum COLOR {
    CONTEXT = 'CONTEXT',
    SECONDARY = 'SECONDARY',
}

export const StyledTabs = (props: {
    children?: ReactElement[];
    onChange: (_event: ChangeEvent<unknown>, value: number) => void;
    value: number;
    color?: COLOR;
}): ReactElement => {
    const { children, onChange, value, color } = props;
    const appContext = useContext<AppContextT>(AppContext);
    const classes = useStyles();

    return (
        <Tabs
            className={classes.reducedHeight}
            classes={{ root: classes.reducedHeight }}
            TabIndicatorProps={{
                className: classNames({
                    [classes.primaryBackground]:
                        color === COLOR.CONTEXT &&
                        appContext.currentContext?.partyCode !== appContext.originalContext?.partyCode,
                    [classes.secondaryBackground]:
                        color === COLOR.CONTEXT &&
                        appContext.currentContext?.partyCode === appContext.originalContext?.partyCode,
                }),
            }}
            onChange={(e, v) => onChange(e, v)}
            value={value}
        >
            {children}
        </Tabs>
    );
};
