import React, { ReactElement, useContext } from 'react';
import { InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';
import { RoleTemplate } from 'popcorn-js/roleTemplate';
import { AppContextT, AppContext } from 'context';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import _ from 'lodash';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'auto',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    textField: {},
    inputLabel: {
        '&$inputLabelFocused': {
            color: theme.palette.text.secondary,
        },
    },
    inputLabelFocused: {},
    inputUnderline: {
        '&&:hover:before': {
            borderBottomColor: theme.palette.text.primary,
        },
        '&:before': {
            borderBottomColor: theme.palette.text.secondary,
        },
        '&:after': {
            borderBottomColor: theme.palette.text.primary,
        },
    },
    input: {
        color: HexToRGBA(theme.palette.text.primary, 0.8),
    },
    inputHover: {},
    listWrapper: {
        display: 'flex',
        height: '50vh',
        flexDirection: 'row',
        columnGap: '16px',
        justifyContent: 'space-around',
    },
    list: {
        height: '100%',
        width: '45%',
        overflowY: 'auto',
    },
    cardHeaderRoot: {
        backgroundColor: HexToRGBA(theme.palette.text.primary, 0.1),
        padding: theme.spacing(1),
    },
}));

export const RoleTemplateDetail = (props: {
    disabled?: boolean;
    template?: RoleTemplate;
    onChange: (template: RoleTemplate) => void;
}): ReactElement => {
    const classes = useStyles();
    const { template, disabled } = props;

    const appContext = useContext<AppContextT>(AppContext);

    const permissions = [...(appContext.permissions || []), ...(appContext.viewPermissions || [])];

    const [permissionList, setPermissionList] = React.useState<{ available: string[]; assigned: string[] }>({
        available: [],
        assigned: [],
    });

    const [search, setSearch] = React.useState<string | undefined>();

    const getBasePermissionList = (
        _template: RoleTemplate | undefined,
    ): { available: string[]; assigned: string[] } => ({
        available: _.difference(permissions, _template?.permissions || []),
        assigned: _template?.permissions ? [..._template.permissions] : [],
    });

    const filterPermissionList = (
        _search: string | undefined,
        _permissionList: { available: string[]; assigned: string[] },
    ): { available: string[]; assigned: string[] } => {
        if (!search) {
            return { available: [..._permissionList.available], assigned: [..._permissionList.assigned] };
        }
        const { available, assigned } = _permissionList;
        let filteredAvailablePermissions = available.filter((permission) =>
            permission.toUpperCase().includes(_search?.toUpperCase() || ''),
        );
        filteredAvailablePermissions = filteredAvailablePermissions.filter(
            (permission) => !template?.permissions?.includes(permission),
        );
        const filteredAssignedPermissions = assigned.filter((permission) =>
            permission.toUpperCase().includes(_search?.toUpperCase() || ''),
        );
        return { available: [...filteredAvailablePermissions], assigned: [...filteredAssignedPermissions] };
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _search = event ? (event.target.value as string) : undefined;
        setSearch(_search);
        const _basePermissionList = getBasePermissionList(template);
        const _permissionList = filterPermissionList(_search, _basePermissionList);
        setPermissionList(_permissionList);
    };

    React.useEffect(() => {
        if (!template || !template.permissions) {
            return;
        }
        const _basePermissionList = getBasePermissionList(template);
        const _permissionList = filterPermissionList(search, _basePermissionList);
        setPermissionList(_permissionList);
    }, [template]);

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.onChange({ ...template, [name]: event.target.value });
    };

    const revokePermission = (index: number) => {
        if (disabled) {
            return;
        }
        const assigned = [...permissionList.assigned];
        const permission = assigned[index];

        if (template?.permissions) {
            const list = template.permissions;
            const idx = list.indexOf(permission);
            list?.splice(idx, 1);
            props.onChange({ ...template, permissions: [...list] });
        }
    };

    const grantPermission = (index: number) => {
        if (disabled) {
            return;
        }
        const available = [...permissionList.available];
        const permission = available[index];

        if (template?.permissions) {
            const list = template.permissions;
            list.splice(0, 0, permission);
            props.onChange({ ...template, permissions: [...list] });
        }
    };

    const InputProps = {
        classes: {
            underline: classes.inputUnderline,
            input: classes.input,
        },
    };

    const InputLabelProps = {
        classes: {
            root: classes.inputLabel,
            focused: classes.inputLabelFocused,
        },
    };

    const { available, assigned } = permissionList;

    return (
        <div className={classes.root}>
            {template && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '16px',
                        boxSizing: 'border-box',
                    }}
                >
                    <div>
                        <BaseTextField
                            id={`RoleTemplateDetail/name`}
                            InputLabelProps={InputLabelProps}
                            InputProps={InputProps}
                            className={classes.textField}
                            disabled={disabled}
                            fullWidth
                            label="Role template name"
                            margin="dense"
                            onChange={handleChange('name')}
                            value={template.name}
                        />
                    </div>
                    <div>
                        <Typography>Permissions</Typography>
                        <BaseTextField
                            id={`RoleTemplateDetail/search`}
                            InputLabelProps={InputLabelProps}
                            InputProps={{
                                ...InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            className={classes.textField}
                            fullWidth
                            label="Search"
                            onChange={handleSearch}
                            value={search}
                        />
                    </div>
                    <div className={classes.listWrapper}>
                        <div className={classes.list}>
                            <Typography>Available</Typography>
                            <List component={'nav'}>
                                {available.map((p: string, idx: number) => (
                                    <ListItem onClick={() => grantPermission(idx)} key={idx} button>
                                        <ListItemText primary={p} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        <div className={classes.list}>
                            <Typography>Assigned</Typography>
                            <List component={'nav'}>
                                {assigned.map((p: string, idx: number) => (
                                    <ListItem onClick={() => revokePermission(idx)} key={idx} button>
                                        <ListItemText primary={p} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
