import React, { ReactElement } from 'react';

export const CircleIcon = (props: { active: boolean; color1: string; color2?: string }): ReactElement => {
    const { active, color1, color2 } = props;
    return (
        <>
            {!active && color2 && (
                <>
                    <svg height="16" width="8">
                        <circle cx="8" cy="8" r="6" fill={color1} />
                    </svg>
                    <svg height="16" width="8">
                        <circle cx="0" cy="8" r="6" fill={color2} />
                    </svg>
                </>
            )}
            {active && color2 && (
                <>
                    <svg height="16" width="8">
                        <circle cx="8" cy="8" r="6" fill={color1} stroke="white" />
                    </svg>
                    <svg height="16" width="8">
                        <circle cx="0" cy="8" r="6" fill={color2} stroke="white" />
                    </svg>
                </>
            )}
            {!active && !color2 && (
                <>
                    <svg height="16" width="16">
                        <circle cx="8" cy="8" r="6" fill={color1} />
                    </svg>
                </>
            )}
            {active && !color2 && (
                <>
                    <svg height="16" width="16">
                        <circle cx="8" cy="8" r="6" fill={color1} stroke="white" />
                    </svg>
                </>
            )}
        </>
    );
};
