import { Option } from 'popcorn-js/options';
import { FieldType, ItemEntry } from 'components/history';
import React, { ReactElement } from 'react';
import { displayDate, processUnixDateForViewing } from 'utils';
import { Divider } from '@material-ui/core';
import { SystemDateTimeFormat } from 'constants/formats';

const styles = {
    root: {
        flexGrow: 1,
        rowGap: '8px',
        boxShadow: '',
        zIndex: 1,
    },
};

export const OptionsFields = (option: Option, fieldType: FieldType, changedFields = [] as string[]): ReactElement => {
    return (
        <div>
            <div style={styles.root}>
                <ItemEntry
                    changed={changedFields.includes('number')}
                    fieldType={fieldType}
                    label={'Option number'}
                    value={option.number || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('externalReference')}
                    fieldType={fieldType}
                    label={'External Reference'}
                    value={option.externalReference || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('tradingPartyCode')}
                    fieldType={fieldType}
                    label={'Trading Party'}
                    value={option.tradingPartyCode}
                />
                <ItemEntry
                    changed={changedFields.includes('transactionID')}
                    fieldType={fieldType}
                    label={'transactionID'}
                    value={option.transactionID}
                />
                <ItemEntry
                    changed={changedFields.includes('type')}
                    fieldType={fieldType}
                    label={'type'}
                    value={option.type}
                />
                <ItemEntry
                    changed={changedFields.includes('date')}
                    fieldType={fieldType}
                    label={'Date'}
                    value={option.captureDate ? displayDate((option.captureDate as unknown) as string) : ''}
                />
                <ItemEntry
                    changed={changedFields.includes('status')}
                    fieldType={fieldType}
                    label={'Option Status'}
                    value={option.status}
                />
                <ItemEntry
                    changed={changedFields.includes('currencyPair')}
                    fieldType={fieldType}
                    label={'Currency Pair'}
                    value={option.currencyPair}
                />
                <ItemEntry
                    changed={changedFields.includes('importExport')}
                    fieldType={fieldType}
                    label={'Import/Export'}
                    value={option.importExport}
                />
                <ItemEntry
                    changed={changedFields.includes('financialYear')}
                    fieldType={fieldType}
                    label={'Financial Year'}
                    value={option.financialYear}
                />
                <ItemEntry
                    changed={changedFields.includes('bank')}
                    fieldType={fieldType}
                    label={'bank'}
                    value={option.bank}
                />
                <ItemEntry
                    changed={changedFields.includes('direction')}
                    fieldType={fieldType}
                    label={'direction'}
                    value={option.direction}
                />
                <ItemEntry
                    changed={changedFields.includes('expiryDate')}
                    fieldType={fieldType}
                    label={'expiryDate'}
                    value={option.expiryDate ? displayDate((option.expiryDate as unknown) as string) : ''}
                />
                <ItemEntry
                    changed={changedFields.includes('tradeDate')}
                    fieldType={fieldType}
                    label={'tradeDate'}
                    value={option.tradeDate ? displayDate((option.tradeDate as unknown) as string) : ''}
                />
                <ItemEntry
                    changed={changedFields.includes('capturedSpotRate')}
                    fieldType={fieldType}
                    label={'capturedSpotRate'}
                    value={option.capturedSpotRate}
                />
                <ItemEntry
                    changed={changedFields.includes('interbankRate')}
                    fieldType={fieldType}
                    label={'interbankRate'}
                    value={option.interbankRate}
                />
                <ItemEntry
                    changed={changedFields.includes('bankRate')}
                    fieldType={fieldType}
                    label={'bankRate'}
                    value={option.bankRate}
                />
                <ItemEntry
                    changed={changedFields.includes('quoteAmount')}
                    fieldType={fieldType}
                    label={'quoteAmount'}
                    value={option.quoteAmount}
                />
                <ItemEntry
                    changed={changedFields.includes('notionalAmount')}
                    fieldType={fieldType}
                    label={'notionalAmount'}
                    value={option.notionalAmount}
                />
                <ItemEntry
                    changed={changedFields.includes('strikePrice')}
                    fieldType={fieldType}
                    label={'strikePrice'}
                    value={option.strikePrice}
                />
                <ItemEntry
                    changed={changedFields.includes('effectiveRate')}
                    fieldType={fieldType}
                    label={'effectiveRate'}
                    value={option.effectiveRate}
                />
                <ItemEntry
                    changed={changedFields.includes('trader')}
                    fieldType={fieldType}
                    label={'trader'}
                    value={option.trader}
                />
                <ItemEntry
                    changed={changedFields.includes('traderOrganisation')}
                    fieldType={fieldType}
                    label={'traderOrganisation'}
                    value={option.traderOrganisation}
                />
                <ItemEntry
                    changed={changedFields.includes('notes')}
                    fieldType={fieldType}
                    label={'notes'}
                    value={option.notes}
                />
                <Divider
                    light
                    style={{
                        height: '2px',
                        margin: '20px 20px',
                    }}
                />

                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(option.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(option.auditEntry || {}).action || '-'}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={
                        (option.auditEntry || {}).time
                            ? processUnixDateForViewing((option.auditEntry || {}).time, SystemDateTimeFormat)
                            : ''
                    }
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(option.auditEntry || {}).version || 0}
                />
            </div>
        </div>
    );
};
