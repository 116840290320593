import * as React from 'react';
import { ReactElement } from 'react';
import { makeStyles, Tooltip as MUITooltip, withStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { themedUseStyletron as useStyletron } from 'theme/basewebStyleUtils';
import { Help as HelpIcon } from '@material-ui/icons';

const useHedgeCardStyles = makeStyles((theme: CustomTheme) => ({
    helpIcon: {
        width: '12px',
        height: '12px',
        marginTop: '3px',
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.text.primary,
        },
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
    },
}));
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(MUITooltip);

export enum COLOR {
    WHITE = 'WHITE',
    YELLOW = 'YELLOW',
    GREY = 'GREY',
    UNAVAILABLE = 'UNAVAILABLE',
}
export enum FONTSIZE {
    SET = 'SET',
    TEMP = 'TEMP',
}

export const HeaderField = ({
    color,
    heading,
    value,
    helperText,
    headerText,
    fontSize,
}: {
    color?: COLOR;
    heading?: string;
    value?: string;
    helperText?: string;
    title?: string;
    headerText?: string;
    fontSize?: FONTSIZE;
}): ReactElement => {
    const classes = useHedgeCardStyles();
    const [css, theme] = useStyletron();

    let textColor = '';

    switch (color) {
        case COLOR.WHITE:
            textColor = theme.custom.text.primary;
            break;
        case COLOR.YELLOW:
            textColor = theme.custom.text.accent;
            break;
        case COLOR.GREY:
            textColor = theme.text.secondary;
            break;
        case COLOR.UNAVAILABLE:
            textColor = theme.action.disabled;
    }

    let textSize = '';

    switch (fontSize) {
        case FONTSIZE.SET:
            textSize = '16px';
            break;
        case FONTSIZE.TEMP:
            textSize = '12px';
    }

    return (
        <div>
            <div
                className={css({
                    fontSize: '12px',
                    lineHeight: '12px',
                    fontWeight: 'bold',
                    marginRight: '9px',
                    marginBottom: '8px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: theme.custom.text.label,
                })}
            >
                <div
                    className={css({
                        fontSize: textSize,
                        fontWeight: 'bold',
                        marginRight: '9px',
                        display: 'flex',
                        marginTop: '9px',
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: theme.custom.text.primary,
                    })}
                >
                    {headerText}{' '}
                </div>
                {heading}
                <p style={{ width: '5px' }} />
                {helperText && (
                    <LightTooltip placement={'right-end'} title={helperText}>
                        <HelpIcon className={classes.helpIcon} />
                    </LightTooltip>
                )}
            </div>
            <div
                className={css({
                    fontSize: textSize,
                    lineHeight: '24px',
                    fontWeight: 'bold',
                    color: textColor,
                    marginLeft: '8px',
                })}
            >
                {value}
            </div>
        </div>
    );
};
