export enum AuditActionType {
    CREATED = 'CREATED',
    MODIFIED = 'MODIFIED',
    DELETED = 'DELETED',
    RESTORED = 'RESTORED',
}
export interface AuditEntry {
    userId?: string;
    username?: string;
    action?: AuditActionType;
    time?: number;
    version?: number;
}

export interface Entity {
    auditEntry?: AuditEntry;
}
