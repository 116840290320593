import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';

export type GetSpotDateForRequest = {
    currencyPair?: string;
    date: number;
};

export type GetSpotDateForResponse = {
    spotDate: number;
};

export interface DateKeeperI {
    ServiceProviderName: string;
    GetSpotDateFor(request: GetSpotDateForRequest): Promise<GetSpotDateForResponse>;
}

export const DateKeeper: DateKeeperI = {
    ServiceProviderName: 'FxCalendar-DateKeeper',
    GetSpotDateFor(request: GetSpotDateForRequest): Promise<GetSpotDateForResponse> {
        return jsonRPC<GetSpotDateForRequest, GetSpotDateForResponse>({
            url: config.get('apiURL'),
            method: `${DateKeeper.ServiceProviderName}.GetSpotDateFor`,
            request,
        });
    },
};
