import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { PartyCodeIdentifier, IDIdentifier } from 'popcorn-js/search/identifier';
import { Counterparty } from 'popcorn-js/counterparty';
import { Currency } from 'popcorn-js/currency';
import Summary from 'views/SettlementInstruction/Summary';
import { restRPC } from 'utils/restrpc';
import { ImportExport } from '../index';

export interface Amount {
    costCurrencyAmount: number;
    avgCostingRate: number;
    costingRateAvgTotal: number;
    currencyAmount: number;
}

export interface Item {
    perCurrency: Record<string, Amount>;
    total: Amount;
}

export interface Aggregation {
    categoryName: string;
    startDate: number;
    endDate: number;

    invoiceUnRealisedImport: Item;
    invoiceUnRealisedExport: Item;
    invoiceUnRealisedAll: Item;

    invoiceOverdueImport: Item;
    invoiceOverdueExport: Item;
    invoiceOverdueAll: Item;

    invoiceTotalImport: Item;
    invoiceTotalExport: Item;
    invoiceTotalAll: Item;

    tradeRealised: Amount;
    allTradeUnrealised: Amount;

    openTradeBalanceAll: number;
    openTradeBalanceImport: number;
    openTradeBalanceExport: number;

    netCashFlow: number;
}

export interface Total {
    totalAmountInCurrency: number;
    totalAmountInCostCurrency: number;
}

export interface Totals {
    purchaseDue: Total;
    salesDue: Total;
    purchaseOverDue: Total;
    salesOverDue: Total;
    purchaseHedge: Total;
    salesHedge: Total;
    purchaseSalesHedge: Total;
    invoicesDue: Total;
    ordersDue: Total;
    totalOpenTradeBalances: number;
    salesTradeOpenBalances: number;
    purchaseTradeOpenBalances: number;
}

export interface NetTradeCashFlow {
    month: string;
    realisedTotal: number;
    pendingTotal: number;
    extensions: number;
    cancellations: number;
    drawDowns: number;
    forecasted: number;
}

// GenerateMonthViewCashFlowReport
export interface GenerateMonthViewCashFlowReportResponse {
    excelData: string;
    totals: Totals;
    netTradeCashFlowsThisMonth: NetTradeCashFlow;
    aggregations: Array<Aggregation>;
}

export interface GenerateCashFlowReportRequest {
    startDate?: number;
    endDate?: number;
}

export interface GenerateOpenHedgeReportRequest {
    excludeOptions?: boolean;
    excludeFECs?: boolean;
}

export interface GenerateOpenHedgeReportResponse {
    excelData: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GenerateClientFinancialYearEndReportRequest {
    /* empty*/
}

export interface GenerateClientFinancialYearEndReportResponse {
    excelData: string;
}

export interface GenerateNetCashflowReportResponse {
    excelData: string;
}

// GenerateWeekViewCashFlowReport
export interface GenerateWeekViewCashFlowReportResponse {
    excelData: string;
    totals: Totals;
    netTradeCashFlowsThisMonth: NetTradeCashFlow;
    aggregations: Array<Aggregation>;
}

// GenerateMonthViewExposureReportForCurrency
export interface GenerateMonthViewExposureReportForCurrencyResponse {
    excelData: string;
    totals: Totals;
    aggregations: Array<Aggregation>;
}

interface GenerateMonthViewExposureReportForCurrencyRequest {
    currency: string;
    startDate?: number;
    endDate?: number;
}

// GenerateWeekViewExposureReportForCurrency
export interface GenerateWeekViewExposureReportForCurrencyResponse {
    excelData: string;
    totals: Totals;
    aggregations: Array<Aggregation>;
}

export interface GenerateWeekViewExposureReportForCurrencyRequest {
    currency: string;
    startDate: number;
    endDate: number;
}

export interface SingleRateResult {
    withCostingRate: number;
    withCaptureRate: number;
    withSpotRate: number;
}

export interface BidAsk {
    bid: number;
    ask: number;
}

export interface ImportExportReportItem {
    import: number;
    export: number;
}

export interface ImportExportRateResult {
    withCostingRate: ImportExportReportItem;
    withCaptureRate: ImportExportReportItem;
    withSpotRate: ImportExportReportItem;
}

export type GeneratePositionReportForCurrencyRequest = {
    currency: string;
    download?: boolean;
};
export type GeneratePortfolioReportRequest = {
    currencies: string[];
};
export type GenerateExposureBreakdownCurrencyRequest = {
    currency: string;
    importExport: ImportExport;
};
export type GenerateExposureBreakdownCurrencyResponse = {
    invoiceOrderTotal: number;
    invoiceOrderTotalAvgRate: number;
    hedgesTotal: number;
    hedgesTotalAvgRate: number;
    plannedSalesTotal: number;
    plannedSalesAvgRate: number;
    planedSalesRealised: number;
    planedSalesHedged: number;
};
export type GeneratePositionReportForCurrencyResponse = {
    currencyPosition: number;
    totalPnL: SingleRateResult;
    spotRate: BidAsk;
    oneMonthRate: BidAsk;
    twoMonthRate: BidAsk;
    threeMonthRate: BidAsk;
    sixMonthRate: BidAsk;
    totalRealisedPnL: SingleRateResult;
    totalUnrealisedPnL: SingleRateResult;
    ordersInvoicesUnpaidTotal: ImportExportReportItem;
    ordersInvoicesAverageRate: ImportExportRateResult;
    ordersInvoicesUnrealisedProfitAndLoss: ImportExportRateResult;
    tradeTotal: ImportExportReportItem;
    optionTotal: ImportExportReportItem;
    tradeOptionsTotal: ImportExportReportItem;
    tradeAverageRate: ImportExportReportItem;
    optionAverageRate: ImportExportReportItem;
    tradeOptionAverageRate: ImportExportReportItem;
    tradeUnrealisedProfitAndLoss: ImportExportReportItem;
    tradeOptionUnrealisedProfitAndLoss: ImportExportReportItem;
    cfcBalance: ImportExportReportItem;
    cfcAvgRate: ImportExportReportItem;
    cfcUnrealisedProfitAndLoss: ImportExportReportItem;
    netTotal: ImportExportReportItem;
    netAverageRate: ImportExportRateResult;
    netUnrealisedP: ImportExportRateResult;
    netPercentageHedged: ImportExportReportItem;
    acmPosition: ImportExportReportItem;
    averageDaysOutstanding: ImportExportReportItem;
    netUnrealisedProfitAndLoss: ImportExportRateResult;
    excelData: string;
};
export type GeneratePortfolioReportResponse = {
    excelData: string;
};

export interface AsyncGenerateConsolidatedReportResponse {
    jobID: string;
}

export type GenerateCoverageRatioReportRequest = {
    startDate: number;
    endDate: number;
};
export type MonthTotal = {
    month: string;
    exposure: number;
    hedgeAmount: number;
    hedgePercentage: number;
    avgCostingRate: number;
    //
    hedgeAmountWithOptions: number;
    hedgePercentageWithOptions: number;
};

export type CurrencyTotal = {
    currency: string;
    currencySign: string;
    exposure: number;
    hedgeAmount: number;
    hedgePercentage: number;
    avgCostingRate: number;
    monthTotals: MonthTotal[];
    //
    hedgeAmountWithOptions: number;
    hedgePercentageWithOptions: number;
};
export type ImportExportTotal = {
    totalsImport: CurrencyTotal[];
    totalsExport: CurrencyTotal[];
};
export type GenerateCoverageRatioReportResponse = {
    totals: ImportExportTotal;
};

export type GenerateMaturityLadderRequest = {
    /* nothing */
};

export type TotalOnDate = {
    date: string;
    currencyTotal: Record<string, number>;
};

export type GenerateMaturityLadderResponse = {
    totals: TotalOnDate[];
};

export type GenerateConsolidatedExposureReportRequest = {
    subsidiaries: PartyCodeIdentifier[] | string[];
    holdingClientPartyCode: PartyCodeIdentifier | string;
};

export type Exposure = {
    clientName: string;
    invoiceNumber: string;
    currency: Currency;
    amountDue: number;
    subsidiaryLocalCurrency: Currency;
    subsidiaryLocalCurrencyAmountDue: number;
    holdingClientLocalCurrency: Currency;
    holdingClientLocalCurrencyAmountDue: number;
    orderDate: number;
    dueDate: number;
    counterParty: Counterparty;
    notes: string;
};

export type GenerateConsolidatedExposureReportResponse = {
    holdingClientLocalCurrency: Currency;
    exposures: Exposure[];
};

export type GenerateConsolidatedTradesReportRequest = {
    subsidiaries: PartyCodeIdentifier[] | string[];
    holdingClientPartyCode: PartyCodeIdentifier | string;
};

export type Hedge = {
    clientName: string;
    localCurrency: Currency;
    currency: Currency;
    externalReference: string;
    notionalAmount: number;
    amountInParentLocalCurrency: number;
    quoteAmount: number;
    allInRate: number;
    tradeDate: string;
    maturityDate: string;
    bank: string;
    notes: string;
};

export type GenerateConsolidatedTradesReportResponse = {
    hedges: Hedge[];
    holdingClientLocalCurrency: Currency;
};

export type GenerateConsolidatedPositionReportRequest = {
    subsidiaries: PartyCodeIdentifier[] | string[];
    holdingClientPartyCode: PartyCodeIdentifier | string;
};

export type Position = {
    clientName: string;
    localCurrency: Currency;
    currency: Currency;
    exposureAmount: number;
    exposureAmountInParentLocalCurrency: number;
    hedgesAmount: number;
    percentageHedged: number;
    netExposure: number;
    netExposureInParentLocalCurrency: number;
    exposure0To30Days: number;
    exposure31To60Days: number;
    exposure61To90Days: number;
    exposure91PlusDays: number;
};

type Summary = {
    clientName: string;
    percentageHedged: number;
    prevMonthExposureHoldingClientCurrency: number;
    currentMonthExposureHoldingClientCurrency: number;
    currencyMovement: number;
    fluctuation: number;
};

export type ExposurePerCurrency = {
    currency: Currency;
    exposureTotalInHoldingClientLocalCurrency: number;
};

export type GenerateConsolidatedSummaryReportRequest = {
    subsidiaries: PartyCodeIdentifier[] | string[];
    holdingClientPartyCode: PartyCodeIdentifier | string;
};

export type GenerateConsolidatedSummaryReportResponse = {
    holdingClientLocalCurrency: Currency;
    summaries: Summary[];
    exposurePerCurrency: ExposurePerCurrency[];
};

type GenerateConsolidatedPositionReportResponse = {
    holdingClientLocalCurrency: Currency;
    positions: Position[];
};

export type GenerateConsolidatedReportRequest = {
    subsidiaries: PartyCodeIdentifier[];
    holdingClientPartyCode: PartyCodeIdentifier | string;
    userID: IDIdentifier | string;
};

export type GenerateConsolidatedReportResponse = {
    consolidatedExposureReport: GenerateConsolidatedExposureReportResponse;
    consolidatedTradesReport: GenerateConsolidatedTradesReportResponse;
    consolidatedPositionReport: GenerateConsolidatedPositionReportResponse;
    consolidatedSummaryReport: GenerateConsolidatedSummaryReportResponse;
    excelData: string;
};

export enum TYPE {
    CONSOLIDATED_SUMMARY_REPORT_TYPE = 'ConsolidatedSummaryReportType',
    CONSOLIDATED_EXPOSURE_REPORT_TYPE = 'ConsolidatedExposureReportType',
    CONSOLIDATED_TRADES_REPORT_TYPE = 'ConsolidatedTradesReportType',
    CONSOLIDATED_POSITION_REPORT_TYPE = 'ConsolidatedPositionReportType',
    CONSOLIDATED_IMPERIAL_REPORT_TYPE = 'ConsolidatedImperialReportType',
}

export enum JOB_TRIGGER {
    AD_HOC_JOB_TRIGGER = 'AdHoc',
    MONTH_END_JOB_TRIGGER = 'MonthEnd',
}

export type WrappedReport = {
    id: string;
    report: GenerateConsolidatedReportResponse;
    type: TYPE;
    partyCode: string;
    timeStamp: number;
    jobID: string;
    jobTrigger: JOB_TRIGGER;
};

export type GenerateHedgeReportRequest = {
    /* empty */
};

export interface GenerateHedgeReportResponse {
    percentageHedgedImports: number;
    percentageHedgedExports: number;
    grossImportExposureAtSpot: number;
    grossExportExposureAtSpot: number;
}

export type GeneratePnLReportRequest = {
    /* empty */
};
export type GeneratePnLReportResponse = {
    totalPnLWithCostingRate: number;
    totalRealisedPnl: number;
    totalUnRealisedPnl: number;
};

export type GenerateTradeCashFlowReportRequest = {
    /* empty */
};
export type GenerateTradeCashFlowReportResponse = {
    committed: number;
    maturing: number;
    extended: number;
};

export type GeneratePnLBreakdownReportRequest = {
    /* empty */
};
export type GeneratePnLBreakdownReportResponse = {
    // invoice realised PnL
    importInvoiceRealisedPnLWithCostingRate: number;
    importInvoiceRealisedPnLWithCaptureRate: number;
    exportInvoiceRealisedPnLWithCostingRate: number;
    exportInvoiceRealisedPnLWithCaptureRate: number;
    // cancellation trade realised PnL
    importCancellationTradeRealisedPnL: number;
    exportCancellationTradeRealisedPnL: number;
    // total realised PnL
    importTotalRealisedPnLWithCostingRate: number;
    importTotalRealisedPnLWithCaptureRate: number;
    exportTotalRealisedPnLWithCostingRate: number;
    exportTotalRealisedPnLWithCaptureRate: number;
    // total unrealised PnL
    importTotalUnrealisedPnLWithCostingRate: number;
    importTotalUnrealisedPnLWithCaptureRate: number;
    exportTotalUnrealisedPnLWithCostingRate: number;
    exportTotalUnrealisedPnLWithCaptureRate: number;
    // total PnLs
    importTotalPnLWithCostingRate: number;
    importTotalPnLWithCaptureRate: number;
    exportTotalPnLWithCostingRate: number;
    exportTotalPnLWithCaptureRate: number;
};

export type GeneratePreviousFinYearRevenueRequest = {
    /* empty */
};

export interface GeneratePreviousFinYearRevenueResponse {
    total: number;
}

export type GenerateDetailedFinYearRevenueRequest = {
    /* empty */
};

export interface GenerateDetailedFinYearRevenueResponse {
    currentMTD: number;
    previousMTD: number;
    currentPrevMonth: number;
    previousPrevMonth: number;
    currentYTD: number;
    previousYTD: number;
    currentAveMonthly: number;
    previousAveMonthly: number;
}

const Generator = {
    ServiceProviderName: 'Report-Generator',

    async GeneratePnLBreakdownReport(
        request: GeneratePnLBreakdownReportRequest,
    ): Promise<GeneratePnLBreakdownReportResponse> {
        return await restRPC<GeneratePnLBreakdownReportRequest, GeneratePnLBreakdownReportResponse>({
            method: `${Generator.ServiceProviderName}.GeneratePnLBreakdownReport`,
            request,
        });
    },
    async GenerateTradeCashFlowReport(
        request: GenerateTradeCashFlowReportRequest,
    ): Promise<GenerateTradeCashFlowReportResponse> {
        return await restRPC<GenerateTradeCashFlowReportRequest, GenerateTradeCashFlowReportResponse>({
            method: `${Generator.ServiceProviderName}.GenerateTradeCashFlowReport`,
            request,
        });
    },
    async GeneratePnLReport(request: GeneratePnLReportRequest): Promise<GeneratePnLReportResponse> {
        return await restRPC<GeneratePnLReportRequest, GeneratePnLReportResponse>({
            method: `${Generator.ServiceProviderName}.GeneratePnLReport`,
            request,
        });
    },
    async GenerateExposureBreakdownCurrency(
        request: GenerateExposureBreakdownCurrencyRequest,
    ): Promise<GenerateExposureBreakdownCurrencyResponse> {
        return await restRPC<GenerateExposureBreakdownCurrencyRequest, GenerateExposureBreakdownCurrencyResponse>({
            method: `${Generator.ServiceProviderName}.GenerateExposureBreakdownCurrency`,
            request,
        });
    },
    async GenerateHedgeReport(request: GenerateHedgeReportRequest): Promise<GenerateHedgeReportResponse> {
        return await restRPC<GenerateHedgeReportRequest, GenerateHedgeReportResponse>({
            method: `${Generator.ServiceProviderName}.GenerateHedgeReport`,
            request,
        });
    },

    async GenerateMaturityLadderReport(
        request: GenerateMaturityLadderRequest,
    ): Promise<GenerateMaturityLadderResponse> {
        return await jsonRPC<GenerateMaturityLadderRequest, GenerateMaturityLadderResponse>({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GenerateMaturityLadderReport`,
            request,
        });
    },

    async GenerateCoverageRatioReport(
        request: GenerateCoverageRatioReportRequest,
    ): Promise<GenerateCoverageRatioReportResponse> {
        return await jsonRPC<GenerateCoverageRatioReportRequest, GenerateCoverageRatioReportResponse>({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GenerateCoverageRatioReport`,
            request,
        });
    },

    async GeneratePortfolioReport(request: GeneratePortfolioReportRequest): Promise<GeneratePortfolioReportResponse> {
        return await jsonRPC<GeneratePortfolioReportRequest, GeneratePortfolioReportResponse>({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GeneratePortfolioReport`,
            request,
        });
    },

    async GeneratePositionReportForCurrency(
        request: GeneratePositionReportForCurrencyRequest,
    ): Promise<GeneratePositionReportForCurrencyResponse> {
        return await jsonRPC<GeneratePositionReportForCurrencyRequest, GeneratePositionReportForCurrencyResponse>({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GeneratePositionReportForCurrency`,
            request,
        });
    },

    async GenerateMonthViewCashFlowReport(
        request: GenerateCashFlowReportRequest,
    ): Promise<GenerateMonthViewCashFlowReportResponse> {
        return await jsonRPC<GenerateCashFlowReportRequest, GenerateMonthViewCashFlowReportResponse>({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GenerateMonthViewCashFlowReport`,
            request,
        });
    },

    async GenerateWeekViewCashFlowReport(
        request: GenerateCashFlowReportRequest,
    ): Promise<GenerateWeekViewCashFlowReportResponse> {
        return await jsonRPC<GenerateCashFlowReportRequest, GenerateWeekViewCashFlowReportResponse>({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GenerateWeekViewCashFlowReport`,
            request,
        });
    },

    async GenerateMonthViewExposureReportForCurrency(
        request: GenerateMonthViewExposureReportForCurrencyRequest,
    ): Promise<GenerateMonthViewExposureReportForCurrencyResponse> {
        return await jsonRPC<
            GenerateMonthViewExposureReportForCurrencyRequest,
            GenerateMonthViewExposureReportForCurrencyResponse
        >({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GenerateMonthViewExposureReportForCurrency`,
            request,
        });
    },

    async GenerateWeekViewExposureReportForCurrency(
        request: GenerateWeekViewExposureReportForCurrencyRequest,
    ): Promise<GenerateWeekViewExposureReportForCurrencyResponse> {
        return await jsonRPC({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GenerateWeekViewExposureReportForCurrency`,
            request,
        });
    },

    async GenerateConsolidatedExposureReport(request: {
        holdingClientPartyCode: string;
        subsidiaries: string[];
    }): Promise<GenerateConsolidatedExposureReportResponse> {
        return await jsonRPC({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GenerateConsolidatedExposureReport`,
            request,
        });
    },

    async AsyncGenerateConsolidatedReport(request: {
        holdingClientPartyCode?: string;
        subsidiaries?: string[];
    }): Promise<AsyncGenerateConsolidatedReportResponse> {
        return await jsonRPC({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.AsyncGenerateConsolidatedReport`,
            request,
        });
    },

    async GenerateOpenHedgesReport(request: GenerateOpenHedgeReportRequest): Promise<GenerateOpenHedgeReportResponse> {
        return await jsonRPC({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GenerateOpenHedgesReport`,
            request,
        });
    },

    async GenerateNetCashflowReport(request: Record<string, never>): Promise<GenerateNetCashflowReportResponse> {
        return await jsonRPC({
            url: config.get('apiURL'),
            method: `${Generator.ServiceProviderName}.GenerateNetCashflowReport`,
            request,
        });
    },

    async GeneratePreviousFinYearRevenueReport(
        request: GeneratePreviousFinYearRevenueRequest,
    ): Promise<GeneratePreviousFinYearRevenueResponse> {
        return await restRPC<GeneratePreviousFinYearRevenueRequest, GeneratePreviousFinYearRevenueResponse>({
            method: `${Generator.ServiceProviderName}.GeneratePreviousFinYearRevenueReport`,
            request,
        });
    },

    async GenerateDetailedFinYearRevenueReport(
        request: GenerateDetailedFinYearRevenueRequest,
    ): Promise<GenerateDetailedFinYearRevenueResponse> {
        return await restRPC<GenerateDetailedFinYearRevenueRequest, GenerateDetailedFinYearRevenueResponse>({
            method: `${Generator.ServiceProviderName}.GenerateDetailedFinYearRevenueReport`,
            request,
        });
    },

    async GenerateClientFinancialYearEndReport(
        request: GenerateClientFinancialYearEndReportRequest,
    ): Promise<GenerateClientFinancialYearEndReportResponse> {
        return await restRPC<GenerateClientFinancialYearEndReportRequest, GenerateClientFinancialYearEndReportResponse>(
            {
                method: `${Generator.ServiceProviderName}.GenerateClientFinancialYearEndReport`,
                request,
            },
        );
    },
};

export default Generator;
