/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import DefaultHandler, { GetRateCacheRequest } from 'popcorn-js/rick/handler';
import { saveAs } from 'file-saver';
import RatesCache from './RatesCache';
import styles from './styles';
import {
    Downloader,
    DownloadHistoricalRatesRequest,
    DownloadHistoricalRatesResponse,
    DownloadRequest,
    DownloadResponse,
} from 'popcorn-js/rick/downloader';
import { useServiceSync } from 'hooks/useService';

const SystemHome = (): ReactElement => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState(undefined);
    const [ratesCache, setRatesCache] = useState<GetRateCacheRequest | undefined>();

    const [downloadRatesCache] = useServiceSync<DownloadRequest, DownloadResponse>(Downloader.downloadRatesCache);
    const [downloadDailyRatesExcel] = useServiceSync<DownloadRequest, DownloadResponse>(
        Downloader.downloadDailyRatesExcel,
    );
    const [downloadDailyRatesExcelSnapshot] = useServiceSync<DownloadRequest, DownloadResponse>(
        Downloader.downloadDailyRatesExcelSnapshot,
    );
    const [downloadHistoricalRates] = useServiceSync<DownloadHistoricalRatesRequest, DownloadHistoricalRatesResponse>(
        Downloader.downloadHistoricalRates,
    );
    const [downloadDailyRatesPDF] = useServiceSync<DownloadRequest, DownloadResponse>(Downloader.downloadDailyRatesPDF);

    useEffect(() => {
        getRatesCache().finally();
    }, []);

    const exportInstances = async () => {
        try {
            const cacheRatesResult = await downloadRatesCache({} as DownloadRequest);
            // convert base64 to byte array
            const binData = atob(cacheRatesResult.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'DailyRates_Full.xlsx');
            setSuccessMessage('Rick Cache Exported');
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    const exportDailyRatesExcel = async () => {
        try {
            const dailyRatesExcel = await downloadDailyRatesExcel({} as DownloadRequest);
            // convert base64 to byte array
            const binData = atob(dailyRatesExcel.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'DailyRates_Extract.xlsx');
            setSuccessMessage('Daily Rates saved');
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    const exportDailyRatesExcelSnapshot = async () => {
        try {
            const dailyRatesExcel = await downloadDailyRatesExcelSnapshot({} as DownloadRequest);
            // convert base64 to byte array
            const binData = atob(dailyRatesExcel.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'Daily Rates Snapshot.xlsx');
            setSuccessMessage('Daily Rates saved');
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    const exportHistoricalRates = async () => {
        try {
            const historicalRates = await downloadHistoricalRates({} as DownloadHistoricalRatesRequest);

            const binData = atob(historicalRates.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'Historical_Rates_export.xlsx');
            setSuccessMessage('Rick Cache Exported');
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    const exportDailyRatesPDF = async () => {
        try {
            const dailyRatesPDF = await downloadDailyRatesPDF({} as DownloadRequest);

            const binData = atob(dailyRatesPDF.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/pdf; charset=UTF-8',
            });
            saveAs(blob, 'DailyRates_Importer.pdf');
            setSuccessMessage('Daily Rates saved');
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    const getRatesCache = async () => {
        try {
            const cacheRatesResult = await DefaultHandler.GetRateCache({} as GetRateCacheRequest);
            setRatesCache(cacheRatesResult);
        } catch (error) {
            setErrorMessage(error.message || error);
        }
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    return (
        <div style={{ height: '100%', overflowY: 'auto' }} id="systemHomeRoot">
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                onConfirm={confirmationMethod}
                successMessage={successMessage}
                warningMessage={warningMessage}
            />
            <Grid container>
                <Grid item xs={12}>
                    <RatesCache
                        exportInstances={exportInstances}
                        exportDailyRatesExcel={exportDailyRatesExcel}
                        exportDailyRatesExcelSnapshot={exportDailyRatesExcelSnapshot}
                        exportHistoricalRates={exportHistoricalRates}
                        exportDailyRatesPDF={exportDailyRatesPDF}
                        instances={ratesCache}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default withStyles(styles as any)(SystemHome);
