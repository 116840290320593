export enum Category {
    Purchase = 'Purchase',
    PurchaseNotes = 'PurchaseNotes',
    Sales = 'Sales',
    SalesNotes = 'SalesNotes',
}

export type File = {
    name: string;
    data: string | ArrayBuffer | null | undefined;
    ext: string;
};

export type ParseFile = (file: File, category: Category, onSuccess: (rowObjects: unknown[]) => void) => void;
