import { FindRequest } from 'popcorn-js/index';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { Criteria } from 'popcorn-js/search';
import { restRPC } from 'utils/restrpc';

export type DownloadRequest = FindRequest;
export type DownloadResponse = {
    data: string;
};

export const Downloader = {
    ServiceProviderName: 'CFCAccountV2-Downloader',
    Download(request: DownloadRequest): Promise<DownloadResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<DownloadRequest, DownloadResponse>({
            method: `${Downloader.ServiceProviderName}.Download`,
            request: { ...request, criteria: serializedCriteria },
        });
    },
};
