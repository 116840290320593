/* eslint-disable @typescript-eslint/no-explicit-any */
import { Category, File } from 'components/upload';
import { Order } from 'popcorn-js/order';
import XLSX from 'xlsx';

export const fields = [
    {
        name: 'number',
        type: 'string',
    },
    {
        name: 'counterparty',
        type: 'string',
        objField: 'counterparty',
    },
    {
        name: 'invoiceExternalReference',
        type: 'string',
        objField: 'linkedInvoice',
        optional: true,
    },
    {
        name: 'originalAmountDue',
        type: 'float',
    },
    {
        name: 'currencyIsoCode',
        type: 'string',
        objField: 'currency',
    },
    {
        name: 'costCurrencyIsoCode',
        type: 'string',
        objField: 'costCurrency',
    },
    {
        name: 'costingRate',
        type: 'float',
    },
    {
        name: 'captureRate',
        type: 'float',
        optional: true,
    },
    {
        name: 'dueDate',
        type: 'date',
    },
    {
        name: 'shippingDate',
        type: 'date',
        optional: true,
    },
    {
        name: 'shipmentReference',
        type: 'string',
        optional: true,
    },
    {
        name: 'notes',
        type: 'string',
        optional: true,
        objField: 'notes',
    },
    {
        name: 'status',
        type: 'string',
        optional: true,
        objField: 'status',
    },
    {
        name: 'partyCode',
        type: 'string',
        optional: true,
    },
    {
        name: 'financialYear',
        type: 'string',
        optional: false,
    },
    {
        name: 'issueDate',
        type: 'date',
    },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const newFromFields = (uploadObj: any, props: any): any => {
    const newOrder: Order = {} as Order;
    newOrder.id = uploadObj.id;
    newOrder.number = uploadObj.number;
    newOrder.counterpartyId =
        ((props.counterparties || []).find((b: any) => b.name === uploadObj.counterparty) || {}).id ||
        uploadObj.counterparty;
    newOrder.counterparty = uploadObj.counterparty;
    newOrder.originalAmountDue = uploadObj.originalAmountDue;
    newOrder.currency = uploadObj.currencyIsoCode;
    newOrder.costCurrency = uploadObj.costCurrencyIsoCode;
    newOrder.costingRate = uploadObj.costingRate;
    newOrder.captureRate = uploadObj.captureRate;
    newOrder.dueDate = uploadObj.dueDate;
    newOrder.shippingDate = uploadObj.shippingDate;
    newOrder.shipmentReference = uploadObj.shipmentReference;
    newOrder.notes = uploadObj.notes;
    newOrder.status = uploadObj.status;
    newOrder.partyCode = uploadObj.partyCode;
    newOrder.auditEntry = uploadObj.auditEntry;
    newOrder.issueDate = uploadObj.issueDate;
    newOrder.financialYear = uploadObj.financialYear;
    newOrder.invoiceExternalReference = uploadObj.invoiceExternalReference;
    newOrder.linkedInvoices = uploadObj.linkedInvoices;

    return newOrder;
};

export const downloadTemplate = (): void => {
    /* create dummy data */
    const data = [
        {
            /* A */ Number: 'ABC123',
            /* B */ Counterparty: 'CreditorABC',
            /* C */ OriginalAmountDue: 100000.0,
            /* D */ Currency: 'USD',
            /* E */ CostCurrency: 'ZAR',
            /* F */ LinkedInvoice: '',
            /* G */ CostingRate: 15.15,
            /* H */ FinancialYear: 'CURRENT',
            /* I */ DueDate: new Date(),
            /* J */ IssueDate: new Date(),
            /* K */ Notes: 'Coffee beans',
            /* L */ Status: 'PAID',
            /* M */ ShipmentReference: 'Container number XYZ789',
            /* N */ ShippingDate: new Date(),
        },
        {
            Number: 'ABC124',
            Counterparty: 'CreditorXYZ',
            OriginalAmountDue: 200000.0,
            Currency: 'EUR',
            CostCurrency: 'ZAR',
            LinkedInvoice: '',
            CostingRate: 16.15,
            FinancialYear: '2018',
            DueDate: new Date(),
            IssueDate: new Date(),
            Notes: 'Sugar',
            Status: 'PAID',
            ShipmentReference: 'Container number XYZ780',
            ShippingDate: new Date(),
        },
    ];

    /* create a new workbook */
    const workbook = XLSX.utils.book_new();

    /* create the worksheet */
    const worksheet = XLSX.utils.json_to_sheet(data);

    /* set some formatting */
    worksheet['A1']['v'] = 'Number';
    worksheet['B1']['v'] = 'Counterparty';
    worksheet['C1']['v'] = 'Original Amount Due';
    worksheet['D1']['v'] = 'Currency';
    worksheet['E1']['v'] = 'Cost Currency';
    worksheet['F1']['v'] = 'Linked Invoice';
    worksheet['G1']['v'] = 'Costing Rate';
    worksheet['H1']['v'] = 'Financial Year';
    worksheet['I1']['v'] = 'Due Date';
    worksheet['J1']['v'] = 'Issue Date';
    worksheet['K1']['v'] = 'Notes';
    worksheet['L1']['v'] = 'Status';
    worksheet['M1']['v'] = 'Shipment Reference';
    worksheet['N1']['v'] = 'Shipping Date';

    worksheet['D2']['z'] = '#,##0.00';
    worksheet['D3']['z'] = '#,##0.00';

    /* add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');

    /* download the workbook */
    XLSX.writeFile(workbook, 'orders.xlsx');
};

export const parseFile = (file: File, category: Category, onSuccess: (rowObjects: any[]) => void): void => {
    let rowObjects = [];
    switch (file.ext) {
        case 'xlsx':
        case 'xls':
            let workbook = undefined;
            let worksheet = undefined;
            // Try parse data to workbook
            try {
                workbook = XLSX.read(file.data, { type: 'binary' });
            } catch (e) {
                throw new Error(`unable to read file data to workbook\n${e}`);
            }
            // Try get worksheet
            try {
                worksheet = workbook.Sheets[workbook.SheetNames[0]];
            } catch (e) {
                throw new Error(`unable to get first worksheet of given workbook\n${e}`);
            }
            // Try convert contents of worksheet to object array
            try {
                rowObjects = XLSX.utils.sheet_to_json(worksheet, { raw: false });
            } catch (e) {
                throw new Error('unable to convert sheet to row objects');
            }
            break;
        default:
            throw new Error(`Files With Extension ${file.ext} are not supported`);
    }

    // Check if there are any rows in file provided
    if (!(rowObjects.length > 0)) {
        throw new Error('no rows in workbook supplied');
    }

    onSuccess(rowObjects);
};
