import React, { SyntheticEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseButton, VARIANT, SIZE, COLOR } from 'components/BaseButton';

const useStyles = makeStyles(() => ({
    buttonWrapper: {
        justifySelf: 'center',
        alignSelf: 'center',
        fontSize: '14px',
        width: 'auto',
    },
}));

interface UploadClientDataButtonProps {
    disabled: boolean;
    onClick: (event: SyntheticEvent<HTMLButtonElement, Event>) => void;
}

export const UploadClientDataButton: React.FC<UploadClientDataButtonProps> = (props: UploadClientDataButtonProps) => {
    const classes = useStyles();
    return (
        <div className={classes.buttonWrapper}>
            <BaseButton
                id={'UploadClientDataButton/View'}
                disabled={props.disabled}
                variant={VARIANT.CONTAINED}
                color={COLOR.ACTION}
                size={SIZE.MEDIUM}
                onClick={props.onClick}
                text={'UPLOAD'}
                height={'30px'}
            />
        </div>
    );
};
