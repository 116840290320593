import React, { ChangeEvent, ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Actions, TradeValues } from './index';
import { Card, FormControl, MenuItem } from '@material-ui/core';
import { LightNumberField, LightSelect, LightTextField } from './styledComponents';
import { BillingType } from 'popcorn-js/tradeV2';
import { FormatNumber } from 'utils';

export const BillingInformation = ({
    uuid,
    trade,
    tradeDispatch,
    autoFillMode,
    marginNotesNeeded,
    revenueFieldsInactive,
}: {
    uuid: string;
    trade: TradeValues;
    tradeDispatch: Actions;
    autoFillMode: boolean;
    marginNotesNeeded: boolean;
    revenueFieldsInactive: boolean;
}): ReactElement => {
    const classes = useStyles();

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelLight}>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Billing Type'}
                            disabled={autoFillMode || revenueFieldsInactive}
                            style={{ width: '100%' }}
                            error={!!trade.fieldErrors.billingType}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                                tradeDispatch.setBillingType(uuid, event.target.value as BillingType);
                            }}
                            value={trade.billingType ? trade.billingType.toString() : ''}
                        >
                            <MenuItem key={BillingType.ClientBilling} value={BillingType.ClientBilling}>
                                {'Client'}
                            </MenuItem>
                            <MenuItem key={BillingType.BankBilling} value={BillingType.BankBilling}>
                                {'Bank'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                    <LightNumberField
                        label={'Bank Rate'}
                        disabled
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setBankRate(uuid, event.target.value)
                        }
                        value={trade.bankRate}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightNumberField
                        label={'Intermediary Margin'}
                        error={!!trade.fieldErrors.intermediaryMargin}
                        disabled={revenueFieldsInactive}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            tradeDispatch.setIntermediaryMargin(uuid, event.target.value, !revenueFieldsInactive);
                        }}
                        value={FormatNumber(trade.intermediaryMargin, true, true, 4)}
                    />
                    <LightTextField
                        label={'Margin Notes'}
                        disabled={autoFillMode}
                        error={marginNotesNeeded}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setMarginNotes(uuid, event.target.value)
                        }
                        value={trade.marginNotes}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightNumberField
                        label={'Fee'}
                        disabled
                        value={trade.billingType === BillingType.ClientBilling ? trade.clientFee : trade.billedToBank}
                        style={{ width: '100%' }}
                    />
                    <LightNumberField
                        id={'adminFee'}
                        label={'Admin Fee'}
                        disabled={revenueFieldsInactive}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setAdminFee(uuid, event.target.value)
                        }
                        value={FormatNumber(trade.adminFee, true, true, 2)}
                    />
                </div>
            </div>
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
    },
    detailsPanelLight: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
}));
