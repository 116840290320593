import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridColumns, GridRowData } from '@mui/x-data-grid';

export const UploadClientDataGrid = (props: { columns: GridColumns; rows: GridRowData[] }): ReactElement => {
    const classes = useStyles();
    const { columns, rows } = props;
    return (
        <div>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount={true}
                hideFooter={true}
                hideFooterRowCount={true}
                scrollbarSize={1}
                className={classes.root}
                rowHeight={40}
            />
        </div>
    );
};
const useStyles = makeStyles(() => ({
    root: {
        height: '250px',
        overflowY: 'hidden',
        '&.MuiDataGrid-root': {
            border: 'hidden',
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-renderingZone': {
            '& .MuiDataGrid-row': {
                height: '10px',
            },
        },
    },
}));
