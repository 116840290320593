import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import {
    Button,
    Dialog,
    FormHelperText,
    Grid,
    Input,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { Currency } from 'popcorn-js/currency';
import { processUnixDateForViewing } from 'utils';
import { ImportExport } from 'popcorn-js';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';

type SettlementInstructionCreateInfo = {
    date: number;
    currency: string;
};

const ItemWrapper = (props: {
    children: ReactElement;
    error: string | undefined;
    label: string | undefined;
}): ReactElement => {
    return (
        <Grid item>
            <div
                style={{
                    height: props.error ? '45px' : '31px',
                    display: 'grid',
                    gridGap: '20px',
                    gridTemplateColumns: '1fr 1fr',
                    marginLeft: '40px',
                    marginRight: '40px',
                }}
            >
                <Typography variant={'subtitle1'}>
                    <div>{props.label}</div>
                </Typography>
                <div>
                    {props.children}
                    {props.error && (
                        <FormHelperText error id="helperText">
                            {props.error}
                        </FormHelperText>
                    )}
                </div>
            </div>
        </Grid>
    );
};

export const AddNewSI = (props: {
    onSubmit: (i: SettlementInstructionCreateInfo) => void;
    closeDialog: () => void;
    importExport: ImportExport;
    currencies: (Currency | undefined)[];
    currency: string | undefined;
    show: boolean;
}): ReactElement => {
    const { onSubmit, closeDialog, show, importExport, currencies, currency } = props;
    const inputProps = {
        disableUnderline: true,
        readOnly: true,
    };
    const [
        settlementInstructionCreateInfo,
        setSettlementInstructionCreateInfo,
    ] = useState<SettlementInstructionCreateInfo>({} as SettlementInstructionCreateInfo);
    const [invalidFields, setInvalidFields] = useState<Record<string, string | undefined>>();

    const handleSubmit = async () => {
        onSubmit(settlementInstructionCreateInfo);
    };

    const handleChange = (field: string, value: string | number | undefined) => {
        if (currency !== undefined) {
            setSettlementInstructionCreateInfo({
                ...settlementInstructionCreateInfo,
                [field]: value,
                currency: currency,
            });
        } else {
            setSettlementInstructionCreateInfo({
                ...settlementInstructionCreateInfo,
                [field]: value,
            });
        }
        setInvalidFields({
            ...invalidFields,
            [field]: undefined,
        });
    };

    return (
        <Dialog open={show}>
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            id: 'SettlementInstruction/AddNewSI/title',
                            type: ITEM_VARIATION.TITLE,
                            text: `Create New ${importExport ? importExport : ''} Settlement Instruction`,
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'SettlementInstruction/AddNewSI/close',
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            helpText: 'Close',
                            onClick: closeDialog,
                        },
                    ],
                }}
            >
                <div
                    style={{
                        margin: '30px',
                    }}
                >
                    {currency == undefined && (
                        <ItemWrapper error={invalidFields?.currency} label={'Currency'}>
                            <Select
                                fullWidth
                                onChange={(event) => handleChange('currency', event.target.value as string)}
                                value={settlementInstructionCreateInfo.currency || ''}
                            >
                                {currencies.map(
                                    (c, i) =>
                                        c && (
                                            <MenuItem key={i} value={c.isoCode}>
                                                {c.isoCode}
                                            </MenuItem>
                                        ),
                                )}
                            </Select>
                        </ItemWrapper>
                    )}
                    {currency !== undefined && (
                        <ItemWrapper error={invalidFields?.currency} label={'Currency'}>
                            <TextField InputProps={inputProps} id="time" type={'datetime'} value={currency} />
                        </ItemWrapper>
                    )}
                    <ItemWrapper error={invalidFields?.date} label={'Delivery Date'}>
                        <Input
                            fullWidth
                            inputProps={{
                                max: '9999-12-31',
                            }}
                            onChange={(event) => handleChange('date', moment.utc(event.target.value).unix())}
                            type={'date'}
                            value={processUnixDateForViewing(settlementInstructionCreateInfo.date)}
                        />
                    </ItemWrapper>
                    <div
                        style={{
                            textAlign: 'center',
                            margin: '10px',
                        }}
                    >
                        <Button onClick={handleSubmit} style={{ width: '100%' }}>
                            {'Create'}
                        </Button>
                    </div>
                </div>
            </StandardCard>
        </Dialog>
    );
};
