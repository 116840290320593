/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Order } from 'popcorn-js/order';
import { Recordkeeper as OrderRecordkeeper } from 'popcorn-js/order/recordkeeper';
import { Recordkeeper as CounterpartyRecordkeeper } from 'popcorn-js/counterparty/recordkeeper';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Counterparty } from 'popcorn-js/counterparty';
import { AppContextT, AppContext } from 'context';
import { Currency } from 'popcorn-js/currency';
import { Criteria, CriteriaType } from 'popcorn-js/search';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { OrderFields } from './OrderFields';

export type OrderH = Order & {
    currencyIsoCode?: string;
    costCurrencyIsoCode?: string;
    counterpartyName?: string;
};

export const History = (props: { order: Order; onHide?: () => void; open: boolean }): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [order, setOrder] = useState<OrderH>(props.order);
    const [history, setHistory] = useState<OrderH[]>([]);

    useEffect(() => {
        load(props.order).finally();
    }, [props.order]);

    const load = async (i: Order) => {
        try {
            setIsLoading(true);
            const _history = await retrieveHistory(i);
            const _counterparties = await findAssociatedCounterparties(i);
            setOrder(modifyFields(i, _counterparties));
            setHistory(modifyMultiple(_history, _counterparties));
            setIsLoading(false);
        } catch (e) {
            console.error('error retrieving history', e);
        }
    };

    const retrieveHistory = async (i: Order): Promise<Order[]> => {
        const retrieveResponse = await OrderRecordkeeper.retrieveHistory({
            identifier: { type: IdentifierType.ID_IDENTIFIER, id: i.id },
        });
        return retrieveResponse.history;
    };

    const modifyMultiple = (orders: Order[], counterparties: Counterparty[]): OrderH[] => {
        return orders.map((inv: Order) => modifyFields(inv, counterparties));
    };

    const modifyFields = (order: Order, counterparties: Counterparty[]): OrderH => {
        return {
            ...order,
            currencyIsoCode: (appContext.currencies?.find((c: Currency) => c.isoCode === order.currency) || {}).isoCode,
            costCurrencyIsoCode: (appContext.currencies?.find((c: Currency) => c.isoCode === order.costCurrency) || {})
                .isoCode,
            counterpartyName: (counterparties.find((c: Counterparty) => c.id === order.counterpartyId) || {}).name,
        };
    };

    const findAssociatedCounterparties = async (inv: Order): Promise<Counterparty[]> => {
        const counterpartyIds = [];
        if (inv.counterpartyId && inv.counterpartyId !== '') {
            counterpartyIds.push(inv.counterpartyId);
        }
        for (const inv of history) {
            if (inv.counterpartyId && inv.counterpartyId !== '') {
                if (!counterpartyIds.includes(inv.counterpartyId)) {
                    counterpartyIds.push(inv.counterpartyId);
                }
            }
        }
        if (counterpartyIds.length <= 0) {
            return [];
        }
        const criteria: Criteria = counterpartyIds.map((id: string) => ({
            type: CriteriaType.ExactCriterion,
            field: 'id',
            text: id,
        }));
        const findResponse = await CounterpartyRecordkeeper.find({ criteria });
        return findResponse.records;
    };

    return (
        <HistoryLayout
            entity={order}
            entityFields={OrderFields}
            entityHistory={history}
            entityName={'Order'}
            loading={isLoading}
            onHide={props.onHide}
            open={props.open}
        />
    );
};
