import React, { ReactElement, useEffect, useState } from 'react';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import TradeDetailDialog from 'components/tradeV2/TradeDetailDialog';
import { displayAmount } from 'views/Client/util';
import { Handler as TradeHandler } from 'popcorn-js/tradeV2/handler';
import { Invoice, TradeLink } from 'popcorn-js/invoice';
import { CriteriaType, ExactCriterion } from 'popcorn-js/search';
import { Counterparty } from 'popcorn-js/counterparty';
import { Collapse } from '@material-ui/core';
import { StandardCard } from 'components/Card/Card';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Trade } from 'popcorn-js/tradeV2';
import Table from 'components/Table/Table';
import { useServiceSync } from 'hooks/useService';
import { FindRequest, FindResponse } from 'popcorn-js';

const LinkedTrades = (props: {
    invoice: Invoice;
    updateInvoiceSuccess?: (i?: Invoice) => void;
    readOnly?: boolean;
    onClose?: () => void;
    counterparties?: Counterparty[];
}): ReactElement => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(true);
    const [selected, setSelected] = useState<DisplayTradeLink | undefined>();
    const [selectedLinkedTrade, setSelectedLinkedTrade] = useState<Trade | undefined>();
    const [linkedTrades, setLinkedTrades] = useState<Trade[] | undefined>();
    const [showLinkedTradeDetail, setShowLinkedTradeDetail] = useState(false);
    const [displayTradeLinks, setDisplayTradeLinks] = useState<DisplayTradeLink[] | undefined>();

    const [tradeFind] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);

    useEffect(() => {
        assignInvoice(props.invoice).finally();
    }, [props.invoice]);

    const assignInvoice = async (i: Invoice) => {
        const _linkedTrades = await findLinkedTrades(i);
        setLinkedTrades(_linkedTrades);
        const _displayTradeLinks = i.tradeLinks?.map(
            (tradeLink: TradeLink): DisplayTradeLink => ({
                tradeId: tradeLink.tradeId,
                amount: tradeLink.amount,
                date: tradeLink.date,
                id: _linkedTrades?.find((trade: Trade) => trade.id === tradeLink.tradeId)?.id,
                externalReference: _linkedTrades?.find((trade: Trade) => trade.id === tradeLink.tradeId)
                    ?.externalReference,
            }),
        );
        setDisplayTradeLinks(_displayTradeLinks);
    };

    const findLinkedTrades = async (i: Invoice): Promise<Trade[] | undefined> => {
        if ((i.tradeLinks || []).length === 0) {
            return;
        }
        const criteria = (i.tradeLinks || []).map(
            (tl: TradeLink) =>
                ({
                    type: CriteriaType.ExactCriterion,
                    field: 'id',
                    text: tl.tradeId,
                } as ExactCriterion),
        );
        try {
            const findResponse = await tradeFind({ criteria });

            return findResponse.records;
        } catch (e) {}
    };

    const renderDialogs = () => {
        return (
            <React.Fragment>
                {showLinkedTradeDetail && (
                    <TradeDetailDialog
                        closeDialog={() => setShowLinkedTradeDetail(false)}
                        open={showLinkedTradeDetail}
                        trade={selectedLinkedTrade}
                        amendSuccess={() => undefined}
                        readOnly
                    />
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <StandardCard
                cardHeaderProps={{
                    tailoredState: !!selected ? STATES.SELECTED_ROW : undefined,
                    squareEdge: true,

                    itemsLeft: [
                        {
                            id: 'LinkedTrades/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Linked Trades',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'LinkedInvoices/view',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View details',
                            onClick: () => {
                                const _selectedLinkedTrade = linkedTrades?.find(
                                    (linkedTrade: Trade) => linkedTrade.id === selected?.tradeId,
                                );
                                if (_selectedLinkedTrade) {
                                    setSelectedLinkedTrade(_selectedLinkedTrade);
                                    setShowLinkedTradeDetail(true);
                                } else {
                                    //todo
                                }
                            },
                            hide: !selected || !open,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/collapse',
                            icon: ACTION_BUTTON_TYPE.COLLAPSE,
                            helpText: 'Collapse',
                            onClick: () => setOpen(false),
                            hide: !open,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/expand',
                            icon: ACTION_BUTTON_TYPE.EXPAND,
                            helpText: 'Expand',
                            onClick: () => setOpen(true),
                            hide: open,
                        },
                    ],
                }}
            >
                <Collapse in={open}>
                    <div className={classes.linkedInvoicesWrapper}>
                        <Table
                            showCheckboxes
                            columns={[
                                {
                                    title: 'External Reference',
                                    field: 'externalReference',
                                },
                                {
                                    title: 'Allocated Amount',
                                    field: 'amount',
                                    render: (linkedTrade: DisplayTradeLink) =>
                                        displayAmount(props.invoice.currency, linkedTrade.amount, 'code', 2),
                                },
                            ]}
                            data={displayTradeLinks}
                            selected={selected ? [selected] : []}
                            onSelectAll={() => (selected ? setSelected(undefined) : undefined)}
                            onRowCheck={(tradeLink: DisplayTradeLink) =>
                                tradeLink.tradeId === selected?.tradeId
                                    ? setSelected(undefined)
                                    : setSelected(tradeLink)
                            }
                            rowClickAction={(tradeLink: DisplayTradeLink) =>
                                tradeLink.tradeId === selected?.tradeId
                                    ? setSelected(undefined)
                                    : setSelected(tradeLink)
                            }
                            rowDoubleClickAction={(tradeLink: DisplayTradeLink) => {
                                const _selectedLinkedTrade = linkedTrades?.find(
                                    (linkedTrade: Trade) => linkedTrade.id === tradeLink?.tradeId,
                                );
                                if (_selectedLinkedTrade) {
                                    setSelectedLinkedTrade(_selectedLinkedTrade);
                                    setShowLinkedTradeDetail(true);
                                }
                            }}
                            disableFooter
                        />
                    </div>
                    {renderDialogs()}
                </Collapse>
            </StandardCard>
        </React.Fragment>
    );
};

type DisplayTradeLink = {
    externalReference?: string;
    id?: string;
} & TradeLink;

const useStyles = makeStyles((theme: CustomTheme) => ({
    linkedInvoicesWrapper: {
        backgroundColor: theme.palette.background.paper,
        height: 'relative',
        width: 'relative',
        padding: 0,
        display: 'grid',
    },
}));

export default LinkedTrades;
