import React from 'react';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';

export type DisplayRateProps = {
    variant: 'title' | 'value' | 'slash';
    color: 'default' | 'white' | 'import' | 'export';
    text?: string;
};

export const DisplayRate = (props: DisplayRateProps): React.ReactElement => {
    const theme = useTheme<CustomTheme>();

    const colors = {
        default: theme.palette.custom.icons.main,
        white: theme.palette.text.primary,
        import: theme.palette.custom.import.main,
        export: theme.palette.custom.export.main,
    };
    const variants = {
        title: {
            fontWeight: 'bold',
            fontSize: '12px',
            opacity: 0.6,
        },
        value: {
            fontWeight: 'normal',
            fontSize: '12px',
        },
        slash: {
            fontWeight: 'normal',
            fontSize: '12px',
            opacity: 0.6,
        },
    };

    //default
    let style = {};
    //style
    style = { ...style, ...variants[props.variant] };
    //color
    style = { ...style, color: colors[props.color] };

    return <div style={style}>{props.text}</div>;
};
