/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useCallback } from 'react';
import { TreeView, TreeItem } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CustomPalette } from './custom';
import { Popover, TextField, Typography } from '@material-ui/core';
import { useStyletron } from 'styletron-react';
import _ from 'lodash';
import { SketchPicker } from 'react-color';

interface RenderTree {
    id: string;
    name: string;
    children?: RenderTree[];
    value?: string;
}

const convertToRenderTree = (o: any, key: string, paths: string[], parents?: string): RenderTree => {
    const subParents = parents ? parents + '.' + key : key;
    paths.push(subParents);
    if (typeof o === 'object') {
        const children: RenderTree[] = [] as RenderTree[];
        for (const k of Object.keys(o)) {
            children.push(convertToRenderTree(o[k], k, paths, subParents));
        }
        return {
            id: subParents,
            name: key,
            children,
        };
    } else {
        return {
            id: subParents,
            name: key,
            value: o,
        };
    }
};

const ColorDisplay = (props: { nodes: RenderTree; onChange: (color: string, path: string) => void }): ReactElement => {
    const { nodes, onChange } = props;
    const [css] = useStyletron();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(open ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let backgroundColor = 'rgba(255, 255, 255, 0)';
    if (typeof nodes.value === 'string') {
        backgroundColor = nodes.value;
    }
    return (
        <>
            <Popover
                id={'chooser'}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <SketchPicker color={backgroundColor} onChange={(v: any) => onChange(v.hex as string, nodes.id)} />
            </Popover>
            <TextField
                contentEditable={false}
                InputProps={{
                    className: css({ backgroundColor }),
                }}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    onChange(event.target.value as string, nodes.id);
                }}
                onClick={handleClick}
                label={nodes.name}
                id={nodes.id}
                value={nodes.value}
                size="small"
            />
        </>
    );
};

const renderTree = (nodes: RenderTree, onChange: (color: string, path: string) => void): ReactElement => {
    if (Array.isArray(nodes.children)) {
        return (
            <TreeItem key={nodes.id} nodeId={nodes.id} label={<Typography variant={'h6'}>{nodes.name}</Typography>}>
                {nodes.children.map((node) => renderTree(node, onChange))}
            </TreeItem>
        );
    } else {
        return <ColorDisplay key={nodes.id} onChange={onChange} nodes={nodes} />;
        // return <SketchPicker color={nodes.value} />;
    }
};

export const PaletteEditor = (props: {
    palette: CustomPalette;
    onChange: (p: CustomPalette) => void;
}): ReactElement => {
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [palette, setPalette] = React.useState<CustomPalette>(props.palette);

    const [css] = useStyletron();

    const handleToggle = (_: unknown, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (_: unknown, nodeIds: string[]) => {
        setSelected(nodeIds);
    };

    const handlePaletteChange = useCallback(
        _.debounce(() => props.onChange(palette), 500),
        [palette],
    );

    useEffect(() => {
        handlePaletteChange();
    }, [handlePaletteChange]);

    // remove palette properties that aren't colors
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { augmentColor, type, contrastThreshold, getContrastText, tonalOffset, ...colorsPalette } = {
        ...palette,
    };
    const paths = [] as string[];
    const paletteTree = convertToRenderTree(colorsPalette, 'palette', paths);

    return (
        <TreeView
            className={css({
                width: '50vw',
                height: '50vh',
            })}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
        >
            {renderTree(paletteTree, (color: string, path: string) => {
                const newPalette = { ...palette };
                const p = path.toString().substr(8 /* to the end */);
                _.set(newPalette, p, color);
                setPalette({
                    ...newPalette,
                    // re-add removed palette properties
                    augmentColor,
                    type,
                    contrastThreshold,
                    getContrastText,
                    tonalOffset,
                });
            })}
        </TreeView>
    );
};
