import { Currency } from 'popcorn-js/currency';
import { ProcessingBank } from 'popcorn-js/party';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import Big from 'big.js';
import { OptionDirection, OptionRecordInput, OptionType } from 'popcorn-js/options';
import { CustomTheme } from 'theme/custom';
import { BillingType, ImportExport } from 'popcorn-js/tradeV2';

export type InitProps = {
    processingBank?: ProcessingBank;
    currencyPair?: CurrencyPair;
    direction?: OptionDirection;
    type?: OptionType;
    trader?: string;
    setExpanded: (e?: string) => void;
    nonTradingDays: Date[];
};

export type OptionValues = {
    uuid?: string;
    externalReference?: string;
    number?: string;
    notes?: string;
    currencyPair?: CurrencyPair;
    premium?: Big;
    expiryDate?: Date;
    deliveryDate?: Date;
    strikePrice?: Big;
    tradeDate?: Date;
    type?: OptionType;
    direction?: OptionDirection;
    importExport?: ImportExport;
    bank?: ProcessingBank;
    interbankRate?: Big;
    bankRate?: Big;
    notionalAmount?: Big;
    quoteAmount?: Big;
    season?: string;
    trader?: string;
    clientReference?: string;
    product?: string;
    clientNotes?: string;
    billingType?: BillingType;
    intermediaryMargin?: Big;
    marginNotes?: string;
    adminFee?: Big;
    clientFee?: Big;
    billedToBank?: Big;
};

export const calcDefaultImportExport = (type?: OptionType, direction?: OptionDirection): ImportExport => {
    switch (true) {
        case direction == OptionDirection.BUY && type == OptionType.PUT:
            return ImportExport.EXPORT;
        case direction == OptionDirection.SELL && type == OptionType.PUT:
            return ImportExport.IMPORT;
        case direction == OptionDirection.BUY && type == OptionType.CALL:
            return ImportExport.IMPORT;
        case direction == OptionDirection.SELL && type == OptionType.CALL:
            return ImportExport.EXPORT;
        default:
            return ImportExport.IMPORT;
    }
};

export const optionValid = (t: OptionValues): boolean => {
    return !!(
        t.externalReference &&
        t.currencyPair &&
        t.premium &&
        t.bank &&
        t.tradeDate &&
        t.direction &&
        t.expiryDate &&
        t.strikePrice &&
        t.type &&
        t.importExport &&
        t.notionalAmount &&
        t.deliveryDate &&
        t.trader
    );
};

export const mapOption = ({
    optionValues,
    traderOrganisation,
    capturedSpotRate,
}: {
    optionValues: OptionValues;
    parentPartyCode?: string;
    traderOrganisation?: string;
    spotPrice?: Big;
    capturedSpotRate?: Big;
}): OptionRecordInput => ({
    externalReference: optionValues.externalReference,
    number: optionValues.number,
    notionalAmount: optionValues.notionalAmount?.toNumber(),
    currencyPair: optionValues.currencyPair?.name,
    premium: optionValues.premium?.toNumber(),
    expiryDate: optionValues.expiryDate,
    deliveryDate: optionValues.deliveryDate,
    strikePrice: optionValues.strikePrice?.toNumber(),
    tradeDate: optionValues.tradeDate,
    type: optionValues.type,
    direction: optionValues.direction,
    importExport: optionValues.importExport,
    bank: optionValues.bank?.partyCode,
    interbankRate: optionValues.interbankRate?.toNumber(),
    bankRate: optionValues.bankRate?.toNumber(),
    trader: optionValues.trader,
    traderOrganisation: traderOrganisation,
    notes: optionValues.notes,
    capturedSpotRate: capturedSpotRate?.toNumber(),
    season: optionValues.season,
    clientReference: optionValues.clientReference,
    product: optionValues.product,
    clientNotes: optionValues.clientNotes,
    billingType: optionValues.billingType,
    intermediaryMargin: optionValues.intermediaryMargin?.toNumber(),
    marginNotes: optionValues.marginNotes,
    adminFee: optionValues.adminFee?.toNumber(),
    clientFee: optionValues.clientFee?.toNumber(),
    billedToBank: optionValues.billedToBank?.toNumber(),
});

export const determineSellColor = (theme: CustomTheme, type?: OptionType, direction?: OptionDirection): string => {
    if (type === OptionType.CALL && direction === OptionDirection.BUY) {
        return theme.palette.custom.import.main;
    }
    if (type === OptionType.CALL && direction === OptionDirection.SELL) {
        return theme.palette.custom.export.main;
    }
    return theme.palette.custom.icons.main;
};

export const determineBuyColor = (theme: CustomTheme, type?: OptionType, direction?: OptionDirection): string => {
    if (type === OptionType.PUT && direction === OptionDirection.BUY) {
        return theme.palette.custom.export.main;
    }
    if (type === OptionType.PUT && direction === OptionDirection.SELL) {
        return theme.palette.custom.import.main;
    }
    return theme.palette.custom.icons.main;
};

export const getPutCurrency = (
    type?: OptionType,
    currencyPair?: CurrencyPair,
    currencies?: Currency[],
): Currency | undefined => {
    return type === OptionType.PUT
        ? currencies?.find((currency: Currency) => currencyPair?.baseCurrency === currency.isoCode)
        : currencies?.find((currency: Currency) => currencyPair?.quoteCurrency === currency.isoCode);
};

export const getCallCurrency = (
    type?: OptionType,
    currencyPair?: CurrencyPair,
    currencies?: Currency[],
): Currency | undefined => {
    return type === OptionType.CALL
        ? currencies?.find((currency: Currency) => currencyPair?.baseCurrency === currency.isoCode)
        : currencies?.find((currency: Currency) => currencyPair?.quoteCurrency === currency.isoCode);
};

export const calculateCallAmount = (strikePrice?: Big, notionalAmount?: Big, type?: OptionType): Big | undefined => {
    switch (type) {
        case OptionType.CALL:
            return notionalAmount;
        case OptionType.PUT:
            return notionalAmount?.mul(strikePrice || new Big(0));
        default:
            return new Big(0);
    }
};

export const calculatePutAmount = (strikePrice?: Big, notionalAmount?: Big, type?: OptionType): Big | undefined => {
    switch (type) {
        case OptionType.CALL:
            return notionalAmount?.mul(strikePrice || new Big(0));
        case OptionType.PUT:
            return notionalAmount;
        default:
            return new Big(0);
    }
};

export type Actions = {
    updateOptionExtRef: (uuid: string, value: string) => void;
    addOption: () => void;
    removeOption: (uuid: string) => void;
    setPremium: (uuid: string, value: string) => void;
    setTradeDate: (uuid: string, value: Date | null) => void;
    setExpiryDate: (uuid: string, value: Date | null) => void;
    setDeliveryDate: (uuid: string, value: Date | null) => void;
    changeOptionType: (uuid: string, value: string) => void;
    setDirection: (uuid: string, value: string) => void;
    setImportExport: (uuid: string, value: string) => void;
    setBank: (uuid: string, value: ProcessingBank) => void;
    setNotes: (uuid: string, value: string) => void;
    setNotionalAmount: (uuid: string, value: string) => void;
    setQuoteAmount: (uuid: string, value: string) => void;
    setSeason: (uuid: string, value: string) => void;
    setClientReference: (uuid: string, value: string) => void;
    setTrader: (uuid: string, value: string) => void;
    setProduct: (uuid: string, value: string) => void;
    setClientNotes: (uuid: string, value: string) => void;
    setBillingType: (uuid: string, value: string) => void;
    setIntermediaryMargin: (uuid: string, value: string) => void;
    setMarginNotes: (uuid: string, value: string) => void;
    setAdminFee: (uuid: string, value: string) => void;
    setBankRate: (uuid: string, value: string) => void;
    setClientFee: (uuid: string, value: string) => void;
    setBilledToBank: (uuid: string, value: string) => void;
};
