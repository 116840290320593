import { createStyles, makeStyles, Tooltip, useTheme } from '@material-ui/core';
import React, { ReactElement, useContext, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { displayAmount } from 'utils/';
import { ErrorOutline as ErrorIcon, FolderOpen, Help as HelpIcon } from '@material-ui/icons';
import { CustomTheme } from 'theme/custom';
import Generator, { CurrencyTotal, GenerateCoverageRatioReportRequest } from 'popcorn-js/report';
import { useService } from 'hooks/useService';
import { ScaleLoader as Spinner } from 'react-spinners';
import { AppContext, AppContextT } from 'context';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        rootCoverageRatio: {
            backgroundColor: theme.palette.custom.paperExtended.paper2,
            borderRadius: '2px',
            marginTop: theme.spacing(2),
            marginBottom: 0,
        },
        tableCellHead: {
            textAlign: 'right',
            backgroundColor: theme.palette.custom.paperExtended.paper2,
            color: theme.palette.text.primary,
            fontSize: '14px',
            lineHeight: '20px',
            height: '20px',
            position: 'sticky',
            top: 0,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            zIndex: 1,
            paddingTop: '0px',
        },
        tableWrapper: {
            height: '400px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            width: '520x',
        },
        helpIconWrapper: {
            width: '14px',
            height: '14px',
            color: theme.palette.text.primary,
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.text.primary,
            },
            marginLeft: '4px',
            alignSelf: 'center',
        },
        helpIcon: {
            width: '14px',
            height: '14px',
        },
        hidden: {
            visibility: 'hidden',
        },
        cell: {
            textAlign: 'right',
            color: theme.palette.text.secondary,
            borderBottom: `1px solid ${theme.palette.custom.dividerExtended.hor_div1}`,
            borderWidth: '520px',
            fontSize: '12px',
        },
        coverageHeader: {
            color: theme.palette.custom.stellcapBrand1.light,
            fontSize: '12px',
            paddingTop: theme.spacing(2),
            marginLeft: '16px',
        },
        coverageWrapper: {
            backgroundColor: theme.palette.custom.paperExtended.paper2,
            height: '100%',
            width: '550px',
            position: 'relative',
        },
        coverageValuesLoading: {
            gridColumn: '3/3',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        fadeWrapper: {
            height: '25px',
            width: '550px',
            top: '395px',
            left: '0px',
            position: 'absolute',
            backgroundImage: 'linear-gradient(to bottom, rgba(47, 48, 93, 0.1), rgba(47, 48 , 93, 1) 100%)',
        },
        tableRow: {
            height: '20px',
        },
        errorIcon: {
            fontSize: 80,
            color: theme.palette.primary.light,
        },
        errorText: {
            color: theme.palette.text.primary,
        },
        noDataIcon: {
            fontSize: 80,
            color: theme.palette.primary.light,
            width: '100%',
            marginTop: '144px',
        },
        noDataText: {
            color: theme.palette.text.primary,
            width: '100%',
            marginLeft: '215px',
        },
    }),
);

function Row(props: { entry: CurrencyTotal; idx: number }) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const { entry, idx } = props;

    // if (entry.exposure === 0 && entry.hedgeAmount === 0) {
    if (entry.exposure === 0 && entry.hedgeAmountWithOptions === 0) {
        return <div />;
    } else {
        return (
            <React.Fragment>
                <TableRow key={idx}>
                    <TableCell className={classes.cell}>
                        <IconButton
                            style={{ paddingLeft: '0px', float: 'left', right: '4px' }}
                            aria-label="expand row"
                            disabled={(entry.monthTotals || []).length === 0}
                            onClick={() => setOpen(!open)}
                            size="small"
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <div style={{ paddingTop: '7px', paddingRight: '4px' }}>{entry.currency}</div>
                    </TableCell>
                    <TableCell className={classes.cell}>
                        {entry.currencySign} {displayAmount(entry.exposure)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                        {entry.currencySign} {displayAmount(entry.hedgeAmountWithOptions)}
                    </TableCell>
                    <TableCell className={classes.cell}>{displayAmount(entry.hedgePercentageWithOptions)} %</TableCell>
                    <TableCell className={classes.cell} style={{ textAlign: 'center', paddingLeft: '30px' }}>
                        {displayAmount(entry.avgCostingRate)}
                    </TableCell>
                </TableRow>
                <TableRow key={idx + 'expand'}>
                    <TableCell
                        colSpan={5}
                        style={{
                            padding: 0,
                        }}
                        className={classes.cell}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Table>
                                <TableHead style={{ visibility: 'collapse' }}>
                                    <TableRow>
                                        <TableCell classes={{ head: classes.tableCellHead }}>Curr</TableCell>
                                        <TableCell classes={{ head: classes.tableCellHead }}>Exposure</TableCell>
                                        <TableCell classes={{ head: classes.tableCellHead }}>HedgeAmount</TableCell>
                                        <TableCell classes={{ head: classes.tableCellHead }}>Hedge%</TableCell>
                                        <TableCell classes={{ head: classes.tableCellHead }}>A/CostRate</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(entry.monthTotals || []).map((monthEntry, monthIdx) => {
                                        return (
                                            <TableRow key={monthIdx}>
                                                <TableCell className={classes.cell}>{monthEntry.month}</TableCell>
                                                <TableCell className={classes.cell}>
                                                    {entry.currencySign} {displayAmount(monthEntry.exposure)}
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    {/*{entry.currencySign} {displayAmount(monthEntry.hedgeAmount)}*/}
                                                    {entry.currencySign}{' '}
                                                    {displayAmount(monthEntry.hedgeAmountWithOptions)}
                                                </TableCell>
                                                <TableCell className={classes.cell}>
                                                    {/*{displayAmount(monthEntry.hedgePercentage)} %*/}
                                                    {displayAmount(monthEntry.hedgePercentageWithOptions)} %
                                                </TableCell>
                                                <TableCell
                                                    className={classes.cell}
                                                    style={{ textAlign: 'center', paddingLeft: '30px' }}
                                                >
                                                    {monthEntry.avgCostingRate.toFixed(4)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
}

const CoverageRatio = (): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const [{ response, loading, error }] = useService(
        {} as GenerateCoverageRatioReportRequest,
        Generator.GenerateCoverageRatioReport,
    );
    return (
        <React.Fragment>
            {(() => {
                if (appContext.currentContext?.partyType !== 'CLIENT') {
                    return (
                        <div className={classes.coverageWrapper}>
                            <FolderOpen className={classes.noDataIcon} />
                            <div className={classes.noDataText}>No Data to display</div>
                        </div>
                    );
                } else if (!response && loading) {
                    return (
                        <div className={classes.coverageWrapper}>
                            <div
                                className={classes.coverageValuesLoading}
                                style={{
                                    backgroundColor: theme.palette.custom.paperExtended.paper2,
                                    padding: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px 0px`,
                                    paddingTop: '190px',
                                }}
                            >
                                <Spinner color={theme.palette.primary.main} loading={loading} />
                            </div>
                        </div>
                    );
                } else if (response && !loading && error === '') {
                    return (
                        <div className={classes.coverageWrapper}>
                            <div className={classes.fadeWrapper} />
                            <div className={classes.coverageHeader}>HEDGING & EXPOSURE</div>
                            <div className={classes.tableWrapper}>
                                {response.totals.totalsImport !== undefined && (
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            font: 'Bold 12px/20px Roboto',
                                            letterSpacing: '0px',
                                            color: theme.palette.custom.import.main,
                                            opacity: 1,
                                            paddingLeft: '16px',
                                            paddingTop: '8px',
                                            paddingBottom: '8px',
                                        }}
                                    >
                                        IMPORTS
                                    </div>
                                )}
                                {response.totals.totalsImport !== undefined && (
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    classes={{ head: classes.tableCellHead }}
                                                    style={{ textAlign: 'center', paddingLeft: '15px' }}
                                                >
                                                    Currency
                                                </TableCell>
                                                <TableCell
                                                    classes={{ head: classes.tableCellHead }}
                                                    style={{ textAlign: 'right', paddingLeft: '0px' }}
                                                >
                                                    Exposure
                                                </TableCell>
                                                <TableCell
                                                    classes={{ head: classes.tableCellHead }}
                                                    style={{ textAlign: 'center', paddingLeft: '10px' }}
                                                >
                                                    Hedge Amount
                                                </TableCell>
                                                <TableCell classes={{ head: classes.tableCellHead }}>Hedge %</TableCell>
                                                <TableCell classes={{ head: classes.tableCellHead }}>
                                                    A/C/Rate
                                                    <Tooltip placement={'top'} title={'Weighted Average Costing Rate'}>
                                                        <span className={classes.helpIconWrapper}>
                                                            <HelpIcon className={classes.helpIcon} />
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(response.totals.totalsImport || []).map((entry, idx) => (
                                                <Row entry={entry} idx={idx} key={idx} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                                {response.totals.totalsExport !== undefined && (
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            font: 'Bold 12px/20px Roboto',
                                            letterSpacing: '0px',
                                            color: theme.palette.custom.export.main,
                                            opacity: 1,
                                            paddingTop: '16px',
                                            paddingLeft: '16px',
                                            paddingBottom: '8px',
                                        }}
                                    >
                                        EXPORTS
                                    </div>
                                )}
                                {response.totals.totalsExport !== undefined && (
                                    <Table aria-label="exports">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    classes={{ head: classes.tableCellHead }}
                                                    style={{ textAlign: 'center', paddingLeft: '15px' }}
                                                >
                                                    Currency
                                                </TableCell>
                                                <TableCell
                                                    classes={{ head: classes.tableCellHead }}
                                                    style={{ textAlign: 'right', paddingLeft: '40px' }}
                                                >
                                                    Exposure
                                                </TableCell>
                                                <TableCell
                                                    classes={{ head: classes.tableCellHead }}
                                                    style={{ textAlign: 'center', paddingLeft: '10px' }}
                                                >
                                                    Hedge Amount
                                                </TableCell>
                                                <TableCell classes={{ head: classes.tableCellHead }}>Hedge %</TableCell>
                                                <TableCell classes={{ head: classes.tableCellHead }}>
                                                    A/C/Rate
                                                    <Tooltip placement={'top'} title={'Weighted Average Costing Rate'}>
                                                        <span className={classes.helpIconWrapper}>
                                                            <HelpIcon className={classes.helpIcon} />
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(response.totals.totalsExport || []).map((entry, idx) => (
                                                <Row entry={entry} idx={idx} key={idx} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    padding: '0',
                                    height: '48px',
                                    color: theme.palette.custom.icons.main,
                                }}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div className={classes.coverageWrapper}>
                            <div className={classes.coverageValuesLoading}>
                                <ErrorIcon className={classes.errorIcon} />
                                <div className={classes.errorText}>{error}</div>
                            </div>
                        </div>
                    );
                }
            })()}
        </React.Fragment>
    );
};
export default CoverageRatio;
