import moment from 'moment';
import React from 'react';
import { round } from 'utils';
import { useTheme } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';

interface XAxisTickFormatterProps {
    dateFormat: string;
    payload?: unknown;
    x?: number;
    y?: number;
    xDiff?: (value: string) => number;
}

export const XAxisTickFormatter: React.FC<XAxisTickFormatterProps> = (props: XAxisTickFormatterProps) => {
    const { dateFormat, x, y, payload, xDiff } = props;
    const displayMonth = (payload as { value: string }).value || '';
    const currentMonth = moment().format(dateFormat);

    const theme = useTheme<CustomTheme>();

    const dx = -20 + (xDiff ? xDiff(displayMonth) : 0);
    if (displayMonth === currentMonth) {
        return (
            <g transform={`translate(${x},${(y || 0) - 15})`}>
                <text
                    dx={dx}
                    dy={0}
                    fill={theme.palette.primary.main}
                    fontSize={'12px'}
                    fontWeight={'bold'}
                    textAnchor="start"
                    x={0}
                    y={0}
                >
                    {displayMonth}
                </text>
            </g>
        );
    }
    return (
        <g transform={`translate(${x},${(y || 0) - 15})`}>
            <text dx={dx} dy={0} fill={theme.palette.text.primary} fontSize={'12px'} textAnchor="start" x={0} y={0}>
                {displayMonth}
            </text>
        </g>
    );
};

export const roundMillions = (value: number): string => {
    if (value < 10 && value > 0) {
        return value.toFixed(2).toString();
    } else if (value === 0) {
        return '00.0';
    } else {
        return value.toFixed(2);
    }
};

export const numberWithCommas = (x: number): string => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};

export const roundScaleAndFormatNumber = (
    amount: number,
    currencySymbol: string,
    showInMillions = false,
    showInThousands = false,
    decimals = 0,
    showSuffix = true,
): string => {
    try {
        const sign = amount < 0 ? '-' : '';
        const symbol = currencySymbol ? currencySymbol : '';
        const absAmount = Math.abs(amount);
        if (showInMillions) {
            const millions = absAmount / 1000000;
            return sign + symbol + `${roundMillions(millions)}${showSuffix ? 'm' : ''}`;
        }

        if (showInThousands) {
            const millions = absAmount / 1000;
            return sign + symbol + `${roundMillions(millions)}${showSuffix ? 'k' : ''}`;
        }

        return sign + symbol + numberWithCommas(round(absAmount, decimals));
    } catch (e) {
        console.error('error formatting and rounding number', e);
        return '0';
    }
};

export type dataScale = 'Millions' | 'Thousands';

export const scaleToNumber = (scale: dataScale): number => {
    switch (scale) {
        case 'Millions':
            return Math.pow(10, 6);
        case 'Thousands':
        default:
            return Math.pow(10, 3);
    }
};

export const categoryNameToMonthNum = (
    categoryName: string | number,
    xAxisDateFormat: string,
): { dateString: string; weekOfMonth: string } => {
    const dateInfo = categoryName.toString().split('_');
    return {
        dateString: moment(`${dateInfo[0]}-${dateInfo[1]}-01`).format(xAxisDateFormat),
        weekOfMonth: dateInfo[2],
    };
};

export const calculateTooltipOffset = (graphWidth: number, numberOfDataPoints: number): number => {
    return graphWidth / (numberOfDataPoints || 1) / 2;
};
