import React from 'react';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { HexToRGBA } from 'utils';
import Dialog from '@material-ui/core/Dialog';
import { ExposureGraphLayout } from 'views/Client/ExposureGraph/ExposureGraphLayout';
import { Currency } from 'popcorn-js/currency';
import { CustomTheme } from 'theme/custom';
import { BaseButton, VARIANT, SIZE, COLOR } from 'components/BaseButton';

interface ImageButtonProps {
    disabled: boolean;
    currency: Currency;
}

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        dialog: {
            overflow: 'hidden',
        },
        root: {
            display: 'grid',
            minWidth: 150,
            width: '100%',
            gridTemplateRows: 'auto auto',
            justifyContent: 'center',
            alignContent: 'space-between',
        },
        image: {
            height: '180px',
            borderRadius: '50%',
        },
        buttonContainedPrimary: {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
            fontSize: '12px',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: HexToRGBA(theme.palette.primary.main, 0.6),
            },
        },
        button: {
            width: '70px',
            height: '28px',
        },
        buttonWrapper: {
            justifySelf: 'center',
            marginBottom: '35px',
        },
    }),
);

export const ExposureGraphButton: React.FC<ImageButtonProps> = (props: ImageButtonProps) => {
    const [showExposureGraph, setShowExposureGraph] = React.useState<boolean>(false);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Dialog
                PaperProps={{ classes: { root: classes.dialog } }}
                fullWidth
                maxWidth={'lg'}
                onClose={(): void => setShowExposureGraph(false)}
                open={showExposureGraph}
                scroll={'body'}
                style={{ overflow: 'body' }}
            >
                <ExposureGraphLayout currency={props.currency} onClose={(): void => setShowExposureGraph(false)} />
            </Dialog>
            <div className={classes.buttonWrapper}>
                <BaseButton
                    id={'ExposureGraphButton/View'}
                    disabled={props.disabled}
                    variant={VARIANT.CONTAINED}
                    color={COLOR.ACTION}
                    size={SIZE.MEDIUM}
                    onClick={(): void => setShowExposureGraph(true)}
                    text={'View'}
                />
            </div>
        </div>
    );
};
