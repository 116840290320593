import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../../theme/custom';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { ProcessingBank } from 'popcorn-js/party';
import { BaseButton, COLOR, SIZE, VARIANT } from '../../../components/BaseButton';

export const Contacts = (props: {
    processingBank: ProcessingBank;
    disabled?: boolean;
    readOnly?: boolean;
    inputProps?: {
        classes: {
            underline: string;
        };
        disableUnderline: boolean;
        readOnly: boolean;
    };
    invalidFields?: Record<string, string>;
    handleFieldChange: (field: string, newValue: unknown, contact: number) => void;
    handleAdd?: () => void;
    handleRemove: (index: number) => void;
}): ReactElement => {
    const { inputProps, readOnly, processingBank, handleFieldChange, handleRemove, handleAdd } = props;
    const classes = useStyles();

    return (
        <div className={classes.contacts}>
            <div className={classes.contactsHeader}> Contacts </div>
            <div className={classes.contactsSelectCard}>
                <div className={classes.container}>
                    {(processingBank?.contacts || []).map((c, i) => (
                        <div key={i} className={classes.contactContainer}>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputField}>
                                    <BaseTextField
                                        disabled={readOnly}
                                        InputProps={inputProps}
                                        id="contracts/Role"
                                        label="Role"
                                        value={c.role || ''}
                                        onChange={(e) => handleFieldChange('role', e.target.value, i)}
                                    />
                                </div>
                                <div className={classes.inputField}>
                                    <BaseTextField
                                        disabled={readOnly}
                                        InputProps={inputProps}
                                        id="contracts/name"
                                        label="Name"
                                        value={c.name || ''}
                                        onChange={(e) => handleFieldChange('name', e.target.value, i)}
                                    />
                                </div>
                                <div className={classes.inputField}>
                                    <BaseTextField
                                        disabled={readOnly}
                                        InputProps={inputProps}
                                        id="contracts/email"
                                        label="Email"
                                        value={c.email || ''}
                                        onChange={(e) => handleFieldChange('email', e.target.value, i)}
                                    />
                                </div>
                                <div className={classes.inputField}>
                                    <BaseTextField
                                        disabled={readOnly}
                                        InputProps={inputProps}
                                        id="contracts/mobile"
                                        label="Mobile"
                                        value={c.mobile || ''}
                                        onChange={(e) => handleFieldChange('mobile', e.target.value, i)}
                                    />
                                </div>

                                <BaseButton
                                    id={'CashFlowButton/View'}
                                    variant={VARIANT.CONTAINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    onClick={() => handleRemove(i)}
                                    text={'Remove'}
                                />
                            </div>
                        </div>
                    ))}
                    <div style={{ marginLeft: '20px' }}>
                        <BaseButton
                            id={'CashFlowButton/View'}
                            variant={VARIANT.CONTAINED}
                            color={COLOR.ACTION}
                            size={SIZE.MEDIUM}
                            onClick={handleAdd} // show contact inputs
                            text={'Add'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    contactsSelectCard: {
        flexGrow: 1,
    },
    contacts: {
        height: '100%',
        width: '100%',
    },
    contactsHeader: {
        margin: '16px 0px 16px 26px ',
        fontSize: '18px',
    },
    wrapper: {
        padding: theme.spacing(1),
    },
    container: {
        width: '945px',
        marginLeft: '10px',
        overflowY: 'hidden',
    },
    contactContainer: {
        width: '910px',
        height: '60px',
        borderRadius: '6px',
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        padding: theme.spacing(1),
    },
    inputContainer: {
        display: 'flex',
        margin: '3px 0px 12px 10px',
        width: '950px',
        alignItems: 'center',
        //backgroundColor: 'red',
    },
    inputField: {
        width: '240px',
        height: '40px',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        padding: '0px 0px 15px 20px',
        marginRight: theme.spacing(3),
        borderRadius: '6px',
        //backgroundColor: 'green',
    },
}));
