import React, { ReactElement } from 'react';
import { Dialog } from '@material-ui/core';
import { OrderDetail } from './OrderDetail';
import { Order } from 'popcorn-js/order';
import { Counterparty } from 'popcorn-js/counterparty';

const OrderDetailDialog = (props: {
    counterparties: Counterparty[];
    onClose: () => void;
    onSaveSuccess: () => void;
    order: Order;
    readOnly?: boolean;
    show: boolean;
}): ReactElement => {
    const { show, onClose, onSaveSuccess, readOnly, counterparties } = props;

    return (
        <Dialog maxWidth={'lg'} onClose={onClose} open={show}>
            <div style={{ overflowY: 'scroll' }}>
                <OrderDetail
                    counterparties={counterparties}
                    onClose={onClose}
                    onSaveSuccess={onSaveSuccess}
                    order={props.order}
                    readOnly={!!readOnly}
                />
            </div>
        </Dialog>
    );
};

export default OrderDetailDialog;
