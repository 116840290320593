import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';

export type DownloadRequest = {
    /* empty */
};
export type DownloadResponse = {
    // base64 encoded
    data: string;
};

export type DownloadHistoricalRatesRequest = {
    /* empty */
};
export type DownloadHistoricalRatesResponse = {
    // base64 encoded
    data: string;
};

export const Downloader = {
    downloadRatesCache(request: DownloadRequest): Promise<DownloadResponse> {
        return jsonRPC<DownloadRequest, DownloadResponse>({
            url: config.get('pricingURL'),
            method: 'Rick-Exporter.ExportRateCache',
            request,
        });
    },

    downloadDailyRatesExcel(request: DownloadRequest): Promise<DownloadResponse> {
        return jsonRPC<DownloadRequest, DownloadResponse>({
            url: config.get('pricingURL'),
            method: 'Rick-Exporter.ExportRateExcel',
            request,
        });
    },

    downloadDailyRatesExcelSnapshot(request: DownloadRequest): Promise<DownloadResponse> {
        return jsonRPC<DownloadRequest, DownloadResponse>({
            url: config.get('pricingURL'),
            method: 'Rick-Exporter.ExportRateExcelSnapshot',
            request,
        });
    },

    downloadHistoricalRates(request: DownloadHistoricalRatesRequest): Promise<DownloadHistoricalRatesResponse> {
        return jsonRPC<DownloadRequest, DownloadResponse>({
            url: config.get('pricingURL'),
            method: 'TimeSeries.ExportTimeSeries',
            request,
        });
    },

    downloadDailyRatesPDF(request: DownloadRequest): Promise<DownloadResponse> {
        return jsonRPC<DownloadRequest, DownloadResponse>({
            url: config.get('pricingURL'),
            method: 'Rick-Exporter.ExportRatePDF',
            request,
        });
    },
};
