import { marshalIdentifier } from 'popcorn-js/search/identifier';
import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { Order } from '.';
import { FindRequest, FindResponse, RetrieveHistoryRequest, RetrieveHistoryResponse } from 'popcorn-js';
import { Criteria } from 'popcorn-js/search';
import { Identifier } from 'popcorn-js/search/identifier';
import { Invoice } from 'popcorn-js/invoice';

export type DeleteRequest = {
    identifier?: Identifier | string;
};
export type DeleteResponse = {
    order: Order;
};
export type AssociationsRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type AssociationsResponse = {
    invoice: Invoice;
};
export type CreateRequest = {
    order: Order;
};
export type CreateResponse = {
    order: Order;
};
export type CreateBatchRequest = {
    orders: Order[];
};
export type CreateBatchResponse = {
    succeeded: Order[];
    failed: Order[];
};
export type DeleteForeverRequest = {
    identifier?: Identifier | string;
};
export type DeleteForeverResponse = {
    order: Order;
};
export type RetrieveRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type RetrieveResponse = {
    order: Order;
};
export type RestoreRequest = {
    identifier?: Identifier | string;
};
export type RestoreResponse = {
    order: Order;
};
export type UpdateRequest = {
    identifier?: Identifier | string;
    order?: Order;
};
export type UpdateResponse = {
    order: Order;
};
export type Update = {
    identifier?: Identifier | string;
    order?: Order;
};
export type UpdateBatchRequest = {
    updates: Update[];
};
export type UpdateBatchResponse = {
    succeeded: Order[];
    failed: Order[];
};
export type ValidateBatchRequest = {
    orders?: Order[];
};
export type Duplicate = {
    oldOrder: Order;
    newOrder: Order;
};
export type InvalidReason = {
    reason: string;
    field: string;
    data: unknown;
};
export type Invalid = {
    order: Order;
    reasons: InvalidReason[];
};
export type ValidateBatchResponse = {
    duplicate?: Duplicate[];
    deleted?: Duplicate[];
    invalid?: Invalid[];
    unique?: Order[];
};
export type ValidateRequest = {
    order?: Order;
};
export type InvalidOrder = {
    order?: Order;
    reasons?: InvalidReason[];
};
export type ValidateResponse = {
    invalidOrder?: InvalidOrder;
};
export type DeleteBatchRequest = {
    numbers?: string[];
};
export type DeleteBatchResponse = {
    orders: Order[];
};
export type DeleteBatch = {
    identifier?: Identifier | string;
    order?: Order;
};

export type DeleteBatchForeverRequest = {
    deleteBatch: DeleteBatch[];
};
export type DeleteBatchForeverResponse = {
    orders: Order[];
};

export type RestoreBatch = {
    identifier?: Identifier | string;
    order?: Order;
};

export type RestoreBatchRequest = {
    restoreBatch: RestoreBatch[];
};
export type RestoreBatchResponse = {
    orders: Order[];
};
export const Recordkeeper = {
    ServiceProviderName: 'Order-Recordkeeper',
    find(request: FindRequest): Promise<FindResponse<Order>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<FindRequest, FindResponse<Order>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RetrieveRequest, RetrieveResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Retrieve`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    create(request: CreateRequest): Promise<CreateResponse> {
        return jsonRPC<CreateRequest, CreateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Create`,
            request: { ...request },
        });
    },
    createBatch(request: CreateBatchRequest): Promise<CreateBatchResponse> {
        return jsonRPC<CreateBatchRequest, CreateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.CreateBatch`,
            request: { ...request },
        });
    },
    update(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<UpdateRequest, UpdateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Update`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    updateBatch(request: UpdateBatchRequest): Promise<UpdateBatchResponse> {
        const updates: Update[] = request.updates
            .map((update: Update) => {
                if (update.identifier) {
                    return {
                        identifier: marshalIdentifier(update.identifier as Identifier),
                        order: update.order,
                    };
                }
                return {};
            })
            .filter((update: Update) => !!update.identifier);
        return jsonRPC<UpdateBatchRequest, UpdateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.UpdateBatch`,
            request: { updates },
        });
    },
    validateBatch(request: ValidateBatchRequest): Promise<ValidateBatchResponse> {
        return jsonRPC<ValidateBatchRequest, ValidateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.ValidateBatch`,
            request: { ...request },
        });
    },
    validate(request: ValidateRequest): Promise<ValidateResponse> {
        return jsonRPC<ValidateRequest, ValidateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Validate`,
            request: { ...request },
        });
    },
    restore(request: RestoreRequest): Promise<RestoreResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RestoreRequest, RestoreResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Restore`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    deleteForever(request: DeleteForeverRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteForeverRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.DeleteForever`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    retrieveHistory(request: RetrieveHistoryRequest): Promise<RetrieveHistoryResponse<Order>> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RetrieveHistoryRequest, RetrieveHistoryResponse<Order>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.RetrieveHistory`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
};
