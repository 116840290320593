import React, { useState, ReactElement, useEffect } from 'react';
import { Collapse, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION, ACTION_BUTTON_TYPE } from 'components/CardHeader/StandardCardHeader';
import HedgeCard from 'views/Client/HedgeCard';
import PnLCard from 'views/Client/PnLCard';
import CashProjectionCard from 'views/Client/CashProjectionCard';
import TradeCashFlowCard from 'views/Client/TradeCashFlowCard';
import { Refresh } from '@material-ui/icons';
import { format } from 'date-fns';
import { ActionButton } from 'components/ActionButton/ActionButton';

const Portfolio = (): ReactElement => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(true);
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);

    const formatTime = format(new Date(), 'kk:mm');

    useEffect(() => {
        setReloadTrigger(false);
    }, [reloadTrigger]);

    return (
        <React.Fragment>
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            id: 'PortfolioCard/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Portfolio',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ELEMENT,
                            id: 'PortfolioCard/refresh',
                            element: (
                                <div className={classes.timeRefreshWrapper}>
                                    <div className={classes.time}>{formatTime}</div>
                                    <div className={classes.refreshPosition}>
                                        <ActionButton
                                            id="refresh"
                                            onClick={() => setReloadTrigger(true)}
                                            icon={<Refresh />}
                                        />
                                    </div>
                                </div>
                            ),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/collapse',
                            icon: ACTION_BUTTON_TYPE.COLLAPSE,
                            helpText: 'Collapse',
                            onClick: () => setOpen(false),
                            hide: !open,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/expand',
                            icon: ACTION_BUTTON_TYPE.EXPAND,
                            helpText: 'Expand',
                            onClick: () => setOpen(true),
                            hide: open,
                        },
                    ],
                }}
            >
                <Collapse in={open}>
                    <div className={classes.portfolioCardContentRoot}>
                        {!reloadTrigger && <HedgeCard />}
                        {!reloadTrigger && <PnLCard />}
                        <CashProjectionCard />
                        {!reloadTrigger && <TradeCashFlowCard />}
                    </div>
                </Collapse>
            </StandardCard>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    portfolioCardContentRoot: {
        backgroundColor: theme.palette.background.paper,
        height: '305px',
        padding: 0,
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: 'auto auto 1fr auto',
        '&:last-child': {
            padding: 0,
        },
    },
    timeRefreshWrapper: {
        width: '66px',
        height: '22px',
        background: theme.palette.custom.paperExtended.paper6,
        borderRadius: '6px',
        padding: theme.spacing(0.5),
        fontSize: '12px',
        display: 'flex',
    },
    time: {
        margin: '5px 0px 5px 0px',
        fontWeight: 'bold',
    },
    refreshPosition: {
        margin: '0px 25px 0px 0px',
        display: 'flex',
    },
}));

export default Portfolio;
