import React, { ReactElement, useState } from 'react';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { StandardCard } from 'components/Card/Card';
import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { ParentAllocation } from './index';
import { LightNumberField } from './styledComponents';
import { FormatNumber } from 'utils';
import moment from 'moment';
import { SystemDateFormat } from 'constants/formats';
import Big from 'big.js';

export const ManageParentTrades = (props: {
    transactionsParents: ParentAllocation[];
    totalAllocatedAmount: Big;
    allocationsChange: (parentId: string, value: string) => void;
    currencyCode: string;
    allocationConfirmed: boolean;
}): ReactElement => {
    const classes = useStyles();

    const totalAvailableBalance = props.transactionsParents.reduce<Big>(function (a, b) {
        return a.add(Big(b.parentAvailableBalance));
    }, Big(0));

    const [expanded, setExpanded] = useState<boolean>(true);
    return (
        <>
            <StandardCard
                cardHeaderProps={{
                    fatter: true,
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            icon: expanded ? ACTION_BUTTON_TYPE.COLLAPSE : ACTION_BUTTON_TYPE.EXPAND,
                            onClick: () => setExpanded(!expanded),
                            id: 'expand',
                        },
                    ],
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TITLE,
                            text: 'Manage Parent Trades',
                            id: 'title',
                            variant: 'title2',
                        },
                    ],
                }}
            >
                <Collapse in={expanded} className={classes.content}>
                    <div className={classes.parentTradesHeading}>
                        <div className={classes.headingText}>External reference</div>
                        <div className={classes.headingText}>Trade Type</div>
                        <div className={classes.headingText}>Available Balance</div>
                        <div className={classes.headingText}>Buy/Sell</div>
                        <div className={classes.headingText}>Maturity Date</div>
                        <div className={classes.headingText}>Bank</div>
                        <div className={classes.headingText}>Allocated Amount</div>
                    </div>
                    {props.transactionsParents.map((parentTrade: ParentAllocation, k: number) => (
                        <div key={k}>
                            <div className={classes.parentTradesRow}>
                                <div className={classes.valueText}>{parentTrade.parentExternalReference}</div>
                                <div className={classes.valueText}>{parentTrade.parentType}</div>
                                <div className={classes.valueText}>
                                    {FormatNumber(parentTrade.parentAvailableBalance, true, true, 2)}
                                </div>
                                <div className={classes.valueText}>{parentTrade.parentDirection}</div>
                                <div className={classes.valueText}>
                                    {moment(parentTrade.parentMaturityDate).format(SystemDateFormat)}
                                </div>
                                <div className={classes.valueText}>{parentTrade.parentBank}</div>
                                <div className={classes.valueText}>
                                    <LightNumberField
                                        prefix={props.currencyCode}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            props.allocationsChange(parentTrade.id, e.target.value)
                                        }
                                        value={parentTrade.amount}
                                        disabled={props.allocationConfirmed == true}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className={classes.totalsRow}>
                        <div className={classes.totalsText}></div>
                        <div className={classes.totalsText}>Total Available Balance:</div>
                        <div className={classes.totalsValueText}>
                            {FormatNumber(totalAvailableBalance.toFixed(2), true, true, 2)}
                        </div>
                        <div className={classes.totalsText}></div>
                        <div className={classes.totalsText}></div>
                        <div className={classes.totalsText}>Total Allocated Amount:</div>
                        <div className={classes.totalsValueText}>
                            {props.currencyCode} {FormatNumber(props.totalAllocatedAmount.toFixed(2), true, true, 2)}
                        </div>
                    </div>
                </Collapse>
            </StandardCard>
        </>
    );
};

export const MemoizedManageParentTrades = React.memo(ManageParentTrades);

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: { backgroundColor: theme.palette.background.paper },
    parentTradesHeading: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        height: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.light,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    parentTradesRow: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.dark,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    totalsRow: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        height: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.dark,
        borderTop: `solid 2px ${theme.palette.custom.dividerExtended.ver_div2}`,
    },
    headingText: {
        width: '160px',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        fontSize: '14px',
    },
    totalsText: {
        width: '160px',
        fontWeight: 'bold',
        textAlign: 'left',
        color: theme.palette.custom.text.label,
        fontSize: '14px',
    },
    valueText: {
        width: '160px',
        color: theme.palette.text.primary,
        fontSize: '16px',
    },
    totalsValueText: {
        width: '160px',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        fontSize: '16px',
    },
    action: {
        width: '160px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));
