import { restRPC } from 'utils/restrpc';
import { Identifier, marshalIdentifier } from 'popcorn-js/search/identifier';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { Order } from '.';
import { FindRequest, FindResponse } from 'popcorn-js';
import { Criteria } from 'popcorn-js/search';
import {
    CreateBatchRequest,
    CreateBatchResponse,
    DeleteRequest,
    DeleteResponse,
    UpdateBatchRequest,
    UpdateBatchResponse,
    UpdateRequest,
    UpdateResponse,
    DeleteBatchRequest,
    DeleteBatchResponse,
    DeleteBatchForeverRequest,
    DeleteBatchForeverResponse,
    RestoreBatchRequest,
    RestoreBatchResponse,
    DeleteBatch,
    RestoreBatch,
} from './recordkeeper';

export type Update = {
    identifier?: Identifier | string;
    order?: Order;
};
export type ValidateBatchRequest = {
    orders?: Order[];
};
export type Duplicate = {
    oldOrder: Order;
    newOrder: Order;
};
export type InvalidReason = {
    reason: string;
    field: string;
    data: unknown;
};
export type Invalid = {
    order: Order;
    reasons: InvalidReason[];
};
export type ValidateBatchResponse = {
    duplicate?: Duplicate[];
    deleted?: Duplicate[];
    invalid?: Invalid[];
    unique?: Order[];
};
export type RecordRequest = {
    order: Order;
};
export type RecordResponse = {
    order: Order;
    // toto fix this
    Order: Order;
};

export type HandlerFindResponse = FindResponse<Order>;

export const Handler = {
    ServiceProviderName: 'Order-Handler',
    ValidateBatch(request: ValidateBatchRequest): Promise<ValidateBatchResponse> {
        return restRPC<ValidateBatchRequest, ValidateBatchResponse>({
            method: `${Handler.ServiceProviderName}.ValidateBatch`,
            request,
        });
    },
    Record(request: RecordRequest): Promise<RecordResponse> {
        return jsonRPC<RecordRequest, RecordResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Record`,
            request,
        });
    },
    Find(request: FindRequest): Promise<FindResponse<Order>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    CreateBatch(request: CreateBatchRequest): Promise<CreateBatchResponse> {
        return jsonRPC<CreateBatchRequest, CreateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.CreateBatch`,
            request: { ...request },
        });
    },
    Update(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<UpdateRequest, UpdateResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Update`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    UpdateBatch(request: UpdateBatchRequest): Promise<UpdateBatchResponse> {
        const updates: Update[] = request.updates
            .map((update: Update) => {
                if (update.identifier) {
                    return {
                        identifier: marshalIdentifier(update.identifier as Identifier),
                        order: update.order,
                    };
                }
                return {};
            })
            .filter((update: Update) => !!update.identifier);
        return jsonRPC<UpdateBatchRequest, UpdateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.UpdateBatch`,
            request: { updates },
        });
    },
    Delete(request: DeleteRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.Delete`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    DeleteBatch(request: DeleteBatchRequest): Promise<DeleteBatchResponse> {
        return jsonRPC<DeleteBatchRequest, DeleteBatchResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.DeleteBatch`,
            request: {
                ...request,
            },
        });
    },
    DeleteBatchForever(request: DeleteBatchForeverRequest): Promise<DeleteBatchForeverResponse> {
        const deleteBatch: DeleteBatch[] = request.deleteBatch
            .map((order: DeleteBatch) => {
                if (order.identifier) {
                    return {
                        identifier: marshalIdentifier(order.identifier as Identifier),
                        order: order.order,
                    };
                }
                return {};
            })
            .filter((order: DeleteBatch) => !!order.identifier);
        return jsonRPC<DeleteBatchForeverRequest, DeleteBatchForeverResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.DeleteBatchForever`,
            request: { deleteBatch },
        });
    },
    RestoreBatch(request: RestoreBatchRequest): Promise<RestoreBatchResponse> {
        const restoreBatch: RestoreBatch[] = request.restoreBatch
            .map((order: RestoreBatch) => {
                if (order.identifier) {
                    return {
                        identifier: marshalIdentifier(order.identifier as Identifier),
                        order: order.order,
                    };
                }
                return {};
            })
            .filter((order: RestoreBatch) => !!order.identifier);
        return jsonRPC<RestoreBatchRequest, RestoreBatchResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.RestoreBatch`,
            request: { restoreBatch },
        });
    },
};

export default Handler;
