import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import {
    DeleteRequest,
    DeleteResponse,
    Recordkeeper as CounterpartyRecordkeeper,
} from 'popcorn-js/counterparty/recordkeeper';
import Table from 'components/Table/Table';
import CounterpartyDetailDialog from 'components/counterparty/CounterpartyDetailDialog';
import { Criteria, CriteriaType, Criterion, Query } from 'popcorn-js/search';
import { Counterparty } from 'popcorn-js/counterparty';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { STATES, ITEM_VARIATION, ACTION_BUTTON_TYPE } from 'components/CardHeader/StandardCardHeader';
import { StandardCard } from 'components/Card/Card';
import Big from 'big.js';
import { useStyletron } from 'styletron-react';
import { useService, useServiceSync } from 'hooks/useService';
import { AppContext, AppContextT } from 'context';
import { PartyType } from 'popcorn-js/party';

const pageSize = 12;

const CounterpartyStation = (): ReactElement => {
    const [css] = useStyletron();
    const appContext = useContext<AppContextT>(AppContext);
    const usersContext = appContext.currentContext?.partyType;

    const [counterparties, setCounterparties] = useState<Counterparty[]>([]);
    const [selected, setSelected] = useState<Counterparty | undefined>();
    const [criteria, setCriteria] = useState<Criterion[]>([]);
    const [query, setQuery] = useState<Query>({
        sortBy: ['name'],
        order: ['asc'],
        limit: pageSize,
        offset: 0,
    });
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);
    const [viewDelete, setViewDelete] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [showCounterpartyDetail, setShowCounterpartyDetail] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [isClient, setIsClient] = useState<boolean>(false);

    const [counterpartyDelete] = useServiceSync<DeleteRequest, DeleteResponse>(CounterpartyRecordkeeper.delete);
    const generateFindCounterpartiesRequest = useCallback(() => {
        return {
            criteria: criteria,
            query: query,
            deleted: viewDelete,
        };
    }, [query, criteria, viewDelete]);

    /* find Counterparties --------------------------------------------------------------------- */
    const [
        { response: findCounterpartiesResponse, loading: findCounterpartiesLoading, error: findCounterpartiesError },
        setFindCounterpartiesRequest,
    ] = useService(undefined, CounterpartyRecordkeeper.find);

    const refresh = () => {
        window.location.reload();
    };

    useEffect(() => {
        setIsClient(() => {
            return usersContext === PartyType.CLIENT;
        });
    }, []);

    useEffect(() => {
        if (findCounterpartiesResponse && findCounterpartiesResponse.records && !findCounterpartiesLoading) {
            setTotal(findCounterpartiesResponse.total);
            setCounterparties(findCounterpartiesResponse.records);
        }
        if (findCounterpartiesError) {
            setErrorMessage(findCounterpartiesError);
        }
    }, [findCounterpartiesResponse, findCounterpartiesLoading, findCounterpartiesError]);

    useEffect(() => {
        setFindCounterpartiesRequest(generateFindCounterpartiesRequest());
    }, [generateFindCounterpartiesRequest, setFindCounterpartiesRequest, query, criteria]);

    const handleSelection = (c: Counterparty) => {
        if (selected) {
            setSelected(undefined);
        } else {
            setSelected(c);
        }
    };

    const handleFilterChange = (filterCriteria: Criteria) => {
        setCriteria(filterCriteria);
        const newQuery = {
            ...query,
            offset: 0,
        };
        setQuery(newQuery);
    };

    const handleChangePage = (_event: unknown, page: number) => {
        const offset = query.limit ? query.limit * page : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
    };
    const handleChangeSorting = (field: string, order: 'asc' | 'desc') => {
        const newQuery = {
            ...query,
            sortBy: [field],
            order: [order],
        };
        setQuery(newQuery);
    };
    const handleToggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const handleShowDetail = () => {
        setShowCounterpartyDetail(true);
    };

    const handleHideDetail = () => {
        setShowCounterpartyDetail(false);
        setLoading(false);
    };

    const handleDelete = async () => {
        if (!selected) {
            return;
        }
        try {
            const deleteResponse = await counterpartyDelete({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const newCounterparties = counterparties.slice();
            const indexToRemove = counterparties.findIndex(
                (c: Counterparty) => c.id === deleteResponse.counterparty.id,
            );
            if (indexToRemove >= 0) {
                newCounterparties.splice(indexToRemove, 1);
            }
            setSuccessMessage('Counterparty Deleted');
            setCounterparties(newCounterparties);
            setSelected(undefined);
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    const showDeleteForeverConfirmation = (counterparty: Counterparty) => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(
            `You are about to delete counterparty '${counterparty.name}' forever. Do you want to continue?`,
        );
        setConfirmationMethod(() => async () => {
            setLoading(true);
            try {
                await CounterpartyRecordkeeper.deleteForever({
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: counterparty.id },
                });
                setSuccessMessage('Counterparty Deleted Forever');
                setWarningMessage(undefined);
                setConfirmationMethod(undefined);
                setErrorMessage(undefined);
                setSelected(undefined);
            } catch (e) {
                setSuccessMessage(undefined);
                setWarningMessage(undefined);
                setConfirmationMethod(undefined);
                setErrorMessage(e.message || e);
            }
            setLoading(false);
            refresh();
        });
    };

    const handleRestore = async () => {
        if (!selected) {
            return;
        }
        try {
            const restoreResponse = await CounterpartyRecordkeeper.restore({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const newCounterparties = counterparties.slice();
            const indexToRemove = counterparties.findIndex(
                (b: Counterparty) => b.id === restoreResponse.counterparty.id,
            );
            if (indexToRemove >= 0) {
                newCounterparties.splice(indexToRemove, 1);
            }
            setSuccessMessage('Counterparty Restored');
            setCounterparties(newCounterparties);
            setSelected(undefined);
            refresh();
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    const handleViewDeleted = () => {
        setSelected(undefined);
        setViewDelete(true);
    };

    const handleReturn = () => {
        setViewDelete(false);
        setSelected(undefined);
    };

    const handleNew = () => {
        setLoading(true);
        setSelected(undefined);
        handleShowDetail();
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
    };

    const handleDetailDialogSaveSuccess = (counterparty: Counterparty) => {
        if (loading) {
            setLoading(false);
        } else {
            const newCounterparties = counterparties.slice();
            const indexToReplace = counterparties.findIndex((c: Counterparty) => c.id === counterparty.id);
            if (indexToReplace >= 0) {
                newCounterparties[indexToReplace] = counterparty;
            }
            setCounterparties(newCounterparties);
        }
        handleSelection(counterparty);
        setShowCounterpartyDetail(false);
        setQuery(query);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery = {
            ...query,
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
    };

    const renderDialogs = () => {
        return (
            <React.Fragment>
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    successMessage={successMessage}
                    onConfirm={confirmationMethod}
                    warningMessage={warningMessage}
                />
                {showCounterpartyDetail && (
                    <CounterpartyDetailDialog
                        counterparty={selected}
                        isNew={loading}
                        onClose={handleHideDetail}
                        onSaveSuccess={handleDetailDialogSaveSuccess}
                        readOnly={viewDelete}
                        show={showCounterpartyDetail}
                    />
                )}
            </React.Fragment>
        );
    };

    return (
        <div
            className={css({
                height: 'calc(100vh - 100px)',
                overflowY: 'scroll',
                justifyItems: 'center',
            })}
        >
            <Grid container>
                <Grid item xs={12}>
                    <StandardCard
                        cardHeaderProps={{
                            tailoredState: !!selected ? STATES.SELECTED_ROW : undefined,
                            itemsLeft: [
                                {
                                    id: 'CounterpartyStation/title',
                                    type: ITEM_VARIATION.TITLE,
                                    text: !viewDelete ? 'Counterparties' : 'Deleted Counterparties',
                                },
                            ],
                            itemsRight: [
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    helpText: 'View',
                                    id: 'CounterpartyStation/view',
                                    icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                                    onClick: handleShowDetail,
                                    hide: !selected,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    helpText: 'Restore',
                                    id: 'CounterpartyStation/restore',
                                    icon: ACTION_BUTTON_TYPE.RESTORE,
                                    onClick: handleRestore,
                                    hide: !selected || !viewDelete || !isClient,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'CounterpartyStation/delete-forever',
                                    icon: ACTION_BUTTON_TYPE.DELETE,
                                    helpText: 'Delete Forever',
                                    onClick: () => showDeleteForeverConfirmation(selected || ({} as Counterparty)),
                                    hide: !selected || !viewDelete || !isClient,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    id: 'CounterpartyStation/delete',
                                    icon: ACTION_BUTTON_TYPE.DELETE,
                                    helpText: 'Delete',
                                    onClick: handleDelete,
                                    hide: !selected || viewDelete || !isClient,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    helpText: 'Filter',
                                    id: 'CounterpartyStation/filter',
                                    icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                                    onClick: handleToggleFilter,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    helpText: 'Back',
                                    id: 'CounterpartyStation/back',
                                    icon: ACTION_BUTTON_TYPE.RETURN,
                                    onClick: handleReturn,
                                    hide: !viewDelete,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    helpText: 'View Deleted',
                                    id: 'CounterpartyStation/view-deleted',
                                    icon: ACTION_BUTTON_TYPE.VIEW_DELETED,
                                    onClick: handleViewDeleted,
                                    hide: viewDelete,
                                },
                                {
                                    type: ITEM_VARIATION.ICON_BUTTON,
                                    helpText: 'Create New',
                                    id: 'CounterpartyStation/create-new',
                                    icon: ACTION_BUTTON_TYPE.NEW,
                                    onClick: handleNew,
                                    hide: viewDelete || !!selected || !isClient,
                                },
                            ],
                        }}
                    >
                        <div
                            className={css({
                                maxHeight: 'calc(100vh - 100px)',
                            })}
                        >
                            <Table
                                columns={[
                                    {
                                        title: 'Name',
                                        field: 'name',
                                        filter: { type: CriteriaType.TextCriterion },
                                        render: (c: Counterparty) => c.name,
                                    },
                                    {
                                        title: 'External Reference',
                                        field: 'externalReference',
                                        filter: { type: CriteriaType.TextCriterion },
                                        render: (c: Counterparty) => c.externalReference,
                                    },
                                ]}
                                defaultColConfig={[
                                    { header: 'Name', visible: true },
                                    { header: 'External Reference', visible: true },
                                ]}
                                rowClickAction={handleSelection}
                                rowDoubleClickAction={(rowData: Counterparty) => {
                                    setSelected(rowData);
                                    handleShowDetail();
                                }}
                                onRowCheck={handleSelection}
                                rowsPerPage={query.limit}
                                rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                selected={selected ? [selected] : []}
                                onChangeSorting={handleChangeSorting}
                                handleChangePage={handleChangePage}
                                sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                onFilterChange={handleFilterChange}
                                data={counterparties}
                                loading={findCounterpartiesLoading}
                                count={total}
                                page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                showFilterRow={showFilter}
                                onSelectAll={() => setSelected(undefined)}
                                showCheckboxes
                            />
                        </div>
                    </StandardCard>
                </Grid>
            </Grid>
            {renderDialogs()}
        </div>
    );
};

export default CounterpartyStation;
