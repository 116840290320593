import React, { ReactElement, useContext } from 'react';
import { Card, Dialog, List, ListItem, makeStyles } from '@material-ui/core';
import { HexToRGBA } from 'utils/';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Comparator from 'popcorn-js/apiUser/comparator';
import { Recordkeeper as RoleRecordkeeper } from 'popcorn-js/role/recordkeeper';
import { CustomTheme } from 'theme/custom';
import { Role } from 'popcorn-js/security/role';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { ITEM_VARIATION, ACTION_BUTTON_TYPE } from 'components/CardHeader/StandardCardHeader';
import { AppContext, AppContextT } from 'context';
import { ApiUser } from 'popcorn-js/apiUser';
import {
    CreateRequest,
    CreateResponse,
    DeleteForeverRequest,
    DeleteForeverResponse,
    UpdateRequest,
    UpdateResponse,
    Recordkeeper as ApiUserRecordkeeper,
} from 'popcorn-js/apiUser/recordkeeper';
import APIUserDetails from './APIUserDetails';
import { StandardCard } from 'components/Card/Card';
import { useStyletron } from 'styletron-react';
import { BaseButton, COLOR as BaseButtonColor, SIZE, VARIANT } from 'components/BaseButton';
import { FindRequest, FindResponse } from 'popcorn-js';
import { useServiceSync } from 'hooks/useService';
import { CriteriaType } from 'popcorn-js/search';

export const APIUserConfiguration = (): ReactElement => {
    const classes = useStyles();

    const appContext = useContext<AppContextT>(AppContext);
    const partyCode = appContext.party?.partyCode || '';

    const [apiUsers, setApiUsers] = React.useState<Array<ApiUser>>([]);
    const [roles, setRoles] = React.useState<Role[]>([]);
    const [selected, setSelected] = React.useState<ApiUser>();
    const [original, setOriginal] = React.useState<ApiUser>();
    const [message, setMessage] = React.useState<{
        success?: string;
        error?: string;
        warn?: string;
        confirm?: () => void;
    }>({});
    const [invokedAction, setInvokedAction] = React.useState<number>(invokedActions.init);
    const [deleted, setDeleted] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(true);
    const [apiKeyToDisplay, setApiKeyToDisplay] = React.useState<string | undefined>();
    const [css] = useStyletron();

    const [roleFind] = useServiceSync<FindRequest, FindResponse<Role>>(RoleRecordkeeper.find);
    const [apiUserFind] = useServiceSync<FindRequest, FindResponse<ApiUser>>(ApiUserRecordkeeper.find);
    const [apiUserCreate] = useServiceSync<CreateRequest, CreateResponse>(ApiUserRecordkeeper.create);
    const [apiUserUpdate] = useServiceSync<UpdateRequest, UpdateResponse>(ApiUserRecordkeeper.update);
    const [apiUserDeleteForever] = useServiceSync<DeleteForeverRequest, DeleteForeverResponse>(
        ApiUserRecordkeeper.deleteForever,
    );

    React.useEffect(() => {
        setLoading(true);
        roleFind({
            criteria: [{ type: CriteriaType.ExactCriterion, field: 'partyCode', text: partyCode }],
            query: { sortBy: ['name'] },
        }).then((response) => {
            const roles = response.records || [];
            setRoles(roles);
        });
        apiUserFind({
            criteria: [{ type: CriteriaType.ExactCriterion, field: 'partyCode', text: partyCode }],
            query: {},
            deleted: deleted,
        }).then((response) => {
            const apiUsers = response.records || [];

            setApiUsers(apiUsers);

            if (apiUsers.length > 0) {
                setSelected(apiUsers[0]);
                setOriginal(apiUsers[0]);
            }
        });
        setLoading(false);
    }, [partyCode, deleted]);

    const handleDeleteForever = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            await apiUserDeleteForever({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const list = apiUsers.slice();
            const index = apiUsers.findIndex((u) => u.id === selected.id);
            list.splice(index, 1);
            setApiUsers(list);

            if (list.length > 0) {
                const lastIdx = list.length - 1;
                setSelected(list[lastIdx]);
            } else {
                setSelected(undefined);
                setDeleted(false);
            }

            setMessage({
                success: 'Api User deleted forever successfully',
            });
        } catch (e) {
            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };

    const handleNew = (): void => {
        setSelected({
            partyCode,
            id: '',
            apiKey: '',
            description: '',
            accountLocked: false,
            authenticationAllowed: false,
        } as ApiUser);
        setOriginal(undefined);
        setInvokedAction(invokedActions.New);
    };

    const handleCreate = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            selected.partyCode = partyCode;
            const createNewUserResponse = await apiUserCreate({ apiUser: selected });
            const list = apiUsers.slice();
            list.push(createNewUserResponse.apiUser);
            setApiUsers(list);
            setSelected(createNewUserResponse.apiUser);
            setOriginal(createNewUserResponse.apiUser);
            setInvokedAction(invokedActions.Save);
            setApiKeyToDisplay(createNewUserResponse.apiUser.apiKey);
        } catch (e) {
            console.error('handleCreate', e);

            setMessage({
                error: e.message || e,
            });
        }
        setLoading(false);
    };

    const handleUpdate = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        setLoading(true);
        try {
            const updateResponse = await apiUserUpdate({
                apiUser: selected,
                id: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const index = apiUsers.findIndex((apiUser) => apiUser.id === selected.id);
            const list = apiUsers.slice();
            list.splice(index, 1, updateResponse.apiUser);
            setApiUsers(list);
            setSelected(updateResponse.apiUser);
            setOriginal(updateResponse.apiUser);

            setMessage({
                success: 'Saved successfully',
            });
            setInvokedAction(invokedActions.Save);
        } catch (e) {
            console.error('handleUpdate', e);
            setMessage({
                error: e.message || e,
            });
        }

        setLoading(false);
    };

    const handleDiscard = (): void => {
        if (!selected || !selected.id || selected.id === '') {
            if (Comparator.IsDirty({ ...selected, partyCode: '' }, original)) {
                setMessage({
                    warn: 'Discard changes?',
                    confirm: () => {
                        setSelected(apiUsers[0]);
                        setOriginal(apiUsers[0]);
                        setMessage({});
                    },
                });
            } else {
                if (apiUsers.length > 0) {
                    setSelected(apiUsers[0]);
                    setOriginal(apiUsers[0]);
                } else {
                    setSelected({ partyCode } as ApiUser);
                }
                setMessage({});
            }
        } else {
            const original = apiUsers.find((apiUser) => apiUser.id === selected.id);
            if (!original) {
                console.error('No original found!');
            }
            if (Comparator.IsDirty(selected, original)) {
                setMessage({
                    warn: 'Discard changes?',
                    confirm: () => {
                        setSelected(original);
                        setMessage({});
                    },
                });
            }
        }
        setInvokedAction(invokedActions.Delete);
    };

    const handleSave = async (): Promise<void> => {
        if (!selected) {
            return;
        }
        if (selected.id) {
            await handleUpdate();
        } else {
            await handleCreate();
        }
    };

    const handleSelect = (apiUser: ApiUser): void => {
        setInvokedAction(invokedActions.Selected);
        setSelected(apiUser);
        setOriginal(apiUser);
    };

    const closeAlert = (): void => {
        setMessage({});
    };

    const list = (): ReactElement[] => {
        if (!selected) {
            return [];
        }

        return apiUsers.map((apiUser) => (
            <ListItem
                button
                component="li"
                key={apiUser.id}
                onClick={(): void => handleSelect(apiUser)}
                selected={apiUser.id === selected.id}
            >
                {apiUser.description}
            </ListItem>
        ));
    };

    const changed = Comparator.IsDirty(selected, original);

    return (
        <StandardCard
            cardHeaderProps={{
                fullHeight: true,
                itemsLeft: [
                    {
                        id: 'APIUserConfiguration/title',
                        type: ITEM_VARIATION.TITLE,
                        text: deleted ? 'Deleted Api Users' : 'Api Users',
                    },
                ],
                itemsRight: [
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'APIUserConfiguration/new',
                        icon: ACTION_BUTTON_TYPE.NEW,
                        helpText: 'New',
                        onClick: handleNew,
                        hide: (deleted || changed) && apiUsers.length != 0,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'APIUserConfiguration/return',
                        icon: ACTION_BUTTON_TYPE.RETURN,
                        helpText: 'Return',
                        // onClick: toggleDelete,
                        onClick: () => {
                            return;
                        },
                        hide: !deleted || (selected && changed),
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'APIUserConfiguration/delete-forever',
                        icon: ACTION_BUTTON_TYPE.DELETE,
                        helpText: 'Delete forever',
                        onClick: handleDeleteForever,
                        hide: deleted || changed,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'APIUserConfiguration/save',
                        icon: ACTION_BUTTON_TYPE.SAVE,
                        helpText: 'Save',
                        onClick: handleSave,
                        hide: !changed,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'APIUserConfiguration/discard',
                        icon: ACTION_BUTTON_TYPE.CANCEL,
                        helpText: 'Discard',
                        onClick: handleDiscard,
                        hide: !changed,
                    },
                ],
            }}
        >
            <div className={classes.content}>
                {isLoading && (
                    <Dialog
                        BackdropProps={{ classes: { root: classes.progressSpinnerDialogBackdrop } }}
                        PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                        open={isLoading}
                    >
                        <CircularProgress className={classes.progress} />
                    </Dialog>
                )}
                <Card elevation={0} className={classes.listWrapper}>
                    <List className={classes.list} component="ul">
                        {list()}
                    </List>
                </Card>
                <APIUserDetails
                    handleChange={(apiUser: ApiUser): void => {
                        setSelected(apiUser);
                    }}
                    invokedAction={invokedAction}
                    roles={roles}
                    apiUser={selected}
                />
                <NotificationSweetAlert
                    errorMessage={message.error}
                    onClose={closeAlert}
                    onConfirm={message.confirm}
                    successMessage={message.success}
                    warningMessage={message.warn}
                />
                <Dialog open={Boolean(apiKeyToDisplay)} maxWidth={'sm'} fullWidth>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'APIUserConfiguration/api-key',
                                    type: ITEM_VARIATION.TITLE,
                                    text: 'API Key Created',
                                },
                            ],
                        }}
                    >
                        <div
                            className={css({
                                padding: '8px',
                                fontSize: '14px',
                            })}
                        >
                            {`Please make a copy of your api key, you won't be able to access this again later.`}
                            <div
                                className={css({
                                    padding: '16px',
                                    fontSize: '14px',
                                })}
                            >
                                <code>{apiKeyToDisplay}</code>
                            </div>
                            <BaseButton
                                id={'APIUserConfiguration/copy-to-clipboard'}
                                variant={VARIANT.CONTAINED}
                                color={BaseButtonColor.ACTION}
                                size={SIZE.MEDIUM}
                                onClick={() => {
                                    navigator.clipboard
                                        .writeText(apiKeyToDisplay || '')
                                        .then(() => {
                                            alert('API Key copied to clipboard');
                                        })
                                        .catch((e) => {
                                            alert(`Can't copy to clipboard: ${e}`);
                                        })
                                        .finally();
                                    setApiKeyToDisplay(undefined);
                                }}
                                text={'Copy to Clipboard'}
                            />
                        </div>
                    </StandardCard>
                </Dialog>
            </div>
        </StandardCard>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    card: {
        border: `1px solid ${theme.palette.primary.light}`,
    },
    title: {
        fontWeight: 'bold',
    },
    cardContent: {
        padding: 0,
    },
    cardHeaderRoot: {
        backgroundColor: HexToRGBA(theme.palette.text.primary, 0.1),
        padding: theme.spacing(1),
    },
    list: {
        overflowY: 'auto',
        paddingTop: '0',
        height: '100%',
    },
    progress: {
        color: theme.palette.text.secondary,
        margin: theme.spacing(2),
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    content: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    listWrapper: {
        flex: '1 1 auto',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        maxWidth: '200px',
    },
}));

const invokedActions = {
    init: -1,
    Selected: 0,
    Save: 1,
    New: 2,
    Delete: 3,
    Discard: 4,
    UserInvite: 5,
};
