/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Note that columns are directly proportional to props.children
import moment from 'moment';
import { SystemDateFormat, SystemDateTimeFormat } from 'constants/formats';
import Big from 'big.js';
import { addMinutes, isWeekend, addDays, formatISO, format, fromUnixTime } from 'date-fns';
import { getSignBasedOnType, Invoice } from '../popcorn-js/invoice';

export const simpleCompare = (a: Record<string, unknown>, b: Record<string, unknown>): boolean => {
    return JSON.stringify(a) === JSON.stringify(b);
};

// CompareObjects compares two nested objects
// TODO that this has not been implemented to compare arrays nested within the object
export const CompareObjects = (o1: any, o2: any, recursionDepth = 0): boolean => {
    recursionDepth = recursionDepth + 1;

    if (recursionDepth > 30) {
        console.error('max recursion depth exceeded, abandoning object compare');
        return false;
    }

    if (!o1 || !o2) {
        return false;
    }
    for (const p in o1) {
        if (o1.hasOwnProperty(p)) {
            if (isObject(o1[p])) {
                if (!CompareObjects(o1[p], o2[p], recursionDepth)) {
                    return false;
                }
            } else if (o1[p] !== o2[p]) {
                return false;
            }
        }
    }
    for (const p in o2) {
        if (o2.hasOwnProperty(p)) {
            if (isObject(o2[p])) {
                if (!CompareObjects(o1[p], o2[p], recursionDepth)) {
                    return false;
                }
            } else if (o1[p] !== o2[p]) {
                return false;
            }
        }
    }
    return true;
};

export function isString(value: any) {
    return typeof value === 'string';
}

export function isArray(value: any) {
    return value && typeof value === 'object' && value.constructor === Array;
}

export function isObject(value: any) {
    return value && typeof value === 'object' && {}.toString.apply(value) === '[object Object]';
}

export function isNumber(value: any) {
    return typeof value === 'number' && isFinite(value);
}

export function isFunction(value: any) {
    return typeof value === 'function';
}

export function round(value: number, decimals: number) {
    const wasNegative = value < 0;
    value = Number(Math.round(Math.abs(value) * Math.pow(10, decimals)) * Math.pow(10, -decimals));
    return wasNegative ? -1 * value : value;
}

export function objectCopy(input: any, recursionDepth?: any) {
    // Increment recursion depth to prevent infinite recursion
    // with circular objects
    recursionDepth = recursionDepth ? recursionDepth + 1 : 1;

    if (recursionDepth > 30) {
        console.error('max recursion depth exceeded, abandoning object copy');
        return input;
    }

    let copy: Record<any, any>;

    switch (true) {
        case isObject(input):
            copy = {};
            for (const field in input) {
                copy[field] = objectCopy(input[field], recursionDepth);
            }
            break;

        case isArray(input):
            copy = [];
            input.forEach((item: any) => {
                copy.push(objectCopy(item, recursionDepth));
            });
            break;

        // -bool, null, undefined, string and number are all passed by value
        //  through a = b
        // functions will be passed by reference
        default:
            copy = input;
    }
    return copy;
}

export function processUnixDateForViewing(dateUnix: any, format = SystemDateFormat) {
    if (!dateUnix) {
        return '-';
    }
    if (isString(dateUnix)) {
        return '-';
    }
    if (dateUnix === 0) {
        return '-';
    }
    try {
        return moment.unix(dateUnix).format(format);
    } catch (e) {
        return '-';
    }
}

export function processUnixDateTimeForViewing(dateUnix: any, format = SystemDateTimeFormat) {
    if (!dateUnix) {
        return '-';
    }
    if (isString(dateUnix)) {
        return '-';
    }
    if (dateUnix === 0) {
        return '-';
    }
    try {
        return moment.unix(dateUnix).format(format);
    } catch (e) {
        return '-';
    }
}

/**
 * @return {string}
 */
export function HexToRGBA(hex: any, opacity: any) {
    let val = hex;
    if (val.startsWith('#')) {
        val = val.substring(1);
    }
    if (val.length > 6) {
        console.error('The hex value is invalid:', hex);
        return 'rgba(255, 255, 255, 1)';
    } else if (val.length < 6) {
        const zero = [];
        for (let i = 0; i < 6 - val.length; i++) {
            zero.push('0');
        }
        val = val.concat(val, zero);
    }

    const red = parseInt(val.substr(0, 2), 16);
    const green = parseInt(val.substr(2, 2), 16);
    const blue = parseInt(val.substr(4, 2), 16);
    return 'rgba(' + red + ',' + green + ',' + blue + ',' + opacity + ')';
}

/**
 * SortObject is used to sort objects by a list of sort parameters eg. ["name","-number"]
 * @return {number}
 */
export function SortObjects(item1: any, item2: any, sortBy: any) {
    if (!(isObject(item1) && isObject(item2))) {
        console.error('Invalid items passed to SortObjects.' + item1 + '.' + item2);
        return 0;
    }

    if (!isArray(sortBy)) {
        console.error('Invalid sort by array passed to SortObjects.');
        return 0;
    }

    if (sortBy.length < 1) {
        console.error('Nothing specified in sortBy');
        return 0;
    }

    for (const i in sortBy) {
        let reverse = 1;
        if (sortBy.hasOwnProperty(i)) {
            let field = sortBy[i];
            const s = field.charAt(0);
            if (s === '-') {
                reverse = -1;
                field = field.substr(1);
            }

            if (!(isNumber(item1[field]) || isString(item1[field]))) {
                console.error('can only sort on string and number values');
                return 0;
            }

            if (isNumber(item1[field])) {
                return reverse * SortNumber(item1[field], item2[field]);
            }

            if (isString(item1[field])) {
                const result = SortString(item1[field], item2[field]);
                if (result !== 0) {
                    return result * reverse;
                }
            }
        }
    }

    return 0;
}

/**
 * @return {number}
 */
export function SortString(item1: any, item2: any) {
    if (item1) {
        return item1.localeCompare(item2, 'en', { sensitivity: 'base' });
    }
    return 0;
}

/**
 * @return {number}
 */
export function SortNumber(item1: any, item2: any) {
    if (item1 > item2) {
        return -1;
    }
    if (item1 < item2) {
        return 1;
    }
    return 0;
}

function roundMillions(value: number) {
    if (value < 10 && value > 0) {
        return value.toFixed(2).toString();
    } else if (value === 0) {
        return '00.0';
    } else {
        return value.toFixed(1);
    }
}

export function roundScaleAndFormatNumber(
    amount: number,
    currencySymbol: string,
    showInMillions = false,
    decimals = 0,
    showSuffix = true,
) {
    try {
        const sign = amount < 0 ? '-' : '';
        const symbol = currencySymbol ? currencySymbol : '';
        const absAmount = Math.abs(amount);
        if (showInMillions) {
            const millions = absAmount / 1000000;
            return sign + symbol + `${roundMillions(millions)}${showSuffix ? 'm' : ''}`;
        }
        return sign + symbol + numberWithCommas(round(absAmount, decimals));
    } catch (e) {
        console.error('error formatting and rounding number', e);
        return 0;
    }
}

function numberWithCommas(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export const displayAmount = (amount: number | undefined | Big): string =>
    amount
        ? new Intl.NumberFormat('en-UK', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
          }).format(Number(amount))
        : '0.00';

export const displayRate = (amount: number | undefined | Big): string =>
    amount
        ? new Intl.NumberFormat('en-UK', { maximumFractionDigits: 4, minimumFractionDigits: 4 }).format(Number(amount))
        : '0.00';

export const displayRateSixDecimalPlaces = (amount: number | undefined | Big): string =>
    amount
        ? new Intl.NumberFormat('en-UK', { maximumFractionDigits: 6, minimumFractionDigits: 6 }).format(Number(amount))
        : '0.00';

export const FormatNumber = (
    amount: string | number | Big = '0',
    outputAsString = false,
    thousandIndicator = false,
    decimalPlaces = 4,
    fixed = false,
) => {
    // Determine if value is negative
    const negative = (() => {
        // If amount is a string
        if (typeof amount === 'string') {
            if (amount.length > 1) {
                if (amount[0] === '-') {
                    return true;
                }
            }
        }
        // The decimal places can be specified as the last parameter.
        // If amount is a number
        if (typeof amount === 'number' && isFinite(amount)) {
            const factor = Math.pow(10, decimalPlaces);
            amount = Math.round(amount * factor) / factor;
            if (amount * -1 > 0) {
                return true;
            }
        }
    })();
    amount = amount == null ? '' : amount;
    let stringAmount = '';
    let decimalString = '';
    const integerPart = amount.toString().split('.')[0];
    let decimalPoint = amount.toString().indexOf('.') === -1 ? '' : '.';
    let decimalPart = amount.toString().split('.')[1];

    if (integerPart) {
        stringAmount = integerPart.replace(/[^0-9.]/g, '');
        stringAmount = stringAmount.replace(/\b0+\B/g, '');
    }
    if (thousandIndicator && outputAsString) {
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(stringAmount)) {
            stringAmount = stringAmount.replace(rgx, '$1,$2');
        }
    } else {
        if (thousandIndicator && !outputAsString) {
            console.warn("Format Number error: Can't add 'thousand indicator' when output format is number");
        }
    }

    if (decimalPart && decimalPlaces && decimalPlaces > 0) {
        decimalPart = decimalPart.replace(/[^0-9.]/g, '');
        decimalString = decimalPart.substr(0, decimalPlaces);
    }

    if (fixed && outputAsString) {
        decimalPoint = '.';
        while (decimalString.length < decimalPlaces) {
            decimalString = decimalString + '0';
        }
    }

    const output = stringAmount + decimalPoint + decimalString;
    const returnVal = outputAsString ? output : parseFloat(output);
    return negative ? `-${returnVal}` : returnVal;
};

/**
 * getMidDay sets the time to the middle of the day, defined as 11:59:59 GMT, for
 * the given date.
 * @param date
 */
export const getMidDay = (date: Date): Date => {
    date.setHours(11);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(0);
    return addMinutes(date, -date.getTimezoneOffset());
};

export const getMidDayISODateString = (date: Date): string => {
    return getMidDay(date).toString();
};

export const getNextTradeDate = (date: Date): Date => {
    const current = getMidDay(date);
    const next = addDays(current, 1);
    if (isWeekend(next)) {
        return getNextTradeDate(next);
    }
    return next;
};

export const getPreviousTradeDate = (date: Date): Date => {
    const current = getMidDay(date);
    const previous = addDays(current, -1);
    if (isWeekend(previous)) {
        return getNextTradeDate(previous);
    }
    return previous;
};

export const displayDate = (date: Date | string | null | undefined): string => {
    if (!date) {
        return '-';
    }
    if (typeof date === 'string') {
        const dateParts = date?.split('T');
        return dateParts?.length === 2 ? dateParts[0] : '';
    }
    if (date) {
        return formatISO(date, { representation: 'date' });
    }
    return date;
};
export const displayNumberDate = (date?: number | null) => {
    if (!date) {
        return '-';
    }
    return format(fromUnixTime(date || 0), 'yyyyMMdd');
};

export type Diff<T extends string | number | symbol, U extends string | number | symbol> = ({ [P in T]: P } &
    { [P in U]: never } & { [x: string]: never })[T];

export type Omit<T, K extends keyof T> = Pick<T, Diff<keyof T, K>>;

export function FormatBoolean(boolean: boolean): string {
    if (boolean) {
        return 'True';
    } else {
        return 'False';
    }
}

export const displayAmountWithSign = (invoice: Invoice, value: number | undefined) => {
    const sign = getSignBasedOnType(invoice);
    if (value) {
        return Intl.NumberFormat('en-UK', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }).format(Number(sign * value));
    } else {
        return '0.00';
    }
};
