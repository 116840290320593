import { Identifier, isIdentifier, marshalIdentifier } from 'popcorn-js/search/identifier';
import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { Order } from '.';

export type LinkToInvoiceRequest = {
    identifier?: Identifier | string;
    invoiceId?: Identifier | string;
};

export type LinkToInvoiceResponse = {
    order: Order;
};

export type UnlinkFromInvoiceRequest = {
    identifier?: Identifier | string;
    invoiceId?: Identifier | string;
};

export type UnlinkFromInvoiceResponse = {
    order: Order;
};

export const Linker = {
    ServiceProviderName: 'Order-Linker',
    linkToInvoice(request: LinkToInvoiceRequest): Promise<LinkToInvoiceResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        let serializedInvoiceId = request.invoiceId;
        if (isIdentifier(request.invoiceId)) {
            serializedInvoiceId = request.invoiceId ? marshalIdentifier(request.invoiceId as Identifier) : undefined;
        }
        return jsonRPC<LinkToInvoiceRequest, LinkToInvoiceResponse>({
            url: config.get('apiURL'),
            method: `${Linker.ServiceProviderName}.LinkToInvoice`,
            request: {
                ...request,
                identifier: serializedIdentifier,
                invoiceId: serializedInvoiceId,
            },
        });
    },
    unlinkFromInvoice(request: UnlinkFromInvoiceRequest): Promise<UnlinkFromInvoiceResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        let serializedInvoiceId = request.invoiceId;
        if (isIdentifier(request.invoiceId)) {
            serializedInvoiceId = request.invoiceId ? marshalIdentifier(request.invoiceId as Identifier) : undefined;
        }
        return jsonRPC<UnlinkFromInvoiceRequest, UnlinkFromInvoiceResponse>({
            url: config.get('apiURL'),
            method: `${Linker.ServiceProviderName}.UnlinkFromInvoice`,
            request: {
                ...request,
                identifier: serializedIdentifier,
                invoiceId: serializedInvoiceId,
            },
        });
    },
};
