import React, { Fragment, ReactElement, useContext, useState } from 'react';
import { Backdrop, CircularProgress, Dialog, makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from './styledComponents';
import { useServiceSync } from 'hooks/useService';
import { Handler } from 'popcorn-js/options/handler';
import {
    calculateCallAmount,
    calculatePutAmount,
    getCallCurrency,
    getPutCurrency,
    mapOption,
    OptionValues,
} from './index';
import { CustomTheme } from 'theme/custom';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { formatISO } from 'date-fns';
import { FormatNumber, HexToRGBA } from 'utils';
import { AppContext, AppContextT } from 'context';
import Big from 'big.js';
import { OptionDirection, OptionType } from 'popcorn-js/options';

export const ConfirmOptionDetails = (props: {
    open: boolean;
    onClose: () => void;
    onFinished: () => void;
    options: OptionValues[];
    capturedSpotRate?: Big;
    traderOrganisation?: string;
}): ReactElement => {
    const { open, onClose, onFinished, capturedSpotRate, traderOrganisation, options } = props;

    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [error, setError] = useState<string | undefined>();
    const [success, setSuccess] = useState<boolean>(false);
    const [recording, setRecording] = useState<boolean>(false);
    const [record] = useServiceSync(Handler.Record);

    const recordOption = async () => {
        try {
            setRecording(true);
            await record({
                options: options.map((t: OptionValues) =>
                    mapOption({
                        optionValues: t,
                        parentPartyCode: appContext.parentPartyCode,
                        traderOrganisation,
                        capturedSpotRate,
                    }),
                ),
            });
            setSuccess(true);
        } catch (e) {
            setError(e);
        }
        setRecording(false);
    };

    return (
        <Dialog
            id={'ConfirmTradeDetailsDialog'}
            onClose={onClose}
            open={open}
            maxWidth={'xl'}
            classes={{
                root: classes.dialog,
            }}
        >
            <StandardCard
                cardHeaderProps={{
                    fatter: true,
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TITLE,
                            text: `Confirm Option Details`,
                            id: 'title',
                            variant: 'title2',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            onClick: onClose,
                            id: 'close',
                        },
                    ],
                }}
            >
                <div className={classes.parentTradesHeading}>
                    <div className={classes.headingText}>Option</div>
                    <div className={classes.headingText}>External Ref.</div>
                    <div className={classes.headingText}>Call Amount</div>
                    <div className={classes.headingText}>Trade Date</div>
                    <div className={classes.headingText}>Expiry Date</div>
                    <div className={classes.headingText}>Strike Price</div>
                    <div className={classes.headingText}>Put Amount</div>
                    <div className={classes.headingText}>Billing Type</div>
                    <div className={classes.headingText}>Intermediary Margin</div>
                </div>
                <>
                    {options.map((t: OptionValues, k: number) => {
                        const callAmount = calculateCallAmount(t.strikePrice, t.notionalAmount, t.type);
                        const putAmount = calculatePutAmount(t.strikePrice, t.notionalAmount, t.type);
                        const formatPutAmount = FormatNumber(putAmount, true, true, 2);
                        const formatCallAmount = FormatNumber(callAmount, true, true, 2);

                        const putCurrency = getPutCurrency(t.type, t.currencyPair, appContext.currencies);
                        const callCurrency = getCallCurrency(t.type, t.currencyPair, appContext.currencies);
                        return (
                            <div key={k}>
                                <div className={classes.parentTradesRow}>
                                    <div className={classes.valueText}>{`Option ${k + 1}`}</div>
                                    <div className={classes.valueText}>{t.externalReference}</div>
                                    <div
                                        className={
                                            t.direction === OptionDirection.BUY && t.type === OptionType.CALL
                                                ? classes.importCurrencyValueText
                                                : t.direction === OptionDirection.SELL && t.type === OptionType.CALL
                                                ? classes.exportCurrencyValueText
                                                : classes.currencyValueText
                                        }
                                    >{`${callCurrency?.isoCode} ${formatCallAmount}`}</div>
                                    <div className={classes.valueText}>
                                        {t.tradeDate && formatISO(t.tradeDate, { representation: 'date' })}
                                    </div>
                                    <div className={classes.valueText}>
                                        {t.expiryDate && formatISO(t.expiryDate, { representation: 'date' })}
                                    </div>
                                    <div className={classes.valueText}>{t.strikePrice?.toFixed(6)}</div>
                                    <div
                                        className={
                                            t.direction === OptionDirection.BUY && t.type === OptionType.PUT
                                                ? classes.exportCurrencyValueText
                                                : t.direction === OptionDirection.SELL && t.type === OptionType.PUT
                                                ? classes.importCurrencyValueText
                                                : classes.currencyValueText
                                        }
                                    >{`${putCurrency?.isoCode} ${formatPutAmount}`}</div>
                                    <div className={classes.valueText}>{t.billingType && t.billingType.toString()}</div>
                                    <div className={classes.valueText}>{t.intermediaryMargin?.toFixed(4)}</div>
                                </div>
                            </div>
                        );
                    })}
                </>
                <div className={classes.buttonRow}>
                    <BaseButton
                        text={'Confirm Information'}
                        id={'confirm'}
                        variant={VARIANT.CONTAINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        onClick={recordOption}
                        disableUppercase
                    />
                    <BaseButton
                        text={'Revise Information'}
                        id={'confirm'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        onClick={onClose}
                        disableUppercase
                    />
                </div>
            </StandardCard>
            <Backdrop open={recording} style={{ zIndex: 10 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                autoHideDuration={3000}
                onClose={() => (success ? onFinished() : onClose())}
                open={success || !!error}
            >
                <Fragment>
                    {success && (
                        <Alert onClose={() => onFinished()} severity="success">
                            Successfully recorded option!
                        </Alert>
                    )}
                    {!!error && (
                        <Alert onClose={() => onClose()} severity="error">
                            Failed to record option: {error}
                        </Alert>
                    )}
                </Fragment>
            </Snackbar>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    parentTradesHeading: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        height: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.light,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    parentTradesRow: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        height: theme.spacing(5),
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    buttonRow: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    headingText: {
        width: '160px',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        fontSize: '14px',
    },
    valueText: {
        width: '160px',
        color: theme.palette.text.secondary,
        fontSize: '14px',
    },
    importCurrencyValueText: {
        width: '160px',
        color: theme.palette.custom.import.main,
        fontWeight: 'bold',
        fontSize: '14px',
    },
    exportCurrencyValueText: {
        width: '160px',
        color: theme.palette.custom.export.main,
        fontWeight: 'bold',
        fontSize: '14px',
    },
    currencyValueText: {
        width: '160px',
        color: theme.palette.custom.paperExtended.paper4,
        fontWeight: 'bold',
        fontSize: '14px',
    },
    dialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: HexToRGBA(theme.palette.background.default, 0.8),
        },
    },
}));
