import { v4 } from 'uuid';
import { useServiceSync } from 'hooks/useService';
import { DateKeeper, GetSpotDateForRequest, GetSpotDateForResponse } from 'popcorn-js/fxCalendar/dateKeeper';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { BillingType, ImportExport, invertDirection, TradeDirection, TradeType } from 'popcorn-js/tradeV2';
import { fromUnixTime, getUnixTime, isAfter } from 'date-fns';
import { debounce } from '@material-ui/core';
import _ from 'lodash';
import Big from 'big.js';
import {
    ACMParentAllocation,
    Actions,
    FieldErrors,
    fieldErrorsValid,
    getInitialDate,
    InitProps,
    ParentAllocation,
    TradeValues,
    Transaction,
    TransactionState,
} from './index';
import { getMidDay, getNextTradeDate, getPreviousTradeDate } from 'utils';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { currencies, Currency } from 'popcorn-js/currency';

export const useTransaction = (
    initProps: InitProps,
): [transactionState: Transaction, actions: Actions, loading: boolean, error: string | undefined] => {
    const tradeUuid = initProps.optionTradeValues ? initProps.optionTradeValues.uuid : v4();
    const cancellationTradeUuid = v4();

    // service providers
    const [getSpotDateFor] = useServiceSync<GetSpotDateForRequest, GetSpotDateForResponse>(DateKeeper.GetSpotDateFor);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    // get spot dates
    const setSpotDate = useCallback(async (_trade: TradeValues, newTradeDate: Date | null) => {
        if (_trade.tradeType !== TradeType.SPOT) {
            return;
        }
        setLoading(true);
        try {
            const sd = await getSpotDateFor({
                currencyPair: initProps.currencyPair.name,
                date: getUnixTime(newTradeDate || 0),
            });
            actions.setMaturityDate(_trade.uuid, fromUnixTime(sd.spotDate));
        } catch (e) {
            setError(e);
        }
        setLoading(false);
    }, []);

    // for autofill
    const setTradeType = useCallback(async (_trade: TradeValues, newTradeDate: Date, newMaturityDate: Date) => {
        setLoading(true);
        if (_trade.tradeType === TradeType.FORWARD || _trade.tradeType === TradeType.SPOT) {
            try {
                const sd = await getSpotDateFor({
                    currencyPair: initProps.currencyPair.name,
                    date: getUnixTime(newTradeDate || 0),
                });
                isAfter(newMaturityDate, fromUnixTime(sd.spotDate))
                    ? actions.changeTradeType(_trade.uuid, TradeType.FORWARD)
                    : actions.changeTradeType(_trade.uuid, TradeType.SPOT);
            } catch (e) {
                setError(e);
            }
        }
        setLoading(false);
    }, []);

    const initTrades = useCallback(async () => {
        setLoading(true);

        const trd = await initTradeValues(initProps, setError, false, tradeUuid, true, getSpotDateFor);

        // Creates cancellation legs for trades with different maturity dates
        const newTrades = [];
        if (initProps.distinctDates) {
            for (let i = 0; i < initProps.distinctDates.length; i++) {
                const date = initProps.distinctDates[i];
                const currentDate = new Date(date);
                const parentGroupAllocation = initProps.parentAllocation.filter(
                    (parent) => parent.parentMaturityDate.toDateString() == date,
                );
                const cancTrade = await initTradeValues(
                    {
                        ...initProps,
                        currentDate: currentDate,
                        parentAllocation: parentGroupAllocation,
                    },
                    setError,
                    true,
                    v4(),
                    true,
                    getSpotDateFor,
                );
                newTrades.push(cancTrade);
            }
            newTrades.push(trd);
        }

        const cancTrade = await initTradeValues(initProps, setError, true, cancellationTradeUuid, true, getSpotDateFor);

        let _trades = [];
        switch (initProps.tradeType) {
            case TradeType.DRAWDOWN:
            case TradeType.EXTENSION:
                _trades = initProps.distinctDates ? newTrades : [cancTrade, trd];
                break;
            case TradeType.CANCELLATION:
                _trades = [cancTrade];
                break;
            case TradeType.FORWARD:
                _trades = [trd];
                break;
            case TradeType.SPOT:
                _trades = [trd];
                break;
            case TradeType.SWAP:
                const sellTrd = await initTradeValues(
                    initProps,
                    setError,
                    false,
                    tradeUuid,
                    true,
                    getSpotDateFor,
                    TradeDirection.SELL,
                );
                const buyTrd = await initTradeValues(
                    initProps,
                    setError,
                    false,
                    cancellationTradeUuid,
                    true,
                    getSpotDateFor,
                    TradeDirection.BUY,
                );
                _trades = [sellTrd, buyTrd];
                break;
            default:
                return [];
        }
        setTrades(_trades);
        setLoading(false);
    }, []);

    const [trades, setTrades] = useState<TradeValues[]>([]);
    const [state, setState] = useState(initTransactionFromProps(initProps, tradeUuid, cancellationTradeUuid));

    useEffect(() => {
        initTrades().finally();
    }, []);

    const debounced = useCallback(
        debounce(() => {
            const transactionValid = transaction.fieldErrors.marginNotes == undefined;
            const tradevaluesValid = transaction.trades.reduce(
                (total: boolean, current: TradeValues) => total && current.valid,
                true,
            );
            const valid = transactionValid && tradevaluesValid;
            if (valid != state.valid) {
                setState((prevState) => ({ ...prevState, valid: valid }));
            }
        }, 1000),
        [state.valid, state.fieldErrors.marginNotes, trades],
    );

    useEffect(() => {
        debounced();
    });

    const handleAddTrade = async (prevState: TransactionState, prevTrades: TradeValues[], cancellation: boolean) => {
        setLoading(true);
        const newTradeUUid = v4();
        const newTrade = await initTradeValues(
            initProps,
            setError,
            cancellation,
            newTradeUUid,
            false,
            getSpotDateFor,
            undefined,
            prevTrades,
            prevState.transactionParents,
        );
        setTrades([...prevTrades, newTrade]);
        if (cancellation) {
            setState((prevState) => {
                return { ...prevState, expandedCancellationTrade: newTradeUUid };
            });
        } else {
            setState((prevState) => {
                return { ...prevState, expandedTrade: newTradeUUid };
            });
        }
        setLoading(false);
    };

    const actions: Actions = {
        setAcmParents: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.acmAllocations = _value as ACMParentAllocation[];
                        return _trade;
                    },
                });
            });
        }, []),
        setACM: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.acm = _value as boolean;
                        return _trade;
                    },
                });
            });
        }, []),
        changeTradeType: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.tradeType = _value as TradeType;
                        return _trade;
                    },
                });
            });
            setState((prevState) => {
                if (prevState.type == TradeType.SPOT || prevState.type == TradeType.FORWARD) {
                    return { ...prevState, type: value as TradeType };
                }
                return { ...prevState };
            });
        }, []),
        changeTransactionParentAllocation: useCallback((parentId, value) => {
            setState((prevState) => {
                //
                const newTransactionParents = _.cloneDeep(prevState.transactionParents);
                const i = newTransactionParents.findIndex((_t: ParentAllocation) => _t.id === parentId);
                if (i == -1) {
                    return prevState;
                }

                const parent = newTransactionParents[i];
                let valueBig = new Big(value || 0);

                if (valueBig.gt(parent.parentAvailableBalance || 0)) {
                    valueBig = Big(parent.parentAvailableBalance || 0);
                }

                newTransactionParents[i] = {
                    ...newTransactionParents[i],
                    amount: valueBig.eq(0) ? '' : valueBig.toFixed(2),
                };

                const totalParentAllocation = newTransactionParents.reduce(
                    (total, current) => total.add(current.amount || 0),
                    Big(0),
                );
                return { ...prevState, totalParentAllocation, transactionParents: newTransactionParents };
            });
        }, []),
        changeTradeParentAllocation: useCallback((uuid, parentId, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        const newParents = _.cloneDeep(_trade.allocations);
                        const i = newParents.findIndex((_t: ParentAllocation) => _t.id === parentId);
                        if (i == -1) {
                            return _trade;
                        }
                        newParents[i] = {
                            ...newParents[i],
                            amount: _value as string,
                        };
                        const totalParentAllocation: Big = newParents.reduce(
                            (total, current) => total.add(current.amount || 0),
                            Big(0),
                        );
                        const { buy, sell } = calcBuyAndSellAmount(
                            _trade.direction,
                            Big(totalParentAllocation || 0),
                            Big(_trade.dealRate || 0),
                        );

                        return {
                            ..._trade,
                            allocations: newParents,
                            totalParentAllocation: totalParentAllocation,
                            notionalAmount: totalParentAllocation.toFixed(2),
                            buyAmount: buy.toFixed(2),
                            sellAmount: sell.toFixed(2),
                        };
                    },
                });
            });
        }, []),
        setDealRate: useCallback(
            (uuid, value) => {
                setTrades((prevTrades: TradeValues[]) => {
                    return setTradeValue({
                        prevTrades,
                        uuid,
                        value,
                        setterFunc: (_trade, _value) => {
                            _trade.dealRate = _value as string;
                            const { buy, sell } = calcBuyAndSellAmount(
                                _trade.direction,
                                Big(_trade.notionalAmount || 0),
                                Big((_value as string) || 0),
                            );
                            _trade.buyAmount = buy.toFixed(2);
                            _trade.sellAmount = sell.toFixed(2);
                            _trade.forwardPoints = calcForwardPoints(
                                Big(state.spotPrice || 0),
                                Big(_trade.dealRate || 0),
                            ).toFixed(6);
                            return _trade;
                        },
                        validateFunc: (prevFieldErrors, _value) => {
                            return {
                                ...prevFieldErrors,
                                dealRate: Big((_value as string) || 0).eq(Big(0)) ? 'required' : undefined,
                            };
                        },
                    });
                });
            },
            [state.spotPrice],
        ),
        setInterBankRate: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.interbankRate = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setSpotPrice: useCallback(
            debounce((uuid: string, value: string) => {
                setState((prevState: TransactionState) => {
                    return {
                        ...prevState,
                        spotPrice: value,
                    };
                });
                setTrades((prevTrades: TradeValues[]) => {
                    return setTradeValue({
                        prevTrades,
                        uuid,
                        value,
                        setterFunc: (_trade, _value) => {
                            _trade.spotPrice = _value as string;
                            _trade.forwardPoints = calcForwardPoints(
                                Big(_trade.spotPrice || 0),
                                Big(_trade.dealRate || 0),
                            ).toFixed(6);
                            return _trade;
                        },
                    });
                });
            }, 50),
            [trades],
        ),
        setBankRate: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.bankRate = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setDirection: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.direction = _value as TradeDirection;
                        if (_trade.allocations.length === 0) {
                            _trade.importExport =
                                _trade.direction === TradeDirection.BUY ? ImportExport.IMPORT : ImportExport.EXPORT;
                        }
                        const tempCurrency = _trade.sellCurrency;
                        _trade.sellCurrency = _trade.buyCurrency;
                        _trade.buyCurrency = tempCurrency;

                        _trade.buyAmount = '';
                        _trade.sellAmount = '';
                        return _trade;
                    },
                });
            });
        }, []),
        setNotionalAmount: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.notionalAmount = _value as string;
                        const { dealRate, buy, sell } = calcRateAndAmounts(
                            _trade.direction,
                            Big(_trade.notionalAmount || 0),
                            Big(_trade.quoteAmount || 0),
                        );
                        _trade.dealRate = dealRate.toFixed(6) || '';
                        _trade.buyAmount = buy.toFixed(2) || '';
                        _trade.sellAmount = sell.toFixed(2) || '';
                        _trade.forwardPoints = calcForwardPoints(
                            Big(_trade.spotPrice || 0),
                            Big(_trade.dealRate || 0),
                        ).toFixed(6);
                        return _trade;
                    },
                    validateFunc: (prevFieldErrors) => {
                        return {
                            ...prevFieldErrors,
                            dealRate: undefined,
                        };
                    },
                });
            });
        }, []),
        setQuoteAmount: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.quoteAmount = _value as string;
                        const { dealRate, buy, sell } = calcRateAndAmounts(
                            _trade.direction,
                            Big(_trade.notionalAmount || 0),
                            Big(_trade.quoteAmount || 0),
                        );
                        _trade.dealRate = dealRate.toFixed(6) || '';
                        _trade.buyAmount = buy.toFixed(2) || '';
                        _trade.sellAmount = sell.toFixed(2) || '';
                        _trade.forwardPoints = calcForwardPoints(
                            Big(_trade.spotPrice || 0),
                            Big(_trade.dealRate || 0),
                        ).toFixed(6);
                        return _trade;
                    },
                    validateFunc: (prevFieldErrors) => {
                        return {
                            ...prevFieldErrors,
                            dealRate: undefined,
                        };
                    },
                });
            });
        }, []),
        setCurrencyPair: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.currencyPair = _value as CurrencyPair;
                        return _trade;
                    },
                });
            });
        }, []),
        setBuyCurrency: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.buyCurrency = _value as Currency;
                        return _trade;
                    },
                });
            });
        }, []),
        setSellCurrency: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.sellCurrency = _value as Currency;
                        return _trade;
                    },
                });
            });
        }, []),
        setIntermediaryMargin: useCallback((uuid, value, required) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.intermediaryMargin = _value as string;
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _trade.billingType as BillingType,
                            _trade.direction,
                            Big(_trade.notionalAmount || 0),
                            Big(_trade.dealRate || 0),
                            Big(_trade.intermediaryMargin || 0),
                        );
                        _trade.bankRate = bankRate.toFixed(6);
                        _trade.clientFee = clientFee.toFixed(2);
                        _trade.billedToBank = billedToBank.toFixed(2);
                        return _trade;
                    },
                    validateFunc: (prevFieldErrors, _value) => {
                        return {
                            ...prevFieldErrors,
                            intermediaryMargin: required && (_value as string) == '' ? 'required' : undefined,
                        };
                    },
                });
            });
        }, []),
        setClientFee: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.clientFee = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setAdminFee: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.adminFee = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setBilledToBank: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.billedToBank = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setExpandedTrade: useCallback((uuid) => {
            setState((prevState) => {
                if (prevState.expandedTrade === uuid) {
                    return { ...prevState, expandedTrade: '' };
                }
                return { ...prevState, expandedTrade: uuid };
            });
        }, []),
        setExpandedCancellationTrade: useCallback((uuid) => {
            setState((prevState) => {
                if (prevState.expandedCancellationTrade === uuid) {
                    return { ...prevState, expandedCancellationTrade: '' };
                }
                return { ...prevState, expandedCancellationTrade: uuid };
            });
        }, []),
        addCancellationTrade: useCallback(async () => {
            setState((prevState) => {
                setTrades((prevTrades) => {
                    handleAddTrade(prevState, prevTrades, true);
                    return prevTrades;
                });
                return prevState;
            });
        }, []),
        addTrade: useCallback(async () => {
            setState((prevState) => {
                setTrades((prevTrades) => {
                    handleAddTrade(prevState, prevTrades, false);
                    return prevTrades;
                });
                return prevState;
            });
        }, []),
        removeTrade: useCallback((uuid) => {
            setTrades((prevTrades) => [...prevTrades.filter((trd) => trd.uuid != uuid)]);
        }, []),
        updateTradeExtRef: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.externalReference = _value as string;
                        return _trade;
                    },
                    validateFunc: (prevFieldErrors, _value) => {
                        return {
                            ...prevFieldErrors,
                            externalReference: _value == '' ? 'required' : undefined,
                        };
                    },
                });
            });
        }, []),
        setMaturityDate: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.maturityDate = _value as Date;
                        return _trade;
                    },
                });
            });
        }, []),
        setBank: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.bank = _value as string;
                        return _trade;
                    },
                    validateFunc: (prevFieldErrors, _value) => {
                        return {
                            ...prevFieldErrors,
                            bank: _value == '' ? 'required' : undefined,
                        };
                    },
                });
            });
        }, []),
        setTradeDate: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.tradeDate = _value as Date;
                        setSpotDate(_trade, value).finally();
                        return _trade;
                    },
                });
            });
        }, []),
        updateTrader: useCallback((value) => {
            setState((prevState) => {
                return { ...prevState, trader: value };
            });
        }, []),
        setCapturedSpotRate: useCallback((value) => {
            setState((prevState) => {
                return { ...prevState, capturedSpotRate: value };
            });
        }, []),
        setNotes: useCallback((value) => {
            setState((prevState) => {
                return { ...prevState, notes: value };
            });
        }, []),
        setSeason: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.season = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setProduct: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.product = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setClientReference: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.clientReference = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setClientNotes: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.clientNotes = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setMarginNotes: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.marginNotes = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setTradeNotes: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.notes = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setOptionReference: useCallback((value) => {
            setState((prevState) => {
                return { ...prevState, optionReference: value };
            });
        }, []),
        changeOptionReference: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.optionReference = _value as string;
                        return _trade;
                    },
                });
            });
        }, []),
        setOptionNumber: useCallback((value) => {
            setState((prevState) => {
                return { ...prevState, optionNumber: value };
            });
        }, []),
        setTraderOrganisation: useCallback((value) => {
            setState((prevState) => {
                return { ...prevState, traderOrganisation: value };
            });
        }, []),
        setBillingType: useCallback((uuid, value, required) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.billingType = _value as BillingType;
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _trade.billingType,
                            _trade.direction,
                            Big(_trade.notionalAmount || 0),
                            Big(_trade.dealRate || 0),
                            Big(_trade.intermediaryMargin || 0),
                        );
                        _trade.bankRate = bankRate.toFixed(6);
                        _trade.clientFee = clientFee.toFixed(2);
                        _trade.billedToBank = billedToBank.toFixed(2);
                        return _trade;
                    },
                    validateFunc: (prevFieldErrors, _value) => {
                        return {
                            ...prevFieldErrors,
                            billingType: required && _value == '' ? 'required' : undefined,
                        };
                    },
                });
            });
        }, []),
        updateMarginNotesRequirement: useCallback(
            debounce((needed) => {
                setState((prevState: TransactionState) => {
                    return {
                        ...prevState,
                        fieldErrors: {
                            ...prevState.fieldErrors,
                            marginNotes: needed ? 'required' : undefined,
                        },
                    };
                });
            }, 50),
            [],
        ),
        autoFillDates: useCallback((uuid, value) => {
            setTrades((prevTrades: TradeValues[]) => {
                return setTradeValue({
                    prevTrades,
                    uuid,
                    value,
                    setterFunc: (_trade, _value) => {
                        _trade.tradeDate = (_value as Date[])[0] as Date;
                        _trade.maturityDate = (_value as Date[])[1] as Date;
                        setTradeType(_trade, _trade.tradeDate, _trade.maturityDate).finally();
                        return _trade;
                    },
                });
            });
        }, []),
    };

    const transaction = useMemo(() => {
        return { ...state, trades };
    }, [state, trades]);

    return [transaction, actions, loading, error];
};

export const initTransactionFromProps = (
    initProps: InitProps,
    tradeUuid: string,
    cancellationTradeUuid: string,
): TransactionState => {
    let fieldErrors = {};
    if (!initProps.optionTradeValues?.dealRate) {
        fieldErrors = {
            spotPrice: 'required',
        };
    }

    return {
        fieldErrors: fieldErrors,
        trader: initProps.initTrader,
        traderOrganisation: initProps.optionTradeValues?.traderOrganisation || initProps.initTraderOrganisation,
        notes: initProps.optionTradeValues?.notes,
        optionReference: initProps.optionTradeValues?.optionReference || '',
        optionNumber: initProps.optionTradeValues?.optionNumber || '',
        spotPrice: initProps.optionTradeValues?.dealRate || '',
        capturedSpotRate: Big(0),
        valid: false,
        expandedTrade: tradeUuid,
        expandedCancellationTrade: cancellationTradeUuid,
        error: '',
        capturedBy: initProps.initTrader,
        captureDate: new Date(),
        type: initProps.tradeType,
        transactionParents: initProps.parentAllocation,
        totalParentAllocation: initProps.totalParentAllocation,
    };
};

const initTradeValues = async (
    initProps: InitProps,
    setError: Dispatch<SetStateAction<string | undefined>>,
    cancellation: boolean,
    uuid: string,
    initialTrade: boolean,
    getSpotDateFor: (request: GetSpotDateForRequest) => Promise<GetSpotDateForResponse>,
    customDirection?: TradeDirection,
    prevTrades?: TradeValues[],
    transactionParents?: ParentAllocation[],
): Promise<TradeValues> => {
    if (initProps.optionTradeValues) {
        return initProps.optionTradeValues;
    }

    const firstParentMaturityDate = (() => {
        if (initProps.currentDate) {
            return initProps.currentDate;
        }
        if (initProps.selectedParents.length > 0) {
            return initProps.selectedParents[0].maturityDate || new Date();
        }
        return new Date();
    })();
    const initialDate: Date = getInitialDate();
    const firstParentMaturityDateMid = getMidDay(firstParentMaturityDate);
    const previousTradingDate = getMidDay(getPreviousTradeDate(firstParentMaturityDate));

    let direction = customDirection || initProps.firstParentDirection;
    if (cancellation) {
        direction = invertDirection(direction);
    }
    const { buyCurrency, sellCurrency } = splitBuySellCurrency(initProps.currencyPair, direction);

    let spotDate = getUnixTime(initialDate);
    try {
        const sd = await getSpotDateFor({
            currencyPair: initProps.currencyPair.name,
            date: getUnixTime(initialDate),
        });
        spotDate = sd.spotDate;
    } catch (e) {
        setError(e.message || e);
    }

    let totalParentAllocation = new Big(0);
    let allocations: ParentAllocation[];

    if (initialTrade) {
        allocations = initProps.parentAllocation;
    } else {
        const remainingParentAlloc = calcRemainingTradeBalances(
            prevTrades || [],
            transactionParents || [],
            cancellation,
        );
        allocations = remainingParentAlloc;
        remainingParentAlloc.forEach((p) => {
            totalParentAllocation = initialTrade ? totalParentAllocation.add(p.amount || 0) : new Big(0);
        });
    }

    const notionalAmount: Big = allocations.reduce(
        (total: Big, current: ParentAllocation) => total.add(Big(current.amount || 0)),
        Big(0),
    );

    const buySell = calcBuyAndSellAmount(direction, notionalAmount, Big(0));

    const tradeType = initProps.tradeType === TradeType.SWAP ? TradeType.SPOT : initProps.tradeType;
    const importExport = customDirection
        ? customDirection == TradeDirection.BUY
            ? ImportExport.IMPORT
            : ImportExport.EXPORT
        : initProps.importExport;

    const maturityDate = determineMaturityDate(
        initialDate,
        fromUnixTime(spotDate),
        tradeType,
        cancellation,
        firstParentMaturityDateMid,
    );

    return {
        uuid,
        spotDate: spotDate,
        importExport: importExport,
        externalReference: '',
        direction,
        currencyPair: initProps.currencyPair,
        interbankRate: '',
        bankRate: '',
        bank: '',
        maturityDate,
        tradeDate: initialDate,
        forwardPoints: Big(0).toFixed(6),
        dealRate: '',
        notionalAmount: notionalAmount.toFixed(2),
        sellCurrency,
        buyCurrency,
        cancellation,
        totalParentAllocation,
        allocations,
        acmAllocations: [],
        valid: false,
        acm: initProps.acm,
        maturityDateMin: initProps.tradeType === TradeType.EXTENSION ? firstParentMaturityDateMid : null,
        maturityDateMax: initProps.tradeType === TradeType.DRAWDOWN ? previousTradingDate : null,
        tradeType,
        buyAmount: !buySell.buy.eq(0) ? buySell.buy.toFixed(2) : '',
        sellAmount: !buySell.sell.eq(0) ? buySell.sell.toFixed(2) : '',
        intermediaryMargin: '',
        adminFee: '',
        clientFee: '',
        billedToBank: '',
        marginNotes: '',
        product: '',
        season: '',
        fieldErrors: {
            externalReference: 'required',
            tradeDate: undefined,
            maturityDate: undefined,
            bank: 'required',
            forwardPoints: undefined,
            dealRate: 'required',
            billingType: 'required',
            // trader: undefined,
            intermediaryMargin: undefined,
        },
    };
};

const determineMaturityDate = (
    tradeDate: Date,
    spotDate: Date,
    tradeType: TradeType,
    cancellation: boolean,
    firstParentMaturityDateMid?: Date,
): Date => {
    switch (true) {
        case tradeType == TradeType.CANCELLATION && cancellation:
            return firstParentMaturityDateMid || tradeDate;
        case tradeType == TradeType.DRAWDOWN && !cancellation:
            return spotDate;
        case tradeType == TradeType.DRAWDOWN && cancellation:
            return firstParentMaturityDateMid || tradeDate;
        case tradeType == TradeType.EXTENSION && !cancellation:
            return spotDate;
        case tradeType == TradeType.EXTENSION && cancellation:
            return firstParentMaturityDateMid || tradeDate;
        case tradeType == TradeType.FORWARD && !cancellation:
            return getMidDay(getNextTradeDate(spotDate));
        case tradeType == TradeType.SPOT && !cancellation:
            return spotDate;
        case tradeType == TradeType.SWAP && !cancellation:
            return spotDate;
        default:
            return tradeDate;
    }
};

type setTradeValueProps = {
    prevTrades: TradeValues[];
    value: unknown;
    uuid: string;
    setterFunc: (trade: TradeValues, value: unknown) => TradeValues;
    validateFunc?: (prevFieldErrors: FieldErrors, value: unknown) => FieldErrors;
};

const setTradeValue = ({ prevTrades, setterFunc, uuid, value, validateFunc }: setTradeValueProps) => {
    const newTrades = _.cloneDeep(prevTrades);
    const i = newTrades.findIndex((_t: TradeValues) => _t.uuid === uuid);
    if (i == -1) {
        return prevTrades;
    }

    const fieldErrors = validateFunc ? validateFunc(newTrades[i].fieldErrors, value) : { ...newTrades[i].fieldErrors };
    const valid = fieldErrorsValid(fieldErrors);
    newTrades[i] = {
        ...setterFunc(newTrades[i], value),
        fieldErrors,
        valid,
    };
    return newTrades;
};

const calcBuyAndSellAmount = (
    direction: TradeDirection,
    notionalAmount: Big,
    dealRate: Big,
): { buy: Big; sell: Big } => {
    const quoteAmount = notionalAmount.mul(dealRate);
    return direction === TradeDirection.BUY
        ? { buy: notionalAmount, sell: quoteAmount }
        : { buy: quoteAmount, sell: notionalAmount };
};

const calcRateAndAmounts = (
    direction: TradeDirection,
    notionalAmount: Big,
    quoteAmount: Big,
): { dealRate: Big; buy: Big; sell: Big } => {
    // Avoid division by zero
    if (notionalAmount.eq(Big(0))) {
        return direction === TradeDirection.BUY
            ? { dealRate: Big(0), buy: Big(0), sell: quoteAmount }
            : { dealRate: Big(0), buy: quoteAmount, sell: Big(0) };
    }
    const rate = quoteAmount.div(notionalAmount);
    return direction === TradeDirection.BUY
        ? { dealRate: rate, buy: notionalAmount, sell: quoteAmount }
        : { dealRate: rate, buy: quoteAmount, sell: notionalAmount };
};

const calcRevenue = (
    billingType: BillingType,
    direction: TradeDirection,
    notionalAmount: Big,
    dealRate: Big,
    intermediaryMargin: Big,
): { bankRate: Big; clientFee: Big; billedToBank: Big } => {
    let bankRate = Big(0);
    if (billingType === BillingType.ClientBilling) {
        bankRate = dealRate;
    } else if (billingType === BillingType.BankBilling) {
        bankRate =
            direction === TradeDirection.BUY ? dealRate.sub(intermediaryMargin) : dealRate.add(intermediaryMargin);
    }
    const clientFee = billingType == BillingType.BankBilling ? Big(0) : intermediaryMargin.mul(notionalAmount);
    const billedToBank = billingType === BillingType.BankBilling ? intermediaryMargin.mul(notionalAmount) : Big(0);
    return { bankRate: bankRate, clientFee: clientFee, billedToBank: billedToBank };
};

const calcForwardPoints = (spotPrice: Big, dealRate: Big): Big => {
    if (spotPrice.eq(0)) {
        return new Big(0);
    }
    return dealRate.sub(spotPrice);
};

const splitBuySellCurrency = (currencyPair: CurrencyPair, direction: TradeDirection) => {
    const baseCurrency = currencies[currencyPair.baseCurrency];
    const quoteCurrency = currencies[currencyPair.quoteCurrency];

    return direction === TradeDirection.BUY
        ? { buyCurrency: baseCurrency, sellCurrency: quoteCurrency }
        : { buyCurrency: quoteCurrency, sellCurrency: baseCurrency };
};

const calcRemainingTradeBalances = (
    currentTrades: TradeValues[],
    transactionParents: ParentAllocation[],
    cancellation: boolean,
): ParentAllocation[] => {
    const remainingParentAllocations: ParentAllocation[] = [];
    transactionParents.forEach((p) => {
        let remainingAllocationOfParent = Big(p.amount);
        currentTrades.forEach((t) => {
            t.allocations.forEach((a) => {
                if (a.id === p.id && cancellation === t.cancellation) {
                    remainingAllocationOfParent = remainingAllocationOfParent.sub(a.amount || 0);
                }
            });
        });

        if (remainingAllocationOfParent.lt(0)) {
            remainingAllocationOfParent = Big(0);
        }

        remainingParentAllocations.push({
            id: p.id,
            parentExternalReference: p.parentExternalReference,
            parentType: p.parentType,
            parentAvailableBalance: p.parentAvailableBalance,
            parentDirection: p.parentDirection,
            parentDealRate: p.parentDealRate,
            amount: remainingAllocationOfParent.toFixed(2),
            parentMaturityDate: p.parentMaturityDate,
            parentBank: p.parentBank,
        });
    });

    return remainingParentAllocations;
};
