// eslint-disable-next-line @typescript-eslint/no-explicit-any
import React, { ReactNode } from 'react';
import Paper from '@material-ui/core/Paper';
import { processUnixDateForViewing } from 'utils';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { Currency } from 'popcorn-js/currency';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            margin: '8px',
        },
        heading: {
            fontSize: '14px',
            fontWeight: 'bold',
            padding: 4,
            alignSelf: 'center',
            borderBottom: '2px solid #2F3160',
        },
        grandTotal: {
            display: 'grid',
            gridTemplateColumns: '120px auto',
            margin: '8px',
        },
        sectionRoot: {
            borderBottom: '2px solid #2F3160',
            alignContent: 'center',
            display: 'grid',
            gridTemplateRows: '30px auto',
        },
        currencyBreakDown: {
            display: 'grid',
            gridTemplateColumns: '120px auto',
        },
        sectionTotal: {
            display: 'grid',
            gridTemplateColumns: '120px auto',
        },
        sectionHeader: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#FFF',
            marginTop: '8px',
        },
    }),
);

interface CustomTooltipSectionLineProps {
    valueFormatter?: (n: number) => string;
    value?: number;
    heading: string;
    dataKey?: string;
    style?: React.CSSProperties;
}

export const CustomTooltipSectionLine: React.FC<CustomTooltipSectionLineProps> = (
    props: CustomTooltipSectionLineProps,
) => {
    const { value, heading, valueFormatter, style } = props;

    const classes = useStyles();
    if (!value) {
        return null;
    }
    return (
        <div className={classes.currencyBreakDown} style={{ ...style }}>
            <div>{heading}</div>
            <div>{valueFormatter ? valueFormatter(value || 0) : value} </div>
        </div>
    );
};

interface TooltipPayload {
    // eslint-disable-next-line
    /* eslint-disable @typescript-eslint/no-explicit-any */
    payload?: any;
}

interface TooltipSectionProps {
    valueFormatter?: (n: number) => string;
    data?: Record<string, number | undefined>;
    payload?: ReadonlyArray<TooltipPayload>;
    heading?: string;
    keys?: string[];
    onlyShowSectionTotals?: boolean;
    children?: React.ReactNode;
    lineWrapperStyle?: React.CSSProperties;
    hideIfZero?: boolean;
    style?: React.CSSProperties;
    showTotal?: boolean;
}

export const CustomTooltipSection: React.FC<TooltipSectionProps> = (props: TooltipSectionProps) => {
    const {
        data,
        keys,
        heading,
        onlyShowSectionTotals,
        children,
        lineWrapperStyle,
        valueFormatter,
        hideIfZero,
        style,
        showTotal,
    } = props;
    const classes = useStyles();

    if (keys && keys.length === 0) {
        return null;
    }

    let total = 0;

    const renderCustomLines = ((): ReactNode => {
        if (!children) {
            return null;
        }
        return React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                if ('props' in (child || {})) {
                    const key = child && child.props.dataKey;
                    const value = data && data[key];
                    total += value || 0;
                    return (
                        <div style={{ ...lineWrapperStyle }}>
                            {React.cloneElement(
                                child as React.ReactElement<{ value: number; valueFormatter: (n: number) => string }>,
                                {
                                    value,
                                    valueFormatter,
                                    // other props
                                    // use this props or child.props
                                },
                            )}
                        </div>
                    );
                }
            }
        });
    })();

    if (hideIfZero && total === 0) {
        return null;
    }

    return (
        <div className={classes.sectionRoot} style={{ ...style }}>
            {heading && <div className={classes.sectionHeader}>{heading}</div>}
            {!onlyShowSectionTotals && <div>{renderCustomLines} </div>}
            {showTotal && (
                <CustomTooltipSectionLine
                    heading={'Total: '}
                    style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                    value={total || 0}
                    valueFormatter={valueFormatter}
                />
            )}
            <div style={{ marginBottom: '15px' }}></div>
        </div>
    );
};

interface RechartsCustomTooltipProps {
    valueFormatter?: (n: number) => string;
    active?: boolean;
    currency?: Currency;
    onlyShowSectionTotals?: boolean;
    payload?: ReadonlyArray<TooltipPayload>;
    children?: React.ReactNode;
    sectionWrapperStyle?: React.CSSProperties;
    heading?: (data: Record<string, string | number>) => string | React.ReactElement;
}

export const CustomTooltip: React.FC<RechartsCustomTooltipProps> = (props: RechartsCustomTooltipProps) => {
    const classes = useStyles();
    const { active, payload, children, sectionWrapperStyle, valueFormatter, onlyShowSectionTotals, heading } = props;

    const data = payload ? payload[0]?.payload : undefined;

    const renderSections = (): React.ReactNode => {
        if (!children) {
            return null;
        }
        return React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                return (
                    <div style={{ ...sectionWrapperStyle }}>
                        {React.cloneElement(child as React.ReactElement<Record<string, unknown>>, {
                            data,
                            valueFormatter,
                            onlyShowSectionTotals,
                            // other props
                            // use this props or child.props
                        })}
                    </div>
                );
            }
        });
    };

    if (active && payload && payload.length > 0) {
        return (
            <Paper>
                <div className={classes.root}>
                    <div className={classes.heading}>
                        {heading ? heading(data) : processUnixDateForViewing(data.date, 'MMM YYYY')}
                    </div>
                    {renderSections()}
                </div>
            </Paper>
        );
    }
    return null;
};
