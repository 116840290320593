import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';

export default function SuccessIcon(): ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.icon}>
            <span className={classes.success_line_long} />
            <span className={classes.success_line_tip} />
            <div className={classes.success_ring} />
        </div>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        // --swal-red: #FFFFFF;
    },
    icon: {
        // animation: animateErrorIcon 0.5s;
        width: '80px',
        height: '80px',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderRadius: '50%',
        padding: 0,
        position: 'relative',
        boxSizing: 'content-box',
        margin: '20px auto',
        '&:first-child': {
            marginTop: '32px',
        },
    },
    success_line_long: {
        // border-color: var(--swal-green),
        height: '5px',
        // background-color: var(--swal-green),
        display: 'block',
        borderRadius: '2px',
        position: 'absolute',
        zIndex: 2,
        width: '47px',
        right: '8px',
        top: '38px',
        transform: 'rotate(-45deg)',
        // animation: animateSuccessLong 0.75s,
        backgroundColor: 'currentcolor',
    },
    success_line_tip: {
        height: '5px',
        width: '25px',
        left: '14px',
        top: '46px',
        transform: 'rotate(45deg)',
        // animation: animateSuccessTip 0.75s,
        // backgroundColor: var(--swal-green),
        backgroundColor: 'currentcolor',
        display: 'block',
        borderRadius: '2px',
        position: 'absolute',
        zIndex: 2,
        // border-color: var(--swal-green),
    },
    success_ring: {
        width: '80px',
        height: '80px',
        // border: 4px solid var(--swal-green-light),
        borderRadius: '50%',
        boxSizing: 'content-box',
        position: 'absolute',
        left: '-4px',
        top: '-4px',
        zIndex: 2,
        // border-color: var(--swal-green),
    },
}));
