import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';

export interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat<string> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: string | undefined;
}
export const NumberFormatCustom = (props: NumberFormatCustomProps): ReactElement => {
    const { inputRef, onChange, value, ...other } = props;

    return (
        <NumberFormat
            {...other}
            allowEmptyFormatting={false}
            allowLeadingZeros={false}
            defaultValue={''}
            getInputRef={inputRef}
            onValueChange={(values) => {
                if (isNaN(Number(values.value))) {
                    onChange({
                        target: {
                            name: props.name,
                            value: '',
                        },
                    });
                } else {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }
            }}
            thousandSeparator
            value={value}
        />
    );
};
