import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import FilteredMultiSelect from 'react-filtered-multiselect';
import { makeStyles, useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { isFunction } from 'utils/';
import { CustomTheme } from 'theme/custom';

export type CurrencyPairOptionT = {
    value: string;
    text: string;
};

export const CurrencyPairs = (props: {
    assignedCurrencyPairOptions: CurrencyPairOptionT[];
    availableCurrencyPairOptions: CurrencyPairOptionT[];
    disabled?: boolean;
    onAdd: (c: CurrencyPairOptionT[]) => void;
    onRemove: (c: CurrencyPairOptionT[]) => void;
}): ReactElement => {
    const {
        availableCurrencyPairOptions,
        assignedCurrencyPairOptions,
        onRemove,
        onAdd,
        disabled: disabledProp,
    } = props;

    const classes = useStyles();
    const theme = useTheme<CustomTheme>();

    const fmsClassNames = {
        filter: classes.filteredMultiSelectFilter,
        select: classes.filteredMultiSelectSelect,
        button: classes.filteredMultiSelectButton,
        buttonActive: classes.filteredMultiSelectButtonActive,
    };

    const disabled = disabledProp || !(isFunction(onAdd) && isFunction(onRemove));

    return (
        <div style={{ marginTop: '8px', width: '945px' }}>
            <Grid
                container
                direction={'row'}
                style={{
                    paddingLeft: '40px',
                    paddingTop: theme.spacing(),
                }}
            >
                <Grid item xs={6}>
                    <div id="currencyPairSelectAdd">
                        <Typography variant={'subtitle1'}>Available</Typography>
                        <FilteredMultiSelect
                            buttonText={'Add'}
                            className={classes.filteredMultiSelectRoot}
                            classNames={fmsClassNames}
                            disabled={disabled}
                            onChange={onAdd}
                            options={availableCurrencyPairOptions || []}
                            placeholder={'Search'}
                            showFilter
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div id="currencyPairSelectRemove">
                        <Typography variant={'subtitle1'}>Assigned</Typography>
                        <FilteredMultiSelect
                            buttonText={'Remove'}
                            className={classes.filteredMultiSelectRoot}
                            classNames={fmsClassNames}
                            onChange={onRemove}
                            options={assignedCurrencyPairOptions || []}
                            placeholder={'Search'}
                            showFilter
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    currencySelectCard: {
        flexGrow: 1,
    },
    filteredMultiSelectRoot: {},
    filteredMultiSelectFilter: {
        display: 'block',
        width: '100%',
        height: '34px',
        fontSize: 14,
        lineHeight: '1.42857143',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        border: 'none',
        outline: 'none',
    },
    filteredMultiSelectButton: {
        ...theme.typography.button,
        color: theme.palette.primary.main,
        border: 'none',
        borderColor: theme.palette.primary.main,
        borderRadius: '4px',
        marginTop: '4px',
        padding: '6px 12px',
    },
    filteredMultiSelectButtonActive: {
        ...theme.typography.button,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: 'none',
        borderColor: theme.palette.primary.main,
        borderRadius: '4px',
        marginTop: '4px',
        padding: '6px 12px',
    },
    filteredMultiSelectSelect: {
        display: 'block',
        width: '100%',
        fontSize: 14,
        lineHeight: '1.42857143',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        border: 'none',
        outline: 'none',
    },
}));
