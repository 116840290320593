/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactElement, useRef, useEffect } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    Grid,
    Step,
    StepButton,
    Stepper,
    Tooltip,
    makeStyles,
} from '@material-ui/core';
import { CheckCircle as SuccessIcon, Close as CloseIcon, ErrorOutline as ErrorIcon } from '@material-ui/icons';
import { isNumber, isObject, isString } from 'utils';
import Table from 'components/Table/Table';
import moment from 'moment';
import { SystemDateFormat } from 'constants/formats';
import Fab from '@material-ui/core/Fab';
import { CustomTheme } from 'theme/custom';
import { File, ParseFile } from 'components/upload';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';

const useStyles = makeStyles((theme: CustomTheme) => ({
    dialogTitleWrapper: {
        padding: 5,
        backgroundColor: theme.palette.primary.main,
    },
    dialogTitle: {
        padding: 5,
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center',
    },
    dialogTitleText: {
        justifySelf: 'center',
        color: theme.palette.background.paper,
    },
    TBDLogoWrapper: {
        padding: 4,
    },
    TBDLogoImg: {
        width: '30px',
        verticalAlign: 'middle',
        border: '0',
    },
    dialogTitleCloseBtnWrapper: {
        justifySelf: 'end',
        paddingLeft: 4,
    },
    dialogTitleCloseButton: {
        padding: 2,
        minHeight: '20px',
        minWidth: '20px',
        height: '20px',
        width: '20px',
    },
    dialogTitleCloseIcon: {
        fontSize: 15,
    },
    dialogContent: {
        padding: '5 5 10 5',
        display: 'grid',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1fr',
    },
    configSelect: {
        color: theme.palette.custom.text.body,
    },
    configSelectDisabled: {
        color: theme.palette.text.secondary,
    },
    root: {},
    stepContentWrapper: {
        padding: '5 5 10 5',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        justifyItems: 'center',
        alignItems: 'center',
    },
    stepperWrapper: {},
    stepper: {
        padding: '5 5 10 5',
    },
    button: {
        minWidth: '140px',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    progress: {
        margin: 2,
    },
    errorIcon: {
        fontSize: 80,
    },
    confTitle: {
        padding: 2,
    },
    confBody: {
        padding: 2,
    },
    hiddenInput: {
        visibility: 'hidden',
    },
}));

const processingStates = {
    fileSelect: 'Processing File',
    fileParse: 'Parsing File',
    objectMapping: 'Mapping Objects',
    processingImportObjectsForDownload: 'Preparing Download',
};

const errorStates = {
    processFileError: 'Error Processing File',
    parseFileError: 'Error Parsing File',
    mappingError: 'Error Mapping Headers',
    createImportObjectsError: 'Error Creating Import Objects',
    errorCreatingPreviewDownload: 'Error Creating Preview Download',
};

const states: Record<string, Record<string, number | string>> = {
    'Select File': {
        selectingFile: 0,
        fileSelected: 1,
        processingFile: processingStates.fileSelect,
        processFileError: errorStates.processFileError,
        confirmFileDropped: 4,
        parsingFile: processingStates.fileParse,
        parseFileError: errorStates.parseFileError,
        parseFileSuccess: 7,
    },
    'Import Configuration': {
        setup: 8,
        inProgress: processingStates.objectMapping,
        error: errorStates.mappingError,
        createImportObjectsError: errorStates.createImportObjectsError,
    },
};

const events = {
    init: states['Select File'].selectingFile,
    dropOrSelectFile: states['Select File'].processingFile,
    processFileError: states['Select File'].processFileError,
    processFileSuccess: states['Select File'].parsingFile,
    parseFileError: states['Select File'].parseFileError,
    parseFileSuccess: states['Import Configuration'].setup,
    changeSelectedMappingConf: states['Select File'].parsingFile,
    mappingError: states['Import Configuration'].error,
    startMappingToObjects: states['Import Configuration'].inProgress,
    errorCreatingImportObjects: states['Import Configuration'].createImportObjectsError,
};

const steps: string[] = Object.keys(states);

const getStep = (activeState: any) => {
    for (const step of Object.keys(states)) {
        const stepStates = states[step as string];
        if (isObject(stepStates)) {
            for (const stepState in stepStates) {
                if (stepStates[stepState] === activeState) {
                    return step;
                }
            }
        } else if (stepStates === activeState) {
            return step;
        }
    }
};

const stepComplete = (stepLabel: string, activeStep: string) => {
    return steps.indexOf(activeStep) > steps.indexOf(stepLabel);
};

const defaultMappingConf = {
    name: 'default',
    headerRowNo: 1,
};

const removeCharacter = (value: unknown, character: string): unknown | string => {
    if (!isString(value)) {
        return value;
    }
    while ((value as string).includes(character)) {
        value = (value as string).replace(character, '');
    }
    return value as string;
};

export const Import = (props: {
    onAwayClick: any;
    submitRecords: any;
    fields: any;
    newFromFields: (uploadObj: any, props: any) => void;
    previewTableColumns: any;
    addNewFromImportProps: any;
    show: boolean;
    category: any;
    parseFile: ParseFile;
    entityTypeDescription: string;
}): ReactElement => {
    const {
        submitRecords,
        previewTableColumns,
        fields,
        addNewFromImportProps,
        category,
        newFromFields,
        onAwayClick,
        parseFile,
        entityTypeDescription,
    } = props;

    const classes = useStyles();

    const fileRef = useRef<any>(null);

    const triggerInputClick = () => fileRef?.current?.click();

    const [activeState, setActiveState] = useState<any>(events.init);
    const [errors, setErrors] = useState<any>({});
    const [mappingConf, setMappingConf] = useState<any>(defaultMappingConf);
    const [importSheet, setImportSheet] = useState<any>();

    const cleanHeader = (header: any) => {
        if (typeof header !== typeof '_') {
            return header;
        }
        header = header.toLowerCase().trim();
        while (header.includes(' ')) {
            header = header.replace(' ', '');
        }
        while (header.includes('/')) {
            header = header.replace('/', '');
        }
        return header;
    };

    const parseValueForObj = (value: unknown, type: any, optional: boolean) => {
        let retVal;
        switch (type) {
            case 'date':
                // possible date formats
                const formats = ['MM/DD/YY', SystemDateFormat];

                if (value === '') {
                    // if a blank string is given
                    if (optional) {
                        // then if the value is optional, return default for date
                        return 0;
                    } else {
                        // otherwise log error and return undefined
                        console.error(`unable to parse value '${value}' to ${type}`);
                        return undefined;
                    }
                } else if (isString(value)) {
                    // otherwise if some other string is given
                    try {
                        // try and parse the date
                        return moment
                            .utc(value as string, formats)
                            .startOf('day')
                            .unix();
                    } catch (e) {
                        // if parsing fails then
                        if (optional) {
                            // if value is optional, return default for date
                            return 0;
                        } else {
                            // otherwise log error and return undefined
                            console.error(`unable to parse value '${value}' to ${type}`);
                            return undefined;
                        }
                    }
                } else if (isNumber(value)) {
                    // otherwise if a number is given
                    // return the integer part of the number
                    return Math.trunc(Number(value));
                } else {
                    // if anything else is given
                    if (optional) {
                        // if value is optional, return default for date
                        return 0;
                    } else {
                        // otherwise log error and return undefined
                        console.error(`unable to parse value '${value}' to ${type}`);
                        return undefined;
                    }
                }

            case 'float':
                value = (value as string).replace(',', '');
                retVal = parseFloat(value as string);
                // if the result is not a number
                if (isNaN(retVal)) {
                    if (optional) {
                        // then if the value is optional, return default for float
                        return 0.0;
                    } else {
                        // otherwise log error and return undefined
                        console.error(`unable to parse value '${value}' to ${type}`);
                        return undefined;
                    }
                } else {
                    // if the result is a number, return rounded to 4 decimal places
                    return retVal;
                }

            case 'int':
                // try and parse the value
                value = removeCharacter(value, ' ');
                if ((value as string).includes(',') && (value as string).includes('.')) {
                    value = removeCharacter(value, ',');
                }
                retVal = parseInt(value as string, 10);
                // if the result is not a number
                if (isNaN(retVal)) {
                    if (optional) {
                        // then if the value is optional, return default for int
                        return 0;
                    } else {
                        // otherwise log error and return undefined
                        console.error(`unable to parse value '${value}' to ${type}`);
                        return undefined;
                    }
                } else {
                    // if the result of parsing is a number, return as is
                    return retVal;
                }

            case 'string':
            default:
                if (value === '' || value === undefined) {
                    if (optional) {
                        // then if the value is optional, return default for string
                        return '';
                    } else {
                        // otherwise log error and return undefined
                        console.error(`unable to parse value '${value}' to ${type}`);
                        return undefined;
                    }
                } else {
                    return (value as any).toString();
                }
        }
    };

    const reInit = () => {
        setActiveState(events.init);
        setErrors({});
        setImportSheet({
            rowObjects: [],
        });
        setMappingConf(defaultMappingConf);
    };

    const closeImportDialog = () => {
        reInit();
        onAwayClick();
    };

    useEffect(() => {
        if (activeState === states['Import Configuration'].inProgress) {
            try {
                createImportObjects();
            } catch (e) {
                console.error('Error creating import objects', e);
                setActiveState(events.errorCreatingImportObjects);
                setErrors({
                    ...errors,
                    [events.errorCreatingImportObjects]: e,
                });
            }
            return;
        }
    }, [activeState]);

    const activeStep = getStep(activeState);
    const dialogProps = (() => {
        switch (activeStep) {
            default:
                return {
                    fullScreen: true,
                };
        }
    })();

    const renderProcessing = () => {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    alignItems: 'center',
                    justifyItems: 'center',
                    margin: '15px',
                }}
            >
                <div>
                    <b>{activeState}</b>
                </div>
                <div>
                    <CircularProgress className={classes.progress} />
                </div>
            </div>
        );
    };

    const renderError = () => {
        const error = errors[activeState];

        let errorMsg = 'Unknown Error';
        if (isObject(error)) {
            if (error.message) {
                errorMsg = error.message;
            }
        } else if (isString(error)) {
            errorMsg = error;
        } else if (error instanceof Error) {
            errorMsg = error.message;
        }

        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    alignItems: 'center',
                    justifyItems: 'center',
                    margin: '15px',
                }}
            >
                <div>
                    <b>{activeState}</b>
                </div>
                <div>
                    <ErrorIcon className={classes.errorIcon} />
                </div>
                <div>{errorMsg}</div>
                <div>
                    <Button className={classes.button} onClick={reInit}>
                        Start Over
                    </Button>
                </div>
            </div>
        );
    };

    const handleFileDrop = (acceptedFiles: any) => {
        if (!acceptedFiles.length) {
            console.error('No accepted files found');
            return;
        }

        // Find File extension
        const extPos = acceptedFiles[0].name.search(/\./);
        if (extPos < 0) {
            console.error('unable to find file extension');
            setActiveState(events.processFileError);
            return;
        }
        let fileExt: any;
        try {
            fileExt = acceptedFiles[0].name.slice(extPos + 1);
        } catch (e) {
            console.error('Unable to get file extension', e);
            setActiveState(events.processFileError);
            return;
        }

        // Create file reader object
        const reader = new FileReader();

        // Attach event handlers
        reader.onload = (event) => {
            setActiveState(events.processFileSuccess);
            const _file: File = {
                name: acceptedFiles[0].name,
                data: event?.target?.result,
                ext: fileExt,
            };
            try {
                parseFile(_file, props.category, (rowObjects: any[]) => {
                    setImportSheet({ rowObjects });
                    setActiveState(events.parseFileSuccess);
                });
            } catch (e) {
                console.error(`Error Parsing File\n${e}`);
                setActiveState(events.parseFileError);
                setErrors({
                    ...errors,
                    [events.parseFileError]: e,
                });
            }
        };

        reader.onerror = (err) => {
            console.error('error loading file: ', err);
            setActiveState(events.processFileError);
        };
        // Start File Read
        reader.readAsBinaryString(acceptedFiles[0]);
    };

    const createImportObjects = () => {
        const objInitFieldsTypeMap: Record<string, any> = {};
        const objInitFieldsOptionalMap: Record<string, any> = {};
        try {
            fields.forEach((fieldInit: any) => {
                objInitFieldsTypeMap[fieldInit.name as string] = fieldInit.type;
                objInitFieldsOptionalMap[fieldInit.name as string] = fieldInit.optional;
            });
        } catch (e) {
            throw new Error(`Unable to get objInitFields from given entityImportClass\n${e}`);
        }

        const _importObjects = [];
        const _importFieldsObjs = [];

        if (mappingConf.name === 'default') {
            for (let i = 0; i < importSheet.rowObjects.length; i++) {
                const rowObj = importSheet.rowObjects[i];
                const initObj: Record<string, any> = {};
                fields.forEach((objInit: any) => {
                    Object.keys(rowObj).forEach((rowHdr) => {
                        const objFieldUsed = objInit.objField && cleanHeader(objInit.objField) === cleanHeader(rowHdr);
                        if (objFieldUsed || cleanHeader(objInit.name) === cleanHeader(rowHdr)) {
                            const val = parseValueForObj(rowObj[rowHdr], objInit.type, objInit.optional);
                            initObj[objInit.name as string] = val;
                        }
                    });
                });
                // Create Objects
                const newObj = newFromFields(initObj, addNewFromImportProps);
                _importObjects.push(newObj);
                // Save init Objects
                _importFieldsObjs.push(initObj);
            }
        }

        return [_importObjects, _importFieldsObjs];
    };

    const colHeaderCheck = () => {
        // Check for missing non-default columns:
        // First get init data fields
        let importSheetHdrs: any;
        try {
            importSheetHdrs = Object.keys(importSheet.rowObjects[0]);
        } catch (e) {
            console.error(`invalid entityImportClass\n${e}`);
            setActiveState(events.mappingError);
            setErrors({
                ...errors,
                [events.mappingError]: `Error Mapping Column Headers\n${e}`,
            });
        }
        const missingCols = [];
        if (mappingConf.name === 'default') {
            for (let i = 0; i < fields.length; i++) {
                for (let j = 0; j < importSheetHdrs.length; j++) {
                    if (
                        (fields[i].objField && cleanHeader(fields[i].objField) === cleanHeader(importSheetHdrs[j])) ||
                        cleanHeader(fields[i].name) === cleanHeader(importSheetHdrs[j])
                    ) {
                        break;
                    }
                    if (j === importSheetHdrs.length - 1) {
                        if (!fields[i].optional) {
                            missingCols.push(fields[i].name);
                        }
                    }
                }
            }
        } else {
            console.error('invalid mapping configuration');
            setActiveState(events.mappingError);
            setErrors({
                ...errors,
                [events.mappingError]: 'invalid mapping configuration',
            });
        }
        return missingCols;
    };

    const renderMapHeadersToFieldsStep = () => {
        const missingHeaders = colHeaderCheck();
        let columns;
        try {
            columns = previewTableColumns.map((col: any) => {
                return {
                    title: col.Header,
                    field: col.accessor,
                    render: (rowObj: any) => rowObj[col.Header],
                };
            });
        } catch (e) {
            setActiveState(events.mappingError);
            setErrors({
                ...errors,
                [events.mappingError]: `Error Mapping Column Headers\n${e}`,
            });
        }

        return (
            <div
                id="importDialogImportConfigStepRoot"
                style={{
                    padding: 10,
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                }}
            >
                <div style={{ padding: '0px 0px 10px 0px' }}>
                    <Grid alignItems={'flex-start'} container direction={'column'} justify={'flex-start'} spacing={1}>
                        <Grid item>
                            <div
                                style={{
                                    display: 'grid',
                                    alignItems: 'center',
                                    gridTemplateColumns: 'auto auto',
                                }}
                            >
                                <div className={classes.confTitle}>Header Row Number:</div>
                                <div className={classes.confBody}>{mappingConf.headerRowNo}</div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div
                    style={{
                        padding: '0px 0px 10px 0px',
                        justifySelf: 'end',
                    }}
                >
                    <Grid alignItems={'flex-start'} container direction={'column'} justify={'flex-start'} spacing={1}>
                        {missingHeaders.length ? (
                            <React.Fragment>
                                <Grid item>
                                    <div
                                        style={{
                                            display: 'grid',
                                            alignItems: 'center',
                                            gridTemplateColumns: 'auto auto',
                                        }}
                                    >
                                        <div>Missing Columns In Import Sheet</div>
                                        <ErrorIcon />
                                    </div>
                                    <div>Resolve To Continue</div>
                                    <div>Correct Sheet Or Select Different Configuration</div>
                                </Grid>
                                <Grid item>
                                    <ul>
                                        {missingHeaders.map((hdr: any, idx: number) => {
                                            return <li key={idx}>{hdr}</li>;
                                        })}
                                    </ul>
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Grid item>
                                    <div
                                        style={{
                                            display: 'grid',
                                            alignItems: 'center',
                                            gridTemplateColumns: 'auto auto',
                                        }}
                                    >
                                        <div>All Columns Mapped Successfully</div>
                                        <SuccessIcon />
                                    </div>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </div>
                <div
                    style={{
                        padding: '0px 0px 10px 0px',
                        justifySelf: 'end',
                    }}
                >
                    <Grid alignItems={'flex-start'} container direction={'column'} justify={'flex-start'} spacing={1}>
                        <Grid item>
                            <Button
                                className={classes.button}
                                id="importDialogValidateBtn"
                                onClick={() => {
                                    const [importObjects, importFieldsObjs] = createImportObjects();
                                    submitRecords(importObjects, importFieldsObjs);
                                }}
                            >
                                Validate
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.button}
                                id="importDialogImportConfigStepStartOverBtn"
                                onClick={reInit}
                            >
                                Start Over
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div
                    style={{
                        overflow: 'auto',
                        gridColumn: '1/4',
                    }}
                >
                    <Table disableFooter columns={columns} data={importSheet.rowObjects} />
                </div>
            </div>
        );
    };

    const renderSelectFileStep = () => {
        return (
            <div style={{ padding: 10 }}>
                <Grid alignItems={'center'} container direction={'column'} spacing={1}>
                    <Grid container direction={'row'} justify={'center'} spacing={1}>
                        <React.Fragment>
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    id="selectFileBtn"
                                    onClick={() => triggerInputClick()}
                                >
                                    Select File
                                </Button>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </Grid>
                <input
                    className={classes.hiddenInput}
                    ref={fileRef}
                    type={'file'}
                    onChange={(event: any) => handleFileDrop(event.target.files)}
                />
            </div>
        );
    };

    return (
        <Dialog className={classes.root} onClose={closeImportDialog} open={props.show} scroll="paper" {...dialogProps}>
            <DialogTitle className={classes.dialogTitleWrapper}>
                <div className={classes.dialogTitle}>
                    <div className={classes.TBDLogoWrapper}></div>
                    {category === 'PurchaseNotes' && (
                        <div className={classes.dialogTitleText}>Importing: Purchase {entityTypeDescription}</div>
                    )}
                    {category === 'SalesNotes' && (
                        <div className={classes.dialogTitleText}>Importing: Sales {entityTypeDescription}</div>
                    )}
                    {category !== 'SalesNotes' && category !== 'PurchaseNotes' && (
                        <div className={classes.dialogTitleText}>
                            Importing: {category} {entityTypeDescription}
                        </div>
                    )}
                    <div className={classes.dialogTitleCloseBtnWrapper}>
                        <Tooltip placement="top" title="Close">
                            <Fab
                                aria-label="Close"
                                className={classes.dialogTitleCloseButton}
                                color="primary"
                                id="importDialogCloseBtn"
                                onClick={closeImportDialog}
                            >
                                <CloseIcon className={classes.dialogTitleCloseIcon} />
                            </Fab>
                        </Tooltip>
                    </div>
                </div>
            </DialogTitle>
            <ErrorBoundary type={'Import'} id={'entityTypeDescription'} errorComponent={<div>Oh no!</div>}>
                <div className={classes.dialogContent} id="importDialogRoot">
                    <div className={classes.stepperWrapper}>
                        <Stepper activeStep={steps.indexOf(activeStep || '')} className={classes.stepper} nonLinear>
                            {steps.map((stepLabel, stepIdx) => {
                                return (
                                    <Step key={stepIdx}>
                                        <StepButton completed={stepComplete(stepLabel, activeStep || '')}>
                                            {stepLabel}
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>
                    <div className={classes.stepContentWrapper}>
                        {(() => {
                            switch (true) {
                                // Render Generic Processing Screen
                                case Object.values(processingStates).includes(activeState || ''):
                                    return renderProcessing();
                                // Render Generic Error Screen
                                case Object.values(errorStates).includes(activeState):
                                    return renderError();

                                // Check For Steps and Render Them
                                case activeStep === 'Select File':
                                    return renderSelectFileStep();
                                case activeStep === 'Import Configuration':
                                    return renderMapHeadersToFieldsStep();
                                default:
                                    return <div>Should not see this</div>;
                            }
                        })()}
                    </div>
                </div>
            </ErrorBoundary>
        </Dialog>
    );
};

export default Import;
