import { Payment } from '.';
import { restRPC } from 'utils/restrpc';

export type MergeRequest = {
    payments: Payment[];
    partyCode: string;
};

export type MergeResponse = {
    mergedCount: number;
    missingInvoiceCount: number;
};

export interface Integrator {
    ServiceProviderName: string;
    Merge(request: MergeRequest): Promise<MergeResponse>;
}

export const DefaultIntegrator: Integrator = {
    ServiceProviderName: 'payment.Integrator',
    Merge(request: MergeRequest): Promise<MergeResponse> {
        return restRPC<MergeRequest, MergeResponse>({
            method: `${DefaultIntegrator.ServiceProviderName}.Merge`,
            request,
        });
    },
};
