import { AppContext, AppContextT } from 'context';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useEffect, useState } from 'react';

export const useService = <ReqT, ResT>(
    initialRequest: ReqT | undefined,
    service: (request: ReqT) => Promise<ResT>,
    callbacks?: {
        onResponse?: (r?: ResT) => void;
        onError?: (e?: string) => void;
    },
): [
    {
        request: ReqT | undefined;
        response: ResT | undefined;
        loading: boolean;
        error: string | undefined;
    },
    Dispatch<SetStateAction<ReqT | undefined>>,
    () => void,
] => {
    const appContext = useContext<AppContextT>(AppContext);

    const [request, setRequest] = useState<ReqT | undefined>(initialRequest);
    const [response, setResponse] = useState<ResT | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    let onResponse: ((r?: ResT) => void) | undefined;
    let onError: ((e?: string) => void) | undefined;
    if (callbacks) {
        onResponse = callbacks?.onResponse;
        onError = callbacks?.onError;
    }

    const resetResponse = () => {
        setResponse(undefined);
    };

    useEffect(() => {
        if (!request) {
            return;
        }

        setResponse(undefined);

        const fetchData = async (): Promise<ResT | undefined> => {
            setError('');
            setLoading(true);
            setRequest(undefined);
            try {
                const resp = await service(request);
                setResponse(resp);
                setLoading(false);
                onResponse && onResponse(resp);
                return resp;
            } catch (e) {
                if (e?.message?.includes('token has expired') || e?.code === -32001) {
                    appContext.setSessionExpired && appContext.setSessionExpired();
                }
                setResponse(undefined);
                const err = (e.message ? e.message : e).toString();
                setError(err);
                onError && onError(err);
                setLoading(false);
            }
        };
        fetchData().finally();
    }, [request, service]);

    return [{ request, response, loading, error }, setRequest, resetResponse];
};

export const useServiceWithCallBack = <ReqT, ResT>(
    initialRequest: ReqT | undefined,
    service: (request: ReqT) => Promise<ResT>,
): [
    {
        request: ReqT | undefined;
        response: ResT | undefined;
        loading: boolean;
        error: string | undefined;
    },
    Dispatch<SetStateAction<ReqT | undefined>>,
    Dispatch<SetStateAction<(() => (response: ResT | undefined, error: string | undefined) => void) | undefined>>,
    () => void,
] => {
    const appContext = useContext<AppContextT>(AppContext);

    const [request, setRequest] = useState<ReqT | undefined>(initialRequest);
    const [response, setResponse] = useState<ResT | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [nextReqCallBack, setNextReqCallBack] = useState<
        (() => (response: ResT | undefined, error: string | undefined) => void) | undefined
    >(undefined);

    const resetResponse = () => {
        setResponse(undefined);
        setError(undefined);
    };

    useEffect(() => {
        if (!request) {
            return;
        }

        setResponse(undefined);

        const fetchData = async () => {
            setError('');
            setLoading(true);
            setRequest(undefined);
            try {
                const resp = await service(request);
                setResponse(resp);
                setLoading(false);
                return resp;
            } catch (e) {
                if (e?.message?.includes('token has expired') || e?.code === -32001) {
                    appContext.setSessionExpired && appContext.setSessionExpired();
                }
                setResponse(undefined);
                const err = (e.message ? e.message : e).toString();
                setError(err);
                setLoading(false);
                throw err;
            }
        };

        fetchData()
            .then((result) => {
                // Typescript and inner workings of reacts not in sync
                // eslint-disable-next-line
                // @ts-ignore
                nextReqCallBack && nextReqCallBack(result, undefined);
                setNextReqCallBack(undefined);
            })
            .catch((e) => {
                // Typescript and inner workings of reacts not in sync
                // eslint-disable-next-line
                // @ts-ignore
                nextReqCallBack && nextReqCallBack(undefined, (e.message ? e.message : e).toString());
                setNextReqCallBack(undefined);
            });
    }, [request, service]);

    return [{ request, response, loading, error }, setRequest, setNextReqCallBack, resetResponse];
};

export const useServiceSync = <ReqT, ResT>(
    service?: (request: ReqT) => Promise<ResT>,
): [(request: ReqT) => Promise<ResT>] => {
    const appContext = useContext<AppContextT>(AppContext);
    if (!service) {
        throw new Error('service implementation not defined');
    }
    const exec = async (request: ReqT): Promise<ResT> => {
        try {
            return await service(request);
        } catch (e) {
            if (e?.message?.includes('token has expired') || e?.code === -32001) {
                appContext.setSessionExpired && appContext.setSessionExpired();
            }
            throw e;
        }
    };
    return [exec];
};
