import { v1 as uuidv1 } from 'uuid';

export type Request<T> = {
    jsonrpc: '2.0';
    id: string;
    method: string;
    params: T;
};

export type Response<T> = {
    jsonrpc: '2.0';
    id: string;
    result: T;
};

export function httpRequest<T>(params: { url: string; request?: unknown }): Promise<T> {
    const { url, request } = params;

    const header = new Headers({
        'Content-Type': 'application/json',
    });
    let body: string;
    if (request) {
        body = JSON.stringify(request);
    }
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: header,
            mode: 'cors',
            body: body,
            credentials: 'include',
        })
            .then((responseObject) => {
                if (responseObject.ok) {
                    responseObject
                        .json()
                        .then((body) => resolve(body))
                        .catch((e) => resolve(e));
                } else {
                    reject(responseObject);
                }
            })
            .catch((e) => reject(e));
    });
}

export const jsonRpcRequest = async (params: {
    url: string;
    method: string;
    request: Record<string, unknown>;
    onStart: () => void;
    onSuccess: (_: unknown) => void;
    onError: (_: unknown) => void;
    accessToken: string;
}): Promise<unknown> => {
    const { url, method, request, onStart, onSuccess, onError, accessToken } = params;

    onStart();
    const id = uuidv1();
    const header = new Headers({
        'Content-Type': 'application/json',
    });

    if (accessToken) {
        header.append('Authorization', accessToken);
    }

    const body = JSON.stringify({
        jsonrpc: '2.0',
        method: method,
        params: [request],
        id: id,
    });
    console.debug(method + ' jsonRpcRequest.body: ', JSON.parse(body));
    return fetch(url, {
        method: 'POST',
        headers: header,
        mode: 'cors',
        body: body,
        credentials: 'include',
    })
        .then((responseObject) => {
            return responseObject.json();
        })
        .then((response) => {
            console.debug(method + ' jsonRpcRequest.response: ', response);
            if (response.result) {
                onSuccess(response.result);
            } else {
                console.error(method + ' - error: ', response.error);
                if (response.error.message) {
                    onError(response.error.message);
                } else {
                    onError(response.error);
                }
            }
        })
        .catch((error) => {
            console.error(method + ' jsonRpcRequest.error: ', error);
            onError('Unknown error for - ' + method);
        });
};

export const jsonRpcRequestRaw = (params: {
    url: string;
    method: string;
    request?: Record<string, unknown>;
    accessToken?: string;
}): Promise<unknown> => {
    const { url, method, request, accessToken } = params;

    const id = uuidv1();
    const header = new Headers({
        'Content-Type': 'application/json',
    });

    if (accessToken) {
        header.append('Authorization', accessToken);
    }

    const body = JSON.stringify({
        jsonrpc: '2.0',
        method: method,
        params: [request],
        id: id,
    });
    console.debug(method + ' jsonRpcRequest.body: ', JSON.parse(body));

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: header,
            mode: 'cors',
            body: body,
            credentials: 'include',
        })
            .then((responseObject) => {
                if (responseObject.ok) {
                    return responseObject.json();
                } else {
                    reject('Request failed: ' + responseObject.statusText);
                }
            })
            .then((response) => {
                console.debug(method + ' jsonRpcRequest.response: ', response);
                if (response.result) {
                    console.debug(method + ' - success', response.result);
                    resolve(response.result);
                } else {
                    reject(response.error);
                    console.error(method + ' - error: ', response.error);
                }
            })
            .catch((error) => {
                reject(error);
                console.error(method + ' jsonRpcRequest.error: ', error);
            });
    });
};

export const jsonRPC = <ReqT, ResT>(params: {
    url: string;
    method: string;
    request?: ReqT;
    accessToken?: string;
}): Promise<ResT> => {
    const { url, method, request, accessToken } = params;

    const id = uuidv1();
    const header = new Headers({
        'Content-Type': 'application/json',
    });

    if (accessToken) {
        header.append('Authorization', accessToken);
    }

    const body = JSON.stringify({
        jsonrpc: '2.0',
        method: method,
        params: [request],
        id: id,
    });
    console.debug(method + ' jsonRpcRequest.body: ', JSON.parse(body));

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: header,
            mode: 'cors',
            body: body,
            credentials: 'include',
        })
            .then((responseObject) => {
                if (responseObject.ok) {
                    return responseObject.json();
                } else {
                    reject('Request failed: ' + responseObject.statusText);
                }
            })
            .then((response) => {
                console.debug(method + ' jsonRpcRequest.response: ', response);
                if (response.result) {
                    console.debug(method + ' - success', response.result);
                    resolve(response.result);
                } else {
                    console.error(method + ' - error: ', response.error);
                    reject(response.error);
                }
            })
            .catch((error) => {
                reject(error);
                console.error(method + ' jsonRpcRequest.error: ', error);
            });
    });
};
