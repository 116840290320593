export enum IdentifierType {
    ADMIN_EMAIL_IDENTIFIER = 'ADMIN_EMAIL_IDENTIFIER',
    CODE_IDENTIFIER = 'CODE_IDENTIFIER',
    ID_IDENTIFIER = 'ID_IDENTIFIER',
    ISO_CODE_IDENTIFIER = 'ISO_CODE_IDENTIFIER',
    LOGIN_NAME_IDENTIFIER = 'LOGIN_NAME_IDENTIFIER',
    NAME_IDENTIFIER = 'NAME_IDENTIFIER',
    HOSTNAME_IDENTIFIER = 'HOSTNAME_IDENTIFIER',
    NAME_PARTY_IDENTIFIER = 'NAME_PARTY_IDENTIFIER',
    PARTY_CODE_IDENTIFIER = 'PARTY_CODE_IDENTIFIER',
    REASON_IDENTIFIER = 'REASON_IDENTIFIER',
    THRESHOLD_CURRENCY_PAIR_IDENTIFIER = 'THRESHOLD_CURRENCY_PAIR_IDENTIFIER',
    TYPE_PARTY_IDENTIFIER = 'TYPE_PARTY_IDENTIFIER',
    NUMBER_PARTY_IDENTIFIER = 'NUMBER_PARTY_IDENTIFIER',
    NUMBER_PARTY_BANK_IDENTIFIER = 'NUMBER_PARTY_BANK_IDENTIFIER',
    NUMBER_PARTY_COUNTERPARTY_IDENTIFIER = 'NUMBER_PARTY_COUNTERPARTY_IDENTIFIER',
    NUMBER_PARTY_COUNTERPARTYID_IDENTIFIER = 'NUMBER_PARTY_COUNTERPARTYID_IDENTIFIER',
    EXTERNAL_REFERENCE_PARTY_COUNTERPARTY = 'EXTERNAL_REFERENCE_PARTY_COUNTERPARTY',
    NUMBER_IDENTIFIER = 'NUMBER_IDENTIFIER',
    EXTERNAL_REFERENCE_TRADINGPARTY_COUNTERPARTY = 'EXTERNAL_REFERENCE_TRADINGPARTY_COUNTERPARTY',
    EXTERNAL_REFERENCE_TRADINGPARTY = 'EXTERNAL_REFERENCE_TRADINGPARTY',
    EMAIL_IDENTIFIER = 'EMAIL_IDENTIFIER',
}

export type PartyCodeIdentifier = {
    type: IdentifierType.PARTY_CODE_IDENTIFIER;
    partyCode?: string;
};
export type NamePartyIdentifier = {
    type: IdentifierType.NAME_PARTY_IDENTIFIER;
    name?: string;
    partyCode?: string;
};
export type NameIdentifier = {
    type: IdentifierType.NAME_IDENTIFIER;
    name?: string;
};
export type IDIdentifier = {
    type: IdentifierType.ID_IDENTIFIER;
    id?: string;
};
export type ExternalReferenceTradingPartyIdentifier = {
    type: IdentifierType.EXTERNAL_REFERENCE_TRADINGPARTY;
    externalReference?: string;
    tradingPartyCode?: string;
};

export type Identifier =
    | IDIdentifier
    | PartyCodeIdentifier
    | NameIdentifier
    | ExternalReferenceTradingPartyIdentifier
    | NamePartyIdentifier;

export const isIdentifier = (i: unknown): i is Identifier => !!(i as Identifier).type;

export const marshalIdentifier = (identifier: Identifier): string => {
    try {
        if (!Object.values(IdentifierType).includes(identifier.type)) {
            throw new Error('identifier not supported');
        }
    } catch (err) {
        throw err;
    }
    return identifier.type + '::' + JSON.stringify(identifier);
};
