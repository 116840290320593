/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    TextField,
    Typography,
    useTheme,
} from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useStyletron } from 'styletron-react';
import { BuildDropDownFromArray } from './util';
import { FieldType } from '.';

export const ItemEntry = (props: {
    changed: boolean;
    fieldType: FieldType;
    label: string;
    value: any;
    nestedArray?: any;
    nestedArrayFieldsToIgnore?: any;
    error?: boolean;
    onChange?: (e: any) => void;
    textArea?: boolean;
}): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const [css] = useStyletron();
    if (props.fieldType === FieldType.FIELD_TYPE_VALUE) {
        if (props.nestedArray) {
            return (
                <div>
                    <ExpansionPanel>
                        <ExpansionPanelSummary style={{ height: '32px', minHeight: '32px' }}>
                            {props.label}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            style={{
                                maxHeight: '210px',
                                overflow: 'scroll',
                                paddingBottom: '20px',
                            }}
                        >
                            {BuildDropDownFromArray(0, props.value, props.nestedArrayFieldsToIgnore)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            );
        }
        return (
            <div
                className={css({
                    height: '31px',
                    display: 'grid',
                    marginLeft: '5px',
                    fontWeight: 'normal',
                    backgroundColor: props.changed ? theme.palette.primary.dark : 'transparent',
                    zIndex: 1,
                })}
            >
                <TextField
                    InputProps={{
                        classes: {
                            root: css({
                                color: props.changed
                                    ? theme.palette.custom.text.accent
                                    : theme.palette.custom.text.body,
                            }),
                        },
                    }}
                    disabled
                    error={props.error}
                    onChange={props.onChange}
                    value={{}.toString.apply(props.value) === '[object Array]' ? props.value.join(', ') : props.value}
                />
            </div>
        );
    } else {
        return (
            <div
                style={{
                    height: props.textArea ? '' : '31px',
                    display: 'grid',
                    gridTemplateColumns: '250px 288px',
                    marginLeft: '40px',
                    marginRight: '40px',
                }}
            >
                <Typography variant={'subtitle2'}>
                    <div>{props.label}</div>
                </Typography>
            </div>
        );
    }
};

export default ItemEntry;
