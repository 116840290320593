import React, { ReactElement } from 'react';
import { TextField, StandardTextFieldProps } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { makeStyles } from '@material-ui/styles';
import { HexToRGBA } from 'utils';

export interface BaseTextFieldProps extends StandardTextFieldProps {
    colorVariant?: string;
    id: string;
}

export const BaseTextField = (props: BaseTextFieldProps): ReactElement => {
    const { disabled, onChange, InputLabelProps, InputProps, value, ...rest } = props;
    const classes = useStyles();

    const mergedClasses = {
        ...{
            root: classes.lightTextFieldRoot,
        },
        ...props.classes,
    };

    return (
        <TextField
            {...rest}
            classes={mergedClasses}
            InputProps={{
                ...InputProps,
                classes: {
                    underline: classes.lightTextFieldUnderline,
                },
                className: InputProps?.className,
            }}
            InputLabelProps={{
                ...InputLabelProps,
                classes: {
                    focused: classes.lightLabelFocused,
                    root: classes.lightLabel,
                },
            }}
            disabled={disabled}
            margin={'none'}
            onChange={onChange}
            value={value}
        />
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    lightTextFieldRoot: {
        color: theme.palette.text.primary,
    },
    lightTextFieldUnderline: {
        '&:before': {
            borderBottom: `1px solid  ${HexToRGBA(theme.palette.text.primary, 0.7)}`,
        },
        '&:after': {
            borderBottom: `2px solid  ${theme.palette.primary.main}`,
        },
    },
    lightLabel: {
        color: HexToRGBA(theme.palette.text.primary, 0.7),
    },
    lightLabelFocused: {
        color: HexToRGBA(theme.palette.primary.main, 0.7),
    },
}));
