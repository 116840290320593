import React from 'react';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';
import { useTheme } from '@material-ui/styles';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';

export type Border = {
    weight: 'normal' | 'bold';
    color: 'default' | 'white' | 'import' | 'export';
};
export type BorderTemplate = {
    top?: Border;
    bottom?: Border;
    left?: Border;
    right?: Border;
};
export type CellProps = {
    variant?: 'title' | 'heading' | 'total' | 'value' | 'smallTitle' | 'smallValue';
    color?: 'default' | 'white' | 'yellow' | 'import' | 'export' | 'grey';
    align?: 'left' | 'right' | 'center';
    width?: string;
    height?: string;
    border?: BorderTemplate;
    text?: string;
    prefix?: string;
    editable?: boolean;
    editField?: (field: string, newValue: unknown, index: number) => void;
    field?: string;
    index?: number;
};

export const Cell = (props: CellProps): React.ReactElement => {
    const theme = useTheme<CustomTheme>();
    const { variant, color, align, width, height, border, editField, editable, text, field, prefix, index } = props;
    const aligns = {
        left: 'flex-start',
        right: 'flex-end',
        center: 'center',
    };

    const weights = {
        normal: '1px',
        bold: '2px',
    };

    const colors = {
        default: `${HexToRGBA(theme.palette.text.primary, 0.2)}`,
        white: theme.palette.text.primary,
        grey: theme.palette.text.secondary,
        yellow: theme.palette.warning.light,
        import: theme.palette.custom.import.main,
        export: theme.palette.custom.export.main,
    };

    // font style
    const variants = {
        title: {
            fontWeight: 'bold',
            fontSize: '16px',
        },
        heading: {
            fontWeight: 'bold',
            fontSize: '12px',
        },
        total: {
            fontWeight: 'bold',
            fontSize: '14px',
        },
        value: {
            fontWeight: 'normal',
            fontSize: '14px',
        },
        smallTitle: {
            fontWeight: 'bold',
            fontSize: '11px',
        },
        smallValue: {
            fontWeight: 'normal',
            fontSize: '12px',
        },
    };

    const borderStyle = (p: 'Left' | 'Right' | 'Top' | 'Bottom', t?: Border) =>
        t
            ? {
                  [`border${p}`]: `${weights[t.weight]} solid ${colors[t.color]}`,
              }
            : {};
    let style = {
        ...borderStyle('Top', border?.top),
        ...borderStyle('Bottom', border?.bottom),
        ...borderStyle('Left', border?.left),
        ...borderStyle('Right', border?.right),
    };
    // style
    style = variant ? { ...style, ...variants[variant] } : style;
    // color
    style = color ? { ...style, color: colors[color] } : style;

    return (
        <div
            style={{
                width: width || '',
                minWidth: width || '',
                flexGrow: width ? 0 : 1,
                height: height || '',
                display: 'flex',
            }}
        >
            <div
                style={{
                    ...style,
                    padding: theme.spacing(1),
                    textAlign: align ? align : 'center',
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: align ? aligns[align] : 'flex-start',
                    alignItems: 'center',
                }}
            >
                {!editable && <div>{text}</div>}
                {editable && (
                    <BaseNumberField
                        disabled={!editable}
                        id={field ? field : 'numberField'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            editField && field
                                ? editField(field, Number(e.target.value), index ? index : 0)
                                : () => void 0
                        }
                        value={text != '-' ? text : ''}
                        prefix={prefix}
                    />
                )}
            </div>
        </div>
    );
};
