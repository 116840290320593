/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { createStyles, debounce, makeStyles } from '@material-ui/core';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import Table from 'components/Table/Table';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { TradeConfirmation } from 'popcorn-js/tradeV3';
import { displayAmount } from 'utils';
import { useService, useServiceSync } from 'hooks/useService';
import {
    DefaultConfirmationHandler,
    FindTradeConfirmationsRequest,
    FindTradeConfirmationsResponse,
} from 'popcorn-js/tradeV3/confirmationHandler';
import Big from 'big.js';
import { Trade } from 'popcorn-js/tradeV2';
import moment from 'moment';
import { SystemDateFormat } from 'constants/formats';
import { ConfirmationDetail } from './ConfirmationDetail';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { DownloadRequest, DownloadResponse } from 'popcorn-js/cfcDeposit/downloader';
import FileSaver from 'file-saver';
import { FindRequest, FindResponse } from 'popcorn-js';
import { Handler as TradeHandler } from 'popcorn-js/tradeV2/handler';
import { format } from 'date-fns';
import {
    Downloader as DownloaderTrade,
    DownloadTradeRequest,
    DownloadTradeResponse,
} from 'popcorn-js/tradeV2/downloader';
import { downloadTemplate } from './index';
import { AppContext, AppContextT } from 'context';
import { PartyType } from 'popcorn-js/party';
import SelectedRowsButton from 'components/SelectedRowsButton/selectedRowsButton';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { ConfirmationsUploader } from './ConfirmationsUploader';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: 'calc(100vh - 100px)',
            overflowY: 'scroll',
            justifyItems: 'center',
        },
    }),
);

const baseCriteriaConfig: Record<string, Criterion> = {};
const baseTradeCriteriaConfig: Record<string, Criterion> = {
    financialYear: {
        type: CriteriaType.BoolCriterion,
        field: 'matched',
        value: false,
    },
};

export enum ActiveState {
    viewing = 'ACTIVE_STATE_VIEWING',
    viewDelete = 'ACTIVE_STATE_VIEWING_DELETE',
}

export const ConfirmationsWorkstation = (): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const usersContext = appContext.partyType;
    const [confirmationsTotal, setConfirmationsTotal] = useState<number>(0);
    const [confirmations, setConfirmations] = useState<TradeConfirmation[] | undefined>();
    const [tradesTotal, setTradesTotal] = useState<number>(0);
    const [trades, setTrades] = useState<Trade[] | undefined>();
    const [tabValue, setTabValue] = React.useState(0);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<TradeConfirmation[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>();
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [editConfirmation, setEditConfirmation] = useState<boolean>(false);
    const [validateConfirmation, setValidateConfirmation] = useState<boolean>(false);
    const [activeState, setActiveState] = useState<ActiveState>(ActiveState.viewing);
    const [tradeConfirmations, setTradeConfirmations] = useState<TradeConfirmation[] | undefined>();
    const [isSystem, setIsSystem] = useState<boolean>(true);
    const { tradeV3ConfirmationHandler } = useContext<ServiceContextT>(ServiceContext);
    const [findTradeService] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [uploading, setUploading] = useState<boolean>(false);
    const initialTradeCriteria = Object.keys(baseTradeCriteriaConfig).map((field) => {
        return baseTradeCriteriaConfig[field];
    });
    const initialCriteria = Object.keys(baseCriteriaConfig).map((field) => {
        return baseCriteriaConfig[field];
    });
    const [queryTrades, setQueryTrades] = useState<Query>({
        sortBy: ['tradeDate', 'externalReference'],
        order: ['desc', 'asc'],
        limit: 12,
        offset: 0,
    });
    const [query, setQuery] = useState<Query>({
        sortBy: ['tradeDate', 'createdDate', 'externalReference'],
        order: ['desc', 'desc', 'asc'],
        limit: 12,
        offset: 0,
    });
    const [criteriaTrades, setTradesCriteria] = useState<Criteria>(initialTradeCriteria);
    const [criteria, setCriteria] = useState<Criteria>(initialCriteria);
    const [{ response: deleteResponse, error: deleteError }, setDeleteRequest] = useService(
        undefined,
        tradeV3ConfirmationHandler.DeleteTradeConfirmation,
    );
    const [findTradeConfirmations] = useServiceSync<FindTradeConfirmationsRequest, FindTradeConfirmationsResponse>(
        DefaultConfirmationHandler.FindTradeConfirmations,
    );
    const [DownloadConfirmations] = useServiceSync<DownloadRequest, DownloadResponse>(
        DefaultConfirmationHandler.Download,
    );
    const [DownloadTradesExec] = useServiceSync<DownloadTradeRequest, DownloadTradeResponse>(
        DownloaderTrade.downloadTrades,
    );

    const downloadConfirmations = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const downloadResponse = await DownloadConfirmations({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            // convert base64 to byte array
            const binData = atob(downloadResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            const date = new Date();
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            const today = yyyy + '-' + mm + '-' + dd;
            FileSaver.saveAs(blob, 'Trade Confirmations Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
        setLoading(false);
    };

    const downloadUnmatchedTrades = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const downloadUnmatchedTradesResponse = await DownloadTradesExec({
                criteria: _criteria || criteriaTrades,
                query: _query || queryTrades,
            });
            // convert base64 to byte array
            const binData = atob(downloadUnmatchedTradesResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Trade Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
        setLoading(false);
    };

    const findConfirmations = async (_criteria?: Criteria, _query?: Query, _deleted?: boolean) => {
        setLoading(true);
        try {
            const result = await findTradeConfirmations({
                criteria: _criteria || criteria,
                query: _query || query,
                deleted: _deleted,
            });
            setTradeConfirmations(
                result.records.map((t: TradeConfirmation) => ({
                    ...t,
                })),
            );
            setConfirmations(result.records);
            setConfirmationsTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const findTrades = async (_criteria?: Criteria, _query?: Query, _deleted?: boolean) => {
        setLoading(true);
        try {
            const result = await findTradeService({
                criteria: _criteria || criteriaTrades,
                query: _query || queryTrades,
                deleted: _deleted,
            });
            setTrades(result.records);
            setTradesTotal(result.total);
        } catch (e) {
            setErrorMessage(e.message);
        }
        setSelected([]);
        setLoading(false);
    };

    const handleChangePage = (even: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findConfirmations(criteria, newQuery, activeState === ActiveState.viewDelete).finally();
    };

    const handleChangePageTrades = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = queryTrades.limit ? queryTrades.limit * newPage : 0;
        const newQuery = {
            ...queryTrades,
            offset,
        };
        setQueryTrades(newQuery);
        findTrades(criteriaTrades, newQuery, activeState === ActiveState.viewDelete).finally();
    };

    const handleChangeRowsPerPageTrades = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['tradeDate', 'externalReference'],
            order: ['desc', 'asc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQueryTrades(newQuery);
        findTrades(criteriaTrades, newQuery, activeState === ActiveState.viewDelete).finally();
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['tradeDate', 'createdDate', 'externalReference'],
            order: ['desc', 'desc', 'asc'],
            limit: Big(rowsPerPage).toNumber(),
            offset: 0,
        };
        setQuery(newQuery);
        findConfirmations(criteria, newQuery, activeState === ActiveState.viewDelete).finally();
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const handleChangeSorting = (sortBy: string, order: QueryOrderT) => {
        const newQuery: Query = {
            ...query,
            sortBy: [sortBy, 'externalReference'],
            order: [order, 'asc'],
        };
        setQuery(newQuery);
        findConfirmations(criteria, newQuery, activeState === ActiveState.viewDelete).finally();
    };

    const handleChangeSortingTrades = (sortBy: string, order: QueryOrderT) => {
        setSelected([]);
        const newQuery = {
            ...queryTrades,
            sortBy: [sortBy],
            order: [order],
        };
        setQueryTrades(newQuery);
        findTrades(criteriaTrades, newQuery, activeState === ActiveState.viewDelete).finally();
    };

    const handleFilterChange = useCallback(
        debounce((newCrit: Criteria, tab?: number) => {
            const newQuery = {
                ...query,
                offset: 0,
            };
            let newTab = tabValue;
            if (tab != undefined) {
                newTab = tab;
            }
            const validatedCriteria = newCrit.filter((f: Criterion) => f.field === 'validated');
            const matchedCriteria = newCrit.filter((f: Criterion) => f.field === 'matched');
            if (newTab === 1 && validatedCriteria.length === 0 && matchedCriteria.length === 0) {
                newCrit.push(
                    { type: CriteriaType.BoolCriterion, field: 'validated', value: false },
                    { type: CriteriaType.BoolCriterion, field: 'matched', value: true },
                );
            }
            if (newTab === 2 && validatedCriteria.length === 0 && matchedCriteria.length === 0) {
                newCrit.push(
                    { type: CriteriaType.BoolCriterion, field: 'validated', value: false },
                    { type: CriteriaType.BoolCriterion, field: 'matched', value: false },
                );
            }
            if (newCrit) {
                setQuery(newQuery);
                setCriteria(newCrit);
                findConfirmations(newCrit, newQuery, activeState === ActiveState.viewDelete).finally();
            }
        }, 100),
        [query, tabValue, activeState],
    );

    const handleUnmatchedFilterChange = useCallback(
        debounce((newCrit: Criteria, tab?: number) => {
            const newQuery = {
                ...queryTrades,
                offset: 0,
            };
            let newTab = tabValue;
            if (tab != undefined) {
                newTab = tab;
            }
            const tabCriteria = newCrit.filter((f: Criterion) => f.field === 'matched');
            if (tabCriteria.length === 0) {
                if (newTab == 3) {
                    newCrit.push({
                        type: CriteriaType.BoolCriterion,
                        field: 'matched',
                        value: false,
                    });
                }
            }
            if (newCrit) {
                setQueryTrades(newQuery);
                setTradesCriteria(newCrit);
                findTrades(newCrit, newQuery, activeState === ActiveState.viewDelete).finally();
            }
        }, 100),
        [query, tabValue, activeState],
    );

    const handleSelectRow = (rowData: TradeConfirmation) => {
        const ind = (selected || []).findIndex(
            (selectedConfirmation: TradeConfirmation) => selectedConfirmation.id === rowData.id,
        );
        const _selected = [...(selected || [])];
        if (tabValue === 1) {
            _selected.splice(0);
        }
        if (ind === -1) {
            const newSelected = { ...rowData };
            if (newSelected.direction == 'BUY') {
                newSelected.amountBoughtByBank = newSelected.lcyAmount;
                newSelected.amountSoldByBank = newSelected.fxAmount;
                newSelected.currencyBoughtByBank = newSelected.currencyPair?.split('/')[1];
                newSelected.currencySoldByBank = newSelected.currencyPair?.split('/')[0];
            } else {
                newSelected.amountSoldByBank = newSelected.lcyAmount;
                newSelected.amountBoughtByBank = newSelected.fxAmount;
                newSelected.currencyBoughtByBank = newSelected.currencyPair?.split('/')[0];
                newSelected.currencySoldByBank = newSelected.currencyPair?.split('/')[1];
            }
            _selected.push(newSelected);
        } else {
            _selected.splice(ind, 1);
        }
        setSelected && setSelected(_selected);
    };

    const handleSelectAll = () => {
        const newSelected = [...(selected || [])];
        if (newSelected.length !== 0) {
            setSelected && setSelected([]);
        } else if (tradeConfirmations) {
            tradeConfirmations.forEach((tc: TradeConfirmation) => {
                newSelected.push(tc);
            });
            setSelected && setSelected(newSelected);
        }
    };

    const handleRowDoubleClick = (rowData: TradeConfirmation) => {
        const newSelected = { ...rowData };
        if (newSelected.direction == 'BUY') {
            newSelected.amountBoughtByBank = newSelected.lcyAmount;
            newSelected.amountSoldByBank = newSelected.fxAmount;
            newSelected.currencyBoughtByBank = newSelected.currencyPair?.split('/')[1];
            newSelected.currencySoldByBank = newSelected.currencyPair?.split('/')[0];
        } else {
            newSelected.amountSoldByBank = newSelected.lcyAmount;
            newSelected.amountBoughtByBank = newSelected.fxAmount;
            newSelected.currencyBoughtByBank = newSelected.currencyPair?.split('/')[0];
            newSelected.currencySoldByBank = newSelected.currencyPair?.split('/')[1];
        }
        setSelected([newSelected]);
        setEditConfirmation(true);
    };

    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const closeDialog = () => {
        setOpenNew(false);
        setEditConfirmation(false);
        setValidateConfirmation(false);
        setSelected([]);
        setShowFilterRow(false);
        handleFilterChange([]);
        handleUnmatchedFilterChange([]);
    };

    const showCreationConfirmation = () => {
        setWarningMessage('Please confirm that the selected confirmations\nDO NOT relate to SWAPS or CANCELLATIONS.');
        setConfirmationMethod(() => () => {
            setWarningMessage(undefined);
        });
    };

    const showDeleteConfirmation = (confirmations: TradeConfirmation[]) => {
        let conNumber = '';
        confirmations.forEach((con: TradeConfirmation) => {
            if (conNumber.length == 0) {
                conNumber += con.externalReference;
            }
        });
        if (confirmations.length == 1) {
            setWarningMessage(`You are about to delete Trade Confirmation ${conNumber}.\n Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await tradeV3ConfirmationHandler.DeleteTradeConfirmation({
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: confirmations[0].id },
                    });
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSuccessMessage(`Trade Confirmation ${conNumber} Deleted`);
                    setDeleteConfirmation(true);
                    setSelected([]);
                    await findConfirmations();
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        } else {
            const externalReferences = [] as string[];
            for (const conf of confirmations) {
                if (conf.externalReference) {
                    externalReferences.push(conf.externalReference);
                }
            }
            setWarningMessage(
                `You are about to delete ${externalReferences.length} Trade Confirmations.\n Do you want to continue?`,
            );
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await tradeV3ConfirmationHandler.DeleteTradeConfirmationBatch({
                        externalReferences: externalReferences,
                    });
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSuccessMessage(`${externalReferences.length} Trade Confirmations Deleted`);
                    setSelected([]);
                    await findConfirmations();
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        }
    };

    const showDeleteForeverConfirmation = (confirmations: TradeConfirmation[]) => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        let number = '';
        confirmations.forEach((tc: TradeConfirmation) => {
            if (number.length == 0) {
                number += tc.externalReference;
            }
        });
        if (confirmations.length === 1) {
            setWarningMessage(
                `You are about to delete Trade Confirmation ${number} forever.\n Do you want to continue?`,
            );
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await tradeV3ConfirmationHandler.DeleteTradeConfirmationForever({
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: confirmations[0].id },
                    });
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSuccessMessage(`Trade Confirmation ${number} Delete Forever`);
                    setSelected([]);
                    await findConfirmations(criteria, query, true);
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        } else {
            const ids = [] as string[];
            for (const con of confirmations) {
                if (con.id) {
                    ids.push(con.id);
                }
            }
            setWarningMessage(
                `You are about to delete ${ids.length} Trade Confirmation forever.\n Do you want to continue?`,
            );
            setConfirmationMethod(() => async () => {
                setLoading(true);
                const deleteBatch: any[] = confirmations.map((confirmation: any) => {
                    return {
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: confirmation.id },
                        confirmation: confirmation,
                    };
                });
                try {
                    await tradeV3ConfirmationHandler.DeleteTradeConfirmationBatchForever({
                        deleteBatch: deleteBatch,
                    });
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSuccessMessage(`${ids.length} Trade Confirmation Deleted Forever`);
                    setSelected([]);
                    await findConfirmations(criteria, query, true);
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        }
    };

    const allowRestoreConfirmation = (confirmations: TradeConfirmation[]) => {
        let confirmNumber = '';
        confirmations.forEach((tr: TradeConfirmation) => {
            if (confirmNumber.length == 0) {
                confirmNumber += tr.externalReference;
            }
        });
        if (confirmations.length === 1) {
            setLoading(true);
            setSelected([]);
            tradeV3ConfirmationHandler
                .Restore({
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: confirmations[0].id },
                })
                .then(() => {
                    setSuccessMessage(`Trade Confirmation ${confirmNumber} Restored`);
                    findConfirmations(criteria, query, true).then();
                })
                .catch((e) => setErrorMessage(e.message || e))
                .finally(() => setLoading(false));
        } else {
            setLoading(true);
            setSelected([]);
            const restoreBatch: any[] = confirmations.map((con: any) => {
                return {
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: con.id },
                    confirmation: con,
                };
            });
            tradeV3ConfirmationHandler
                .RestoreBatch({
                    restoreBatch: restoreBatch,
                })
                .then(() => {
                    setSuccessMessage(`${restoreBatch.length} Trade Confirmations Restored`);
                    findConfirmations(criteria, query, true).then();
                })
                .catch((e) => setErrorMessage(e.message || e))
                .finally(() => setLoading(false));
        }
    };

    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
        setSelected([]);
        switch (newValue) {
            case 0:
                setShowFilterRow(false);
                handleFilterChange([], 0);
                break;
            case 1: {
                setShowFilterRow(false);
                handleFilterChange(
                    [
                        {
                            type: CriteriaType.BoolCriterion,
                            field: 'validated',
                            value: false,
                        },
                        {
                            type: CriteriaType.BoolCriterion,
                            field: 'matched',
                            value: true,
                        },
                    ],
                    1,
                );
                break;
            }
            case 2: {
                setShowFilterRow(false);
                handleFilterChange(
                    [
                        {
                            type: CriteriaType.BoolCriterion,
                            field: 'matched',
                            value: false,
                        },
                        {
                            type: CriteriaType.BoolCriterion,
                            field: 'validated',
                            value: false,
                        },
                    ],
                    2,
                );
                break;
            }
            case 3: {
                setActiveState(ActiveState.viewing);
                setShowFilterRow(false);
                handleUnmatchedFilterChange([], 3);
            }
        }
    };

    const handleHelperTextFilter = (helpText?: string, tab?: number) => {
        let newTab = tabValue;
        if (tab != undefined) {
            newTab = tab;
        }
        if (newTab == 0 || newTab == 1 || newTab == 2) {
            helpText = 'Filter Confirmations';
        } else {
            helpText = 'Filter Unmatched trades';
        }
        return helpText;
    };

    const handleHelperTextNew = (helpText?: string, tab?: number) => {
        let newTab = tabValue;
        if (tab != undefined) {
            newTab = tab;
        }
        if (newTab == 0) {
            helpText = 'Add Confirmation';
        }
        if (newTab == 2) {
            helpText = 'Add Trade';
        }
        return helpText;
    };

    useEffect(() => {
        setIsSystem(() => {
            return usersContext === PartyType.SYSTEM;
        });
        if (usersContext === PartyType.SYSTEM) {
            setTabValue(3);
        }
    }, []);

    useEffect(() => {
        if (deleteError && deleteError !== '' && deleteConfirmation) {
            setErrorMessage(deleteError);
            setDeleteConfirmation(false);
        }
        if (deleteResponse && deleteResponse.confirmation && deleteResponse.confirmation.id) {
            setDeleteConfirmation(false);
        }
    }, [deleteError, deleteConfirmation, setDeleteConfirmation, deleteResponse]);

    if (selected) {
        useEffect(() => {
            if (deleteConfirmation && selected?.length === 1) {
                setDeleteRequest({ identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected[0].id } });
                setSelected([]);
            }
        }, [selected[0], deleteConfirmation, setDeleteRequest]);
    }

    return (
        <div id="tradeStationRoot" className={classes.root}>
            {openNew && <ConfirmationDetail closeDialog={closeDialog} show={openNew} />}
            {editConfirmation && (
                <ConfirmationDetail
                    closeDialog={closeDialog}
                    show={editConfirmation}
                    confirmationEdit={selected[0]}
                    edit={true}
                />
            )}
            {validateConfirmation && (
                <ConfirmationDetail
                    closeDialog={closeDialog}
                    show={validateConfirmation}
                    confirmationEdit={selected[0]}
                    edit={true}
                    validate={true}
                />
            )}
            {uploading && (
                <ConfirmationsUploader
                    show={uploading}
                    onClose={() => {
                        setUploading(false);
                    }}
                />
            )}
            <ActionsMenu
                id={'OrderStation/more-options'}
                anchorElement={moreOptionsAnchorEl}
                items={[
                    {
                        id: 'download-template',
                        text: 'Download Template',
                        onClick: () => downloadTemplate(),
                    },
                    {
                        id: 'upload-confirmations',
                        text: 'Upload Confirmations',
                        onClick: () => setUploading(true),
                        disabled: isSystem,
                    },
                    {
                        id: 'download-confirmations',
                        text: 'Download Confirmations',
                        onClick: () => {
                            if (tabValue == 0 || tabValue == 1 || tabValue == 2) {
                                downloadConfirmations(criteria, query).then();
                            } else {
                                downloadUnmatchedTrades(criteriaTrades, queryTrades).then();
                            }
                        },
                    },
                ]}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                title={'More Options'}
            />
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TABS,
                            options: (() => {
                                return [
                                    {
                                        id: 'AllConfirmations',
                                        label: 'All Confirmations',
                                        value: 'All Confirmations',
                                    },
                                    {
                                        id: 'ValidationErrors',
                                        label: 'Validation Errors',
                                        value: 'Validation Errors',
                                    },
                                    {
                                        id: 'UnmatchedConfirmations',
                                        label: 'Unmatched Confirmations',
                                        value: 'Unmatched Confirmations',
                                    },
                                    {
                                        id: 'UnmatchedTrades',
                                        label: 'Unmatched Trades',
                                        value: 'Unmatched Trades',
                                    },
                                ];
                            })(),
                            onChange: (_event: ChangeEvent<unknown>, value: number) => {
                                handleTabChange(value);
                            },
                            value: tabValue,
                            id: 'fec-tabs',
                        },
                        {
                            type: ITEM_VARIATION.ELEMENT,
                            id: 'ConformationsWorkstation/confirmation/selectedRowButton',
                            element: (
                                <SelectedRowsButton
                                    disabled={false}
                                    onClick={() => setSelected([])}
                                    count={selected?.length ? selected?.length : 0}
                                />
                            ),
                            hide: selected?.length === 0 || !selected,
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/validate',
                            icon: ACTION_BUTTON_TYPE.CHECK_MARK,
                            helpText: 'Validate',
                            hide:
                                selected?.length === 0 ||
                                selected?.length > 1 ||
                                selected[0]?.validated ||
                                selected[0]?.matched ||
                                tabValue == 0 ||
                                tabValue == 1 ||
                                tabValue == 3 ||
                                activeState == ActiveState.viewDelete,
                            onClick: () => setValidateConfirmation(true),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/return',
                            icon: ACTION_BUTTON_TYPE.RETURN,
                            helpText: 'Return',
                            onClick: () => {
                                setActiveState(ActiveState.viewing);
                                setShowFilterRow(false);
                                setQuery(query);
                                setSelected([]);
                                findConfirmations(criteria, query, false).then();
                            },
                            hide: activeState !== ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/delete',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete',
                            onClick: () => showDeleteConfirmation(selected || ({} as TradeConfirmation[])),
                            hide: isSystem || selected?.length === 0 || activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/edit',
                            icon: ACTION_BUTTON_TYPE.EDIT,
                            helpText: 'Edit',
                            onClick: () => setEditConfirmation(true),
                            hide:
                                isSystem ||
                                selected?.length === 0 ||
                                selected?.length > 1 ||
                                tabValue == 3 ||
                                activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/delete-forever',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete Forever',
                            onClick: () => showDeleteForeverConfirmation(selected || ({} as TradeConfirmation[])),
                            hide: selected?.length === 0 || activeState !== ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/restore',
                            icon: ACTION_BUTTON_TYPE.RESTORE,
                            helpText: 'Restore',
                            onClick: () => allowRestoreConfirmation(selected || ({} as TradeConfirmation[])),
                            hide: selected?.length === 0 || activeState !== ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/new',
                            icon: ACTION_BUTTON_TYPE.NEW,
                            helpText: handleHelperTextNew(),
                            onClick: () => {
                                if (tabValue == 0) {
                                    setOpenNew(true);
                                } else {
                                    showCreationConfirmation();
                                }
                            },
                            hide:
                                isSystem ||
                                (selected?.length === 1 && tabValue == 0) ||
                                (tabValue == 2 &&
                                    (selected?.length === 0 ||
                                        appContext.currentContext?.partyType !== PartyType.CLIENT)) ||
                                tabValue == 1 ||
                                tabValue == 3 ||
                                activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/show_filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: handleHelperTextFilter(),
                            onClick: () => {
                                if (tabValue == 0 || tabValue == 1 || tabValue == 2) {
                                    setShowFilterRow(!showFilterRow);
                                    handleFilterChange([], tabValue);
                                } else {
                                    setShowFilterRow(!showFilterRow);
                                    handleUnmatchedFilterChange([], tabValue);
                                }
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/download',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: 'Download Unmatched trades',
                            onClick: () => {
                                downloadUnmatchedTrades(criteriaTrades, queryTrades).then();
                            },
                            hide: activeState === ActiveState.viewDelete || tabValue != 3,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'MasterList/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: ' Open column configuration',
                            onClick: () => setColConfigOpen(true),
                            hide: tabValue == 3,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'confirmation/view-deleted',
                            icon: ACTION_BUTTON_TYPE.VIEW_DELETED,
                            helpText: ' View Deleted Items',
                            onClick: () => {
                                setActiveState(ActiveState.viewDelete);
                                setQuery(query);
                                setSelected([]);
                                findConfirmations(criteria, query, true).then();
                            },
                            hide: isSystem || tabValue == 3 || activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/more-options',
                            icon: ACTION_BUTTON_TYPE.MORE_OPTIONS,
                            helpText: 'More Options',
                            onClick: (event: any) =>
                                setMoreActionsAnchorEl(event.currentTarget ? event.currentTarget : undefined),
                            hide: tabValue === 3,
                        },
                    ],
                }}
            >
                <div>
                    {((): React.ReactNode => {
                        return (
                            <>
                                {tabValue != 3 && (
                                    <Table
                                        colConfigCloseFromCard={handleCloseColConfig}
                                        colConfigOpenFromCard={colConfigOpen}
                                        columns={[
                                            {
                                                title: 'Bank',
                                                field: 'bank',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.bank) {
                                                        return rowData.bank;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Client',
                                                field: 'client',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.client) {
                                                        return rowData.client;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Bank Client',
                                                field: 'bankClient',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.bankClient) {
                                                        return rowData.bankClient;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Trade Date',
                                                field: 'tradeDate',
                                                filter: {
                                                    displayAccessor: 'tradeDate',
                                                    valueAccessor: 'tradeDate',
                                                    type: CriteriaType.TimeCriterion,
                                                },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.tradeDate) {
                                                        return moment(rowData.tradeDate).format(SystemDateFormat);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'External Reference',
                                                field: 'externalReference',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.externalReference) {
                                                        return rowData.externalReference;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'FX Amount',
                                                field: 'fxAmount.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.fxAmount) {
                                                        return displayAmount(rowData.fxAmount);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'LCY Amount',
                                                field: 'lcyAmount.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.lcyAmount) {
                                                        return displayAmount(rowData.lcyAmount);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Deal Rate',
                                                field: 'dealRate',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.dealRate) {
                                                        return rowData.dealRate.toFixed(6);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Maturity Date',
                                                field: 'maturityDate',
                                                filter: {
                                                    displayAccessor: 'maturityDate',
                                                    valueAccessor: 'maturityDate',
                                                    type: CriteriaType.TimeCriterion,
                                                },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.maturityDate) {
                                                        return moment(rowData.maturityDate).format(SystemDateFormat);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Created Date',
                                                field: 'createdDate',
                                                filter: {
                                                    displayAccessor: 'createdDate',
                                                    valueAccessor: 'createdDate',
                                                    type: CriteriaType.TimeCriterion,
                                                },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.createdDate) {
                                                        return moment(rowData.createdDate).format(SystemDateFormat);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Parent Trade',
                                                field: 'parentTradeReference',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.parentTradeReference) {
                                                        return rowData.parentTradeReference;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Deal Status',
                                                field: 'dealStatusIndicator',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.dealStatusIndicator) {
                                                        return rowData.dealStatusIndicator;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            tabValue === 0
                                                ? {
                                                      title: 'Matched',
                                                      field: 'matched',
                                                      filter: { type: CriteriaType.BoolCriterion },
                                                      render: (rowData: TradeConfirmation) => {
                                                          return rowData.matched ? 'Matched' : '';
                                                      },
                                                  }
                                                : {
                                                      title: 'Matched',
                                                      field: 'matched',
                                                      render: (rowData: TradeConfirmation) => {
                                                          return rowData.matched ? 'Matched' : '';
                                                      },
                                                  },
                                            tabValue === 0
                                                ? {
                                                      title: 'Validated',
                                                      field: 'validated',
                                                      filter: { type: CriteriaType.BoolCriterion },
                                                      render: (rowData: TradeConfirmation) => {
                                                          return rowData.validated
                                                              ? 'Validated'
                                                              : rowData.matched
                                                              ? 'Error'
                                                              : '';
                                                      },
                                                  }
                                                : {
                                                      title: 'Validated',
                                                      field: 'validated',
                                                      render: (rowData: TradeConfirmation) => {
                                                          return rowData.validated
                                                              ? 'Validated'
                                                              : rowData.matched
                                                              ? 'Error'
                                                              : '';
                                                      },
                                                  },
                                            {
                                                title: 'Currency Pair',
                                                field: 'currencyPair',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.currencyPair) {
                                                        return rowData.currencyPair;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Direction',
                                                field: 'direction',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.direction) {
                                                        return rowData.direction;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Notes',
                                                field: 'notes',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.notes) {
                                                        return rowData.notes;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Party Code',
                                                field: 'processingOrgPartyCode',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: TradeConfirmation) => {
                                                    if (rowData.processingOrgPartyCode) {
                                                        return rowData.processingOrgPartyCode;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                        ]}
                                        count={confirmationsTotal}
                                        data={confirmations || []}
                                        defaultColConfig={[
                                            { header: 'Bank', visible: true },
                                            { header: 'Client', visible: true },
                                            { header: 'Bank Client', visible: true },
                                            { header: 'Trade Date', visible: true },
                                            { header: 'External Reference', visible: true },
                                            { header: 'LCY Amount', visible: true },
                                            { header: 'FX Amount', visible: true },
                                            { header: 'Currency Pair', visible: true },
                                            { header: 'Deal Rate', visible: true },
                                            { header: 'Maturity Date', visible: true },
                                            { header: 'Created Date', visible: true },
                                            { header: 'Parent Trade', visible: false },
                                            { header: 'Deal Status', visible: true },
                                            { header: 'Matched', visible: true },
                                            { header: 'Validated', visible: true },
                                            { header: 'Direction', visible: true },
                                            { header: 'Notes', visible: false },
                                            { header: 'Party Code', visible: false },
                                        ]}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        loading={loading}
                                        onChangeSorting={handleChangeSorting}
                                        onFilterChange={handleFilterChange}
                                        onRowCheck={handleSelectRow}
                                        order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                        page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                        rowClickAction={handleSelectRow}
                                        rowDoubleClickAction={(e) => {
                                            !isSystem ? handleRowDoubleClick(e) : undefined;
                                        }}
                                        rowsPerPage={query.limit}
                                        rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                                        onSelectAll={handleSelectAll}
                                        selected={selected ? selected : []}
                                        showCheckboxes
                                        showFilterRow={showFilterRow}
                                        sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                        tableID={'TradeMasterListTable'}
                                        title={'Trades'}
                                        initialCriteria={baseCriteriaConfig}
                                    />
                                )}
                                {tabValue == 3 && (
                                    <Table
                                        columns={[
                                            {
                                                title: 'Bank',
                                                field: 'bank',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.bank) {
                                                        return rowData.bank;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'External Reference',
                                                field: 'externalReference',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.externalReference) {
                                                        return rowData.externalReference;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Trading Party Code',
                                                field: 'tradingPartyCode',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.tradingPartyCode) {
                                                        return rowData.tradingPartyCode;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Currency Pair',
                                                field: 'currencyPair',
                                                filter: { type: CriteriaType.TextCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.currencyPair) {
                                                        return rowData.currencyPair;
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Notional Amount',
                                                field: 'notionalAmount.value',
                                                filter: { type: CriteriaType.NumberCriterion },
                                                render: (rowData: Trade) => {
                                                    if (rowData.notionalAmount) {
                                                        return displayAmount(rowData.notionalAmount);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Trade Date',
                                                field: 'tradeDate',
                                                filter: {
                                                    displayAccessor: 'tradeDate',
                                                    valueAccessor: 'tradeDate',
                                                    type: CriteriaType.TimeCriterion,
                                                },
                                                render: (rowData: Trade) => {
                                                    if (rowData.tradeDate) {
                                                        return moment(rowData.tradeDate).format(SystemDateFormat);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                            {
                                                title: 'Maturity Date',
                                                field: 'maturityDate',
                                                filter: {
                                                    displayAccessor: 'maturityDate',
                                                    valueAccessor: 'maturityDate',
                                                    type: CriteriaType.TimeCriterion,
                                                },
                                                render: (rowData: Trade) => {
                                                    if (rowData.maturityDate) {
                                                        return moment(rowData.maturityDate).format(SystemDateFormat);
                                                    } else {
                                                        return '-';
                                                    }
                                                },
                                            },
                                        ]}
                                        count={tradesTotal}
                                        data={trades || []}
                                        defaultColConfig={[
                                            { header: 'Bank', visible: true },
                                            { header: 'Trading Party Code', visible: true },
                                            { header: 'External Reference', visible: true },
                                            { header: 'Currency Pair', visible: true },
                                            { header: 'Notional Amount', visible: true },
                                            { header: 'Trade Date', visible: true },
                                            { header: 'Maturity Date', visible: true },
                                        ]}
                                        handleChangePage={handleChangePageTrades}
                                        handleChangeRowsPerPage={handleChangeRowsPerPageTrades}
                                        loading={loading}
                                        onChangeSorting={handleChangeSortingTrades}
                                        order={
                                            queryTrades.order && queryTrades.order.length > 0
                                                ? queryTrades.order[0]
                                                : undefined
                                        }
                                        page={Math.ceil(
                                            queryTrades.limit && queryTrades.offset
                                                ? queryTrades.offset / queryTrades.limit
                                                : 0,
                                        )}
                                        rowsPerPage={queryTrades.limit}
                                        rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                                        sortBy={
                                            queryTrades.sortBy && queryTrades.sortBy.length > 0
                                                ? queryTrades.sortBy[0]
                                                : undefined
                                        }
                                        tableID={'Trades'}
                                        title={'Trades'}
                                        initialCriteria={baseTradeCriteriaConfig}
                                        showFilterRow={showFilterRow}
                                        onFilterChange={handleUnmatchedFilterChange}
                                    />
                                )}
                            </>
                        );
                    })()}
                </div>
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
            </StandardCard>
        </div>
    );
};
