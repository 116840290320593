import React, { ReactElement, useEffect, useState } from 'react';
import { DataGrid, GridRowData } from '@material-ui/data-grid';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { useStyletron } from 'styletron-react';
import { DefaultRecordkeeper } from 'popcorn-js/obfuscation/recordkeeper';
import { useService } from 'hooks/useService';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';

export const ObfuscationConfig = (): ReactElement => {
    const [css] = useStyletron();

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    const [{ response: findResponse, loading: findInProgress, error: findError }, setFindRequest] = useService(
        { criteria: [] },
        DefaultRecordkeeper.find,
    );
    useEffect(() => {
        if (findError) {
            setFindRequest({});
        } else {
            setErrorMessage(undefined);
        }
    }, [findError]);
    useEffect(() => {
        if (findError) {
            setErrorMessage(findError);
        } else {
            setErrorMessage(undefined);
        }
    }, [findError]);
    return (
        <StandardCard
            cardHeaderProps={{
                itemsLeft: [
                    {
                        id: 'ClientMapping/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Client mapping',
                    },
                ],
                itemsRight: [],
            }}
        >
            <div
                className={css({
                    padding: '16px',
                    height: 'calc(100vh - 200px)',
                })}
            >
                {findResponse?.records && (
                    <DataGrid
                        loading={findInProgress}
                        hideFooter
                        rows={((): Readonly<GridRowData[]> => {
                            const rows = findResponse?.records?.map((obf) => {
                                return {
                                    id: obf.id || '',
                                    oldName: obf.oldName || '',
                                    newName: obf.newName || '-',
                                };
                            });
                            return rows || ([] as Readonly<GridRowData[]>);
                        })()}
                        columns={[
                            {
                                field: 'oldName',
                                headerName: 'Original Name',
                                width: 200,
                            },
                            {
                                field: 'newName',
                                headerName: 'Fake Name',
                                width: 300,
                            },
                        ]}
                    />
                )}
            </div>
            <NotificationSweetAlert
                onClose={() => {
                    setErrorMessage(undefined);
                    setSuccessMessage(undefined);
                }}
                errorMessage={errorMessage}
                successMessage={successMessage}
            />
        </StandardCard>
    );
};
