import React, { ChangeEvent, MouseEvent, ReactElement, useContext } from 'react';
import { CardHeader, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import classNames from 'classnames';
import {
    Link,
    LinkOff,
    Add,
    Done,
    Folder,
    RemoveRedEye,
    Save,
    AddCircle,
    ArrowBackIos,
    Clear,
    Delete,
    Edit,
    ExpandLess,
    ExpandMore,
    FilterList,
    GetApp,
    MoreVert,
    RestoreFromTrash,
    Visibility,
    ViewColumn,
    History,
    TrendingDown,
    TrendingFlat,
    Refresh,
    Mail,
    Publish,
    SystemUpdateAlt,
    PostAdd,
    DeleteForever,
} from '@material-ui/icons';

import { BaseButton, VARIANT as BASE_BUTTON_VARIANT, SIZE } from 'components/BaseButton';
import { StyledTabs } from 'components/Tabs/Tabs';
import { StyledTab } from 'components/Tabs/Tab';

import { COLOR as BaseWebButtonColor } from 'components/BaseButton';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { COLOR as ActionButtonColor } from 'components/ActionButton/ActionButton';
import { AppContext, AppContextT } from 'context';
import SplitButton from 'components/SplitButton';

export const StandardCardHeader = (props: CardHeaderProps): ReactElement => {
    const { itemsRight, tailoredState, selectedItems } = props;
    let { color, itemsLeft } = props;
    const classes = useStyles({ fatter: props.fatter })();
    const appContext = useContext<AppContextT>(AppContext);

    switch (tailoredState) {
        case STATES.SELECTED_ROW:
            color = COLOR.PRIMARY;
            itemsLeft = [
                {
                    id: 'StandardCardHeader/title',
                    type: ITEM_VARIATION.TITLE,
                    text: selectedItems && selectedItems > 1 ? `${selectedItems} Selected Rows` : 'Selected Row',
                },
            ];
            break;
        default:
            break;
    }

    const renderItemsRight = (): ReactElement => {
        return renderItems('R');
    };

    const renderItemsLeft = (): ReactElement => {
        return renderItems('L');
    };

    const renderItems = (position: 'L' | 'R'): ReactElement => {
        let key = 0;
        let itemsToRender = itemsLeft;
        if (position === 'R') {
            itemsToRender = itemsRight;
        }
        return (
            <div className={classes.flexDisplay}>
                {itemsToRender?.map((item) => {
                    key++;
                    if (item.hide) {
                        return null;
                    }
                    switch (item.type) {
                        case ITEM_VARIATION.TABS:
                            return (
                                <div key={item.id}>
                                    <StyledTabs onChange={item.onChange} value={item.value}>
                                        {item.options.map((o) => {
                                            key++;
                                            return <StyledTab id={o.id} key={key} label={o.label} />;
                                        })}
                                    </StyledTabs>
                                </div>
                            );
                        case ITEM_VARIATION.TITLE:
                            return (
                                <div
                                    key={item.id}
                                    className={classNames({
                                        [classes.titleDefault]: !item.variant,
                                        [classes.title1]: item.variant === 'title1',
                                        [classes.title2]: item.variant === 'title2',
                                        [classes.title3]: item.variant === 'title3',
                                        [classes.importColor]: color === COLOR.IMPORT,
                                        [classes.exportColor]: color === COLOR.EXPORT,
                                        [classes.titlePrimaryContrastColor]: color === COLOR.PRIMARY,
                                    })}
                                >
                                    {item.text}
                                </div>
                            );
                        case ITEM_VARIATION.BUTTON:
                            return (
                                <div key={item.id} className={classes.buttonWrapper}>
                                    <BaseButton
                                        id={item.id}
                                        key={item.id}
                                        text={item.text}
                                        disabled={item.disabled}
                                        onClick={item.onClick}
                                        variant={item.buttonVariant}
                                        color={((): BaseWebButtonColor => {
                                            if (color === COLOR.PRIMARY) {
                                                return BaseWebButtonColor.DARKBLUE;
                                            }
                                            return BaseWebButtonColor.ACTION;
                                        })()}
                                        size={item.size || SIZE.SMALL}
                                    />
                                </div>
                            );

                        case ITEM_VARIATION.BUTTON_WITH_DROPDOWN:
                            return (
                                <div key={item.id}>
                                    <SplitButton
                                        id={item.id}
                                        disableDropdown={item.disableDropdown}
                                        disabled={item.disabled}
                                        initialOption={item.initialOption}
                                        menuOptions={item.menuOptions}
                                        onSelect={item.onSelect}
                                    />
                                </div>
                            );
                        case ITEM_VARIATION.ICON_BUTTON:
                            return (
                                <div key={item.id}>
                                    <ActionButton
                                        disabled={item.disabled}
                                        helpText={item.helpText}
                                        icon={IconMap[item.icon]}
                                        id={item.id}
                                        onClick={item.onClick}
                                        color={((): ActionButtonColor | undefined => {
                                            if (color === COLOR.PRIMARY) {
                                                return ActionButtonColor.PRIMARY;
                                            }
                                        })()}
                                    />
                                </div>
                            );
                        case ITEM_VARIATION.ELEMENT:
                            return (
                                <div
                                    key={item.id}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={item.onClick}
                                >
                                    {item.element}
                                </div>
                            );
                    }
                })}
            </div>
        );
    };

    return (
        <CardHeader
            classes={{
                root: classes.root,
                action: classes.action,
            }}
            className={classNames({
                [classes.root]: color === undefined,
                [classes.primaryColor]: color === COLOR.PRIMARY,
                [classes.stellcapBrand1Color]: color === COLOR.CONTEXT && !appContext.contextSwitched,
                [classes.stellcapBrand2Color]: color === COLOR.CONTEXT && appContext.contextSwitched,
            })}
            action={renderItemsRight()}
            title={renderItemsLeft()}
        />
    );
};

const useStyles = ({ fatter }: { fatter?: boolean }) =>
    makeStyles((theme: CustomTheme) => ({
        buttonWrapper: {
            margin: '3px',
        },
        flexDisplay: {
            display: 'flex',
        },
        root: {
            padding: 0,
            height: fatter ? '48px' : '42px',
        },
        primaryColor: {
            padding: 0,
            background: theme.palette.primary.main,
        },
        importColor: {
            background: theme.palette.custom.import.main,
        },
        exportColor: {
            background: theme.palette.custom.export.main,
        },
        stellcapBrand2Color: {
            background: theme.palette.custom.stellcapBrand2.main,
        },
        stellcapBrand1Color: {
            background: theme.palette.custom.stellcapBrand1.main,
        },
        action: {
            alignSelf: 'unset',
            margin: '0px',
            marginRight: theme.spacing(2),
        },
        titleDefault: {
            fontSize: '20px',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(1),
            marginRight: '20px',
            paddingLeft: theme.spacing(2),
        },
        title1: {
            fontSize: '20px',
            fontWeight: 'bold',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
            margin: theme.spacing(3),
        },
        title2: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
            margin: theme.spacing(3),
        },
        title3: {
            fontSize: '16px',
            fontWeight: 'bold',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
            margin: theme.spacing(3),
        },
        titlePrimaryContrastColor: {
            color: theme.palette.primary.contrastText,
        },
    }));

export enum ACTION_BUTTON_TYPE {
    NEW = 'NEW',
    VIEW_DETAIL = 'VIEW_DETAIL',
    VIEW_DELETED = 'VIEW_DELETED',
    ADD = 'ADD',
    SHOW_FILTER = 'SHOW_FILTER',
    MORE_OPTIONS = 'MORE_OPTIONS',
    DOWNLOAD = 'DOWNLOAD',
    DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE',
    UPLOAD = 'UPLOAD',
    CUSTOM = 'CUSTOM',
    OPEN_COL_CONF = 'OPEN_COL_CONF',
    RETURN = 'RETURN',
    COLLAPSE = 'COLLAPSE',
    EXPAND = 'EXPAND',
    DELETE = 'DELETE',
    DELETE_PERMANENTLY = 'DELETE_PERMANENTLY',
    RESTORE = 'RESTORE',
    EDIT = 'EDIT',
    CANCEL = 'CANCEL',
    LINK = 'LINK',
    LINK_OFF = 'LINK_OFF',
    PLUS = 'PLUS',
    SAVE = 'SAVE',
    CHECK_MARK = 'CHECK_MARK',
    HISTORY = 'HISTORY',
    DRAW_DOWN = 'DRAW_DOWN',
    EXTEND = 'EXTEND',
    REFRESH = 'REFRESH',
    SEND = 'SEND',
}

type IconMapT = Record<ACTION_BUTTON_TYPE, ReactElement>;
const IconMap: IconMapT = {
    ADD: <PostAdd />,
    NEW: <AddCircle />,
    PLUS: <Add />,
    VIEW_DETAIL: <Visibility />,
    VIEW_DELETED: <Folder />,
    SHOW_FILTER: <FilterList />,
    MORE_OPTIONS: <MoreVert />,
    DOWNLOAD: <GetApp />,
    DOWNLOAD_TEMPLATE: <SystemUpdateAlt />,
    UPLOAD: <Publish />,
    CUSTOM: <RemoveRedEye />,
    OPEN_COL_CONF: <ViewColumn />,
    RETURN: <ArrowBackIos />,
    COLLAPSE: <ExpandLess />,
    EXPAND: <ExpandMore />,
    DELETE: <Delete />,
    DELETE_PERMANENTLY: <DeleteForever />,
    RESTORE: <RestoreFromTrash />,
    EDIT: <Edit />,
    CANCEL: <Clear />,
    LINK: <Link />,
    LINK_OFF: <LinkOff />,
    SAVE: <Save />,
    CHECK_MARK: <Done />,
    HISTORY: <History />,
    DRAW_DOWN: <TrendingDown />,
    EXTEND: <TrendingFlat />,
    REFRESH: <Refresh />,
    SEND: <Mail />,
};

export enum ITEM_VARIATION {
    BUTTON = 'BUTTON',
    BUTTON_WITH_DROPDOWN = 'BUTTON_WITH_DROPDOWN',
    TITLE = 'TITLE',
    SMALL_TITLE = 'SMALL_TITLE',
    TABS = 'TABS',
    ICON_BUTTON = 'ICON_BUTTON',
    ELEMENT = 'ELEMENT', // usage discouraged
}

export type ButtonT = {
    type: ITEM_VARIATION.BUTTON;
    text: string;
    disabled?: boolean;
    buttonVariant: BASE_BUTTON_VARIANT;
    onClick?: () => void;
    hide?: boolean;
    id: string;
    size?: SIZE;
    // size // This will be controlled be the StandardCardHeader
    // buttonColor: BaseWebButtonColor // This will be controlled be the StandardCardHeader
};

export type ButtonWithDropDown = {
    type: ITEM_VARIATION.BUTTON_WITH_DROPDOWN;
    disableDropdown: boolean;
    disabled: boolean;
    initialOption: string;
    menuOptions: Array<string>;
    onSelect: (arg: string) => void;
    hide?: boolean;
    id: string;
};

export type Title = {
    type: ITEM_VARIATION.TITLE;
    text: string;
    hide?: boolean;
    variant?: 'title1' | 'title2' | 'title3';
    id: string;
    // Color and typography will be controlled be the StandardCardHeader
};

export type Tabs = {
    type: ITEM_VARIATION.TABS;
    options: { id: string; label: string; value: string }[];
    onChange: (_event: ChangeEvent<unknown>, value: number) => void;
    value: number;
    hide?: boolean;
    id: string;
    // Color and typography will be controlled be the StandardCardHeader
};

export type RenderReactElement = {
    type: ITEM_VARIATION.ELEMENT;
    element: ReactElement;
    hide?: boolean;
    id: string;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    // Color and typography will be controlled be the StandardCardHeader
};

export type IconButton = {
    type: ITEM_VARIATION.ICON_BUTTON;
    id: string;
    disabled?: boolean;
    icon: ACTION_BUTTON_TYPE;
    helpText?: string;
    onClick: (event: MouseEvent<HTMLElement>) => void;
    hide?: boolean;
    // Color and typography will be controlled be the StandardCardHeader
};

export type Item = ButtonT | ButtonWithDropDown | Title | Tabs | IconButton | RenderReactElement;

export enum COLOR {
    DEFAULT = 'DEFAULT', // pallette.background.paper
    PRIMARY = 'PRIMARY',
    CONTEXT = 'CONTEXT',
    CONTRAST = 'CONTRAST',
    IMPORT = 'IMPORT',
    EXPORT = 'EXPORT',
}

// TODO add tailoredStates
export enum STATES {
    SELECTED_ROW = 'SELECTED_ROW',
}

export type CardHeaderProps = {
    color?: COLOR;
    fatter?: boolean;
    itemsRight?: Item[];
    itemsLeft?: Item[];
    tailoredState?: STATES;
    fullHeight?: boolean;
    selectedItems?: number;
    squareEdge?: boolean;
};
