import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from 'theme/custom';
import { roundScaleAndFormatNumber } from 'utils';
import { Currency } from 'popcorn-js/currency';

export const DisplayValue = (props: { currency: Currency; value: number }): React.ReactElement => {
    const { currency, value } = props;
    const classes = useStyles({});

    if (value > 999999999) {
        return <div className={classes.justifyEnd}>{`${currency.symbol}999,999,999+`}</div>;
    } else if (value < -999999999) {
        return <div className={classes.justifyEnd}>{`-${currency.symbol}999,999,999+`}</div>;
    } else {
        return <div className={classes.justifyEnd}>{roundScaleAndFormatNumber(value, currency.symbol, false, 0)}</div>;
    }
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    justifyEnd: {
        justifySelf: 'end',
        color: theme.palette.grey[200],
    },
}));
