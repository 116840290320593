/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext } from 'react';
import { ItemWrapper } from 'components/audit/IOWrapper';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { StyledSelect } from 'components/Select/StyledSelect';
import { Order, OrderStatus, orderTypeOptions } from 'popcorn-js/order';
import { StyledAsyncSelect } from 'components/Select/StyledAsyncSelect';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { SystemDateFormat } from 'constants/formats';
import { processUnixDateForViewing } from 'utils';
import { showIcon } from './OrderDetailV2';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { InvalidReason } from 'popcorn-js/order/recordkeeper';
import { financialYears } from 'popcorn-js';
import { AppContext, AppContextT } from 'context';
import { Counterparty } from 'popcorn-js/counterparty';

export const OrderData = (props: {
    invalidFields: InvalidReason[];
    readOnly: boolean;
    order: Order;
    orderCounterparty: Counterparty;
    inputProps: {
        classes: {
            underline: string;
        };
        disableUnderline: boolean;
        readOnly: boolean;
    };
    editOrder: (field: string, newValue: any) => void;
    generateCounterpartyOptions: (inputValue: string) => Promise<{ value: string; label: string }[]>;
}): ReactElement => {
    const {
        invalidFields,
        readOnly,
        order,
        orderCounterparty,
        inputProps,
        editOrder,
        generateCounterpartyOptions,
    } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const statusOptions = Object.values(OrderStatus).map((status: OrderStatus) => ({
        label: status,
        value: status,
    }));
    const financialYearOptions = financialYears.map((financialYear) => ({
        value: financialYear,
        label: financialYear,
    }));

    const currencyOptions =
        appContext.currencies?.map((c: any) => ({
            value: c.isoCode,
            label: c.isoCode,
        })) || [];
    return (
        <div className={classes.orderDetailsCardWrapper}>
            <div className={classes.typeItemWrapper}>
                <ItemWrapper validationErrors={invalidFields} field={'type'} label={'Type'}>
                    {readOnly ? (
                        <BaseTextField
                            id="OrderDetail/type"
                            value={order?.type || ''}
                            InputProps={(() => {
                                return {
                                    ...inputProps,
                                    className: classes.formFieldOrange,
                                };
                            })()}
                        />
                    ) : (
                        <StyledSelect
                            readOnly
                            onChange={(selected: any) => {
                                editOrder('type', selected.value);
                            }}
                            options={orderTypeOptions.map((status) => ({
                                label: status,
                                value: status,
                            }))}
                            value={{
                                label: order.type || '',
                                value: order.type || '',
                            }}
                        />
                    )}
                </ItemWrapper>
                <div className={classes.twoColumnWrapper}>
                    <ItemWrapper validationErrors={invalidFields} field={'number'} label={'Number'}>
                        <BaseTextField
                            disabled={readOnly}
                            InputProps={inputProps}
                            id="OrderDetail/number"
                            onChange={(e: any) => editOrder('number', e.target.value)}
                            value={order?.number || ''}
                        />
                    </ItemWrapper>
                    <ItemWrapper validationErrors={invalidFields} field={'status'} label={'Status'}>
                        {readOnly ? (
                            <BaseTextField
                                disabled={readOnly}
                                InputProps={inputProps}
                                id="OrderDetail/status"
                                value={order?.status || ''}
                            />
                        ) : (
                            <StyledSelect
                                readOnly
                                onChange={(selected: any) => editOrder('status', selected.value)}
                                options={statusOptions}
                                value={{
                                    label: order.status || OrderStatus.PROPOSED,
                                    value: order.status || OrderStatus.PROPOSED,
                                }}
                            />
                        )}
                    </ItemWrapper>
                </div>
                <div className={classes.twoColumnWrapper}>
                    <ItemWrapper validationErrors={invalidFields} field={'financialYear'} label={'Financial Year'}>
                        {readOnly ? (
                            <BaseTextField
                                disabled={readOnly}
                                InputProps={inputProps}
                                id="OrderDetail/financialYear"
                                value={order?.financialYear || ''}
                            />
                        ) : (
                            <StyledSelect
                                readOnly
                                onChange={(selected: any) => editOrder('financialYear', selected.value)}
                                options={financialYearOptions}
                                value={{
                                    label: order?.financialYear || '',
                                    value: order?.financialYear || '',
                                }}
                            />
                        )}
                    </ItemWrapper>
                    <ItemWrapper validationErrors={invalidFields} field={'direction'} label={'Direction'}>
                        <BaseTextField
                            disabled
                            InputProps={inputProps}
                            id="OrderDetail/Direction"
                            value={order?.direction}
                        />
                    </ItemWrapper>
                </div>
            </div>
            <div className={classes.typeItemWrapper}>
                <div className={classes.twoColumnWrapper}>
                    <ItemWrapper validationErrors={invalidFields} field={'currency'} label={'Currency'}>
                        {readOnly ? (
                            <BaseTextField
                                disabled={readOnly}
                                InputProps={inputProps}
                                id="OrderDetail/currency"
                                value={order?.currency}
                            />
                        ) : (
                            <StyledSelect
                                readOnly
                                onChange={(selected: any) => editOrder('currency', selected.value)}
                                options={currencyOptions}
                                value={currencyOptions.find((b) => b.value === order.currency)}
                            />
                        )}
                    </ItemWrapper>
                    <ItemWrapper validationErrors={invalidFields} field={'costCurrency'} label={'Cost Currency'}>
                        {readOnly ? (
                            <BaseTextField
                                disabled={readOnly}
                                InputProps={inputProps}
                                id="OrderDetail/costCurrency"
                                value={order?.costCurrency}
                            />
                        ) : (
                            <StyledSelect
                                readOnly
                                onChange={(selected: any) => editOrder('costCurrency', selected.value)}
                                options={currencyOptions}
                                value={currencyOptions.find((b) => b.value === order.costCurrency)}
                            />
                        )}
                    </ItemWrapper>
                </div>
                <div style={{ width: '100%' }}>
                    <ItemWrapper
                        fieldLength={'267px'}
                        validationErrors={invalidFields}
                        field={'counterpartyId'}
                        label={'Counterparty'}
                    >
                        {readOnly ? (
                            <BaseTextField
                                disabled={readOnly}
                                InputProps={inputProps}
                                id="OrderDetail/counterparty"
                                value={
                                    order?.counterpartyId !== '' ? orderCounterparty.name || order?.counterpartyId : ''
                                }
                            />
                        ) : (
                            <StyledAsyncSelect
                                readOnly={readOnly}
                                loadOptions={generateCounterpartyOptions}
                                onChange={(selected: any) => editOrder('counterpartyId', selected?.value)}
                                value={{
                                    value: order?.counterpartyId || '',
                                    label: orderCounterparty.name || order?.counterpartyId || '',
                                }}
                            />
                        )}
                    </ItemWrapper>
                </div>
            </div>
            <div className={classes.twoColumnWrapper}>
                <ItemWrapper
                    field={'expectedDeliveryDate'}
                    validationErrors={invalidFields}
                    label={'Expected Delivery Date'}
                >
                    <div style={{ width: '125px' }}>
                        <LightDatePicker
                            disabled={readOnly}
                            minDateMessage={null}
                            format={'yyyy/MM/dd'}
                            keyboardIcon={showIcon(readOnly)}
                            InputProps={{
                                disableUnderline: readOnly,
                            }}
                            id="expectedDeliveryDate"
                            onChange={(date: MaterialUiPickersDate, value: string | null | undefined) =>
                                editOrder('expectedDeliveryDate', moment.utc(value))
                            }
                            style={{ width: '100%' }}
                            value={moment(order.expectedDeliveryDate).format(SystemDateFormat)}
                        />
                    </div>
                </ItemWrapper>
                <ItemWrapper validationErrors={invalidFields} field={'issueDate'} label={'Issue Date'}>
                    <div style={{ width: '125px' }}>
                        <LightDatePicker
                            disabled={readOnly}
                            format={'yyyy/MM/dd'}
                            invalidDateMessage={null}
                            keyboardIcon={showIcon(readOnly)}
                            InputProps={{
                                disableUnderline: readOnly,
                            }}
                            id="issueDate"
                            onChange={(date: MaterialUiPickersDate, value: string | null | undefined) =>
                                editOrder('issueDate', moment.utc(value).unix())
                            }
                            style={{ width: '100%' }}
                            value={processUnixDateForViewing(order?.issueDate)}
                        />
                    </div>
                </ItemWrapper>
            </div>
            <div className={classes.twoColumnWrapper}>
                <ItemWrapper validationErrors={invalidFields} field={'shipmentReference'} label={'Shipping Reference'}>
                    <BaseTextField
                        disabled={readOnly}
                        InputProps={inputProps}
                        id="OrderDetail/shipmentReference"
                        onChange={(e) => editOrder('shipmentReference', e.target.value)}
                        value={order?.shipmentReference || '#'}
                    />
                </ItemWrapper>
                <ItemWrapper validationErrors={invalidFields} field={'shippingDate'} label={'Shipping Date'}>
                    <div style={{ width: '125px' }}>
                        <LightDatePicker
                            disabled={readOnly}
                            format={'yyyy/MM/dd'}
                            keyboardIcon={showIcon(readOnly)}
                            invalidDateMessage={null}
                            InputProps={{
                                disableUnderline: readOnly,
                            }}
                            id="shippingDate"
                            onChange={(date: MaterialUiPickersDate, value: string | null | undefined) =>
                                editOrder('shippingDate', moment.utc(value).unix())
                            }
                            style={{ width: '100%' }}
                            value={processUnixDateForViewing(order?.shippingDate || null)}
                            emptyLabel={'-'}
                        />
                    </div>
                </ItemWrapper>
            </div>
            <ItemWrapper fieldLength={'250px'} validationErrors={invalidFields} field={'notes'} label={'Notes'}>
                <BaseTextField
                    disabled={readOnly}
                    InputProps={(() => {
                        return {
                            ...inputProps,
                            className: classes.notesInputProp,
                        };
                    })()}
                    id="OrderDetail/notes"
                    onChange={(e: any) => editOrder('notes', e.target.value)}
                    value={order?.notes || ''}
                />
            </ItemWrapper>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    orderDetailsCardWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper5,
        display: 'grid',
        width: '308px',
    },
    typeItemWrapper: {
        height: 'fit-content',
        paddingBottom: '19px',
        borderBottom: '1px solid rgba(255,255,255,.25)',
    },
    twoColumnWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '1px',
    },
    formFieldOrange: {
        color: theme.palette.custom.import.main,
    },
    notesInputProp: {
        fontSize: '12px',
    },
}));
