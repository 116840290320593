/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useCallback, SyntheticEvent } from 'react';
import { withStyles, TextField, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { CriteriaType, NumberCriterion } from 'popcorn-js/search';
import { HexToRGBA } from 'utils';
import _ from 'lodash';
import { Clear } from '@material-ui/icons';

const styles = (theme: any) => ({
    input: {
        color: theme.palette.text.primary,
        fontSize: 12,
        '::placeholder': {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    notchedOutline: {
        borderWidth: '2px',
        borderColor: `${theme.palette.background.paper} !important`,
        boxShadow: `0 2px 2px 0 ${HexToRGBA('#000000', 1)}`,
    },
    clearButton: {
        zIndex: 10,
    },
});

const baseCriteria = (field: string): NumberCriterion => ({
    type: CriteriaType.NumberCriterion,
    field: field,
});

const NumberFilter = (props: {
    fieldID: string;
    onChange?: (props: any) => void;
    filterConfig?: any;
    initValue?: any;
    value?: any;
    classes: any;
}): ReactElement => {
    const { classes, filterConfig } = props;

    const [lessThan, setLessThan] = useState<number | undefined>();
    const [moreThan, setMoreThan] = useState<number | undefined>();

    const update = useCallback(
        _.debounce((m: number | undefined, l: number | undefined) => {
            if (m && l) {
                props.onChange &&
                    props.onChange({
                        ...baseCriteria(props.fieldID),
                        moreThan: { amount: Number(m) },
                        lessThan: { amount: Number(l) },
                    });
            } else if (m && !l) {
                props.onChange &&
                    props.onChange({
                        ...baseCriteria(props.fieldID),
                        moreThan: { amount: Number(m) },
                        lessThan: { ignore: true },
                    });
            } else if (!m && l) {
                props.onChange &&
                    props.onChange({
                        ...baseCriteria(props.fieldID),
                        moreThan: { ignore: true },
                        lessThan: { amount: Number(l) },
                    });
            } else {
                props.onChange && props.onChange(undefined);
            }
        }, 500),
        [props.onChange],
    );

    const onMoreThanChange = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const m = Number((event.target as HTMLInputElement | HTMLTextAreaElement).value);
        if (m !== moreThan) {
            update(m, lessThan);
            setMoreThan(m);
        }
    };
    const onLessThanChange = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const l = Number((event.target as HTMLInputElement | HTMLTextAreaElement).value);
        if (l !== lessThan) {
            update(moreThan, l);
            setLessThan(l);
        }
    };

    return (
        <div
            style={{
                height: '100%',
                display: 'grid',
                alignItems: 'center',
                gridTemplateColumns: 'auto',
                gridTemplateRows: 'auto auto',
                minWidth: filterConfig?.width ? `${filterConfig.width}px` : '175px',
                width: filterConfig?.width ? `${filterConfig.width}px` : '175px',
            }}
        >
            <div>
                <TextField
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline,
                            input: classes.input,
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title="Clear">
                                    <IconButton
                                        size={'small'}
                                        className={classes.clearButton}
                                        onClick={() => {
                                            setMoreThan(undefined);
                                            update(undefined, lessThan);
                                        }}
                                    >
                                        <Clear fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                    margin={'dense'}
                    onChange={onMoreThanChange}
                    placeholder={'Min'}
                    value={moreThan || ''}
                    variant={'outlined'}
                    type={'number'}
                />
            </div>
            <div>
                <TextField
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline,
                            input: classes.input,
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title="Clear">
                                    <IconButton
                                        size={'small'}
                                        className={classes.clearButton}
                                        onClick={() => {
                                            setLessThan(undefined);
                                            update(moreThan, undefined);
                                        }}
                                    >
                                        <Clear fontSize={'small'} />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                    margin={'dense'}
                    onChange={onLessThanChange}
                    placeholder={'Max'}
                    value={lessThan || ''}
                    variant={'outlined'}
                    type={'number'}
                />
            </div>
        </div>
    );
};

export const StyledNumber = withStyles(styles)(NumberFilter);
