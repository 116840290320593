import React, { useContext } from 'react';
import { CardContent, Checkbox, Grid, makeStyles } from '@material-ui/core';
import { HexToRGBA } from 'utils/';
import Card from '@material-ui/core/Card';
import { Subscription, SubscriptionType, User } from 'popcorn-js/user';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Role } from 'popcorn-js/security/role';
import { CustomTheme } from 'theme/custom';
import { AppContext, AppContextT } from 'context';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { PartyType } from 'popcorn-js/party';

interface UserDetailsProps {
    handleChange?: (user: User) => void;
    roles?: Role[];
    user?: User;
    disabled?: boolean;
    partyCode?: string;
}

export const UserDetail: React.FC<UserDetailsProps> = (props: UserDetailsProps) => {
    const classes = useStyles();
    const { user, disabled, handleChange, roles, partyCode } = props;

    const appContext = useContext<AppContextT>(AppContext);

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSelectRole((event.target as HTMLInputElement).value);
    };

    const handleSubscriptionChange = (subscription: SubscriptionType, checked: boolean) => {
        let newSubs: Subscription[] = [];
        if (checked) {
            if (user && user.subscriptions) {
                newSubs = [...user.subscriptions];
            }
            newSubs.push({ type: subscription, partyCode: partyCode, enableForTestingEnvs: false });
        } else {
            for (const sub of user?.subscriptions || []) {
                if (sub.type !== subscription) {
                    newSubs.push({
                        type: sub.type,
                        partyCode: sub.partyCode,
                        enableForTestingEnvs: sub.enableForTestingEnvs,
                    });
                }
            }
        }
        if (user) {
            handleChange &&
                handleChange({
                    ...user,
                    subscriptions: newSubs,
                } as User);
        }
    };

    const InputProps = {
        classes: {
            underline: classes.inputUnderline,
            input: classes.input,
        },
    };

    const InputLabelProps = {
        shrink: Boolean(user && user.loginName),
        classes: {
            root: classes.inputLabel,
            focused: classes.inputLabelFocused,
            disabled: classes.inputLabelDisabled,
        },
    };

    const onSelectRole = (roleName: string): void => {
        const role = roles?.find((role: Role) => role.name === roleName) || {};
        handleChange &&
            handleChange({
                ...user,
                roleId: role.id,
            } as User);
    };

    let roleNames = roles?.map((role: Role) => role.name || '') || ([] as string[]);
    // filter out context role always
    roleNames = roleNames.filter((r: string) => r !== 'context');

    const selectedRole = roles?.find((role: Role) => role.id === (user || {}).roleId);

    return (
        <Card elevation={0} className={classes.root}>
            {user && (
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <BaseTextField
                                id={`UserDetail/loginName`}
                                InputLabelProps={{
                                    ...InputLabelProps,
                                    shrink: Boolean(user.loginName),
                                }}
                                InputProps={InputProps}
                                className={classes.textField}
                                disabled={disabled}
                                fullWidth
                                label={'User Name'}
                                margin={'dense'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                    handleChange &&
                                        handleChange({
                                            ...user,
                                            loginName: e.target.value,
                                        });
                                }}
                                value={user.loginName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BaseTextField
                                id={`UserDetail/firstName`}
                                InputLabelProps={{
                                    ...InputLabelProps,
                                    shrink: Boolean(user.firstName),
                                }}
                                InputProps={InputProps}
                                className={classes.textField}
                                disabled={disabled}
                                fullWidth
                                label={'First Name'}
                                margin={'dense'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                    handleChange &&
                                        handleChange({
                                            ...user,
                                            firstName: e.target.value,
                                        });
                                }}
                                value={user.firstName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BaseTextField
                                id={`UserDetail/lastName`}
                                InputLabelProps={{
                                    ...InputLabelProps,
                                    shrink: Boolean(user.lastName),
                                }}
                                InputProps={InputProps}
                                className={classes.textField}
                                disabled={disabled}
                                fullWidth
                                label={'Last Name'}
                                margin={'dense'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                    handleChange &&
                                        handleChange({
                                            ...user,
                                            lastName: e.target.value,
                                        });
                                }}
                                value={user.lastName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <BaseTextField
                                id={`UserDetail/emailAddress`}
                                InputLabelProps={{
                                    ...InputLabelProps,
                                    shrink: Boolean(user.emailAddress),
                                }}
                                InputProps={InputProps}
                                className={classes.textField}
                                disabled={disabled}
                                fullWidth
                                label={'Email Address'}
                                margin={'dense'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                    handleChange &&
                                        handleChange({
                                            ...user,
                                            emailAddress: e.target.value,
                                        });
                                }}
                                value={user.emailAddress}
                            />
                        </Grid>
                        <Grid item className={classes.GridStyle} xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Role</FormLabel>
                                <RadioGroup
                                    aria-label="role"
                                    name="role"
                                    value={selectedRole?.name || ''}
                                    onChange={handleRoleChange}
                                >
                                    {roleNames
                                        ?.filter(
                                            (roleName) =>
                                                !(
                                                    (appContext.currentContext?.partyType as PartyType) ===
                                                        PartyType.CLIENT &&
                                                    ['admin', 'context'].includes(roleName || '')
                                                ),
                                        )
                                        .map((roleName?: string) => (
                                            <FormControlLabel
                                                key={`role-select-${roleName}`}
                                                value={roleName}
                                                control={<Radio />}
                                                label={roleName}
                                            />
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.GridStyle} xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Subscriptions</FormLabel>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(
                                                user.subscriptions?.find(
                                                    (s: Subscription) => s.type === SubscriptionType.DailyRates,
                                                ),
                                            )}
                                            inputProps={{ 'aria-labelledby': SubscriptionType.DailyRates }}
                                            onChange={(): void => {
                                                handleSubscriptionChange(
                                                    SubscriptionType.DailyRates,
                                                    !Boolean(
                                                        user.subscriptions?.find(
                                                            (s: Subscription) => s.type === SubscriptionType.DailyRates,
                                                        ),
                                                    ),
                                                );
                                            }}
                                            value="DailyRates"
                                        />
                                    }
                                    label="Daily Rates (PDF)"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(
                                                user.subscriptions?.find(
                                                    (s: Subscription) => s.type === SubscriptionType.DailyRatesExcel,
                                                ),
                                            )}
                                            inputProps={{ 'aria-labelledby': SubscriptionType.DailyRatesExcel }}
                                            onChange={(): void => {
                                                handleSubscriptionChange(
                                                    SubscriptionType.DailyRatesExcel,
                                                    !Boolean(
                                                        user.subscriptions?.find(
                                                            (s: Subscription) =>
                                                                s.type === SubscriptionType.DailyRatesExcel,
                                                        ),
                                                    ),
                                                );
                                            }}
                                            value="DailyRatesExcel"
                                        />
                                    }
                                    label="Daily Rates (Excel)"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(
                                                user.subscriptions?.find(
                                                    (s: Subscription) =>
                                                        s.type === SubscriptionType.DailyRatesExcelSnapshot,
                                                ),
                                            )}
                                            inputProps={{ 'aria-labelledby': SubscriptionType.DailyRatesExcelSnapshot }}
                                            onChange={(): void => {
                                                handleSubscriptionChange(
                                                    SubscriptionType.DailyRatesExcelSnapshot,
                                                    !Boolean(
                                                        user.subscriptions?.find(
                                                            (s: Subscription) =>
                                                                s.type === SubscriptionType.DailyRatesExcelSnapshot,
                                                        ),
                                                    ),
                                                );
                                            }}
                                            value="DailyRatesExcelSnapshot"
                                        />
                                    }
                                    label="Daily Rates Snapshot (Excel)"
                                />
                                {partyCode !== 'SYS' && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(
                                                    user.subscriptions?.find(
                                                        (s: Subscription) => s.type === SubscriptionType.SISubmitted,
                                                    ),
                                                )}
                                                inputProps={{ 'aria-labelledby': SubscriptionType.SISubmitted }}
                                                onChange={(): void => {
                                                    handleSubscriptionChange(
                                                        SubscriptionType.SISubmitted,
                                                        !Boolean(
                                                            user.subscriptions?.find(
                                                                (s: Subscription) =>
                                                                    s.type === SubscriptionType.SISubmitted,
                                                            ),
                                                        ),
                                                    );
                                                }}
                                                value="SISubmitted"
                                            />
                                        }
                                        label="SI Submitted"
                                    />
                                )}
                                {partyCode !== 'SYS' && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(
                                                    user.subscriptions?.find(
                                                        (s: Subscription) => s.type === SubscriptionType.SICompleted,
                                                    ),
                                                )}
                                                inputProps={{ 'aria-labelledby': SubscriptionType.SICompleted }}
                                                onChange={(): void => {
                                                    handleSubscriptionChange(
                                                        SubscriptionType.SICompleted,
                                                        !Boolean(
                                                            user.subscriptions?.find(
                                                                (s: Subscription) =>
                                                                    s.type === SubscriptionType.SICompleted,
                                                            ),
                                                        ),
                                                    );
                                                }}
                                                value="SICompleted"
                                            />
                                        }
                                        label="SI Completed"
                                    />
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
            )}
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'auto',
        maxWidth: '700px',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    cardHeaderRoot: {
        backgroundColor: HexToRGBA(theme.palette.text.primary, 0.1),
        padding: theme.spacing(1),
    },
    inputLabel: {
        '&$inputLabelFocused': {
            color: theme.palette.text.secondary,
        },
        '&$inputLabelDisabled': {
            color: theme.palette.text.secondary,
        },
    },
    inputLabelFocused: {},
    inputLabelDisabled: {},

    inputUnderline: {
        '&&:hover:before': {
            borderBottomColor: theme.palette.text.primary,
        },
        '&:before': {
            borderBottomColor: theme.palette.text.secondary,
        },
        '&:after': {
            borderBottomColor: theme.palette.action.active,
        },
    },
    input: {
        color: HexToRGBA(theme.palette.text.primary, 0.8),
    },
    textField: {},
    GridStyle: { maxHeight: '50%', paddingTop: '16px' },
}));
