import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { FindRequest } from 'popcorn-js/index';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { Criteria } from 'popcorn-js/search';
import { Identifier, marshalIdentifier } from 'popcorn-js/search/identifier';
import { restRPC } from 'utils/restrpc';

export type DownloadListRequest = FindRequest;
export type DownloadListResponse = {
    data?: string;
};

export type DownloadConfirmationRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};

export type DownloadPDFConfirmationRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};

export type DownloadConfirmationResponse = {
    data?: string;
};

export type DownloadPDFConfirmationResponse = {
    data?: string;
};

export const Downloader = {
    ServiceProviderName: 'SettlementInstruction-Downloader',
    DownloadList(request: DownloadListRequest): Promise<DownloadListResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<DownloadListRequest, DownloadListResponse>({
            url: config.get('apiURL'),
            method: `${Downloader.ServiceProviderName}.DownloadList`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },

    DownloadConfirmation(request: DownloadConfirmationRequest): Promise<DownloadConfirmationResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DownloadConfirmationRequest, DownloadConfirmationResponse>({
            url: config.get('apiURL'),
            method: `${Downloader.ServiceProviderName}.DownloadConfirmation`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },

    DownloadPDFConfirmation(request: DownloadPDFConfirmationRequest): Promise<DownloadPDFConfirmationResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<DownloadPDFConfirmationRequest, DownloadPDFConfirmationResponse>({
            method: `${Downloader.ServiceProviderName}.DownloadPDFConfirmation`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
};
