const successColor = '#4caf50';

const tooltip = {
    padding: '10px 15px',
    minWidth: '130px',
    color: '#FFFFFF',
    lineHeight: '1.7em',
    background: 'rgba(85,85,85,0.9)',
    border: 'none',
    borderRadius: '3px',
    opacity: '1!important',
    boxShadow:
        '0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
    maxWidth: '200px',
    textAlign: 'center',
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    textShadow: 'none',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'normal',
    lineBreak: 'auto',
};

const hoverCardStyle = {
    cardHover: {
        '&:hover': {
            '& $cardHeaderHover': {
                transform: 'translate3d(0, -50px, 0)',
            },
        },
    },
    cardHeaderHover: {
        transition: 'all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)',
    },
    cardHoverUnder: {
        position: 'absolute',
        zIndex: '1',
        top: '-50px',
        width: 'calc(100% - 30px)',
        left: '17px',
        right: '17px',
        textAlign: 'center',
    },
};

const title = {
    color: '#3C4858',
    textDecoration: 'none',
    fontWeight: '300',
    marginTop: '30px',
    marginBottom: '25px',
    minHeight: '32px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    '& small': {
        color: '#777',
        fontSize: 14,
        fontWeight: '400',
        lineHeight: '1',
    },
};

export const cardTitle = {
    ...title,
    marginTop: '0',
    marginBottom: '3px',
    minHeight: 'auto',
    '& a': {
        ...title,
        marginTop: '.625rem',
        marginBottom: '0.75rem',
        minHeight: 'auto',
    },
};

const styles = {
    ...hoverCardStyle,
    tooltip,
    cardTitle: {
        ...cardTitle,
        marginTop: '0px',
        marginBottom: '3px',
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
    cardProductTitle: {
        ...cardTitle,
        marginTop: '0px',
        marginBottom: '3px',
        textAlign: 'center',
    },
    cardCategory: {
        color: '#999999',
        fontSize: 14,
        paddingTop: '10px',
        marginBottom: '0',
        marginTop: '0',
        margin: '0',
    },
    cardProductDescription: {
        textAlign: 'center',
        color: '#999999',
    },
    stats: {
        color: '#999999',
        fontSize: 12,
        lineHeight: '22px',
        display: 'inline-flex',
        '& svg': {
            position: 'relative',
            top: '4px',
            width: '16px',
            height: '16px',
            marginRight: '3px',
        },
        '& i': {
            position: 'relative',
            top: '4px',
            fontSize: 16,
            marginRight: '3px',
        },
    },
    productStats: {
        paddingTop: '7px',
        paddingBottom: '7px',
        margin: '0',
    },
    successText: {
        color: successColor,
    },
    upArrowCardCategory: {
        width: 14,
        height: 14,
    },
    underChartIcons: {
        width: '17px',
        height: '17px',
    },
    price: {
        color: 'inherit',
        '& h4': {
            marginBottom: '0px',
            marginTop: '0px',
        },
    },
    masterRow: {
        backgroundColor: '#b7ffb1',
    },
    offlineRow: {
        backgroundColor: '#ff9c9b',
    },
    normalRow: {
        backgroundColor: '#ffe4ab',
    },
};

export default styles;
