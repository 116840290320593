import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

const LightCheckboxBase = withStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        width: '14px',
        height: '14px',
        padding: theme.spacing(2),
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    checked: {},
}))(Checkbox);

export const BaseCheckBox = (props: CheckboxProps): ReactElement => <LightCheckboxBase {...props} />;
