import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { Client } from 'popcorn-js/party';

export const Products = (props: {
    client: Client;
    readOnly?: boolean;
    invalidFields?: Record<string, string>;
    inputProps?: {
        classes: {
            underline: string;
        };
        disableUnderline: boolean;
        readonly: boolean;
    };
    handleFieldChange: (newValue: unknown, index: number) => void;
    handleAdd?: () => void;
    handleRemove: (index: number) => void;
}): ReactElement => {
    const { handleFieldChange, client, handleAdd, handleRemove, inputProps, readOnly } = props;
    const classes = useStyles();

    return (
        <div className={classes.products}>
            <div className={classes.productsHeader}> Client Products </div>
            <div className={classes.productsSelectCard}>
                <div className={classes.container}>
                    {(client?.products || []).map((product, i) => (
                        <div key={i}>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputWrapper}>
                                    <BaseTextField
                                        id={`product/Name`}
                                        disabled={readOnly}
                                        inputProps={inputProps}
                                        fullWidth
                                        label="Product"
                                        onChange={(e) => handleFieldChange(e.target.value, i)}
                                        value={product || ''}
                                    />
                                </div>
                                <BaseButton
                                    id={'CashFlowButton/View'}
                                    variant={VARIANT.CONTAINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    onClick={() => handleRemove(i)}
                                    text={'Remove'}
                                />
                            </div>
                        </div>
                    ))}
                    <div style={{ marginLeft: '20px' }}>
                        <BaseButton
                            id={'CashFlowButton/View'}
                            variant={VARIANT.CONTAINED}
                            color={COLOR.ACTION}
                            size={SIZE.MEDIUM}
                            onClick={handleAdd} // show product inputs
                            text={'Add'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    productsSelectCard: {
        flexGrow: 1,
        overflowY: 'auto',
    },
    productsHeader: {
        margin: '16px 0px 16px 26px ',
        fontSize: '18px',
    },
    container: {
        width: '910px',
        marginLeft: '20px',
        height: '100%',
    },
    inputWrapper: {
        width: '750px',
        height: '60px',
        borderRadius: '6px',
        marginBottom: theme.spacing(3),
        marginTop: '10px',
        marginRight: '30px',
    },
    products: {
        height: '100%',
        width: '945px',
    },
    productContainer: {
        width: '945px',
        height: '60px',
        borderRadius: '6px',
        marginBottom: theme.spacing(3),
        // backgroundColor: theme.palette.custom.paperExtended.paper2,
        padding: theme.spacing(1),
        background: 'green',
    },
    inputContainer: {
        display: 'flex',
        margin: '3px 0px 12px 10px',
        width: '950px',
        alignItems: 'center',
    },
    inputField: {
        width: '240px',
        height: '40px',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        padding: '0px 0px 15px 20px',
        marginRight: theme.spacing(3),
        borderRadius: '6px',
    },
}));
