/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import { CriteriaType, DateCriterion } from 'popcorn-js/search';
import { HexToRGBA } from 'utils';
import { CustomTheme } from 'theme/custom';
import { getUnixTime, parseISO, isValid } from 'date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';

const styles = (theme: CustomTheme) => ({
    input: {
        color: theme.palette.text.primary,
        fontSize: 12,
    },
    notchedOutline: {
        borderWidth: '2px',
        borderColor: `${theme.palette.custom.paperExtended.paper2} !important`,
        boxShadow: `0 2px 2px 0 ${HexToRGBA('#000000', 1)}`,
    },
});

const DateFilter = (props: {
    fieldID: string;
    onChange?: (props: DateCriterion | undefined) => void;
    filterConfig?: any;
    initValue?: DateCriterion;
    showBorder?: boolean;
    classes: any;
}): ReactElement => {
    const { filterConfig, classes } = props;

    const [startDate, setStartDate] = useState<string | undefined | null>(null);
    const [endDate, setEndDate] = useState<string | undefined | null>(null);

    useEffect(() => {
        if (startDate && endDate) {
            const newValue: DateCriterion = {
                type: CriteriaType.DateCriterion,
                field: props.fieldID,
                startDate: {
                    date: getUnixTime(parseISO(startDate.replace('00:00:00.000', '20:59:59.999'))),
                    inclusive: true,
                },
                endDate: {
                    date: getUnixTime(parseISO(endDate.replace('00:00:00.000', '20:59:59.999'))),
                    inclusive: true,
                },
            };
            props.onChange && props.onChange(newValue);
        } else if (startDate && !endDate) {
            const newValue: DateCriterion = {
                type: CriteriaType.DateCriterion,
                field: props.fieldID,
                startDate: {
                    date: getUnixTime(parseISO(startDate.replace('00:00:00.000', '20:59:59.999'))),
                    inclusive: true,
                },
                endDate: { ignore: true },
            };
            props.onChange && props.onChange(newValue);
        } else if (!startDate && endDate) {
            const newValue: DateCriterion = {
                type: CriteriaType.DateCriterion,
                field: props.fieldID,
                startDate: { ignore: true },
                endDate: {
                    date: getUnixTime(parseISO(endDate.replace('00:00:00.000', '20:59:59.999'))),
                    inclusive: true,
                },
            };
            props.onChange && props.onChange(newValue);
        } else {
            props.onChange && props.onChange(undefined);
        }
    }, [startDate, endDate]);

    const onStartDateChange = (date: MaterialUiPickersDate, value: string | null | undefined) => {
        if (value && isValid(parseISO(value))) {
            setStartDate(date?.toISOString());
        } else {
            setStartDate(undefined);
        }
    };

    const onEndDateChange = (date: MaterialUiPickersDate, value: string | null | undefined) => {
        if (value && isValid(parseISO(value))) {
            setEndDate(date?.toISOString());
        } else {
            setEndDate(undefined);
        }
    };

    const addInputProps = { style: {} };
    if (filterConfig) {
        if (filterConfig.width) {
            addInputProps.style = {
                width: filterConfig.width,
            };
        } else {
            addInputProps.style = {
                width: 175,
            };
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div
                style={{
                    height: '100%',
                    display: 'grid',
                    alignItems: 'center',
                    gridTemplateColumns: 'auto',
                    gridTemplateRows: 'auto auto',
                    minWidth: filterConfig?.width ? `${filterConfig.width}px` : '175px',
                    width: filterConfig?.width ? `${filterConfig.width}px` : '175px',
                }}
            >
                <div>
                    <KeyboardDatePicker
                        // Getting console error when passing a boolean
                        // eslint-disable-next-line
                        // @ts-ignore
                        // clearable={'true'}
                        // clearable
                        autoOk
                        disableToolbar
                        maskChar={'_'}
                        variant={'inline'}
                        margin={'none'}
                        id={'startDate'}
                        value={startDate}
                        placeholder={'yyyy-MM-dd'}
                        initialFocusedDate={new Date()}
                        onChange={onStartDateChange}
                        format={'yyyy-MM-dd'}
                        KeyboardButtonProps={{
                            size: 'small',
                        }}
                        TextFieldComponent={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        ...params.InputProps?.classes,
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline,
                                    },
                                }}
                                margin={'dense'}
                                variant={'outlined'}
                            />
                        )}
                    />
                </div>
                <div>
                    <KeyboardDatePicker
                        // Getting console error when passing a boolean
                        // clearable
                        autoOk
                        disableToolbar
                        maskChar={'_'}
                        variant={'inline'}
                        margin={'none'}
                        value={endDate}
                        placeholder={'yyyy-MM-dd'}
                        initialFocusedDate={new Date()}
                        onChange={onEndDateChange}
                        format={'yyyy-MM-dd'}
                        KeyboardButtonProps={{
                            size: 'small',
                        }}
                        TextFieldComponent={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        ...params.InputProps?.classes,
                                        input: classes.input,
                                        notchedOutline: classes.notchedOutline,
                                    },
                                }}
                                margin={'dense'}
                                variant={'outlined'}
                            />
                        )}
                    />
                </div>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export const StyledDate = withStyles(styles as any)(DateFilter);
