import React, { ReactElement } from 'react';
import { Dialog } from '@material-ui/core';
import { BaseAppBar } from '../BaseAppBar/BaseAppBar';
import { makeStyles } from '@material-ui/styles';
import OrderDetail from './OrderDetailV2';
import LinkedInvoices from './LinkedInvoices';
import { CustomTheme } from 'theme/custom';
import { Counterparty } from 'popcorn-js/counterparty';
import { Order } from 'popcorn-js/order';

const OrderDetailDialog = (props: {
    counterparties: Counterparty[];
    onClose: () => void;
    onSaveSuccess: () => void;
    order: Order;
    readOnly?: boolean;
    show: boolean;
}): ReactElement => {
    const { show, onClose, counterparties, onSaveSuccess, order } = props;
    const classes = useStyles();

    return (
        <Dialog onClose={onClose} fullScreen open={show}>
            <BaseAppBar title={'Manage Order Details'} onClose={onClose} showCloseButton />
            <div className={classes.orderDetailsWrapper}>
                <div className={classes.orderInformationWrapper}>
                    <OrderDetail counterparties={counterparties} onSaveSuccess={onSaveSuccess} order={order} readOnly />
                </div>
                <div className={classes.linkedInvoicesWrapper}>
                    <LinkedInvoices counterparties={counterparties} onSaveSuccess={onSaveSuccess} order={order} show />
                </div>
            </div>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    orderDetailsWrapper: {
        backgroundColor: theme.palette.primary.contrastText,
        height: '100%',
        paddingTop: 35,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    orderInformationWrapper: {
        backgroundColor: theme.palette.primary.contrastText,
        height: '763px',
        width: '508px',
        paddingRight: '8px',
        display: 'grid',
    },
    linkedInvoicesWrapper: {
        backgroundColor: theme.palette.background.paper,
        height: '763px',
        width: '771.41px',
        padding: 0,
        display: 'grid',
    },
}));

export default OrderDetailDialog;
