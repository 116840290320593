import React, { useContext, useEffect, useState, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import Table from 'components/Table/Table';
import { CriteriaType } from 'popcorn-js/search';
import { useServiceSync } from 'hooks/useService';
import { AppContext, AppContextT } from 'context';
import {
    FindSubsidiariesRequest,
    FindSubsidiariesResponse,
    Handler as ClientHandler,
} from 'popcorn-js/party/client/handler';
import { Client } from 'popcorn-js/party';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';

export const ReportingEntityDashboard = (): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [subsidiaries, setSubsidiaries] = React.useState<Client[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const [find] = useServiceSync<FindSubsidiariesRequest, FindSubsidiariesResponse>(ClientHandler.findSubsidiaries);

    useEffect(() => {
        find({
            holdingClientPartyCode: appContext.party?.partyCode || '',
            subsidiaries: (appContext.party as Client)?.subsidiaries,
        })
            .then((response) => setSubsidiaries(response.subsidiaries))
            .catch(setErrorMessage);
    }, []);

    return (
        <div className={classes.root}>
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            id: 'ReportingEntityDashboard/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Reporting Entity Subsidiaries',
                        },
                    ],
                }}
            >
                <Table
                    columns={[
                        {
                            title: 'Client Name',
                            filter: { type: CriteriaType.TextCriterion },
                            render: (rowData: Client) => rowData.name,
                        },
                        {
                            title: 'Party Code',
                            filter: { type: CriteriaType.TextCriterion },
                            render: (rowData: Client) => rowData.partyCode,
                        },
                    ]}
                    data={subsidiaries || []}
                    defaultColConfig={[
                        { header: 'Client Name', visible: true },
                        { header: 'Party Code', visible: true },
                    ]}
                    disableFooter
                />
            </StandardCard>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={() => setErrorMessage(undefined)}
                autoFormat={false}
            />
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        width: '1160px',
    },
    buttonContainer: {
        marginTop: '8px',
    },
}));
