/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { ItemWrapper } from '../audit/IOWrapper';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { displayAmount, processUnixDateForViewing } from 'utils';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { InvalidReason } from 'popcorn-js/order/recordkeeper';
import { Order } from 'popcorn-js/order';
import { showIcon } from './OrderDetailV2';

export const AmountDetail = (props: {
    invalidFields: InvalidReason[];
    readOnly: boolean;
    editOrder: (field: string, newValue: any) => void;
    order: Order;
    inputProps: {
        classes: {
            underline: string;
        };
        disableUnderline: boolean;
        readOnly: boolean;
    };
}): ReactElement => {
    const { invalidFields, readOnly, editOrder, order, inputProps } = props;
    const classes = useStyles();
    const currencySymbol = order?.currency || '';
    const overInvoiced =
        order.invoicedAmount && order.originalAmountDue && order.invoicedAmount > order.originalAmountDue;

    return (
        <div className={classes.orderAmountCardWrapper}>
            <ItemWrapper validationErrors={invalidFields} field={'originalAmountDue'} label={'Original Amount Due'}>
                <BaseNumberField
                    disabled={readOnly}
                    InputProps={inputProps}
                    overridePrefixColor
                    prefix={currencySymbol}
                    id="OrderDetail/originalAmountDue"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        editOrder('originalAmountDue', Number(e.target.value))
                    }
                    value={displayAmount(order?.originalAmountDue)}
                />
            </ItemWrapper>
            <ItemWrapper validationErrors={invalidFields} field={'dueDate'} label={'Due Date'}>
                <div style={{ width: '125px' }}>
                    <LightDatePicker
                        disabled={readOnly}
                        format={'yyyy/MM/dd'}
                        keyboardIcon={showIcon(readOnly)}
                        InputProps={{
                            disableUnderline: readOnly,
                        }}
                        id="dueDate"
                        onChange={(date: MaterialUiPickersDate, value: string | null | undefined) =>
                            editOrder('dueDate', moment.utc(value).unix())
                        }
                        style={{ width: '100%' }}
                        value={processUnixDateForViewing(order?.dueDate)}
                    />
                </div>
            </ItemWrapper>
            <ItemWrapper validationErrors={invalidFields} field={'costingRate'} label={'Costing Rate'}>
                <BaseNumberField
                    disabled={readOnly}
                    InputProps={inputProps}
                    id="OrderDetail/costingRate"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        editOrder('costingRate', Number(e.target.value))
                    }
                    value={order?.costingRate?.toFixed(4)}
                />
            </ItemWrapper>
            <ItemWrapper validationErrors={invalidFields} field={'captureRate'} label={'Capture Rate'}>
                <BaseNumberField
                    disabled
                    InputProps={inputProps}
                    id="OrderDetail/captureRate"
                    value={order?.captureRate?.toFixed(4)}
                />
            </ItemWrapper>
            <div className={classes.specialItemWrapper}>
                <ItemWrapper validationErrors={invalidFields} field={'invoicedAmount'} label={'Invoiced Amount'}>
                    <BaseNumberField
                        disabled
                        overridePrefixColor
                        prefix={currencySymbol}
                        id="OrderDetail/invoicedAmount"
                        InputProps={(() => {
                            return {
                                ...inputProps,
                            };
                        })()}
                        value={displayAmount(order?.invoicedAmount)}
                    />
                </ItemWrapper>
            </div>
            <ItemWrapper validationErrors={invalidFields} field={'exposureBalance'} label={'Exposure Balance'}>
                <BaseNumberField
                    prefix={currencySymbol}
                    overridePrefixColor
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        className: classNames({
                            [classes.formFieldRed]: overInvoiced,
                            [classes.formFieldGreen]: !overInvoiced,
                        }),
                    }}
                    id="OrderDetail/exposureBalance"
                    value={displayAmount(order?.exposureBalance)}
                    readOnly
                />
            </ItemWrapper>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    orderAmountCardWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        height: '595px',
        width: '197px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7.5px',
    },
    specialItemWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        height: 'fit-content',
        paddingBottom: '18px',
    },
    formFieldGreen: {
        color: theme.palette.success.light,
    },
    formFieldRed: {
        color: theme.palette.custom.data.overdue.main,
    },
}));
