import React, { Fragment, ReactElement } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from 'components/tradeV2/ticket/styledComponents';

export const StandardAlert = ({
    successMessage,
    errorMessage,
    onClose,
    autoHideDuration,
}: {
    successMessage?: string;
    errorMessage?: string;
    onClose: () => void;
    autoHideDuration?: number;
}): ReactElement => {
    return (
        <Snackbar
            autoHideDuration={autoHideDuration || 3000}
            onClose={onClose}
            open={!!successMessage || !!errorMessage}
        >
            <Fragment>
                {!!successMessage && (
                    <Alert onClose={onClose} severity={'success'}>
                        {successMessage}
                    </Alert>
                )}
                {!!errorMessage && (
                    <Alert onClose={() => onClose()} severity={'error'}>
                        {errorMessage}
                    </Alert>
                )}
            </Fragment>
        </Snackbar>
    );
};
