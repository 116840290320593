import React, { ReactElement, MouseEvent } from 'react';
import { IconButton, Tooltip, CircularProgress, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import classNames from 'classnames';

export enum COLOR {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    WHITE = 'WHITE',
}

export const ActionButton = (props: {
    id: string;
    disabled?: boolean;
    loading?: boolean;
    icon: ReactElement;
    helpText?: string;
    onClick: (event: MouseEvent<HTMLElement>) => void;
    color?: COLOR;
}): ReactElement => {
    const classes = useStyles();

    return (
        <>
            {props.disabled && (
                <IconButton className={classes.iconButton} size={'small'} disabled id={props.id}>
                    {props.icon}
                </IconButton>
            )}
            {!props.disabled && (
                <Tooltip title={props.helpText || ''}>
                    <IconButton
                        // className={classes.iconButton}
                        className={classNames({
                            [classes.iconButton]: true,
                            [classes.primaryContrastColor]: props.color === COLOR.PRIMARY,
                            [classes.secondaryContrastColor]: props.color === COLOR.SECONDARY,
                            [classes.whiteContrastColor]: props.color === COLOR.WHITE,
                        })}
                        size={'small'}
                        disabled={props.loading}
                        id={props.id}
                        onClick={(e) => {
                            props.onClick(e);
                        }}
                    >
                        {props.icon}
                        {props.loading && (
                            <CircularProgress className={classes.progress} color={'secondary'} size={16} />
                        )}
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    progress: {
        position: 'fixed',
    },
    iconButton: {
        marginLeft: theme.spacing(1),
    },
    primaryContrastColor: {
        color: theme.palette.primary.contrastText,
    },
    secondaryContrastColor: {
        color: theme.palette.text.hint,
    },
    whiteContrastColor: {
        color: theme.palette.text.primary,
    },
}));
