import React from 'react';
import { HandlerI } from './tradeV2/handler';
import { DateKeeperI } from './fxCalendar/dateKeeper';
import { Handler as RatesHandler } from 'popcorn-js/rick/handler';
import { Recordkeeper as TradingDayExceptionRecordkeeper } from './tradingDayException/recordkeeper';
import { Handler as PaymentHandler } from './payment/handler';
import { Handler as DayEndPositionHandler } from './dayEndPosition/handler';
import { ConfirmationHandler } from './tradeV3/confirmationHandler';
import { Integrator as PaymentIntegrator } from './payment/integrator';
import { ContextSwitcher } from './party/contextSwitcher';
import { Handler } from './cfcDeposit/handler';
import { HandlerI as ContractHandler } from './producerContracts/handler';

export interface ServiceContextT {
    tradeV2Handler?: HandlerI;
    dateKeeper?: DateKeeperI;
    ratesHandler?: RatesHandler;
    tradingDayExceptionRecordkeeper?: TradingDayExceptionRecordkeeper;
    paymentHandler?: PaymentHandler;
    dayEndPositionHandler?: DayEndPositionHandler;
    paymentIntegrator?: PaymentIntegrator;
    tradeV3ConfirmationHandler: ConfirmationHandler;
    contextSwitcher?: ContextSwitcher;
    cfcDepositHandler: Handler;
    contractHandler: ContractHandler;
}

export const ServiceContext = React.createContext<ServiceContextT>({} as ServiceContextT);
