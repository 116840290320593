import { CustomPalette } from './custom';
import { Theme as BaseUITheme } from 'baseui/theme';

export type CustomBaseUIThemeT = BaseUITheme & CustomPalette;

import { createThemedStyled, createThemedWithStyle, createThemedUseStyletron } from 'baseui';

export const themedStyled = createThemedStyled<CustomBaseUIThemeT>();
export const themedWithStyle = createThemedWithStyle<CustomBaseUIThemeT>();
export const themedUseStyletron = createThemedUseStyletron<CustomBaseUIThemeT>();
