/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, ChangeEvent, ReactElement, MouseEvent, FunctionComponent, ReactNode } from 'react';
import {
    InputBase,
    TextField,
    withStyles,
    Select,
    InputLabel,
    IconButton,
    TextFieldProps,
    InputBaseComponentProps,
    SelectProps,
    InputLabelProps,
    useTheme,
    Radio,
    RadioProps,
    FormControlLabelProps,
    FormControlLabel,
    Checkbox,
    CheckboxProps,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Cancel } from '@material-ui/icons';
import { AlertProps } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CustomTheme } from 'theme/custom';

// TODO these components to be merged with and replaced with the base components

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat<string> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: string | undefined;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export const NumberFormatCustom = (props: NumberFormatCustomProps): ReactElement => {
    const { inputRef, onChange, value, ...other } = props;

    return (
        <NumberFormat
            {...other}
            allowEmptyFormatting={false}
            allowLeadingZeros={false}
            defaultValue={''}
            getInputRef={inputRef}
            onValueChange={(values) => {
                if (isNaN(Number(values.value))) {
                    onChange({
                        target: {
                            name: props.name,
                            value: '',
                        },
                    });
                } else {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }
            }}
            thousandSeparator
            value={value}
        />
    );
};

export const DarkTextFieldBase = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.contrastText,
        borderBottomColor: `${theme.palette.primary.contrastText} !important`,
        '& .MuiInput-underline': {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: `${theme.palette.primary.contrastText} !important`,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: `${theme.palette.primary.contrastText} !important`,
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: `${theme.palette.primary.contrastText} !important`,
        },
        '& .Mui-disabled': {
            color: theme.palette.text.primary,
            opacity: 0.75,
        },
        '& .Mui-disabled:before': {
            borderBottomColor: `${theme.palette.primary.contrastText} !important`,
            borderBottomStyle: 'solid',
        },
        '& .Mui-error': {
            color: `${theme.palette.error.dark} !important`,
        },
        '& .Mui-error:before': {
            borderBottomColor: `${theme.palette.error.dark} !important`,
        },
        '& .Mui-error:after': {
            borderBottomColor: `${theme.palette.error.dark} !important`,
        },
        '& .MuiInputLabel-shrink': {
            fontSize: '14px !important',
        },
    },
}))(TextField);

export const DarkTextField = (props: TextFieldProps): ReactElement => {
    const { disabled, onChange, label, value, InputLabelProps, InputProps, ...rest } = props;
    const theme = useTheme<CustomTheme>();
    const [focused, setFocused] = useState<boolean>(false);
    const [shrunk, setShrunk] = useState<boolean>(false);

    useEffect(() => {
        setShrunk(!!value || focused);
    }, [value, focused]);

    return (
        <DarkTextFieldBase
            InputLabelProps={{
                ...InputLabelProps,
                style: {
                    color: theme.palette.primary.contrastText,
                    marginLeft: shrunk ? '0px' : '34px',
                },
                shrink: shrunk,
            }}
            InputProps={{
                ...InputProps,
                endAdornment: !disabled ? (
                    <IconButton
                        tabIndex={-1}
                        onClick={(event: MouseEvent<HTMLElement>) => {
                            const newEvent = (event as unknown) as ChangeEvent<HTMLInputElement>;
                            if (onChange) {
                                onChange({ ...newEvent, target: { ...newEvent.target, value: '' } });
                            }
                        }}
                        size={'small'}
                    >
                        <Cancel
                            style={{
                                color: theme.palette.primary.contrastText,
                                opacity: 0.2,
                            }}
                        />
                    </IconButton>
                ) : (
                    ''
                ),
            }}
            disabled={disabled}
            inputProps={{
                style: {
                    color: theme.palette.primary.contrastText,
                },
                onFocus: () => setFocused(true),
                onBlur: () => setFocused(false),
            }}
            label={label}
            onChange={onChange}
            style={{
                color: theme.palette.primary.contrastText,
            }}
            value={value}
            {...rest}
        />
    );
};

export const DarkNumberField = (props: TextFieldProps & NumberFormatProps): ReactElement => {
    const { prefix, label, ...rest } = props;
    const theme = useTheme<CustomTheme>();
    return (
        <DarkTextField
            InputProps={{
                inputComponent: (NumberFormatCustom as unknown) as FunctionComponent<InputBaseComponentProps>,
                startAdornment: prefix ? (
                    <span
                        style={{
                            color: props.error ? theme.palette.error.dark : theme.palette.primary.contrastText,
                            opacity: props.disabled ? 0.75 : 1.0,
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            marginRight: '2px',
                            marginBottom: '2px',
                        }}
                    >
                        {prefix}
                    </span>
                ) : (
                    ''
                ),
            }}
            label={label}
            {...rest}
        />
    );
};

export const LightTextFieldBase = withStyles((theme) => ({
    root: {
        '& .MuiInput-underline': {
            borderBottomColor: theme.palette.text.primary,
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: theme.palette.text.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.text.primary,
        },
        '& .MuiInput-underline:hover': {
            borderBottomColor: theme.palette.text.primary,
        },
        '& .Mui-disabled': {
            color: theme.palette.text.primary,
            opacity: 0.75,
        },
        '& .Mui-disabled:before': {
            borderBottomColor: theme.palette.text.primary,
            borderBottomStyle: 'solid',
        },
        '& .Mui-error': {
            color: `${theme.palette.error.main} !important`,
        },
        '& .Mui-error:before': {
            borderBottomColor: `${theme.palette.error.main} !important`,
        },
        '& .Mui-error:after': {
            borderBottomColor: `${theme.palette.error.main} !important`,
        },
        '& .MuiInputLabel-shrink': {
            fontSize: '14px !important',
        },
    },
}))(TextField);

export const LightTextField = (props: TextFieldProps): ReactElement => {
    const { disabled, onChange, label, value, InputLabelProps, InputProps, ...rest } = props;
    const theme = useTheme<CustomTheme>();
    const [focused, setFocused] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);

    return (
        <LightTextFieldBase
            InputLabelProps={{
                ...InputLabelProps,
                style: {
                    color: theme.palette.text.primary,
                    fontSize: '12px',
                    fontWeight: 'bold',
                },
                shrink: !!value || focused,
            }}
            InputProps={{
                endAdornment:
                    !disabled && hover ? (
                        <IconButton
                            tabIndex={-1}
                            onClick={(event: MouseEvent<HTMLElement>) => {
                                const newEvent = (event as unknown) as ChangeEvent<HTMLInputElement>;
                                if (onChange) {
                                    onChange({ ...newEvent, target: { ...newEvent.target, value: '' } });
                                }
                            }}
                            size={'small'}
                        >
                            {<Cancel style={{ color: theme.palette.text.primary, opacity: 0.2 }} />}
                        </IconButton>
                    ) : (
                        ''
                    ),
                ...InputProps,
            }}
            disabled={disabled}
            inputProps={{
                style: {
                    color: theme.palette.text.primary,
                },
            }}
            label={label}
            margin={'none'}
            onChange={onChange}
            style={{
                color: theme.palette.text.primary,
            }}
            value={value}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            {...rest}
        />
    );
};

export const LightNumberField = (props: TextFieldProps & NumberFormatProps): ReactElement => {
    const { prefix, ...rest } = props;
    const theme = useTheme<CustomTheme>();
    return (
        <LightTextField
            InputProps={{
                ...props.InputProps,
                inputComponent: (NumberFormatCustom as unknown) as FunctionComponent<InputBaseComponentProps>,
                startAdornment: prefix ? (
                    <span
                        style={{
                            color: theme.palette.text.primary,
                            opacity: props.disabled ? 0.75 : 1.0,
                            fontSize: '16px',
                            fontFamily: 'Roboto',
                            marginRight: '2px',
                            marginBottom: '2px',
                        }}
                    >
                        {prefix}
                    </span>
                ) : (
                    ''
                ),
            }}
            {...rest}
        />
    );
};

export const LightDatePickerBase = withStyles((theme) => ({
    root: {
        height: 'auto',
        '& .MuiInput-underline:before': {
            borderBottomColor: theme.palette.text.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.text.primary,
        },
        '& .Mui-error': {
            color: `${theme.palette.error.main} !important`,
        },
        '& .Mui-error:before': {
            borderBottomColor: `${theme.palette.error.main} !important`,
        },
        '& .Mui-error:after': {
            borderBottomColor: `${theme.palette.error.main} !important`,
        },
        '& .Mui-disabled': {
            color: theme.palette.text.primary,
            opacity: 0.75,
        },
        '& .Mui-disabled:before': {
            borderBottomColor: theme.palette.text.primary,
            borderBottomStyle: 'solid',
        },
        '& .MuiInputLabel-shrink': {
            fontSize: '14px !important',
        },
    },
}))(KeyboardDatePicker);

export const LightDatePicker = (props: KeyboardDatePickerProps): ReactElement => {
    const theme = useTheme<CustomTheme>();
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <LightDatePickerBase
                InputLabelProps={{
                    style: {
                        color: theme.palette.text.primary,
                        fontSize: '12px',
                        fontWeight: 'bold',
                    },
                }}
                KeyboardButtonProps={{
                    size: 'small',
                    tabIndex: -1,
                    style: {
                        color: props.error ? theme.palette.error.main : theme.palette.text.primary,
                    },
                }}
                InputAdornmentProps={{
                    ...props.InputAdornmentProps,
                    style: { margin: 0 },
                }}
                autoOk
                disableToolbar
                format={'MM/dd/yyyy'}
                invalidDateMessage={'invalid date'}
                margin={'none'}
                variant={'inline'}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
};

export const LightBootstrapInput = withStyles((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        'label + &': {
            marginTop: theme.spacing(1.75),
        },
    },
    focused: {
        borderBottom: `2px solid ${theme.palette.text.primary}`,
    },
    error: {
        color: `${theme.palette.error.main} !important`,
        borderBottomColor: `${theme.palette.error.main} !important`,
        borderBottomWidth: `2px !important`,
    },
    disabled: {
        opacity: '0.75 !important',
    },
}))(InputBase);

const SelectIcon = (props: { error?: boolean }) => {
    const theme = useTheme<CustomTheme>();
    return <ExpandMoreIcon style={{ color: props.error ? theme.palette.error.main : theme.palette.text.primary }} />;
};

export const LightSelect = (props: SelectProps): ReactElement => {
    return (
        <div style={{ height: '48px' }}>
            <LightInputLabel disabled={props.disabled} error={props.error}>
                {props.label}
            </LightInputLabel>
            <Select
                IconComponent={() => <SelectIcon error={props.error} />}
                input={<LightBootstrapInput />}
                {...props}
            />
        </div>
    );
};

const DarkBootstrapInput = withStyles((theme) => ({
    root: {
        color: `${theme.palette.primary.contrastText} !important`,
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
        'label + &': {
            marginTop: theme.spacing(1.75),
        },
    },
    focused: {
        borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
    },
    error: {
        color: `${theme.palette.error.main} !important`,
        borderBottomColor: `${theme.palette.error.main} !important`,
        borderBottomWidth: `2px !important`,
    },
    disabled: {
        opacity: '0.75 !important',
    },
}))(InputBase);

const DarkSelectIcon = (props: { error?: boolean }) => {
    const theme = useTheme<CustomTheme>();
    return (
        <ExpandMoreIcon
            {...props}
            style={{ color: props.error ? theme.palette.error.main : theme.palette.primary.contrastText }}
        />
    );
};

export const DarkSelect = (props: SelectProps): ReactElement => {
    return (
        <div style={{ height: '32px' }}>
            {props.label && (
                <LightInputLabel disabled={props.disabled} error={props.error}>
                    {props.label}
                </LightInputLabel>
            )}{' '}
            <Select
                IconComponent={() => <DarkSelectIcon error={props.error} />}
                input={<DarkBootstrapInput />}
                {...props}
            />
        </div>
    );
};

const InputLabelBase = withStyles((theme) => ({
    root: {
        color: `${theme.palette.text.primary} !important`,
        fontSize: '12px',
        fontWeight: 'bold',
    },
    shrink: {
        color: `${theme.palette.text.primary} !important`,
        fontSize: '14px',
    },
    error: {
        color: `${theme.palette.error.main} !important`,
    },
    disabled: {
        opacity: '0.75 !important',
    },
}))(InputLabel);

export const LightInputLabel = (props: InputLabelProps): ReactElement => {
    const { children, ...rest } = props;
    return <InputLabelBase {...rest}>{children}</InputLabelBase>;
};

export function Alert(props: AlertProps): ReactElement {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RadioBase = withStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    checked: {},
}))(Radio);

export const LightRadio = (props: RadioProps): ReactElement => <RadioBase {...props} />;

const LightFormControlLabelBase = withStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
    },
    label: {
        fontSize: '14px',
    },
}))(FormControlLabel);

export const LightFormControlLabel = (props: FormControlLabelProps): ReactElement => {
    return <LightFormControlLabelBase {...props} />;
};

const LightCheckboxBase = withStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        width: '14px',
        height: '14px',
        padding: theme.spacing(2),
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    checked: {},
}))(Checkbox);

export const LightCheckbox = (props: CheckboxProps): ReactElement => <LightCheckboxBase {...props} />;

interface CommonProps {
    children: ReactNode;
}

export const HeaderText = (props: CommonProps): ReactElement => {
    const theme = useTheme<CustomTheme>();
    return (
        <span
            style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: theme.palette.text.primary,
                width: 'auto',
            }}
        >
            {props.children}
        </span>
    );
};

export const CellText = (props: CommonProps): ReactElement => {
    const theme = useTheme<CustomTheme>();
    return (
        <span
            style={{
                fontSize: '14px',
                fontFamily: 'Roboto',
                color: theme.palette.text.primary,
            }}
        >
            {props.children}
        </span>
    );
};
