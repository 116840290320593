import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';

export default function ErrorIcon(): ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.errorIcon}>
            <div className={classes.error_x_mark}>
                <span className={classes.error_line_left} />
                <span className={classes.error_line_right} />
            </div>
        </div>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        // --swal-red: #FFFFFF;
    },
    errorIcon: {
        // animation: animateErrorIcon 0.5s;
        width: '80px',
        height: '80px',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderRadius: '50%',
        padding: 0,
        position: 'relative',
        boxSizing: 'content-box',
        margin: '20px auto',
        '&:first-child': {
            marginTop: '32px',
        },
    },
    error_x_mark: {
        borderColor: 'red',
        position: 'relative',
        display: 'block',
        // animation: animateXMark 0.5s;

        transform: 'scale(1)',
        marginTop: 0,
        opacity: 1,
    },
    error_line_left: {
        // border-color: var(--swal-red);
        // animation: animateErrorIcon 0.5s;
        position: 'absolute',
        height: '5px',
        width: '47px',
        // backgroundColor: var(--swal-red);
        display: 'block',
        top: '37px',
        borderRadius: '2px',
        transform: 'rotate(45deg)',
        left: '17px',
        //
        backgroundColor: 'currentcolor',
    },
    error_line_right: {
        // border-color: var(--swal-red);
        // animation: animateErrorIcon 0.5s;
        position: 'absolute',
        height: '5px',
        width: '47px',
        // backgroundColor: var(--swal-red);
        display: 'block',
        top: '37px',
        borderRadius: '2px',
        transform: 'rotate(-45deg)',
        right: '16px',
        //
        backgroundColor: 'currentcolor',
    },
}));
