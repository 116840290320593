import { createStyles, makeStyles } from '@material-ui/core';
import React, { ReactElement, SyntheticEvent, useCallback } from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { displayAmount, HexToRGBA } from 'utils/';
import { CustomTheme } from 'theme/custom';
import { ActionM } from '.';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        rootActions: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '20px',
            marginTop: theme.spacing(2),
            marginLeft: '4px',
            marginBottom: 0,
            color: theme.palette.text.primary,
        },
        actionEntry: {
            gridTemplateRows: '1fr 1fr 1fr',
            borderBottom: `1px solid ${theme.palette.custom.dividerExtended.hor_div1}`,
            padding: '12px',
        },
        actionEntrySelect: {
            gridTemplateRows: '1fr 1fr 1fr',
            // closest color in palette - color in design is #3F407C, which is not in palette
            backgroundColor: theme.palette.custom.paperExtended.paper3,
            padding: '12px',
        },
        actionsGridColumnLayout: {
            display: 'grid',
            gridTemplateColumns: '1fr 1.5fr 3.5fr',
        },
        actionsDueDateColumnLayout: {
            display: 'grid',
            gridTemplateColumns: '1fr 6.5fr',
        },
        actionsStatusColumnLayout: {
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
        },
        statusText: {
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
            paddingLeft: '16px',
        },
        actionTypeText: {
            fontSize: '14px',
            lineHeight: '14px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
            paddingLeft: '16px',
        },
        actionDueDateText: {
            fontSize: '12px',
            lineHeight: '14px',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
        },
        actionDueDateIcon: {
            width: '14px',
            fill: theme.palette.primary.main,
        },
        dueDateTextImport: {
            fontSize: '12px',
            lineHeight: '14px',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.custom.import.main,
        },
        dueDateTextExport: {
            fontSize: '12px',
            lineHeight: '14px',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.custom.export.main,
        },
        dueDateIconImport: {
            width: '14px',
            fill: theme.palette.custom.import.main,
        },
        dueDateIconExport: {
            width: '14px',
            fill: theme.palette.custom.export.main,
        },
        buttonContainedPrimaryActions: {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
            fontSize: '12px',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: HexToRGBA(theme.palette.primary.main, 0.6),
            },
            width: '70px',
            height: '28px',
        },
        actionCurrencyText: {
            margin: 'auto',
            marginLeft: '0',
        },
        amountText: {
            margin: 'auto',
            marginRight: '12px',
        },
        helperText: {
            margin: 'auto',
            marginLeft: '16px',
        },
        footerGridColumnLayout: {
            display: 'flex',
            paddingBottom: '16px',
            paddingTop: '8px',
        },
        pagination: {
            border: 'none',
        },
        actionHeader: {
            color: theme.palette.custom.stellcapBrand1.light,
            fontSize: '12px',
            padding: theme.spacing(2),
            marginLeft: '12px',
            backgroundColor: theme.palette.custom.paperExtended.paper2,
        },

        backToTopButton: {
            color: HexToRGBA(theme.palette.common.white, 0.8),
            backgroundColor: theme.palette.custom.paperExtended.paper2,
            border: '0px',
            left: '285px',
            fontSize: '12px',
            marginLeft: '110px',
        },
        actionsWrapper: {
            backgroundColor: theme.palette.custom.paperExtended.paper2,
            height: '100%',
            marginTop: '0px',
            marginBottom: '0px',
            paddingBottom: '0px',
            opacity: 1,
            position: 'relative',
        },
        fadeWrapper: {
            height: '25px',
            width: '257px',
            top: '395px',
            left: '8px',
            position: 'absolute',
            borderBottomLeftRadius: '5px',
            backgroundImage: 'linear-gradient(to bottom, rgba(47, 48, 93, 0.1), rgba(47, 48 , 93, 1) 100%)',
        },
        scrollWrapper: {
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '&::-webkit-scrollbar-track': {
                display: 'none',
            },
            height: 390,
            maxHeight: 390,
            msOverflowStyle: 'none',
        },
    }),
);

const Actions = (props: {
    actionsData: ActionM[];
    actionsPage: number;
    actionsLimit: number;
    actionsOffset: number;
    handleChangePageActions: (event: SyntheticEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
    handleResolveClick: (action: ActionM) => void;
    handleShowResolve: (action: ActionM) => void;
}): ReactElement => {
    const { handleResolveClick, handleShowResolve } = props;

    const classes = useStyles();

    const getActionsDataToDisplay = useCallback((): ActionM[] | undefined => {
        if (props.actionsData) {
            return props.actionsData;
        }
    }, [props.actionsData]);

    const scrollToTop = () => {
        (document.getElementById('scrollWrapperActions') as HTMLFormElement).scroll({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className={classes.actionsWrapper}>
            <div className={classes.actionHeader}>
                ACTIONS
                <button onClick={scrollToTop} className={classes.backToTopButton}>
                    Back to top
                </button>
            </div>
            <div className={classes.fadeWrapper} />
            <div id={'scrollWrapperActions'} className={classes.scrollWrapper}>
                {(getActionsDataToDisplay() || []).map((d) => {
                    let colorClass;
                    let colorClassButton;
                    switch (d.importExport) {
                        case 'Import':
                            colorClass = classes.dueDateTextImport;
                            colorClassButton = classes.dueDateIconImport;
                            break;
                        case 'Export':
                            colorClass = classes.dueDateTextExport;
                            colorClassButton = classes.dueDateIconExport;
                            break;
                        default:
                            colorClass = classes.actionDueDateText;
                            colorClassButton = classes.actionDueDateIcon;
                    }
                    return (
                        <div
                            className={d.buttonShow ? classes.actionEntrySelect : classes.actionEntry}
                            key={(d.id as unknown) as string}
                            onClick={() => handleShowResolve(d)}
                        >
                            <div className={classes.actionsGridColumnLayout}>
                                <div className={classes.actionTypeText}>{d.type}</div>
                                <div className={classes.actionCurrencyText}>{d.foreignCurrency}</div>
                                <div className={classes.amountText}>
                                    {d.currencySymbol + ' ' + displayAmount(d.amount)}
                                </div>
                            </div>
                            <div className={classes.actionsDueDateColumnLayout}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '16px',
                                    }}
                                >
                                    <AccessTimeIcon className={colorClassButton} />
                                </div>
                                <div className={colorClass}>Due: {d.date}</div>
                            </div>
                            <div className={classes.actionsStatusColumnLayout}>
                                <div className={classes.statusText}>Status: {d.status}</div>
                                {d.buttonShow && (
                                    <BaseButton
                                        onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
                                            e.stopPropagation();
                                            handleResolveClick(d);
                                        }}
                                        id={`FECManagementStation/Actions/resolve`}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        text={'Resolve'}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Actions;
