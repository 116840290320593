import { restRPC } from 'utils/restrpc';
import { Identifier, marshalIdentifier } from '../search/identifier';
import { FindRequest, FindResponse } from 'popcorn-js';
import { Contract } from './index';
import { marshalCriteria } from '../search/marshaller';
import { Criteria } from '../search';

export type CreateRequest = {
    contract: Contract;
};

export type CreateResponse = {
    contract: Contract;
};

export type CreateBatchRequest = {
    contracts: Contract[];
};

export type CreateBatchResponse = {
    contracts: Contract[];
};

export type DeleteRequest = {
    identifier?: Identifier | string;
};

export type DeleteResponse = {
    contract: Contract;
};

export type UpdateRequest = {
    identifier?: Identifier | string;
    contract: Contract;
};

export type UpdateResponse = {
    contract: Contract;
};

export type FindContractResponse = FindResponse<Contract>;

export interface HandlerI {
    ServiceProviderName: string;

    Create(request: CreateRequest): Promise<CreateResponse>;
    CreateBatch(request: CreateBatchRequest): Promise<CreateBatchResponse>;
    Delete(request: DeleteRequest): Promise<DeleteResponse>;
    Find(request: FindRequest): Promise<FindContractResponse>;
    Update(request: UpdateRequest): Promise<UpdateResponse>;
}

export const Handler: HandlerI = {
    ServiceProviderName: 'Contract-Handler',

    Create(request: CreateRequest): Promise<CreateResponse> {
        return restRPC<CreateRequest, CreateResponse>({
            method: `${Handler.ServiceProviderName}.Create`,
            request: request,
        });
    },

    CreateBatch(request: CreateBatchRequest): Promise<CreateBatchResponse> {
        return restRPC<CreateBatchRequest, CreateBatchResponse>({
            method: `${Handler.ServiceProviderName}.CreateBatch`,
            request: request,
        });
    },

    Delete(request: DeleteRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<DeleteRequest, DeleteResponse>({
            method: `${Handler.ServiceProviderName}.Delete`,
            request: { ...request, identifier: serializedIdentifier },
        });
    },

    Find(request: FindRequest): Promise<FindContractResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return restRPC<FindRequest, FindContractResponse>({
            method: `${Handler.ServiceProviderName}.Find`,
            request: { ...request, criteria: serializedCriteria },
        });
    },

    Update(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return restRPC<UpdateRequest, UpdateResponse>({
            method: `${Handler.ServiceProviderName}.Update`,
            request: { ...request, identifier: serializedIdentifier },
        });
    },
};
