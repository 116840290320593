import React, { ChangeEvent, ReactElement, ReactNode, useContext } from 'react';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { Collapse, makeStyles, useTheme } from '@material-ui/core';
import { StandardCard } from 'components/Card/Card';
import { CustomTheme } from 'theme/custom';
import { displayAmount } from 'utils';
import {
    calculateCallAmount,
    calculatePutAmount,
    getCallCurrency,
    getPutCurrency,
    Actions,
    OptionValues,
} from './index';
import { Warning } from '@material-ui/icons';
import { AppContext, AppContextT } from 'context';
import { OptionType } from 'popcorn-js/options';
import { LightTextField } from './styledComponents';

export const OptionPanelCard = ({
    children,
    disabled,
    disableHeader,
    index,
    option,
    uuid,
    onExpand,
    expanded,
    onRemove,
    valid,
    dispatch,
}: {
    uuid: string;
    disabled?: boolean;
    disableHeader?: boolean;
    valid?: boolean;
    index?: number;
    children: ReactNode;
    onExpand?: () => void;
    expanded?: string;
    onRemove?: () => void;
    option: OptionValues;
    dispatch: Actions;
}): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    if (disableHeader) {
        return <>{children}</>;
    }

    const appContext = useContext<AppContextT>(AppContext);

    const putCurrency = getPutCurrency(option.type, option.currencyPair, appContext.currencies);
    const callCurrency = getCallCurrency(option.type, option.currencyPair, appContext.currencies);

    const callAmount = calculateCallAmount(option.strikePrice, option.notionalAmount, option.type);
    const putAmount = calculatePutAmount(option.strikePrice, option.notionalAmount, option.type);

    return (
        <StandardCard
            cardHeaderProps={{
                fatter: true,
                itemsRight: [
                    {
                        type: ITEM_VARIATION.ELEMENT,
                        element: <Warning style={{ color: theme.palette.error.main }} />,
                        hide: valid,
                        id: 'warning',
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        icon: ACTION_BUTTON_TYPE.DELETE_PERMANENTLY,
                        onClick: () => {
                            onRemove ? onRemove() : undefined;
                        },
                        disabled,
                        id: 'remove',
                        hide: !onRemove,
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        icon: expanded === uuid ? ACTION_BUTTON_TYPE.COLLAPSE : ACTION_BUTTON_TYPE.EXPAND,
                        onClick: () => onExpand && onExpand(),
                        disabled,
                        id: 'expand',
                        hide: !onExpand,
                    },
                ],
                itemsLeft: [
                    {
                        type: ITEM_VARIATION.TITLE,
                        text: getTitle(expanded === uuid, index),
                        id: 'title',
                        variant: 'title2',
                    },
                    {
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <div className={classes.collapsedTitle}>
                                <span className={classes.collapsedInfo}>
                                    {option.externalReference ? truncate(option.externalReference) : '-'}
                                </span>
                                <span className={classes.collapsedInfo}>
                                    {option.strikePrice ? option.strikePrice.toFixed(6) : '-'}
                                </span>
                                <span className={classes.collapsedInfo}>
                                    {option.type === OptionType.CALL
                                        ? callAmount
                                            ? `${callCurrency?.isoCode} ${displayAmount(callAmount)}`
                                            : '-'
                                        : putAmount
                                        ? `${putCurrency?.isoCode} ${displayAmount(putAmount)}`
                                        : '-'}
                                </span>
                            </div>
                        ),
                        id: 'TradePanel/external-ref-collapsed',
                        hide: expanded === uuid,
                    },
                    {
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <div className={classes.collapsedTitle}>
                                <span className={classes.referenceTitle}>External Reference:</span>
                            </div>
                        ),
                        id: 'externalReferenceTitle',
                        hide: expanded !== uuid,
                    },
                    {
                        type: ITEM_VARIATION.ELEMENT,
                        element: (
                            <div>
                                <LightTextField
                                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                        dispatch.updateOptionExtRef(uuid, event.target.value)
                                    }
                                    value={option.externalReference || ''}
                                    error={!option.externalReference || option.externalReference === ''}
                                />
                            </div>
                        ),
                        id: 'externalReference',
                        hide: expanded !== uuid,
                    },
                ],
            }}
        >
            <Collapse in={expanded === uuid} className={classes.content}>
                {expanded === uuid && children}
            </Collapse>
        </StandardCard>
    );
};

const getTitle = (expanded: boolean, index?: number): string => {
    return expanded ? `Option ${(index || 0) + 1}` : `Option ${(index || 0) + 1}`;
};

const truncate = (t: string): string => {
    if (t.length > 6) {
        return `${t.substr(0, 2)}...${t.substr(t.length - 4, 4)}`;
    }
    return t;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    collapsedInfo: {
        color: theme.palette.text.secondary,
        fontSize: '14px',
        marginRight: theme.spacing(2),
    },
    collapsedTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    referenceTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        color: theme.palette.secondary.main,
        marginRight: '10px',
    },
}));
