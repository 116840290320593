import React, { useState } from 'react';
import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Order } from 'popcorn-js/order';
import { BaseTextField } from '../BaseTextField/BaseTextField';
import moment from 'moment';
import { SystemDateFormat } from 'constants/formats';
import { BaseNumberField } from '../BaseTextField/BaseNumberField';
import { ItemWrapper } from '../audit/IOWrapper';
import { InvalidReason } from 'popcorn-js/order/recordkeeper';

const OriginalCapturedDetails = (props: {
    order: Order;
    inputProps: {
        classes: {
            underline: string;
        };
        disableUnderline: boolean;
        readOnly: boolean;
    };
}): ReactElement => {
    const { order, inputProps } = props;
    const classes = useStyles();
    const [invalidFields] = useState<InvalidReason[]>([] as InvalidReason[]);
    return (
        <React.Fragment>
            <div className={classes.originalCapturedHeaderWrapper}>Original Captured Info</div>
            <div className={classes.originalCapturedCardWrapper}>
                <ItemWrapper field={'captureDate'} validationErrors={invalidFields} label={'Capture Date'}>
                    <BaseTextField
                        InputProps={inputProps}
                        disabled={true}
                        id={'captureDate'}
                        type={'date'}
                        value={moment(order.captureDate).format(SystemDateFormat)}
                    />
                </ItemWrapper>
                <ItemWrapper field={'captureSpotRate'} validationErrors={invalidFields} label={'Capture Spot Rate'}>
                    <BaseNumberField
                        InputProps={inputProps}
                        disabled={true}
                        id="OrderDetail/captureSpotRate"
                        value={order.captureSpotRate?.toFixed(4)}
                    />
                </ItemWrapper>
                <ItemWrapper
                    field={'originalCaptureRate'}
                    validationErrors={invalidFields}
                    label={'Original Capture Rate'}
                >
                    <BaseNumberField
                        InputProps={inputProps}
                        disabled={true}
                        id="OrderDetail/originalCaptureRate"
                        value={order.originalCaptureRate?.toFixed(4)}
                    />
                </ItemWrapper>
            </div>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    originalCapturedHeaderWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        height: '46px',
        lineHeight: '46px',
        paddingLeft: '24px',
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid rgba(255,255,255,.25)',
    },
    originalCapturedCardWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        height: '66.54px',
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid rgba(255,255,255,.25)',
    },
}));

export default OriginalCapturedDetails;
