import React from 'react';
import { roundScaleAndFormatNumber } from 'utils';
import { makeStyles } from '@material-ui/core';
import { Currency } from 'popcorn-js/currency';
import { GenerateMonthViewCashFlowReportResponse } from 'popcorn-js/report';
import { CustomTheme } from 'theme/custom';
import { GraphLegendItem } from './GraphLegendItem';

interface CashFlowTotalsProps {
    currency: Currency;
    report: GenerateMonthViewCashFlowReportResponse;
}

export const CashFlowTotals: React.FC<CashFlowTotalsProps> = (props: CashFlowTotalsProps) => {
    const classes = useStyles();
    const totals = (props.report || {}).totals || {};
    const localCurrency = props.currency;

    const salesDue = totals.salesDue?.totalAmountInCostCurrency;
    const purchaseDue = totals.purchaseDue?.totalAmountInCostCurrency;

    const purchaseOverDue = totals.purchaseOverDue?.totalAmountInCostCurrency;
    const salesOverDue = totals.salesOverDue?.totalAmountInCostCurrency;

    const purchaseHedge = totals.purchaseHedge?.totalAmountInCostCurrency;
    const salesHedge = totals.salesHedge?.totalAmountInCostCurrency;
    const purchaseSalesHedge = totals.purchaseSalesHedge?.totalAmountInCostCurrency;

    const showPurchaseTotals = purchaseDue !== 0 || purchaseOverDue !== 0 || purchaseHedge !== 0;
    const showSalesTotals = salesDue !== 0 || salesOverDue !== 0 || salesHedge !== 0;
    const showTradeForecasted = showPurchaseTotals || showSalesTotals;
    const showTradeForecastedNextToPurchase = showPurchaseTotals;

    return (
        <React.Fragment>
            <div className={classes.graphLegendWrapper}>
                <div className={classes.container}>
                    <div className={showSalesTotals && showPurchaseTotals ? classes.column : classes.singleRowColumn}>
                        {showSalesTotals && (
                            <GraphLegendItem
                                icon={'teal'}
                                title={'Sales'}
                                value={roundScaleAndFormatNumber(salesDue, localCurrency.symbol, false, 0)}
                                tooltipTitle={'Sum of all sales orders, invoices and credit notes converted at MTM'}
                                align={'left'}
                            />
                        )}
                        {showPurchaseTotals && (
                            <GraphLegendItem
                                icon={'orange'}
                                title={'Purchases'}
                                value={roundScaleAndFormatNumber(purchaseDue, localCurrency.symbol, false, 0)}
                                tooltipTitle={'Sum of all purchase orders, invoices and credit notes converted at MTM'}
                                align={'left'}
                            />
                        )}
                    </div>
                    <div className={showSalesTotals && showPurchaseTotals ? classes.column : classes.singleRowColumn}>
                        {showSalesTotals && (
                            <GraphLegendItem
                                icon={'red'}
                                title={'Overdue Sales'}
                                value={roundScaleAndFormatNumber(salesOverDue, localCurrency.symbol, false, 0)}
                                tooltipTitle={
                                    'Sum of all outstanding sales orders, invoices and credit notes converted at MTM'
                                }
                                align={'left'}
                            />
                        )}
                        {showPurchaseTotals && (
                            <GraphLegendItem
                                icon={'red'}
                                title={'Overdue Purchases'}
                                value={roundScaleAndFormatNumber(purchaseOverDue, localCurrency.symbol, false, 0)}
                                tooltipTitle={
                                    'Sum of all outstanding sales orders, invoices and credit notes converted at MTM'
                                }
                                align={'left'}
                            />
                        )}
                    </div>
                    <div className={showSalesTotals && showPurchaseTotals ? classes.column : classes.singleRowColumn}>
                        {showTradeForecasted && showTradeForecastedNextToPurchase && (
                            <GraphLegendItem
                                icon={'yellow'}
                                title={'Future Net Trade Cash Flows'}
                                value={roundScaleAndFormatNumber(purchaseSalesHedge, localCurrency.symbol, false, 0)}
                                tooltipTitle={'Total ZAR cash flows due to drawdown, extension or cancellation of FECs'}
                                align={'left'}
                            />
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    graphLegendWrapper: {
        width: '1000px',
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        margin: '15px 10px 5px 68px',
        padding: theme.spacing(1),
        alignItems: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        flexGrow: 1,
        borderRadius: '4px',
        flex: 'box',
    },
    column: {
        width: '100%',
        display: 'grid',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gridTemplateRows: '1fr 1fr',
    },
    singleRowColumn: {
        width: '100%',
        display: 'grid',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gridTemplateRows: '1fr',
    },
    container: {
        columnGap: '5px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        marginLeft: '30px',
    },
}));
