import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import {
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Table as MUITable,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@material-ui/core';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { ScaleLoader as Spinner } from 'react-spinners';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { AppContext, AppContextT } from 'context';
import { useStyletron } from 'styletron-react';
import {
    Clear as CancelIcon,
    Edit as EditIcon,
    ErrorOutline as ErrorIcon,
    History as HistoryIcon,
    RemoveRedEye,
    Save as SaveIcon,
} from '@material-ui/icons';
import cx from 'classnames';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { displayDate, displayRate, HexToRGBA, objectCopy } from 'utils';
import {
    AmendRequest,
    Handler as OptionHandler,
    RecordResponse,
    DefaultRevenueRequest,
    DefaultRevenueResponse,
} from 'popcorn-js/options/handler';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Recordkeeper as ProcessingBankRecordkeeper } from 'popcorn-js/party/processingBank/recordkeeper';
import { Client, ProcessingBank, ProcessingOrg } from 'popcorn-js/party';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useServiceSync } from 'hooks/useService';
import { financialYears, FindRequest, FindResponse, ImportExport, seasons } from 'popcorn-js';
import { CriteriaType } from 'popcorn-js/search';
import Big from 'big.js';
import { debounce } from 'lodash';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { Option, OptionDirections, OptionStatus, OptionType } from 'popcorn-js/options';
import { Event, FieldIsEditable, fieldLabels, OptionAmendInputFromOption, State } from './index';
import { History } from 'components/Option/History';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'popcorn-js/party/processingOrg/recordkeeper';
import { BillingTypes, PartyType } from 'popcorn-js/tradeV2';

const OptionDetailDialog = (props: {
    closeDialog: () => void;
    open: boolean;
    option?: Option;
    amendSuccess: () => void;
}): ReactElement => {
    const { closeDialog, open, option, amendSuccess } = props;
    // state
    const [activeState, setActiveState] = useState<State | Event>(Event.init);
    // NotificationSweetAlert
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);
    const [editedOption, setEditedOption] = useState<Option>(objectCopy(option));
    const [processingBanks, setProcessingBanks] = useState<ProcessingBank[]>([] as ProcessingBank[]);
    const [otherTransactionOptions, setOtherTransactionOptions] = useState<Option[]>([] as Option[]);
    const appContext = useContext<AppContextT>(AppContext);
    const theme = useTheme<CustomTheme>();
    const [css] = useStyletron();
    const classes = useStyles();
    const [parentParty, setParentParty] = useState<ProcessingOrg | undefined>();
    const [closePageVisible, setClosePageVisible] = useState<boolean>(true);
    const [marginNotesNeeded, setMarginNotesNeeded] = useState<boolean>(false);
    const [originalOption, setOriginalOption] = useState<Option>(objectCopy(option));
    const [defaultRevenue, setDefaultRevenue] = useState<Option>(objectCopy(option));
    const [transactionFieldsFilled, setTransactionFieldsFilled] = useState<boolean>(false);

    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );

    const party = (appContext.party || {}) as Client | ProcessingOrg;

    // service providers
    const [processingBankRecordkeeperFind] = useServiceSync<FindRequest, FindResponse<ProcessingBank>>(
        ProcessingBankRecordkeeper.find,
    );
    const [optionHandlerAmend] = useServiceSync<AmendRequest, RecordResponse>(OptionHandler.Amend);
    const [tradeHandlerFind] = useServiceSync<FindRequest, FindResponse<Option>>(OptionHandler.Find);
    const [CalculateDefaultRevenue] = useServiceSync<DefaultRevenueRequest, DefaultRevenueResponse>(
        OptionHandler.CalculateDefaultRevenue,
    );
    const [selectedLinkedOption, setSelectedLinkedOption] = useState<Option | undefined>(undefined);

    const retrieveParentParty = async () => {
        setActiveState(Event.enterLoading);
        try {
            const retrieveParentPartyResponse = await processingOrgRecordkeeperRetrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.parentPartyCode },
            });
            setParentParty(retrieveParentPartyResponse.processingOrg);
        } catch (e) {
            console.error('error finding parent party', e);
            setActiveState(Event.errorEvent);
        }
        setActiveState(Event.doneLoading);
    };

    const findProcessingBanks = async () => {
        setActiveState(Event.enterLoading);
        try {
            const findProcessingBanksResponse = await processingBankRecordkeeperFind({});
            setProcessingBanks(findProcessingBanksResponse.records);
        } catch (e) {
            console.error('error finding processing banks', e);
            setActiveState(Event.errorEvent);
        }
        setActiveState(Event.doneLoading);
    };

    const findLinkedEntities = async () => {
        setActiveState(Event.enterLoading);
        try {
            if (option?.transactionID) {
                const otherTransactionTradesResp = await tradeHandlerFind({
                    criteria: [
                        {
                            type: CriteriaType.TextCriterion,
                            field: 'transactionID',
                            text: option?.transactionID,
                        },
                    ],
                });
                const thisTrade = otherTransactionTradesResp.records.find((r) => r.id === option.id);
                if (thisTrade) {
                    setEditedOption(thisTrade);
                    setOriginalOption(thisTrade);
                    setDefaultRevenue(thisTrade);
                }
                setOtherTransactionOptions(otherTransactionTradesResp.records);
            }
        } catch (e) {
            console.error('error finding linked entities', e);
            setActiveState(Event.errorEvent);
        }
        setActiveState(Event.doneLoading);
    };

    useEffect(() => {
        findProcessingBanks().finally();
        findLinkedEntities().finally();
        retrieveParentParty().finally();
    }, []);

    useEffect(() => {
        setMarginNotesNeeded(() => {
            return (
                editedOption.marginNotes == '' &&
                (editedOption.intermediaryMargin !== defaultRevenue.intermediaryMargin ||
                    editedOption.billingType !== defaultRevenue.billingType ||
                    editedOption.adminFee !== defaultRevenue.adminFee)
            );
        });
    }, [editedOption]);

    const calculateDefaultRevenue = useRef(
        debounce(async (request: Option) => {
            try {
                CalculateDefaultRevenue({ option: request }).then((response) => {
                    setEditedOption(response.option);
                });
            } catch (e) {
                setErrorMessage(`error amending Default Revenue: ${e.message || e}`);
            }
        }, 1000),
    ).current;

    useEffect(() => {
        calculateDefaultRevenue.cancel();
    }, [calculateDefaultRevenue]);

    useEffect(() => {
        setClosePageVisible(() => {
            return activeState != State.editingExisting;
        });
    }, [activeState]);

    const handleSaveChanges = async () => {
        if (!option) {
            setActiveState(Event.errorEvent);
            return;
        }
        setActiveState(Event.enterLoading);
        try {
            await optionHandlerAmend({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: editedOption.id },
                option: OptionAmendInputFromOption(editedOption),
            });
            setSuccessMessage('successfully amended option');
            await amendSuccess();
            setEditedOption(option);
            findProcessingBanks().finally();
            findLinkedEntities().finally();
            setMarginNotesNeeded(false);
            3;
            setSuccessMessage(undefined);
        } catch (e) {
            setEditedOption(option);
            setErrorMessage(`error amending: ${e.message || e}`);
        }

        setActiveState(Event.doneLoading);
    };

    const handleCancelEditExisting = () => {
        if (!option) {
            setActiveState(Event.errorEvent);
            return;
        }
        setWarningMessage('Discard Changes');
        setConfirmationMethod(() => () => {
            setEditedOption(originalOption);
            setDefaultRevenue(originalOption);
            setActiveState(Event.cancelEditExisting);
            setWarningMessage(undefined);
        });
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const handleEnterEditMode = async () => {
        if (originalOption) {
            setActiveState(Event.enterLoading);
            try {
                await CalculateDefaultRevenue({ option: editedOption }).then((response) => {
                    setEditedOption(response.option);
                    setDefaultRevenue(response.option);
                    setTransactionFieldsFilled(true);
                });
            } catch (e) {
                setErrorMessage(`error calculating Default Revenue: ${e.message || e}`);
                setTransactionFieldsFilled(false);
            }
            setActiveState(Event.doneLoading);
        }
        setMarginNotesNeeded(false);
        setActiveState(State.editingExisting);
    };

    const renderOptionDetailsLayout = (): ReactElement => {
        const itemLg = 3;
        const itemMd = 12;
        const itemSm = 12;
        const itemXs = 12;
        return (
            <React.Fragment>
                <div className={classes.tradeDetailFormSection2}>
                    <Grid container direction={'row'} spacing={3}>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('externalReference', undefined, 'accent')}</Grid>
                                <Grid item>{RenderField('type', undefined, undefined, undefined)}</Grid>
                                <Grid item>{RenderField('bank')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('direction')}</Grid>
                                <Grid item>{RenderField('strikePrice')}</Grid>
                                <Grid item>{RenderField('premium')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('currencyPair')}</Grid>
                                <Grid item>
                                    {RenderField(
                                        'callAmount',
                                        undefined,
                                        'white',
                                        option?.type === OptionType.CALL
                                            ? (_newValue, _option) => {
                                                  try {
                                                      _option['notionalAmount'] = _newValue
                                                          ? Big((_newValue as number) || 0).toNumber()
                                                          : 0;
                                                      _option['callAmount'] = _newValue
                                                          ? Big((_newValue as number) || 0).toNumber()
                                                          : 0;
                                                  } catch (e) {
                                                      return _option;
                                                  }
                                                  return _option;
                                              }
                                            : (_newValue, _option) => {
                                                  try {
                                                      _option['quoteAmount'] = _newValue
                                                          ? Big((_newValue as number) || 0).toNumber()
                                                          : 0;
                                                      _option['callAmount'] = _newValue
                                                          ? Big((_newValue as number) || 0).toNumber()
                                                          : 0;
                                                  } catch (e) {
                                                      return _option;
                                                  }
                                                  return _option;
                                              },
                                    )}
                                </Grid>
                                <Grid item>
                                    {RenderField(
                                        'putAmount',
                                        undefined,
                                        'white',
                                        option?.type === OptionType.PUT
                                            ? (_newValue, _option) => {
                                                  try {
                                                      _option['notionalAmount'] = _newValue
                                                          ? Big((_newValue as number) || 0).toNumber()
                                                          : 0;
                                                      _option['putAmount'] = _newValue
                                                          ? Big((_newValue as number) || 0).toNumber()
                                                          : 0;
                                                  } catch (e) {
                                                      return _option;
                                                  }
                                                  return _option;
                                              }
                                            : (_newValue, _option) => {
                                                  try {
                                                      _option['quoteAmount'] = _newValue
                                                          ? Big((_newValue as number) || 0).toNumber()
                                                          : 0;
                                                      _option['putAmount'] = _newValue
                                                          ? Big((_newValue as number) || 0).toNumber()
                                                          : 0;
                                                  } catch (e) {
                                                      return _option;
                                                  }
                                                  return _option;
                                              },
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid item>{RenderField('tradeDate')}</Grid>
                            <Grid item>{RenderField('expiryDate', undefined, 'white')}</Grid>
                            <Grid item>{RenderField('deliveryDate')}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} spacing={3}>
                        <Grid item lg={6} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('notes')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tradeDetailSectionLabel2}>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('status', undefined, 'white', undefined)}</Grid>
                                <Grid item>{RenderField('financialYear')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('importExport')}</Grid>
                                <Grid item>{RenderField('season')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('clientReference', undefined, 'white')}</Grid>
                                <Grid item>{RenderField('product')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('effectiveRate')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} spacing={3}>
                        <Grid item lg={6} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('clientNotes')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tradeDetailFormSection2}>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('trader')}</Grid>
                                <Grid item>{RenderField('marginNotes', undefined, 'red')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('billingType')}</Grid>
                                <Grid item>{RenderField('intermediaryMargin')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('bankRate')}</Grid>
                                <Grid item>{RenderField('billedToBank')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('clientFee')}</Grid>
                                <Grid item>{RenderField('adminFee')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tradeDetailSectionLabel2}>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('number')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('processingOrgPartyCode')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('interbankRate')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('capturedSpotRate')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    };

    const renderControlIcons = () => {
        switch (activeState) {
            case State.nop:
                return (
                    <React.Fragment>
                        <Tooltip title="History">
                            <IconButton onClick={() => setActiveState(Event.showHistory)}>
                                <HistoryIcon />
                            </IconButton>
                        </Tooltip>
                        {appContext.currentContext?.partyType === PartyType.CLIENT && (
                            <Tooltip title="Edit">
                                <IconButton onClick={() => handleEnterEditMode()}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </React.Fragment>
                );
            case State.editingExisting:
                return (
                    <React.Fragment>
                        <Tooltip title="Save Changes">
                            <IconButton onClick={handleSaveChanges} disabled={marginNotesNeeded}>
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                            <IconButton onClick={handleCancelEditExisting}>
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    </React.Fragment>
                );

            default:
        }
    };

    const renderLayout = (): ReactElement => {
        return (
            <div className={classes.detailLayout}>
                <div className={classes.fecBreakdownDetailLayout}>
                    <div
                        className={cx(
                            css({
                                backgroundColor: theme.palette.background.paper,
                            }),
                            classes.tradeDetailTitleLayout,
                        )}
                    >
                        <div className={classes.tradeDetailTitle}>Linked Entities</div>
                    </div>
                    <div>
                        <div className={classes.tradeDetailSectionLabel}>{`All Options in Transaction`}</div>
                        <div className={classes.tradeDetailFormSection}>
                            {EntityTable({
                                classes: classes,
                                currentEntityID: option?.id || '',
                                entities: otherTransactionOptions as Array<
                                    Record<keyof Option, string | number | Date>
                                >,
                                noLinkedText: 'No Options in Transaction',
                                onClick: (lt) => setSelectedLinkedOption(lt as Option),
                                cols: [
                                    {
                                        accessor: 'externalReference',
                                        header: 'External Reference',
                                    },
                                    {
                                        accessor: 'effectiveRate',
                                        header: 'Effective Rate',
                                        customFormatFunc: (val) => {
                                            return displayRate(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'strikePrice',
                                        header: 'Strike Price',
                                        customFormatFunc: (val) => {
                                            return displayRate(val as number);
                                        },
                                    },
                                ],
                            })}
                        </div>
                    </div>
                </div>
                <div className={classes.tradeDetailLayout}>
                    <div
                        className={cx(
                            css({
                                backgroundColor: theme.palette.background.paper,
                            }),
                            classes.tradeDetailTitleLayout,
                        )}
                    >
                        <div className={classes.tradeDetailTitle}>Option Details</div>
                        <div className={classes.tradeDetailTitleActions}>{renderControlIcons()}</div>
                    </div>
                    <div className={classes.tradeDetailFormLayout} style={{ height: '100%' }}>
                        {renderOptionDetailsLayout()}
                    </div>
                </div>
            </div>
        );
    };

    const calculateStrikePrice = async (requestOption: Option) => {
        if (requestOption.notionalAmount && requestOption.quoteAmount) {
            const notionalAmount = Number(requestOption.notionalAmount.toFixed(2)) || 0.0;
            const quoteAmount = Number(requestOption.quoteAmount.toFixed(2)) || 0.0;
            requestOption.strikePrice = Number((quoteAmount / notionalAmount).toFixed(6));
            setEditedOption(requestOption);
        }
    };

    const RenderField = (
        field: keyof Option,
        altTooltip?: string,
        variant?: 'white' | 'red' | 'accent' | 'importExport' | 'importExportWhite',
        customEditFunc?: (newValue: unknown, option: Option) => Option,
    ): ReactElement => {
        if (!option) {
            return <div />;
        }

        const linkedEntities = otherTransactionOptions.length > 1;
        const canEdit = customEditFunc || FieldIsEditable(field, linkedEntities, transactionFieldsFilled);
        const tooltip = canEdit ? '' : 'Cannot edit';
        const fieldsToRoundToSix: Array<keyof Option> = [
            'strikePrice',
            'premium',
            'bankRate',
            'effectiveRate',
            'interbankRate',
            'capturedSpotRate',
        ];
        const fieldsToRoundToFour: Array<keyof Option> = ['intermediaryMargin'];
        const fieldsToRoundToTwo: Array<keyof Option> = [
            'notionalAmount',
            'quoteAmount',
            'putAmount',
            'callAmount',
            'clientFee',
            'billedToBank',
            'adminFee',
        ];
        const dateFields: Array<keyof Option> = ['captureDate', 'deliveryDate', 'tradeDate', 'expiryDate'];
        let value = getKeyValue<Option, keyof Option>(editedOption, field);
        const isNumeric = typeof editedOption[field] === 'number';

        const setCalculatedFields = (o: Option, field: string): Option => {
            if (field === 'billingType' || field === 'intermediaryMargin') {
                if (o.intermediaryMargin !== 0) {
                    calculateDefaultRevenue(o);
                } else {
                    calculateDefaultRevenue.cancel();
                    o.billedToBank = 0;
                    o.clientFee = 0;
                    o.bankRate = 0;
                    o.billingType = undefined;
                }
            }
            return o;
        };

        const checkIfMarginNotesNeeded = (o: Option, field: string) => {
            if (
                field === 'billingType' ||
                field === 'intermediaryMargin' ||
                field === 'adminFee' ||
                field === 'marginNotes'
            ) {
                setMarginNotesNeeded(() => {
                    return (
                        o.marginNotes == '' &&
                        (o.intermediaryMargin !== defaultRevenue.intermediaryMargin ||
                            o.billingType !== defaultRevenue.billingType ||
                            o.adminFee !== defaultRevenue.adminFee)
                    );
                });
            }
        };

        /* eslint-disable @typescript-eslint/no-explicit-any */
        const handleChange = (value: any) => {
            let newOption = objectCopy(editedOption);

            if (customEditFunc) {
                const _newOption = customEditFunc(value, newOption);
                calculateStrikePrice(_newOption);
                setEditedOption(_newOption);
                return;
            }

            if (isNumeric) {
                try {
                    newOption[field] = value ? Big(value || 0).toNumber() : 0;
                } catch (e) {
                    return;
                }
            } else {
                newOption[field] = value;
            }
            checkIfMarginNotesNeeded(newOption, field);
            newOption = setCalculatedFields(newOption, field);
            setEditedOption(newOption);
        };

        switch (typeof value) {
            case 'number':
                if (fieldsToRoundToTwo.includes(field)) {
                    value = value?.toFixed(2);
                    break;
                }
                if (fieldsToRoundToFour.includes(field)) {
                    value = value?.toFixed(4);
                    break;
                }
                if (fieldsToRoundToSix.includes(field)) {
                    value = value?.toFixed(6);
                }
                break;
            case 'string':
                if (dateFields.includes(field)) {
                    value = displayDate(value);
                    break;
                }
                break;
            case 'boolean':
                if (value) {
                    value = 'TRUE';
                } else {
                    value = 'FALSE';
                }
                break;
            default:
                value = '-';
                break;
        }
        const dateField: Array<keyof Option> = ['tradeDate', 'expiryDate', 'deliveryDate'];
        const selectFields: Array<keyof Option> = [
            'currencyPair',
            'bank',
            'direction',
            'financialYear',
            'importExport',
            'type',
            'status',
            'traderOrganisation',
            'season',
            'product',
            'trader',
            'billingType',
        ];

        const selectOptions: Record<string, Array<{ value: string; label: string }>> = {
            currencyPair: appContext.currencyPairs
                ? appContext.currencyPairs.map((ccyPair) => ({
                      value: ccyPair.name,
                      label: ccyPair.name,
                  }))
                : [],
            bank: processingBanks.map((pb) => ({
                value: pb.partyCode,
                label: pb.name,
            })),
            direction: OptionDirections,
            financialYear: financialYears.map((fy) => ({
                value: fy,
                label: fy,
            })),
            importExport: [
                { value: ImportExport.IMPORT, label: 'Import' },
                { value: ImportExport.EXPORT, label: 'Export' },
            ],
            type: [
                { value: OptionType.CALL, label: 'Call' },
                { value: OptionType.PUT, label: 'Put' },
            ],
            status: [
                { value: OptionStatus.OPEN, label: 'Open' },
                { value: OptionStatus.EXERCISED, label: 'Exercised' },
                { value: OptionStatus.EXPIRED, label: 'Expired' },
            ],
            traderOrganisation: [
                {
                    value: parentParty?.partyCode || '-',
                    label: parentParty?.name || '-',
                },
                {
                    value: party?.partyCode || '-',
                    label: party?.name || '-',
                },
            ],
            billingType: BillingTypes.map((bt) => ({
                value: bt,
                label: bt,
            })),
            season: seasons.map((s) => ({
                value: s,
                label: s,
            })),
            product: ((appContext.party as Client)?.products || []).map((p) => ({
                value: p,
                label: p,
            })),
            trader: (parentParty?.traders || []).map((t) => ({
                value: t,
                label: t,
            })),
        };

        const fieldSelectOptions = selectOptions[field];
        const importExportVariant = variant == 'importExport' || variant === 'importExportWhite';
        const isImport = value === 'BUY' || value == 'Import';
        const isExport = value === 'SELL' || value == 'Export';

        const inputLabelProps = {
            classes: {
                focused: classes.focusedInputLabel,
                root: classes.inputLabel,
            },
            shrink: true,
            style: (() => {
                switch (variant) {
                    case 'importExport':
                    case 'accent':
                        return { color: theme.palette.text.disabled };
                    case 'importExportWhite':
                        return { color: theme.palette.text.secondary };
                    case 'red':
                        if (marginNotesNeeded && activeState === State.editingExisting) {
                            return { color: theme.palette.error.main };
                        }
                        return { color: theme.palette.text.secondary };
                    default:
                        return undefined;
                }
            })(),
        };

        switch (true) {
            case selectFields.includes(field):
                return (
                    <FormControl
                        aria-describedby={field}
                        className={classes.formField}
                        disabled={!canEdit || activeState !== State.editingExisting}
                    >
                        <InputLabel
                            classes={{
                                focused: classes.focusedInputLabel,
                                root: classes.inputLabel,
                            }}
                            htmlFor={field}
                            style={{ width: 150, color: theme.palette.text.disabled }}
                        >
                            {fieldLabels[field]}
                        </InputLabel>
                        <Select
                            // disableUnderline={activeState !== State.editingExisting}
                            id={field}
                            input={
                                <Input
                                    classes={{
                                        underline: classes.line,
                                    }}
                                />
                            }
                            inputProps={{
                                readOnly: activeState !== State.editingExisting,
                            }}
                            onChange={(
                                event: React.ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) => {
                                const value = event.target.value;
                                if (!option) {
                                    return;
                                }
                                let newOption = objectCopy(editedOption);
                                newOption[field] = value;
                                checkIfMarginNotesNeeded(newOption, field);
                                newOption = setCalculatedFields(newOption, field);
                                setEditedOption(newOption);
                            }}
                            style={{ width: 150 }}
                            value={value}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {(fieldSelectOptions || []).map((opt, idx) => {
                                return (
                                    <MenuItem key={idx} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                );
            case dateField.includes(field):
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ paddingBottom: '12px' }}
                            autoOk
                            disableToolbar
                            maskChar={'_'}
                            variant={'inline'}
                            margin={'none'}
                            value={value}
                            placeholder={'dd/MM/yyyy'}
                            label={fieldLabels[field]}
                            initialFocusedDate={new Date()}
                            onChange={(date: MaterialUiPickersDate) => {
                                const newTrade = objectCopy(editedOption);
                                const newDate = date as Date;
                                newTrade[field] = newDate.toISOString();
                                setEditedOption(newTrade);
                            }}
                            inputProps={{
                                width: '100%',
                            }}
                            format={'dd/MM/yyyy'}
                            KeyboardButtonProps={{
                                size: 'small',
                            }}
                            className={classes.fullWidth}
                            TextFieldComponent={(params) => (
                                <BaseTextField
                                    id={'TradeDetailDialog/legs.0.tradeDate'}
                                    {...params}
                                    margin={'dense'}
                                    variant={'standard'}
                                />
                            )}
                            disabled={activeState !== State.editingExisting}
                        />
                    </MuiPickersUtilsProvider>
                );
            case isNumeric:
                return (
                    <Tooltip enterDelay={500} title={altTooltip || tooltip}>
                        <span>
                            <BaseNumberField
                                disabled={!canEdit || activeState !== State.editingExisting}
                                classes={{
                                    root: cx(
                                        {
                                            [classes.whiteTextField]: variant === 'white',
                                            [classes.accentTextField]: variant === 'accent',
                                        },
                                        classes.fullWidth,
                                    ),
                                }}
                                className={classes.formField}
                                id={field}
                                label={fieldLabels[field]}
                                value={(value as unknown) as number}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(event.target.value);
                                }}
                            />
                        </span>
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip enterDelay={500} title={altTooltip || tooltip}>
                        <span>
                            <BaseTextField
                                classes={{
                                    root: cx(
                                        {
                                            [classes.whiteTextField]: variant === 'white',
                                            [classes.accentTextField]: variant === 'accent',
                                            [classes.importTextField]: importExportVariant && isImport,
                                            [classes.exportTextField]: importExportVariant && isExport,
                                        },
                                        classes.fullWidth,
                                    ),
                                }}
                                InputLabelProps={inputLabelProps}
                                disabled={!canEdit || activeState !== State.editingExisting}
                                className={classes.formField}
                                id={field}
                                label={fieldLabels[field]}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(event.target.value);
                                }}
                                value={value}
                                // InputProps={inputProps}
                            />
                        </span>
                    </Tooltip>
                );
        }
    };

    return (
        <Dialog classes={{ paper: classes.dialogPaper }} fullScreen open={open}>
            {selectedLinkedOption && (
                <OptionDetailDialog
                    closeDialog={closeDialog}
                    open={selectedLinkedOption as boolean}
                    option={selectedLinkedOption}
                    amendSuccess={amendSuccess}
                />
            )}
            <BaseAppBar title={'Option summary'} onClose={() => closeDialog()} showCloseButton={closePageVisible} />
            <DialogContent classes={{ root: classes.rootOverride }}>
                {((): ReactElement => {
                    switch (activeState) {
                        case State.nop:
                        case State.editingExisting:
                        case State.loading:
                        case State.showingHistory:
                            return renderLayout();
                        case State.error:
                            return RenderError(errorMessage);
                        default:
                            return <div>Error</div>;
                    }
                })()}
            </DialogContent>
            <Dialog
                BackdropProps={{ classes: { root: classes.progressSpinnerDialogBackdrop } }}
                PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                className={classes.progressSpinnerDialog}
                open={activeState === State.loading}
            >
                <Spinner loading={activeState === State.loading} />
            </Dialog>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                onConfirm={confirmationMethod}
                successMessage={successMessage}
                warningMessage={warningMessage}
            />
            <History
                onHide={() => setActiveState(Event.exitHistory)}
                open={activeState === State.showingHistory}
                option={option}
            />
        </Dialog>
    );
};

const RenderError = (message?: string): ReactElement => {
    return (
        // className={classes.errorDisplay}
        // className={classes.errorIcon}
        <div>
            <div>
                <Typography color={'error'}>
                    <b>Error</b>
                </Typography>
            </div>
            <div>
                <ErrorIcon color={'error'} />
            </div>
            <div>
                <Typography color={'error'}>{message}</Typography>
            </div>
        </div>
    );
};

const getKeyValue = function <T, U extends keyof T>(obj: T, key: U) {
    return obj[key];
};

type EntityTableProps<T> = {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    classes: any;
    entities: Array<T>;
    noLinkedText: string;
    cols: Array<{
        accessor: string;
        header: string;
        customFormatFunc?: (val: string | number | Date) => string;
    }>;
    onClick?: (entity: T) => void;
    currentEntityID: string;
};
const EntityTable = ({
    entities,
    cols,
    noLinkedText,
    onClick,
    currentEntityID,
    classes,
}: EntityTableProps<Record<string, string | number | Date>>): ReactElement => {
    if (entities.length === 0) {
        return <div className={classes.noEntriesText}>{noLinkedText}</div>;
    }

    return (
        <MUITable size="small">
            <TableBody>
                <TableRow>
                    {cols.map((col) => {
                        return (
                            <TableCell classes={{ root: cx(classes.cellBorder, classes.fontBold) }} key={col.accessor}>
                                {col.header}
                            </TableCell>
                        );
                    })}
                    <TableCell classes={{ root: classes.cellBorder }} />
                </TableRow>
                {(entities || []).map((entry, idx) => {
                    const isCurrent = currentEntityID === entry.id;
                    return (
                        <TableRow
                            style={{
                                opacity: isCurrent ? 1 : 0.6,
                            }}
                            key={idx}
                        >
                            {cols.map((col) => {
                                let val = col.accessor in entry && entry[col.accessor];
                                val = col.customFormatFunc ? col.customFormatFunc(val as string | number | Date) : val;
                                return (
                                    <TableCell
                                        classes={{ root: cx({ [classes.accentColor]: isCurrent }, classes.cellBorder) }}
                                        key={col.accessor}
                                    >
                                        {val.toString()}
                                    </TableCell>
                                );
                            })}
                            {!isCurrent ? (
                                <TableCell classes={{ root: cx(classes.cellBorder, classes.iconPadding) }}>
                                    <Tooltip title={'View Details'}>
                                        <IconButton
                                            key={'view'}
                                            onClick={() => {
                                                onClick && onClick(entry);
                                            }}
                                            style={{
                                                padding: '0px',
                                            }}
                                        >
                                            <RemoveRedEye fontSize={'small'} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            ) : (
                                <TableCell classes={{ root: cx(classes.cellBorder, classes.iconPadding) }} />
                            )}
                        </TableRow>
                    );
                })}
            </TableBody>
        </MUITable>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    fullWidth: {
        width: '100%',
    },
    iconPadding: {
        paddingLeft: '0px',
    },
    fontBold: {
        fontWeight: 'bold',
    },
    cellBorder: {
        borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.6)}`,
        padding: theme.spacing(1),
    },
    accentColor: {
        color: theme.palette.primary.main,
    },
    noEntriesText: {
        fontSize: '14px',
        color: theme.palette.text.disabled,
        padding: theme.spacing(2),
    },
    whiteTextField: {
        '& .Mui-disabled': {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    redTextField: {
        '& .Mui-disabled': {
            color: theme.palette.error.main,
            opacity: 1,
        },
    },
    accentTextField: {
        '& .Mui-disabled': {
            color: theme.palette.primary.main,
            opacity: 1,
        },
    },
    importTextField: {
        '& .Mui-disabled': {
            color: theme.palette.custom.import.main,
            opacity: 1,
        },
    },
    exportTextField: {
        '& .Mui-disabled': {
            color: theme.palette.custom.export.main,
            opacity: 1,
        },
    },
    formField: {
        height: '60px',
    },
    dialogPaper: {
        backgroundColor: theme.palette.background.default,
        overflow: 'hidden',
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    rootOverride: {
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr',
        overflow: 'hidden',
    },
    errorDisplay: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        alignItems: 'center',
        justifyItems: 'center',
        margin: '15px',
    },
    errorIcon: {
        fontSize: 80,
    },
    detailLayout: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        paddingTop: theme.spacing(),
        gridColumnGap: '8px',
        overflowY: 'auto',
        height: 'calc(100vh - 100px)',
    },
    tradeDetailLayout: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.75)',
        display: 'grid',
        gridTemplateRows: '48px 1fr',
    },
    fecBreakdownDetailLayout: {
        background: theme.palette.custom.paperExtended.paper5,
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.75)',
        display: 'grid',
        gridTemplateRows: '48px 1fr',
        overflowX: 'hidden',
        minWidth: '620px',
        padding: '0px',
    },
    tradeDetailTitleLayout: {
        paddingLeft: '13px',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
    },
    tradeDetailTitleLayout2: {
        paddingLeft: '16px',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
    },
    tradeDetailTitle: {
        fontSize: '17px',
        lineHeight: '17px',
        fontWeight: 'bold',
    },
    tradeDetailTitleActions: {
        justifySelf: 'end',
        display: 'flex',
        flexDirection: 'row',
    },
    tradeDetailFormLayout: {
        overflowX: 'hidden',
        background: theme.palette.custom.paperExtended.paper5,
    },
    tradeDetailFormSection2: {
        padding: theme.spacing(),
        paddingLeft: '16px',
        paddingTop: '16px',
        background: theme.palette.custom.paperExtended.paper5,
    },
    tradeDetailFormSection: {
        background: theme.palette.custom.paperExtended.paper5,
        paddingBottom: theme.spacing(2),
    },
    tradeDetailSectionLabel: {
        height: '39px',
        backgroundColor: `${HexToRGBA(theme.palette.custom.paperExtended.paper2, 1)}`,
        display: 'grid',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        fontSize: '14px',
        lineHeight: '14px',
        fontWeight: 500,
    },
    tradeDetailSectionLabel2: {
        backgroundColor: `${HexToRGBA(theme.palette.custom.paperExtended.paper2, 1)}`,
        padding: theme.spacing(),
        paddingLeft: '16px',
        paddingTop: '16px',
    },
    inputLabel: {
        color: theme.palette.text.disabled,
    },
    focusedInputLabel: {
        color: theme.palette.primary.light + '!important',
    },
    line: {
        '&:before': {
            borderBottom: `1px solid  ${HexToRGBA(theme.palette.text.primary, '0.13')}`,
        },
        '&:after': {
            borderBottom: `2px solid  ${theme.palette.primary.light}`,
        },
    },
    numberList: {
        width: '400px',
        height: '10px',
    },
    validationVerifiedWrapper: {
        display: 'flex',
        height: '24px',
        width: 'relative',
        backgroundColor: theme.palette.success.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    validationUnmatchedWrapper: {
        display: 'flex',
        height: '24px',
        width: 'relative',
        backgroundColor: theme.palette.warning.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    validationErrorWrapper: {
        display: 'flex',
        height: '24px',
        width: 'relative',
        backgroundColor: theme.palette.error.main,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
}));

export default OptionDetailDialog;
