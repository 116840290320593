import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { useServiceSync } from 'hooks/useService';
import Generator, {
    GeneratePreviousFinYearRevenueRequest,
    GeneratePreviousFinYearRevenueResponse,
} from 'popcorn-js/report';
import { COLOR, FONTSIZE, HeaderField } from 'components/HeaderField/HeaderField';
import { ScaleLoader as Spinner } from 'react-spinners';
import { displayAmount } from 'utils';

const PreviousFinancialYearCard = (): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();

    const [response, setResponse] = useState<GeneratePreviousFinYearRevenueResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();

    const [generate] = useServiceSync<GeneratePreviousFinYearRevenueRequest, GeneratePreviousFinYearRevenueResponse>(
        Generator.GeneratePreviousFinYearRevenueReport,
    );

    useEffect(() => {
        generate({})
            .then(setResponse)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div>
                <div className={classes.loadingLayout}>
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <div className={classes.loadingLayout}>
                    <div className={classes.errorText}>Failed to load report</div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.previousFinancialYear}>
            <HeaderField
                value={`Previous Financial Year: R${displayAmount(response?.total)}`}
                color={COLOR.YELLOW}
                fontSize={FONTSIZE.SET}
            />
        </div>
    );
};
const useStyles = makeStyles((theme: CustomTheme) => ({
    previousFinancialYear: {
        marginLeft: '15px',
    },
    loadingLayout: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));
export default PreviousFinancialYearCard;
