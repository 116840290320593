import { httpRequest } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { Claims } from 'popcorn-js/security';

export type SwitchContextRequest = {
    partyToSwitchTo: string;
};

export type SwitchContextResponse = {
    claims: Claims;
};

export interface ContextSwitcher {
    switchContext(request: SwitchContextRequest): Promise<SwitchContextResponse>;
}

export const DefaultContextSwitcher = {
    switchContext(request: SwitchContextRequest): Promise<SwitchContextResponse> {
        return httpRequest<SwitchContextResponse>({
            url: `${config.get('url')}/switch-context`,
            request,
        });
    },
};
