import React, { SyntheticEvent } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { BaseButtonGroup } from 'components/BaseButton';
import { BaseButton, VARIANT, SIZE, COLOR } from 'components/BaseButton';
import { ActionsMenu, Item } from 'components/ActionsMenu/ActionsMenu';
interface SplitButtonProps {
    id: string;
    disableDropdown: boolean;
    disabled: boolean;
    initialOption: string;
    menuOptions: Array<string>;
    onSelect: (arg: string) => void;
}

const SplitButton: React.FC<SplitButtonProps> = (props: SplitButtonProps) => {
    const [anchorRef, setAnchorRef] = React.useState<HTMLElement | undefined>();
    const [selectedIndex, setSelectedIndex] = React.useState(
        props.menuOptions.findIndex((option) => option === props.initialOption),
    );

    const handleMenuItemClick = (index: number) => {
        setSelectedIndex(index);
        props.onSelect(props.menuOptions[index]);
    };

    const handleToggle = (event?: SyntheticEvent<HTMLButtonElement>) => {
        setAnchorRef(event?.currentTarget || undefined);
    };

    const menuItems: Item[] = props.menuOptions.map((option: string, index: number) => ({
        id: `${index}`,
        text: option,
        onClick: () => handleMenuItemClick(index),
    }));

    return (
        <div>
            <BaseButtonGroup>
                <BaseButton
                    id={props.id}
                    variant={VARIANT.CONTAINED}
                    color={COLOR.ACTION}
                    size={SIZE.SMALL}
                    text={props.menuOptions[selectedIndex]}
                />
                <BaseButton
                    id={`${props.id}-Dropdown`}
                    disabled={props.disableDropdown}
                    variant={VARIANT.CONTAINED}
                    color={COLOR.ACTION}
                    size={SIZE.SMALL}
                    onClick={handleToggle}
                    text={<ArrowDropDownIcon style={{ padding: 0 }} fontSize={'small'} />}
                />
            </BaseButtonGroup>
            <ActionsMenu
                id={props.id}
                anchorElement={anchorRef}
                onClose={() => setAnchorRef(undefined)}
                items={menuItems}
            />
        </div>
    );
};

export default SplitButton;
