import React, { ReactElement } from 'react';
import { useTheme } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';

export function BuildDropDownFromArray(
    idx: number | string,
    array: unknown[],
    fieldsToIgnore?: string[],
): ReactElement | undefined {
    let i = 0;
    const getKey = () => {
        i++;
        return `${idx}.${i}`;
    };
    if (isArray(array)) {
        return (
            <ul style={{ padding: '0' }}>
                {array.map((item: unknown) => {
                    if (isObject(item)) {
                        return BuildDropdownFromObject(getKey(), item as Record<string, unknown>, fieldsToIgnore);
                    }
                    if (isArray(item)) {
                        return BuildDropDownFromArray(getKey(), item as unknown[]);
                    }
                    return <li key={getKey()}>{item as string | number}</li>;
                })}
            </ul>
        );
    }
}

export function BuildDropdownFromObject(
    idx: string,
    object: Record<string, unknown>,
    fieldsToIgnore?: string[],
): ReactElement | undefined {
    let i = 0;
    const getKey = () => {
        i++;
        return `${idx}.${i}`;
    };
    if (isObject(object)) {
        const items = [];
        for (const key in object) {
            if (fieldsToIgnore?.includes(key)) {
                continue;
            }
            if (isObject(object[key])) {
                items.push(
                    <li key={getKey()}>{BuildDropdownFromObject(idx, object[key] as Record<string, unknown>)}</li>,
                );
            }
            if (isArray(object[key])) {
                items.push(
                    <li key={getKey()}>
                        <span>{toWords(key)}</span>
                        <span>{BuildDropDownFromArray(getKey(), object[key] as unknown[])}</span>
                    </li>,
                );
            }
            if (isValue(object[key])) {
                items.push(<li key={getKey()}>{`${toWords(key)} - ${object[key]}`}</li>);
            }
        }
        const theme = useTheme<CustomTheme>();
        return (
            <ul
                key={getKey()}
                style={{
                    border: '1px solid',
                    borderColor: theme.palette.action.disabled,
                    margin: '5px',
                }}
            >
                {items.map((t) => t)}
            </ul>
        );
    }
}

export function toWords(text: string): string {
    return text
        .replace(/([A-Z]+)/g, ' $1')
        .replace(/([A-Z][a-z])/g, ' $1')
        .replace(/^./, (match) => match.toUpperCase());
}

export function compare(obj1: Record<string, unknown>, obj2: Record<string, unknown>, nestedName?: string): string[] {
    if (!obj1 || !obj2) {
        return [];
    }
    if (Object.keys(obj1).length === 0 || Object.keys(obj2).length === 0) {
        return [];
    }
    let differences: string[] = [];

    for (const key in obj1) {
        // if either of the fields are undefined/null, and the other is not
        // it should be marked as a difference
        if ((!!obj1[key] && !obj2[key]) || (!obj1[key] && !!obj2[key])) {
            differences.push(nestedName ? nestedName + '.' + key : key);
        }
        if (isValue(obj1[key]) && isValue(obj2[key])) {
            if (obj1[key] !== obj2[key]) {
                differences.push(nestedName ? nestedName + '.' + key : key);
            }
        }
        if (isObject(obj1[key]) && isObject(obj2[key])) {
            const response = compare(
                obj1[key] as Record<string, unknown>,
                obj2[key] as Record<string, unknown>,
                nestedName ? nestedName + '.' + key : key,
            );
            differences = differences.concat(response);
        }
        if (isArray(obj1[key]) && isArray(obj2[key])) {
            if (!CompareArray(obj1[key] as unknown[], obj2[key] as unknown[])) {
                differences.push(nestedName ? nestedName + '.' + key : key);
            }
        }
    }
    return differences;
}

/**
 * @return {boolean}
 */
export function CompareArray(array1?: unknown[], array2?: unknown[]): boolean {
    if (array1 === undefined || array2 === undefined) {
        return false;
    }
    if (array1.length !== array2.length) {
        return false;
    }
    if ((array1.length > 1 && isValue(array1[0])) || (array2.length > 1 && isValue(array2[0]))) {
        return !(
            array1.some((item) => !array2.find((item2) => item === item2)) ||
            array2.some((item2) => !array1.find((item1) => item1 === item2))
        );
    }
    // TODO compare nested objects
    return true;
}

export function isArray(obj: unknown): boolean {
    return {}.toString.apply(obj) === '[object Array]';
}

export function isObject(obj: unknown): boolean {
    return {}.toString.apply(obj) === '[object Object]';
}

export function isValue(obj: unknown): boolean {
    return !isObject(obj) && !isArray(obj);
}
