import React, { ReactElement } from 'react';
import { FieldType, Header, ItemEntry } from 'components/history';
import { Divider } from '@material-ui/core';
import { processUnixDateForViewing } from 'utils';
import { SystemDateFormat, SystemDateTimeFormat } from 'constants/formats';
import { InvoiceH } from './History';
import moment from 'moment';

const styles = {
    root: {
        display: 'grid',
        flexGrow: 1,
        rowGap: '2px',
        boxShadow: '',
        // paddingTop: '25px',
        zIndex: 1,
        gridTemplateRows: (() => {
            let space = '';
            for (let i = 0; i < 20; i++) {
                space = space + '32px ';
            }
            return space;
        })(),
    },
    divider: {
        height: '2px',
        margin: '20px 20px',
    },
    subDivider: {
        height: '1px',
        margin: '16px 40px',
    },
};

export const InvoiceFields = (
    invoice: InvoiceH,
    fieldType: FieldType,
    changedFields = [] as string[],
): ReactElement => {
    return (
        <div>
            <main style={styles.root}>
                <Header fieldType={fieldType} label={'Invoice Information'} />
                {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
                <ItemEntry
                    changed={changedFields.includes('number')}
                    fieldType={fieldType}
                    label={'Number'}
                    value={invoice.number || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('counterpartyId')}
                    fieldType={fieldType}
                    label={'Counterparty'}
                    value={invoice.counterpartyName || invoice.counterpartyId || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('originalAmountDue')}
                    fieldType={fieldType}
                    label={'Original Amount Due'}
                    value={invoice.originalAmountDue}
                />
                <ItemEntry
                    changed={changedFields.includes('balanceDue')}
                    fieldType={fieldType}
                    label={'Balance Due'}
                    value={invoice.balanceDue}
                />
                <ItemEntry
                    changed={changedFields.includes('currency')}
                    fieldType={fieldType}
                    label={'Currency'}
                    value={invoice.currencyIsoCode || invoice.currency || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('costCurrency')}
                    fieldType={fieldType}
                    label={'Cost Currency'}
                    value={invoice.costCurrencyIsoCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('costingRate')}
                    fieldType={fieldType}
                    label={'Costing Rate'}
                    value={invoice.costingRate}
                />
                <ItemEntry
                    changed={changedFields.includes('dueDate')}
                    fieldType={fieldType}
                    label={'Due Date'}
                    value={processUnixDateForViewing(invoice.dueDate, SystemDateFormat)}
                />
                <ItemEntry
                    changed={changedFields.includes('shippingDate')}
                    fieldType={fieldType}
                    label={'Shipping Date'}
                    value={processUnixDateForViewing(invoice.shippingDate, SystemDateFormat)}
                />
                <ItemEntry
                    changed={changedFields.includes('shipmentReference')}
                    fieldType={fieldType}
                    label={'Shipment Reference'}
                    value={invoice.shipmentReference}
                />
                <ItemEntry
                    changed={changedFields.includes('notes')}
                    fieldType={fieldType}
                    label={'Notes'}
                    value={invoice.notes || '-'}
                />
                <ItemEntry
                    changed={changedFields.includes('status')}
                    fieldType={fieldType}
                    label={'Status'}
                    value={invoice.status || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('partyCode')}
                    fieldType={fieldType}
                    label={'Party Code'}
                    value={invoice.partyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('effectiveRate')}
                    fieldType={fieldType}
                    label={'Effective Rate'}
                    value={invoice.effectiveRate}
                />
                <ItemEntry
                    changed={changedFields.includes('issueDate')}
                    fieldType={fieldType}
                    label={'Issue Date'}
                    value={processUnixDateForViewing(invoice.issueDate, SystemDateFormat)}
                />
                <ItemEntry
                    changed={changedFields.includes('balanceCaptureRate')}
                    fieldType={fieldType}
                    label={'Balance Capture Rate'}
                    value={invoice.balanceCaptureRate}
                />
                <ItemEntry
                    changed={changedFields.includes('captureRate')}
                    fieldType={fieldType}
                    label={'Capture Rate'}
                    value={invoice.captureRate}
                />
                <ItemEntry
                    changed={changedFields.includes('originalCaptureRate')}
                    fieldType={fieldType}
                    label={'Original Capture Rate'}
                    value={invoice.originalCaptureRate}
                />
                <ItemEntry
                    changed={changedFields.includes('captureSpotRate')}
                    fieldType={fieldType}
                    label={'Capture Spot Rate'}
                    value={invoice.captureSpotRate}
                />
                <ItemEntry
                    changed={changedFields.includes('captureDate')}
                    fieldType={fieldType}
                    label={'Capture Date'}
                    value={moment(invoice.captureDate).format(SystemDateFormat)}
                />
                <ItemEntry
                    changed={changedFields.includes('direction')}
                    fieldType={fieldType}
                    label={'Direction'}
                    value={invoice.direction || '-'}
                />
                <ItemEntry
                    changed={changedFields.includes('paidAmount')}
                    fieldType={fieldType}
                    label={'Paid Amount'}
                    value={invoice.paidAmount || '-'}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Audit Entry'} />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(invoice.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(invoice.auditEntry || {}).action || '-'}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={
                        (invoice.auditEntry || {}).time
                            ? processUnixDateForViewing((invoice.auditEntry || {}).time, SystemDateTimeFormat)
                            : ''
                    }
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(invoice.auditEntry || {}).version || 0}
                />
            </main>
        </div>
    );
};
