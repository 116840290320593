import * as React from 'react';
import { ReactElement } from 'react';
import { makeStyles, Tooltip as MUITooltip, withStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { Help as HelpIcon } from '@material-ui/icons';

const useHedgeCardStyles = makeStyles((theme: CustomTheme) => ({
    helpIcon: {
        width: '12px',
        height: '12px',
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.text.primary,
        },
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
    },
}));
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(MUITooltip);

export const HeaderWithToolTip = ({
    helperText,
    headerText,
    atStart,
}: {
    helperText?: string | ReactElement;
    headerText?: string;
    atStart?: boolean;
}): ReactElement => {
    const classes = useHedgeCardStyles();

    return (
        <div style={{ display: 'flex' }}>
            {helperText && atStart && (
                <>
                    <LightTooltip placement={'right-end'} title={helperText}>
                        <HelpIcon className={classes.helpIcon} />
                    </LightTooltip>
                    <p style={{ width: '5px' }} />
                </>
            )}
            {headerText}
            {helperText && !atStart && (
                <>
                    <p style={{ width: '5px' }} />
                    <LightTooltip placement={'right-end'} title={helperText}>
                        <HelpIcon className={classes.helpIcon} />
                    </LightTooltip>
                </>
            )}
        </div>
    );
};
