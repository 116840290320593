import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { Client } from 'popcorn-js/party';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { displayAmount, objectCopy } from 'utils';
import { TransactionFees } from './TransactionFees';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';

export const Pricing = (props: {
    client: Client;
    state: string;
    onChange: (field: string, value: unknown) => void;
    handleAdd: () => void;
    handleUpdate: () => void;
}): ReactElement => {
    const { client, state, handleAdd, onChange, handleUpdate } = props;
    const classes = useStyles();
    const [saved, setSaved] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const handleTransactionFeeChange = (field: string, newValue: unknown, index: number) => {
        const fields = field.split('.');
        const _client = objectCopy(client);
        const _transactionFee = objectCopy(_client.transactionFees[index]);
        if (fields[1] != undefined) {
            _transactionFee[fields[0]][fields[1]] = newValue;
        } else {
            _transactionFee[field] = newValue;
        }
        _client.transactionFees[index] = _transactionFee;
        onChange('transactionFees', _client.transactionFees);
    };

    const handleRemoveTransactionFee = (index: number) => {
        const _client = objectCopy(client);
        const _transactionFee = objectCopy(_client.transactionFees);
        _transactionFee.splice(index, 1);
        _client.transactionFees = _transactionFee;
        onChange('transactionFees', _client.transactionFees);
    };

    const checkEmptyFields = () => {
        let update = true;
        if (client.transactionFees) {
            for (const transactionFee of client.transactionFees) {
                if (transactionFee.bank == '') {
                    setErrorMessage('No Bank selected');
                    update = false;
                    break;
                }
            }
        }
        if (update) {
            handleUpdate();
            setSaved(true);
        }
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
    };

    useEffect(() => {
        setSaved(false);
    }, [saved]);

    return (
        <div className={classes.root}>
            <div className={classes.monthlyFees}>
                <div className={classes.saveButton}>
                    <BaseButton
                        id={'updateFee'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        onClick={() => {
                            checkEmptyFields();
                        }}
                        text={'Record Pricing Information'}
                        disabled={state == 'ACTIVE_STATE_VIEWING'}
                    />
                </div>
                <div>
                    <div className={classes.header}> Monthly Fees </div>
                    <BaseNumberField
                        id={'monthlyFees'}
                        label="Monthly Fee"
                        disabled={false}
                        prefix={'ZAR'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange('monthlyFee', Number(e.target.value));
                        }}
                        value={displayAmount((client as Client)?.monthlyFee) || ''}
                    />
                </div>
            </div>
            <div className={classes.transactionFees}>
                <div className={classes.header}> Transaction Fees </div>
                {(client?.transactionFees || []).map((transactionFee, i) => (
                    <div key={i}>
                        <TransactionFees
                            transactionFee={transactionFee}
                            transactionFees={client?.transactionFees || []}
                            index={i}
                            onChange={handleTransactionFeeChange}
                            handleRemove={handleRemoveTransactionFee}
                            saved={saved}
                        />
                    </div>
                ))}
                <BaseButton
                    id={'addBank'}
                    variant={VARIANT.OUTLINED}
                    color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    onClick={handleAdd}
                    text={'Add Bank'}
                    disabled={
                        client.transactionFees &&
                        client.transactionFees.length > 0 &&
                        client?.transactionFees[client.transactionFees.length - 1].bank == ''
                    }
                />
            </div>
            <NotificationSweetAlert onClose={handleHideAlert} errorMessage={errorMessage} autoFormat={false} />
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        margin: '0px 24px 0px 24px',
        height: '100%',
        width: '100%',
    },
    monthlyFees: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        borderBottom: '0.5px solid',
        borderBottomColor: theme.palette.divider,
        paddingBottom: '34px',
    },
    header: {
        margin: '24px 0px 24px 0px ',
        fontSize: '18px',
    },
    saveButton: {
        marginTop: '16px',
    },
    transactionFees: {
        width: '100%',
    },
}));
