import { ProcessingOrgFields } from 'components/party/ProcessingOrgFields';
import { PartyType } from 'popcorn-js/party';
import React, { ReactElement } from 'react';
import { PartyConfiguration } from './PartyConfiguration';

export const ProcessingOrgConfiguration = (): ReactElement => {
    return (
        <PartyConfiguration
            title={'Processing Orgs'}
            partyType={PartyType.PROCESSING_ORG}
            fieldsElement={ProcessingOrgFields}
        />
    );
};
