/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { StandardCard } from '../Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION, STATES } from '../CardHeader/StandardCardHeader';
import { CircularProgress, Collapse, Dialog, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Counterparty } from 'popcorn-js/counterparty';
import { LinkedInvoice, Order } from 'popcorn-js/order';
import { StyledAsyncSelect } from '../Select/StyledAsyncSelect';
import { Close as CancelIcon, Link as LinkIcon, Save as SaveIcon } from '@material-ui/icons';
import { Criteria, CriteriaType } from 'popcorn-js/search';
import {
    Recordkeeper as InvoiceRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'popcorn-js/invoice/recordkeeper';
import { FindRequest, FindResponse } from 'popcorn-js/index';
import { AppContext, AppContextT } from 'context';
import { Linker as OrderLinker, LinkToInvoiceRequest, LinkToInvoiceResponse } from 'popcorn-js/order/linker';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Invoice } from 'popcorn-js/invoice';
import Table from '../Table/Table';
import { displayRate, HexToRGBA } from 'utils';
import InvoiceDetailDialogV2 from '../invoice/InvoiceDetailDialogV2';
import { displayAmount } from 'views/Client/util';
import { CustomTheme } from 'theme/custom';
import { useServiceSync } from 'hooks/useService';
import moment from 'moment';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';

const LinkedInvoices = (props: {
    counterparties: Counterparty[];
    onSaveSuccess: () => void;
    order: Order;
    readOnly?: boolean;
    show: boolean;
}): ReactElement => {
    const { onSaveSuccess, counterparties } = props;

    const [order, setOrder] = useState<Order>(props.order);

    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [open, setOpen] = useState<boolean>(true);
    const [createLink, setCreateLink] = useState(false);
    const [orderInvoice, setOrderInvoice] = useState<Invoice>({} as Invoice);
    const [defaultInvoiceOptions, setDefaultInvoiceOptions] = useState<{ value: string; label: string }[]>([]);
    const [invoiceIdToLinkOption, setInvoiceIdToLinkOption] = useState<{ value: string; label: string }>(
        {} as { value: string; label: string },
    );
    const [showInvoiceDetail, setShowInvoiceDetail] = useState<boolean>(false);
    const [selected, setSelected] = useState<LinkedInvoice | undefined>();
    const [displayLinkedInvoices, setDisplayLinkedInvoices] = useState<DisplayLinkedInvoice[] | undefined>();

    const [invoiceFind] = useServiceSync<FindRequest, FindResponse<Invoice>>(InvoiceRecordkeeper.find);
    const [invoiceRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(InvoiceRecordkeeper.retrieve);
    const [linkToInvoice] = useServiceSync<LinkToInvoiceRequest, LinkToInvoiceResponse>(OrderLinker.linkToInvoice);
    const [unlinkFromInvoice] = useServiceSync<LinkToInvoiceRequest, LinkToInvoiceResponse>(
        OrderLinker.unlinkFromInvoice,
    );

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<undefined | (() => void)>(undefined);

    const generateInvoiceOptions = async (inputValue: string): Promise<{ value: string; label: string }[]> => {
        const criteria: Criteria = [
            {
                type: CriteriaType.TextCriterion,
                text: inputValue,
                field: 'externalReference',
            },
            {
                type: CriteriaType.ExactCriterion,
                text: appContext.party?.partyCode || '',
                field: 'partyCode',
            },
            {
                type: CriteriaType.ExactCriterion,
                field: 'counterpartyId',
                text: order.counterpartyId,
            },
            {
                type: CriteriaType.ExactCriterion,
                field: 'currency',
                text: order.currency,
            },
            {
                type: CriteriaType.ExactCriterion,
                field: 'costCurrency',
                text: order.costCurrency,
            },
        ];
        const query = {
            offset: 0,
            limit: 20,
        };

        try {
            const findResponse = await invoiceFind({ criteria, query });
            return (findResponse.records || []).map((b: any) => ({
                value: b.id,
                label: b.externalReference,
            }));
        } catch (e) {
            throw e.message || e;
        }
    };

    const assignOrderInvoice = async (linkedInvoice: LinkedInvoice | undefined) => {
        setLoading(true);
        if (linkedInvoice === undefined) {
            // order invoice not set
            return;
        }

        if (linkedInvoice && orderInvoice.id === linkedInvoice.invoiceId) {
            // order invoice hasn't changed
            return;
        }

        try {
            const retrieveResponse = await invoiceRetrieve({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: linkedInvoice.invoiceId },
            });
            setOrderInvoice(retrieveResponse.invoice);
        } catch (e) {
            console.error('Could not retrieve invoice: ', e.message || e);
            setLoading(false);
        }
        setLoading(false);
    };
    const initiateLinking = async () => {
        setCreateLink(true);
        const result = await generateInvoiceOptions('');
        setDefaultInvoiceOptions(result);
    };
    const cancelLinking = () => {
        setInvoiceIdToLinkOption({} as { value: string; label: string });
        setCreateLink(false);
    };
    const saveLink = async () => {
        setLoading(true);
        if (order.id) {
            try {
                const linkResponse = await linkToInvoice({
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id },
                    invoiceId: invoiceIdToLinkOption.value,
                });
                cancelLinking();
                await assignOrderInvoice(undefined);
                setOrder(linkResponse.order);
                setSuccessMessage('successfully linked invoice');
                onSaveSuccess();
            } catch (e) {
                setErrorMessage(e.message || e);
            }
        }
        setLoading(false);
    };
    const ChooseInvoice = (): ReactElement => {
        return (
            <div className={classes.chooseInvoiceWrapper}>
                {createLink && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '150px' }}>
                            <StyledAsyncSelect
                                defaultOptions={defaultInvoiceOptions}
                                loadOptions={generateInvoiceOptions}
                                onChange={(selected: any) => setInvoiceIdToLinkOption(selected)}
                                value={{
                                    value: invoiceIdToLinkOption.value,
                                    label: invoiceIdToLinkOption.label,
                                }}
                                noOptionsMessage={'Start Typing...'}
                                disableCreate
                            />
                        </div>
                        <Tooltip title="Save">
                            <IconButton size={'small'} onClick={saveLink}>
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                            <IconButton size={'small'} onClick={cancelLinking}>
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                {!createLink && (
                    <Tooltip title={'Link Invoice'}>
                        <IconButton size={'small'} onClick={initiateLinking}>
                            <LinkIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        );
    };
    const renderDialogs = () => {
        return (
            <React.Fragment>
                {showInvoiceDetail && (
                    <InvoiceDetailDialogV2
                        counterparties={counterparties || []}
                        invoice={{ ...orderInvoice }}
                        onClose={() => setShowInvoiceDetail(false)}
                        readOnly
                        show
                    />
                )}
            </React.Fragment>
        );
    };
    const calculateAverage = (linkedInvoice: LinkedInvoice[] | undefined) => {
        const _sum = linkedInvoice
            ? linkedInvoice?.reduce((total, current) => (total += current.effectiveRate ? current.effectiveRate : 0), 0)
            : 0;
        const _count = linkedInvoice ? linkedInvoice?.length : 1;
        const _average = _sum / _count;
        if (_average >= 0) {
            return _average.toFixed(4);
        } else {
            return '-';
        }
    };

    useEffect(() => {
        const _displayLinkedInvoices = order.linkedInvoices?.map(
            (linkedInvoice: LinkedInvoice): DisplayLinkedInvoice => ({
                id: linkedInvoice.externalReference,
                ...linkedInvoice,
            }),
        );
        setDisplayLinkedInvoices(_displayLinkedInvoices);
    }, [order]);

    const handleUnlink = async () => {
        setLoading(true);
        if (order.id) {
            try {
                const linkResponse = await unlinkFromInvoice({
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id },
                    invoiceId: selected?.invoiceId,
                });
                cancelLinking();
                setOrder(linkResponse.order);
                onSaveSuccess();
                setSelected(undefined);
                setSuccessMessage('successfully unlinked invoice');
            } catch (e) {
                setErrorMessage(e.message || e);
            }
        }
        setLoading(false);
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    return (
        <React.Fragment>
            <StandardCard
                cardHeaderProps={{
                    tailoredState: !!selected ? STATES.SELECTED_ROW : undefined,
                    squareEdge: true,
                    itemsLeft: [
                        {
                            id: 'LinkedInvoices/title',
                            type: ITEM_VARIATION.TITLE,
                            text: 'Linked Invoices',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'LinkedInvoices/view',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View details',
                            onClick: () => {
                                assignOrderInvoice(selected).then(() => setShowInvoiceDetail(true));
                            },
                            hide: !selected || !open,
                        },
                        {
                            id: 'OrderInformation/link',
                            type: ITEM_VARIATION.ELEMENT,
                            element: <ChooseInvoice />,
                            hide: !open || !!selected,
                        },
                        {
                            id: 'OrderInformation/unlink',
                            type: ITEM_VARIATION.ICON_BUTTON,
                            icon: ACTION_BUTTON_TYPE.LINK_OFF,
                            helpText: 'Unlink Invoice',
                            onClick: handleUnlink,
                            hide: !open || !selected,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/collapse',
                            icon: ACTION_BUTTON_TYPE.COLLAPSE,
                            helpText: 'Collapse',
                            onClick: () => setOpen(false),
                            hide: !open || !!selected,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'PortfolioCard/expand',
                            icon: ACTION_BUTTON_TYPE.EXPAND,
                            helpText: 'Expand',
                            onClick: () => setOpen(true),
                            hide: open || !!selected,
                        },
                    ],
                }}
            >
                <Collapse in={open}>
                    <Table
                        showCheckboxes
                        columns={[
                            {
                                title: 'Date',
                                field: 'date',
                                render: (linkedInvoice: LinkedInvoice) =>
                                    moment(linkedInvoice.date).format('yyyy/MM/DD'),
                            },
                            {
                                title: 'Amount',
                                field: 'fxAmount',
                                render: (linkedInvoice: LinkedInvoice) =>
                                    displayAmount(order.currency, linkedInvoice.fxAmount, 'code', 2),
                            },
                            {
                                title: 'Ext. Reference',
                                field: 'externalReference',
                                render: (linkedInvoice: LinkedInvoice) => linkedInvoice.externalReference,
                            },
                            {
                                title: 'Number',
                                field: 'number',
                            },
                            {
                                title: 'Eff. Rate',
                                field: 'effectiveRate',
                                render: (linkedInvoice: LinkedInvoice) => displayRate(linkedInvoice.effectiveRate),
                            },
                        ]}
                        data={displayLinkedInvoices}
                        selected={selected ? [selected] : []}
                        onSelectAll={() => (selected ? setSelected(undefined) : undefined)}
                        onRowCheck={(linkedInvoice: DisplayLinkedInvoice) =>
                            linkedInvoice.externalReference === selected?.externalReference
                                ? setSelected(undefined)
                                : setSelected(linkedInvoice)
                        }
                        rowClickAction={(linkedInvoice: DisplayLinkedInvoice) => {
                            linkedInvoice.externalReference === selected?.externalReference
                                ? setSelected(undefined)
                                : setSelected(linkedInvoice);
                        }}
                        rowDoubleClickAction={(linkedInvoice: DisplayLinkedInvoice) => {
                            assignOrderInvoice(linkedInvoice).then(() => setShowInvoiceDetail(true));
                        }}
                        disableFooter
                    />
                    <div className={classes.footerWrapper}>
                        Invoiced Amount:
                        <span className={classes.invoicedTotalWrapper}>
                            {displayAmount(order.currency, order.invoicedAmount, 'code', 2)}
                        </span>
                        Average Effective Rate:
                        <span className={classes.averageEffRateWrapper}>{calculateAverage(order.linkedInvoices)}</span>
                    </div>
                    {renderDialogs()}
                </Collapse>
                <NotificationSweetAlert
                    onClose={handleHideAlert}
                    errorMessage={errorMessage}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
            </StandardCard>
            {loading && (
                <Dialog
                    BackdropProps={{
                        classes: { root: classes.progressSpinnerDialogBackdrop },
                    }}
                    PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                    className={classes.loading}
                    open={loading}
                >
                    <CircularProgress className={classes.progress} />
                </Dialog>
            )}
        </React.Fragment>
    );
};

type DisplayLinkedInvoice = {
    id?: string;
} & LinkedInvoice;

const useStyles = makeStyles((theme: CustomTheme) => ({
    chooseInvoiceWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 10,
    },
    footerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid rgba(255,255,255)',
        borderBottom: '1px solid rgba(255,255,255,.25)',
        padding: '10px 10px 10px 77px',
        fontSize: '0.875rem',
    },
    invoicedTotalWrapper: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        padding: '0px 225px 0px 5px',
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
    averageEffRateWrapper: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        paddingLeft: '5px',
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    progress: {
        color: theme.palette.text.hint,
    },
    loading: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default LinkedInvoices;
