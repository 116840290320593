import React, { ReactElement } from 'react';
import { CardHeaderProps, StandardCardHeader } from 'components/CardHeader/StandardCardHeader';
import { Card, CardContent } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';

export const StandardCard = (props: {
    cardHeaderProps?: CardHeaderProps;
    children?: ReactElement | ReactElement[];
}): ReactElement => {
    const { cardHeaderProps, children } = props;
    const classes = useStyles();
    return (
        <Card
            classes={{
                root: cx(
                    {
                        [classes.cardRootFullHeight]: cardHeaderProps?.fullHeight,
                        [classes.cardRootSquareEdge]: cardHeaderProps?.squareEdge,
                    },
                    classes.cardRootDefault,
                ),
            }}
        >
            {cardHeaderProps && <StandardCardHeader {...cardHeaderProps} />}
            <CardContent
                classes={{
                    root: classes.contentRoot,
                }}
            >
                {children}
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    contentRoot: {
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        flexGrow: 1,
        overflow: 'auto',
    },
    cardRootDefault: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardRootFullHeight: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    cardRootSquareEdge: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0px',
    },
}));
