/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { isFunction } from 'utils';
import ListItem from './ListItem';
import ListItemHolder from './ListItemHolder';
import { Card, CardContent, Grid } from '@material-ui/core';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: any) => ({
    root: {
        padding: 10,
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
    },
    list: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        justifyItems: 'center',
    },
    listTitle: {
        margin: 5,
        alignSelf: 'center',
        fontSize: 16,
        fontWeight: 20,
    },
    listItemsWrapper: {
        boxShadow: 'inset 0 0 4px #000000',
        backgroundColor: theme.palette.background.default,
        padding: 10,
    },
    listItemsInnerWrapper: {
        height: 200,
        width: 220,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));

const DnDListAssignSort = (props: {
    unassignedItems: any[];
    assignedItems: any[];
    accessor: any;
    destListTitle: string;
    onChange: (_: { unassignedItems: any[]; assignedItems: any[] }) => void;
    resetToggle: boolean;
    sourceListTitle: string;
}): ReactElement => {
    const { accessor, onChange, sourceListTitle, destListTitle } = props;

    const classes = useStyles();

    const [unassignedItems, setUnassignedItems] = useState<any[]>(props.unassignedItems);
    const [assignedItems, setAssignedItems] = useState<any[]>(props.assignedItems);
    const [prevResetToggle, setPrevResetToggle] = useState<boolean>(props.resetToggle);

    const itemDropped = (droppedItem: any, toAssigned: any, itemHolderIdx: number) => {
        let sourceList;
        let destList;
        if (toAssigned) {
            sourceList = unassignedItems;
            destList = assignedItems;
        } else {
            sourceList = assignedItems;
            destList = unassignedItems;
        }

        // Was the item dropped on the end ListItemHolder?
        const droppedAtEnd = itemHolderIdx === destList.length;

        // Is item already in the destination list?
        let itemInDestList = false;
        let existingItemIdx;
        for (existingItemIdx = 0; existingItemIdx < destList.length; existingItemIdx++) {
            if (destList[existingItemIdx][accessor] === droppedItem[accessor]) {
                itemInDestList = true;
                break;
            }
        }

        // If item is already in destination list and it was not dropped at
        // it's existing position
        // i.e. If (itemHolderIdx === existingItemIdx) then do nothing
        if (itemInDestList && itemHolderIdx !== existingItemIdx) {
            // Remove Existing Item
            if (existingItemIdx === destList.length - 1) {
                destList = destList.slice(0, existingItemIdx);
            } else {
                destList = [...destList.slice(0, existingItemIdx), ...destList.slice(existingItemIdx + 1)];
            }

            // If Item dropped after existing item
            if (itemHolderIdx > existingItemIdx) {
                // Insert at itemHolderIdx - 1
                destList.splice(itemHolderIdx - 1, 0, droppedItem);
            } else {
                // Else Item dropped before existing item
                // Insert at itemHolderIdx
                destList.splice(itemHolderIdx, 0, droppedItem);
            }
        } else {
            // Item not in destination list

            // Remove item from source list
            sourceList = sourceList.filter((item) => item[accessor] !== droppedItem[accessor]);

            // Was item dropped at end?
            if (droppedAtEnd) {
                // If it was dropped at the end, push it to the destination list
                // (i.e add at end)
                destList.push(droppedItem);
            } else {
                // Otherwise it was dropped somewhere in the list,
                // add it at that index
                destList.splice(itemHolderIdx, 0, droppedItem);
            }
        }

        let _assignedItems = [...sourceList];
        let _unassignedItems = [...destList];
        if (toAssigned) {
            _assignedItems = [...destList];
            _unassignedItems = [...sourceList];
        }
        setAssignedItems(_assignedItems);
        setUnassignedItems(_unassignedItems);

        if (isFunction(onChange)) {
            onChange({
                assignedItems: _assignedItems,
                unassignedItems: _unassignedItems,
            });
        } else {
            console.error('onChange prop given to DnDListAssignSort is not a function');
        }
    };

    useEffect(() => {
        if (prevResetToggle !== props.resetToggle) {
            setPrevResetToggle(props.resetToggle);
            setUnassignedItems(props.unassignedItems);
            setAssignedItems(props.assignedItems);
        }
    }, [props.resetToggle, props.unassignedItems, props.assignedItems]);

    return (
        // eslint-disable-next-line
        // @ts-ignore
        <DndProvider backend={HTML5Backend}>
            <div className={classes.root}>
                <Card>
                    <CardContent>
                        <Grid container direction={'row'} spacing={8}>
                            <Grid item>
                                <div className={classes.list}>
                                    <div className={classes.listTitle}>{sourceListTitle}</div>
                                    <div className={classes.listItemsWrapper}>
                                        <div className={classes.listItemsInnerWrapper}>
                                            {unassignedItems.map((item, idx) => {
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <ListItemHolder
                                                            key={1000 + idx}
                                                            onItemDrop={(droppedItem: any) => {
                                                                itemDropped(droppedItem, false, idx);
                                                            }}
                                                        />
                                                        <ListItem
                                                            accessor={accessor}
                                                            key={2000 + idx}
                                                            listObject={item}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}
                                            <ListItemHolder
                                                holderHeight={unassignedItems.length ? 100 : 200}
                                                onItemDrop={(droppedItem) => {
                                                    itemDropped(droppedItem, false, unassignedItems.length);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className={classes.list}>
                                    <div className={classes.listTitle}>{destListTitle}</div>
                                    <div className={classes.listItemsWrapper}>
                                        <div className={classes.listItemsInnerWrapper}>
                                            {assignedItems.map((item, idx) => {
                                                return (
                                                    <React.Fragment key={3000 + idx}>
                                                        <ListItemHolder
                                                            key={4000 + idx}
                                                            onItemDrop={(droppedItem: any) => {
                                                                itemDropped(droppedItem, true, idx);
                                                            }}
                                                        />
                                                        <ListItem
                                                            accessor={accessor}
                                                            key={5000 + idx}
                                                            listObject={item}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}
                                            <ListItemHolder
                                                holderHeight={assignedItems.length ? 40 : 150}
                                                onItemDrop={(droppedItem) => {
                                                    itemDropped(droppedItem, true, assignedItems.length);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </DndProvider>
    );
};

export default DnDListAssignSort;
