export const displayAmount = (
    currency?: string,
    amount?: number,
    currencyDisplay?: string,
    fractionDigits?: number,
): string =>
    `${new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency,
        currencySign: 'accounting',
        maximumFractionDigits: fractionDigits ? fractionDigits : 0,
        minimumFractionDigits: fractionDigits ? fractionDigits : 0,
        currencyDisplay,
    }).format(amount || 0)}`;

export const displayAmountWithFraction = (amount?: number): string =>
    `${new Intl.NumberFormat('en-UK', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(Number(amount) || 0)}`;

export const displayCFCDepositAmount = (
    currency?: string,
    amount?: number,
    currencyDisplay?: string,
    fractionDigits?: number,
): string =>
    `${new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency,
        currencySign: 'accounting',
        maximumFractionDigits: fractionDigits ? fractionDigits : 2,
        minimumFractionDigits: fractionDigits ? fractionDigits : 2,
        currencyDisplay,
    }).format(amount || 0)}`;
