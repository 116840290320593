import React, { ReactElement, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import { Criteria, CriteriaType, Criterion } from 'popcorn-js/search';
import { StyledDate as DateFilter } from './DateFilter';
import { StyledTime as TimeFilter } from './TimeFilter';
import { StyledText as TextFilter } from './TextFilter';
import { StyledTextOptions as TextOptionsFilter } from './TextOptionsFilter';
import { StyledBoolFilter as BoolFilter } from './BoolFilter';
import { StyledNumber as NumberFilter } from './NumberFilter';
import { columnT } from './Table';
import { CustomTheme } from 'theme/custom';

const useStyles = makeStyles((theme: CustomTheme) => ({
    tableCellHead: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontSize: 16,
        position: 'sticky',
        top: '35px',
        border: '0px',
        zIndex: 1,
    },
}));

export default function FilterRow<T extends unknown>(props: {
    initialCriteria: Record<string, unknown>;
    showCheckboxes: boolean;
    show: boolean;
    onFilterChange: (criteria: Criteria) => void;
    columns?: columnT<T>[];
}): ReactElement {
    const { initialCriteria, showCheckboxes } = props;
    const classes = useStyles();
    const baseCriteria = _.clone(initialCriteria);
    const [columnCrit, setColumnCrit] = React.useState(baseCriteria);
    const [show, setShow] = React.useState(true);

    const showFilter = props.show;

    useEffect(() => {
        setShow(showFilter);
        setColumnCrit({ ...baseCriteria });
    }, [showFilter]);

    function generateCriteria(): Criteria {
        const criteria: Criteria = [];
        for (const field in columnCrit) {
            if (columnCrit.hasOwnProperty(field) && columnCrit[field] !== undefined) {
                criteria.push(columnCrit[field] as Criterion);
            }
        }
        return criteria;
    }

    function handleFilterChange(val: unknown, field: string) {
        const newColumnCrit = columnCrit || {};
        if (!val) {
            delete newColumnCrit[field];
        } else {
            newColumnCrit[field] = val;
        }
        setColumnCrit(newColumnCrit);
        props.onFilterChange(generateCriteria());
    }

    // eslint-disable-next-line react/no-multi-comp
    function renderDefaultFilter(column: columnT<T>) {
        return (
            <TextFilter
                id={column.field || ''}
                fieldID={column.field || ''}
                filterConfig={column.filter}
                initValue={initialCriteria && column?.field ? initialCriteria[column.field] : ''}
                onChange={(val) => {
                    handleFilterChange(val, column.field || '');
                }}
            />
        );
    }

    // eslint-disable-next-line react/no-multi-comp
    function renderTextOptionsFilter(column: columnT<T>) {
        return (
            <TextOptionsFilter
                id={column.field || ''}
                fieldID={column.field || ''}
                filterConfig={column.filter}
                initValue={columnCrit ? columnCrit[column.field || ''] : ''}
                onChange={(val) => handleFilterChange(val, column.field || '')}
            />
        );
    }

    // eslint-disable-next-line react/no-multi-comp
    function renderAsyncTextOptionsFilter(column: columnT<T>) {
        return (
            <TextOptionsFilter
                id={column.field || ''}
                fieldID={column.field || ''}
                filterConfig={column.filter}
                onChange={(val) => handleFilterChange(val, column.field || '')}
            />
        );
    }

    // eslint-disable-next-line react/no-multi-comp
    function renderBoolFilter(column: columnT<T>) {
        return (
            <BoolFilter
                id={column.field || ''}
                fieldID={column.field || ''}
                filterConfig={column.filter}
                onChange={(val) => handleFilterChange(val, column.field || '')}
            />
        );
    }

    // eslint-disable-next-line react/no-multi-comp
    function renderNumberFilter(column: columnT<T>) {
        return (
            <NumberFilter
                fieldID={column.field || ''}
                filterConfig={column.filter}
                onChange={(val) => handleFilterChange(val, column.field || '')}
            />
        );
    }

    // eslint-disable-next-line react/no-multi-comp
    function renderDateFilter(column: columnT<T>) {
        return (
            <DateFilter
                fieldID={column.field || ''}
                filterConfig={column.filter || ''}
                onChange={(val) => handleFilterChange(val, column.field || '')}
            />
        );
    }

    function renderTimeFilter(column: columnT<T>) {
        return (
            <TimeFilter
                fieldID={column.field || ''}
                filterConfig={column.filter || ''}
                onChange={(val) => handleFilterChange(val, column.field || '')}
            />
        );
    }

    function getComponentForColumn(column: columnT<T>) {
        if (column?.filter) {
            switch (column.filter.type) {
                case CriteriaType.TextCriterion:
                    if (column.filter.options !== undefined) {
                        return renderTextOptionsFilter(column);
                    }
                    if (column.filter.asyncOptionsFetcher !== undefined) {
                        return renderAsyncTextOptionsFilter(column);
                    }
                    return renderDefaultFilter(column);
                case CriteriaType.NumberCriterion:
                    return renderNumberFilter(column);
                case CriteriaType.BoolCriterion:
                    return renderBoolFilter(column);
                case CriteriaType.DateCriterion:
                    return renderDateFilter(column);
                case CriteriaType.TimeCriterion:
                    return renderTimeFilter(column);
                default:
                    return renderDefaultFilter(column);
            }
        }
    }

    return (
        <TableRow>
            {showCheckboxes && show && (
                <TableCell classes={{ head: classes.tableCellHead }} key={'checkbox'} padding={'none'} />
            )}
            {show &&
                props.columns?.map((col, idx) => (
                    <TableCell
                        classes={{ head: classes.tableCellHead }}
                        key={'filterRow' + idx}
                        padding={col?.filter?.disablePadding ? 'none' : 'checkbox'}
                    >
                        {getComponentForColumn(col)}
                    </TableCell>
                ))}
        </TableRow>
    );
}
