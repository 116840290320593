import React from 'react';
import { makeStyles } from '@material-ui/core';
import { roundScaleAndFormatNumber } from 'utils';
import { Currency } from 'popcorn-js/currency';
import { GenerateWeekViewExposureReportForCurrencyResponse } from 'popcorn-js/report';
import { CustomTheme } from 'theme/custom';
import { GraphLegendItem } from '../CashFlowGraph/GraphLegendItem';
import classNames from 'classnames';

interface ExposureTotalsProps {
    currency: Currency;
    response: GenerateWeekViewExposureReportForCurrencyResponse | undefined;
}

const ExposureTotals: React.FC<ExposureTotalsProps> = (props: ExposureTotalsProps) => {
    const classes = useStyles();
    //Error handling to be added once standardized
    if (!props.response) {
        return <div />;
    }
    const totals = props.response.totals;
    const localCurrency = props.currency;

    const salesDue = totals.salesDue?.totalAmountInCurrency;
    const purchaseDue = totals.purchaseDue?.totalAmountInCurrency;
    const purchaseOverDue = totals.purchaseOverDue?.totalAmountInCurrency;
    const salesOverDue = totals.salesOverDue?.totalAmountInCurrency;
    const purchaseTradeOpenBalances = totals.purchaseTradeOpenBalances;
    const salesTradeOpenBalances = totals.salesTradeOpenBalances;

    const showPurchaseTotals = purchaseDue !== 0 || purchaseOverDue !== 0 || purchaseTradeOpenBalances !== 0;
    const showSalesTotals = salesDue !== 0 || salesOverDue !== 0 || salesTradeOpenBalances !== 0;
    const showTotals = showSalesTotals && showPurchaseTotals;

    return (
        <React.Fragment>
            <div className={classes.graphLegendWrapper}>
                <div className={classes.container}>
                    <div
                        className={classNames({
                            [classes.column]: showTotals,
                            [classes.singleRowColumn]: !showTotals,
                        })}
                    >
                        {showSalesTotals && (
                            <GraphLegendItem
                                icon={'teal'}
                                title={'Sales'}
                                value={roundScaleAndFormatNumber(salesDue, localCurrency.symbol, false, 0)}
                                tooltipTitle={'Sum of all sales orders, invoices and credit notes'}
                            />
                        )}
                        {showPurchaseTotals && (
                            <GraphLegendItem
                                icon={'orange'}
                                title={'Purchases'}
                                value={roundScaleAndFormatNumber(purchaseDue, localCurrency.symbol, false, 0)}
                                tooltipTitle={'Sum of all purchase orders, invoices and credit notes'}
                            />
                        )}
                    </div>
                    <div
                        className={classNames({
                            [classes.column]: showTotals,
                            [classes.singleRowColumn]: !showTotals,
                        })}
                    >
                        {showSalesTotals && (
                            <GraphLegendItem
                                icon={'red'}
                                title={'Overdue Sales'}
                                value={roundScaleAndFormatNumber(salesOverDue, localCurrency.symbol, false, 0)}
                                tooltipTitle={'Sum of all outstanding sales orders, invoices and credit notes'}
                            />
                        )}
                        {showPurchaseTotals && (
                            <GraphLegendItem
                                icon={'red'}
                                title={'Overdue Purchases'}
                                value={roundScaleAndFormatNumber(purchaseOverDue, localCurrency.symbol, false, 0)}
                                tooltipTitle={'Sum of all outstanding sales orders, invoices and credit notes'}
                            />
                        )}
                    </div>
                    <div
                        className={classNames({
                            [classes.column]: showTotals,
                            [classes.singleRowColumn]: !showTotals,
                        })}
                    >
                        {showPurchaseTotals && (
                            <GraphLegendItem
                                icon={'yellow'}
                                title={'Import Hedges'}
                                value={roundScaleAndFormatNumber(
                                    purchaseTradeOpenBalances,
                                    localCurrency.symbol,
                                    false,
                                    0,
                                )}
                                tooltipTitle={'Sum of all available balances on import hedges (Buy FX)'}
                            />
                        )}
                        {showSalesTotals && (
                            <GraphLegendItem
                                icon={'yellow'}
                                title={'Export Hedges'}
                                value={roundScaleAndFormatNumber(
                                    salesTradeOpenBalances,
                                    localCurrency.symbol,
                                    false,
                                    0,
                                )}
                                tooltipTitle={'Sum of all available balances on export hedges (Sell FX)'}
                            />
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ExposureTotals;

const useStyles = makeStyles((theme: CustomTheme) => ({
    graphLegendWrapper: {
        width: '1000px',
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        margin: '15px 10px 5px 100px',
        padding: theme.spacing(1),
        alignItems: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        flexGrow: 1,
        borderRadius: '4px',
        flex: 'box',
    },
    column: {
        width: '100%',
        display: 'grid',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gridTemplateRows: '1fr 1fr',
    },
    singleRowColumn: {
        width: '100%',
        display: 'grid',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gridTemplateRows: '1fr',
    },
    container: {
        columnGap: '30px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        marginLeft: '30px',
    },
}));
