import React, { ChangeEvent, ReactElement, useContext, useState, useEffect } from 'react';
import { makeStyles, Card, MenuItem, FormControl } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { LightSelect, LightTextField } from './styledComponents';
import { seasons } from 'popcorn-js';
import { AppContext, AppContextT } from 'context';
import { Client, ProcessingOrg } from 'popcorn-js/party';
import { useServiceSync } from 'hooks/useService';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'popcorn-js/party/processingOrg/recordkeeper';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Actions, OptionValues } from './index';

export const OptionReferences = ({
    uuid,
    option,
    dispatch,
}: {
    uuid: string;
    option: OptionValues;
    dispatch: Actions;
}): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const clientProduct = (appContext.party as Client)?.products;
    const [parentParty, setParentParty] = useState<ProcessingOrg | undefined>();
    const party = (appContext.party || {}) as Client | ProcessingOrg;

    useEffect(() => {
        retrieveParentParty().finally();
    }, []);

    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );
    const retrieveParentParty = async () => {
        try {
            const retrieveParentPartyResponse = await processingOrgRecordkeeperRetrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.parentPartyCode },
            });
            setParentParty(retrieveParentPartyResponse.processingOrg);
        } catch (e) {
            console.error('error finding parent party', e);
        }
    };

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelDark}>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Season'}
                            style={{ width: '100%' }}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                dispatch.setSeason(uuid, event.target.value as string)
                            }
                            value={option.season || ''}
                        >
                            {seasons.map(
                                (s) =>
                                    s && (
                                        <MenuItem key={s} value={s}>
                                            {s}
                                        </MenuItem>
                                    ),
                            )}
                            <MenuItem key={'b'} value={''}>
                                {'blank'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Product'}
                            style={{ width: '100%' }}
                            disabled={!clientProduct?.length}
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                dispatch.setProduct(uuid, event.target.value as string)
                            }
                            value={option.product || ''}
                        >
                            <MenuItem key={'n'} value={''}>
                                {'None'}
                            </MenuItem>
                            {(clientProduct || []).map(
                                (p) =>
                                    p && (
                                        <MenuItem key={p} value={p}>
                                            {p}
                                        </MenuItem>
                                    ),
                            )}
                        </LightSelect>
                    </FormControl>
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightTextField
                        label={'Client Reference'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            dispatch.setClientReference(uuid, event.target.value as string)
                        }
                        value={option.clientReference || ''}
                    />
                    <LightTextField
                        id={'clientNotes'}
                        label={'Client Notes'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            dispatch.setClientNotes(uuid, event.target.value as string)
                        }
                        value={option.clientNotes || ''}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Trader'}
                            style={{ width: '100%' }}
                            fullWidth
                            onChange={(event: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                dispatch.setTrader(uuid, event.target.value as string)
                            }
                            value={option.trader || ''}
                            error={!option.trader}
                        >
                            {parentParty?.traders?.map((trader, i) => (
                                <MenuItem key={i} value={trader}>
                                    {trader}
                                </MenuItem>
                            ))}
                        </LightSelect>
                    </FormControl>
                    <LightTextField
                        id={'traderNotes'}
                        label={'Trader Notes'}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            dispatch.setNotes(uuid, event.target.value as string)
                        }
                        value={option.notes || ''}
                    />
                </div>
            </div>
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    detailsPanelDark: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
}));
