/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Invoice } from 'popcorn-js/invoice';
import { Recordkeeper as InvoiceRecordkeeper } from 'popcorn-js/invoice/recordkeeper';
import { Recordkeeper as CounterpartyRecordkeeper } from 'popcorn-js/counterparty/recordkeeper';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Counterparty } from 'popcorn-js/counterparty';
import { AppContextT, AppContext } from 'context';
import { Currency } from 'popcorn-js/currency';
import { Criteria, CriteriaType } from 'popcorn-js/search';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { InvoiceFields } from './InvoiceFields';

export type InvoiceH = Invoice & {
    currencyIsoCode?: string;
    costCurrencyIsoCode?: string;
    counterpartyName?: string;
};

export const History = (props: { invoice: Invoice; onHide?: () => void; open: boolean }): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [invoice, setInvoice] = useState<InvoiceH>(props.invoice);
    const [history, setHistory] = useState<InvoiceH[]>([]);

    useEffect(() => {
        load(props.invoice).finally();
    }, [props.invoice]);

    const load = async (i: Invoice) => {
        try {
            setIsLoading(true);
            const _history = await retrieveHistory(i);
            const _counterparties = await findAssociatedCounterparties(i);
            setInvoice(modifyFields(i, _counterparties));
            setHistory(modifyMultiple(_history, _counterparties));
            setIsLoading(false);
        } catch (e) {
            console.error('error retrieving history', e);
        }
    };

    const retrieveHistory = async (i: Invoice): Promise<Invoice[]> => {
        const retrieveResponse = await InvoiceRecordkeeper.retrieveHistory({
            identifier: { type: IdentifierType.ID_IDENTIFIER, id: i.id },
        });
        return retrieveResponse.history;
    };

    const modifyMultiple = (invoices: Invoice[], counterparties: Counterparty[]): InvoiceH[] => {
        return invoices.map((inv: Invoice) => modifyFields(inv, counterparties));
    };

    const modifyFields = (invoice: Invoice, counterparties: Counterparty[]): InvoiceH => {
        return {
            ...invoice,
            currencyIsoCode: (appContext.currencies?.find((c: Currency) => c.isoCode === invoice.currency) || {})
                .isoCode,
            costCurrencyIsoCode: (
                appContext.currencies?.find((c: Currency) => c.isoCode === invoice.costCurrency) || {}
            ).isoCode,
            counterpartyName: (counterparties.find((c: Counterparty) => c.id === invoice.counterpartyId) || {}).name,
        };
    };

    const findAssociatedCounterparties = async (inv: Invoice): Promise<Counterparty[]> => {
        const counterpartyIds = [];
        if (inv.counterpartyId && inv.counterpartyId !== '') {
            counterpartyIds.push(inv.counterpartyId);
        }
        for (const inv of history) {
            if (inv.counterpartyId && inv.counterpartyId !== '') {
                if (!counterpartyIds.includes(inv.counterpartyId)) {
                    counterpartyIds.push(inv.counterpartyId);
                }
            }
        }
        if (counterpartyIds.length <= 0) {
            return [];
        }
        const criteria: Criteria = counterpartyIds.map((id: string) => ({
            type: CriteriaType.ExactCriterion,
            field: 'id',
            text: id,
        }));
        const findResponse = await CounterpartyRecordkeeper.find({ criteria });
        return findResponse.records;
    };

    return (
        <HistoryLayout
            entity={invoice}
            entityFields={InvoiceFields}
            entityHistory={history}
            entityName={'Invoice'}
            loading={isLoading}
            onHide={props.onHide}
            open={props.open}
        />
    );
};
