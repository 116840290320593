import { Currency } from 'popcorn-js/currency';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { Client, PartyType, ProcessingBank, ProcessingOrg, System } from 'popcorn-js/party';
import { Context, ExtendedContext, UserProfile } from 'popcorn-js/security';
import React from 'react';

export interface AppContextT {
    apiURL: string;
    pricingURL: string;
    token?: string;
    currentContext?: Context;
    originalContext?: Context;
    originalExtendedContext?: ExtendedContext;
    switchableContext?: ExtendedContext;
    userProfile?: UserProfile;
    permissions?: string[];
    viewPermissions?: string[];
    currencies?: Currency[];
    currencyPairs?: CurrencyPair[];
    assignedCurrencyPairs?: CurrencyPair[];
    localCurrency?: Currency;
    party: Client | ProcessingOrg | System;
    partyType: PartyType;
    parentPartyCode?: string;
    contextSwitched?: boolean;
    setSessionExpired?: () => void;
    environment: 'www' | 'dev' | 'localhost' | 'staging';
    processingBanks?: ProcessingBank[];
    processingOrg?: ProcessingOrg[];
    reportingEntity?: boolean;
    switchContext: (partCode: string) => Promise<void>;
}

export const AppContext = React.createContext<AppContextT>({} as AppContextT);
