import { AuditEntry } from 'popcorn-js/audit';

export enum TradeType { //changed the text to abbreviations
    SPOT = 'SPOT',
    TOD = 'TOD',
    TOM = 'TOM',
    FORWARD = 'FORWARD',
    CANCELLATION = 'CANCELLATION',
    DRAWDOWN = 'DRAWDOWN',
    DRAWDOWNFARLEG = 'DRAWDOWN FAR LEG',
    DRAWDOWNNEARLEG = 'DRAWDOWN NEAR LEG',
    EXTENSION = 'EXTENSION',
    EXTENSIONFARLEG = 'EXTENSION FAR LEG',
    EXTENSIONNEARLEG = 'EXTENSION NEAR LEG',
    SWAP = 'SWAP',
}

export enum TradeDirection {
    BUY = 'BUY',
    SELL = 'SELL',
}

export enum BillingType {
    BankBilling = 'BANK',
    ClientBilling = 'CLIENT',
}

export const invertDirection = (direction?: TradeDirection): TradeDirection =>
    direction === TradeDirection.BUY ? TradeDirection.SELL : TradeDirection.BUY;

export enum ImportExport {
    IMPORT = 'Import',
    EXPORT = 'Export',
}

export enum TradeStatus {
    OPEN = 'OPEN',
    MATURED = 'MATURED',
    EXPIRED = 'EXPIRED',
    EXERCISED = 'EXERCISED',
}

export type TradeParent = {
    amount: number;
    id: string;
};

export interface Trade {
    id?: string;
    auditEntry?: AuditEntry;
    number?: string;
    counterpartyId?: string;
    counterparty?: string;
    externalReference?: string;
    tradingPartyCode?: string;
    cancellation?: boolean;
    transactionID?: string;
    type?: TradeType;
    parents?: TradeParent[];
    acmParents?: TradeParent[];
    captureDate?: Date;
    status?: TradeStatus;
    currencyPair?: string;
    processingOrgPartyCode?: string;
    processingOrgName?: string;
    importExport?: ImportExport;
    acm?: boolean;
    financialYear?: string;
    bank?: string;
    direction?: TradeDirection;
    maturityDate?: Date;
    tradeDate?: Date;
    availableBalance?: number;
    acmBalance?: number;
    capturedSpotRate?: number;
    interbankRate?: number;
    bankRate?: number;
    quoteAmount?: number;
    notionalAmount?: number;
    spotPrice?: number;
    forwardPoints?: number;
    dealRate?: number;
    effectiveRate?: number;
    netCashFlow?: number;
    realisedPnl?: number;
    trader?: string;
    traderOrganisation?: string;
    portfolioManager?: string;
    relationshipManager?: string;
    notes?: string;
    optionReference?: string;
    optionNumber?: string;
    matched?: boolean;
    season?: string;
    product?: string;
    clientReference?: string;
    clientNotes?: string;
    marginNotes?: string;
    intermediaryMargin?: number;
    clientFee?: number;
    adminFee?: number;
    billingType?: BillingType;
    validated?: boolean;
    billedToBank?: number;
}

export type TradeRecordInput = {
    externalReference?: string;
    parents?: TradeParent[];
    ACMParents?: TradeParent[];
    currencyPair?: string;
    processingOrgPartyCode?: string;
    capturedSpotRate?: number;
    trader?: string;
    traderOrganisation?: string;
    bank?: string;
    interbankRate?: number;
    bankRate?: number;
    maturityDate?: Date;
    notionalAmount?: number;
    quoteAmount?: number;
    tradeDate?: Date;
    spotPrice?: number;
    dealRate?: number;
    direction?: TradeDirection;
    notes?: string;
    optionReference?: string;
    optionNumber?: string;
    season?: string;
    product?: string;
    clientReference?: string;
    clientNotes?: string;
    marginNotes?: string;
    intermediaryMargin?: number;
    clientFee?: number;
    adminFee?: number;
    billingType?: BillingType;
    billedToBank?: number;
    //
    acm?: boolean;
};

export type TradeRecordInputWithACMFlag = TradeRecordInput & { acm?: boolean };

export type TradeAmendInput = {
    type?: TradeType;
    currencyPair?: string;
    bank?: string;
    direction?: TradeDirection;
    status?: TradeStatus;
    tradeDate?: Date;
    capturedSpotRate?: number;
    interbankRate?: number;
    bankRate?: number;
    trader?: string;
    traderOrganisation?: string;
    notes?: string;
    optionReference?: string;
    optionNumber?: string;
    externalReference?: string;
    maturityDate?: Date;
    notionalAmount?: number;
    spotPrice?: number;
    dealRate?: number;
    financialYear?: string;
    season?: string;
    product?: string;
    clientReference?: string;
    clientNotes?: string;
    marginNotes?: string;
    intermediaryMargin?: number;
    clientFee?: number;
    adminFee?: number;
    billingType?: BillingType;
    billedToBank?: number;
};

export type Node = {
    trade: Trade;
    children?: Node[];
};

export const TradeDirections = [
    {
        value: TradeDirection.BUY,
        label: TradeDirection.BUY,
    },
    {
        value: TradeDirection.SELL,
        label: TradeDirection.SELL,
    },
];
export const TradeStatuses = [
    {
        value: TradeStatus.OPEN,
        label: TradeStatus.OPEN,
    },
    {
        value: TradeStatus.MATURED,
        label: TradeStatus.MATURED,
    },
];

export const BillingTypes = [BillingType.ClientBilling, BillingType.BankBilling];

export enum PartyType {
    SYSTEM = 'SYSTEM',
    PROCESSING_ORG = 'PROCESSING_ORG',
    CLIENT = 'CLIENT',
}
