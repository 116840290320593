import React, { useCallback, useContext, useEffect, useState } from 'react';
import { StandardCard } from 'components/Card/Card';
import { Trade, TradeDirection } from 'popcorn-js/tradeV2';
import Table from 'components/Table/Table';
import {
    ACTION_BUTTON_TYPE,
    COLOR as standardCardHeaderColors,
    ITEM_VARIATION,
} from 'components/CardHeader/StandardCardHeader';
import { displayAmount } from 'utils';
import { useService } from 'hooks/useService';
import { Criteria, CriteriaType, Query, QueryOrderT } from 'popcorn-js/search';
import { SettlementInstruction } from 'popcorn-js/settlementInstruction';
import { AppContext, AppContextT } from 'context';
import moment from 'moment';
import { Handler } from 'popcorn-js/tradeV2/handler';
import { debounce } from 'lodash';

const pageSize = 10;

export const TradeSelection = (props: {
    selectedTrades: Trade[];
    onTradeSelect: (trd: Trade) => void;
    SI: SettlementInstruction;
    direction: TradeDirection;
    onClose: () => void;
}): React.ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);
    const { onTradeSelect, SI, onClose, direction } = props;
    const tradeCurrencyPairCriteria: Criteria = [];
    for (const ccyPair of appContext.currencyPairs || []) {
        if (ccyPair.baseCurrency === SI.currency || ccyPair.quoteCurrency === SI.currency) {
            tradeCurrencyPairCriteria.push({
                type: CriteriaType.ExactCriterion,
                text: ccyPair.name,
                field: 'currencyPair',
            });
        }
    }

    const defaultTradeCriteria: Criteria = [
        ...tradeCurrencyPairCriteria,
        {
            type: CriteriaType.ExactCriterion,
            text: SI.partyCode as string,
            field: 'tradingPartyCode',
        },
        {
            type: CriteriaType.ExactCriterion,
            text: direction as string,
            field: 'direction',
        },
        {
            type: CriteriaType.TimeCriterion,
            field: 'maturityDate',
            endDate: {
                date: moment
                    .unix(SI.date || 0)
                    .endOf('day')
                    .toISOString(),
                inclusive: true,
                ignore: false,
            },
        },
        {
            type: CriteriaType.NumberCriterion,
            field: 'availableBalance.value',
            lessThan: {
                ignore: true,
            },
            moreThan: {
                amount: 0.004,
                inclusive: false,
            },
        },
    ];
    const [query, setQuery] = useState<Query>({
        sortBy: ['maturityDate', 'availableBalance.value', 'currencyPair.name'],
        order: ['asc', 'desc', 'asc'],
        limit: pageSize,
        offset: 0,
    });

    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [criteria, setCriteria] = useState<Criteria>(defaultTradeCriteria);

    const [tradesTotal, setTradesTotal] = useState<number>(0);
    const [tradesResponse, setTradesResponse] = useState<Trade[]>([]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
    };

    const handleChangeSorting = (sortBy: string, order: QueryOrderT) => {
        const newQuery = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
    };

    const handleFilterChange = useCallback(
        debounce((newCrit: Criteria) => {
            const newQuery = {
                ...query,
                offset: 0,
            };

            setQuery(newQuery);
            setCriteria([...newCrit, ...defaultTradeCriteria]);
        }, 300),
        [query],
    );

    const [
        { response: tradeFindResponse, loading: tradeFindLoading, error: findTradesError },
        setFindTradesRequest,
    ] = useService({ criteria: defaultTradeCriteria, query }, Handler.Find);

    const generateFindTradesRequest = useCallback(() => {
        return {
            criteria: criteria,
            query: query,
            deleted: false,
        };
    }, [query, criteria, false]);

    useEffect(() => {
        setFindTradesRequest(generateFindTradesRequest());
    }, [generateFindTradesRequest, setFindTradesRequest, query, criteria]);

    useEffect(() => {
        if (tradeFindResponse && tradeFindResponse.records && !tradeFindLoading) {
            setTradesTotal(tradeFindResponse.total);
            setTradesResponse(tradeFindResponse.records || []);
        }
    }, [tradeFindResponse, tradeFindLoading, findTradesError]);

    return (
        <StandardCard
            cardHeaderProps={{
                color: standardCardHeaderColors.CONTEXT,
                itemsLeft: [
                    {
                        id: 'SettlementInstruction/TradeSelection/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'SELECT TRADE TO LINK',
                    },
                ],
                itemsRight: [
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'filter',
                        icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                        helpText: 'Filter',
                        onClick: () => {
                            setShowFilterRow(!showFilterRow);
                        },
                    },
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'close',
                        icon: ACTION_BUTTON_TYPE.CANCEL,
                        helpText: 'Close',
                        onClick: onClose,
                    },
                ],
            }}
        >
            <Table
                columns={[
                    {
                        title: 'External Reference',
                        field: 'externalReference',
                        render: (rowData: Trade) => {
                            if (rowData.externalReference) {
                                return rowData.externalReference;
                            } else {
                                return '-';
                            }
                        },
                        filter: { type: CriteriaType.TextCriterion },
                    },
                    {
                        title: 'Trade Type',
                        field: 'type',
                        render: (rowData: Trade) => {
                            if (rowData) {
                                return rowData.type;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Currency',
                        field: 'currencyPair',
                        render: (rowData: Trade) => {
                            if (rowData.currencyPair) {
                                return rowData.currencyPair;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Available Balance',
                        field: 'availableBalance.value',
                        render: (rowData: Trade) => {
                            return displayAmount(rowData.availableBalance);
                        },
                    },
                ]}
                count={tradesTotal}
                data={tradesResponse}
                defaultColConfig={[
                    { header: 'External Reference', visible: true },
                    { header: 'Trade Type', visible: true },
                    { header: 'Currency', visible: true },
                    { header: 'Available Balance', visible: true },
                ]}
                showFilterRow={showFilterRow}
                handleChangePage={handleChangePage}
                onChangeSorting={handleChangeSorting}
                onFilterChange={handleFilterChange}
                handleChangeRowsPerPage={() => {
                    return;
                }}
                rowClickAction={(trd: Trade) => onTradeSelect(trd)}
                loading={tradeFindLoading}
                order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                rowsPerPage={pageSize}
                sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                tableID={'LinkedTradeSelectionTable'}
                title={''}
            />
        </StandardCard>
    );
};
