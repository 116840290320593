import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';

interface CreateSIButtonProps {
    disabled: boolean;
    onClick: (e?: string) => void;
}

const CreateSIButton: React.FC<CreateSIButtonProps> = (props: CreateSIButtonProps) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.buttonWrapper}>
                <BaseButton
                    id={'CreateSIButton/Create'}
                    disabled={props.disabled}
                    variant={VARIANT.CONTAINED}
                    color={COLOR.DEFAULT}
                    size={SIZE.SMALL}
                    onClick={(): void => props.onClick()}
                    text={'CREATE SI'}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '160px',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '14px',
    },
}));

export default CreateSIButton;
