/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import { CustomTheme } from 'theme/custom';
import { getMidDay, HexToRGBA } from 'utils';
import { useService } from 'hooks/useService';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { parseISO } from 'date-fns';
import { AppContext, AppContextT } from 'context';
import moment, { now } from 'moment';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import { SystemDateFormat } from 'constants/formats';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { TradeConfirmation } from 'popcorn-js/tradeV3';
import { StyledSelect } from 'components/Select/StyledSelect';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { PartyType } from 'popcorn-js/tradeV2';

export const ConfirmationDetail = (props: {
    closeDialog: () => void;
    show: boolean;
    confirmationEdit?: TradeConfirmation;
    edit?: boolean;
    validate?: boolean;
}): ReactElement => {
    const { closeDialog, show, confirmationEdit, edit, validate } = props;
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const [confirmation, setConfirmation] = useState<TradeConfirmation>(
        edit ? (confirmationEdit as TradeConfirmation) : ({} as TradeConfirmation),
    );
    const [invalidFields, setInvalidFields] = useState<Record<string, string | undefined>>();
    const [creating, setCreating] = React.useState<boolean>(false);
    const [updating, setUpdating] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const { tradeV3ConfirmationHandler } = useContext<ServiceContextT>(ServiceContext);

    const bankOptions: { value: string; label: string }[] = [];
    appContext.processingBanks?.slice().forEach((c) => {
        bankOptions.push({
            label: c.name,
            value: c.name,
        });
    });
    const currencyOptions: { value: string; label: string }[] = [];
    appContext.currencies?.slice().forEach((c) => {
        currencyOptions.push({
            value: c.isoCode,
            label: c.isoCode,
        });
    });

    useEffect(() => {
        const amountBoughtByBank = Number(confirmation.amountBoughtByBank?.toFixed(2)) || 0.0;
        const amountSoldByBank = Number(confirmation.amountSoldByBank?.toFixed(2)) || 0.0;
        let dealRate = 0;
        if (confirmation.currencyBoughtByBank == 'ZAR') {
            dealRate = amountBoughtByBank / amountSoldByBank;
        } else if (confirmation.currencySoldByBank == 'ZAR') {
            dealRate = amountSoldByBank / amountBoughtByBank;
        }

        setConfirmation({
            ...confirmation,
            ['dealRate']: Number(dealRate.toFixed(6)),
        });
    }, [confirmation.amountBoughtByBank, confirmation.amountSoldByBank]);

    const handleChange = (field: string, value: string | number | boolean | Date | undefined) => {
        setConfirmation({
            ...confirmation,
            [field]: value,
        });
        setInvalidFields({
            ...invalidFields,
            [field]: undefined,
        });
    };

    const handleHideAlert = () => {
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        closeDialog();
    };
    const [{ response: createResponse, error: createError }, setCreateRequest] = useService(
        undefined,
        tradeV3ConfirmationHandler.CreateTradeConfirmation,
    );
    const [{ response: updateResponse, error: updateError }, setUpdateRequest] = useService(
        undefined,
        tradeV3ConfirmationHandler.UpdateTradeConfirmation,
    );
    useEffect(() => {
        if (createError && createError !== '' && creating) {
            setErrorMessage(createError);
            setCreating(false);
        }
        if (createResponse && createResponse.confirmation && createResponse.confirmation.id) {
            setSuccessMessage('Confirmation successfully created');
            setCreating(false);
        }
    }, [createError, setCreating, creating, createResponse]);
    useEffect(() => {
        if (creating && confirmation) {
            setCreateRequest({ confirmation: confirmation });
        }
    }, [confirmation, creating, setCreateRequest]);
    const onSave = async () => {
        const _confirmation = { ...confirmation };
        (_confirmation as Record<string, unknown>)['createdDate'] = moment(now());
        (_confirmation as Record<string, unknown>)['processingOrgPartyCode'] =
            appContext.partyType == PartyType.CLIENT ? appContext.parentPartyCode : appContext.party.partyCode;
        setConfirmation(_confirmation);
        setCreating(true);
    };

    useEffect(() => {
        if (updateError && updateError !== '' && updating) {
            setErrorMessage(updateError);
            setUpdating(false);
        }
        if (updateResponse && updateResponse.confirmation && updateResponse.confirmation.id) {
            setSuccessMessage('Confirmation successfully updated');
            setUpdating(false);
        }
    }, [updateError, updating, setUpdating, updateResponse]);
    useEffect(() => {
        if (updating && confirmation) {
            setUpdateRequest({
                confirmation: confirmation,
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: confirmation.id },
            });
        }
    }, [confirmation, updating, setUpdateRequest]);
    const onUpdate = async () => {
        const _confirmation = { ...confirmation };
        setConfirmation(_confirmation);
        setUpdating(true);
    };
    const onValidate = async () => {
        const _confirmation = { ...confirmation };
        _confirmation.validated = true;
        setConfirmation(_confirmation);
        setUpdating(true);
    };
    return (
        <Dialog fullScreen onClose={() => closeDialog()} open={show} classes={{ paper: classes.paper }}>
            <BaseAppBar title={`Trade Confirmation`} onClose={closeDialog} showCloseButton />
            <div className={classes.detailsWrapper}>
                <div className={classes.details}>
                    <StandardCard
                        cardHeaderProps={{
                            itemsLeft: [
                                {
                                    id: 'SettlementInstruction/AddNewSI/title',
                                    type: ITEM_VARIATION.TITLE,
                                    text: `Capture Trade Confirmation Form`,
                                },
                            ],
                        }}
                    >
                        <div className={classes.container}>
                            <div className={classes.column}>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Bank</sup>
                                    {validate ? (
                                        <BaseTextField
                                            disabled={validate}
                                            id="bank"
                                            value={confirmation.bank}
                                            style={{ width: '100%' }}
                                        />
                                    ) : (
                                        <StyledSelect
                                            onChange={(value: any) => handleChange('bank', value.value as string)}
                                            options={bankOptions}
                                            value={{ value: confirmation.bank || '', label: confirmation.bank || '' }}
                                        />
                                    )}
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Client Name</sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="bankClient"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('bankClient', e.target.value)
                                        }
                                        value={confirmation.bankClient}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.selectLabel}>Trade Date</div>
                                    <BaseTextField
                                        disabled={validate}
                                        id="tradeDate"
                                        type={'date'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('tradeDate', getMidDay(parseISO(e.target.value)))
                                        }
                                        value={
                                            confirmation.tradeDate
                                                ? moment(confirmation.tradeDate).format(SystemDateFormat)
                                                : ''
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <div className={classes.selectLabel}>Value/ Maturity Date</div>
                                    <BaseTextField
                                        disabled={validate}
                                        id="maturityDate"
                                        type={'date'}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('maturityDate', getMidDay(parseISO(e.target.value)))
                                        }
                                        value={
                                            confirmation.maturityDate
                                                ? moment(confirmation.maturityDate).format(SystemDateFormat)
                                                : ''
                                        }
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className={classes.border} />
                            <div className={classes.column}>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Currency Bought By Bank</sup>
                                    {validate ? (
                                        <BaseTextField
                                            disabled={validate}
                                            id="currencyBoughtByBank"
                                            value={confirmation.currencyBoughtByBank}
                                            style={{ width: '100%' }}
                                        />
                                    ) : (
                                        <StyledSelect
                                            onChange={(value: any) =>
                                                handleChange('currencyBoughtByBank', value.value as string)
                                            }
                                            options={currencyOptions}
                                            value={{
                                                value: confirmation.currencyBoughtByBank || '',
                                                label: confirmation.currencyBoughtByBank || '',
                                            }}
                                        />
                                    )}
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Amount Bought By Bank</sup>
                                    <BaseNumberField
                                        disabled={validate}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange('amountBoughtByBank', Number(e.target.value))
                                        }
                                        id="amountBoughtByBank"
                                        value={confirmation.amountBoughtByBank?.toFixed(2)}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Currency Sold By Bank</sup>
                                    {validate ? (
                                        <BaseTextField
                                            disabled={validate}
                                            id="currencySoldByBank"
                                            value={confirmation.currencySoldByBank}
                                            style={{ width: '100%' }}
                                        />
                                    ) : (
                                        <StyledSelect
                                            onChange={(value: any) =>
                                                handleChange('currencySoldByBank', value.value as string)
                                            }
                                            options={currencyOptions}
                                            value={{
                                                value: confirmation.currencySoldByBank || '',
                                                label: confirmation.currencySoldByBank || '',
                                            }}
                                        />
                                    )}
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Amount Sold By Bank</sup>
                                    <BaseNumberField
                                        disabled={validate}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange('amountSoldByBank', Number(e.target.value))
                                        }
                                        id="amountSoldByBank"
                                        value={confirmation.amountSoldByBank?.toFixed(2)}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Deal/ Exchange Rate</sup>
                                    <BaseNumberField
                                        disabled={true}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange('dealRate', Number(e.target.value))
                                        }
                                        id="dealRate"
                                        value={confirmation.dealRate?.toFixed(6)}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Parent Trade (Optional)</sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="parentTradeReference"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('parentTradeReference', e.target.value)
                                        }
                                        value={confirmation.parentTradeReference}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className={classes.border} />
                            <div className={classes.column}>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>
                                        External Reference / Deal Number / Deal Reference
                                    </sup>
                                    <BaseTextField
                                        disabled={validate}
                                        id="externalReference"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('externalReference', e.target.value)
                                        }
                                        value={confirmation.externalReference}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Deal Status (Optional)</sup>

                                    <BaseTextField
                                        disabled={validate}
                                        id="dealStatusIndicator"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('dealStatusIndicator', e.target.value)
                                        }
                                        value={confirmation.dealStatusIndicator}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className={classes.item}>
                                    <sup className={classes.selectLabel}>Notes (Optional)</sup>

                                    <BaseTextField
                                        disabled={validate}
                                        id="notes"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            handleChange('notes', e.target.value)
                                        }
                                        value={confirmation.notes}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                {!edit && !validate && (
                                    <div className={classes.button}>
                                        <BaseButton
                                            text={'ADD CONFIRMATION'}
                                            id={'confirm'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.LARGE}
                                            width={'100%'}
                                            onClick={onSave}
                                        />
                                    </div>
                                )}
                                {edit && !validate && (
                                    <div className={classes.button}>
                                        <BaseButton
                                            text={'UPDATE CONFIRMATION'}
                                            id={'confirm'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.LARGE}
                                            width={'100%'}
                                            onClick={onUpdate}
                                        />
                                    </div>
                                )}
                                {validate && (
                                    <div className={classes.button}>
                                        <BaseButton
                                            text={'VALIDATE'}
                                            id={'validate'}
                                            variant={VARIANT.CONTAINED}
                                            color={COLOR.ACTION}
                                            size={SIZE.LARGE}
                                            width={'100%'}
                                            onClick={onValidate}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </StandardCard>
                </div>
            </div>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                successMessage={successMessage}
            />
        </Dialog>
    );
};
const useStyles = makeStyles((theme: CustomTheme) => ({
    paper: {
        backgroundColor: theme.palette.background.default,
    },
    item: {
        width: '100%',
        margin: theme.spacing(),
        height: '56px',
        flex: 1,
        paddingTop: 0,
    },
    button: {
        width: '100%',
        margin: theme.spacing(),
        marginTop: theme.spacing(5),
    },
    detailsWrapper: {
        margin: '20px',
        justifyContent: 'center',
        display: 'flex',
    },
    details: { width: '1230px' },
    column: {
        width: '400px',
        margin: '30px',
    },
    container: {
        display: 'flex',
    },
    select: {
        width: '100%',
        height: '100%',
        paddingTop: 0,
    },
    border: { backgroundColor: theme.palette.background.paper, width: '3px' },
    selectLabel: {
        fontSize: 12,
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
}));
