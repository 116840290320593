import React from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, ReferenceLine } from 'recharts';
import {
    roundScaleAndFormatNumber,
    categoryNameToMonthNum,
    calculateTooltipOffset,
} from 'views/Client/SharedGraphComponents';
import { CustomTooltipSection, CustomTooltipSectionLine, CustomTooltip } from '../RechartsCustomTooltip';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { Currency } from 'popcorn-js/currency';
import { Aggregation, GenerateWeekViewExposureReportForCurrencyResponse } from 'popcorn-js/report';
import { CustomTheme } from 'theme/custom';

interface Response {
    data: Array<WeekViewExposureDataSeries>;
    ticks: Array<string>;
    monthsColumnAmountMap: Record<string, number>;
}

export interface viewPermissions {
    canViewMaturityLadder: boolean;
}

interface WeekViewExposureDataSeries {
    categoryName?: string;
    startDate?: number;
    endDate?: number;
    purchaseExposureTotal?: number;
    salesExposureTotal?: number;
    invoiceOverdueImport?: number;
    invoiceOverdueExport?: number;
    invoiceUnRealisedImport?: number;
    invoiceUnRealisedExport?: number;
    openTradeBalanceImport?: number;
    openTradeBalanceExport?: number;
    xAxisLabel?: string;
}

const transformMonthViewData = (
    cashFlowResponse: GenerateWeekViewExposureReportForCurrencyResponse,
    dateFormat: string,
): Response => {
    let data: Array<WeekViewExposureDataSeries> = [];

    if (!cashFlowResponse.aggregations) {
        return {
            data: [],
            ticks: [],
            monthsColumnAmountMap: {},
        };
    }

    data = cashFlowResponse.aggregations.map(function (p: Aggregation): WeekViewExposureDataSeries {
        return {
            categoryName: p.categoryName,
            startDate: p.startDate,
            endDate: p.endDate,

            // For Tooltip:  Total purchase exposure
            purchaseExposureTotal: (p.invoiceTotalImport?.total || {}).currencyAmount,

            // For Tooltip:  Total sales exposure
            salesExposureTotal: (p.invoiceTotalExport.total || {}).currencyAmount,

            // Note: Currency breakdown no longer on graph neither in tooltip
            // For Graph: Import Invoice Overdue Series
            invoiceOverdueImport: p.invoiceOverdueImport.total.currencyAmount,
            // For Graph: Export Invoice Overdue Series
            invoiceOverdueExport: p.invoiceOverdueExport.total.currencyAmount,
            // For Graph: Import Invoice Due Series
            invoiceUnRealisedImport: p.invoiceUnRealisedImport.total.currencyAmount,
            // For Graph: Export Invoice Due Series
            invoiceUnRealisedExport: p.invoiceUnRealisedExport.total.currencyAmount,

            // For Tooltip and graph: Import trade open balances
            openTradeBalanceImport: p.openTradeBalanceImport,

            // For Tooltip and graph: Export trade open balances
            openTradeBalanceExport: p.openTradeBalanceExport,
        };
    });

    const { dataWithGaps, ticks, monthsColumnAmountMap } = addMonthGaps(data, dateFormat);

    return {
        data: dataWithGaps,
        ticks: ticks,
        monthsColumnAmountMap,
    };
};

const addMonthGaps = (
    data: Array<WeekViewExposureDataSeries>,
    xAxisDateFormat: string,
): {
    dataWithGaps: Array<WeekViewExposureDataSeries>;
    ticks: Array<string>;
    monthsColumnAmountMap: Record<string, number>;
} => {
    const dataWithGaps: Array<WeekViewExposureDataSeries> = [];
    const monthsColumnAmountMap: Record<string, number> = {};
    const dataLength = data.length;
    if (dataLength > 20) {
        dataWithGaps.push({});
        dataWithGaps.push({});
    }
    const ticks: Array<string> = [];
    const labelsAdded: Array<string> = [];
    data.forEach((p, i) => {
        dataWithGaps.push(p);
        const monthData = categoryNameToMonthNum(p['categoryName'] || '', xAxisDateFormat);
        const month = monthData.dateString;
        const monthWeekNum = monthData.weekOfMonth;

        if (!labelsAdded.includes(month)) {
            p.xAxisLabel = month;
            labelsAdded.push(month);
            ticks.push(month);
            monthsColumnAmountMap[month] = parseInt(monthWeekNum);
        }

        if (dataLength - 1 > i + 1) {
            const pPlusOne = data[i + 1];
            const monthPlusOne = categoryNameToMonthNum(pPlusOne['categoryName'] || '', xAxisDateFormat).dateString;
            if (monthPlusOne !== month) {
                dataWithGaps.push({
                    // xAxisLabel: month
                });
            }
        }
        if (dataLength - 1 === i) {
            dataWithGaps.push({
                // xAxisLabel: month
            });
        }
    });
    return {
        dataWithGaps,
        ticks,
        monthsColumnAmountMap,
    };
};

interface ExposureGraphProps {
    currency: Currency;
    data: GenerateWeekViewExposureReportForCurrencyResponse | undefined;
    width: number;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
    tooltipRoot: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
    },
    tooltipHeading: {},
    tooltipSubHeading: {
        fontSize: '12px',
        color: theme.palette.grey[200],
    },
}));

export const WeekViewExposureGraph: React.FC<ExposureGraphProps> = (props: ExposureGraphProps) => {
    if (!props.data) {
        return <div />;
    }
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const dateFormat = 'MMM-YY';
    const graphData = transformMonthViewData(props.data, dateFormat);

    const TickFormatter = (tick: number): string => {
        // return (tick / scaleToNumber(scale)) //.toFixed(0)
        return roundScaleAndFormatNumber(tick || 0, currencyCode);
    };

    const currencyCode = props.currency ? props.currency.symbol : '';
    const tooltip: React.ReactElement = (
        <CustomTooltip
            currency={props.currency}
            heading={(data: WeekViewExposureDataSeries) => {
                const { dateString, weekOfMonth } = categoryNameToMonthNum(data['categoryName'] || '', 'MMMM');
                const startDate = moment.unix(data['startDate'] || 0).format('DD/MM/YYYY');
                const endDate = moment.unix(data['endDate'] || 0).format('DD/MM/YYYY');
                return (
                    <div className={classes.tooltipRoot}>
                        <div className={classes.tooltipHeading}>{`${dateString} Week ${weekOfMonth}`}</div>
                        <div className={classes.tooltipSubHeading}>{`${startDate} - ${endDate}`}</div>
                    </div>
                );
            }}
            valueFormatter={(val: number): string => {
                return roundScaleAndFormatNumber(val || 0, currencyCode);
            }}
        >
            <CustomTooltipSection
                heading={'Purchase Exposure'}
                hideIfZero
                style={{ color: theme.palette.custom.import.main }}
            >
                <CustomTooltipSectionLine dataKey={'purchaseExposureTotal'} heading={'Total'} />
            </CustomTooltipSection>
            <CustomTooltipSection
                heading={'Sales Exposure'}
                hideIfZero
                style={{ color: theme.palette.custom.export.main }}
            >
                <CustomTooltipSectionLine dataKey={'salesExposureTotal'} heading={'Total'} />
            </CustomTooltipSection>
            <CustomTooltipSection
                heading={'FEC Balances Maturing'}
                hideIfZero
                style={{ color: theme.palette.custom.data.graphC }}
            >
                <CustomTooltipSectionLine dataKey={'openTradeBalanceImport'} heading={'Purchase Hedges'} />
                <CustomTooltipSectionLine dataKey={'openTradeBalanceExport'} heading={'Sales Hedges'} />
            </CustomTooltipSection>
        </CustomTooltip>
    );

    const ImportStack = 'ImportStack';
    const ExportStack = 'ExportStack';

    return (
        <div>
            <BarChart
                barCategoryGap={'15%'}
                barGap={0}
                data={graphData.data || []}
                height={360}
                margin={{
                    top: 15,
                    right: 30,
                    left: 35,
                    bottom: 10,
                }}
                stackOffset={'sign'}
                width={props.width || 1000}
            >
                <XAxis
                    axisLine={false}
                    dataKey="xAxisLabel"
                    interval={0}
                    orientation={'top'}
                    stroke={'white'}
                    tickMargin={20}
                    tickLine={false}
                    angle={-45}
                    tick={{ fontSize: '12px' }}
                />
                <YAxis
                    allowDecimals
                    axisLine={false}
                    interval={0}
                    tick={{ fill: theme.palette.grey[200] }}
                    tickFormatter={TickFormatter}
                    tickLine={false}
                />
                <Tooltip
                    content={tooltip}
                    cursor={{ fill: '#1E2036' }}
                    offset={calculateTooltipOffset(props.width, graphData?.data?.length)}
                />
                <CartesianGrid stroke={theme.palette.custom.dividerExtended.ver_div2} vertical={false} />
                <Bar
                    dataKey={'invoiceOverdueImport'}
                    fill={theme.palette.custom.data.graphB}
                    stackId={ImportStack}
                    stroke={theme.palette.custom.data.graphB}
                />
                <Bar
                    dataKey={'invoiceOverdueExport'}
                    fill={theme.palette.custom.data.graphB}
                    stackId={ExportStack}
                    stroke={theme.palette.custom.data.graphB}
                />
                <Bar
                    dataKey={'invoiceUnRealisedImport'}
                    fill={theme.palette.custom.import.main}
                    stackId={ImportStack}
                    stroke={theme.palette.custom.import.main}
                />
                <Bar
                    dataKey={'invoiceUnRealisedExport'}
                    fill={theme.palette.custom.export.main}
                    stackId={ExportStack}
                    stroke={theme.palette.custom.export.main}
                />
                <Bar
                    dataKey={'openTradeBalanceImport'}
                    fill={theme.palette.custom.data.graphC}
                    stackId={ImportStack}
                    stroke={theme.palette.custom.data.graphC}
                />
                <Bar
                    dataKey={'openTradeBalanceExport'}
                    fill={theme.palette.custom.data.graphC}
                    stackId={ExportStack}
                    stroke={theme.palette.custom.data.graphC}
                />
                <ReferenceLine stroke={'#C6C6C6'} y={0} />
            </BarChart>
        </div>
    );
};
