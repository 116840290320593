/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    withStyles,
    withTheme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Info as InfoIcon } from '@material-ui/icons';
import { ErrorIcon, SuccessIcon, WarningIcon } from 'icons';
import { HexToRGBA } from 'utils';

const styles = () => ({});

const autoCapitalise = (text: string | undefined) => {
    if (text) {
        const sentences = text.trim().split('.');
        try {
            const newSentences = [];
            for (const sentence of sentences) {
                newSentences.push(
                    (sentence.toLowerCase().charAt(0).toUpperCase() + sentence.toLowerCase().substring(1)).trim(),
                );
            }
            return newSentences.join('. ').trim();
        } catch {
            return undefined;
        }
    }
};

function NotificationSweetAlert(props: {
    classes?: Record<string, unknown>;
    errorDismissButtonText?: string;
    infoMessage?: string;
    onCancel?: () => void;
    onClose?: () => void;
    onConfirm?: () => void;
    onDismiss?: () => void;
    options?: Record<string, unknown>;
    errorMessage?: string | undefined;
    successMessage?: string | undefined;
    warningMessage?: string | undefined;
    confirmationMessage?: string | undefined;
    headerConfimationMessage?: string | undefined;
    hideButton?: boolean;
    autoFormat?: boolean;
}) {
    const {
        classes,
        errorMessage,
        infoMessage,
        onClose,
        onConfirm,
        options = {},
        successMessage,
        warningMessage,
        confirmationMessage,
        errorDismissButtonText,
        headerConfimationMessage,
        hideButton,
        onDismiss,
    } = props;

    const [valid, setValid] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showInfo, setShowInfo] = useState<boolean>(false);

    useEffect(() => {
        setValid(validate(successMessage, infoMessage, warningMessage, errorMessage, confirmationMessage));
        setShowInfo(!!infoMessage && infoMessage !== '');
        setShowError(!!errorMessage && errorMessage !== '');
        setShowWarning(!!warningMessage && warningMessage !== '');
        setShowConfirmation(!!confirmationMessage && confirmationMessage !== '');
        setShowSuccess(!!successMessage && successMessage !== '');
    }, [errorMessage, warningMessage, successMessage, infoMessage, confirmationMessage]);

    if (!valid) {
        return <div />;
    }

    if (showError) {
        return (
            <Error
                errorDismissButtonText={errorDismissButtonText}
                hideButton={hideButton}
                message={errorMessage}
                onClose={() => {
                    onClose && onClose();
                    onDismiss && onDismiss();
                }}
                open={showError}
            />
        );
    } else if (showWarning) {
        return (
            <Warning
                classes={classes}
                message={warningMessage}
                onClose={onClose}
                onContinue={onConfirm}
                open={showWarning}
            />
        );
    } else if (showConfirmation) {
        return (
            <ConfimUnlinking
                classes={classes}
                message={confirmationMessage}
                onClose={onClose}
                onContinue={onConfirm}
                open={showConfirmation}
                headerConfimationMessage={headerConfimationMessage}
            />
        );
    } else if (showSuccess) {
        return <Success message={successMessage} onClose={onClose} open={showSuccess} />;
    } else if (showInfo) {
        return (
            <Info
                classes={classes}
                message={infoMessage}
                onClose={onClose}
                onContinue={onConfirm}
                open={showInfo}
                options={options}
            />
        );
    } else {
        return <div />;
    }
}

const successDialogStyle = makeStyles((theme: any) => ({
    paper: {
        background: theme.palette.success.main,
        width: 479,
        height: 'auto',
        minHeight: 297,
        textAlign: 'center',
    },
}));
const Success = withStyles((theme) => ({
    icon: {
        width: 88,
        height: 88,
    },
    backDrop: {
        background: HexToRGBA(theme.palette.background.paper, 0.5),
    },
}))(
    (props: {
        message?: string;
        open?: boolean;
        onClose?: () => void;
        classes?: any;
        theme?: any;
        hideButton?: boolean;
        autoFormat?: boolean;
    }) => {
        const { message, open, onClose, classes, theme } = props;
        const [timeoutInstance, setTimeoutInstance] = useState<number | undefined>(undefined);
        useEffect(() => {
            // create timeout for success only once on component mount
            if (message) {
                setTimeoutInstance(setTimeout(onClose as TimerHandler, 1500));
            }
        }, [message, onClose]);

        return (
            <Dialog
                BackdropProps={{
                    classes: { root: classes.backDrop },
                }}
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                classes={successDialogStyle(theme)}
                onClose={() => {
                    onClose && onClose();
                    clearTimeout(timeoutInstance);
                }}
                open={!!open}
            >
                <DialogTitle>
                    <SuccessIcon /* className={classes.icon} */ />
                    <Typography component="p" variant={'h4'}>
                        Action Confirmed
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body2'} style={{ whiteSpace: 'pre' }}>
                        {props.autoFormat ? autoCapitalise(message) : message}
                    </Typography>
                </DialogContent>
            </Dialog>
        );
    },
);

const errorDialogStyle = makeStyles((theme: any) => ({
    paper: {
        background: theme.palette.error.main,
        width: 479,
        height: 'auto',
        minHeight: 297,
        textAlign: 'center',
    },
}));
const errorStyle = (theme: any) => ({
    icon: {
        width: 88,
        height: 88,
    },
    button: {
        color: theme.palette.text.primary,
        backgroundColor: HexToRGBA(theme.palette.text.primary, 0.2),
        '&:hover': {
            color: theme.palette.error.dark,
            backgroundColor: theme.palette.text.primary,
        },
        width: '50%',
        margin: '0 auto',
        marginBottom: theme.spacing(2),
    },
    backDrop: {
        background: HexToRGBA('#000', 0.5),
    },
});

const Error = withStyles(errorStyle)(
    (props: {
        message?: string;
        open?: boolean;
        onClose?: () => void;
        classes?: any;
        theme?: any;
        errorDismissButtonText?: string;
        hideButton?: boolean;
        autoFormat?: boolean;
    }) => {
        const { message, open, onClose, classes, theme, errorDismissButtonText, hideButton } = props;
        return (
            <Dialog
                BackdropProps={{
                    classes: { root: classes.backDrop },
                }}
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                classes={errorDialogStyle(theme)}
                onClose={onClose}
                open={!!open}
            >
                <DialogTitle>
                    <ErrorIcon />
                    <Typography component="p" variant="h4">
                        Action Failed
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body2'} style={{ whiteSpace: props.autoFormat ? 'inherit' : 'pre' }}>
                        {props.autoFormat ? autoCapitalise(message) : message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {!hideButton && (
                        <Button className={classes.button} color="primary" href={''} onClick={onClose}>
                            {errorDismissButtonText || 'Dismiss'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    },
);

const warningDialogStyle = makeStyles((theme: any) => ({
    paper: {
        background: theme.palette.warning.main,
        width: 480,
        height: 'auto',
        minHeight: 297,
        textAlign: 'center',
    },
}));
const warningStyle = (theme: any) => ({
    icon: {
        width: 88,
        height: 88,
    },
    button: {
        color: theme.palette.text.primary,
        backgroundColor: HexToRGBA(theme.palette.text.primary, 0.2),
        '&:hover': {
            color: theme.palette.warning.dark,
            backgroundColor: theme.palette.text.primary,
        },
        width: '50%',
        marginBottom: theme.spacing(2),
    },
    backDrop: {
        background: HexToRGBA('#000', 0.5),
    },
});

const Warning = withStyles(warningStyle)(
    (props: {
        message?: string;
        open?: boolean;
        onClose?: () => void;
        onContinue?: () => void;
        classes?: any;
        theme?: any;
        hideButton?: boolean;
        autoFormat?: boolean;
    }) => {
        const { message, open, onClose, onContinue, classes, theme } = props;
        return (
            <Dialog
                BackdropProps={{
                    classes: { root: classes.backDrop },
                }}
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                classes={warningDialogStyle(theme)}
                onClose={onClose}
                open={!!open}
            >
                <DialogTitle>
                    <WarningIcon />
                    <Typography component="p" variant={'h4'}>
                        Warning!
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body2'} style={{ whiteSpace: props.autoFormat ? 'inherit' : 'pre' }}>
                        {props.autoFormat ? autoCapitalise(message) : message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} color="primary" href={''} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button autoFocus className={classes.button} color="primary" href={''} onClick={onContinue}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
);

const confirmationDialogStyle = makeStyles((theme: any) => ({
    paper: {
        background: theme.palette.background.paper,
        width: 582,
        minHeight: 206,
        textAlign: 'left',
        marginLeft: '0',
    },
}));

const confirmationStyle = (theme: any) => ({
    textBody: {
        color: theme.palette.error.dark,
        marginTop: theme.spacing(2),
        fontSize: 15,
    },
    header: {
        background: theme.palette.background.default,
        minHeight: 20,
        marginBottom: theme.spacing(2),
        padding: '5px 22px',
    },
    cancelButton: {
        color: theme.palette.common.white,
        backgroundColor: HexToRGBA(theme.palette.background.paper, 0.8),
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.background.paper,
        },
        border: '1.5px solid white',
        marginBottom: theme.spacing(2),
        width: '110px',
        borderRadius: '5px',
        backDrop: {
            background: HexToRGBA('#000', 0.5),
        },
    },
    confirmButton: {
        color: theme.palette.common.white,
        backgroundColor: HexToRGBA(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        width: '110px',
        marginBottom: theme.spacing(2),
        backDrop: {
            background: HexToRGBA('#000', 0.5),
        },
    },
});

const ConfimUnlinking = withStyles(confirmationStyle)(
    (props: {
        message?: string;
        open?: boolean;
        onClose?: () => void;
        onContinue?: () => void;
        classes?: any;
        theme?: any;
        hideButton?: boolean;
        autoFormat?: boolean;
        headerConfimationMessage?: string;
    }) => {
        const { message, open, onClose, onContinue, classes, theme, headerConfimationMessage } = props;
        return (
            <Dialog
                BackdropProps={{
                    classes: { root: classes.backDrop },
                }}
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                classes={confirmationDialogStyle(theme)}
                onClose={onClose}
                open={!!open}
            >
                <DialogTitle className={classes.header}>
                    <Typography component="p" variant={'inherit'}>
                        {props.autoFormat ? autoCapitalise(headerConfimationMessage) : headerConfimationMessage}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body2'} style={{ whiteSpace: props.autoFormat ? 'inherit' : 'pre' }}>
                        {props.autoFormat ? autoCapitalise(message) : message}
                    </Typography>
                    <Typography className={classes.textBody} component="p" variant={'inherit'}>
                        This Action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} color="primary" href={''} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button autoFocus className={classes.confirmButton} color="primary" href={''} onClick={onContinue}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
);

const infoDialogStyle = makeStyles((theme: any) => ({
    paper: {
        background: theme.palette.primary.light,
        width: 'auto',
        minWidth: 479,
        height: 'auto',
        minHeight: 297,
        textAlign: 'center',
    },
}));

const infoStyle = (theme: any) => ({
    icon: {
        width: 88,
        height: 88,
    },
    button: {
        color: theme.palette.text.primary,
        backgroundColor: HexToRGBA(theme.palette.text.primary, 0.2),
        '&:hover': {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.text.primary,
        },
        width: '50%',
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    backDrop: {
        background: HexToRGBA('#000', 0.5),
    },
});

const Info = withStyles(infoStyle)(
    (props: {
        message?: string;
        open?: boolean;
        onClose?: () => void;
        onContinue?: () => void;
        classes?: any;
        theme?: any;
        options?: any;
        autoFormat?: boolean;
    }) => {
        const { message, open, onClose, onContinue, classes, options, theme } = props;
        return (
            <Dialog
                BackdropProps={{
                    classes: { root: classes.backDrop },
                }}
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                classes={infoDialogStyle(theme)}
                onClose={onClose}
                open={!!open}
            >
                <DialogTitle>
                    <InfoIcon />
                    <Typography component="p" variant={'h4'}>
                        {options.title || 'Info!'}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body2'} style={{ whiteSpace: props.autoFormat ? 'inherit' : 'pre' }}>
                        {props.autoFormat ? autoCapitalise(message) : message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {!options.hideCancel && (
                        <Button className={classes.button} color="primary" href={''} onClick={onClose}>
                            {options.cancelButtonText || 'Cancel'}
                        </Button>
                    )}
                    <Button autoFocus className={classes.button} color="primary" href={''} onClick={onContinue}>
                        {options.continueButtonText || 'Continue'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
);

const validate = (
    successMessage: string | undefined,
    infoMessage: string | undefined,
    warningMessage: string | undefined,
    errorMessage: string | undefined,
    confirmationMessage: string | undefined,
): boolean => {
    let total = 0;
    if (successMessage && successMessage !== '') {
        total += 1;
    }
    if (infoMessage && infoMessage !== '') {
        total += 1;
    }
    if (warningMessage && warningMessage !== '') {
        total += 1;
    }

    if (confirmationMessage && confirmationMessage !== '') {
        total += 1;
    }
    if (errorMessage && errorMessage !== '') {
        total += 1;
    }

    if (total > 1) {
        // eslint-disable-next-line no-console
        console.error(
            'Notification SweetAlert has more than one message type set. Only one message can be set at any given instance.',
        );
        // eslint-disable-next-line no-console
        console.error('successMessage: ', successMessage);
        // eslint-disable-next-line no-console
        console.error('infoMessage: ', infoMessage);
        // eslint-disable-next-line no-console
        console.error('confirmationMessage: ', confirmationMessage);
        // eslint-disable-next-line no-console
        console.error('warningMessage: ', warningMessage);
        // eslint-disable-next-line no-console
        console.error('errorMessage: ', errorMessage);
        return false;
    }

    return true;
};

export default withTheme(withStyles(styles)(NotificationSweetAlert));
