/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, makeStyles, Tooltip, TextField } from '@material-ui/core';
import { Pageview, RemoveCircleOutline as DeLinkIcon } from '@material-ui/icons';
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { HexToRGBA } from 'utils';
import { CustomTheme } from 'theme/custom';
import { Invoice } from 'popcorn-js/invoice';
import { LinkedInvoice } from 'popcorn-js/order';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        display: 'flex',
        textAlign: 'center',
        padding: theme.spacing(),
        width: '100%',
        height: '33px',
    },
    formField: {
        color: theme.palette.custom.text.body,
    },
    title: {
        color: theme.palette.custom.text.accent,
    },
    column: {
        width: '200px',
        flex: 1,
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
    header: {
        flex: 1,
        width: '200px',
        fontWeight: 'bold',
        font: 'roboto',
        fontSize: 14,
        color: theme.palette.custom.text.label,
    },
    iconButton: {
        padding: '0px',
        paddingTop: '3px',
        flex: '0 0 30px',
    },
}));

export const ListItem = (props: {
    columns: any[];
    entity?: any;
    header?: boolean;
    hideTooltip?: boolean;
    onClick?: (i: Invoice) => void;
    additionalActions?: ReactElement[];
    delinkOnClick?: (i: LinkedInvoice) => void;
}): ReactElement => {
    const classes = useStyles();

    return (
        <div
            className={classes.root}
            onClick={() => {
                if (props.onClick && !props.delinkOnClick) {
                    props.onClick(props.entity);
                }
            }}
            style={{
                borderWidth: props.header ? '0 0 1px 0px' : '1px 0 0 0',
                cursor: props.header ? '' : 'pointer',
            }}
        >
            {props.columns.map((c, key) => {
                return (
                    <TextField
                        InputProps={{
                            className: props.header
                                ? classes.header
                                : c.className
                                ? classNames(c.className, classes.column)
                                : classes.column,
                            startAdornment: !props.header && c.currencySymbol,
                            disableUnderline: true,
                            readOnly: true,
                        }}
                        key={key}
                        value={props.header ? c.Header : props.entity[c.accessor]}
                    />
                );
            })}
            <div className={classes.iconButton}>
                {!props.header && !props.hideTooltip && (
                    <Tooltip title={'View Details'}>
                        <IconButton
                            key={'view'}
                            onClick={() => {
                                if (props.onClick) {
                                    props.onClick(props.entity);
                                }
                            }}
                            style={{
                                padding: '0px',
                            }}
                        >
                            <Pageview />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {!props.delinkOnClick && (
                <div className={classes.iconButton}>{!props.header && props.additionalActions}</div>
            )}
            {props.delinkOnClick && (
                <div className={classes.iconButton}>
                    <Tooltip key={'unlink'} title={'Unlink From Invoice'}>
                        <IconButton
                            onClick={() => {
                                if (props.delinkOnClick) {
                                    props.delinkOnClick(props.entity);
                                }
                            }}
                            style={{
                                padding: '0px',
                            }}
                        >
                            <DeLinkIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};
