import React, { ChangeEvent, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Card, CardHeader, createStyles, IconButton, makeStyles, Popover, Tooltip, useTheme } from '@material-ui/core';
import { Client, ProcessingOrg } from 'popcorn-js/party';
import { useService, useServiceSync } from 'hooks/useService';
import DetailDialog from 'components/tradeV2/TradeDetailDialog';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import { ImportExport, PartyType, Trade, TradeDirection, TradeStatus, TradeType } from 'popcorn-js/tradeV2';
import { displayAmount, displayDate, displayRate, displayRateSixDecimalPlaces, getMidDay, HexToRGBA } from 'utils';
import { debounce, get } from 'lodash';
import moment from 'moment';
import { useStyletron } from 'styletron-react';
import { CustomTheme } from 'theme/custom';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, Item, ITEM_VARIATION, STATES } from 'components/CardHeader/StandardCardHeader';
import { Handler as TradeHandler } from 'popcorn-js/tradeV2/handler';
import { DeletePermanentlyRequest, Handler as OptionHandler, RecordResponse } from 'popcorn-js/options/handler';
import { financialYears, FindRequest, FindResponse } from 'popcorn-js';
import { Clear as MdClear, FileCopy, InsertDriveFile } from '@material-ui/icons';
import Table from 'components/Table/Table';
import { AppContext, AppContextT } from 'context';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { BaseButton, BaseButtonGroup, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import CreateSwapLink from 'views/FECManagementStationV2/CreateSwapLink';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { format, isEqual, isValid, parseISO } from 'date-fns';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import {
    Downloader as DownloaderTrade,
    DownloadTradeRequest,
    DownloadTradeResponse,
} from 'popcorn-js/tradeV2/downloader';
import { OptionsFECTableTab } from 'views/FECManagementStationV2/OptionsFECTableTab';
import { OptionPopover } from 'views/FECManagementStationV2/OptionPopover';
import { Option, OptionStatus, OptionType } from 'popcorn-js/options';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Downloader, DownloadRequest, DownloadResponse } from 'popcorn-js/options/downloader';
import FileSaver from 'file-saver';
import { FieldErrors, TradeValues } from 'components/tradeV2/ticket';
import Big from 'big.js';
import { Currency } from 'popcorn-js/currency';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import Generator, { GenerateOpenHedgeReportRequest, GenerateOpenHedgeReportResponse } from 'popcorn-js/report';
import TradeTicket from 'components/tradeV2/ticket/ParentTicket';
import { Counterparty } from 'popcorn-js/counterparty';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'popcorn-js/party/processingOrg/recordkeeper';
import { Handler as ClientHandler } from 'popcorn-js/party/client/handler';
import { SwitchContextLink } from 'views/FECManagementStationV2/SwitchContextLink';

const importExportOptions = [{ value: ImportExport.IMPORT }, { value: ImportExport.EXPORT }];

const tradeStatuses = [
    {
        value: TradeStatus.OPEN,
        label: TradeStatus.OPEN,
    },
    {
        value: TradeStatus.MATURED,
        label: TradeStatus.MATURED,
    },
];
const tradeDirections = [
    {
        value: TradeDirection.BUY,
        label: TradeDirection.BUY,
    },
    {
        value: TradeDirection.SELL,
        label: TradeDirection.SELL,
    },
];

const FECTable: React.FC = () => {
    const classes = useStyles(useTheme());
    const appContext = useContext<AppContextT>(AppContext);
    const serviceContextValue = useContext<ServiceContextT>(ServiceContext);
    const currencyPairs = appContext.currencyPairs || [];
    const currencies = appContext.currencies || [];
    const myParty = (appContext.party as Client | ProcessingOrg) || ({} as Client);
    const partyType = appContext.partyType || PartyType.CLIENT;
    // notifications
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [confirmationMethod, setConfirmationMethod] = useState<undefined | (() => void)>(undefined);
    // state
    const [selectedCurrencyPair, setSelectedCurrencyPair] = useState<CurrencyPair | undefined>();
    const [direction, setDirection] = useState<TradeDirection | undefined>();
    const [type, setType] = useState<OptionType | undefined>();
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<undefined | Element | ((element: Element) => Element)>();
    const [newOptionPopoverAnchor, setNewOptionPopoverAnchor] = useState<
        undefined | Element | ((element: Element) => Element)
    >();
    const [newTradePopoverOpen, setNewTradePopoverOpen] = useState<boolean>(false);
    const [colConfigOpen, setColConfigOpen] = useState<boolean>(false);
    const [optionsColConfigOpen, setOptionsColConfigOpen] = useState<boolean>(false);
    const [showFilterRow, setShowFilterRow] = useState<boolean>(false);
    const [showOptionsFilterRow, setOptionsShowFilterRow] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<number>(0);
    const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false);
    const [ticketState, setTicketState] = useState<number>(ticketStates.nop);
    const [selected, setSelected] = React.useState<Trade[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [tradesTotal, setTradesTotal] = useState<number>(0);
    const [tradesResponse, setTradesResponse] = useState<Trade[] | undefined>();
    const [selectedOptions, setSelectedOptions] = React.useState<Option | undefined>();
    const [optionDetailDialogOpen, setOptionDetailDialogOpen] = useState<boolean>(false);
    const [tradeValues, setTradeValues] = useState<TradeValues>();
    const [isClient, setIsClient] = useState<boolean>(false);
    const [parentParty, setParentParty] = useState<string>();
    const usersContext = appContext.currentContext?.partyType;

    const [query, setQuery] = useState<Query>({
        sortBy: ['maturityDate', 'availableBalance.value', 'currencyPair'],
        order: ['asc', 'desc', 'asc'],
        limit: 50,
        offset: 0,
    });
    const [criteria, setCriteria] = useState<Criteria>([
        {
            type: CriteriaType.TimeCriterion,
            field: 'maturityDate',
            startDate: {
                ignore: false,
                date: moment().local().startOf('day').toISOString(),
                inclusive: true,
            },
            endDate: {
                ignore: true,
                date: new Date().toISOString(),
                inclusive: true,
            },
        },
        {
            type: CriteriaType.NumberCriterion,
            field: 'availableBalance.value',
            lessThan: {
                ignore: true,
            },
            moreThan: {
                amount: 0.0004,
                inclusive: false,
            },
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.FORWARD,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.EXTENSION,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.SPOT,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.DRAWDOWN,
        },
        {
            type: CriteriaType.TextCriterion,
            field: 'type',
            text: TradeType.SWAP,
        },
        {
            type: CriteriaType.BoolCriterion,
            field: 'cancellation',
            value: false,
        },
        { type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' },
    ]);

    //services
    const [findTrades] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);
    const [findClient] = useServiceSync<FindRequest, FindResponse<Client>>(ClientHandler.find);
    const [deleteOptionPermanently] = useServiceSync<DeletePermanentlyRequest, RecordResponse>(
        OptionHandler.DeletePermanently,
    );
    const [
        { response: deleteTradeResponse, loading: deleteTradeLoading, error: deleteTradeError },
        setDeleteTradesRequest,
    ] = useService(undefined, TradeHandler.DeleteTransactionForever);
    const [findProcessingOrg] = useServiceSync<FindRequest, FindResponse<ProcessingOrg>>(
        ProcessingOrgRecordkeeper.find,
    );
    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );
    const [downloadOptions] = useServiceSync<DownloadRequest, DownloadResponse>(Downloader.download);
    const [DownloadFECTrades] = useServiceSync<DownloadTradeRequest, DownloadTradeResponse>(
        DownloaderTrade.downloadFECs,
    );
    const [DownloadTradesExec] = useServiceSync<DownloadTradeRequest, DownloadTradeResponse>(
        DownloaderTrade.downloadTrades,
    );
    const [GenerateOpenHedges] = useServiceSync<GenerateOpenHedgeReportRequest, GenerateOpenHedgeReportResponse>(
        Generator.GenerateOpenHedgesReport,
    );

    const retrieveParentParty = async () => {
        try {
            const retrieveParentPartyResponse = await processingOrgRecordkeeperRetrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: appContext.parentPartyCode },
            });
            setParentParty(retrieveParentPartyResponse.processingOrg.name.toUpperCase());
        } catch (e) {
            console.error('error finding parent party', e);
        }
    };

    const findTradesProcessingOrgs = async (_trades: Trade[]): Promise<ProcessingOrg[]> => {
        const proOrgFindCrit: Criteria = _trades
            .filter((b) => b.processingOrgPartyCode != '')
            .map((b) => ({
                type: CriteriaType.ExactCriterion,
                field: 'partyCode',
                text: b.processingOrgPartyCode,
            }));
        const response = await findProcessingOrg({
            criteria: proOrgFindCrit,
        });
        return response.records;
    };

    const findTradesTradingParties = async (_trades: Trade[]): Promise<Client[]> => {
        const counterpartyFindCrit: Criteria = _trades
            .filter((b) => b.counterpartyId !== '')
            .map((b) => ({ type: CriteriaType.ExactCriterion, field: 'partyCode', text: b.tradingPartyCode }));
        const response = await findClient({
            criteria: counterpartyFindCrit,
        });
        return response.records;
    };

    const findEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await findTrades({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            const clients = await findTradesTradingParties(result.records);
            const proOrgs = await findTradesProcessingOrgs(result.records);
            setTradesResponse(
                result.records.map((i: Trade) => ({
                    ...i,
                    counterparty: clients.find((c: Client) => c.partyCode === i.tradingPartyCode)?.name,
                    processingOrgName: proOrgs.find((c: ProcessingOrg) => c.partyCode === i.processingOrgPartyCode)
                        ?.name,
                })),
            );
            setTradesTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const StyledMaturingValue = (props: { t: Trade }) => {
        const maturity = displayDate(props.t.maturityDate?.toString()).slice(0, 10);

        const getDay = new Date();
        const today = getDay.toISOString().slice(0, 10);

        const tomorrow = new Date(getDay);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const nextD = tomorrow.toISOString().slice(0, 10);

        const inTwoDays = new Date(getDay);
        inTwoDays.setDate(inTwoDays.getDate() + 2);
        const twoDaysAfter = inTwoDays.toISOString().slice(0, 10);

        const [css] = useStyletron();
        const theme = useTheme<CustomTheme>();
        let color = 'inherit';
        if (maturity === today) {
            color = theme.palette.custom.data.overdue.dark;
        }
        if (maturity === nextD || maturity === twoDaysAfter) {
            color = theme.palette.custom.stellcapBrand1.light;
        }
        return (
            <div>
                <span className={css({ color })}>{displayDate(props.t.maturityDate?.toString())}</span>
            </div>
        );
    };

    const handleCloseDialog = async () => {
        setDetailDialogOpen(false);
        setSelected([]);
        await findEntities(criteria, query);
    };

    const closeTicket = async () => {
        setTicketState(ticketStates.nop);
        if (tabValue === 1 || tabValue === 4) {
            setSelectedOptions(undefined);
            optionsReloadTrigger();
        } else {
            setSelected([]);
            await findEntities(criteria, query);
        }
    };

    const handleTabChange = (newValue: number) => {
        setShowFilterRow(false);
        setOptionsShowFilterRow(false);
        setTabValue(newValue);
        switch (newValue) {
            case 0: //Open FECs
                handleFilterChange(
                    [
                        {
                            type: CriteriaType.TimeCriterion,
                            field: 'maturityDate',
                            startDate: {
                                ignore: false,
                                date: moment().local().startOf('day').toISOString(),
                                inclusive: true,
                            },
                            endDate: {
                                ignore: true,
                                date: new Date().toISOString(),
                                inclusive: true,
                            },
                        },
                    ],
                    0,
                );
                break;
            case 1: //Open options
                setCriteria([{ type: CriteriaType.TextCriterion, field: 'status', text: 'OPEN' }]);
                break;
            case 2: {
                //FECs Matured and Unlinked - Default filter
                handleFilterChange(
                    [
                        {
                            type: CriteriaType.TextCriterion,
                            field: 'financialYear',
                            text: 'CURRENT',
                        },
                        {
                            type: CriteriaType.TimeCriterion,
                            field: 'maturityDate',
                            startDate: {
                                ignore: true,
                                date: new Date().toISOString(),
                                inclusive: true,
                            },
                            endDate: {
                                ignore: false,
                                date: moment().local().endOf('day').subtract(1, 'days').toISOString(),
                                inclusive: false,
                            },
                        },
                    ],
                    2,
                );
                break;
            }
            case 3: //All FECs
                handleFilterChange(
                    [
                        //Default for ALL FECs
                        {
                            type: CriteriaType.TextCriterion,
                            field: 'financialYear',
                            text: 'CURRENT',
                        },
                        {
                            type: CriteriaType.NumberCriterion,
                            field: 'dealRate.value',
                            lessThan: {
                                ignore: true,
                            },
                            moreThan: {
                                amount: 0,
                                inclusive: false,
                            },
                        },
                    ],
                    3,
                );
                break;
            case 4: {
                //AlL Options
                setCriteria([{ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' }]);
            }
        }
    };

    const handleOptionsFilterChange = useCallback(
        debounce(async (newCriteria: Criteria) => {
            const newQuery = {
                ...query,
                offset: 0,
            };
            const allCriteria = newCriteria.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );
            if (financialYears[0] === 'CURRENT') {
                //Add ALL to slice
                financialYears.unshift('ALL');
            }
            const finYearCrit = newCriteria.filter((f: Criterion) => f.field === 'financialYear');
            if (finYearCrit.length === 0) {
                newCriteria.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' });
            } else if (allCriteria.length > 0) {
                {
                    if (financialYears[0] === 'ALL') {
                        //remove ALL from slice then push
                        const index = financialYears.indexOf('ALL');
                        if (index !== -1) {
                            financialYears.splice(index, 1);
                        }
                        newCriteria.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: '' });
                    }
                }
            }
            const statusCrit = newCriteria.filter((f: Criterion) => f.field === 'status');
            if (statusCrit.length === 0 && tabValue === 1) {
                newCriteria.push({ type: CriteriaType.TextCriterion, field: 'status', text: 'OPEN' });
            }
            setQuery(newQuery);
            setCriteria(newCriteria);
        }, 100),
        [query, tabValue],
    );

    const handleFilterChange = useCallback(
        debounce(async (newCriteria: Criteria, tab?: number) => {
            const newQuery = {
                ...query,
                offset: 0,
            };
            let newTab = tabValue;
            if (tab != undefined) {
                newTab = tab;
            }
            const allCriteria = newCriteria.filter(
                (f: Criterion) =>
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.TextCriterion &&
                        f.text &&
                        f.text === 'ALL') ||
                    (f.field === 'financialYear' &&
                        f.type === CriteriaType.ExactCriterion &&
                        f.text &&
                        f.text === 'ALL'),
            );

            if (newTab === 3 && newCriteria.length === 0) {
                newCriteria.push(
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'financialYear',
                        text: 'CURRENT',
                    },
                    {
                        type: CriteriaType.NumberCriterion,
                        field: 'dealRate.value',
                        lessThan: {
                            ignore: true,
                        },
                        moreThan: {
                            amount: 0,
                            inclusive: false,
                        },
                    },
                );
            }
            if (newTab === 3 && allCriteria.length > 0) {
                {
                    if (financialYears[0] === 'ALL') {
                        newCriteria.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: '' });
                    }
                }
            }

            if (newTab !== 3) {
                const finYearCrit = newCriteria.filter((f: Criterion) => f.field === 'financialYear');
                if (finYearCrit.length === 0) {
                    newCriteria.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' });
                } else if (allCriteria.length > 0) {
                    {
                        if (financialYears[0] === 'ALL') {
                            newCriteria.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: '' });
                        }
                    }
                }

                const tabCriteria = newCriteria.filter((f: Criterion) => f.field === 'maturityDate');
                if (tabCriteria.length === 0) {
                    switch (newTab) {
                        case 0: //Open FECs
                            newCriteria.push({
                                type: CriteriaType.TimeCriterion,
                                field: 'maturityDate',
                                startDate: {
                                    ignore: false,
                                    date: moment().local().startOf('day').toISOString(),
                                    inclusive: true,
                                },
                                endDate: {
                                    ignore: true,
                                    date: new Date().toISOString(),
                                    inclusive: true,
                                },
                            });
                            break;
                        case 2: {
                            //FECs Matured and Unlinked
                            newCriteria.push({
                                type: CriteriaType.TimeCriterion,
                                field: 'maturityDate',
                                startDate: {
                                    ignore: true,
                                    date: new Date().toISOString(),
                                    inclusive: true,
                                },
                                endDate: {
                                    ignore: false,
                                    date: moment().local().endOf('day').subtract(1, 'days').toISOString(),
                                    inclusive: false,
                                },
                            });
                            break;
                        }
                    }
                }
                const tradeTypeCriteria = newCriteria.filter((f: Criterion) => f.field === 'type');
                if (tradeTypeCriteria.length === 0) {
                    newCriteria.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: TradeType.FORWARD,
                    });
                    newCriteria.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: TradeType.SPOT,
                    });
                    newCriteria.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: TradeType.DRAWDOWN,
                    });
                    newCriteria.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: TradeType.EXTENSION,
                    });
                    newCriteria.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: TradeType.SWAP,
                    });
                    newCriteria.push({
                        type: CriteriaType.BoolCriterion,
                        field: 'cancellation',
                        value: false,
                    });
                }
                const tradeTotalCriteria = newCriteria.filter((f: Criterion) => f.field === 'availableBalance.value');
                if (tradeTotalCriteria.length > 0) {
                    const amount = get(tradeTotalCriteria[0], 'moreThan.amount');
                    if (amount < 0.0004) {
                        tradeTotalCriteria.forEach(
                            (f: Criterion) =>
                                f.type === CriteriaType.NumberCriterion &&
                                (f.moreThan = {
                                    amount: 0.0004,
                                    inclusive: false,
                                    ignore: false,
                                }),
                        );
                    }
                } else {
                    newCriteria.push({
                        type: CriteriaType.NumberCriterion,
                        field: 'availableBalance.value',
                        lessThan: {
                            ignore: true,
                        },
                        moreThan: {
                            amount: 0.0004,
                            inclusive: false,
                        },
                    });
                }
                if (newTab === 1) {
                    //Open Options
                    newCriteria.push({ type: CriteriaType.TextCriterion, field: 'status', text: 'OPEN' });
                }
            }

            setQuery(newQuery);
            setCriteria(newCriteria);
            await findEntities(newCriteria, newQuery);
        }, 100),
        [query, tabValue],
    );

    const handleCancelClick = (trade: Trade) => {
        const newSelectedCurrencyPair = currencyPairs.find((c) => c.name === trade.currencyPair);
        let newDirection: TradeDirection = TradeDirection.BUY;
        if (trade.direction == TradeDirection.BUY) {
            newDirection = TradeDirection.SELL;
        }
        setSelectedCurrencyPair(newSelectedCurrencyPair);
        setDirection(newDirection);
        setTicketState(ticketStates.showingTradeCancellation);
    };

    const getTradeableCurrencyPairs = () => {
        const partyCurrencyPairs = myParty.currencyPairs;
        if (partyCurrencyPairs) {
            const tradeablePairs: CurrencyPair[] = [];

            for (const currencyPairName of partyCurrencyPairs) {
                const found = currencyPairs.find((c) => c.name === currencyPairName);
                if (found) {
                    const n = found.name.indexOf('/');
                    found.displayName = found.name.substring(0, n !== -1 ? n : found.name.length);
                    const foundSymbol = currencies.find((c) => c.isoCode === found.displayName);
                    if (foundSymbol) {
                        found.displayName = found.displayName + ' (' + foundSymbol.symbol + ')';
                    }
                    tradeablePairs.push(found);
                }
            }
            return tradeablePairs;
        } else {
            return [];
        }
    };
    const tradableCurrencyPairs = getTradeableCurrencyPairs();
    const renderTickets = (): React.ReactElement => {
        switch (ticketState) {
            case ticketStates.showingOptionExercise:
                return (
                    <TradeTicket
                        closeTicket={closeTicket}
                        show={!!ticketStates.showingOptionExercise}
                        initialDirection={tradeValues?.direction}
                        currencyPairToTrade={selectedCurrencyPair}
                        tradeType={tradeValues?.tradeType || TradeType.FORWARD}
                        parentTrades={[]}
                        tradeValues={tradeValues}
                        optionExercise
                        autoFillMode={false}
                    />
                );
            case ticketStates.showingTradeExtension:
                return (
                    <TradeTicket
                        closeTicket={closeTicket}
                        show={!!ticketStates.showingTradeExtension}
                        parentTrades={selected.map((trd) => {
                            trd.maturityDate = new Date(trd.maturityDate || 0);
                            trd.captureDate = new Date();
                            trd.tradeDate = new Date();
                            return trd;
                        })}
                        tradeType={TradeType.EXTENSION}
                        autoFillMode
                        optionExercise={false}
                    />
                );
            case ticketStates.showingTradeCancellation:
                return (
                    <TradeTicket
                        closeTicket={closeTicket}
                        show={!!ticketStates.showingTradeCancellation}
                        tradeType={TradeType.CANCELLATION}
                        parentTrades={selected.map((trd) => {
                            trd.maturityDate = new Date(trd.maturityDate || 0);
                            trd.captureDate = new Date();
                            trd.tradeDate = new Date();
                            return trd;
                        })}
                        autoFillMode
                        optionExercise={false}
                    />
                );
            case ticketStates.showingTradeDrawdown:
                return (
                    <TradeTicket
                        closeTicket={closeTicket}
                        show={!!ticketStates.showingTradeDrawdown}
                        parentTrades={selected.map((trd) => {
                            trd.maturityDate = new Date(trd.maturityDate || 0);
                            trd.captureDate = new Date();
                            trd.tradeDate = new Date();
                            return trd;
                        })}
                        tradeType={TradeType.DRAWDOWN}
                        autoFillMode
                        optionExercise={false}
                    />
                );
            case ticketStates.showingTradeNew:
                return (
                    <TradeTicket
                        closeTicket={closeTicket}
                        show={!!ticketStates.showingTradeNew}
                        initialDirection={direction}
                        currencyPairToTrade={selectedCurrencyPair}
                        tradeType={TradeType.SPOT}
                        parentTrades={[]}
                        optionExercise={false}
                        autoFillMode
                    />
                );
            case ticketStates.showingTradeSwap:
                if (selectedCurrencyPair) {
                    //// TODO
                    return (
                        <ServiceContext.Provider value={{ ...serviceContextValue, tradeV2Handler: TradeHandler }}>
                            <TradeTicket
                                closeTicket={closeTicket}
                                show={!!ticketStates.showingTradeSwap}
                                initialDirection={TradeDirection.BUY}
                                currencyPairToTrade={selectedCurrencyPair}
                                parentTrades={[]}
                                tradeType={TradeType.SWAP}
                                optionExercise={false}
                                autoFillMode={true}
                            />
                        </ServiceContext.Provider>
                    );
                } else {
                    return <span />;
                }
            case ticketStates.nop:
                return <span />;
            default:
                return <span />;
        }
    };

    const ShowOptions = true;

    const itemsLeft: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: (() => {
                const opt = [
                    {
                        id: 'FECManagementStation/FECTable/open-fecs',
                        label: 'Open Trades',
                        value: 'Open Trades',
                    },
                    {
                        id: 'FECManagementStation/FECTable/open-options',
                        label: 'Open Options',
                        value: 'Open Options',
                    },
                    {
                        id: 'FECManagementStation/FECTable/fecs-matured-&-unlinked',
                        label: 'Trades Matured & Unlinked',
                        value: 'Trades Matured & Unlinked',
                    },
                    {
                        id: 'FECManagementStation/FECTable/all-fecs',
                        label: 'All Trades',
                        value: 'All Trades',
                    },
                ];
                if (ShowOptions) {
                    opt.push({
                        id: 'FECManagementStation/FECTable/all-options',
                        label: 'All Options',
                        value: 'All Options',
                    });
                }
                return opt;
            })(),
            onChange: (_event: ChangeEvent<unknown>, value: number) => {
                handleTabChange(value);
            },
            value: tabValue,
            id: 'fec-tabs',
        },
    ];
    const useTrigger = (): [triggerState: boolean, trigger: () => void] => {
        const [triggerState, setTriggerState] = useState<boolean>(false);
        return [triggerState, () => setTriggerState(!triggerState)];
    };
    const [triggerState, optionsReloadTrigger] = useTrigger();

    const handleExerciseOptions = async () => {
        setLoading(true);
        const newSelectedCurrencyPair = currencyPairs.find((c) => c.name === selectedOptions?.currencyPair);
        setSelectedCurrencyPair(newSelectedCurrencyPair);

        //Convert option to be exercised into a trade
        let direction: TradeDirection = TradeDirection.SELL;
        let buyAmount: number | undefined;
        let sellAmount: number | undefined;
        if (selectedOptions?.direction === 'SELL') {
            buyAmount = selectedOptions?.putAmount;
            sellAmount = selectedOptions?.callAmount;
            switch (selectedOptions?.type) {
                case 'PUT':
                    direction = TradeDirection.BUY;
                    break;
                case 'CALL':
                    direction = TradeDirection.SELL;
            }
        } else {
            buyAmount = selectedOptions?.callAmount;
            sellAmount = selectedOptions?.putAmount;
            switch (selectedOptions?.type) {
                case 'PUT':
                    direction = TradeDirection.SELL;
                    break;
                case 'CALL':
                    direction = TradeDirection.BUY;
            }
        }

        const splitCurrencies = selectedOptions?.currencyPair?.split('/');
        let sellCurrency: Currency | undefined;
        let buyCurrency: Currency | undefined;
        if (splitCurrencies && direction === TradeDirection.BUY) {
            sellCurrency = currencies.find((c) => c.isoCode === splitCurrencies[1]);
            buyCurrency = currencies.find((c) => c.isoCode === splitCurrencies[0]);
        } else if (splitCurrencies) {
            sellCurrency = currencies.find((c) => c.isoCode === splitCurrencies[0]);
            buyCurrency = currencies.find((c) => c.isoCode === splitCurrencies[1]);
        }

        const fieldErrors: FieldErrors = {
            externalReference: 'required',
            tradeDate: undefined,
            maturityDate: undefined,
            bank: undefined,
            forwardPoints: undefined,
            dealRate: undefined,
            billingType: 'required',
        };

        const tradeValues: TradeValues = {
            uuid: selectedOptions?.transactionID || '',
            externalReference: '',
            direction: direction,
            currencyPair: newSelectedCurrencyPair,
            sellCurrency: sellCurrency,
            buyCurrency: buyCurrency,
            notionalAmount: selectedOptions?.notionalAmount?.toString(),
            interbankRate: selectedOptions?.interbankRate?.toString(),
            bankRate: selectedOptions?.bankRate?.toString(),
            bank: selectedOptions?.bank,
            maturityDate: new Date(selectedOptions?.deliveryDate ? selectedOptions?.deliveryDate : ''),
            tradeDate: new Date(selectedOptions?.expiryDate ? selectedOptions?.expiryDate : ''),
            dealRate: selectedOptions?.strikePrice?.toString(),
            cancellation: false,
            allocations: [],
            totalParentAllocation: new Big(0),
            valid: false,
            acm: false,
            maturityDateMin: null,
            maturityDateMax: null,
            tradeType: TradeType.SPOT,
            buyAmount: buyAmount?.toString(),
            sellAmount: sellAmount?.toString(),
            forwardPoints: '0',
            fieldErrors: fieldErrors,
            importExport: selectedOptions?.importExport,
            trader: selectedOptions?.trader,
            traderOrganisation: selectedOptions?.traderOrganisation,
            notes: selectedOptions?.notes,
            optionReference: selectedOptions?.externalReference,
            optionNumber: selectedOptions?.number,
        };
        setTradeValues(tradeValues);
        //Option to be exercised is now a trade
        setTicketState(ticketStates.showingOptionExercise);
        setNewTradePopoverOpen(false);
        setLoading(false);
    };

    const handleDeleteOptionForever = async () => {
        setLoading(true);
        setSelectedOptions(undefined);
        try {
            await deleteOptionPermanently({
                identifier: {
                    type: IdentifierType.ID_IDENTIFIER,
                    id: selectedOptions?.id,
                },
            });
            optionsReloadTrigger();
            setSelectedOptions(undefined);
            setSuccessMessage('option deleted');
        } catch (e) {
            setErrorMessage(`error deleting option: ${e}`);
        }
        setLoading(false);
    };

    const handleDownloadOptions = async () => {
        setLoading(true);
        try {
            const downloadResponse = await downloadOptions({
                criteria: criteria,
                query: query,
            });
            // convert base64 to byte array
            const binData = atob(downloadResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const date = new Date();
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = date.getFullYear();
            const today = yyyy + '-' + mm + '-' + dd;
            FileSaver.saveAs(blob, 'Option Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(`error downloading options: ${e}`);
        }
        setLoading(false);
    };

    const handleDownloadFECTrades = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const downloadFECTradesResponse = await DownloadFECTrades({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            // convert base64 to byte array
            const binData = atob(downloadFECTradesResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'FEC Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
        setLoading(false);
    };

    const handleDownloadOpenHedges = async () => {
        setLoading(true);
        try {
            const generateOpenHedgesResponse = await GenerateOpenHedges({});

            // convert base64 to byte array
            const binData = atob(generateOpenHedgesResponse.excelData);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Open Hedges Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
        setLoading(false);
    };

    const downloadTrades = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const downloadTradesResponse = await DownloadTradesExec({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            // convert base64 to byte array
            const binData = atob(downloadTradesResponse.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Trade Download ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
        setLoading(false);
    };

    const generateRowActions = (): Item[] => {
        if (selectedOptions) {
            return [
                {
                    type: ITEM_VARIATION.ICON_BUTTON,
                    id: 'FECManagementStation/FECTable/exercise-option',
                    icon: ACTION_BUTTON_TYPE.CHECK_MARK,
                    helpText: 'Exercise Option',
                    onClick: () => handleExerciseOptions().finally(),
                    hide: selectedOptions.status == OptionStatus.EXERCISED || partyType !== PartyType.CLIENT,
                },
                {
                    type: ITEM_VARIATION.ICON_BUTTON,
                    id: 'FECManagementStation/FECTable/view-option-details',
                    icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                    helpText: 'View Option Details',
                    onClick: () => setOptionDetailDialogOpen(true),
                },
                {
                    type: ITEM_VARIATION.ICON_BUTTON,
                    id: 'FECManagementStation/FECTable/delete-option',
                    icon: ACTION_BUTTON_TYPE.DELETE_PERMANENTLY,
                    helpText: 'Delete Option',
                    onClick: () => {
                        setWarningMessage(
                            `Are you sure you want to permanently delete '${selectedOptions.externalReference}'?`,
                        );
                        setConfirmationMethod(() => () => {
                            setWarningMessage(undefined);
                            setConfirmationMethod(undefined);
                            handleDeleteOptionForever().finally();
                        });
                    },
                    hide: !isClient,
                },
            ];
        }

        if (!(selected && selected.length > 0)) {
            return [];
        }

        const ExtendActionErrors: string[] = [];
        const DrawDownActionErrors: string[] = [];
        const CancellationErrors: string[] = [];

        const firstDirection = selected[0].direction;
        const firstMaturity = selected[0].maturityDate;
        const firstImportExport = selected[0].importExport;
        const firstBank = selected[0].bank;
        const firstCurrencyPair = selected[0].currencyPair;

        selected.forEach((selectedRow: Trade) => {
            if (selectedRow.availableBalance && selectedRow.availableBalance <= 0) {
                ExtendActionErrors.push('No Balance left');
                DrawDownActionErrors.push('No balance left');
            }
            if (selectedRow.direction !== firstDirection) {
                const msg = 'Directions must be the same';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }

            if (
                selectedRow.maturityDate &&
                firstMaturity &&
                isValid(parseISO((firstMaturity as unknown) as string)) &&
                isValid(parseISO((selectedRow.maturityDate as unknown) as string))
            ) {
                if (
                    !isEqual(
                        getMidDay(parseISO((selectedRow.maturityDate as unknown) as string)),
                        getMidDay(parseISO((firstMaturity as unknown) as string)),
                    )
                ) {
                    const msg = 'Maturity dates must be the same';
                    CancellationErrors.push(msg);
                }
            } else {
                const msg = 'Maturity dates not supplied';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }
            if (selectedRow.importExport !== firstImportExport) {
                const msg = 'Import/export must be the same';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }
            if (selectedRow.bank !== firstBank) {
                const msg = 'Trade banks must be the same';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }
            if (selectedRow.currencyPair !== firstCurrencyPair) {
                const msg = 'Trade currency pairs must be the same';
                CancellationErrors.push(msg);
                DrawDownActionErrors.push(msg);
                ExtendActionErrors.push(msg);
            }
        });

        let items: Item[] = [] as Item[];

        if (selected.length === 1) {
            items = [
                {
                    type: ITEM_VARIATION.ICON_BUTTON,
                    id: 'FECManagementStation/HedgeMaturingTable/view-details',
                    icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                    helpText: 'View Details',
                    onClick: () => setDetailDialogOpen(true),
                },
                {
                    type: ITEM_VARIATION.ICON_BUTTON,
                    id: 'FECManagementStation/FECTable/delete-trade',
                    icon: ACTION_BUTTON_TYPE.DELETE_PERMANENTLY,
                    helpText: 'Delete Transaction',
                    onClick: () => {
                        setWarningMessage(
                            `You are about to delete '${selected[0]?.number}'. Deleting this trade will delete the whole transaction
                                           this trade belongs to. Do you want to continue?`,
                        );
                        setConfirmationMethod(() => () => {
                            setWarningMessage(undefined);
                            setConfirmationMethod(undefined);
                            setDeleteTradesRequest({
                                identifier: {
                                    type: IdentifierType.ID_IDENTIFIER,
                                    id: selected[0].transactionID,
                                },
                            });
                        });
                    },
                    hide: !isClient,
                },
            ];
        }

        const canCancel = CancellationErrors.length === 0;
        const canExtend = ExtendActionErrors.length === 0;
        const canDrawdown = DrawDownActionErrors.length === 0;

        const drawdownTypes: (TradeType | undefined)[] = [
            TradeType.EXTENSION,
            TradeType.FORWARD,
            TradeType.SPOT,
            TradeType.SWAP,
        ];
        const drawdownActionVisible = selected.every((trade: Trade) => drawdownTypes.includes(trade.type));
        if (drawdownActionVisible) {
            items.push({
                type: ITEM_VARIATION.ICON_BUTTON,
                id: 'FECManagementStation/HedgeMaturingTable/perform-drawdown',
                icon: ACTION_BUTTON_TYPE.DRAW_DOWN,
                helpText: canDrawdown ? 'Perform Drawdown' : 'Cannot Perform Drawdown, ' + DrawDownActionErrors.join(),
                onClick: () => {
                    if (canDrawdown) {
                        setTicketState(ticketStates.showingTradeDrawdown);
                    }
                },
                hide: tabValue == 3 || partyType !== PartyType.CLIENT,
            });
        }
        const extensionTypes: (TradeType | undefined)[] = [
            TradeType.DRAWDOWN,
            TradeType.EXTENSION,
            TradeType.FORWARD,
            TradeType.SPOT,
            TradeType.SWAP,
        ];
        const extensionActionVisible = selected.every((trade: Trade) => extensionTypes.includes(trade.type));
        if (extensionActionVisible) {
            items.push({
                type: ITEM_VARIATION.ICON_BUTTON,
                id: 'FECManagementStation/HedgeMaturingTable/perform-extension',
                icon: ACTION_BUTTON_TYPE.EXTEND,
                helpText: canExtend ? 'Perform Extension' : 'Cannot Perform Extension, ' + ExtendActionErrors.join(),
                onClick: () => {
                    if (canExtend) {
                        setTicketState(ticketStates.showingTradeExtension);
                    }
                },
                hide: tabValue == 3 || partyType !== PartyType.CLIENT,
            });
        }

        const cancellationTypes: (TradeType | undefined)[] = [
            TradeType.DRAWDOWN,
            TradeType.EXTENSION,
            TradeType.FORWARD,
            TradeType.SPOT,
            TradeType.SWAP,
        ];
        const cancellationActionVisible = selected.every((trade: Trade) => cancellationTypes.includes(trade.type));
        if (cancellationActionVisible) {
            items.push({
                type: ITEM_VARIATION.ICON_BUTTON,
                id: 'FECManagementStation/HedgeMaturingTable/perform-cancellation',
                icon: ACTION_BUTTON_TYPE.CANCEL,
                helpText: canCancel
                    ? 'Perform Cancellation'
                    : 'Cancellation parents Invalid, ' + CancellationErrors.join(),
                onClick: () => {
                    if (canCancel) {
                        handleCancelClick(selected[0]);
                    }
                },
                hide: tabValue == 3 || partyType !== PartyType.CLIENT,
            });
        }

        return items;
    };

    const handleCloseColConfig = () => {
        setColConfigOpen(false);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleChangeSorting = async (sortBy: string, order: QueryOrderT) => {
        const newQuery: Query = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        await findEntities(criteria, newQuery);
    };

    const handleSelectRow = (rowData: Trade) => {
        const ind = selected.findIndex((selectedTrd: Trade) => selectedTrd.id === rowData.id);
        const _selected = [...selected];
        if (tabValue === 3) {
            _selected.splice(0);
        }
        if (ind === -1) {
            _selected.push(rowData);
        } else {
            _selected.splice(ind, 1);
        }
        setSelected(_selected);
    };

    const handleSelectAll = () => {
        const newSelected = [...selected];
        if (newSelected.length !== 0) {
            setSelected([]);
        } else if (tradesResponse && tabValue !== 3) {
            tradesResponse.forEach((trade: Trade) => {
                newSelected.push(trade);
            });
            setSelected(newSelected);
        }
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const handlePriceSelection = (selectedPrice: string, direction: TradeDirection, showTicket: boolean) => {
        const newSelectedCurrencyPair = currencyPairs.find((c) => c.name === selectedPrice);
        if (showTicket) {
            setSelectedCurrencyPair(newSelectedCurrencyPair);
            setDirection(direction);
            setTicketState(ticketStates.showingTradeNew);
            setNewTradePopoverOpen(false);
        }
    };

    const handleOptionPriceSelection = (_selectedPrice: string, _type: OptionType, _showTicket: boolean) => {
        const newSelectedCurrencyPair = currencyPairs.find((c) => c.name === _selectedPrice);
        if (_showTicket) {
            setSelectedCurrencyPair(newSelectedCurrencyPair);
            setType(_type);
            setTicketState(ticketStates.showingOptionNew);
            setNewOptionPopoverAnchor(undefined);
        }
    };

    const generateProcessingOrgOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findProcessingOrg({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const generateClientOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findClient({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const amendSuccess = async () => {
        if (!selected?.length) {
            return;
        }
        const selectedTradeId = selected[0].id;
        await findEntities(criteria, query);
        const selectedTrades = tradesResponse?.filter((trd) => trd.id === selectedTradeId);
        const selectedTrade = selectedTrades?.length === 1 ? selectedTrades[0] : undefined;
        if (!selectedTrade) {
            handleCloseDialog();
            return;
        }
        setSelected([selectedTrade]);
    };

    const selectedItems = useMemo(() => {
        if (selectedOptions) {
            return 1;
        }
        if (selected && selected.length > 0) {
            return selected.length;
        }
    }, [selectedOptions, selected]);

    const tailoredState = useMemo(() => {
        if (selectedOptions) {
            return STATES.SELECTED_ROW;
        }
        if (selected && selected.length > 0) {
            return STATES.SELECTED_ROW;
        }

        return undefined;
    }, [selectedOptions, selected]);

    useEffect(() => {
        setIsClient(() => {
            return usersContext === PartyType.CLIENT;
        });
        retrieveParentParty().finally();
    }, []);

    useEffect(() => {
        if (deleteTradeResponse && !deleteTradeLoading) {
            setSuccessMessage('Trade deleted');
            setSelected([]);
            handleFilterChange([]);
        }
        if (deleteTradeError) {
            setErrorMessage(deleteTradeError);
        }
    }, [deleteTradeResponse, deleteTradeLoading, deleteTradeError]);

    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    return (
        <div id="f'cManagementStationRoot">
            {detailDialogOpen && (
                <DetailDialog
                    closeDialog={handleCloseDialog}
                    open={detailDialogOpen}
                    trade={selected[0]}
                    amendSuccess={amendSuccess}
                />
            )}
            {renderTickets()}
            <ActionsMenu
                id={'download-list'}
                anchorElement={moreOptionsAnchorEl}
                items={[
                    {
                        id: 'summary-downloads',
                        text: 'Open Hedges Report',
                        icon: <InsertDriveFile style={{ paddingRight: '10px' }} />,
                        onClick: () => {
                            handleDownloadOpenHedges().finally();
                        },
                    },
                    {
                        id: 'excel-download',
                        text: 'Excel Sheet',
                        icon: <FileCopy style={{ paddingRight: '10px' }} />,
                        onClick:
                            tabValue == 1 || tabValue == 4
                                ? handleDownloadOptions
                                : tabValue == 3
                                ? () => {
                                      downloadTrades(criteria, query).finally();
                                  }
                                : () => {
                                      handleDownloadFECTrades(criteria, query).finally();
                                  },
                    },
                ]}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                title={tabValue == 0 ? 'Download Trades' : 'Download Options'}
            />
            <StandardCard
                cardHeaderProps={{
                    selectedItems: selectedItems,
                    tailoredState: tailoredState,
                    itemsLeft,
                    itemsRight: [
                        ...generateRowActions(),
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'FECManagementStation/HedgeMaturingTable/new',
                            icon: ACTION_BUTTON_TYPE.NEW,
                            helpText: tabValue == 1 || tabValue == 4 ? 'New Options' : 'New Trade',
                            onClick: (event: SyntheticEvent<HTMLElement> | undefined) => {
                                if (tabValue == 1 || tabValue == 4) {
                                    setNewOptionPopoverAnchor(event?.currentTarget);
                                } else {
                                    setNewTradePopoverOpen(true);
                                    setPopoverAnchorEl(event?.currentTarget);
                                }
                            },
                            hide: !isClient,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'FECManagementStation/HedgeMaturingTable/filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter',
                            onClick: () => {
                                if (tabValue == 1 || tabValue == 4) {
                                    handleOptionsFilterChange([]);
                                    setOptionsShowFilterRow(!showOptionsFilterRow);
                                } else {
                                    handleFilterChange([]);
                                    setShowFilterRow(!showFilterRow);
                                }
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'FECManagementStation/download',
                            icon: ACTION_BUTTON_TYPE.DOWNLOAD,
                            helpText: tabValue == 1 || tabValue == 4 ? 'Download Options' : 'Download Trades',
                            onClick:
                                !isClient && tabValue == 0
                                    ? () => {
                                          handleDownloadFECTrades(criteria, query).finally();
                                      }
                                    : !isClient && tabValue == 1
                                    ? handleDownloadOptions
                                    : tabValue == 2
                                    ? () => {
                                          handleDownloadFECTrades(criteria, query).finally();
                                      }
                                    : tabValue == 3
                                    ? () => {
                                          downloadTrades(criteria, query).finally();
                                      }
                                    : tabValue == 4
                                    ? handleDownloadOptions
                                    : (event: SyntheticEvent<HTMLElement> | undefined) => {
                                          setMoreActionsAnchorEl(
                                              event?.currentTarget ? event.currentTarget : undefined,
                                          );
                                      },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'FECManagementStation/HedgeMaturingTable/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: 'Column Configuration',
                            onClick: () => {
                                if (tabValue == 1 || tabValue == 4) {
                                    setOptionsColConfigOpen(!optionsColConfigOpen);
                                } else {
                                    setColConfigOpen(!colConfigOpen);
                                }
                            },
                        },
                    ],
                }}
            >
                <div>
                    {((): React.ReactNode => {
                        if (tabValue === 1 || tabValue === 4) {
                            return (
                                <OptionsFECTableTab
                                    handleCloseColConfig={() => setOptionsColConfigOpen(false)}
                                    handleOptionsFilterChange={handleOptionsFilterChange}
                                    colConfigOpen={optionsColConfigOpen}
                                    showFilterRow={showOptionsFilterRow}
                                    newOptionCurrencyPair={selectedCurrencyPair}
                                    newOptionType={type}
                                    criteria={criteria}
                                    showNewOptionsTicket={ticketState === ticketStates.showingOptionNew}
                                    onCloseNewOptionsTicket={closeTicket}
                                    setSelected={setSelectedOptions}
                                    selected={selectedOptions}
                                    setDetailDialogOpen={setOptionDetailDialogOpen}
                                    detailDialogOpen={optionDetailDialogOpen}
                                    reloadTriggerState={triggerState}
                                    parentParty={parentParty}
                                />
                            );
                        }
                        return (
                            <React.Fragment>
                                <Table
                                    colConfigCloseFromCard={handleCloseColConfig}
                                    colConfigOpenFromCard={colConfigOpen}
                                    columns={[
                                        {
                                            title: 'Processing Org',
                                            field: 'processingOrgPartyCode',
                                            filter: {
                                                asyncOptionsFetcher: generateProcessingOrgOptions,
                                                displayAccessor: 'name',
                                                valueAccessor: 'partyCode',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Trade) => (
                                                <SwitchContextLink
                                                    counterparty={rowData.processingOrgName || parentParty || ''}
                                                    partyCode={rowData.processingOrgPartyCode || ''}
                                                />
                                            ),
                                        },
                                        {
                                            title: 'Client',
                                            field: 'tradingPartyCode',
                                            filter: {
                                                asyncOptionsFetcher: generateClientOptions,
                                                displayAccessor: 'name',
                                                valueAccessor: 'partyCode',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Trade) => (
                                                <SwitchContextLink
                                                    counterparty={rowData.counterparty || ''}
                                                    partyCode={rowData.tradingPartyCode || ''}
                                                />
                                            ),
                                        },
                                        {
                                            title: 'Trade Number',
                                            field: 'number',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.number) {
                                                    return rowData.number;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'External Ref',
                                            field: 'externalReference',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData) {
                                                    return rowData.externalReference;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            width: '100px',
                                        },
                                        {
                                            title: 'Transaction ID',
                                            field: 'transactionID',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.transactionID) {
                                                    return rowData.transactionID;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Type',
                                            field: 'type',
                                            filter: {
                                                type: CriteriaType.TextCriterion,
                                                options: tradeTypesForColumn,
                                                displayAccessor: 'value',
                                                valueAccessor: 'value',
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData) {
                                                    return rowData.type;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            width: '55px',
                                        },
                                        {
                                            title: 'Capture Date',
                                            field: 'captureDate',
                                            filter: { type: CriteriaType.TimeCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.captureDate) {
                                                    return displayDate((rowData.captureDate as unknown) as string);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            width: '85px',
                                        },
                                        {
                                            title: 'Status',
                                            field: 'status',
                                            filter: {
                                                options: tradeStatuses,
                                                displayAccessor: 'value',
                                                valueAccessor: 'value',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.status) {
                                                    return rowData.status;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Currency',
                                            field: 'currencyPair',
                                            filter: {
                                                options: currencyPairs,
                                                displayAccessor: 'name',
                                                valueAccessor: 'name',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.currencyPair) {
                                                    return rowData.currencyPair;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            width: '57px',
                                        },
                                        {
                                            title: 'IM/EX',
                                            field: 'importExport',
                                            filter: {
                                                type: CriteriaType.TextCriterion,
                                                displayAccessor: 'value',
                                                valueAccessor: 'value',
                                                options: importExportOptions,
                                            },
                                            // eslint-disable-next-line react/display-name
                                            render: (rowData: Trade) => <StyledImExValue trade={rowData} />,
                                            width: '38px',
                                        },
                                        {
                                            title: 'ACM Trade',
                                            field: 'acm',
                                            filter: { type: CriteriaType.BoolCriterion },
                                            render: (rowData: Trade) => {
                                                return rowData.acm ? 'TRUE' : 'FALSE';
                                            },
                                        },
                                        {
                                            title: 'Financial Year',
                                            field: 'financialYear',
                                            filter: {
                                                options: financialYears.map((f) => ({ name: f })),
                                                displayAccessor: 'name',
                                                valueAccessor: 'name',
                                                type: CriteriaType.TextCriterion,
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.financialYear) {
                                                    return rowData.financialYear;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Bank',
                                            field: 'bank',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                return rowData.bank;
                                            },
                                            width: '31px',
                                        },
                                        {
                                            title: 'Forward Points',
                                            field: 'forwardPoints.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.forwardPoints) {
                                                    return displayRate(rowData.forwardPoints);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Cancellation Trade',
                                            field: 'cancellation',
                                            filter: { type: CriteriaType.BoolCriterion },
                                            render: (rowData: Trade) => {
                                                return rowData.cancellation ? 'TRUE' : 'FALSE';
                                            },
                                        },
                                        {
                                            title: 'Matched',
                                            field: 'matched',
                                            filter: { type: CriteriaType.BoolCriterion },
                                            render: (rowData: Trade) => {
                                                return rowData.matched ? 'Matched' : '';
                                            },
                                        },
                                        {
                                            title: 'Direction',
                                            field: 'direction',
                                            filter: {
                                                type: CriteriaType.TextCriterion,
                                                displayAccessor: 'value',
                                                valueAccessor: 'value',
                                                options: tradeDirections,
                                            },
                                            render: (rowData: Trade) => {
                                                if (rowData.direction) {
                                                    return rowData.direction;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Maturity',
                                            field: 'maturityDate',
                                            filter: { type: CriteriaType.TimeCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.maturityDate) {
                                                    return <StyledMaturingValue t={rowData} />;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            width: '70px',
                                        },
                                        {
                                            title: 'Trade Date',
                                            field: 'tradeDate',
                                            render: (rowData: Trade) => {
                                                if (rowData.tradeDate) {
                                                    return displayDate((rowData.tradeDate as unknown) as string);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            filter: { type: CriteriaType.TimeCriterion },
                                        },
                                        {
                                            title: 'Avail Balance',
                                            field: 'availableBalance.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                return displayAmount(rowData.availableBalance);
                                            },
                                            width: '100px',
                                        },
                                        {
                                            title: 'ACM Balance',
                                            field: 'acmBalance.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.acmBalance) {
                                                    return displayAmount(rowData.acmBalance);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Captured Spot Rate',
                                            field: 'capturedSpotRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.capturedSpotRate) {
                                                    return rowData.capturedSpotRate.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Interbank Rate',
                                            field: 'interbankRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.interbankRate) {
                                                    return rowData.interbankRate.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Bank Rate',
                                            field: 'bankRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.bankRate) {
                                                    return rowData.bankRate.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Quote Amount',
                                            field: 'quoteAmount.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.quoteAmount) {
                                                    return displayAmount(rowData.quoteAmount);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Notional',
                                            field: 'notionalAmount.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData) {
                                                    return displayAmount(rowData.notionalAmount);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            width: '80px',
                                        },
                                        {
                                            title: 'Spot Price',
                                            field: 'spotPrice.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.spotPrice) {
                                                    return rowData.spotPrice.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Deal Rate',
                                            field: 'dealRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData) {
                                                    const retVal = displayRateSixDecimalPlaces(rowData.dealRate);
                                                    return (
                                                        <span style={{ float: 'left' }}>
                                                            {retVal ? retVal : 'undefined'}
                                                        </span>
                                                    );
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            width: '60px',
                                        },
                                        {
                                            title: 'EFF Rate',
                                            field: 'effectiveRate.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData) {
                                                    const retVal = displayRate(rowData.effectiveRate);
                                                    return (
                                                        <span style={{ float: 'left' }}>
                                                            {retVal ? retVal : 'undefined'}
                                                        </span>
                                                    );
                                                } else {
                                                    return '-';
                                                }
                                            },
                                            width: '60px',
                                        },
                                        {
                                            title: 'Net Cash Flow',
                                            field: 'netCashFlow.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.netCashFlow) {
                                                    return rowData.netCashFlow.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Realised PnL',
                                            field: 'realisedPnl.value',
                                            filter: { type: CriteriaType.NumberCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.realisedPnl) {
                                                    return rowData.realisedPnl.toFixed(4);
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Trader',
                                            field: 'trader',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.trader) {
                                                    return rowData.trader;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Trader Organisation',
                                            field: 'traderOrganisation',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.traderOrganisation) {
                                                    return rowData.traderOrganisation;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Portfolio Manager',
                                            field: 'portfolioManager',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.portfolioManager) {
                                                    return rowData.portfolioManager;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Relationship Manager',
                                            field: 'relationshipManager',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.relationshipManager) {
                                                    return rowData.relationshipManager;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Notes',
                                            field: 'notes',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.notes) {
                                                    return rowData.notes;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Option Reference',
                                            field: 'optionReference',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.optionReference) {
                                                    return rowData.optionReference;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Season',
                                            field: 'season',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.season) {
                                                    return rowData.season;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                        {
                                            title: 'Product',
                                            field: 'product',
                                            filter: { type: CriteriaType.TextCriterion },
                                            render: (rowData: Trade) => {
                                                if (rowData.product) {
                                                    return rowData.product;
                                                } else {
                                                    return '-';
                                                }
                                            },
                                        },
                                    ]}
                                    count={tradesTotal}
                                    data={tradesResponse || []}
                                    defaultColConfig={[
                                        { header: 'IM/EX', visible: true },
                                        { header: 'External Ref', visible: true },
                                        { header: 'Type', visible: true },
                                        { header: 'Currency', visible: true },
                                        { header: 'Capture Date', visible: true },
                                        { header: 'Notional', visible: true },
                                        { header: 'Avail Balance', visible: true },
                                        { header: 'Maturity', visible: true },
                                        { header: 'Deal Rate', visible: true },
                                        { header: 'EFF Rate', visible: true },
                                        { header: 'Bank', visible: true },
                                        { header: 'Forward Points', visible: false },
                                        { header: 'Cancellation Trade', visible: false },
                                        { header: 'Matched', visible: false },
                                        { header: 'Trade Number', visible: false },
                                        { header: 'Transaction ID', visible: false },
                                        { header: 'Status', visible: false },
                                        { header: 'ACM Trade', visible: false },
                                        { header: 'Financial Year', visible: false },
                                        { header: 'Direction', visible: false },
                                        { header: 'Trade Date', visible: false },
                                        { header: 'ACM Balance', visible: false },
                                        { header: 'Captured Spot Rate', visible: false },
                                        { header: 'Interbank Rate', visible: false },
                                        { header: 'Bank Rate', visible: false },
                                        { header: 'Quote Amount', visible: false },
                                        { header: 'Spot Price', visible: false },
                                        { header: 'Net Cash Flow', visible: false },
                                        { header: 'Realised PnL', visible: false },
                                        { header: 'Trader', visible: false },
                                        { header: 'Trader Organisation', visible: false },
                                        { header: 'Portfolio Manager', visible: false },
                                        { header: 'Relationship Manager', visible: false },
                                        { header: 'Notes', visible: false },
                                        { header: 'Option Reference', visible: false },
                                        { header: 'Season', visible: false },
                                        { header: 'Product', visible: false },
                                        { header: 'Processing Org', visible: false },
                                        { header: 'Client', visible: false },
                                    ]}
                                    handleChangePage={handleChangePage}
                                    loading={loading}
                                    onChangeSorting={handleChangeSorting}
                                    onFilterChange={handleFilterChange}
                                    onRowCheck={handleSelectRow}
                                    order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                                    rowClickAction={handleSelectRow}
                                    rowDoubleClickAction={(rowData: Trade) => {
                                        setSelected([rowData]);
                                        setDetailDialogOpen(true);
                                    }}
                                    rowsPerPage={query.limit}
                                    rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                                    onSelectAll={handleSelectAll}
                                    selected={selected}
                                    showCheckboxes
                                    showFilterRow={showFilterRow}
                                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                                    tableID={'FEC-table'}
                                    title={'Trades'}
                                />
                            </React.Fragment>
                        );
                    })()}
                </div>
            </StandardCard>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                onConfirm={confirmationMethod}
                successMessage={successMessage}
                warningMessage={warningMessage}
            />
            <OptionPopover
                onClose={() => setNewOptionPopoverAnchor(undefined)}
                tradableCurrencyPairs={tradableCurrencyPairs}
                anchorEl={newOptionPopoverAnchor}
                handlePriceSelection={handleOptionPriceSelection}
            />
            <Popover
                anchorEl={popoverAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setNewTradePopoverOpen(false);
                    setPopoverAnchorEl(undefined);
                }}
                open={newTradePopoverOpen && tabValue !== 4}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card>
                    <CardHeader
                        action={
                            <Tooltip key={'close'} title={'Close'}>
                                <IconButton
                                    onClick={() => {
                                        setNewTradePopoverOpen(false);
                                    }}
                                >
                                    <MdClear />
                                </IconButton>
                            </Tooltip>
                        }
                        classes={{
                            root: classes.cardHeader,
                            action: classes.action,
                            title: classes.cardTitle,
                        }}
                        title={'Capture New Trade'}
                    />
                    <div>
                        <div className={classes.currencyEntries}>
                            {(tradableCurrencyPairs || []).map((k) => {
                                return (
                                    <div className={classes.currencyGridColumnLayout} key={k.displayName}>
                                        <div className={classes.currencyText}>{k.displayName}</div>
                                        <div className={classes.buttonContainer}>
                                            <BaseButtonGroup>
                                                <BaseButton
                                                    id={`FECTable/buy-${k.name}`}
                                                    variant={VARIANT.CONTAINED}
                                                    color={COLOR.ACTION}
                                                    size={SIZE.MEDIUM}
                                                    onClick={() =>
                                                        handlePriceSelection(k.name, TradeDirection.BUY, true)
                                                    }
                                                    text={'Buy'}
                                                />
                                                <BaseButton
                                                    id={`FECTable/sell-${k.name}`}
                                                    variant={VARIANT.CONTAINED}
                                                    color={COLOR.ACTION}
                                                    size={SIZE.MEDIUM}
                                                    onClick={() =>
                                                        handlePriceSelection(k.name, TradeDirection.SELL, true)
                                                    }
                                                    text={'Sell'}
                                                />
                                            </BaseButtonGroup>
                                            <CreateSwapLink
                                                onClick={() => {
                                                    setTicketState(ticketStates.showingTradeSwap);
                                                    setSelectedCurrencyPair(k);
                                                    setNewTradePopoverOpen(false);
                                                }}
                                                text={'Add SWAP Trade'}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Card>
            </Popover>
        </div>
    );
};

const StyledImExValue = (props: { trade: Trade }) => {
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    let color = 'inherit';
    if (props.trade.importExport === 'Import') {
        color = theme.palette.custom.import.main;
    }
    if (props.trade.importExport === 'Export') {
        color = theme.palette.custom.export.main;
    }
    return <span className={css({ color })}>{props.trade.importExport?.toUpperCase()}</span>;
};

const tradeTypesForColumn = [
    { value: TradeType.SPOT },
    { value: TradeType.FORWARD },
    { value: TradeType.EXTENSION },
    { value: TradeType.DRAWDOWN },
    { value: TradeType.SWAP },
    { value: TradeType.CANCELLATION },
];

const ticketStates = {
    nop: 0,
    showingTradeNew: 1,
    showingTradeHistory: 2,
    showingTradeExtension: 3,
    showingTradeDrawdown: 4,
    showingTradeCancellation: 5,
    showingTradeSwap: 6,
    showingOptionNew: 7,
    showingOptionExercise: 8,
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        rootTable: {
            borderRadius: '8px',
            marginBottom: 0,
        },
        cardContentRoot: {
            padding: 0,
            gridTemplateColumns: 'auto auto 1fr auto',
            '&:last-child': {
                padding: 0,
            },
        },
        headerRoot: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1.5),
            paddingLeft: theme.spacing(1.75),
            height: '52px',
        },
        headerRootSelect: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1.5),
            paddingLeft: theme.spacing(1.75),
            height: '52px',
            color: theme.palette.primary.contrastText,
        },
        headerTitleText: {
            color: theme.palette.primary.contrastText,
        },
        cardHeader: {
            padding: '0px',
            height: '16px',
            paddingTop: '16px',
            paddingBottom: '16px',
            backgroundColor: theme.palette.background.paper,
        },
        cardTitle: {
            fontWeight: 'bold',
            fontSize: 16,
            marginRight: '0px',
            marginTop: '0px',
            marginLeft: '24px',
        },
        action: {
            color: 'red',
            marginRight: '8px',
            marginTop: '-15px',
        },
        currencyText: {
            fontSize: '18px',
            textTransform: 'none',
            color: theme.palette.secondary.main,
            paddingTop: '15px',
            paddingBottom: '36px',
            paddingRight: '0px',
        },
        buttonContainer: {
            color: HexToRGBA(theme.palette.text.primary, 0.2),
            justifySelf: 'center',
            paddingLeft: '24px',
            paddingTop: '8px',
        },
        currencyEntries: {
            margin: '0px 24px 0px 24px',
        },
        currencyGridColumnLayout: {
            display: 'grid',
            gridTemplateColumns: '84px 1fr',
            borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
            '&:last-of-type': {
                borderBottom: 'none',
            },
        },
        buttonContainedPrimary: {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
            fontSize: '12px',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: HexToRGBA(theme.palette.primary.main, 0.6),
            },
        },
        button: {
            width: '50px',
            height: '30px',
        },
        indicator: {
            backgroundColor: theme.palette.secondary.light,
        },
        iconButton: {
            '& svg': {
                fontSize: 32,
            },
        },
    }),
);

export default FECTable;
