import { AuditEntry } from 'popcorn-js/audit';

export enum OrderType {
    Purchase = 'Purchase',
    Sales = 'Sales',
}

export enum OrderDirectionType {
    Payable = 'Payable',
    Receivable = 'Receivable',
}

export type OrderImportExport = 'Import' | 'Export';
export type OrderDirection = 'Payable' | 'Receivable';

export const ORDER_STATUS_PROPOSED = 'PROPOSED';
export const ORDER_STATUS_CONFIRMED = 'CONFIRMED';
export const ORDER_STATUS_RECEIVED = 'RECEIVED';
export const ORDER_STATUS_PAID = 'PAID';
export const ORDER_STATUS_EXPIRED = 'EXPIRED';

export const ORDER_INVALID_REASON_COUNTERPARTY_DOES_NOT_EXIST = 'counterparty does not exist';

export const ALL_ORDER_STATUSES = [
    ORDER_STATUS_CONFIRMED,
    ORDER_STATUS_PROPOSED,
    ORDER_STATUS_RECEIVED,
    ORDER_STATUS_PAID,
    ORDER_STATUS_EXPIRED,
];

export const orderTypeOptions = ['Purchase', 'Sales'];
export const orderDirectionOptions = ['Payable', 'Receivable'];
export const orderImportExportOptions = ['Import', 'Export'];

export enum OrderStatus {
    PROPOSED = 'PROPOSED',
    CONFIRMED = 'CONFIRMED',
    PAID = 'PAID',
    INVOICED = 'INVOICED',
}

export interface Order {
    id?: string;
    type?: OrderType;
    importExport?: OrderImportExport;
    direction?: OrderDirection;
    number?: string;
    counterpartyId?: string;
    // for ease of display
    counterparty?: string;
    originalAmountDue?: number;
    invoicedAmount?: number;
    exposureBalance?: number;
    currency?: string;
    costCurrency?: string;
    costingRate?: number;
    captureRate?: number;
    originalCaptureRate?: number;
    captureSpotRate?: number;
    dueDate?: number;
    shippingDate?: number;
    shipmentReference?: string;
    notes?: string;
    status?: OrderStatus;
    partyCode?: string;
    issueDate?: number;
    linkedInvoices?: LinkedInvoice[];
    // for ease of import and display
    invoiceExternalReference?: string;
    auditEntry?: AuditEntry;
    financialYear?: string;
    captureDate?: string;
    expectedDeliveryDate?: string;
}

export interface LinkedInvoice {
    invoiceId?: string;
    fxAmount?: number;
    number?: string;
    externalReference?: string;
    effectiveRate?: number;
    date?: string;
}
