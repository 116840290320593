/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactElement, useEffect, useContext, ChangeEvent } from 'react';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import { Recordkeeper as OrderRecordkeeper } from 'popcorn-js/order/recordkeeper';
import { Handler as OrderHandler } from 'popcorn-js/order/handler';
import { financialYears, Direction, FindRequest, FindResponse } from 'popcorn-js/';
import { Downloader as OrderDownloader, DownloadRequest, DownloadResponse } from 'popcorn-js/order/downloader';
import { Counterparty } from 'popcorn-js/counterparty';
import { Recordkeeper as CounterpartyRecordkeeper } from 'popcorn-js/counterparty/recordkeeper';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { History } from 'components/order/History';
import { processUnixDateForViewing, processUnixDateTimeForViewing } from 'utils';
import { saveAs } from 'file-saver';
import { Order, OrderStatus, OrderType } from 'popcorn-js/order';
import { displayAmount } from 'utils';
import Table from 'components/Table/Table';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { CategoryMenu } from 'components/CategoryMenu/CategoryMenu';
import { AppContextT, AppContext } from 'context';
import OrderForm from 'components/order/OrderForm';
import { AuditEntry } from 'popcorn-js/audit';
import { useStyletron } from 'styletron-react';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import OrderUploadContainer from 'components/order/upload/OrderUploadContainer';
import { downloadTemplate } from 'components/order/upload';
import { Category } from 'components/upload';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION, ACTION_BUTTON_TYPE, Item } from 'components/CardHeader/StandardCardHeader';
import { useServiceSync } from 'hooks/useService';
import moment from 'moment';
import { SystemDateFormat, SystemDateTimeFormat } from 'constants/formats';
import { Client, IsClient, ProcessingOrg } from 'popcorn-js/party';
import OrderDetailDialog from 'components/order/OrderDetailDialogV2';
import SelectedRowsButton from 'components/SelectedRowsButton/selectedRowsButton';
import { PartyType } from 'popcorn-js/party';

export enum ActiveState {
    viewing = 'ACTIVE_STATE_VIEWING',
    editing = 'ACTIVE_STATE_EDITING',
    creating = 'ACTIVE_STATE_CREATING',
    uploading = 'ACTIVE_STATE_UPLOADING',
    viewDelete = 'ACTIVE_STATE_VIEWING_DELETE',
}

const orderStatuses = Object.values(OrderStatus).map((value) => ({ value }));
const orderImportExport = ['IMPORT', 'EXPORT'].map((value) => ({ value }));
const orderDirections = [
    {
        value: Direction.PAYABLE,
        label: Direction.PAYABLE,
    },
    {
        value: Direction.RECEIVABLE,
        label: Direction.RECEIVABLE,
    },
];
const baseCriteriaConfig: Record<string, Criterion> = {
    financialYear: {
        type: CriteriaType.TextCriterion,
        field: 'financialYear',
        text: 'CURRENT',
    },
};
const safeRender = (accessor: string, formatter = (value: unknown) => value) => (rowData: Order) => {
    try {
        return formatter(rowData[accessor as keyof Order]);
    } catch (e) {
        return '-';
    }
};

const initialPageSize = 12;
const rowsPerPageOptions = [5, 10, 12, 17, 20, 25, 30];

export const OrderStation = (): ReactElement => {
    const [css] = useStyletron();
    const appContext = useContext<AppContextT>(AppContext);
    const usersContext = appContext.currentContext?.partyType;
    const party = (appContext.party || {}) as Client | ProcessingOrg;
    const initialTab = (party: Client | ProcessingOrg) => {
        let tab = 0;
        if (IsClient(party)) {
            if (party.export && !party.import) {
                tab = 1;
            }
        }
        return tab;
    };
    const [tabValue, setTabValue] = React.useState(initialTab(party));

    const initialiseCriteria = (party: Client | ProcessingOrg) => {
        const criteria: Criteria = [{ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' }];
        if (IsClient(party)) {
            if (tabValue === 1) {
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: OrderType.Sales,
                });
            } else {
                criteria.push({
                    type: CriteriaType.TextCriterion,
                    field: 'type',
                    text: OrderType.Purchase,
                });
            }
        } else {
            criteria.push({
                type: CriteriaType.TextCriterion,
                field: 'type',
                text: OrderType.Purchase,
            });
        }
        return criteria;
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [activeState, setActiveState] = useState<ActiveState>(ActiveState.viewing);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [selected, setSelected] = useState<Order[] | undefined>();
    const [showOrderHistory, setShowOrderHistory] = useState<boolean>(false);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);
    const [orders, setOrders] = useState<Order[]>([]);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [createNewAnchorEl, setCreateNewAnchorEl] = useState<undefined | Element | ((element: Element) => Element)>(
        undefined,
    );
    const [uploadAnchorEl, setUploadAnchorEl] = useState<undefined | Element | ((element: Element) => Element)>(
        undefined,
    );
    const [orderCategory, setOrderCategory] = useState<Category | undefined>();
    const [orderType, setOrderType] = useState<OrderType | undefined>(undefined);
    const initialQuery: Query = {
        sortBy: ['dueDate', 'number'],
        order: ['asc', 'asc'],
        limit: initialPageSize,
        offset: 0,
    };
    const [query, setQuery] = useState<Query>(initialQuery);
    const [counterparties, setCounterparties] = useState<Counterparty[]>([]);
    const [criteria, setCriteria] = useState<Criteria>(initialiseCriteria(party));
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [moreOptionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | undefined>();
    const [showColumnConfiguration, setShowColumnConfiguration] = useState<boolean>(false);
    const [isClient, setIsClient] = useState<boolean>(false);

    // service providers
    const [orderFind] = useServiceSync<FindRequest, FindResponse<Order>>(OrderRecordkeeper.find);
    const [counterpartyFind] = useServiceSync<FindRequest, FindResponse<Counterparty>>(CounterpartyRecordkeeper.find);
    const [downloadOrders] = useServiceSync<DownloadRequest, DownloadResponse>(OrderDownloader.downloadOrders);

    useEffect(() => {
        setIsClient(() => {
            return usersContext === PartyType.CLIENT;
        });
        findOrders().finally();
    }, []);

    useEffect(() => {
        setSelected(undefined);
    }, [activeState]);

    const findOrders = async (_criteria?: Criteria, _query?: Query, _deleted?: boolean) => {
        setLoading(true);
        try {
            const result = await orderFind({
                criteria: _criteria || criteria,
                query: _query || query,
                deleted: _deleted,
            });
            const _counterparties = await findOrderCounterparties(result.records);
            setOrders(
                result.records.map((i: Order) => ({
                    ...i,
                    counterparty: _counterparties.find((c: Counterparty) => c.id === i.counterpartyId)?.name,
                })),
            );
            setCounterparties(_counterparties);
            setTotalOrders(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const findOrderCounterparties = async (_orders: Order[]): Promise<Counterparty[]> => {
        setLoading(true);
        const counterpartyFindCrit: Criteria = _orders
            .filter((b) => b.counterpartyId !== '')
            .map((b) => ({ type: CriteriaType.ExactCriterion, field: 'id', text: b.counterpartyId }));

        const counterpartyFindResponse =
            counterpartyFindCrit.length > 0
                ? await CounterpartyRecordkeeper.find({ criteria: counterpartyFindCrit })
                : { records: [] };

        setLoading(false);
        return counterpartyFindResponse.records;
    };

    const exportOrders = async (_criteria: Criteria) => {
        setLoading(true);
        try {
            const orderExportResult = await downloadOrders({ criteria: _criteria });
            // convert base64 to byte array
            const binData = atob(orderExportResult.data);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });
            saveAs(blob, 'order_export.xlsx');
            setLoading(false);
        } catch (error) {
            setErrorMessage(error.message || error);
        } finally {
            setLoading(false);
        }
    };
    const showDeleteForeverConfirmation = (rowInfo: Order[]) => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        let ordNumber = '';
        rowInfo.forEach((ord: Order) => {
            if (ordNumber.length == 0) {
                ordNumber += ord.number;
            }
        });
        if (rowInfo.length == 1) {
            setWarningMessage(`You are about to delete order ${ordNumber} forever.\n Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await OrderRecordkeeper.deleteForever({
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: rowInfo[0].id },
                    });
                    setSuccessMessage(`Order ${ordNumber} Deleted Forever`);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    findOrders(criteria, query, true);
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        } else {
            const orderIds = [] as string[];
            for (const ord of rowInfo) {
                if (ord.id) {
                    orderIds.push(ord.id);
                }
            }
            setWarningMessage(`You are about to delete ${orderIds.length} orders,\n forever. Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                const deleteBatch: any[] = rowInfo.map((order: any) => {
                    return {
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id },
                        order: order,
                    };
                });
                try {
                    await OrderHandler.DeleteBatchForever({
                        deleteBatch: deleteBatch,
                    });
                    setSuccessMessage(`${orderIds.length} Orders Deleted Forever`);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    await findOrders(criteria, query, true);
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        }
    };
    const showDeleteConfirmation = (rowInfo: Order[]) => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        let ordNumber = '';
        rowInfo.forEach((ord: Order) => {
            if (ordNumber.length == 0) {
                ordNumber += ord.number;
            }
        });
        if (rowInfo.length == 1) {
            setWarningMessage(`You are about to delete order ${ordNumber}.\n Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await OrderHandler.Delete({
                        identifier: { type: IdentifierType.ID_IDENTIFIER, id: rowInfo[0].id },
                    });
                    setSuccessMessage(`Order ${ordNumber} Deleted`);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    findOrders();
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        } else {
            const numbers = [] as string[];
            for (const ord of rowInfo) {
                if (ord.number) {
                    numbers.push(ord.number);
                }
            }
            setWarningMessage(`You are about to delete ${numbers.length} orders.\n Do you want to continue?`);
            setConfirmationMethod(() => async () => {
                setLoading(true);
                try {
                    await OrderHandler.DeleteBatch({
                        numbers: numbers,
                    });
                    setSuccessMessage(`${numbers.length} Orders Deleted`);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(undefined);
                    setSelected(undefined);
                    findOrders();
                } catch (e) {
                    setSuccessMessage(undefined);
                    setWarningMessage(undefined);
                    setConfirmationMethod(undefined);
                    setErrorMessage(e.message || e);
                }
                setLoading(false);
            });
        }
    };

    const allowBatchRestore = (rowInfo: Order[]) => {
        let rowNum = '';
        rowInfo.forEach((order: Order) => {
            if (rowNum.length == 0) {
                rowNum += order.number;
            }
        });
        if (selected?.length === 1) {
            setLoading(true);
            setSelected(undefined);
            OrderRecordkeeper.restore({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: rowInfo[0].id },
            })
                .then(() => {
                    setSuccessMessage(`Order ${rowNum} Restored`);
                    findOrders(criteria, query, true);
                })
                .catch((e) => setErrorMessage(e.message || e))
                .finally(() => setLoading(false));
        } else {
            setLoading(true);
            setSelected(undefined);
            const restoreBatch: any[] = rowInfo.map((order: any) => {
                return {
                    identifier: { type: IdentifierType.ID_IDENTIFIER, id: order.id },
                    order: order,
                };
            });
            OrderHandler.RestoreBatch({
                restoreBatch: restoreBatch,
            })
                .then(() => {
                    setSuccessMessage(`${restoreBatch.length} Orders Restored`);
                    findOrders(criteria, query, true);
                })
                .catch((e) => setErrorMessage(e.message || e))
                .finally(() => setLoading(false));
        }
    };

    const handleHideAlert = () => {
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setErrorMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const generateCounterpartyOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await counterpartyFind({
                criteria,
                query: { sortBy: ['dueDate', 'number'], order: ['asc', 'asc'], limit: 20, offset: 0 },
            });
            return response.records;
        } catch (e) {
            throw new Error(e.message || e);
        }
    };

    const renderDialogs = () => {
        return (
            <span>
                {createNewAnchorEl && (
                    <CategoryMenu
                        title={'Create New'}
                        anchorEl={createNewAnchorEl}
                        onClose={() => setCreateNewAnchorEl(undefined)}
                        onClickItem={(value: string) => {
                            setCreateNewAnchorEl(undefined);
                            setOrderType(value as OrderType);
                            setActiveState(ActiveState.creating);
                        }}
                        importItems={[{ text: 'Purchase Order', value: OrderType.Purchase }]}
                        exportItems={[{ text: 'Sales Order', value: OrderType.Sales }]}
                    />
                )}
                {uploadAnchorEl && (
                    <CategoryMenu
                        title={'Upload'}
                        anchorEl={uploadAnchorEl}
                        onClose={() => setUploadAnchorEl(undefined)}
                        onClickItem={(value: string) => {
                            setUploadAnchorEl(undefined);
                            setOrderCategory(value as Category);
                            setActiveState(ActiveState.uploading);
                        }}
                        importItems={[{ text: 'Purchase Orders', value: Category.Purchase }]}
                        exportItems={[{ text: 'Sales Orders', value: Category.Sales }]}
                    />
                )}
                {showDetail && selected && (
                    <OrderDetailDialog
                        counterparties={counterparties}
                        order={selected[0]}
                        onClose={() => setShowDetail(false)}
                        show={showDetail}
                        onSaveSuccess={() => findOrders()}
                    />
                )}
                {activeState === ActiveState.creating && (
                    <OrderForm
                        createCounterpartyError={(e) => setErrorMessage(`Failed to create counterparty ${e}`)}
                        createCounterpartySuccess={() => setSuccessMessage('Counterparty Created')}
                        createOrderError={(e) => setErrorMessage(`Failed to create order ${e}`)}
                        createOrderSuccess={() => {
                            setSuccessMessage('Order Created');
                            findOrders();
                        }}
                        hideOrderForm={() => setActiveState(ActiveState.viewing)}
                        showForm={activeState === ActiveState.creating}
                        type={orderType || OrderType.Purchase}
                        validateOrderError={(e) => setErrorMessage(`Failed to validate order ${e}`)}
                    />
                )}
                {activeState === ActiveState.uploading && (
                    <OrderUploadContainer
                        category={orderCategory}
                        enableDownloadTemplate={true}
                        onAwayClick={() => setActiveState(ActiveState.viewing)}
                        showImportDialog={() => setActiveState(ActiveState.uploading)}
                        uploadSuccess={() => findOrders()}
                    />
                )}
                {showOrderHistory && selected && (
                    <History order={selected[0]} onHide={() => setShowOrderHistory(false)} open={showOrderHistory} />
                )}
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                />
            </span>
        );
    };

    const handleChangeSorting = (sortBy: string, order: QueryOrderT) => {
        const newQuery = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        findOrders(criteria, newQuery, activeState === ActiveState.viewDelete);
    };

    const handleFilterChange = (newCrit: Criteria) => {
        const newQuery = {
            ...query,
            offset: 0,
        };
        const tabCrit = newCrit.filter((f: Criterion) => f.field === 'type');
        const allCriteria = newCrit.filter(
            (f: Criterion) =>
                (f.field === 'financialYear' && f.type === CriteriaType.TextCriterion && f.text && f.text === 'ALL') ||
                (f.field === 'financialYear' && f.type === CriteriaType.ExactCriterion && f.text && f.text === 'ALL'),
        );
        const finYearCrit = newCrit.filter((f: Criterion) => f.field === 'financialYear');
        if (finYearCrit.length === 0) {
            newCrit.push({ type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' });
        } else if (allCriteria.length > 0) {
            {
                if (financialYears[0] === 'ALL') {
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'financialYear',
                        text: '',
                    });
                }
            }
        }
        if (tabCrit.length === 0) {
            switch (tabValue) {
                case 0:
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: OrderType.Purchase,
                    });
                    break;
                case 1: {
                    newCrit.push({
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: OrderType.Sales,
                    });
                }
            }
        }
        setQuery(newQuery);
        setCriteria(newCrit);
        findOrders(newCrit, newQuery, activeState === ActiveState.viewDelete);
    };

    const handleChangeRowsPerPage = (event: any) => {
        const rowsPerPage = event.target.value;
        const newQuery: Query = {
            sortBy: ['dueDate', 'number'],
            order: ['asc', 'asc'],
            limit: rowsPerPage,
            offset: 0,
        };
        setQuery(newQuery);
        findOrders(criteria, newQuery, activeState === ActiveState.viewDelete);
    };

    const handleChangePage = (event: any, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findOrders(criteria, newQuery, activeState === ActiveState.viewDelete);
    };
    const displayRate = (value: number | undefined): string => {
        if (value) {
            return value.toFixed(4);
        }
        return '-';
    };
    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
        setSelected([]);
        switch (newValue) {
            case 0:
                handleFilterChange([
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: OrderType.Purchase,
                    },
                    { type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' },
                ]);
                break;
            case 1: {
                handleFilterChange([
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'type',
                        text: OrderType.Sales,
                    },
                    { type: CriteriaType.TextCriterion, field: 'financialYear', text: 'CURRENT' },
                ]);
            }
        }
    };
    const itemsLeft: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: [
                {
                    id: 'OrderStation/purchase',
                    label: 'Purchase',
                    value: 'Purchase',
                },
                {
                    id: 'OrderStation/sales',
                    label: 'Sales',
                    value: 'Sales',
                },
            ],
            onChange: (_event: ChangeEvent<unknown>, value: number) => {
                handleTabChange(value);
            },
            value: tabValue,
            id: 'fec-tabs',
        },
        {
            type: ITEM_VARIATION.ELEMENT,
            id: 'OrderStation/Orders/selectedRowButton',
            element: (
                <SelectedRowsButton
                    disabled={false}
                    onClick={() => setSelected([])}
                    count={selected?.length ? selected?.length : 0}
                />
            ),
            hide: selected?.length === 0 || !selected,
        },
    ];
    const handleSelectRow = (rowData: Order) => {
        const index = (selected || []).findIndex((selectedOrder: Order) => selectedOrder.id === rowData.id);
        const _selected = [...(selected || [])];
        if (index === -1) {
            _selected.push(rowData);
        } else {
            _selected.splice(index, 1);
        }
        setSelected && setSelected(_selected);
    };
    const handleSelectAll = () => {
        const newSelected = [...(selected || [])];
        if (newSelected.length !== 0) {
            setSelected && setSelected([]);
        } else if (orders) {
            orders.forEach((order: Order) => {
                newSelected.push(order);
            });
            setSelected && setSelected(newSelected);
        }
    };
    return (
        <div
            className={css({
                height: 'calc(100vh - 100px)',
                overflowY: 'scroll',
                justifyItems: 'center',
            })}
        >
            {renderDialogs()}
            <ActionsMenu
                id={'OrderStation/more-options'}
                anchorElement={moreOptionsAnchorEl}
                items={[
                    {
                        id: 'download-template',
                        text: 'Download Template',
                        onClick: () => downloadTemplate(),
                    },
                    {
                        id: 'upload-orders',
                        text: 'Upload Orders',
                        onClick: (event: any) => setUploadAnchorEl(event.currentTarget),
                        disabled: !isClient,
                    },
                    {
                        id: 'download-orders',
                        text: 'Download Orders',
                        onClick: () => {
                            exportOrders(criteria).finally();
                        },
                    },
                ]}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                title={'More Options'}
            />
            <StandardCard
                cardHeaderProps={{
                    itemsLeft,
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/history',
                            icon: ACTION_BUTTON_TYPE.HISTORY,
                            helpText: 'History',
                            onClick: () => {
                                setSelected(selected || ({} as Order[]));
                                setShowOrderHistory(true);
                            },
                            hide: selected?.length !== 1 || !selected || activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/return',
                            icon: ACTION_BUTTON_TYPE.RETURN,
                            helpText: 'return',
                            onClick: () => {
                                setActiveState(ActiveState.viewing);
                                setShowFilter(false);
                                setQuery(initialQuery);
                                findOrders(initialiseCriteria(party), initialQuery, false);
                            },
                            hide: activeState !== ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/delete',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete',
                            onClick: () => showDeleteConfirmation(selected || ({} as Order[])),
                            hide:
                                !isClient ||
                                !selected ||
                                selected?.length === 0 ||
                                activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/delete-forever',
                            icon: ACTION_BUTTON_TYPE.DELETE,
                            helpText: 'Delete Forever',
                            onClick: () => showDeleteForeverConfirmation(selected || ({} as Order[])),
                            hide:
                                !isClient ||
                                !selected ||
                                selected?.length === 0 ||
                                activeState !== ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/restore',
                            icon: ACTION_BUTTON_TYPE.RESTORE,
                            helpText: 'Restore',
                            onClick: () => allowBatchRestore(selected || ({} as Order[])),
                            hide:
                                !isClient ||
                                !selected ||
                                selected?.length === 0 ||
                                activeState !== ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/view-details',
                            icon: ACTION_BUTTON_TYPE.VIEW_DETAIL,
                            helpText: 'View Details',
                            onClick: () => {
                                setSelected(selected || ({} as Order[]));
                                setShowDetail(true);
                            },
                            hide: selected?.length !== 1 || !selected,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/filter',
                            icon: ACTION_BUTTON_TYPE.SHOW_FILTER,
                            helpText: 'Filter',
                            onClick: () => {
                                setShowFilter(!showFilter);
                                handleFilterChange([]);
                            },
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/new',
                            icon: ACTION_BUTTON_TYPE.NEW,
                            helpText: 'New',
                            onClick: (event: any) => setCreateNewAnchorEl(event.currentTarget),
                            hide: !isClient,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/view-column-conf',
                            icon: ACTION_BUTTON_TYPE.OPEN_COL_CONF,
                            helpText: ' Open column configuration',
                            onClick: () => setShowColumnConfiguration(true),
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/view-deleted',
                            icon: ACTION_BUTTON_TYPE.VIEW_DELETED,
                            helpText: ' View Deleted Items',
                            onClick: () => {
                                setActiveState(ActiveState.viewDelete);
                                setQuery(initialQuery);
                                findOrders(criteria, initialQuery, true);
                            },
                            hide: activeState === ActiveState.viewDelete,
                        },
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            id: 'OrderStation/more-options',
                            icon: ACTION_BUTTON_TYPE.MORE_OPTIONS,
                            helpText: 'More Options',
                            onClick: (event: any) =>
                                setMoreActionsAnchorEl(event.currentTarget ? event.currentTarget : undefined),
                        },
                    ],
                }}
            >
                <Table
                    colConfigCloseFromCard={() => setShowColumnConfiguration(false)}
                    colConfigOpenFromCard={showColumnConfiguration}
                    columns={[
                        {
                            title: 'Type',
                            field: 'type',
                        },
                        {
                            title: 'Number',
                            field: 'number',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        { title: 'Party', field: 'partyCode' },
                        {
                            title: 'Financial Year',
                            field: 'financialYear',
                            filter: {
                                options: financialYears.map((f) => ({ name: f })),
                                displayAccessor: 'name',
                                valueAccessor: 'name',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Counterparty',
                            disableSort: true,
                            filter: {
                                asyncOptionsFetcher: generateCounterpartyOptions,
                                displayAccessor: 'name',
                                valueAccessor: 'id',
                                type: CriteriaType.TextCriterion,
                            },
                            render: (rowData: Order) => rowData.counterparty,
                            field: 'counterpartyId',
                        },
                        {
                            title: 'Original Amount Due',
                            field: 'originalAmountDue.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('originalAmountDue', (originalAmountDue) =>
                                displayAmount(originalAmountDue as number),
                            ),
                        },
                        {
                            title: 'Invoiced Amount',
                            field: 'invoicedAmount.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('invoicedAmount', (invoicedAmount) =>
                                displayAmount(invoicedAmount as number),
                            ),
                        },
                        {
                            title: 'Exposure Balance',
                            field: 'exposureBalance.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('exposureBalance', (exposureBalance) =>
                                displayAmount(exposureBalance as number),
                            ),
                        },
                        {
                            title: 'Currency',
                            field: 'currency',
                            disableSort: true,
                            filter: {
                                options: appContext.currencies,
                                displayAccessor: 'isoCode',
                                valueAccessor: 'isoCode',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Cost Currency',
                            field: 'costCurrency',
                            disableSort: true,
                            filter: {
                                options: appContext.currencies,
                                displayAccessor: 'isoCode',
                                valueAccessor: 'isoCode',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Costing Rate',
                            field: 'costingRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('costingRate', (costingRate: unknown) =>
                                (costingRate as number).toFixed(4),
                            ),
                        },
                        {
                            title: 'Due Date',
                            field: 'dueDate',
                            render: safeRender('dueDate', processUnixDateForViewing),
                            filter: { type: CriteriaType.DateCriterion },
                        },
                        {
                            title: 'Shipping Date',
                            field: 'shippingDate',
                            filter: { type: CriteriaType.DateCriterion },
                            render: safeRender('shippingDate', (shippingDate) =>
                                processUnixDateForViewing(shippingDate),
                            ),
                        },
                        {
                            title: 'Shipment Reference',
                            field: 'shipmentReference',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        {
                            title: 'Notes',
                            field: 'notes',
                            filter: { type: CriteriaType.TextCriterion },
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            filter: {
                                options: orderStatuses,
                                displayAccessor: 'value',
                                valueAccessor: 'value',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Approved Amount',
                            disableSort: true,
                            render: safeRender('approvedAmount', (approvedAmount) =>
                                displayAmount(approvedAmount as number),
                            ),
                        },
                        {
                            title: 'Paid Amount',
                            disableSort: true,
                            render: safeRender('paidAmount', (paidAmount) => displayAmount(paidAmount as number)),
                        },
                        {
                            title: 'Effective Rate',
                            field: 'effectiveRate',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('effectiveRate', (effectiveRate) =>
                                (effectiveRate as number).toFixed(4),
                            ),
                        },
                        {
                            title: 'Issue Date',
                            field: 'issueDate',
                            filter: { type: CriteriaType.DateCriterion },
                            render: safeRender('issueDate', (issueDate) => processUnixDateForViewing(issueDate)),
                        },
                        {
                            title: 'Capture Rate',
                            field: 'captureRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: safeRender('captureRate', (captureRate) => (captureRate as number).toFixed(4)),
                        },
                        {
                            title: 'Original Capture Rate',
                            field: 'originalCaptureRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (order: Order) => displayRate(order.originalCaptureRate),
                        },
                        {
                            title: 'Capture Spot Rate',
                            field: 'captureSpotRate.value',
                            filter: { type: CriteriaType.NumberCriterion },
                            render: (order: Order) => displayRate(order.captureSpotRate),
                        },
                        {
                            title: 'Capture Date',
                            field: 'captureDate',
                            filter: {
                                displayAccessor: 'captureDate',
                                valueAccessor: 'captureDate',
                                type: CriteriaType.TimeCriterion,
                            },
                            render: (rowData: Order) => moment(rowData.captureDate).format(SystemDateTimeFormat),
                        },
                        {
                            title: 'Expected Delivery Date',
                            field: 'expectedDeliveryDate',
                            filter: {
                                displayAccessor: 'expectedDeliveryDate',
                                valueAccessor: 'expectedDeliveryDate',
                                type: CriteriaType.TimeCriterion,
                            },
                            render: (rowData: Order) => moment(rowData.expectedDeliveryDate).format(SystemDateFormat),
                        },
                        {
                            title: 'Direction',
                            field: 'direction',
                            filter: {
                                type: CriteriaType.TextCriterion,
                                options: orderDirections,
                                displayAccessor: 'value',
                                valueAccessor: 'value',
                            },
                        },
                        {
                            title: 'Import/Export',
                            field: 'importExport',
                            filter: {
                                options: orderImportExport,
                                displayAccessor: 'value',
                                valueAccessor: 'value',
                                type: CriteriaType.TextCriterion,
                            },
                        },
                        {
                            title: 'Last Modified',
                            field: 'auditEntry.time',
                            render: safeRender('auditEntry', (auditEntry: unknown) =>
                                processUnixDateTimeForViewing((auditEntry as AuditEntry).time),
                            ),
                        },
                    ]}
                    count={totalOrders}
                    data={orders}
                    defaultColConfig={[
                        { header: 'Type', visible: true },
                        { header: 'Number', visible: true },
                        { header: 'Counterparty', visible: true },
                        { header: 'Original Amount Due', visible: true },
                        { header: 'Invoiced Amount', visible: false },
                        { header: 'Exposure Balance', visible: false },
                        { header: 'Currency', visible: true },
                        { header: 'Due Date', visible: true },
                        { header: 'Status', visible: true },
                        { header: 'Last Modified', visible: true },
                        { header: 'Financial Year', visible: false },
                        { header: 'Party', visible: false },
                        { header: 'Cost Currency', visible: false },
                        { header: 'Costing Rate', visible: false },
                        { header: 'Shipping Date', visible: false },
                        { header: 'Shipment Reference', visible: false },
                        { header: 'Notes', visible: false },
                        { header: 'Approved Amount', visible: false },
                        { header: 'Paid Amount', visible: false },
                        { header: 'Effective Rate', visible: false },
                        { header: 'Issue Date', visible: false },
                        { header: 'Capture Rate', visible: false },
                        { header: 'Capture Date', visible: false },
                        { header: 'Expected Delivery Date', visible: false },
                        { header: 'Original Capture Rate', visible: false },
                        { header: 'Capture Spot Rate', visible: false },
                        { header: 'Direction', visible: false },
                        { header: 'Import/Export', visible: false },
                    ]}
                    showFilterRow={showFilter}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    initialCriteria={baseCriteriaConfig}
                    loading={loading}
                    onChangeSorting={handleChangeSorting}
                    onFilterChange={handleFilterChange}
                    order={query.order && query.order.length > 0 ? query.order[0] : undefined} // rowActions={generateRowActions}
                    page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                    rowDoubleClickAction={(rowData: Order) => {
                        setSelected([rowData]);
                        setShowDetail(true);
                    }}
                    selected={selected ? selected : []}
                    onRowCheck={handleSelectRow}
                    rowClickAction={handleSelectRow}
                    onSelectAll={handleSelectAll}
                    rowsPerPage={query.limit}
                    rowsPerPageOptions={rowsPerPageOptions}
                    sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                    tableID={'OrderStationTable'}
                    title={activeState === ActiveState.viewDelete ? 'Deleted Orders' : 'Orders'}
                    showCheckboxes
                />
            </StandardCard>
        </div>
    );
};

export default OrderStation;
