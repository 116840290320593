import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';

export default function WarningIcon(): ReactElement {
    const classes = useStyles();
    return (
        <div className={classes.icon}>
            <span className={classes.warning_body}>
                <span className={classes.warning_dot} />
            </span>
        </div>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        // --swal-orange: #FFFFFF;
    },
    icon: {
        width: '80px',
        height: '80px',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderRadius: '50%',
        padding: 0,
        position: 'relative',
        boxSizing: 'content-box',
        margin: '20px auto',
        '&:first-child': {
            marginTop: '32px',
        },
    },
    warning_body: {
        // border-color: var(--swal-orange);
        // animation: pulseWarning 0.75s infinite alternate;
        position: 'absolute',
        width: '5px',
        height: '47px',
        left: '50%',
        top: '10px',
        borderRadius: '2px',
        marginLeft: '-2px',
        // backgroundColor: var(--swal-orange),
        backgroundColor: 'currentcolor',
    },
    warning_dot: {
        // border-color: var(--swal-orange);
        // animation: pulseWarning 0.75s infinite alternate;
        position: 'absolute',
        width: '7px',
        height: '7px',
        borderRadius: '50%',
        marginLeft: '-3px',
        left: '50%',
        bottom: '-11px',
        // background-color: var(--swal-orange);

        backgroundColor: 'currentcolor',
    },
}));
