import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { useServiceSync } from 'hooks/useService';
import { makeStyles, useTheme } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import Generator, { GeneratePnLBreakdownReportRequest, GeneratePnLBreakdownReportResponse } from 'popcorn-js/report';
import { AppContext, AppContextT } from 'context';
import { ScaleLoader as Spinner } from 'react-spinners';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import { Cell, CellProps } from 'components/Cell/Cell';
import { displayAmount } from '../util';

interface PnLBreakdownProps {
    onClose: () => void;
}

export const PnLBreakdown = (props: PnLBreakdownProps): ReactElement => {
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();

    const appContext = useContext<AppContextT>(AppContext);
    const ccy = appContext.localCurrency?.isoCode;

    const [response, setResponse] = useState<GeneratePnLBreakdownReportResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();

    const [generate] = useServiceSync<GeneratePnLBreakdownReportRequest, GeneratePnLBreakdownReportResponse>(
        Generator.GeneratePnLBreakdownReport,
    );

    useEffect(() => {
        generate({})
            .then(setResponse)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className={classes.pnlWrapper}>
                <div className={classes.loadingLayout}>
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.pnlWrapper}>
                <div className={classes.loadingLayout}>
                    <ErrorIcon className={classes.errorIcon} />
                    <div className={classes.errorText}>Failed to load report</div>
                </div>
            </div>
        );
    }

    return (
        <StandardCard
            cardHeaderProps={{
                itemsLeft: [
                    {
                        id: 'PnLBreakdown/title',
                        type: ITEM_VARIATION.TITLE,
                        text: 'Profit & Loss Breakdown',
                    },
                ],
                itemsRight: [
                    {
                        type: ITEM_VARIATION.ICON_BUTTON,
                        id: 'close',
                        icon: ACTION_BUTTON_TYPE.CANCEL,
                        helpText: 'Close',
                        onClick: () => props.onClose(),
                    },
                ],
            }}
        >
            <div className={classes.pnlWrapper}>
                <div className={classes.row}>
                    <Cell
                        // ----- ROW 1 ----
                        variant={'title'}
                        color={'export'}
                        border={{
                            bottom: { weight: 'bold', color: 'export' },
                            left: { weight: 'normal', color: 'default' },
                        }}
                        align={'center'}
                        width={'280px'}
                        text={'Exports'}
                    />
                    <Cell
                        variant={'title'}
                        color={'import'}
                        border={{
                            bottom: { weight: 'bold', color: 'import' },
                            left: { weight: 'normal', color: 'default' },
                        }}
                        align={'center'}
                        width={'280px'}
                        text={'Imports'}
                    />
                </div>
                <div className={classes.row} /*---- ROW 2 ----*/>
                    <Cell {...headingCellProps} text={'Costing Rate Vs Effective Rate'} />
                    <Cell {...headingCellProps} text={'Capture Rate Vs Effective Rate'} />
                    <Cell {...headingCellProps} text={'Costing Rate Vs Effective Rate'} />
                    <Cell {...headingCellProps} text={'Capture Rate Vs Effective Rate'} />
                </div>
                <div className={classes.row} /*---- ROW 3 ----*/>
                    <Cell
                        variant={'heading'}
                        color={'white'}
                        border={{
                            top: { weight: 'normal', color: 'default' },
                        }}
                        align={'left'}
                        text={'Realised P/L On Invoice Settlement'}
                    />
                    <Cell
                        {...valueCellProps}
                        text={displayAmount(ccy, response?.exportInvoiceRealisedPnLWithCostingRate)}
                    />
                    <Cell
                        {...valueCellProps}
                        text={displayAmount(ccy, response?.exportInvoiceRealisedPnLWithCaptureRate)}
                    />
                    <Cell
                        {...valueCellProps}
                        text={displayAmount(ccy, response?.importInvoiceRealisedPnLWithCostingRate)}
                    />
                    <Cell
                        {...valueCellProps}
                        text={displayAmount(ccy, response?.importInvoiceRealisedPnLWithCaptureRate)}
                    />
                </div>
                <div className={classes.row} /*---- ROW 4 ----*/>
                    <Cell
                        variant={'heading'}
                        color={'white'}
                        border={{
                            top: { weight: 'normal', color: 'default' },
                        }}
                        align={'left'}
                        text={'Realised P/L On Cancelled Trades'}
                    />
                    <Cell {...valueCellProps} text={displayAmount(ccy, response?.exportCancellationTradeRealisedPnL)} />
                    <Cell {...valueCellProps} text={displayAmount(ccy, response?.exportCancellationTradeRealisedPnL)} />
                    <Cell {...valueCellProps} text={displayAmount(ccy, response?.importCancellationTradeRealisedPnL)} />
                    <Cell {...valueCellProps} text={displayAmount(ccy, response?.importCancellationTradeRealisedPnL)} />
                </div>
                <div className={classes.row} /*---- ROW 5 ----*/>
                    <Cell
                        variant={'heading'}
                        color={'yellow'}
                        border={{
                            top: { weight: 'bold', color: 'white' },
                        }}
                        align={'left'}
                        text={'Total Realised Profit/(Loss)'}
                    />
                    <Cell
                        {...totalCellProps}
                        text={displayAmount(ccy, response?.exportTotalRealisedPnLWithCostingRate)}
                    />
                    <Cell
                        {...totalCellProps}
                        text={displayAmount(ccy, response?.exportTotalRealisedPnLWithCaptureRate)}
                    />
                    <Cell
                        {...totalCellProps}
                        text={displayAmount(ccy, response?.importTotalRealisedPnLWithCostingRate)}
                    />
                    <Cell
                        {...totalCellProps}
                        text={displayAmount(ccy, response?.importTotalRealisedPnLWithCaptureRate)}
                    />
                </div>
                <div className={classes.row} /*---- ROW 6 ----*/>
                    <div className={classes.space} />
                </div>
                <div className={classes.row} /*---- ROW 7 ----*/>
                    <Cell variant={'heading'} color={'white'} align={'left'} text={'Total Unrealised Profit/(Loss)'} />
                    <Cell
                        {...valueCellProps}
                        /* disable top border */
                        border={{ ...valueCellProps.border, top: undefined }}
                        text={displayAmount(ccy, response?.exportTotalUnrealisedPnLWithCostingRate)}
                    />
                    <Cell
                        {...valueCellProps}
                        /* disable top border */
                        border={{ ...valueCellProps.border, top: undefined }}
                        text={displayAmount(ccy, response?.exportTotalUnrealisedPnLWithCaptureRate)}
                    />
                    <Cell
                        {...valueCellProps}
                        /* disable top border */
                        border={{ ...valueCellProps.border, top: undefined }}
                        text={displayAmount(ccy, response?.importTotalUnrealisedPnLWithCostingRate)}
                    />
                    <Cell
                        {...valueCellProps}
                        /* disable top border */
                        border={{ ...valueCellProps.border, top: undefined }}
                        text={displayAmount(ccy, response?.importTotalUnrealisedPnLWithCaptureRate)}
                    />
                </div>
                <div className={classes.row} /*---- ROW 8 ----*/>
                    <Cell
                        variant={'heading'}
                        color={'yellow'}
                        align={'left'}
                        border={{
                            top: { weight: 'bold', color: 'white' },
                        }}
                        text={'Total Profit/(Loss)'}
                    />
                    <Cell {...totalCellProps} text={displayAmount(ccy, response?.exportTotalPnLWithCostingRate)} />
                    <Cell {...totalCellProps} text={displayAmount(ccy, response?.exportTotalPnLWithCaptureRate)} />
                    <Cell {...totalCellProps} text={displayAmount(ccy, response?.importTotalPnLWithCostingRate)} />
                    <Cell {...totalCellProps} text={displayAmount(ccy, response?.importTotalPnLWithCaptureRate)} />
                </div>
            </div>
        </StandardCard>
    );
};

const headingCellProps: CellProps = {
    variant: 'heading',
    color: 'white',
    border: {
        left: { weight: 'normal', color: 'default' },
    },
    align: 'right',
    width: '140px',
};

const valueCellProps: CellProps = {
    variant: 'value',
    color: 'grey',
    border: {
        top: { weight: 'normal', color: 'default' },
        left: { weight: 'normal', color: 'default' },
    },
    align: 'center',
    width: '140px',
};

const totalCellProps: CellProps = {
    variant: 'total',
    color: 'yellow',
    border: {
        top: { weight: 'bold', color: 'white' },
        left: { weight: 'normal', color: 'default' },
    },
    align: 'center',
    width: '140px',
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    pnlWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(4),
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    loadingLayout: {
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
    space: {
        width: '100%',
        height: '40px',
    },
}));
