export interface Currency {
    isoCode: string;
    name: string;
    symbol: string;
}

export const currencies: Record<string, Currency> = {
    USD: { isoCode: 'USD', name: 'US Dollar', symbol: '$' },
    EUR: { isoCode: 'EUR', name: 'Euro', symbol: '€' },
    GBP: { isoCode: 'GBP', name: 'British Pound', symbol: '£' },
    CHF: { isoCode: 'CHF', name: 'Swiss Franc', symbol: 'CHF' },
    AUD: { isoCode: 'AUD', name: 'Australian Dollar', symbol: 'A$' },
    CAD: { isoCode: 'CAD', name: 'Canadian Dollar', symbol: 'CA$' },
    ZAR: { isoCode: 'ZAR', name: 'South African Rand', symbol: 'R' },
    ADP: { isoCode: 'ADP', name: 'Andorran Peseta', symbol: 'ADP' },
    AED: { isoCode: 'AED', name: 'United Arab Emirates Dirham', symbol: 'AED' },
    AFA: { isoCode: 'AFA', name: 'Afghan Afghani (1927–2002)', symbol: 'AFA' },
    AFN: { isoCode: 'AFN', name: 'Afghan Afghani', symbol: 'AFN' },
    ALK: { isoCode: 'ALK', name: 'Albanian Lek (1946–1965)', symbol: 'ALK' },
    ALL: { isoCode: 'ALL', name: 'Albanian Lek', symbol: 'ALL' },
    AMD: { isoCode: 'AMD', name: 'Armenian Dram', symbol: 'AMD' },
    ANG: { isoCode: 'ANG', name: 'Netherlands Antillean Guilder', symbol: 'ANG' },
    AOA: { isoCode: 'AOA', name: 'Angolan Kwanza', symbol: 'AOA' },
    AOK: { isoCode: 'AOK', name: 'Angolan Kwanza (1977–1991)', symbol: 'AOK' },
    AON: { isoCode: 'AON', name: 'Angolan New Kwanza (1990–2000)', symbol: 'AON' },
    AOR: { isoCode: 'AOR', name: 'Angolan Readjusted Kwanza (1995–1999)', symbol: 'AOR' },
    ARA: { isoCode: 'ARA', name: 'Argentine Austral', symbol: 'ARA' },
    ARL: { isoCode: 'ARL', name: 'Argentine Peso Ley (1970–1983)', symbol: 'ARL' },
    ARM: { isoCode: 'ARM', name: 'Argentine Peso (1881–1970)', symbol: 'ARM' },
    ARP: { isoCode: 'ARP', name: 'Argentine Peso (1983–1985)', symbol: 'ARP' },
    ARS: { isoCode: 'ARS', name: 'Argentine Peso', symbol: 'ARS' },
    ATS: { isoCode: 'ATS', name: 'Austrian Schilling', symbol: 'ATS' },
    AWG: { isoCode: 'AWG', name: 'Aruban Florin', symbol: 'AWG' },
    AZM: { isoCode: 'AZM', name: 'Azerbaijani Manat (1993–2006)', symbol: 'AZM' },
    AZN: { isoCode: 'AZN', name: 'Azerbaijani Manat', symbol: 'AZN' },
    BAD: { isoCode: 'BAD', name: 'Bosnia-Herzegovina Dinar (1992–1994)', symbol: 'BAD' },
    BAM: { isoCode: 'BAM', name: 'Bosnia-Herzegovina Convertible Mark', symbol: 'BAM' },
    BAN: { isoCode: 'BAN', name: 'Bosnia-Herzegovina New Dinar (1994–1997)', symbol: 'BAN' },
    BBD: { isoCode: 'BBD', name: 'Barbadian Dollar', symbol: 'BBD' },
    BDT: { isoCode: 'BDT', name: 'Bangladeshi Taka', symbol: 'BDT' },
    BEC: { isoCode: 'BEC', name: 'Belgian Franc (convertible)', symbol: 'BEC' },
    BEF: { isoCode: 'BEF', name: 'Belgian Franc', symbol: 'BEF' },
    BEL: { isoCode: 'BEL', name: 'Belgian Franc (financial)', symbol: 'BEL' },
    BGL: { isoCode: 'BGL', name: 'Bulgarian Hard Lev', symbol: 'BGL' },
    BGM: { isoCode: 'BGM', name: 'Bulgarian Socialist Lev', symbol: 'BGM' },
    BGN: { isoCode: 'BGN', name: 'Bulgarian Lev', symbol: 'BGN' },
    BGO: { isoCode: 'BGO', name: 'Bulgarian Lev (1879–1952)', symbol: 'BGO' },
    BHD: { isoCode: 'BHD', name: 'Bahraini Dinar', symbol: 'BHD' },
    BIF: { isoCode: 'BIF', name: 'Burundian Franc', symbol: 'BIF' },
    BMD: { isoCode: 'BMD', name: 'Bermudan Dollar', symbol: 'BMD' },
    BND: { isoCode: 'BND', name: 'Brunei Dollar', symbol: 'BND' },
    BOB: { isoCode: 'BOB', name: 'Bolivian Boliviano', symbol: 'BOB' },
    BOL: { isoCode: 'BOL', name: 'Bolivian Boliviano (1863–1963)', symbol: 'BOL' },
    BOP: { isoCode: 'BOP', name: 'Bolivian Peso', symbol: 'BOP' },
    BOV: { isoCode: 'BOV', name: 'Bolivian Mvdol', symbol: 'BOV' },
    BRB: { isoCode: 'BRB', name: 'Brazilian New Cruzeiro (1967–1986)', symbol: 'BRB' },
    BRC: { isoCode: 'BRC', name: 'Brazilian Cruzado (1986–1989)', symbol: 'BRC' },
    BRE: { isoCode: 'BRE', name: 'Brazilian Cruzeiro (1990–1993)', symbol: 'BRE' },
    BRL: { isoCode: 'BRL', name: 'Brazilian Real', symbol: 'R$' },
    BRN: { isoCode: 'BRN', name: 'Brazilian New Cruzado (1989–1990)', symbol: 'BRN' },
    BRR: { isoCode: 'BRR', name: 'Brazilian Cruzeiro (1993–1994)', symbol: 'BRR' },
    BRZ: { isoCode: 'BRZ', name: 'Brazilian Cruzeiro (1942–1967)', symbol: 'BRZ' },
    BSD: { isoCode: 'BSD', name: 'Bahamian Dollar', symbol: 'BSD' },
    BTN: { isoCode: 'BTN', name: 'Bhutanese Ngultrum', symbol: 'BTN' },
    BUK: { isoCode: 'BUK', name: 'Burmese Kyat', symbol: 'BUK' },
    BWP: { isoCode: 'BWP', name: 'Botswanan Pula', symbol: 'BWP' },
    BYB: { isoCode: 'BYB', name: 'Belarusian New Rouble (1994–1999)', symbol: 'BYB' },
    BYN: { isoCode: 'BYN', name: 'Belarusian Rouble', symbol: 'BYN' },
    BYR: { isoCode: 'BYR', name: 'Belarusian Rouble (2000–2016)', symbol: 'BYR' },
    BZD: { isoCode: 'BZD', name: 'Belize Dollar', symbol: 'BZD' },
    CDF: { isoCode: 'CDF', name: 'Congolese Franc', symbol: 'CDF' },
    CHE: { isoCode: 'CHE', name: 'WIR Euro', symbol: 'CHE' },
    CHW: { isoCode: 'CHW', name: 'WIR Franc', symbol: 'CHW' },
    CLE: { isoCode: 'CLE', name: 'Chilean Escudo', symbol: 'CLE' },
    CLF: { isoCode: 'CLF', name: 'Chilean Unit of Account (UF)', symbol: 'CLF' },
    CLP: { isoCode: 'CLP', name: 'Chilean Peso', symbol: 'CLP' },
    CNH: { isoCode: 'CNH', name: 'Chinese Yuan (offshore)', symbol: 'CNH' },
    CNX: { isoCode: 'CNX', name: 'Chinese People’s Bank Dollar', symbol: 'CNX' },
    CNY: { isoCode: 'CNY', name: 'Chinese Yuan', symbol: 'CN¥' },
    COP: { isoCode: 'COP', name: 'Colombian Peso', symbol: 'COP' },
    COU: { isoCode: 'COU', name: 'Colombian Real Value Unit', symbol: 'COU' },
    CRC: { isoCode: 'CRC', name: 'Costa Rican Colón', symbol: 'CRC' },
    CSD: { isoCode: 'CSD', name: 'Serbian Dinar (2002–2006)', symbol: 'CSD' },
    CSK: { isoCode: 'CSK', name: 'Czechoslovak Hard Koruna', symbol: 'CSK' },
    CUC: { isoCode: 'CUC', name: 'Cuban Convertible Peso', symbol: 'CUC' },
    CUP: { isoCode: 'CUP', name: 'Cuban Peso', symbol: 'CUP' },
    CVE: { isoCode: 'CVE', name: 'Cape Verdean Escudo', symbol: 'CVE' },
    CYP: { isoCode: 'CYP', name: 'Cypriot Pound', symbol: 'CYP' },
    CZK: { isoCode: 'CZK', name: 'Czech Koruna', symbol: 'CZK' },
    DDM: { isoCode: 'DDM', name: 'East German Mark', symbol: 'DDM' },
    DEM: { isoCode: 'DEM', name: 'German Mark', symbol: 'DEM' },
    DJF: { isoCode: 'DJF', name: 'Djiboutian Franc', symbol: 'DJF' },
    DKK: { isoCode: 'DKK', name: 'Danish Krone', symbol: 'DKK' },
    DOP: { isoCode: 'DOP', name: 'Dominican Peso', symbol: 'DOP' },
    DZD: { isoCode: 'DZD', name: 'Algerian Dinar', symbol: 'DZD' },
    ECS: { isoCode: 'ECS', name: 'Ecuadorian Sucre', symbol: 'ECS' },
    ECV: { isoCode: 'ECV', name: 'Ecuadorian Unit of Constant Value', symbol: 'ECV' },
    EEK: { isoCode: 'EEK', name: 'Estonian Kroon', symbol: 'EEK' },
    EGP: { isoCode: 'EGP', name: 'Egyptian Pound', symbol: 'EGP' },
    ERN: { isoCode: 'ERN', name: 'Eritrean Nakfa', symbol: 'ERN' },
    ESA: { isoCode: 'ESA', name: 'Spanish Peseta (A account)', symbol: 'ESA' },
    ESB: { isoCode: 'ESB', name: 'Spanish Peseta (convertible account)', symbol: 'ESB' },
    ESP: { isoCode: 'ESP', name: 'Spanish Peseta', symbol: 'ESP' },
    ETB: { isoCode: 'ETB', name: 'Ethiopian Birr', symbol: 'ETB' },
    FIM: { isoCode: 'FIM', name: 'Finnish Markka', symbol: 'FIM' },
    FJD: { isoCode: 'FJD', name: 'Fijian Dollar', symbol: 'FJD' },
    FKP: { isoCode: 'FKP', name: 'Falkland Islands Pound', symbol: 'FKP' },
    FRF: { isoCode: 'FRF', name: 'French Franc', symbol: 'FRF' },
    GEK: { isoCode: 'GEK', name: 'Georgian Kupon Larit', symbol: 'GEK' },
    GEL: { isoCode: 'GEL', name: 'Georgian Lari', symbol: 'GEL' },
    GHC: { isoCode: 'GHC', name: 'Ghanaian Cedi (1979–2007)', symbol: 'GHC' },
    GHS: { isoCode: 'GHS', name: 'Ghanaian Cedi', symbol: 'GHS' },
    GIP: { isoCode: 'GIP', name: 'Gibraltar Pound', symbol: 'GIP' },
    GMD: { isoCode: 'GMD', name: 'Gambian Dalasi', symbol: 'GMD' },
    GNF: { isoCode: 'GNF', name: 'Guinean Franc', symbol: 'GNF' },
    GNS: { isoCode: 'GNS', name: 'Guinean Syli', symbol: 'GNS' },
    GQE: { isoCode: 'GQE', name: 'Equatorial Guinean Ekwele', symbol: 'GQE' },
    GRD: { isoCode: 'GRD', name: 'Greek Drachma', symbol: 'GRD' },
    GTQ: { isoCode: 'GTQ', name: 'Guatemalan Quetzal', symbol: 'GTQ' },
    GWE: { isoCode: 'GWE', name: 'Portuguese Guinea Escudo', symbol: 'GWE' },
    GWP: { isoCode: 'GWP', name: 'Guinea-Bissau Peso', symbol: 'GWP' },
    GYD: { isoCode: 'GYD', name: 'Guyanaese Dollar', symbol: 'GYD' },
    HKD: { isoCode: 'HKD', name: 'Hong Kong Dollar', symbol: 'HK$' },
    HNL: { isoCode: 'HNL', name: 'Honduran Lempira', symbol: 'HNL' },
    HRD: { isoCode: 'HRD', name: 'Croatian Dinar', symbol: 'HRD' },
    HRK: { isoCode: 'HRK', name: 'Croatian Kuna', symbol: 'HRK' },
    HTG: { isoCode: 'HTG', name: 'Haitian Gourde', symbol: 'HTG' },
    HUF: { isoCode: 'HUF', name: 'Hungarian Forint', symbol: 'HUF' },
    IDR: { isoCode: 'IDR', name: 'Indonesian Rupiah', symbol: 'IDR' },
    IEP: { isoCode: 'IEP', name: 'Irish Pound', symbol: 'IEP' },
    ILP: { isoCode: 'ILP', name: 'Israeli Pound', symbol: 'ILP' },
    ILR: { isoCode: 'ILR', name: 'Israeli Shekel (1980–1985)', symbol: 'ILR' },
    ILS: { isoCode: 'ILS', name: 'Israeli New Shekel', symbol: '₪' },
    INR: { isoCode: 'INR', name: 'Indian Rupee', symbol: '₹' },
    IQD: { isoCode: 'IQD', name: 'Iraqi Dinar', symbol: 'IQD' },
    IRR: { isoCode: 'IRR', name: 'Iranian Rial', symbol: 'IRR' },
    ISJ: { isoCode: 'ISJ', name: 'Icelandic Króna (1918–1981)', symbol: 'ISJ' },
    ISK: { isoCode: 'ISK', name: 'Icelandic Króna', symbol: 'ISK' },
    ITL: { isoCode: 'ITL', name: 'Italian Lira', symbol: 'ITL' },
    JMD: { isoCode: 'JMD', name: 'Jamaican Dollar', symbol: 'JMD' },
    JOD: { isoCode: 'JOD', name: 'Jordanian Dinar', symbol: 'JOD' },
    JPY: { isoCode: 'JPY', name: 'Japanese Yen', symbol: 'JP¥' },
    KES: { isoCode: 'KES', name: 'Kenyan Shilling', symbol: 'KES' },
    KGS: { isoCode: 'KGS', name: 'Kyrgystani Som', symbol: 'KGS' },
    KHR: { isoCode: 'KHR', name: 'Cambodian Riel', symbol: 'KHR' },
    KMF: { isoCode: 'KMF', name: 'Comorian Franc', symbol: 'KMF' },
    KPW: { isoCode: 'KPW', name: 'North Korean Won', symbol: 'KPW' },
    KRH: { isoCode: 'KRH', name: 'South Korean Hwan (1953–1962)', symbol: 'KRH' },
    KRO: { isoCode: 'KRO', name: 'South Korean Won (1945–1953)', symbol: 'KRO' },
    KRW: { isoCode: 'KRW', name: 'South Korean Won', symbol: '₩' },
    KWD: { isoCode: 'KWD', name: 'Kuwaiti Dinar', symbol: 'KWD' },
    KYD: { isoCode: 'KYD', name: 'Cayman Islands Dollar', symbol: 'KYD' },
    KZT: { isoCode: 'KZT', name: 'Kazakhstani Tenge', symbol: 'KZT' },
    LAK: { isoCode: 'LAK', name: 'Laotian Kip', symbol: 'LAK' },
    LBP: { isoCode: 'LBP', name: 'Lebanese Pound', symbol: 'LBP' },
    LKR: { isoCode: 'LKR', name: 'Sri Lankan Rupee', symbol: 'LKR' },
    LRD: { isoCode: 'LRD', name: 'Liberian Dollar', symbol: 'LRD' },
    LSL: { isoCode: 'LSL', name: 'Lesotho Loti', symbol: 'LSL' },
    LTL: { isoCode: 'LTL', name: 'Lithuanian Litas', symbol: 'LTL' },
    LTT: { isoCode: 'LTT', name: 'Lithuanian Talonas', symbol: 'LTT' },
    LUC: { isoCode: 'LUC', name: 'Luxembourgian Convertible Franc', symbol: 'LUC' },
    LUF: { isoCode: 'LUF', name: 'Luxembourgian Franc', symbol: 'LUF' },
    LUL: { isoCode: 'LUL', name: 'Luxembourg Financial Franc', symbol: 'LUL' },
    LVL: { isoCode: 'LVL', name: 'Latvian Lats', symbol: 'LVL' },
    LVR: { isoCode: 'LVR', name: 'Latvian Rouble', symbol: 'LVR' },
    LYD: { isoCode: 'LYD', name: 'Libyan Dinar', symbol: 'LYD' },
    MAD: { isoCode: 'MAD', name: 'Moroccan Dirham', symbol: 'MAD' },
    MAF: { isoCode: 'MAF', name: 'Moroccan Franc', symbol: 'MAF' },
    MCF: { isoCode: 'MCF', name: 'Monegasque Franc', symbol: 'MCF' },
    MDC: { isoCode: 'MDC', name: 'Moldovan Cupon', symbol: 'MDC' },
    MDL: { isoCode: 'MDL', name: 'Moldovan Leu', symbol: 'MDL' },
    MGA: { isoCode: 'MGA', name: 'Malagasy Ariary', symbol: 'MGA' },
    MGF: { isoCode: 'MGF', name: 'Malagasy Franc', symbol: 'MGF' },
    MKD: { isoCode: 'MKD', name: 'Macedonian Denar', symbol: 'MKD' },
    MKN: { isoCode: 'MKN', name: 'Macedonian Denar (1992–1993)', symbol: 'MKN' },
    MLF: { isoCode: 'MLF', name: 'Malian Franc', symbol: 'MLF' },
    MMK: { isoCode: 'MMK', name: 'Myanmar Kyat', symbol: 'MMK' },
    MNT: { isoCode: 'MNT', name: 'Mongolian Tugrik', symbol: 'MNT' },
    MOP: { isoCode: 'MOP', name: 'Macanese Pataca', symbol: 'MOP' },
    MRO: { isoCode: 'MRO', name: 'Mauritanian Ouguiya (1973–2017)', symbol: 'MRO' },
    MRU: { isoCode: 'MRU', name: 'Mauritanian Ouguiya', symbol: 'MRU' },
    MTL: { isoCode: 'MTL', name: 'Maltese Lira', symbol: 'MTL' },
    MTP: { isoCode: 'MTP', name: 'Maltese Pound', symbol: 'MTP' },
    MUR: { isoCode: 'MUR', name: 'Mauritian Rupee', symbol: 'MUR' },
    MVP: { isoCode: 'MVP', name: 'Maldivian Rupee (1947–1981)', symbol: 'MVP' },
    MVR: { isoCode: 'MVR', name: 'Maldivian Rufiyaa', symbol: 'MVR' },
    MWK: { isoCode: 'MWK', name: 'Malawian Kwacha', symbol: 'MWK' },
    MXN: { isoCode: 'MXN', name: 'Mexican Peso', symbol: 'MX$' },
    MXP: { isoCode: 'MXP', name: 'Mexican Silver Peso (1861–1992)', symbol: 'MXP' },
    MXV: { isoCode: 'MXV', name: 'Mexican Investment Unit', symbol: 'MXV' },
    MYR: { isoCode: 'MYR', name: 'Malaysian Ringgit', symbol: 'MYR' },
    MZE: { isoCode: 'MZE', name: 'Mozambican Escudo', symbol: 'MZE' },
    MZM: { isoCode: 'MZM', name: 'Mozambican Metical (1980–2006)', symbol: 'MZM' },
    MZN: { isoCode: 'MZN', name: 'Mozambican Metical', symbol: 'MZN' },
    NAD: { isoCode: 'NAD', name: 'Namibian Dollar', symbol: 'NAD' },
    NGN: { isoCode: 'NGN', name: 'Nigerian Naira', symbol: 'NGN' },
    NIC: { isoCode: 'NIC', name: 'Nicaraguan Córdoba (1988–1991)', symbol: 'NIC' },
    NIO: { isoCode: 'NIO', name: 'Nicaraguan Córdoba', symbol: 'NIO' },
    NLG: { isoCode: 'NLG', name: 'Dutch Guilder', symbol: 'NLG' },
    NOK: { isoCode: 'NOK', name: 'Norwegian Krone', symbol: 'NOK' },
    NPR: { isoCode: 'NPR', name: 'Nepalese Rupee', symbol: 'NPR' },
    NZD: { isoCode: 'NZD', name: 'New Zealand Dollar', symbol: 'NZ$' },
    OMR: { isoCode: 'OMR', name: 'Omani Rial', symbol: 'OMR' },
    PAB: { isoCode: 'PAB', name: 'Panamanian Balboa', symbol: 'PAB' },
    PEI: { isoCode: 'PEI', name: 'Peruvian Inti', symbol: 'PEI' },
    PEN: { isoCode: 'PEN', name: 'Peruvian Sol', symbol: 'PEN' },
    PES: { isoCode: 'PES', name: 'Peruvian Sol (1863–1965)', symbol: 'PES' },
    PGK: { isoCode: 'PGK', name: 'Papua New Guinean Kina', symbol: 'PGK' },
    PHP: { isoCode: 'PHP', name: 'Philippine Piso', symbol: 'PHP' },
    PKR: { isoCode: 'PKR', name: 'Pakistani Rupee', symbol: 'PKR' },
    PLN: { isoCode: 'PLN', name: 'Polish Zloty', symbol: 'PLN' },
    PLZ: { isoCode: 'PLZ', name: 'Polish Zloty (1950–1995)', symbol: 'PLZ' },
    PTE: { isoCode: 'PTE', name: 'Portuguese Escudo', symbol: 'PTE' },
    PYG: { isoCode: 'PYG', name: 'Paraguayan Guarani', symbol: 'PYG' },
    QAR: { isoCode: 'QAR', name: 'Qatari Rial', symbol: 'QAR' },
    RHD: { isoCode: 'RHD', name: 'Rhodesian Dollar', symbol: 'RHD' },
    ROL: { isoCode: 'ROL', name: 'Romanian Leu (1952–2006)', symbol: 'ROL' },
    RON: { isoCode: 'RON', name: 'Romanian Leu', symbol: 'RON' },
    RSD: { isoCode: 'RSD', name: 'Serbian Dinar', symbol: 'RSD' },
    RUB: { isoCode: 'RUB', name: 'Russian Rouble', symbol: 'RUB' },
    RUR: { isoCode: 'RUR', name: 'Russian Rouble (1991–1998)', symbol: 'RUR' },
    RWF: { isoCode: 'RWF', name: 'Rwandan Franc', symbol: 'RWF' },
    SAR: { isoCode: 'SAR', name: 'Saudi Riyal', symbol: 'SAR' },
    SBD: { isoCode: 'SBD', name: 'Solomon Islands Dollar', symbol: 'SBD' },
    SCR: { isoCode: 'SCR', name: 'Seychellois Rupee', symbol: 'SCR' },
    SDD: { isoCode: 'SDD', name: 'Sudanese Dinar (1992–2007)', symbol: 'SDD' },
    SDG: { isoCode: 'SDG', name: 'Sudanese Pound', symbol: 'SDG' },
    SDP: { isoCode: 'SDP', name: 'Sudanese Pound (1957–1998)', symbol: 'SDP' },
    SEK: { isoCode: 'SEK', name: 'Swedish Krona', symbol: 'SEK' },
    SGD: { isoCode: 'SGD', name: 'Singapore Dollar', symbol: 'SGD' },
    SHP: { isoCode: 'SHP', name: 'St Helena Pound', symbol: 'SHP' },
    SIT: { isoCode: 'SIT', name: 'Slovenian Tolar', symbol: 'SIT' },
    SKK: { isoCode: 'SKK', name: 'Slovak Koruna', symbol: 'SKK' },
    SLL: { isoCode: 'SLL', name: 'Sierra Leonean Leone', symbol: 'SLL' },
    SOS: { isoCode: 'SOS', name: 'Somali Shilling', symbol: 'SOS' },
    SRD: { isoCode: 'SRD', name: 'Surinamese Dollar', symbol: 'SRD' },
    SRG: { isoCode: 'SRG', name: 'Surinamese Guilder', symbol: 'SRG' },
    SSP: { isoCode: 'SSP', name: 'South Sudanese Pound', symbol: 'SSP' },
    STD: { isoCode: 'STD', name: 'São Tomé & Príncipe Dobra (1977–2017)', symbol: 'STD' },
    STN: { isoCode: 'STN', name: 'São Tomé & Príncipe Dobra', symbol: 'STN' },
    SUR: { isoCode: 'SUR', name: 'Soviet Rouble', symbol: 'SUR' },
    SVC: { isoCode: 'SVC', name: 'Salvadoran Colón', symbol: 'SVC' },
    SYP: { isoCode: 'SYP', name: 'Syrian Pound', symbol: 'SYP' },
    SZL: { isoCode: 'SZL', name: 'Swazi Lilangeni', symbol: 'SZL' },
    THB: { isoCode: 'THB', name: 'Thai Baht', symbol: 'THB' },
    TJR: { isoCode: 'TJR', name: 'Tajikistani Rouble', symbol: 'TJR' },
    TJS: { isoCode: 'TJS', name: 'Tajikistani Somoni', symbol: 'TJS' },
    TMM: { isoCode: 'TMM', name: 'Turkmenistani Manat (1993–2009)', symbol: 'TMM' },
    TMT: { isoCode: 'TMT', name: 'Turkmenistani Manat', symbol: 'TMT' },
    TND: { isoCode: 'TND', name: 'Tunisian Dinar', symbol: 'TND' },
    TOP: { isoCode: 'TOP', name: 'Tongan Paʻanga', symbol: 'TOP' },
    TPE: { isoCode: 'TPE', name: 'Timorese Escudo', symbol: 'TPE' },
    TRL: { isoCode: 'TRL', name: 'Turkish Lira (1922–2005)', symbol: 'TRL' },
    TRY: { isoCode: 'TRY', name: 'Turkish Lira', symbol: 'TRY' },
    TTD: { isoCode: 'TTD', name: 'Trinidad & Tobago Dollar', symbol: 'TTD' },
    TWD: { isoCode: 'TWD', name: 'New Taiwan Dollar', symbol: 'NT$' },
    TZS: { isoCode: 'TZS', name: 'Tanzanian Shilling', symbol: 'TZS' },
    UAH: { isoCode: 'UAH', name: 'Ukrainian Hryvnia', symbol: 'UAH' },
    UAK: { isoCode: 'UAK', name: 'Ukrainian Karbovanets', symbol: 'UAK' },
    UGS: { isoCode: 'UGS', name: 'Ugandan Shilling (1966–1987)', symbol: 'UGS' },
    UGX: { isoCode: 'UGX', name: 'Ugandan Shilling', symbol: 'UGX' },
    USN: { isoCode: 'USN', name: 'US Dollar (Next day)', symbol: 'USN' },
    USS: { isoCode: 'USS', name: 'US Dollar (Same day)', symbol: 'USS' },
    UYI: { isoCode: 'UYI', name: 'Uruguayan Peso (Indexed Units)', symbol: 'UYI' },
    UYP: { isoCode: 'UYP', name: 'Uruguayan Peso (1975–1993)', symbol: 'UYP' },
    UYU: { isoCode: 'UYU', name: 'Uruguayan Peso', symbol: 'UYU' },
    UYW: { isoCode: 'UYW', name: 'Uruguayan Nominal Wage Index Unit', symbol: 'UYW' },
    UZS: { isoCode: 'UZS', name: 'Uzbekistani Som', symbol: 'UZS' },
    VEB: { isoCode: 'VEB', name: 'Venezuelan Bolívar (1871–2008)', symbol: 'VEB' },
    VEF: { isoCode: 'VEF', name: 'Venezuelan Bolívar (2008–2018)', symbol: 'VEF' },
    VES: { isoCode: 'VES', name: 'Venezuelan Bolívar', symbol: 'VES' },
    VND: { isoCode: 'VND', name: 'Vietnamese Dong', symbol: '₫' },
    VNN: { isoCode: 'VNN', name: 'Vietnamese Dong (1978–1985)', symbol: 'VNN' },
    VUV: { isoCode: 'VUV', name: 'Vanuatu Vatu', symbol: 'VUV' },
    WST: { isoCode: 'WST', name: 'Samoan Tala', symbol: 'WST' },
    XAF: { isoCode: 'XAF', name: 'Central African CFA Franc', symbol: 'FCFA' },
    XAG: { isoCode: 'XAG', name: 'Silver', symbol: 'XAG' },
    XAU: { isoCode: 'XAU', name: 'Gold', symbol: 'XAU' },
    XBA: { isoCode: 'XBA', name: 'European Composite Unit', symbol: 'XBA' },
    XBB: { isoCode: 'XBB', name: 'European Monetary Unit', symbol: 'XBB' },
    XBC: { isoCode: 'XBC', name: 'European Unit of Account (XBC)', symbol: 'XBC' },
    XBD: { isoCode: 'XBD', name: 'European Unit of Account (XBD)', symbol: 'XBD' },
    XCD: { isoCode: 'XCD', name: 'East Caribbean Dollar', symbol: 'EC$' },
    XDR: { isoCode: 'XDR', name: 'Special Drawing Rights', symbol: 'XDR' },
    XEU: { isoCode: 'XEU', name: 'European Currency Unit', symbol: 'XEU' },
    XFO: { isoCode: 'XFO', name: 'French Gold Franc', symbol: 'XFO' },
    XFU: { isoCode: 'XFU', name: 'French UIC-Franc', symbol: 'XFU' },
    XOF: { isoCode: 'XOF', name: 'West African CFA Franc', symbol: 'CFA' },
    XPD: { isoCode: 'XPD', name: 'Palladium', symbol: 'XPD' },
    XPF: { isoCode: 'XPF', name: 'CFP Franc', symbol: 'CFPF' },
    XPT: { isoCode: 'XPT', name: 'Platinum', symbol: 'XPT' },
    XRE: { isoCode: 'XRE', name: 'RINET Funds', symbol: 'XRE' },
    XSU: { isoCode: 'XSU', name: 'Sucre', symbol: 'XSU' },
    XTS: { isoCode: 'XTS', name: 'Testing Currency Code', symbol: 'XTS' },
    XUA: { isoCode: 'XUA', name: 'ADB Unit of Account', symbol: 'XUA' },
    XXX: { isoCode: 'XXX', name: 'Unknown Currency', symbol: '¤' },
    YDD: { isoCode: 'YDD', name: 'Yemeni Dinar', symbol: 'YDD' },
    YER: { isoCode: 'YER', name: 'Yemeni Rial', symbol: 'YER' },
    YUD: { isoCode: 'YUD', name: 'Yugoslavian Hard Dinar (1966–1990)', symbol: 'YUD' },
    YUM: { isoCode: 'YUM', name: 'Yugoslavian New Dinar (1994–2002)', symbol: 'YUM' },
    YUN: { isoCode: 'YUN', name: 'Yugoslavian Convertible Dinar (1990–1992)', symbol: 'YUN' },
    YUR: { isoCode: 'YUR', name: 'Yugoslavian Reformed Dinar (1992–1993)', symbol: 'YUR' },
    ZAL: { isoCode: 'ZAL', name: 'South African Rand (financial)', symbol: 'ZAL' },
    ZMK: { isoCode: 'ZMK', name: 'Zambian Kwacha (1968–2012)', symbol: 'ZMK' },
    ZMW: { isoCode: 'ZMW', name: 'Zambian Kwacha', symbol: 'ZMW' },
    ZRN: { isoCode: 'ZRN', name: 'Zairean New Zaire (1993–1998)', symbol: 'ZRN' },
    ZRZ: { isoCode: 'ZRZ', name: 'Zairean Zaire (1971–1993)', symbol: 'ZRZ' },
    ZWD: { isoCode: 'ZWD', name: 'Zimbabwean Dollar (1980–2008)', symbol: 'ZWD' },
    ZWL: { isoCode: 'ZWL', name: 'Zimbabwean Dollar (2009)', symbol: 'ZWL' },
    ZWR: { isoCode: 'ZWR', name: 'Zimbabwean Dollar (2008)', symbol: 'ZWR' },
};
