import React, { ReactElement } from 'react';

import { useTheme } from '@material-ui/core';
import { ValueType } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { CustomTheme } from 'theme/custom';
import { selectStyles, useStyles } from './styles';

export function StyledAsyncSelect(props: {
    onChange: (value: { value: string; label: string }) => void;
    value: { value: string; label: string };
    noOptionsMessage?: string;
    loadOptions?: (inputValue: string) => Promise<{ value: string; label: string }[]>;
    onCreateOption?: (inputValue: string) => Promise<void>;
    defaultOptions?: { value: string; label: string }[];
    readOnly?: boolean;
    disableCreate?: boolean;
}): ReactElement {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const inputProps = {
        classes: {
            underline: classes.fieldUnderline,
        },
        disableUnderline: props.readOnly,
        readOnly: props.readOnly,
    };

    return (
        <AsyncCreatableSelect
            InputProps={{
                ...inputProps,
                disableUnderline: props.readOnly,
                readOnly: props.readOnly,
            }}
            defaultOptions={props.defaultOptions}
            loadOptions={props.loadOptions || (() => undefined)}
            onCreateOption={props.onCreateOption}
            menuPosition={'fixed'}
            onChange={(value: ValueType<{ value: string; label: string }, false>) =>
                props.onChange(value as { value: string; label: string })
            }
            noOptionsMessage={(): string | null => props.noOptionsMessage || 'No options'}
            styles={selectStyles(theme)}
            value={props.value}
            formatCreateLabel={(() => {
                return props.disableCreate ? () => <></> : undefined;
            })()}
        />
    );
}
