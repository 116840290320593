import React, { ReactElement } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Summary } from 'views/RevenueWorkStation/Summary';
import { RevenueTable } from './RevenueTable';

export const RevenueWorkStation = (): ReactElement => {
    const classes = useStyles();
    return (
        <div id={'revenueWorkStationRoot'} className={classes.root}>
            <Grid container direction={'row'} spacing={1}>
                <Grid
                    item
                    style={{
                        width: '1160px',
                        margin: '0 auto',
                    }}
                >
                    <Summary />
                </Grid>
                <Grid
                    item
                    style={{
                        width: '1160px',
                        margin: '0 auto',
                    }}
                >
                    <RevenueTable />
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
    }),
);
