import { AuditEntry } from 'popcorn-js/audit';

export interface Exception {
    id: string;
    entityId: string;
    type: Type;
    exceptionType: ExceptionType;
    number: string;
    status: string;
    exceptionStatus: ExceptionStatus;
    reason: string;
    currency: string;
    action: string;
    actionExecuted: boolean;
    user: string;
    time: string;
    auditEntry: AuditEntry;
}

export enum Type {
    Invoice = 'Invoice',
    Trade = 'Trade',
    Order = 'Order',
    SI = 'SI',
}
export enum ExceptionStatus {
    Pending = 'PENDING',
    Verified = 'VERIFIED',
    Restored = 'RESTORED',
    Deleted = 'DELETED',
}
export enum ExceptionReasons {
    UnverifiedSIPayment = 'Unverified SI Payment',
    OverpaidInvoice = 'Overpaid Invoice',
    OutlyingDueDate = 'Outlying Due Date',
    DueDateNotPopulated = 'due date not populated',
    OverInvoicedOrder = 'Over Invoiced Order',
}
export enum ExceptionType {
    Actionable = 'Actionable',
    IntegrationAnomaly = 'IntegrationAnomaly',
    FailedToTranslate = 'FailedToTranslate',
    NotInvoiced = 'NotInvoiced',
}
