import { ReactElement } from 'react';

export { ItemEntry } from './ItemEntry';
export { Header } from './Header';

export enum FieldType {
    FIELD_TYPE_NAME = 'FIELD_TYPE_NAME',
    FIELD_TYPE_VALUE = 'FIELD_TYPE_VALUE',
}

export interface EntityFields<T> {
    (entity: T, fieldType: FieldType, changedFields: string[]): ReactElement;
}
