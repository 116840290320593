import React, { ChangeEvent, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { AppContext, AppContextT } from 'context';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { HexToRGBA } from 'utils';
import _ from 'lodash';
import { Actions, getCallCurrency, getPutCurrency, optionValid, OptionValues } from './index';
import { Option, OptionDirection, OptionType } from 'popcorn-js/options';
import { OptionPanelCard } from 'components/Option/Tickets/OptionPanelCard';
import { BaseButton, BaseButtonGroup, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { OptionPutCall } from 'components/Option/Tickets/OptionPutCall';
import { BillingType, ImportExport } from 'popcorn-js/tradeV2';
import { OptionDetails } from './OptionDetails';
import { OptionReferences } from './OptionReferences';
import { BillingInformation } from './BillingInformation';
import { useServiceSync } from 'hooks/useService';
import { DefaultRevenueRequest, DefaultRevenueResponse, Handler } from 'popcorn-js/options/handler';
import { debounce } from 'lodash';

export const OptionEditorPanel = ({
    uuid,
    option,
    index,
    dispatch,
    currencyPairToTrade,
    expanded,
    nonTradingDays,
    disabled,
    disableHeader,
    disableTitle,
    onExpand,
    onRemove,
    disableEditMaturityDate,
    marginNotesRequired,
    setMarginNotesRequired,
    intermediaryMarginRequired,
    setIntermediaryMarginRequired,
    billingTypeRequired,
    setBillingTypeRequired,
    setConditionalFieldsRequired,
}: {
    index?: number;
    uuid: string;
    dispatch: Actions;
    option: OptionValues;
    currencyPairToTrade?: CurrencyPair;
    nonTradingDays: Date[];
    onExpand?: () => void;
    expanded?: string;
    disabled?: boolean;
    disableHeader?: boolean;
    disableTitle?: boolean;
    disableEditMaturityDate?: boolean;
    marginNotesRequired: boolean;
    setMarginNotesRequired: (required: boolean) => void;
    intermediaryMarginRequired: boolean;
    setIntermediaryMarginRequired: (required: boolean) => void;
    billingTypeRequired: boolean;
    setBillingTypeRequired: (required: boolean) => void;
    setConditionalFieldsRequired: (required: boolean) => void;
    onRemove?: () => void;
}): ReactElement => {
    const isMounted = useRef(false);
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);

    const clientPartyCode = appContext.party.partyCode;

    // for disabling/enabling revenue fields
    const [revenueCalculated, setRevenueCalculated] = useState<boolean>(false);
    const [bankErrorIconVisible, setBankErrorIconVisible] = useState<boolean>(false);
    const [responseOption, setResponseOption] = useState<Option>();

    // calculate default revenue service
    const [CalculateDefaultRevenue] = useServiceSync<DefaultRevenueRequest, DefaultRevenueResponse>(
        Handler.CalculateDefaultRevenue,
    );

    const calculateDefaultRevenue = async (request: Option) => {
        try {
            const result = await CalculateDefaultRevenue({ option: request });
            if (result.option) {
                const opt = result.option;
                if (opt.billingType) {
                    if (opt.billingType === BillingType.ClientBilling) {
                        dispatch.setBillingType(uuid, BillingType.ClientBilling);
                    } else if (opt.billingType === BillingType.BankBilling) {
                        dispatch.setBillingType(uuid, BillingType.BankBilling);
                    } else {
                        dispatch.setBillingType(uuid, '');
                    }
                }
                dispatch.setIntermediaryMargin(uuid, opt.intermediaryMargin ? opt.intermediaryMargin?.toFixed(4) : '');
                dispatch.setAdminFee(uuid, opt.adminFee ? opt.adminFee?.toFixed(2) : '');
                dispatch.setClientFee(uuid, opt.clientFee ? opt.clientFee?.toFixed(2) : '');
                dispatch.setBilledToBank(uuid, opt.billedToBank ? opt.billedToBank?.toFixed(2) : '');
                dispatch.setBankRate(uuid, opt.bankRate ? opt.bankRate.toFixed(6) : '');
                setResponseOption(opt);
                setRevenueCalculated(true);
                setBankErrorIconVisible(false);
            }
        } catch (e) {
            dispatch.setBillingType(uuid, '');
            dispatch.setIntermediaryMargin(uuid, '');
            dispatch.setAdminFee(uuid, '');
            dispatch.setClientFee(uuid, '');
            dispatch.setBilledToBank(uuid, '');
            dispatch.setBankRate(uuid, '');
            setResponseOption(undefined);
            setRevenueCalculated(false);
            setBankErrorIconVisible(true);
        }
    };

    const debouncedServiceCall = useRef(
        debounce((request: Option) => {
            calculateDefaultRevenue(request).finally();
        }, 2000),
    ).current;

    // cache value changes for triggering the revenue service
    const calcSpotPrice = useMemo(() => {
        return option.strikePrice?.toString();
    }, [option.expiryDate]);

    const calcBank = useMemo(() => {
        return option.bank?.partyCode;
    }, [option.bank]);

    // Trigger the CalculateDefaultRevenue service on field changes
    useEffect(() => {
        if (option.strikePrice && option.strikePrice.gt(0) && option.bank) {
            const request: Option = {
                notionalAmount: option.notionalAmount?.toNumber(),
                strikePrice: option.strikePrice.toNumber(),
                bank: option.bank.partyCode,
                tradingPartyCode: clientPartyCode,
            };
            calculateDefaultRevenue(request).finally();
        }
    }, [calcBank]);
    useEffect(() => {
        if (option.strikePrice && option.strikePrice.gt(0) && option.bank) {
            const request: Option = {
                notionalAmount: option.notionalAmount?.toNumber(),
                strikePrice: option.strikePrice.toNumber(),
                bank: option.bank.partyCode,
                tradingPartyCode: clientPartyCode,
            };
            debouncedServiceCall(request);
        }
    }, [calcSpotPrice]);

    useEffect(() => {
        debouncedServiceCall.cancel();
    }, [debouncedServiceCall]);

    // conditionally required fields
    useEffect(() => {
        if (revenueCalculated && responseOption) {
            const requireMarginNotes =
                (!option.marginNotes || option.marginNotes == '') &&
                (option.billingType != responseOption.billingType ||
                    option.intermediaryMargin?.toFixed(4) !== responseOption.intermediaryMargin?.toFixed(4) ||
                    option.adminFee?.toFixed(2) !== responseOption.adminFee?.toFixed(2));
            const requireIntermediaryMargin = !option.intermediaryMargin || option.intermediaryMargin.eq(0);
            const requireBillingType = !option.billingType;

            setMarginNotesRequired(requireMarginNotes);
            setIntermediaryMarginRequired(requireIntermediaryMargin);
            setBillingTypeRequired(requireBillingType);
            setConditionalFieldsRequired(requireMarginNotes || requireIntermediaryMargin || requireBillingType);
        }
    }, [option.intermediaryMargin, option.billingType, option.adminFee, option.marginNotes]);

    // this hook determined if this component is being mounted for the first time
    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }
    }, []);

    const handleRemove = useCallback(
        _.debounce(() => onRemove && onRemove(), 100),
        [onRemove],
    );

    const putCurrency = getPutCurrency(option.type, currencyPairToTrade, appContext.currencies);
    const callCurrency = getCallCurrency(option.type, currencyPairToTrade, appContext.currencies);

    return (
        <OptionPanelCard
            key={option.uuid + '-card'}
            uuid={uuid || ''}
            disableHeader={disableHeader}
            disabled={disabled}
            onExpand={onExpand}
            option={option}
            index={index}
            expanded={expanded}
            valid={optionValid(option)}
            onRemove={handleRemove}
            dispatch={dispatch}
        >
            <>{/* --------------------- */}</>
            <>{/* Manage option section */}</>
            <>{/* --------------------- */}</>
            {!disableTitle && (
                <div className={classes.manageTradeTitle}>
                    <div className={classes.titleText}>Manage option</div>
                    <div className={classes.manageOptionsSection}>
                        <div className={classes.manageOptionsSectionRow}>
                            <div className={classes.manageOptionsSectionHeading}>Select Option Direction</div>
                            <BaseButtonGroup>
                                <BaseButton
                                    id={`sell-button`}
                                    key={'sell-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={
                                        option.direction === OptionDirection.SELL && option.type === OptionType.PUT
                                            ? COLOR.IMPORT
                                            : option.direction === OptionDirection.SELL &&
                                              option.type === OptionType.CALL
                                            ? COLOR.EXPORT
                                            : COLOR.DARKBLUE
                                    }
                                    size={SIZE.MEDIUM}
                                    onClick={() => dispatch.setDirection(uuid, OptionDirection.SELL)}
                                    text={'Sell'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                                <BaseButton
                                    id={`buy-button`}
                                    key={'buy-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={
                                        option.direction === OptionDirection.BUY && option.type === OptionType.PUT
                                            ? COLOR.EXPORT
                                            : option.direction === OptionDirection.BUY &&
                                              option.type === OptionType.CALL
                                            ? COLOR.IMPORT
                                            : COLOR.DARKBLUE
                                    }
                                    size={SIZE.MEDIUM}
                                    onClick={() => dispatch.setDirection(uuid, OptionDirection.BUY)}
                                    text={'Buy'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                            </BaseButtonGroup>
                        </div>
                        <div className={classes.manageOptionsSectionRow}>
                            <div className={classes.manageOptionsSectionHeading}>Select Option Type</div>
                            <BaseButtonGroup>
                                <BaseButton
                                    id={`put-button`}
                                    key={'put-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={
                                        option.type === OptionType.PUT && option.direction === OptionDirection.BUY
                                            ? COLOR.EXPORT
                                            : option.type === OptionType.PUT &&
                                              option.direction === OptionDirection.SELL
                                            ? COLOR.IMPORT
                                            : COLOR.DARKBLUE
                                    }
                                    size={SIZE.MEDIUM}
                                    onClick={() => dispatch.changeOptionType(uuid, OptionType.PUT)}
                                    text={'Put'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                                <BaseButton
                                    id={`call-button`}
                                    key={'call-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={
                                        option.type === OptionType.CALL && option.direction === OptionDirection.BUY
                                            ? COLOR.IMPORT
                                            : option.type === OptionType.CALL &&
                                              option.direction === OptionDirection.SELL
                                            ? COLOR.EXPORT
                                            : COLOR.DARKBLUE
                                    }
                                    size={SIZE.MEDIUM}
                                    onClick={() => dispatch.changeOptionType(uuid, OptionType.CALL)}
                                    text={'Call'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                            </BaseButtonGroup>
                        </div>
                        <div className={classes.manageOptionsSectionRow}>
                            <div className={classes.manageOptionsSectionHeading}>Select Import/Export</div>
                            <BaseButtonGroup>
                                <BaseButton
                                    id={`import-button`}
                                    key={'import-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={option.importExport === ImportExport.IMPORT ? COLOR.IMPORT : COLOR.DARKBLUE}
                                    size={SIZE.MEDIUM}
                                    onClick={() => dispatch.setImportExport(uuid, ImportExport.IMPORT)}
                                    text={'Import'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                                <BaseButton
                                    id={`export-button`}
                                    key={'export-button'}
                                    variant={VARIANT.CONTAINED}
                                    color={option.importExport === ImportExport.EXPORT ? COLOR.EXPORT : COLOR.DARKBLUE}
                                    size={SIZE.MEDIUM}
                                    onClick={() => dispatch.setImportExport(uuid, ImportExport.EXPORT)}
                                    text={'Export'}
                                    margin={'4px 0px 4px 0px'}
                                    width={'70px'}
                                    disableUppercase
                                />
                            </BaseButtonGroup>
                        </div>
                    </div>
                </div>
            )}
            <div className={classes.tradeCard}>
                <>{/* ----------------------- */}</>
                <>{/* option buy/sell amounts  */}</>
                <>{/* ----------------------- */}</>
                <OptionPutCall
                    type={option.type}
                    direction={option.direction}
                    putCurrency={putCurrency}
                    callCurrency={callCurrency}
                    putAmount={option.type === OptionType.PUT ? option.notionalAmount : option.quoteAmount}
                    callAmount={option.type === OptionType.CALL ? option.notionalAmount : option.quoteAmount}
                    onPutAmountChange={(event: ChangeEvent<HTMLInputElement>) =>
                        option.type === OptionType.PUT
                            ? dispatch.setNotionalAmount(uuid, event.target.value as string)
                            : dispatch.setQuoteAmount(uuid, event.target.value as string)
                    }
                    onCallAmountChange={(event: ChangeEvent<HTMLInputElement>) =>
                        option.type === OptionType.CALL
                            ? dispatch.setNotionalAmount(uuid, event.target.value as string)
                            : dispatch.setQuoteAmount(uuid, event.target.value as string)
                    }
                    typeChange={(_type: OptionType) => {
                        dispatch.changeOptionType(uuid, _type);
                    }}
                />
                <>{/* --------------------------- */}</>
                <>{/* dates, rates and other info */}</>
                <>{/* --------------------------- */}</>
                <OptionDetails
                    uuid={uuid}
                    option={option}
                    bankErrorIconVisible={bankErrorIconVisible}
                    disableEditMaturityDate={disableEditMaturityDate}
                    dispatch={dispatch}
                    nonTradingDays={nonTradingDays}
                />
                <>{/* -------------------------- */}</>
                <>{/* notes and other references */}</>
                <>{/* -------------------------- */}</>
                <OptionReferences uuid={uuid} option={option} dispatch={dispatch} />
                <>{/* -------------------- */}</>
                <>{/* billing information  */}</>
                <>{/* -------------------- */}</>
                <BillingInformation
                    uuid={uuid}
                    option={option}
                    dispatch={dispatch}
                    revenueCalculated={revenueCalculated}
                    marginNotesRequired={marginNotesRequired}
                    intermediaryMarginRequired={intermediaryMarginRequired}
                    billingTypeRequired={billingTypeRequired}
                />
            </div>
        </OptionPanelCard>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: { backgroundColor: theme.palette.background.paper },
    parentTradesTitle: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.dark,
    },
    parentTradesHeading: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        height: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(5),
        backgroundColor: theme.palette.custom.rowHighlights.light,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    parentTradesRow: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(5),
        backgroundColor: theme.palette.custom.rowHighlights.dark,
        borderBottom: `solid 1px ${theme.palette.custom.dividerExtended.hor_div1}`,
    },
    titleText: {
        textTransform: 'uppercase',
        color: theme.palette.custom.stellcapBrand1.light,
        fontSize: '12px',
        margin: theme.spacing(2),
        marginLeft: theme.spacing(3),
    },
    warning: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(1),
        alignItems: 'center',
    },
    warningIcon: { color: theme.palette.warning.light },
    warningText: {
        color: theme.palette.warning.light,
        fontSize: '10px',
        lineHeight: '10px',
    },
    headingText: {
        width: '160px',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        fontSize: '14px',
    },
    valueText: {
        width: '160px',
        color: theme.palette.text.primary,
        fontSize: '16px',
    },
    tradeCard: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        width: '600px',
        paddingTop: theme.spacing(2),
    },
    manageTradeTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.custom.rowHighlights.dark,
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
    },
    detailsLeft: {
        display: 'flex',
        flexDirection: 'row',
        width: '70%',
        columnGap: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    detailsLeftLeft: {
        display: 'flex',
        rowGap: theme.spacing(2),
        width: '50%',
        flexDirection: 'column',
    },
    detailsLeftRight: {
        display: 'flex',
        rowGap: theme.spacing(2),
        width: '50%',
        flexDirection: 'column',
    },
    detailsRight: {
        display: 'flex',
        flexDirection: 'column',
        width: '30%',
        rowGap: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    collapsedInfo: {
        color: theme.palette.text.secondary,
        fontSize: '12px',
        marginRight: theme.spacing(2),
    },
    acmLabel: {
        fontSize: '14px',
    },
    manageOptionsSection: {
        display: 'flex',
        flexDirection: 'column',
        columnGap: theme.spacing(2),
    },
    manageOptionsSectionHeading: {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 'bold',
        width: '200px',
        alignSelf: 'center',
        marginLeft: theme.spacing(3),
    },
    manageOptionsSectionRow: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.2)}`,
    },
}));
