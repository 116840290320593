import React, { ReactElement, useState } from 'react';
import { Dialog, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { StandardCard } from '../Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseCheckBox } from 'components/BaseCheckBox/BaseCheckBox';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import Divider from '@material-ui/core/Divider';
import { text } from './text';

export const PrivacyPolicy = ({
    show,
    onDecline,
    onAccept,
}: {
    show: boolean;
    onAccept: () => void;
    onDecline: () => void;
}): ReactElement => {
    const classes = useStyles();
    const [textExpanded, setTextExpanded] = useState<boolean>(false);
    const [accept, setAccept] = useState<boolean>(false);
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            maxWidth={'md'}
            open={show}
            classes={{ root: classes.dialog, paper: classes.paper }}
        >
            <StandardCard
                cardHeaderProps={{
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TITLE,
                            text: 'Privacy Policy',
                            id: 'title',
                            variant: 'title3',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ELEMENT,
                            element: <span className={classes.updatedTime}>Updated 1 July 2021</span>,
                            id: 'PrivacyPolicy/updatedTime',
                        },
                    ],
                }}
            >
                <div className={classes.content}>
                    <div className={classes.message}>
                        We use cookies to offer you essential services that are required for accessing this website. By
                        continuing to use this website, you consent to the use of cookies in accordance to our
                        <Link onClick={() => setTextExpanded(!textExpanded)} className={classes.privacyPolicyLink}>
                            Privacy Policy
                        </Link>
                        .
                    </div>
                    <div className={classes.accept}>
                        <div className={classes.checkbox}>
                            <BaseCheckBox
                                checked={accept}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setAccept(event.target.checked)
                                }
                            />
                        </div>
                        <p>I hereby accept the terms and conditions in accordance to the Privacy Policy</p>
                    </div>
                    <div className={classes.actions}>
                        <BaseButton
                            text={'Accept'}
                            id={'accept'}
                            disabled={!accept}
                            variant={VARIANT.CONTAINED}
                            color={COLOR.ACTION}
                            size={SIZE.MEDIUM}
                            onClick={onAccept}
                        />
                        <BaseButton
                            text={'Decline'}
                            id={'decline'}
                            variant={VARIANT.OUTLINED}
                            color={COLOR.WHITE}
                            size={SIZE.MEDIUM}
                            onClick={onDecline}
                        />
                    </div>
                    {textExpanded && (
                        <>
                            <Divider variant="middle" />
                            <div className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />
                        </>
                    )}
                </div>
            </StandardCard>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    dialog: {},
    paper: {},
    updatedTime: {
        fontSize: '12px',
        color: theme.palette.text.secondary,
    },
    content: {
        padding: theme.spacing(3.5),
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(),
    },
    message: {
        width: '100%',
        fontSize: '14px',
    },
    checkbox: {
        marginLeft: `-16px`,
    },
    accept: {
        width: '100%',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        columnGap: theme.spacing(2),
    },
    text: {
        height: '200px',
        overflowY: 'auto',
        color: theme.palette.text.primary,
    },
    privacyPolicyLink: {
        marginLeft: '5px',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));
