import React, { ReactElement } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { StandardCard } from 'components/Card/Card';
import { ACTION_BUTTON_TYPE, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { HexToRGBA } from 'utils';

export const ConfirmRemovePayment = (props: {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onCancel?: () => void;
}): ReactElement => {
    const { open, onClose, onConfirm, onCancel } = props;
    const classes = useStyles();
    return (
        <Dialog
            id={'ConfirmTradeDetailsDialog'}
            onClose={onClose}
            open={open}
            maxWidth={'xl'}
            classes={{
                root: classes.dialog,
            }}
        >
            <StandardCard
                cardHeaderProps={{
                    fatter: true,
                    itemsLeft: [
                        {
                            type: ITEM_VARIATION.TITLE,
                            text: `Confirm Remove GL Payment`,
                            id: 'title',
                            variant: 'title2',
                        },
                    ],
                    itemsRight: [
                        {
                            type: ITEM_VARIATION.ICON_BUTTON,
                            icon: ACTION_BUTTON_TYPE.CANCEL,
                            onClick: onClose,
                            id: 'close',
                        },
                    ],
                }}
            >
                <div className={classes.messageRow}>
                    <div className={classes.messageText}>Are you sure you want to remove this GL payment?</div>
                </div>
                <div className={classes.buttonRow}>
                    <BaseButton
                        text={'Confirm'}
                        id={'confirm'}
                        variant={VARIANT.CONTAINED}
                        color={COLOR.ACTION}
                        size={SIZE.MEDIUM}
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                    />
                    <BaseButton
                        text={'Cancel'}
                        id={'cancel'}
                        variant={VARIANT.OUTLINED}
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        onClick={() => {
                            onCancel ? onCancel() : onClose();
                        }}
                    />
                </div>
            </StandardCard>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    content: { backgroundColor: theme.palette.background.paper },
    buttonRow: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    messageRow: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    messageText: {
        width: '160px',
        color: theme.palette.text.secondary,
        fontSize: '14px',
    },
    dialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: HexToRGBA(theme.palette.background.default, 0.8),
        },
    },
}));
