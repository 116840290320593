import React from 'react';
import { CustomTheme } from 'theme/custom';
import { useTheme } from '@material-ui/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export type DisplayClientTotalsProps = {
    variant: 'total' | 'currencyCode' | 'heading';
    value?: string;
    heading?: string;
};

export const DisplayClientTotals = (props: DisplayClientTotalsProps): React.ReactElement => {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles(useTheme());
    const variants = {
        heading: {
            fontWeight: 'bold',
            fontSize: '16px',
            color: theme.palette.text.primary,
            padding: theme.spacing(0.8),
        },
        currencyCode: {
            fontWeight: 'bold',
            fontSize: '14px',
            color: theme.palette.text.primary,
            opacity: 0.7,
            paddingLeft: theme.spacing(0.8),
        },
        total: {
            fontWeight: 'normal',
            fontSize: '14px',
            color: theme.palette.text.primary,
            opacity: 0.7,
        },
    };

    let style = {};
    //style
    style = { ...style, ...variants[props.variant] };

    return (
        <div className={classes.cardRoot}>
            <div style={style}>
                <div className={classes.totals}>
                    {props.heading}
                    {props.value}
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        cardRoot: {
            height: '15px',
            textAlign: 'left',
            padding: theme.spacing(1),
        },
        totals: {
            margin: '0px 5px 0px 10px',
        },
    }),
);
