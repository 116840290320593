import { ProcessingBankFields } from 'components/party/ProcessingBankFields';
import { PartyType } from 'popcorn-js/party';
import React, { ReactElement } from 'react';
import { PartyConfiguration } from './PartyConfiguration';

export const ProcessingBankConfiguration = (): ReactElement => {
    return (
        <PartyConfiguration
            title={'Processing Banks'}
            partyType={PartyType.PROCESSING_BANK}
            fieldsElement={ProcessingBankFields}
        />
    );
};
