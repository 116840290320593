import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { Criteria, Query } from 'popcorn-js/search';
import { User } from '.';
import { FindRequest, FindResponse } from 'popcorn-js/';
import { Identifier } from 'popcorn-js/search/identifier';
import { marshalIdentifier } from 'popcorn-js/search/identifier';
import { marshalCriteria } from 'popcorn-js/search/marshaller';

export type FindAllRequest = {
    query?: Query;
};
export type DeleteRequest = {
    identifier?: Identifier | string;
};
export type DeleteResponse = {
    user: User;
};
export type RestoreRequest = {
    identifier?: Identifier | string;
};
export type RestoreResponse = {
    user: User;
};
export type DeleteForeverRequest = {
    identifier?: Identifier | string;
};
export type DeleteForeverResponse = {
    user: User;
};
export type UpdateRequest = {
    identifier?: Identifier | string;
    user?: User;
};
export type UpdateResponse = {
    user?: User;
};
export const Recordkeeper = {
    ServiceProviderName: 'User-Recordkeeper',
    findAll(request: FindAllRequest): Promise<FindResponse<User>> {
        return jsonRPC<FindAllRequest, FindResponse<User>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.FindAll`,
            request,
        });
    },
    find(request: FindRequest): Promise<FindResponse<User>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<FindRequest, FindResponse<User>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    delete(request: DeleteRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Delete`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    restore(request: RestoreRequest): Promise<RestoreResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RestoreRequest, RestoreResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Restore`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    deleteForever(request: DeleteForeverRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteForeverRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.DeleteForever`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    update(request: UpdateRequest): Promise<UpdateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<UpdateRequest, UpdateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Update`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
};
