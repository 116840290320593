/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, Paper, TableBody, TableCell, Table as MUITable } from '@material-ui/core';
import { WrappedTableRow } from './WrappedTableRow';
import ColumnConfigDialog from './ColumnConfigDialog';
import { CustomTheme } from 'theme/custom';
import { Pagination } from './Pagination';
import { EnhancedTableHead } from './EnhancedTableHead';
import { Criteria, CriteriaType } from 'popcorn-js/search';
import classNames from 'classnames';

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        maxHeight: 'calc(100vh - 150px)',
        overflow: 'hidden',
        marginTop: 0,
    },
    body: {
        fontSize: '12px', // added
    },
    checkboxNormal: {
        color: theme.palette.primary.main,
    },
    paper: {
        width: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        maxHeight: 'calc(100vh - 200px)',
        overflowX: 'scroll',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableCellHead: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        borderWidth: '1px',
        borderColor: theme.palette.custom.paperExtended.paper3,
        height: '48px',
        font: 'Bold 14px Roboto',
        position: 'sticky',
        top: 0,
        whiteSpace: 'nowrap',
        zIndex: 2,
    },
    noRecords: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        borderWidth: '1px',
        borderColor: theme.palette.custom.paperExtended.paper3,
        height: '48px',
    },
    footerGridColumnLayout: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    footer: {
        width: '100%',
    },
    helperText: {
        margin: 'auto',
        marginLeft: '16px',
    },
    tableRowLoading: {
        opacity: 0.3,
    },
}));

export type TableOrderT = 'asc' | 'desc';

export type columnT<T> = {
    title?: string;
    field?: string;
    width?: string;
    render?: (rowData: T) => React.ReactNode | ReactElement | string | number | undefined | unknown;
    disableSort?: boolean;
    filter?: filterT;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export type ColConfigT = {
    header: string;
    visible: boolean;
};

export type filterT = {
    type: CriteriaType;
    options?: unknown[];
    defaultOptions?: unknown[];
    displayAccessor?: string;
    valueAccessor?: string;
    asyncOptionsFetcher?: () => Promise<unknown>;
    width?: number;
    disablePadding?: boolean;
};

const Table = <T extends unknown>(props: {
    data?: T[];
    columns: columnT<T>[];
    colConfigCloseFromCard?: () => void;
    onFilterChange?: (criteria: Criteria) => void;
    page?: number;
    rowsPerPageOptions?: number[];
    rowsPerPage?: number;
    handleChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    handleChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    count?: number;
    loading?: boolean;
    onChangeSorting?: (field: string, order: TableOrderT) => void;
    order?: TableOrderT;
    sortBy?: string;
    defaultColConfig?: ColConfigT[];
    columnConfig?: ColConfigT[];
    tableID?: string;
    initialCriteria?: Record<string, unknown>;
    rowDoubleClickAction?: (rowData: T) => void;
    showCheckboxes?: boolean;
    onSelectAll?: () => void;
    selected?: T[];
    rowClickAction?: (rowData: T) => void;
    showFilterRow?: boolean;
    disableFooter?: boolean;
    onRowCheck?: (rowData: T, checked: boolean) => void;
    colConfigOpenFromCard?: boolean;
    title?: string;
    getRowUniqueIdFunc?: (row: T) => string;
}): ReactElement => {
    const classes = useStyles();

    const {
        data,
        columns,
        colConfigCloseFromCard,
        onFilterChange,
        page,
        rowsPerPageOptions,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        count,
        loading,
        onChangeSorting,
        order,
        sortBy,
        defaultColConfig,
        columnConfig,
        tableID,
        initialCriteria,
        rowDoubleClickAction,
        showCheckboxes,
        onSelectAll,
        selected,
        rowClickAction,
        showFilterRow: showFilterRowFromCard,
        disableFooter,
        onRowCheck,
        colConfigOpenFromCard,
        getRowUniqueIdFunc,
    } = props;

    const emptyRows = (rowsPerPage || 0) - (data?.length || 0);
    const cols: Record<string, columnT<T>> = {};
    for (const c of columns) {
        cols[c.title as string] = c;
    }

    const getVisibleColumns = React.useCallback(
        (columns: columnT<T>[], colConfig?: ColConfigT[]): columnT<T>[] => {
            if (colConfig === undefined) {
                return columns;
            }
            const newColumns: columnT<T>[] = [] as columnT<T>[];
            for (const c of colConfig) {
                if (c.visible) {
                    newColumns.push(cols[c.header]);
                }
            }
            return newColumns;
        },
        [columns],
    );

    // retrieve saved column config
    let initialColConfig = retrieveColConfig(tableID || '');
    if (initialColConfig === undefined) {
        initialColConfig = defaultColConfig;
    }

    const [colConfig, setColConfig] = useState<ColConfigT[] | undefined>(columnConfig || initialColConfig);
    const [hideButton, setHideButton] = useState<boolean>(false);
    const tableRef = React.createRef<HTMLDivElement>();

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const bottom =
            Math.abs(
                event.currentTarget.scrollHeight - (event.currentTarget.scrollTop + event.currentTarget.clientHeight),
            ) <= 10;
        if (bottom && data?.length === rowsPerPage) {
            setHideButton(false);
        } else if (event.currentTarget.scrollHeight > event.currentTarget.clientHeight) {
            setHideButton(true);
        }
    };

    useEffect(() => {
        tableRef.current?.scrollTo({ top: 1, left: 1 });
        if (tableRef.current?.scrollHeight === tableRef?.current?.clientHeight) {
            setHideButton(false);
        } else {
            setHideButton(true);
        }
    }, [page, rowsPerPage]);

    return (
        <div id={tableID} className={classes.root}>
            {colConfigOpenFromCard && (
                <ColumnConfigDialog
                    closeDialog={() => {
                        colConfigCloseFromCard && colConfigCloseFromCard();
                    }}
                    columnConfig={colConfig}
                    defaultColumnConfig={defaultColConfig}
                    onUpdate={(newColConfig: any) => {
                        setColConfig(newColConfig);
                        saveColConfig(tableID || '', newColConfig);
                    }}
                    show={colConfigOpenFromCard}
                />
            )}
            <Paper className={classes.paper}>
                <div
                    ref={tableRef}
                    onScroll={(event: React.UIEvent<HTMLDivElement>): void => handleScroll(event)}
                    className={classes.tableWrapper}
                >
                    <MUITable aria-labelledby="tableTitle" className={classes.table} size={'small'}>
                        <EnhancedTableHead
                            classes={classes}
                            columns={getVisibleColumns(columns, columnConfig || colConfig)}
                            data={data}
                            initialCriteria={initialCriteria || {}}
                            onChangeSorting={
                                onChangeSorting ||
                                (() => {
                                    return;
                                })
                            }
                            onFilterChange={
                                onFilterChange ||
                                (() => {
                                    return;
                                })
                            }
                            onSelectAll={
                                onSelectAll ||
                                (() => {
                                    return;
                                })
                            }
                            order={order || 'desc'}
                            selected={selected || []}
                            showCheckboxes={showCheckboxes || false}
                            showFilterRow={showFilterRowFromCard || false}
                            sortBy={sortBy || ''}
                        />
                        <TableBody>
                            {!data || data.length === 0 ? (
                                <TableRow
                                    className={classNames({
                                        [classes.tableRowLoading]: loading,
                                    })}
                                >
                                    <TableCell className={classes.noRecords} colSpan={20}>
                                        {loading ? 'Loading' : 'No Records Found'}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data.map((row: any, index: number) => {
                                    return (
                                        <WrappedTableRow
                                            columns={getVisibleColumns(columns, columnConfig || colConfig)}
                                            index={index}
                                            key={row.id || index}
                                            loading={loading}
                                            onRowCheck={onRowCheck}
                                            page={page}
                                            row={row}
                                            rowClickAction={rowClickAction}
                                            rowDoubleClickAction={rowDoubleClickAction}
                                            selected={selected}
                                            showCheckboxes={showCheckboxes}
                                            getRowUniqueIdFunc={getRowUniqueIdFunc}
                                        />
                                    );
                                })
                            )}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        background: 'none',
                                        height: 30 * emptyRows,
                                    }}
                                >
                                    <TableCell
                                        style={{
                                            border: '0px',
                                            padding: '0px',
                                            fontSize: '12px',
                                        }}
                                    />
                                </TableRow>
                            )}
                        </TableBody>
                    </MUITable>
                </div>
                {!disableFooter && (
                    <div className={classes.footer}>
                        <div className={classes.footerGridColumnLayout}>
                            <div //Display footer right side, but nav arrows are cut off
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    paddingLeft: '50px',
                                }}
                            >
                                <div>
                                    <Pagination
                                        count={count || 0}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        page={page || 0}
                                        rowsPerPage={rowsPerPage || 0}
                                        hideButton={hideButton}
                                    />
                                </div>
                                {!rowsPerPageOptions && <div className={classes.helperText} />}
                            </div>
                        </div>
                    </div>
                )}
            </Paper>
        </div>
    );
};

const retrieveColConfig = (tableID: string): ColConfigT[] | undefined => {
    try {
        const tableConfigurationsStr = localStorage.getItem('tableConfigurations');
        if (tableConfigurationsStr === null || tableConfigurationsStr === undefined) {
            localStorage.setItem('tableConfigurations', JSON.stringify({}));
            return;
        }
        return JSON.parse(tableConfigurationsStr)[tableID];
    } catch (e) {
        console.error(`error retrieving column config: ${e}`);
        return;
    }
};

const saveColConfig = (tableID: string, colConfig: ColConfigT[]): void => {
    try {
        let tableConfigurations: Record<string, any> = {};
        const tableConfigurationsStr = localStorage.getItem('tableConfigurations');
        if (tableConfigurationsStr) {
            tableConfigurations = JSON.parse(tableConfigurationsStr);
        }
        tableConfigurations[tableID] = colConfig;
        localStorage.setItem('tableConfigurations', JSON.stringify(tableConfigurations));
    } catch (e) {
        console.error(`error saving column config: ${e}`);
    }
};

export default Table;
