import { marshalIdentifier } from 'popcorn-js/search/identifier';
import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { RoleTemplate } from '.';
import { FindRequest as FindTemplatesRequest, FindResponse as FindTemplatesResponse } from 'popcorn-js';
import { Criteria } from 'popcorn-js/search';
import { Identifier } from 'popcorn-js/search/identifier';

export type CreateTemplateRequest = {
    template: RoleTemplate;
};
export type CreateTemplateResponse = {
    template: RoleTemplate;
};
export type DeleteTemplatePermanentlyRequest = {
    identifier?: Identifier | string;
};
export type DeleteTemplatePermanentlyResponse = {
    template: RoleTemplate;
};
export type UpdateTemplateRequest = {
    identifier?: Identifier | string;
    template: RoleTemplate;
};
export type UpdateTemplateResponse = {
    template: RoleTemplate;
};

export const Handler = {
    ServiceProviderName: 'Role-TemplateHandler',
    find(request: FindTemplatesRequest): Promise<FindTemplatesResponse<RoleTemplate>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<FindTemplatesRequest, FindTemplatesResponse<RoleTemplate>>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.FindTemplates`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    create(request: CreateTemplateRequest): Promise<CreateTemplateResponse> {
        return jsonRPC<CreateTemplateRequest, CreateTemplateResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.CreateTemplate`,
            request: { ...request },
        });
    },
    update(request: UpdateTemplateRequest): Promise<UpdateTemplateResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<UpdateTemplateRequest, UpdateTemplateResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.UpdateTemplate`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    deletePermanently(request: DeleteTemplatePermanentlyRequest): Promise<DeleteTemplatePermanentlyResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteTemplatePermanentlyRequest, DeleteTemplatePermanentlyResponse>({
            url: config.get('apiURL'),
            method: `${Handler.ServiceProviderName}.DeleteTemplatePermanently`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
};
