import React, { ReactElement } from 'react';
import { ItemWrapper } from 'components/audit/IOWrapper';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { displayAmount, processUnixDateForViewing } from 'utils';
import { LightDatePicker } from 'components/tradeV2/ticket/styledComponents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment/moment';
import classNames from 'classnames';
import { showIcon } from './InvoiceDetailV2';
import { getSignBasedOnType, Invoice } from 'popcorn-js/invoice';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';

export const AmountDetails = (props: {
    invoiceValidationErrors: { field: string; reason: string }[];
    editInvoice: (field: string, newValue: unknown) => void;
    readOnly: boolean;
    invoice: Invoice;
    inputProps: {
        classes: {
            underline: string;
        };
        disableUnderline: boolean;
        readOnly: boolean;
    };
}): ReactElement => {
    const { invoiceValidationErrors, editInvoice, readOnly, invoice, inputProps } = props;
    const classes = useStyles();
    const overPaid = invoice.paidAmount && invoice.originalAmountDue && invoice.paidAmount > invoice.originalAmountDue;
    const currencySymbol = invoice?.currency || '';

    const setPrefix = (invoice: Invoice, value: number | undefined) => {
        const sign = getSignBasedOnType(invoice);
        if (value) {
            if (sign === -1) {
                return currencySymbol + '-';
            } else {
                return currencySymbol;
            }
        } else {
            return currencySymbol;
        }
    };

    return (
        <div className={classes.amountDetailsWrapper}>
            <ItemWrapper
                field={'originalAmountDue'}
                validationErrors={invoiceValidationErrors}
                label={'Original Amount Due'}
            >
                <BaseNumberField
                    InputProps={inputProps}
                    overridePrefixColor
                    prefix={setPrefix(invoice, invoice.originalAmountDue)}
                    disabled={readOnly}
                    id={'InvoiceDetail/originalAmountDue'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        editInvoice('originalAmountDue', Number(e.target.value))
                    }
                    value={displayAmount(invoice.originalAmountDue)}
                />
            </ItemWrapper>
            <ItemWrapper field={'dueDate'} validationErrors={invoiceValidationErrors} label={'Due Date'}>
                <div style={{ width: '125px' }}>
                    <LightDatePicker
                        disabled={readOnly}
                        format={'yyyy/MM/dd'}
                        keyboardIcon={showIcon(readOnly)}
                        InputProps={{
                            disableUnderline: readOnly,
                        }}
                        id="dueDate"
                        onChange={(date: MaterialUiPickersDate, value: string | null | undefined) =>
                            editInvoice('dueDate', moment.utc(value).unix())
                        }
                        style={{ width: '100%' }}
                        value={processUnixDateForViewing(invoice?.dueDate)}
                    />
                </div>
            </ItemWrapper>
            <ItemWrapper field={'costingRate'} validationErrors={invoiceValidationErrors} label={'Costing Rate'}>
                <BaseNumberField
                    InputProps={inputProps}
                    disabled={readOnly}
                    id="InvoiceDetail/costingRate"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        editInvoice('costingRate', Number(e.target.value))
                    }
                    value={invoice.costingRate?.toFixed(4)}
                />
            </ItemWrapper>
            <ItemWrapper
                field={'captureRate'}
                validationErrors={invoiceValidationErrors}
                label={'Invoice Capture Rate'}
            >
                <BaseNumberField
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                    }}
                    disabled={readOnly}
                    id="InvoiceDetail/captureRate"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        editInvoice('captureRate', Number(e.target.value))
                    }
                    value={invoice.captureRate?.toFixed(4)}
                />
            </ItemWrapper>
            <div className={classes.specialItemWrapper}>
                <ItemWrapper field={'paidAmount'} validationErrors={invoiceValidationErrors} label={'Paid Amount'}>
                    <BaseNumberField
                        overridePrefixColor
                        InputProps={inputProps}
                        prefix={setPrefix(invoice, invoice.paidAmount)}
                        disabled={true}
                        id="InvoiceDetail/paidAmount"
                        value={displayAmount(invoice.paidAmount)}
                    />
                </ItemWrapper>
            </div>
            <ItemWrapper field={'balanceDue'} validationErrors={invoiceValidationErrors} label={'Balance Due'}>
                <BaseNumberField
                    overridePrefixColor
                    prefix={currencySymbol}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        className: classNames({
                            [classes.formFieldRed]: overPaid,
                            [classes.formFieldGreen]: !overPaid,
                        }),
                    }}
                    id={'InvoiceDetail/balanceDue'}
                    value={displayAmount(invoice.balanceDue)}
                />
            </ItemWrapper>
            <ItemWrapper
                field={'balanceCaptureRate'}
                validationErrors={invoiceValidationErrors}
                label={'Balance Capture Rate'}
            >
                <BaseNumberField
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                    }}
                    disabled={readOnly}
                    id="InvoiceDetail/balanceCaptureRate"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        editInvoice('balanceCaptureRate', Number(e.target.value))
                    }
                    value={invoice.balanceCaptureRate?.toFixed(4)}
                />
            </ItemWrapper>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    amountDetailsWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        alignContent: 'center',
        height: '574.51px',
        width: '189px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
    },
    formFieldRed: {
        fontWeight: 'bold',
        color: theme.palette.custom.data.overdue.main,
    },
    paidAmount: {
        display: 'flex',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '20px',
    },
    formFieldGreen: {
        fontWeight: 'bold',
        color: theme.palette.success.light,
    },
    specialItemWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        height: 'fit-content',
        paddingBottom: '8px',
        width: '189px',
    },
}));
