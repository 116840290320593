import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseButton, VARIANT, SIZE, COLOR } from 'components/BaseButton';

const useStyles = makeStyles(() => ({
    buttonWrapper: {
        justifySelf: 'center',
        alignSelf: 'center',
        fontSize: '14px',
        width: 'auto',
        marginTop: '100px',
    },
}));

interface PnLBreakdownButtonProps {
    disabled: boolean;
    onClick: (e?: string) => void;
}

const PnLBreakdownButton: React.FC<PnLBreakdownButtonProps> = (props: PnLBreakdownButtonProps) => {
    const classes = useStyles();
    return (
        <div className={classes.buttonWrapper}>
            <BaseButton
                id={'PnLBreadownButton/View'}
                disabled={props.disabled}
                variant={VARIANT.CONTAINED}
                color={COLOR.ACTION}
                size={SIZE.MEDIUM}
                onClick={(): void => props.onClick()}
                text={'VIEW MORE'}
            />
        </div>
    );
};

export default PnLBreakdownButton;
