import React, { ReactElement } from 'react';
import { ItemWrapper } from 'components/audit/IOWrapper';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import moment from 'moment/moment';
import { SystemDateFormat } from 'constants/formats';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Invoice } from 'popcorn-js/invoice';

export const OriginalDetails = (props: {
    invoiceValidationErrors: { field: string; reason: string }[];
    invoice: Invoice;
    inputProps: {
        classes: {
            underline: string;
        };
        disableUnderline: boolean;
        readOnly: boolean;
    };
}): ReactElement => {
    const { invoiceValidationErrors, invoice, inputProps } = props;
    const classes = useStyles();
    return (
        <div className={classes.originalDetailsWrapper}>
            <div className={classes.originalCapturedHeaderWrapper}>Original Captured Info</div>
            <div className={classes.originalCapturedCardWrapper}>
                <ItemWrapper field={'captureDate'} validationErrors={invoiceValidationErrors} label={'Capture Date'}>
                    <BaseTextField
                        InputProps={inputProps}
                        disabled={true}
                        id={'captureDate'}
                        type={'date'}
                        value={moment(invoice.captureDate).format(SystemDateFormat)}
                    />
                </ItemWrapper>
                <ItemWrapper
                    field={'captureSpotRate'}
                    validationErrors={invoiceValidationErrors}
                    label={'Capture Spot Rate'}
                >
                    <BaseNumberField
                        InputProps={inputProps}
                        disabled={true}
                        id="InvoiceDetail/captureSpotRate"
                        value={invoice.captureSpotRate?.toFixed(4)}
                    />
                </ItemWrapper>
                <ItemWrapper
                    field={'originalCaptureRate'}
                    validationErrors={invoiceValidationErrors}
                    label={'Original Capture Rate'}
                >
                    <BaseNumberField
                        InputProps={inputProps}
                        disabled={true}
                        id="InvoiceDetail/originalCaptureRate"
                        value={invoice.originalCaptureRate?.toFixed(4)}
                    />
                </ItemWrapper>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    originalDetailsWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        height: '115px',
        width: '100%',
        display: 'grid',
    },
    originalCapturedCardWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        height: '66.54px',
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid rgba(255,255,255,.25)',
    },
    originalCapturedHeaderWrapper: {
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        height: '46px',
        lineHeight: '46px',
        paddingLeft: '24px',
        display: 'flex',
        fontWeight: 'bold',
        flexDirection: 'row',
        borderTop: '1px solid rgba(255,255,255,.25)',
    },
}));
