import React, { ReactElement, useEffect } from 'react';
import { styled, useStyletron } from 'styletron-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { AppContext } from 'context';
import { ServiceContext } from 'popcorn-js/serviceContext';
import { Navbar } from 'components/Navbar/Navbar';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { useTheme } from '@material-ui/core/styles';
import { Authenticator } from 'popcorn-js/authenticator/authenticator';
import { Claims, UserProfile } from 'popcorn-js/security';
import { appRoutes, configurationRoutes, ViewRoute } from 'routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DefaultContextSwitcher } from 'popcorn-js/party/contextSwitcher';
import { CustomTheme } from 'theme/custom';
import { currencies, Currency } from 'popcorn-js/currency';
import { currencyPairs, CurrencyPair } from 'popcorn-js/currencyPair';
import { Client, IsClient, PartyType, ProcessingBank, ProcessingOrg, System } from 'popcorn-js/party';
import { Recordkeeper as ProcessingBankRecordkeeper } from 'popcorn-js/party/processingBank/recordkeeper';
import { Recordkeeper as ProcessingOrgRecordkeeper } from 'popcorn-js/party/processingOrg/recordkeeper';
import { FullPageLoader } from 'components/Loader/FullPageLoader';
import { v4 as uuidv4 } from 'uuid';
import { Handler } from './popcorn-js/tradeV2/handler';
import { DateKeeper } from './popcorn-js/fxCalendar/dateKeeper';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import { useServiceSync } from './hooks/useService';
import { AcceptPrivacyPolicyRequest, AcceptPrivacyPolicyResponse, Manager } from './popcorn-js/user/manager';
import { DefaultRecordkeeper } from 'popcorn-js/tradingDayException/recordkeeper';
import DefaultHandler from 'popcorn-js/rick/handler';
import { DefaultHandler as PaymentDefaultHandler } from 'popcorn-js/payment/handler';
import { DefaultHandler as DayEndPositionDefaultHandler } from 'popcorn-js/dayEndPosition/handler';
import { DefaultIntegrator as PaymentDefaultIntegrator } from 'popcorn-js/payment/integrator';
import { DefaultConfirmationHandler } from 'popcorn-js/tradeV3/confirmationHandler';
import DefaultCfcDepositHandler from 'popcorn-js/cfcDeposit/handler';
import { Handler as ProducerContractsHandler } from 'popcorn-js/producerContracts/handler';
import { Config } from 'config';

const AppWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
});

const SIDEBAR_OPEN_WIDTH = 300;
const SIDEBAR_CLOSED_WIDTH = 70;

const App = (props: { config: Config; environment: 'www' | 'dev' | 'localhost' | 'staging' }): ReactElement => {
    const {
        config: { apiURL, pricingURL },
        environment,
    } = props;

    const {
        loginWithRedirect,
        isAuthenticated,
        isLoading: auth0Loading,
        getAccessTokenSilently,
        logout: auth0Logout,
    } = useAuth0();
    const [token, setToken] = useState<string | undefined>();
    const [sessionExpired, setSessionExpired] = useState<boolean>(false);
    const [claims, setClaims] = useState<Claims | undefined>();
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [localCurrency, setLocalCurrency] = useState<Currency | undefined>();
    const [party, setParty] = useState<Client | ProcessingOrg | System | undefined>();
    const [processingBanks, setProcessingBanks] = useState<ProcessingBank[] | undefined>();
    const [processingOrg, setProcessingOrg] = useState<ProcessingOrg[] | undefined>();
    const [loginLoading, setLoginLoading] = useState<boolean>(false);
    const [routeKeyPrefix, setRouteKeyPrefix] = useState<string>(uuidv4());

    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();

    const logout = async () => {
        try {
            await Authenticator.logout();
        } catch (e) {
            console.error('logout failed: ', e);
            setError('failed to logout');
        }
        auth0Logout();
        setClaims(undefined);
        setToken(undefined);
    };

    // this hook check if the session expired and then logs out
    useEffect(() => {
        sessionExpired ? logout().finally() : undefined;
    }, [sessionExpired]);

    const determineLocalCurrencyAndParty = (
        _defaultLocalCurrency: Currency | undefined,
        _currencies: Currency[],
        _claims: Claims | undefined,
    ): [Currency | undefined, Client | ProcessingOrg | System | undefined] => {
        let _localCurrency = _defaultLocalCurrency;
        switch (_claims?.currentPartyInfo?.partyType) {
            case PartyType.CLIENT:
                _localCurrency = _currencies?.find(
                    (c: Currency) => c.isoCode === _claims?.currentPartyInfo?.client?.localCurrency,
                );
                return [_localCurrency, _claims?.currentPartyInfo?.client];
            case PartyType.PROCESSING_ORG:
                return [_localCurrency, _claims?.currentPartyInfo?.processingOrg];
            case PartyType.SYSTEM:
                return [_localCurrency, _claims?.currentPartyInfo?.system];
            default:
                throw new Error('unsupported party type');
        }
    };

    // this effect listens to auth0 state and logs into SOL
    useEffect(() => {
        const getToken = async () => {
            if (isAuthenticated && !auth0Loading && !token && !claims) {
                try {
                    const t = await getAccessTokenSilently();
                    setLoginLoading(true);
                    setToken(t);
                    const authResponse = await Authenticator.login({ token: t });
                    setClaims(authResponse.claims);
                    const _defaultLocalCurrency = currencies['ZAR'];
                    const [_localCurrency, _party] = determineLocalCurrencyAndParty(
                        _defaultLocalCurrency,
                        Object.values(currencies),
                        authResponse.claims,
                    );
                    setParty(_party);
                    setLocalCurrency(_localCurrency);
                    const findProcessingBanksResponse = await ProcessingBankRecordkeeper.find({});
                    setProcessingBanks(findProcessingBanksResponse.records);
                    const findProcessingOrgResponse = await ProcessingOrgRecordkeeper.find({});
                    setProcessingOrg(findProcessingOrgResponse.records);
                } catch (e) {
                    console.error('failed to authenticate:', e);
                    setError('Failed to authenticate with server.');
                    setTimeout(logout, 3000);
                }
                setLoginLoading(false);
            }
        };
        getToken().finally();
    }, [getAccessTokenSilently, isAuthenticated, auth0Loading, token, claims]);

    const switchContext = async (partCode: string) => {
        try {
            setLoginLoading(true);
            const switchContextResponse = await DefaultContextSwitcher.switchContext({ partyToSwitchTo: partCode });
            setClaims(switchContextResponse.claims);
            const _defaultLocalCurrency = currencies['ZAR'];
            const [_localCurrency, _party] = determineLocalCurrencyAndParty(
                _defaultLocalCurrency,
                Object.values(currencies),
                switchContextResponse.claims,
            );
            setParty(_party);
            setLocalCurrency(_localCurrency);
            // forces a re-mount of route components
            setRouteKeyPrefix(uuidv4());
        } catch (e) {
            console.error('failed to switch context: ', e);
            setError('Failed to switch context.');
            setTimeout(window.location.reload, 3000);
        }
        setLoginLoading(false);
    };

    // determine if the logged in user is part of a reporting entity in the current context
    const reportingEntity = IsClient(party) && party?.subsidiaries && party.subsidiaries.length > 0;
    let allRoutes = [...appRoutes, ...configurationRoutes];
    if (reportingEntity) {
        // filter out routes that aren't enabled for the reporting entity
        allRoutes = allRoutes.filter((r) => r.reportingEntity);
        for (const r of allRoutes) {
            if (r.views) {
                r.views = r.views.filter((v) => v.reportingEntity);
            }
        }
    }
    let flattenedRoutes: ViewRoute[] = [];
    for (const r of allRoutes) {
        if (r.views) {
            flattenedRoutes = [...flattenedRoutes, ...r.views];
        } else {
            flattenedRoutes.push(r);
        }
    }

    const permissions = claims?.currentRole?.permissions?.filter((p: string) => !p.startsWith('View.'));
    const viewPermissions = claims?.currentRole?.permissions?.filter((p: string) => p.startsWith('View.'));

    const [acceptPrivacyPolicy] = useServiceSync<AcceptPrivacyPolicyRequest, AcceptPrivacyPolicyResponse>(
        Manager.acceptPrivacyPolicy,
    );

    if (!auth0Loading && !isAuthenticated) {
        loginWithRedirect().finally();
        return <></>;
    } else if (!auth0Loading && !loginLoading && isAuthenticated && claims && party && processingBanks && !error) {
        return (
            <AppWrapper>
                <PrivacyPolicy
                    show={!claims.user?.privacyPolicyAccepted}
                    onAccept={() => {
                        acceptPrivacyPolicy({})
                            .then(() =>
                                setClaims({
                                    ...claims,
                                    user: { ...(claims.user as UserProfile), privacyPolicyAccepted: true },
                                }),
                            )
                            .catch((e) => {
                                setError('failed to accept privacy policy: ' + (e.message || e));
                                setTimeout(logout, 2000);
                            });
                    }}
                    onDecline={() => logout()}
                />
                <AppContext.Provider
                    value={{
                        token,
                        apiURL,
                        pricingURL,
                        currentContext: claims.currentContext,
                        originalContext: claims.originalContext,
                        originalExtendedContext: claims.originalExtendedContext,
                        switchableContext: claims.switchableContext,
                        userProfile: claims.user,
                        permissions,
                        viewPermissions,
                        localCurrency,
                        currencies: Object.values(currencies),
                        currencyPairs: Object.values(currencyPairs),
                        assignedCurrencyPairs: determineAssignedCurrencyPairs(
                            party?.currencyPairs || [],
                            Object.values(currencyPairs),
                        ),
                        party: party || ({} as Client),
                        partyType: claims?.currentPartyInfo?.partyType || PartyType.CLIENT,
                        parentPartyCode: claims?.currentPartyInfo?.parentPartyCode,
                        contextSwitched: claims.currentContext?.partyCode !== claims.originalContext?.partyCode,
                        setSessionExpired: () => setSessionExpired(true),
                        environment,
                        processingBanks,
                        processingOrg,
                        reportingEntity,
                        switchContext,
                    }}
                >
                    <ServiceContext.Provider
                        value={{
                            tradeV2Handler: Handler,
                            dateKeeper: DateKeeper,
                            tradingDayExceptionRecordkeeper: DefaultRecordkeeper,
                            ratesHandler: DefaultHandler,
                            paymentHandler: PaymentDefaultHandler,
                            dayEndPositionHandler: DayEndPositionDefaultHandler,
                            paymentIntegrator: PaymentDefaultIntegrator,
                            tradeV3ConfirmationHandler: DefaultConfirmationHandler,
                            contextSwitcher: DefaultContextSwitcher,
                            cfcDepositHandler: DefaultCfcDepositHandler,
                            contractHandler: ProducerContractsHandler,
                        }}
                    >
                        <Navbar
                            sidebarOpenWidth={SIDEBAR_OPEN_WIDTH}
                            sidebarClosedWidth={SIDEBAR_CLOSED_WIDTH}
                            sidebarOpen={sidebarOpen}
                            onSidebarToggle={() => setSidebarOpen(!sidebarOpen)}
                            onContextSwitch={switchContext}
                            onLogout={logout}
                        />
                        <div
                            className={css({
                                display: 'flex',
                                flexGrow: 1,
                                height: '100%',
                            })}
                        >
                            <Sidebar
                                openWidth={SIDEBAR_OPEN_WIDTH}
                                closedWidth={SIDEBAR_CLOSED_WIDTH}
                                open={sidebarOpen}
                                routes={allRoutes}
                                onClose={() => setSidebarOpen(false)}
                            />
                            <div
                                className={css({
                                    flexGrow: 1,
                                    width: `100%`,
                                    marginLeft: `${SIDEBAR_CLOSED_WIDTH}px`,
                                    padding: '24px',
                                    overflow: 'auto',
                                    backgroundColor: theme.palette.background.default,
                                })}
                            >
                                <Switch>
                                    {flattenedRoutes.map((r: ViewRoute) =>
                                        (() => {
                                            return (
                                                <Route
                                                    key={`${routeKeyPrefix}-${r.name}`}
                                                    component={r.component}
                                                    path={r.path}
                                                />
                                            );
                                        })(),
                                    )}
                                    <Redirect to={'/app/dashboard'} />
                                </Switch>
                            </div>
                        </div>
                    </ServiceContext.Provider>
                </AppContext.Provider>
            </AppWrapper>
        );
    } else {
        return (
            <AppWrapper>
                <FullPageLoader errorMessage={error} />
            </AppWrapper>
        );
    }
};

export default App;

const determineAssignedCurrencyPairs = (
    assignedCurrencyPairNames: string[],
    availableCurrencyPairs: CurrencyPair[],
) => {
    return availableCurrencyPairs.filter((ac) => {
        if (assignedCurrencyPairNames.find((name) => name == ac.name)) {
            return true;
        }
    });
};
