import { useCallback, useEffect, useState } from 'react';
import { Actions, calcDefaultImportExport, InitProps, OptionValues } from './index';
import _ from 'lodash';
import { v4 } from 'uuid';
import { getMidDay, getNextTradeDate } from 'utils';
import Big from 'big.js';
import { addDays, isWeekend } from 'date-fns';
import { OptionDirection, OptionType } from 'popcorn-js/options';
import { ImportExport } from 'popcorn-js';
import { BillingType } from 'popcorn-js/tradeV2';
import { ProcessingBank } from 'popcorn-js/party';

export const useTransaction = (initProps: InitProps): { options: OptionValues[]; dispatch: Actions } => {
    const [options, setOptions] = useState<OptionValues[]>([]);

    const handleAddOption = async (prevOptions: OptionValues[]) => {
        const _uuid = v4();
        const today = getMidDay(new Date());
        const putAmount = Big(0);
        const callAmount = Big(0);
        const importExport = calcDefaultImportExport(initProps.type, initProps.direction);
        const initDate = isWeekend(today) ? getNextTradeDate(today) : today;
        const initDeliveryDate = getNextTradeDate(addDays(initDate, 2));

        const newOption = {
            uuid: _uuid,
            bank: initProps.processingBank,
            putAmount,
            callAmount,
            direction: initProps.direction,
            type: initProps.type,
            importExport,
            currencyPair: initProps.currencyPair,
            tradeDate: initDate,
            expiryDate: initDate,
            deliveryDate: initDeliveryDate,
            trader: initProps.trader,
        } as OptionValues;
        setOptions([...prevOptions, newOption]);
        initProps.setExpanded(_uuid);
    };
    useEffect(() => {
        handleAddOption(options).finally();
    }, []);

    const actions: Actions = {
        updateOptionExtRef: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.externalReference = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        addOption: useCallback(() => {
            setOptions((prevOptions: OptionValues[]) => {
                handleAddOption(prevOptions);
                return prevOptions;
            });
        }, []),
        removeOption: useCallback((uuid) => {
            setOptions((prevOptions) => [...prevOptions.filter((opt) => opt.uuid != uuid)]);
        }, []),
        setPremium: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.premium = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setTradeDate: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.tradeDate = (_value as Date) || null;
                        return _option;
                    },
                });
            });
        }, []),
        setExpiryDate: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.expiryDate = (_value as Date) || null;
                        return _option;
                    },
                });
            });
        }, []),
        setDeliveryDate: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.deliveryDate = (_value as Date) || null;
                        return _option;
                    },
                });
            });
        }, []),
        changeOptionType: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.type = (_value as OptionType) || undefined;
                        _option.importExport = calcDefaultImportExport(_option.type, _option.direction);
                        return _option;
                    },
                });
            });
        }, []),
        setDirection: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.direction = (_value as OptionDirection) || undefined;
                        _option.importExport = calcDefaultImportExport(_option.type, _option.direction);
                        return _option;
                    },
                });
            });
        }, []),
        setImportExport: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.importExport = (_value as ImportExport) || undefined;
                        return _option;
                    },
                });
            });
        }, []),
        setBank: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.bank = (_value as ProcessingBank) || undefined;
                        return _option;
                    },
                });
            });
        }, []),
        setNotes: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.notes = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setNotionalAmount: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.notionalAmount = Big((_value as string) || 0);
                        const { strikePrice } = calcStrikePrice(
                            Big(_option.notionalAmount || 0),
                            Big(_option.quoteAmount || 0),
                        );
                        _option.strikePrice = strikePrice;
                        return _option;
                    },
                });
            });
        }, []),
        setQuoteAmount: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.quoteAmount = Big((_value as string) || 0);
                        const { strikePrice } = calcStrikePrice(
                            Big(_option.notionalAmount || 0),
                            Big(_option.quoteAmount || 0),
                        );
                        _option.strikePrice = strikePrice;
                        return _option;
                    },
                });
            });
        }, []),
        setSeason: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.season = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setClientReference: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.clientReference = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setTrader: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.trader = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setProduct: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.product = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setClientNotes: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.clientNotes = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setBillingType: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.billingType = (_value as BillingType) || undefined;
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _option.billingType as BillingType,
                            _option.direction ? _option.direction : OptionDirection.BUY,
                            Big(_option.notionalAmount || 0),
                            Big(_option.strikePrice || 0),
                            Big(_option.intermediaryMargin || 0),
                        );
                        _option.bankRate = bankRate;
                        _option.clientFee = clientFee;
                        _option.billedToBank = billedToBank;
                        return _option;
                    },
                });
            });
        }, []),
        setIntermediaryMargin: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.intermediaryMargin = Big((_value as string) || 0);
                        const { bankRate, clientFee, billedToBank } = calcRevenue(
                            _option.billingType as BillingType,
                            _option.direction ? _option.direction : OptionDirection.BUY,
                            Big(_option.notionalAmount || 0),
                            Big(_option.strikePrice || 0),
                            Big(_option.intermediaryMargin || 0),
                        );
                        _option.bankRate = bankRate;
                        _option.clientFee = clientFee;
                        _option.billedToBank = billedToBank;
                        return _option;
                    },
                });
            });
        }, []),
        setMarginNotes: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.marginNotes = _value as string;
                        return _option;
                    },
                });
            });
        }, []),
        setAdminFee: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.adminFee = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setBankRate: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.bankRate = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setClientFee: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.clientFee = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
        setBilledToBank: useCallback((uuid, value) => {
            setOptions((prevOptions: OptionValues[]) => {
                return setOptionValue({
                    prevOptions,
                    uuid,
                    value,
                    setterFunc: (_option, _value) => {
                        _option.billedToBank = Big((_value as string) || 0);
                        return _option;
                    },
                });
            });
        }, []),
    };

    return { options: options, dispatch: actions };
};

type setOptionValueProps = {
    prevOptions: OptionValues[];
    value: unknown;
    uuid: string;
    setterFunc: (option: OptionValues, value: unknown) => OptionValues;
};

const setOptionValue = ({ prevOptions, setterFunc, uuid, value }: setOptionValueProps) => {
    const newOptions = _.cloneDeep(prevOptions);
    const i = newOptions.findIndex((_o: OptionValues) => _o.uuid === uuid);
    if (i == -1) {
        return prevOptions;
    }

    newOptions[i] = {
        ...setterFunc(newOptions[i], value),
    };

    return newOptions;
};

const calcStrikePrice = (notionalAmount: Big, quoteAmount: Big): { strikePrice: Big } => {
    if (notionalAmount.eq(Big(0)) || quoteAmount.eq(Big(0))) {
        return { strikePrice: Big(0) };
    }
    return { strikePrice: quoteAmount.div(notionalAmount) };
};

const calcRevenue = (
    billingType: BillingType,
    direction: OptionDirection,
    notionalAmount: Big,
    dealRate: Big,
    intermediaryMargin: Big,
): { bankRate: Big; clientFee: Big; billedToBank: Big } => {
    let bankRate = Big(0);
    if (billingType === BillingType.ClientBilling) {
        bankRate = dealRate;
    } else if (billingType === BillingType.BankBilling) {
        bankRate =
            direction === OptionDirection.BUY ? dealRate.sub(intermediaryMargin) : dealRate.add(intermediaryMargin);
    }
    const clientFee = billingType == BillingType.BankBilling ? Big(0) : intermediaryMargin.mul(notionalAmount);
    const billedToBank = billingType === BillingType.BankBilling ? intermediaryMargin.mul(notionalAmount) : Big(0);
    return { bankRate: bankRate, clientFee: clientFee, billedToBank: billedToBank };
};
