import _ from 'lodash';
import { Subscription, User } from '.';
import { SortObjects } from 'utils';

export const Comparator = {
    IsDirty(a?: User, b?: User): boolean {
        if (!a || !b) {
            return true;
        }
        if (a.loginName !== b.loginName) {
            return true;
        }

        if (a.firstName !== b.firstName) {
            return true;
        }

        if (a.lastName !== b.lastName) {
            return true;
        }

        if (a.emailAddress !== b.emailAddress) {
            return true;
        }

        if (a.partyCode !== b.partyCode) {
            return true;
        }

        if (a.roleId !== b.roleId) {
            return true;
        }
        if (!b.subscriptions) {
            b.subscriptions = [];
        }
        return !_.isEqual(
            a.subscriptions?.sort((a: Subscription, b: Subscription) => SortObjects(a, b, ['type'])),
            b.subscriptions?.sort((a: Subscription, b: Subscription) => SortObjects(a, b, ['type'])),
        );
    },
};

export default Comparator;
