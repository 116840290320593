import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { List, ListItem } from '@material-ui/core';
import {
    BillingAddress,
    Client,
    PartyT,
    PartyType,
    ProcessingBank,
    ProcessingOrg,
    TransactionFee,
} from 'popcorn-js/party';
import { FullPageLoader as Loader } from 'components/Loader/FullPageLoader';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import {
    AddSubsidiaryRequest,
    AddSubsidiaryResponse,
    AssociationsRequest,
    AssociationsResponse,
    CreateRequest as ClientCreateRequest,
    CreateResponse as ClientCreateResponse,
    DeleteForeverRequest as ClientDeleteForeverRequest,
    DeleteForeverResponse as ClientDeleteForeverResponse,
    DeleteRequest as ClientDeleteRequest,
    DeleteResponse as ClientDeleteResponse,
    Handler as ClientHandler,
    RemoveSubsidiaryRequest,
    RemoveSubsidiaryResponse,
    RestoreRequest as ClientRestoreRequest,
    RestoreResponse as ClientRestoreResponse,
    UpdateRequest as ClientUpdateRequest,
    UpdateResponse as ClientUpdateResponse,
} from 'popcorn-js/party/client/handler';
import {
    CreateRequest as ProcessingOrgCreateRequest,
    CreateResponse as ProcessingOrgCreateResponse,
    DeleteForeverRequest as ProcessingOrgDeleteForeverRequest,
    DeleteForeverResponse as ProcessingOrgDeleteForeverResponse,
    DeleteRequest as ProcessingOrgDeleteRequest,
    DeleteResponse as ProcessingOrgDeleteResponse,
    Recordkeeper as ProcessingOrgRecordkeeper,
    RestoreRequest as ProcessingOrgRestoreRequest,
    RestoreResponse as ProcessingOrgRestoreResponse,
    RetrieveRequest,
    RetrieveResponse,
    UpdateRequest as ProcessingOrgUpdateRequest,
    UpdateResponse as ProcessingOrgUpdateResponse,
} from 'popcorn-js/party/processingOrg/recordkeeper';
import {
    CreateRequest as ProcessingBankCreateRequest,
    CreateResponse as ProcessingBankCreateResponse,
    DeleteForeverRequest as ProcessingBankDeleteForeverRequest,
    DeleteForeverResponse as ProcessingBankDeleteForeverResponse,
    DeleteRequest as ProcessingBankDeleteRequest,
    DeleteResponse as ProcessingBankDeleteResponse,
    Recordkeeper as ProcessingBankRecordkeeper,
    RestoreRequest as ProcessingBankRestoreRequest,
    RestoreResponse as ProcessingBankRestoreResponse,
    UpdateRequest as ProcessingBankUpdateRequest,
    UpdateResponse as ProcessingBankUpdateResponse,
} from 'popcorn-js/party/processingBank/recordkeeper';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { BasicInformation } from './BasicInformation';
import { objectCopy, simpleCompare, SortObjects } from 'utils/';
import { CurrencyPairOptionT, CurrencyPairs } from './CurrencyPairs';
import { makeStyles } from '@material-ui/styles';
import { AppContext, AppContextT } from 'context';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { CustomTheme } from 'theme/custom';
import { StandardCard } from 'components/Card/Card';
import { Item, ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { EntityFields } from 'components/history';
import { useServiceSync } from 'hooks/useService';
import { FindRequest, FindResponse, RetrieveHistoryRequest, RetrieveHistoryResponse } from 'popcorn-js';
import { OptionT, Subsidiaries } from './Subsidiaries';
import { MoveUsers } from './MoveUsers';
import { Trade } from 'popcorn-js/tradeV2';
import {
    AddCircle,
    ArrowBackIos,
    Cancel,
    Delete,
    DeleteSweep,
    GetApp,
    History,
    RestoreFromTrash,
    Save,
    Update,
} from '@material-ui/icons';
import { ActionButton, COLOR } from 'components/ActionButton/ActionButton';
import { Contacts } from './Contacts';
import { Address } from './BillingAddress';
import { Traders } from './Traders';
import { Products } from './Products';
import { Pricing } from './Pricing';
import Generator, { GenerateClientFinancialYearEndReportResponse } from 'popcorn-js/report';
import { format } from 'date-fns';
import FileSaver from 'file-saver';

const scrollToPos = (pos: number) => {
    const objDiv = document.getElementById(partyListId);
    if (objDiv) {
        objDiv.scrollTop = pos * objDiv.scrollHeight;
    }
};

const ACTIVE_STATE_VIEWING = 'ACTIVE_STATE_VIEWING';
const ACTIVE_STATE_CREATING = 'ACTIVE_STATE_CREATING';
const ACTIVE_STATE_EDITING = 'ACTIVE_STATE_EDITING';
const ACTIVE_STATE_VIEWING_DELETE = 'ACTIVE_STATE_VIEWING_DELETE';

const tabs = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
};

const partyListId = `PartyConfigurationList`;
const newAutoFocusElementId = 'partyCode';

type CreateRequestT = ClientCreateRequest & ProcessingOrgCreateRequest & ProcessingBankCreateRequest;
type CreateResponseT = Partial<ClientCreateResponse & ProcessingOrgCreateResponse & ProcessingBankCreateResponse>;
type UpdateRequestT = ClientUpdateRequest & ProcessingOrgUpdateRequest & ProcessingBankUpdateRequest;
type UpdateResponseT = Partial<ClientUpdateResponse & ProcessingOrgUpdateResponse & ProcessingBankUpdateResponse>;
type DeleteRequestT = ClientDeleteRequest & ProcessingOrgDeleteRequest & ProcessingBankDeleteRequest;
type DeleteResponseT = Partial<ClientDeleteResponse & ProcessingOrgDeleteResponse & ProcessingBankDeleteResponse>;
type DeleteForeverRequestT = ClientDeleteForeverRequest &
    ProcessingOrgDeleteForeverRequest &
    ProcessingBankDeleteForeverRequest;
type DeleteForeverResponseT = Partial<
    ClientDeleteForeverResponse & ProcessingOrgDeleteForeverResponse & ProcessingBankDeleteForeverResponse
>;
type RestoreRequestT = ClientRestoreRequest & ProcessingOrgRestoreRequest & ProcessingBankRestoreRequest;
type RestoreResponseT = Partial<ClientRestoreResponse & ProcessingOrgRestoreResponse & ProcessingBankRestoreResponse>;

/**
 * PartyConfiguration is a generic configuration component for all party types.
 * @param props
 */
export function PartyConfiguration(props: {
    title: string;
    partyType: PartyType;
    fieldsElement: EntityFields<PartyT>;
}): ReactElement {
    const appContext = useContext<AppContextT>(AppContext);
    const classes = useStyles();
    const { fieldsElement, partyType, title } = props;

    const deleteTitle = ((): string => {
        switch (partyType) {
            case PartyType.CLIENT:
                return 'Deleted Client';
            case PartyType.PROCESSING_BANK:
                return 'Deleted Bank';
            case PartyType.PROCESSING_ORG:
                return 'Deleted Org';
            default:
                return 'Deleted Org';
        }
    })();

    const partyTypeName = (() => {
        switch (partyType) {
            case PartyType.CLIENT:
                return 'Client';
            case PartyType.PROCESSING_ORG:
                return 'Processing Org';
            case PartyType.PROCESSING_BANK:
                return 'Processing Bank';
            default:
                return 'unsupported party type';
        }
    })();

    const handler = (():
        | typeof ClientHandler
        | typeof ProcessingOrgRecordkeeper
        | typeof ProcessingBankRecordkeeper => {
        switch (partyType) {
            case PartyType.CLIENT:
                return ClientHandler;
            case PartyType.PROCESSING_BANK:
                return ProcessingBankRecordkeeper;
            case PartyType.PROCESSING_ORG:
                return ProcessingOrgRecordkeeper;
            default:
                throw new Error('unsupported party type');
        }
    })();

    const [partyFind] = useServiceSync<FindRequest, FindResponse<PartyT>>(handler.find);

    const [partyUpdate] = useServiceSync<UpdateRequestT, UpdateResponseT>(handler.update);

    const [partyCreate] = useServiceSync<CreateRequestT, CreateResponseT>(handler.create);
    const [partyRetrieveHistory] = useServiceSync<RetrieveHistoryRequest, RetrieveHistoryResponse<PartyT>>(
        handler.retrieveHistory,
    );
    const [parentParty, setParentParty] = useState<ProcessingOrg | undefined>();
    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );
    const [partyDelete] = useServiceSync<DeleteRequestT, DeleteResponseT>(handler.delete);
    const [partyDeleteForever] = useServiceSync<DeleteForeverRequestT, DeleteForeverResponseT>(handler.deleteForever);
    const [partyRestore] = useServiceSync<RestoreRequestT, RestoreResponseT>(handler.restore);
    const [associations] = useServiceSync<AssociationsRequest, AssociationsResponse>(handler.associations);
    const [addSubsidiary] = useServiceSync<AddSubsidiaryRequest, AddSubsidiaryResponse>(ClientHandler.addSubsidiary);
    const [removeSubsidiary] = useServiceSync<RemoveSubsidiaryRequest, RemoveSubsidiaryResponse>(
        ClientHandler.removeSubsidiary,
    );

    const refresh = () => {
        window.location.reload();
    };

    const [GenerateClientFinancialYearEndReport] = useServiceSync<
        Record<string, never>,
        GenerateClientFinancialYearEndReportResponse
    >(Generator.GenerateClientFinancialYearEndReport);

    const viewingPartyType = appContext.partyType;
    const allCurrencies = appContext.currencies || [];
    const allCurrencyPairs = appContext.currencyPairs || [];

    const [activeState, setActiveState] = useState<string>(ACTIVE_STATE_VIEWING);
    const [parties, setParties] = useState<PartyT[]>([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [previouslySelectedRowIndex, setPreviouslySelectedRowIndex] = useState<number>(-1);
    const [sortBy] = useState<string[]>(['name']);
    const [history, setHistory] = useState<PartyT[]>([]);
    const [selected, setSelected] = useState<PartyT>({} as PartyT);
    const [original, setOriginal] = useState<PartyT>({} as PartyT);
    const [previouslySelected, setPreviouslySelected] = useState<PartyT>({} as PartyT);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);
    const [invalidFields, setInvalidFields] = useState<Record<string, string>>({});
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [assignedCurrencyPairs, setAssignedCurrencyPairs] = useState<string[]>([]);
    const [availableCurrencyPairs, setAvailableCurrencyPairs] = useState<string[]>([]);

    useEffect(() => {
        find().finally();
    }, []);

    const processCurrencyPairs = (_selected: PartyT) => {
        if (_selected && _selected.currencyPairs) {
            const _assignedCurrencyPairs = [..._selected.currencyPairs] || [];
            const _availableCurrencyPairs = allCurrencyPairs
                .filter((c) => !_assignedCurrencyPairs.includes(c.name))
                .map((c) => (allCurrencyPairs.find((pair: CurrencyPair) => pair.name === c.name) || {}).name || c.name);
            setAssignedCurrencyPairs(_assignedCurrencyPairs);
            setAvailableCurrencyPairs(_availableCurrencyPairs);
        }
    };

    const find = async (deleted = false) => {
        const query = {
            sortBy,
        };
        setIsLoading(true);
        try {
            const response = await partyFind({ query, deleted });
            const _parties = response.records;
            const _total = response.total;
            _parties.sort((a: PartyT, b: PartyT) => SortObjects(a, b, sortBy));
            const _selected = _total > 0 ? _parties[0] : ({} as PartyT);
            const selectedRowIndex = _total > 0 ? 0 : -1;
            handleSelection(_selected, selectedRowIndex, deleted ? ACTIVE_STATE_VIEWING_DELETE : ACTIVE_STATE_VIEWING);
            setParties(_parties);
            setSelected(_selected);
            retrieveParentParty(_selected).finally();
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
        setTabIndex(0);
    };

    const handleViewDelete = async () => {
        await find(true);
    };

    const handleSelection = (selected: PartyT, index: number, _activeState?: string, trade?: Trade[]) => {
        if (activeState === ACTIVE_STATE_EDITING && !_activeState) {
            showDiscardConfirmation(() => {
                handleHideAlert();
                handleSelection(selected, index, ACTIVE_STATE_VIEWING, trade);
            });
            return;
        }

        let t = _activeState;
        const currentState = activeState;

        if (!_activeState) {
            switch (currentState) {
                case ACTIVE_STATE_CREATING:
                case ACTIVE_STATE_EDITING:
                    t = ACTIVE_STATE_VIEWING;
                    break;
                default:
                    t = currentState;
            }
        }

        setSelected(objectCopy(selected));
        setOriginal(objectCopy(selected));
        setPreviouslySelected(objectCopy(selected));
        setSelectedRowIndex(index);
        setPreviouslySelectedRowIndex(index);
        setTabIndex(index === -1 ? 0 : tabIndex);
        setActiveState(t || '');
        processCurrencyPairs(objectCopy(selected));
        retrieveParentParty(selected);
    };

    const handleNew = () => {
        if (partyType == PartyType.CLIENT) {
            const defaultClient: Client = {
                ...({} as Client),
                localCurrency: 'ZAR',
                currencyPairs: [],
            };
            setSelected(defaultClient);
            processCurrencyPairs(defaultClient);
        } else {
            const defaultParty: PartyT = {
                ...({} as PartyT),
                currencyPairs: [],
            };
            setSelected(defaultParty);
            processCurrencyPairs(defaultParty);
        }
        setOriginal({} as PartyT);
        retrieveParentParty(selected);
        setSelectedRowIndex(-1);
        setActiveState(ACTIVE_STATE_CREATING);
        setTabIndex(0);
        document.getElementById(newAutoFocusElementId)?.focus();
    };

    const handleRetrieveHistory = async () => {
        setIsLoading(true);
        try {
            const response = await partyRetrieveHistory({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: selected.id },
            });
            const _history = response.history;
            setHistory([..._history]);
            setShowHistory(true);
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
    };

    const handleReturn = async () => {
        await find();
    };

    const handleRestore = async () => {
        handleHideAlert();
        setIsLoading(true);
        try {
            await partyRestore({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: original.id },
            });
            const _parties = parties.slice();
            const idx = _parties.findIndex((c: PartyT) => c.id === selected.id);
            _parties.splice(idx, 1);

            if (_parties.length > idx) {
                handleSelection(parties[idx], idx);
            } else {
                handleSelection(idx === 0 ? ({} as PartyT) : parties[idx - 1], idx - 1);
            }
            setSuccessMessage(`${partyTypeName} restored`);
            setParties(_parties);
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const handleUpdateParties = (_selected: PartyT) => {
        const _parties = parties.slice() || [];
        const partyToReplaceIndex = _parties.findIndex((b) => b.id === _selected?.id);
        if (partyToReplaceIndex >= 0) {
            _parties[partyToReplaceIndex] = _selected;
        }
        _parties.sort((a, b) => SortObjects(a, b, sortBy));
        const selectedIndex = _parties.findIndex((b) => b.id === selected.id);
        handleSelection(_selected, selectedIndex, ACTIVE_STATE_VIEWING);
        setParties(_parties);
        scrollToPos(partyToReplaceIndex / parties.length);
    };

    const handleUpdate = async () => {
        handleHideAlert();
        setIsLoading(true);
        try {
            const updateResponse = await partyUpdate({
                client: selected as Client,
                processingOrg: selected as ProcessingOrg,
                processingBank: selected as ProcessingBank,
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: original.id },
            });
            const updatedParty = updateResponse.client || updateResponse.processingOrg || updateResponse.processingBank;
            const _selected = objectCopy(updatedParty);
            handleUpdateParties(_selected);
            setSuccessMessage(`${partyTypeName} updated`);
            if (partyTypeName == 'Processing Bank') {
                refresh();
            }
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
    };

    const handleCreate = async () => {
        handleHideAlert();
        setIsLoading(true);
        try {
            const _party = objectCopy(selected);
            _party.parentPartyCode = appContext.party?.partyCode;
            const response = await partyCreate({
                client: _party as Client,
                processingOrg: _party as ProcessingOrg,
                processingBank: _party as ProcessingBank,
            });
            const createdParty = response.client || response.processingOrg || response.processingBank;
            const _selected = objectCopy(createdParty);
            const selectedRowIndex = parties.findIndex((c) => c.id === _selected.id);
            handleSelection(_selected, selectedRowIndex, ACTIVE_STATE_VIEWING);
            setSuccessMessage(`${partyTypeName} created`);
            find().finally();
            refresh();
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
    };

    const handleDelete = async () => {
        handleHideAlert();
        setIsLoading(true);
        try {
            await partyDelete({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: original.id },
            });
            setSuccessMessage(`${partyTypeName} deleted`);
            find().finally();
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
    };

    const handleDeleteForever = async () => {
        handleHideAlert();
        setIsLoading(true);
        try {
            await partyDeleteForever({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: original.id },
            });
            const _parties = parties.slice();
            const idx = _parties.findIndex((c: PartyT) => c.id === selected.id);
            _parties.splice(idx, 1);
            if (_parties.length > idx) {
                handleSelection(parties[idx], idx);
            } else {
                handleSelection(idx === 0 ? ({} as PartyT) : parties[idx - 1], idx - 1);
            }
            setSuccessMessage(`${partyTypeName} permanently deleted`);
            setParties(_parties);
        } catch (e) {
            setErrorMessage(e.message || e);
        }
        setIsLoading(false);
    };

    const showDeleteConfirmation = async () => {
        try {
            const associationsResponse = await associations({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: original.id },
                deleted: true,
            });
            setConfirmationMethod(() => async () => {
                await handleDeleteForever();
            });
            let associationsMessage = '. The following associated entities will also be deleted:';
            associationsMessage +=
                associationsResponse.counterpartyIds?.length > 0
                    ? `\n${associationsResponse.counterpartyIds.length} counterpart(y/ies)`
                    : '';
            associationsMessage +=
                associationsResponse.invoiceIds?.length > 0
                    ? `\n${associationsResponse.invoiceIds.length} invoice(s)`
                    : '';
            associationsMessage +=
                associationsResponse.orderIds?.length > 0 ? `\n${associationsResponse.orderIds.length} order(s)` : '';
            associationsMessage +=
                associationsResponse.tradeIds?.length > 0 ? `\n${associationsResponse.tradeIds.length} trade(s)` : '';
            associationsMessage += associationsResponse.siIds ? `\n${associationsResponse.siIds.length} SI(s)` : '';
            associationsMessage +=
                associationsResponse.apiUserIds?.length > 0
                    ? `\n${associationsResponse.apiUserIds.length} API User(s)`
                    : '';
            associationsMessage +=
                associationsResponse.clientIds?.length > 0
                    ? `\n${associationsResponse.clientIds.length} clien(t/ts)`
                    : '';
            associationsMessage +=
                associationsResponse.userIds?.length > 0 ? `\n${associationsResponse.userIds.length} user(s)` : '';
            associationsMessage +=
                associationsResponse.roleIds?.length > 0 ? `\n${associationsResponse.roleIds.length} role(s)` : '';
            setWarningMessage(
                `You are about to permanently delete ${selected.partyCode} - '${selected.name}'.\n ${associationsMessage}.\nDo you want to continue?`,
            );
        } catch (e) {
            setErrorMessage(e.message || e);
        }
    };

    const showDiscardConfirmation = (confirm: () => void) => {
        setConfirmationMethod(() => confirm);
        setWarningMessage('You have unsaved changes. Do you want to continue?');
    };

    const handleCancelUpdate = () => {
        setInvalidFields({});
        setSelected(objectCopy(original));
        setActiveState(ACTIVE_STATE_VIEWING);
        processCurrencyPairs(objectCopy(original));
    };

    const handleCancelCreate = () => {
        setInvalidFields({});
        setSelected(objectCopy(previouslySelected));
        setOriginal(objectCopy(previouslySelected));
        processCurrencyPairs(objectCopy(previouslySelected));
        setActiveState(ACTIVE_STATE_VIEWING);
        setSelectedRowIndex(previouslySelectedRowIndex);
        setTabIndex(0);
    };

    const handleFieldChange = (field: string, newValue: unknown) => {
        const _selected = objectCopy(selected);
        _selected[field] = newValue;
        const _invalidFields = objectCopy(invalidFields);
        _invalidFields[field] = undefined;
        setSelected(_selected);
        setInvalidFields(_invalidFields);
        handleChanges(_selected);
    };

    const retrieveParentParty = async (_selected: PartyT) => {
        try {
            const retrieveParentPartyResponse = await processingOrgRecordkeeperRetrieve({
                identifier: {
                    type: IdentifierType.PARTY_CODE_IDENTIFIER,
                    partyCode: _selected.parentPartyCode,
                },
            });
            setParentParty(retrieveParentPartyResponse.processingOrg);
        } catch (e) {
            console.error('error finding parent party', e);
        }
    };

    const handleAddCurrencyPairs = (currencyPairs: CurrencyPairOptionT[]) => {
        const _selected = objectCopy(selected);
        for (const ccyPair of currencyPairs) {
            _selected.currencyPairs.push(ccyPair.value);
        }
        setSelected(_selected);
        processCurrencyPairs(_selected);
        handleChanges(_selected);
    };

    const handleRemoveCurrencyPairs = (currencyPairs: CurrencyPairOptionT[]) => {
        const _selected = objectCopy(selected);
        _selected.currencyPairs = (_selected.currencyPairs || []).filter(
            (c: string) => !currencyPairs.find((cc: { value: string }) => cc.value === c),
        );
        setSelected(_selected);
        processCurrencyPairs(_selected);
        handleChanges(_selected);
    };

    const handleAddSubsidiaries = async (subsidiaries: OptionT[]) => {
        const _selected = objectCopy(selected);
        if (!_selected.subsidiaries) {
            _selected.subsidiaries = [];
        }
        for (const sub of subsidiaries) {
            try {
                if (PartyType.CLIENT) {
                    await addSubsidiary({ subsidiaryPartyCode: sub.value, clientPartyCode: _selected.partyCode });
                    _selected.subsidiaries.push(sub.value);
                }
                setSuccessMessage('subsidiary added');
            } catch (e) {
                setErrorMessage(e);
            }
        }
        setSelected(_selected);
        handleUpdateParties(_selected);
    };

    const handleContactsFieldChange = (field: string, newValue: unknown, contact: number) => {
        const _selected = objectCopy(selected);
        const _contacts = objectCopy(_selected.contacts[contact]);
        _contacts[field] = newValue;
        _selected.contacts[contact] = _contacts;
        setSelected(_selected);
        handleChanges(_selected);
    };
    const handleTraderFieldChange = (newValue: unknown, index: number) => {
        const _selected = objectCopy(selected);
        const _trader = objectCopy(_selected.traders);
        _trader[index] = newValue;
        _selected.traders[index] = _trader[index];
        setSelected(_selected);
        handleChanges(_selected);
    };
    const handleProductFieldChange = (newValue: unknown, index: number) => {
        const _selected = objectCopy(selected);
        const _product = objectCopy(_selected.products);
        _product[index] = newValue;
        _selected.products[index] = _product[index];
        setSelected(_selected);
        handleChanges(_selected);
    };

    const handleRemoveContact = (index: number) => {
        const _selected = objectCopy(selected);
        const _contacts = objectCopy(_selected.contacts);
        _contacts.splice(index, 1);
        _selected.contacts = _contacts;
        setSelected(_selected);
        handleChanges(_selected);
    };

    const handleAddContact = () => {
        const _selected = objectCopy(selected);
        const _contacts = [];
        _contacts.push(...(objectCopy(_selected.contacts) || []));
        _contacts.push({
            role: '',
            name: '',
            email: '',
            mobile: '',
        });
        _selected.contacts = _contacts;
        setSelected(_selected);
        handleChanges(_selected);
    };

    const handleAddTrader = () => {
        const _selected = objectCopy(selected);
        const _traders = [];
        _traders.push(...(objectCopy(_selected.traders) || []));
        _traders.push('');
        _selected.traders = _traders;
        setSelected(_selected);
        handleChanges(_selected);
    };
    const handleAddProduct = () => {
        const _selected = objectCopy(selected);
        const _products = [];
        _products.push(...(objectCopy(_selected.products) || []));
        _products.push('');
        _selected.products = _products;
        setSelected(_selected);
        handleChanges(_selected);
    };
    const handleAddTransactionFee = () => {
        const fields = {
            newDeal: 0,
            cancellation: 0,
            swap: 0,
            option: 0,
        };
        const _selected = objectCopy(selected);
        const _transactionFee = [];
        _transactionFee.push(...(objectCopy(_selected.transactionFees) || []));
        _transactionFee.push({
            bank: '',
            intermediaryMargins: fields,
            adminFees: fields,
            billingType: 'CLIENT',
        } as TransactionFee);
        _selected.transactionFees = _transactionFee;
        setSelected(_selected);
        handleChanges(_selected);
    };

    const handleRemoveTrader = (index: number) => {
        const _selected = objectCopy(selected);
        const _trader = objectCopy(_selected.traders);
        _trader.splice(index, 1);
        _selected.traders = _trader;
        setSelected(_selected);
        handleChanges(_selected);
    };
    const handleRemoveProduct = (index: number) => {
        const _selected = objectCopy(selected);
        const _product = objectCopy(_selected.products);
        _product.splice(index, 1);
        _selected.products = _product;
        setSelected(_selected);
        handleChanges(_selected);
    };

    const handleFieldChangeBillingAddress = (field: string, newValue: unknown) => {
        const _selected = objectCopy(selected);
        let _billingAddress = objectCopy(_selected.billingAddress);
        if (_billingAddress === undefined) {
            _billingAddress = {} as BillingAddress;
        }
        _billingAddress[field] = newValue;
        _selected.billingAddress = _billingAddress;
        setSelected(_selected);
        handleChanges(_selected);
    };

    const handleRemoveSubsidiaries = async (subsidiaries: OptionT[]) => {
        const _selected = objectCopy(selected);
        for (const sub of subsidiaries) {
            try {
                if (PartyType.CLIENT) {
                    await removeSubsidiary({
                        subsidiaryPartyCode: sub.value,
                        clientPartyCode: _selected.partyCode,
                    });
                }
                setSuccessMessage('subsidiary removed');
            } catch (e) {
                setErrorMessage(e);
            }
        }
        _selected.subsidiaries = (_selected.subsidiaries || []).filter(
            (s: string) => !subsidiaries.find((o: OptionT) => s === o.value),
        );
        setSelected(_selected);
        handleUpdateParties(_selected);
    };

    const handleChanges = (selected: PartyT) => {
        const currentState = activeState;

        let _activeState = currentState;
        switch (currentState) {
            case ACTIVE_STATE_CREATING:
                _activeState = ACTIVE_STATE_CREATING;
                break;

            case ACTIVE_STATE_VIEWING:
                _activeState =
                    selectedRowIndex >= 0
                        ? !simpleCompare(original, selected)
                            ? ACTIVE_STATE_EDITING
                            : ACTIVE_STATE_VIEWING
                        : ACTIVE_STATE_CREATING;
                break;

            case ACTIVE_STATE_EDITING:
                _activeState = !simpleCompare(original, selected) ? ACTIVE_STATE_EDITING : ACTIVE_STATE_VIEWING;
                break;

            default:
        }
        setActiveState(_activeState);
    };

    const handleDownloadClientFinYearReport = async () => {
        try {
            const generateClientFinYearReportResponse = await GenerateClientFinancialYearEndReport({});
            // convert base64 to byte array
            const binData = atob(generateClientFinYearReportResponse.excelData);
            const bytes = new Array(binData.length);
            for (let i = 0; i < binData.length; i++) {
                bytes[i] = binData.charCodeAt(i);
            }
            const blob = new Blob([new Uint8Array(bytes)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
            });

            const today = format(new Date(), 'yyyy-MM-dd');
            FileSaver.saveAs(blob, 'Client Financial Year End Report ' + today + '.xlsx');
        } catch (e) {
            setErrorMessage(e ? e.message : 'Unexpected Error Occurred. Please Contact Your Administrator');
        }
    };

    const renderCurrencyPairsTabContents = () => {
        const assignedCurrencyPairOptions: CurrencyPairOptionT[] = assignedCurrencyPairs.map((c) => ({
            value: c,
            text: (allCurrencyPairs.find((pair: CurrencyPair) => pair.name === c) || {}).name || c,
        }));
        const availableCurrencyPairOptions: CurrencyPairOptionT[] = availableCurrencyPairs
            .filter((c: string) => !!allCurrencyPairs.find((pair: CurrencyPair) => pair.name === c))
            .map((c) => ({
                value: c,
                text: (allCurrencyPairs.find((pair: CurrencyPair) => pair.name === c) || {}).name || c,
            }));
        return (
            <CurrencyPairs
                assignedCurrencyPairOptions={assignedCurrencyPairOptions}
                availableCurrencyPairOptions={availableCurrencyPairOptions}
                onAdd={handleAddCurrencyPairs}
                onRemove={handleRemoveCurrencyPairs}
            />
        );
    };

    const renderDialogs = () => {
        return (
            <div>
                {isLoading && <Loader />}
                {showHistory && (
                    <HistoryLayout
                        entity={selected}
                        entityFields={fieldsElement}
                        entityHistory={history}
                        entityName={partyTypeName}
                        loading={isLoading}
                        onHide={() => setShowHistory(false)}
                        open
                    />
                )}
                <NotificationSweetAlert
                    errorMessage={errorMessage}
                    onClose={handleHideAlert}
                    onConfirm={confirmationMethod}
                    successMessage={successMessage}
                    warningMessage={warningMessage}
                    autoFormat={false}
                />
            </div>
        );
    };

    const renderControls = () => {
        const disableFields = isLoading || activeState === ACTIVE_STATE_VIEWING_DELETE;

        return (
            <div className={classes.controlsWrapper}>
                {(() => {
                    switch (appContext.partyType) {
                        case PartyType.SYSTEM:
                            return (() => {
                                switch (activeState) {
                                    case ACTIVE_STATE_VIEWING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    {title != 'Client' && (
                                                        <ActionButton
                                                            id={`PartyConfiguration/${appContext.partyType}/new`}
                                                            disabled={disableFields}
                                                            onClick={handleNew}
                                                            icon={<AddCircle />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={'New'}
                                                        />
                                                    )}
                                                    <ActionButton
                                                        id={`${partyTypeName}DeleteButton`}
                                                        disabled={disableFields}
                                                        onClick={handleDelete}
                                                        icon={<Delete />}
                                                        color={COLOR.SECONDARY}
                                                        helpText={'Delete'}
                                                    />
                                                    <ActionButton
                                                        id={`${partyTypeName}ViewDeleteButton`}
                                                        disabled={disableFields}
                                                        onClick={handleViewDelete}
                                                        icon={<DeleteSweep />}
                                                        color={COLOR.SECONDARY}
                                                        helpText={`View Deleted ${title}`}
                                                    />
                                                    {title == 'Client' && (
                                                        <ActionButton
                                                            id={`${partyTypeName}DownloadButton`}
                                                            disabled={disableFields}
                                                            onClick={handleDownloadClientFinYearReport}
                                                            icon={<GetApp />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={`Download Client List`}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );

                                    case ACTIVE_STATE_VIEWING_DELETE:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}ReturnButton`}
                                                        disabled={false}
                                                        onClick={handleReturn}
                                                        icon={<ArrowBackIos />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Return'}
                                                    />
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}RestoreButton`}
                                                            disabled={false}
                                                            onClick={handleRestore}
                                                            icon={<RestoreFromTrash />}
                                                            color={COLOR.WHITE}
                                                            helpText={'Restore'}
                                                        />
                                                    )}
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}DeleteButton`}
                                                            disabled={false}
                                                            onClick={showDeleteConfirmation}
                                                            icon={<Delete />}
                                                            color={COLOR.WHITE}
                                                            helpText={'Delete'}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );
                                    default:
                                }
                            })();

                        case PartyType.PROCESSING_BANK:
                            return (() => {
                                switch (activeState) {
                                    case ACTIVE_STATE_VIEWING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`PartyConfiguration/${appContext.partyType}/new`}
                                                        disabled={disableFields}
                                                        onClick={handleNew}
                                                        icon={<AddCircle />}
                                                        color={COLOR.SECONDARY}
                                                        helpText={'New'}
                                                    />
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}DeleteButton`}
                                                            disabled={disableFields}
                                                            onClick={handleDelete}
                                                            icon={<Delete />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={'Delete'}
                                                        />
                                                    )}
                                                    {selectedRowIndex >= 0 && PartyType.CLIENT && (
                                                        <ActionButton
                                                            id={`${partyTypeName}RetrieveHistoryButton`}
                                                            disabled={disableFields}
                                                            onClick={handleRetrieveHistory}
                                                            icon={<History />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={'View History'}
                                                        />
                                                    )}
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}ViewDeleteButton`}
                                                            disabled={disableFields}
                                                            onClick={handleViewDelete}
                                                            icon={<DeleteSweep />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={`View Deleted ${title}`}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );

                                    case ACTIVE_STATE_VIEWING_DELETE:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}ReturnButton`}
                                                        disabled={false}
                                                        onClick={handleReturn}
                                                        icon={<ArrowBackIos />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Return'}
                                                    />
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}RestoreButton`}
                                                            disabled={false}
                                                            onClick={handleRestore}
                                                            icon={<RestoreFromTrash />}
                                                            color={COLOR.WHITE}
                                                            helpText={'Restore'}
                                                        />
                                                    )}
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}DeleteButton`}
                                                            disabled={false}
                                                            onClick={showDeleteConfirmation}
                                                            icon={<Delete />}
                                                            color={COLOR.WHITE}
                                                            helpText={'Delete'}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );
                                    default:
                                }
                            })();
                        case PartyType.PROCESSING_ORG:
                            return (() => {
                                switch (activeState) {
                                    case ACTIVE_STATE_VIEWING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    {title != 'Processing Orgs' && (
                                                        <ActionButton
                                                            id={`PartyConfiguration/${appContext.partyType}/new`}
                                                            disabled={disableFields}
                                                            onClick={handleNew}
                                                            icon={<AddCircle />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={'New'}
                                                        />
                                                    )}
                                                    {selectedRowIndex >= 0 && title != 'Processing Orgs' && (
                                                        <ActionButton
                                                            id={`${partyTypeName}DeleteButton`}
                                                            disabled={disableFields}
                                                            onClick={handleDelete}
                                                            icon={<Delete />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={'Delete'}
                                                        />
                                                    )}
                                                    {selectedRowIndex >= 0 && title != 'Processing Orgs' && (
                                                        <ActionButton
                                                            id={`${partyTypeName}ViewDeleteButton`}
                                                            disabled={disableFields}
                                                            onClick={handleViewDelete}
                                                            icon={<DeleteSweep />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={`View Deleted ${title}`}
                                                        />
                                                    )}
                                                    {title == 'Client' && (
                                                        <ActionButton
                                                            id={`${partyTypeName}DownloadButton`}
                                                            disabled={disableFields}
                                                            onClick={handleDownloadClientFinYearReport}
                                                            icon={<GetApp />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={`Download Client List`}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );

                                    case ACTIVE_STATE_VIEWING_DELETE:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}ReturnButton`}
                                                        disabled={false}
                                                        onClick={handleReturn}
                                                        icon={<ArrowBackIos />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Return'}
                                                    />
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}RestoreButton`}
                                                            disabled={false}
                                                            onClick={handleRestore}
                                                            icon={<RestoreFromTrash />}
                                                            color={COLOR.WHITE}
                                                            helpText={'Restore'}
                                                        />
                                                    )}
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}DeleteButton`}
                                                            disabled={false}
                                                            onClick={showDeleteConfirmation}
                                                            icon={<Delete />}
                                                            color={COLOR.WHITE}
                                                            helpText={'Delete'}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );
                                    default:
                                }
                            })();
                    }
                })()}
            </div>
        );
    };

    const renderTabControls = () => {
        const disableFields = isLoading || activeState === ACTIVE_STATE_VIEWING_DELETE;

        return (
            <div className={classes.controlsWrapper}>
                {(() => {
                    switch (appContext.partyType) {
                        case PartyType.SYSTEM:
                            return (() => {
                                switch (activeState) {
                                    case ACTIVE_STATE_VIEWING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}RetrieveHistoryButton`}
                                                            disabled={disableFields}
                                                            onClick={handleRetrieveHistory}
                                                            icon={<History />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={'View History'}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );
                                    case ACTIVE_STATE_CREATING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}CreateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCreate}
                                                        icon={<Save />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Save'}
                                                    />
                                                    <ActionButton
                                                        id={`${partyTypeName}CancelCreateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCancelCreate}
                                                        icon={<Cancel />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Cancel'}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );

                                    case ACTIVE_STATE_EDITING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}UpdateButton`}
                                                        disabled={disableFields || tabIndex == tabs.five}
                                                        onClick={handleUpdate}
                                                        icon={<Update />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Update'}
                                                    />
                                                    <ActionButton
                                                        id={`${partyTypeName}CancelUpdateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCancelUpdate}
                                                        icon={<Cancel />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Cancel'}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );

                                    default:
                                }
                            })();
                        case PartyType.CLIENT:
                            return (() => {
                                switch (activeState) {
                                    case ACTIVE_STATE_VIEWING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    {selectedRowIndex >= 0 && PartyType.CLIENT && (
                                                        <ActionButton
                                                            id={`${partyTypeName}RetrieveHistoryButton`}
                                                            disabled={disableFields}
                                                            onClick={handleRetrieveHistory}
                                                            icon={<History />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={'View History'}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );
                                    case ACTIVE_STATE_EDITING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}UpdateButton`}
                                                        disabled={disableFields || tabIndex == tabs.five}
                                                        onClick={handleUpdate}
                                                        icon={<Update />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Update'}
                                                    />
                                                    <ActionButton
                                                        id={`${partyTypeName}CancelUpdateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCancelUpdate}
                                                        icon={<Cancel />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Cancel'}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );

                                    default:
                                }
                            })();
                        case PartyType.PROCESSING_BANK:
                            return (() => {
                                switch (activeState) {
                                    case ACTIVE_STATE_VIEWING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}RetrieveHistoryButton`}
                                                            disabled={disableFields}
                                                            onClick={handleRetrieveHistory}
                                                            icon={<History />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={'View History'}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );
                                    case ACTIVE_STATE_CREATING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}CreateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCreate}
                                                        icon={<Save />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Save'}
                                                    />
                                                    <ActionButton
                                                        id={`${partyTypeName}CancelCreateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCancelCreate}
                                                        icon={<Cancel />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Cancel'}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );

                                    case ACTIVE_STATE_EDITING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}UpdateButton`}
                                                        disabled={disableFields || tabIndex == tabs.five}
                                                        onClick={handleUpdate}
                                                        icon={<Update />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Update'}
                                                    />
                                                    <ActionButton
                                                        id={`${partyTypeName}CancelUpdateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCancelUpdate}
                                                        icon={<Cancel />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Cancel'}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );

                                    default:
                                }
                            })();
                        case PartyType.PROCESSING_ORG:
                            return (() => {
                                switch (activeState) {
                                    case ACTIVE_STATE_VIEWING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    {selectedRowIndex >= 0 && (
                                                        <ActionButton
                                                            id={`${partyTypeName}RetrieveHistoryButton`}
                                                            disabled={disableFields}
                                                            onClick={handleRetrieveHistory}
                                                            icon={<History />}
                                                            color={COLOR.SECONDARY}
                                                            helpText={'View History'}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        );
                                    case ACTIVE_STATE_CREATING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}CreateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCreate}
                                                        icon={<Save />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Save'}
                                                    />
                                                    <ActionButton
                                                        id={`${partyTypeName}CancelCreateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCancelCreate}
                                                        icon={<Cancel />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Cancel'}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );

                                    case ACTIVE_STATE_EDITING:
                                        return (
                                            <React.Fragment>
                                                <div className={classes.controlsRight}>
                                                    <ActionButton
                                                        id={`${partyTypeName}UpdateButton`}
                                                        disabled={disableFields || tabIndex == tabs.five}
                                                        onClick={handleUpdate}
                                                        icon={<Update />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Update'}
                                                    />
                                                    <ActionButton
                                                        id={`${partyTypeName}CancelUpdateButton`}
                                                        disabled={disableFields}
                                                        onClick={handleCancelUpdate}
                                                        icon={<Cancel />}
                                                        color={COLOR.WHITE}
                                                        helpText={'Cancel'}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );

                                    default:
                                }
                            })();
                    }
                })()}
            </div>
        );
    };

    const list = (): ReactElement[] => {
        return parties.map((party, i) => (
            <ListItem
                button
                component="li"
                key={party.id}
                onClick={(): void => handleSelection(party, i)}
                selected={party.id === selected.id}
            >
                {party.name}
            </ListItem>
        ));
    };

    const itemsLeft: Item[] = [
        {
            type: ITEM_VARIATION.TABS,
            options: (() => {
                switch (partyType) {
                    case PartyType.CLIENT:
                        return [
                            {
                                id: 'PartyConfigurations/BasicInfo',
                                label: 'Basic Info',
                                value: 'Basic Info',
                            },
                            {
                                id: 'PartyConfigurations/Currencies',
                                label: 'Currencies',
                                value: 'Currencies',
                            },
                            {
                                id: 'PartyConfigurations/Subsidiaries',
                                label: 'Subsidiaries',
                                value: 'Subsidiaries',
                            },
                            {
                                id: 'PartyConfigurations/MoveUsers',
                                label: 'Move Users',
                                value: 'Move Users',
                            },
                            {
                                id: 'PartyConfigurations/ClientProducts',
                                label: 'Client Products',
                                value: 'Client Products',
                            },
                            {
                                id: 'PartyConfigurations/BasicInfo',
                                label: 'Pricing',
                                value: 'Pricing',
                            },
                        ];
                    case PartyType.PROCESSING_BANK:
                        return [
                            {
                                id: 'PartyConfigurations/BasicInfo',
                                label: 'Basic Info',
                                value: 'Basic Info',
                            },
                            {
                                id: 'PartyConfigurations/Contacts',
                                label: 'Contacts',
                                value: 'Contacts',
                            },
                            {
                                id: 'PartyConfigurations/BillingAddress',
                                label: 'Billing Address',
                                value: 'Billing Address',
                            },
                        ];
                    case PartyType.PROCESSING_ORG:
                        return [
                            {
                                id: 'PartyConfigurations/BasicInfo',
                                label: 'Basic Info',
                                value: 'Basic Info',
                            },
                            {
                                id: 'PartyConfigurations/CurrencyPairs',
                                label: 'Currency Pairs',
                                value: 'Currency Pairs',
                            },
                            {
                                id: 'PartyConfigurations/Traders',
                                label: 'Traders',
                                value: 'Traders',
                            },
                        ];
                }
            })(),
            onChange: (_event: ChangeEvent<unknown>, value: number) => {
                setTabIndex(value);
            },
            value: tabIndex,
            id: 'partyConfig-tabs',
        },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.listCard}>
                <StandardCard
                    cardHeaderProps={{
                        fullHeight: true,
                        itemsLeft: [
                            {
                                id: 'PartyConfiguration/title',
                                type: ITEM_VARIATION.TITLE,
                                text: activeState === ACTIVE_STATE_VIEWING_DELETE ? deleteTitle : title,
                            },
                        ],
                        itemsRight: [
                            {
                                id: 'PartyConfiguration/controls',
                                type: ITEM_VARIATION.ELEMENT,
                                element: <div>{renderControls()}</div>,
                            },
                        ],
                    }}
                >
                    {renderDialogs()}
                    <div className={classes.inner}>
                        <div className={classes.listWrapper}>
                            <div className={classes.listContent}>
                                <div className={classes.listInner}>
                                    <List component="ol">{list()}</List>
                                </div>
                            </div>
                        </div>
                    </div>
                </StandardCard>
            </div>
            <div className={classes.tabsCard}>
                <StandardCard
                    cardHeaderProps={{
                        fullHeight: true,
                        itemsLeft,
                        itemsRight: [
                            {
                                id: 'PartyConfiguration/controls',
                                type: ITEM_VARIATION.ELEMENT,
                                element: <div>{renderTabControls()}</div>,
                            },
                        ],
                    }}
                >
                    <div className={classes.detailsContent}>
                        <div className={classes.detailInner}>
                            {(() => {
                                switch (partyType) {
                                    case PartyType.CLIENT:
                                        return (() => {
                                            switch (tabIndex) {
                                                case tabs.zero:
                                                    return (
                                                        <BasicInformation
                                                            currencies={allCurrencies}
                                                            disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                                                            importExportFields
                                                            invalidFields={invalidFields}
                                                            isNew={
                                                                activeState === ACTIVE_STATE_CREATING ||
                                                                selectedRowIndex < 0
                                                            }
                                                            onChange={handleFieldChange}
                                                            party={selected}
                                                            viewOfPartyType={partyType}
                                                            viewingPartyType={viewingPartyType}
                                                            processingOrg={parentParty}
                                                        />
                                                    );

                                                case tabs.one:
                                                    return renderCurrencyPairsTabContents();
                                                case tabs.two:
                                                    return (
                                                        <Subsidiaries
                                                            parties={parties}
                                                            selected={selected}
                                                            onAdd={handleAddSubsidiaries}
                                                            onRemove={handleRemoveSubsidiaries}
                                                        />
                                                    );
                                                case tabs.three:
                                                    return <MoveUsers party={selected} parties={parties} />;
                                                case tabs.four:
                                                    return (
                                                        <Products
                                                            client={selected}
                                                            handleFieldChange={handleProductFieldChange}
                                                            handleRemove={handleRemoveProduct}
                                                            handleAdd={handleAddProduct}
                                                        />
                                                    );
                                                case tabs.five:
                                                    return (
                                                        <Pricing
                                                            client={selected}
                                                            onChange={handleFieldChange}
                                                            handleAdd={handleAddTransactionFee}
                                                            handleUpdate={handleUpdate}
                                                            state={activeState}
                                                        />
                                                    );
                                                default:
                                                    return <div>invalid tab value</div>;
                                            }
                                        })();

                                    case PartyType.PROCESSING_ORG:
                                        return (() => {
                                            switch (tabIndex) {
                                                case tabs.zero:
                                                    return (
                                                        <BasicInformation
                                                            currencies={allCurrencies}
                                                            disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                                                            importExportFields
                                                            invalidFields={invalidFields}
                                                            isNew={
                                                                activeState === ACTIVE_STATE_CREATING ||
                                                                selectedRowIndex < 0
                                                            }
                                                            onChange={handleFieldChange}
                                                            party={selected}
                                                            viewOfPartyType={partyType}
                                                            viewingPartyType={viewingPartyType}
                                                        />
                                                    );

                                                case tabs.one:
                                                    return renderCurrencyPairsTabContents();
                                                case tabs.two:
                                                    return (
                                                        <Traders
                                                            processingOrg={selected}
                                                            handleFieldChange={handleTraderFieldChange}
                                                            handleRemove={handleRemoveTrader}
                                                            handleAdd={handleAddTrader}
                                                        />
                                                    );
                                                default:
                                                    return <div>invalid tab value</div>;
                                            }
                                        })();
                                    case PartyType.PROCESSING_BANK:
                                        return (() => {
                                            switch (tabIndex) {
                                                case tabs.zero:
                                                    return (
                                                        <BasicInformation
                                                            currencies={allCurrencies}
                                                            disabled={activeState === ACTIVE_STATE_VIEWING_DELETE}
                                                            importExportFields
                                                            invalidFields={invalidFields}
                                                            isNew={
                                                                activeState === ACTIVE_STATE_CREATING ||
                                                                selectedRowIndex < 0
                                                            }
                                                            onChange={handleFieldChange}
                                                            party={selected}
                                                            viewOfPartyType={partyType}
                                                            viewingPartyType={viewingPartyType}
                                                        />
                                                    );

                                                case tabs.one:
                                                    return (
                                                        <Contacts
                                                            processingBank={selected}
                                                            handleFieldChange={handleContactsFieldChange}
                                                            handleRemove={handleRemoveContact}
                                                            handleAdd={handleAddContact}
                                                        />
                                                    );
                                                case tabs.two:
                                                    return (
                                                        <Address
                                                            handleFieldChange={handleFieldChangeBillingAddress}
                                                            processingBank={selected}
                                                        />
                                                    );
                                                default:
                                                    return <div>invalid tab value</div>;
                                            }
                                        })();
                                    default:
                                        throw new Error('unsupported party type');
                                }
                            })()}
                        </div>
                    </div>
                </StandardCard>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: 'calc(100vh - 100px)',
        overflowY: 'scroll',
        display: 'flex',
        flexGrow: 1,
    },
    listCard: {
        width: '425px',
        overflowY: 'scroll',
        marginLeft: '20px',
        height: '100%',
        scrollBar: 'hidden',
    },
    tabsCard: {
        width: '1200px',
        overflowY: 'scroll',
        height: '100%',
    },
    controlsWrapper: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    controlsLeft: {
        justifySelf: 'start',
    },
    controlsRight: {
        justifySelf: 'end',
        position: 'relative',
    },
    controlsRightBank: {
        justifySelf: 'end',
        marginLeft: '235px',
        position: 'relative',
    },
    inner: {
        height: '100%',
        columnGap: '16px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    detailWrapper: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    detailsContent: {
        overflowY: 'auto',
        flexGrow: 1,
        display: 'flex',
    },
    detailInner: {
        flexGrow: 1,
        display: 'flex',
        padding: theme.spacing(1),
    },
    listWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    listContent: {
        overflowY: 'auto',
        flexGrow: 1,
    },
    listInner: {
        height: '1px',
    },
    appBarWrapper: {
        width: '1000px',
        backgroundColor: 'yellow',
    },
}));
