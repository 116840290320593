import { Criteria, Criterion, CriterionFilterType, Query } from '../search';
import { marshalCriteria } from '../search/marshaller';
import { restRPC } from 'utils/restrpc';

export type DownloadRequest = {
    filterType?: CriterionFilterType;
    query?: Query;
    criteriaTrade?: string[] | Criterion[];
    criteriaOption?: string[] | Criterion[];
};

export type DownloadResponse = {
    data: string;
};

export interface DownloaderI {
    ServiceProviderName: string;
    Download(request: DownloadRequest): Promise<DownloadResponse>;
}

export const RevenueDownloader: DownloaderI = {
    ServiceProviderName: 'Revenue-Downloader',
    Download(request: DownloadRequest): Promise<DownloadResponse> {
        const serializedCriteriaTrade = request.criteriaTrade
            ? marshalCriteria(request.criteriaTrade as Criteria)
            : undefined;
        const serializedCriteriaOption = request.criteriaOption
            ? marshalCriteria(request.criteriaOption as Criteria)
            : undefined;

        return restRPC<DownloadRequest, DownloadResponse>({
            method: `${RevenueDownloader.ServiceProviderName}.Download`,
            request: { ...request, criteriaTrade: serializedCriteriaTrade, criteriaOption: serializedCriteriaOption },
        });
    },
};
