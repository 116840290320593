import React from 'react';
import { Bar, BarChart, XAxis, YAxis, LabelList, ResponsiveContainer, Cell } from 'recharts';
import { useTheme } from '@material-ui/core/styles';
import { CustomTheme } from 'theme/custom';
import { makeStyles } from '@material-ui/core';
import { ImportExportReportItem } from 'popcorn-js/report';

export const AverageDaysGraph: React.FC<ImportExportReportItem> = (props: ImportExportReportItem) => {
    const importValue = props.import;
    const exportValue = props.export;
    const graphData = [
        { name: 'Exports', value: exportValue },
        { name: 'Imports', value: importValue },
    ];

    const theme = useTheme<CustomTheme>();
    const classes = useStyles();

    const barColors = [theme.palette.custom.export.main, theme.palette.custom.import.main];

    return (
        <ResponsiveContainer>
            <BarChart data={graphData} layout={'vertical'} margin={{ top: 0, right: 40, bottom: 5, left: 5 }}>
                <XAxis hide axisLine={false} type="number" />
                <YAxis
                    yAxisId={0}
                    dataKey={'name'}
                    type="category"
                    axisLine={{ stroke: theme.palette.text.primary }}
                    tickLine={false}
                    tick={{ fontSize: '14px', fontWeight: 'bold', fill: theme.palette.text.primary }}
                />
                <Bar dataKey="value" barSize={15} width={20} fill={theme.palette.text.primary}>
                    <LabelList dataKey="value" position="right" className={classes.white} />
                    {graphData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
const useStyles = makeStyles((theme: CustomTheme) => ({
    white: {
        fill: theme.palette.text.primary,
        fontWeight: 'bold',
    },
}));
