/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { TableCell, Typography, TableRow, Checkbox } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { columnT } from './Table';

const useStyles = makeStyles((theme: CustomTheme) => ({
    paper: {
        boxShadow: '0px 0px',
        overflowY: 'hidden',
        overflowX: 'hidden',
        marginRight: '-7px',
    },
    rowLoading: {
        opacity: 0.3,
    },
    tableCellRoot: {
        whiteSpace: 'nowrap',
        color: theme.palette.text.secondary,
        borderWidth: '1px',
        borderColor: theme.palette.custom.paperExtended.paper3,
        height: '38px',
    },
    tableCellBody: {
        fontSize: '12px',
    },
}));

export const WrappedTableRow = <T extends unknown>(props: {
    row: any;
    page: any;
    index: any;
    columns?: columnT<T>[];
    loading: any;
    showCheckboxes: any;
    selected: any;
    rowClickAction: any;
    onRowCheck: any;
    rowDoubleClickAction: any;
    getRowUniqueIdFunc?: (row: any) => string;
}): ReactElement => {
    const classes = useStyles();
    const {
        row,
        page,
        index,
        columns,
        onRowCheck,
        loading,
        showCheckboxes,
        selected,
        rowClickAction,
        getRowUniqueIdFunc,
    } = props;

    const [hover, setHover] = React.useState<boolean>(false);

    const theme = useTheme<CustomTheme>();

    function handleMouseEnter() {
        setHover(true);
    }

    function handleMouseLeave() {
        setHover(false);
    }

    const rowId = getRowUniqueIdFunc ? getRowUniqueIdFunc(row) : row.id;

    const isSelected =
        selected && rowId
            ? !!selected.find((rowData: any) => {
                  const rowDataId = getRowUniqueIdFunc ? getRowUniqueIdFunc(rowData) : rowData.id;
                  return rowDataId === rowId;
              })
            : false;

    const CustomCheckbox = withStyles({
        root: {
            color: hover ? theme.palette.background.default : theme.palette.primary.main,
            '&$checked': {
                color: hover ? theme.palette.background.default : theme.palette.primary.main,
            },
        },
        checked: {},
    })(Checkbox);

    return (
        <React.Fragment>
            <TableRow
                hover
                key={`${row.id}_${index}_${page}`}
                onClick={() => {
                    rowClickAction && rowClickAction(row);
                }}
                onDoubleClick={() => {
                    props.rowDoubleClickAction && props.rowDoubleClickAction(row);
                }}
                style={{
                    opacity: loading ? 0.3 : 1,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                tabIndex={-1}
            >
                {showCheckboxes && (
                    <TableCell classes={{ root: classes.tableCellRoot }} key={'checkbox'} padding={'checkbox'}>
                        <CustomCheckbox
                            checked={isSelected}
                            inputProps={{ 'aria-label': 'select all' }}
                            size={'small'}
                            onChange={(_event: unknown, checked: boolean) => onRowCheck(row, checked)}
                        />
                    </TableCell>
                )}
                {columns?.map((col, index2) => {
                    return (
                        <TableCell
                            align={col?.align || 'left'}
                            classes={{ root: classes.tableCellRoot }}
                            key={`${row.id}_${index}_${page}_${col?.field}_${index2}`}
                            style={{
                                padding: '6px 0px 6px 15px',
                                width: col?.width ? `${col?.width}px` : '',
                                maxWidth: col?.width ? `${col?.width}px` : '',
                            }}
                        >
                            <Typography variant={'body2'} noWrap classes={{ body2: classes.tableCellBody }}>
                                {col?.render ? col?.render(row) : row[col?.field || '']}
                            </Typography>
                        </TableCell>
                    );
                })}
            </TableRow>
        </React.Fragment>
    );
};
