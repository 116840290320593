import { makeStyles, AppBar, Toolbar, Typography, IconButton, useTheme } from '@material-ui/core';
import { AppContextT, AppContext } from 'context';
import React, { ReactElement, useContext } from 'react';
import { CustomTheme } from 'theme/custom';
import CloseIcon from '@material-ui/icons/Close';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { useStyletron } from 'styletron-react';

const useStyles = makeStyles((theme: CustomTheme) => ({
    appBar: {
        position: 'relative',
    },
    title1: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: theme.palette.custom.stellcapBrand1.contrastText,
    },
    title2: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: theme.palette.custom.stellcapBrand2.contrastText,
    },
    icon1: {
        color: theme.palette.custom.stellcapBrand1.contrastText,
    },
    icon2: {
        color: theme.palette.custom.stellcapBrand2.contrastText,
    },
    lineBorder: {
        borderLeft: 'solid',
        borderLeftColor: theme.palette.common.white,
        borderLeftWidth: '1px',
    },
    actionButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        marginRight: theme.spacing(2),
    },
}));

export const BaseAppBar = (props: {
    onClose: () => void;
    title: string;
    ShowLogo?: boolean;
    showCloseButton?: boolean;
    showActionButton?: boolean;
    buttonDisabled?: boolean;
    actionButtonOnClick?: () => void;
    buttonText?: string;
}): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);

    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    const hostname = window && window.location && window.location.hostname;
    let brand1;
    let brand2;

    switch (true) {
        case hostname.includes('stellcap'):
            brand1 = theme.palette.custom.stellcapBrand1;
            brand2 = theme.palette.custom.stellcapBrand2;
            break;
        case hostname.includes('citadel'):
            brand1 = theme.palette.custom.citadelBrand1;
            brand2 = theme.palette.custom.citadelBrand2;
            break;
        case hostname.includes('arcimpex'):
            brand1 = theme.palette.custom.acrImpexBrand1;
            brand2 = theme.palette.custom.acrImpexBrand2;
            break;
        case hostname.includes('kudafx'):
            brand1 = theme.palette.custom.kudaBrand1;
            brand2 = theme.palette.custom.kudaBrand2;
            break;
        default:
            brand1 = theme.palette.custom.fxFlowBrand1;
            brand2 = theme.palette.custom.fxFlowBrand2;
    }

    const appBarColor =
        appContext.currentContext?.partyType == 'CLIENT'
            ? brand2
            : appContext.currentContext?.partyType == 'PROCESSING_ORG'
            ? brand1
            : theme.palette.custom.fxFlowBrand1;

    const titleClass = appContext.contextSwitched ? classes.title2 : classes.title1;
    const iconClass = appContext.contextSwitched ? classes.icon2 : classes.icon1;

    function showActionButton() {
        if (props.showActionButton == true) {
            return (
                <div className={classes.actionButtonWrapper}>
                    <BaseButton
                        id={'button'}
                        variant={VARIANT.OUTLINED}
                        size={SIZE.MEDIUM}
                        text={props.buttonText || 'Record info'}
                        color={COLOR.WHITE}
                        onClick={props.actionButtonOnClick}
                        disabled={props.buttonDisabled}
                    />
                </div>
            );
        }
    }

    function showCloseButton() {
        if (props.showCloseButton == true) {
            return (
                <div className={classes.lineBorder}>
                    <IconButton aria-label="close" edge="end" onClick={props.onClose}>
                        <CloseIcon className={iconClass} />
                    </IconButton>
                </div>
            );
        }
    }

    return (
        <AppBar className={classes.appBar}>
            <Toolbar className={css({ backgroundColor: appBarColor.main })}>
                <Typography className={titleClass} variant="h5">
                    {props.title}
                </Typography>
                {showActionButton()}
                {showCloseButton()}
            </Toolbar>
        </AppBar>
    );
};
