import { ApiUser } from '.';

export const Comparator = {
    IsDirty(a?: ApiUser, b?: ApiUser): boolean {
        if (!a || !b) {
            return true;
        }
        if (a.apiKey !== b.apiKey) {
            return true;
        }
        if (a.description !== b.description) {
            return true;
        }
        if (a.roleId !== b.roleId) {
            return true;
        }
        if (a.partyCode !== b.partyCode) {
            return true;
        }
        if (a.accountLocked !== b.accountLocked) {
            return true;
        }
        if (a.authenticationAllowed !== b.authenticationAllowed) {
            return true;
        }
        return false;
    },
};

export default Comparator;
