import {
    BillingTypes,
    ImportExport,
    PartyType,
    Trade,
    TradeDirection,
    TradeDirections,
    TradeStatuses,
    TradeType,
} from 'popcorn-js/tradeV2';
import React, { cloneElement, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import {
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Table as MUITable,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@material-ui/core';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { ScaleLoader as Spinner } from 'react-spinners';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { AppContext, AppContextT } from 'context';
import { useStyletron } from 'styletron-react';
import {
    Clear as CancelIcon,
    Edit as EditIcon,
    ErrorOutline as ErrorIcon,
    History as HistoryIcon,
    RemoveRedEye,
    Save as SaveIcon,
} from '@material-ui/icons';
import { Event, fieldLabels, IsFieldEditable, State, TradeAmendInputFromTrade } from './index';
import cx from 'classnames';
import { BaseTextField } from 'components/BaseTextField/BaseTextField';
import {
    displayAmount,
    displayDate,
    displayRateSixDecimalPlaces,
    getMidDay,
    HexToRGBA,
    isNumber,
    objectCopy,
} from 'utils';
import {
    AmendRequest,
    DefaultRevenueRequest,
    DefaultRevenueResponse,
    Handler as TradeHandler,
    RecordResponse,
} from 'popcorn-js/tradeV2/handler';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Recordkeeper as ProcessingBankRecordkeeper } from 'popcorn-js/party/processingBank/recordkeeper';
import { Client, ProcessingBank, ProcessingOrg } from 'popcorn-js/party';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useServiceSync } from 'hooks/useService';
import { financialYears, FindRequest, FindResponse, seasons } from 'popcorn-js';
import { Recordkeeper as InvoiceRecordkeeper } from 'popcorn-js/invoice/recordkeeper';
import { Invoice } from 'popcorn-js/invoice';
import { CriteriaType, Criterion, CriterionFilterType } from 'popcorn-js/search';
import InvoiceDetailDialogV2 from 'components/invoice/InvoiceDetailDialogV2';
import Big from 'big.js';
import { BaseNumberField } from 'components/BaseTextField/BaseNumberField';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'popcorn-js/party/processingOrg/recordkeeper';
import { DateKeeper, GetSpotDateForRequest, GetSpotDateForResponse } from 'popcorn-js/fxCalendar/dateKeeper';
import { fromUnixTime, getUnixTime, isBefore, isWeekend, parseISO } from 'date-fns';
import moment from 'moment/moment';
import { TradingDayException } from 'popcorn-js/tradingDayException';
import { ServiceContext, ServiceContextT } from 'popcorn-js/serviceContext';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { debounce } from 'lodash';
import { History } from 'components/tradeV2/History';

type LinkedInvoice = {
    linkAmount: number;
} & Invoice;

type LinkedTrade = {
    linkAmount: number;
} & Trade;

const TradeDetailDialog = (props: {
    closeDialog: () => void;
    disallowEditing?: boolean;
    open: boolean;
    readOnly?: boolean;
    trade?: Trade;
    amendSuccess: () => void;
}): ReactElement => {
    const { closeDialog, disallowEditing, open, readOnly, trade, amendSuccess } = props;
    // state
    const [activeState, setActiveState] = useState<State | Event>(Event.init);
    // NotificationSweetAlert
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
    const [confirmationMethod, setConfirmationMethod] = useState<(() => void) | undefined>(undefined);
    const [originalTrade, setOriginalTrade] = useState<Trade>(objectCopy(trade));
    const [editedTrade, setEditedTrade] = useState<Trade>(objectCopy(trade));
    const [processingBanks, setProcessingBanks] = useState<ProcessingBank[]>([] as ProcessingBank[]);
    const [linkedInvoices, setLinkedInvoices] = useState<LinkedInvoice[]>([] as LinkedInvoice[]);
    const [linkedParentTrades, setLinkedParentTrades] = useState<LinkedTrade[]>([] as LinkedTrade[]);
    const [otherTransactionTrades, setOtherTransactionTrades] = useState<Trade[]>([] as Trade[]);
    const [linkedChildTrades, setLinkedChildTrades] = useState<LinkedTrade[]>([] as LinkedTrade[]);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>(undefined);
    const [closePageVisible, setClosePageVisible] = useState<boolean>(true);
    const appContext = useContext<AppContextT>(AppContext);
    const theme = useTheme<CustomTheme>();
    const [css] = useStyletron();
    const classes = useStyles();
    const [parentParty, setParentParty] = useState<ProcessingOrg | undefined>();
    const [nonTradingDays, setNonTradingDays] = useState<Date[]>([]);
    const [marginNotesNeeded, setMarginNotesNeeded] = useState<boolean>(false);
    const [defaultRevenue, setDefaultRevenue] = useState<Trade>(objectCopy(trade));
    const [validationIcon, setValidationIcon] = useState<JSX.Element[]>();
    const [transactionFeesFilled, setTransactionFeesFilled] = useState<boolean>(false);

    // service providers
    const [processingBankRecordkeeperFind] = useServiceSync<FindRequest, FindResponse<ProcessingBank>>(
        ProcessingBankRecordkeeper.find,
    );
    const { tradingDayExceptionRecordkeeper } = useContext<ServiceContextT>(ServiceContext);
    const [tradeHandlerAmend] = useServiceSync<AmendRequest, RecordResponse>(TradeHandler.Amend);
    const [invoiceRecordkeeperFind] = useServiceSync<FindRequest, FindResponse<Invoice>>(InvoiceRecordkeeper.find);
    const [tradeHandlerFind] = useServiceSync<FindRequest, FindResponse<Trade>>(TradeHandler.Find);
    const [tradeHandlerEditing] = useServiceSync<DefaultRevenueRequest, DefaultRevenueResponse>(
        TradeHandler.CalculateDefaultRevenue,
    );
    const [selectedLinkedTrade, setSelectedLinkedTrade] = useState<Trade | undefined>(undefined);
    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );
    const [getSpotDateFor] = useServiceSync<GetSpotDateForRequest, GetSpotDateForResponse>(DateKeeper.GetSpotDateFor);
    const [findTDEs] = useServiceSync<FindRequest, FindResponse<TradingDayException>>(
        tradingDayExceptionRecordkeeper?.find,
    );
    const [CalculateDefaultRevenue] = useServiceSync<DefaultRevenueRequest, DefaultRevenueResponse>(
        TradeHandler.CalculateDefaultRevenue,
    );
    const allCurrencyPairs = appContext.currencyPairs || ([] as CurrencyPair[]);
    const initCurrencyPair = allCurrencyPairs.find((ccy) => ccy.name == trade?.currencyPair);

    useEffect(() => {
        setClosePageVisible(() => {
            return activeState != State.editingExisting;
        });
    }, [activeState]);

    useEffect(() => {
        setMarginNotesNeeded(() => {
            return (
                editedTrade.marginNotes == '' &&
                (editedTrade.intermediaryMargin !== defaultRevenue.intermediaryMargin ||
                    editedTrade.billingType !== defaultRevenue.billingType ||
                    editedTrade.adminFee !== defaultRevenue.adminFee)
            );
        });
    }, [editedTrade]);

    useEffect(() => {
        findProcessingBanks().finally();
        findLinkedEntities().finally();
        retrieveParentParty().finally();
        findTDEs({
            criteria: [
                { type: CriteriaType.ExactCriterion, field: 'currency', text: initCurrencyPair?.quoteCurrency },
                { type: CriteriaType.ExactCriterion, field: 'currency', text: initCurrencyPair?.baseCurrency },
            ],
            filterType: CriterionFilterType.Or,
        })
            .then((r) =>
                setNonTradingDays(r.records.map((tde: TradingDayException) => getMidDay(parseISO(tde.date || '')))),
            )
            .catch((e) => {
                console.error('error finding TDEs', e);
                setActiveState(Event.errorEvent);
            })
            .finally();
    }, []);

    const party = (appContext.party || {}) as Client | ProcessingOrg;
    const isHoliday = (d: Date, nonTradingDays: Date[]): boolean => {
        for (const ntd of nonTradingDays) {
            if (
                ntd.getDate() === d.getDate() &&
                ntd.getMonth() === d.getMonth() &&
                ntd.getFullYear() === d.getFullYear()
            ) {
                return true;
            }
        }
        return false;
    };

    const retrieveParentParty = async () => {
        setActiveState(Event.enterLoading);
        try {
            const retrieveParentPartyResponse = await processingOrgRecordkeeperRetrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.parentPartyCode },
            });
            setParentParty(retrieveParentPartyResponse.processingOrg);
        } catch (e) {
            console.error('error finding parent party', e);
            setActiveState(Event.errorEvent);
        }
        setActiveState(Event.doneLoading);
    };

    const findProcessingBanks = async () => {
        setActiveState(Event.enterLoading);
        try {
            const findProcessingBanksResponse = await processingBankRecordkeeperFind({});
            setProcessingBanks(findProcessingBanksResponse.records);
        } catch (e) {
            console.error('error finding processing banks', e);
            setActiveState(Event.errorEvent);
        }
        setActiveState(Event.doneLoading);
    };

    const findLinkedEntities = async () => {
        setActiveState(Event.enterLoading);
        try {
            const findLinkedInvoicesResp = await invoiceRecordkeeperFind({
                criteria: [
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'tradeLinks.tradeId',
                        text: trade?.id,
                    },
                ],
            });
            if (trade?.parents && trade?.parents?.length !== 0) {
                const findParentTradesResp = await tradeHandlerFind({
                    criteria: trade.parents.map(
                        (tp): Criterion => ({
                            type: CriteriaType.TextCriterion,
                            field: 'id',
                            text: tp.id,
                        }),
                    ),
                });
                const pt: Array<LinkedTrade> = findParentTradesResp.records.map(
                    (parentTrade): LinkedTrade => ({
                        linkAmount: trade?.parents?.find((t) => t.id === parentTrade?.id)?.amount || 0,
                        ...parentTrade,
                    }),
                );
                setLinkedParentTrades(pt);
            }

            if (trade?.transactionID) {
                const otherTransactionTradesResp = await tradeHandlerFind({
                    criteria: [
                        {
                            type: CriteriaType.TextCriterion,
                            field: 'transactionID',
                            text: trade?.transactionID,
                        },
                    ],
                });
                const thisTrade = otherTransactionTradesResp.records.find((r) => r.id === trade.id);
                if (thisTrade) {
                    setOriginalTrade(thisTrade);
                    setEditedTrade(thisTrade);
                    setDefaultRevenue(thisTrade);
                    setValidationIcon(tradeValidation(thisTrade));
                }
                setOtherTransactionTrades(otherTransactionTradesResp.records);
            }

            const findChildTradesResp = await tradeHandlerFind({
                criteria: [
                    {
                        type: CriteriaType.TextCriterion,
                        field: 'parents.id',
                        text: trade?.id,
                    },
                ],
            });

            const li: Array<LinkedInvoice> = findLinkedInvoicesResp.records.map(
                (inv): LinkedInvoice => {
                    return {
                        linkAmount: inv.tradeLinks?.find((tl) => tl.tradeId === trade?.id)?.amount || 0,
                        ...inv,
                    };
                },
            );

            setLinkedInvoices(li);
            const ct: Array<LinkedTrade> = findChildTradesResp.records.map(
                (childTrade): LinkedTrade => ({
                    linkAmount: childTrade?.parents?.find((t) => t.id === trade?.id)?.amount || 0,
                    ...childTrade,
                }),
            );
            setLinkedChildTrades(ct);
        } catch (e) {
            console.error('error finding linked entities', e);
            setActiveState(Event.errorEvent);
        }
        setActiveState(Event.doneLoading);
    };

    const handleSaveChanges = async () => {
        if (!trade) {
            setActiveState(Event.errorEvent);
            return;
        }
        setActiveState(Event.enterLoading);
        try {
            await tradeHandlerAmend({
                identifier: { type: IdentifierType.ID_IDENTIFIER, id: editedTrade.id },
                trade: TradeAmendInputFromTrade(editedTrade),
            });
            setSuccessMessage('successfully amended trade');
            await amendSuccess();
            findProcessingBanks().finally();
            findLinkedEntities().finally();
            setMarginNotesNeeded(false);
            setSuccessMessage(undefined);
        } catch (e) {
            findProcessingBanks().finally();
            findLinkedEntities().finally();
            setErrorMessage(`error amending revenue: ${e.message || e}`);
        }
        setActiveState(Event.doneLoading);
    };

    const handleCancelEditExisting = () => {
        if (!trade) {
            setActiveState(Event.errorEvent);
            return;
        }
        setWarningMessage('Discard Changes');
        setConfirmationMethod(() => () => {
            setEditedTrade(originalTrade);
            setDefaultRevenue(originalTrade);
            setActiveState(Event.cancelEditExisting);
            setWarningMessage(undefined);
        });
    };

    const handleEnterEditMode = async () => {
        if (originalTrade && originalTrade.validated) {
            setActiveState(Event.enterLoading);
            try {
                await tradeHandlerEditing({ trade: editedTrade }).then((newTrade) => {
                    setEditedTrade(newTrade.trade);
                    setDefaultRevenue(newTrade.trade);
                    setTransactionFeesFilled(true);
                });
            } catch (e) {
                setErrorMessage(`error calculating Default Revenue: ${e.message || e}`);
                setTransactionFeesFilled(false);
            }
            setActiveState(Event.doneLoading);
        }
        setMarginNotesNeeded(false);
        setActiveState(State.editingExisting);
    };

    const calculateDefaultRevenue = useRef(
        debounce(async (trade: Trade) => {
            try {
                CalculateDefaultRevenue({ trade: trade }).then((newTrade) => {
                    setEditedTrade(newTrade.trade);
                });
            } catch (e) {
                setErrorMessage(`error amending Default Revenue: ${e.message || e}`);
            }
        }, 500),
    ).current;

    useEffect(() => {
        calculateDefaultRevenue.cancel();
    }, [calculateDefaultRevenue]);

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };

    const renderTradeDetailsLayout = (): ReactElement => {
        const itemLg = 3;
        const itemMd = 12;
        const itemSm = 12;
        const itemXs = 12;
        return (
            <React.Fragment>
                <div className={classes.tradeDetailFormSection2}>
                    <Grid container direction={'row'} spacing={3}>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('externalReference', undefined, 'accent')}</Grid>
                                <Grid item>{RenderField('type')}</Grid>
                                <Grid item>{RenderField('cancellation')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('direction', undefined, 'importExport')}</Grid>
                                <Grid item>{RenderField('tradeDate')}</Grid>
                                <Grid item>{RenderField('maturityDate', undefined, 'white')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('currencyPair')}</Grid>
                                <Grid item>{RenderField('notionalAmount')}</Grid>
                                <Grid item>{RenderField('quoteAmount')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid item>{RenderField('dealRate')}</Grid>
                            <Grid item>{RenderField('forwardPoints')}</Grid>
                            <Grid item>{RenderField('spotPrice')}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} spacing={3}>
                        <Grid item lg={6} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('notes')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('bank')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tradeDetailSectionLabel2}>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('status', undefined, 'white')}</Grid>
                                <Grid item>{RenderField('acm')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('importExport', undefined, 'importExportWhite')}</Grid>
                                <Grid item>{RenderField('acmBalance')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('availableBalance', undefined, 'white')}</Grid>
                                <Grid item>{RenderField('netCashFlow')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('effectiveRate')}</Grid>
                                <Grid item>{RenderField('realisedPnl')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tradeDetailFormSection2}>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('financialYear')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('season')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('product')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'row'}>
                                <Grid item>{RenderField('clientReference')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item lg={6} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('clientNotes')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('optionReference')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tradeDetailSectionLabel2}>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('trader')}</Grid>
                                <Grid item>{RenderField('marginNotes', undefined, 'red')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('billingType')}</Grid>
                                <Grid item>{RenderField('intermediaryMargin')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('bankRate')}</Grid>
                                <Grid item>{RenderField('billedToBank')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('clientFee')}</Grid>
                                <Grid item>{RenderField('adminFee')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.tradeDetailFormSection2}>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('number')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('portfolioManager')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('interbankRate')}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={itemLg} md={itemMd} sm={itemSm} xs={itemXs}>
                            <Grid container direction={'column'}>
                                <Grid item>{RenderField('capturedSpotRate')}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    };

    const tradeValidation = (trade: Trade) => {
        const trades = [];
        switch (true) {
            case trade?.matched !== true:
                trades.push(<div className={classes.validationUnmatchedWrapper}>Unmatched</div>);
                break;
            case trade?.matched === true && trade?.validated === true:
                trades.push(<div className={classes.validationVerifiedWrapper}>Validated</div>);
                break;
            case trade?.matched === true && trade?.validated !== true:
                trades.push(<div className={classes.validationErrorWrapper}>Error</div>);
                break;
            default:
        }
        trades.push(<div />);
        return trades;
    };

    const renderControlIcons = () => {
        switch (activeState) {
            case State.nop:
                if (!readOnly) {
                    return (
                        <React.Fragment>
                            <Tooltip title="Validation" placement={'top'}>
                                <div
                                    style={{
                                        paddingTop: '12px',
                                    }}
                                >
                                    {validationIcon}
                                </div>
                            </Tooltip>
                            <Tooltip title="History">
                                <IconButton onClick={() => setActiveState(Event.showHistory)}>
                                    <HistoryIcon />
                                </IconButton>
                            </Tooltip>
                            {appContext.currentContext?.partyType === PartyType.CLIENT && (
                                <Tooltip title="Edit">
                                    <IconButton onClick={() => handleEnterEditMode()}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </React.Fragment>
                    );
                }
                return;

            case State.editingExisting:
                return (
                    <React.Fragment>
                        <Tooltip title="Save Changes">
                            <IconButton onClick={handleSaveChanges} disabled={marginNotesNeeded}>
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                            <IconButton onClick={handleCancelEditExisting}>
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    </React.Fragment>
                );

            default:
        }
    };

    const renderLayout = (): ReactElement => {
        return (
            <div className={classes.detailLayout}>
                <div className={classes.fecBreakdownDetailLayout}>
                    <div
                        className={cx(
                            css({
                                backgroundColor: theme.palette.background.paper,
                            }),
                            classes.tradeDetailTitleLayout,
                        )}
                    >
                        <div className={classes.tradeDetailTitle}>Linked Entities</div>
                    </div>
                    <div>
                        <div className={classes.tradeDetailSectionLabel}>Parent Trades</div>
                        <div className={classes.tradeDetailFormSection}>
                            {EntityTable({
                                classes: classes,
                                entities: (linkedParentTrades as unknown) as Array<
                                    Record<string, string | number | Date>
                                >,
                                currentEntityID: trade?.id || '',
                                noLinkedText: 'No Linked Parents Trades',
                                onClick: (lt) => setSelectedLinkedTrade(lt as Trade),
                                cols: [
                                    {
                                        accessor: 'externalReference',
                                        signAccessor: '',
                                        header: 'External Reference',
                                    },
                                    {
                                        accessor: 'availableBalance',
                                        signAccessor: '',
                                        header: 'Available Balance',
                                        customFormatFunc: (val) => {
                                            return displayAmount(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'effectiveRate',
                                        signAccessor: '',
                                        header: 'Effective Rate',
                                        customFormatFunc: (val) => {
                                            return displayRateSixDecimalPlaces(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'dealRate',
                                        signAccessor: '',
                                        header: 'Deal\xa0 Rate',
                                        customFormatFunc: (val) => {
                                            return displayRateSixDecimalPlaces(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'linkAmount',
                                        signAccessor: '',
                                        header: 'Link Amount',
                                        customFormatFunc: (val) => {
                                            return displayAmount(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'maturityDate',
                                        signAccessor: '',
                                        header: 'Maturity Date',
                                        customFormatFunc: (val) => {
                                            return displayDate(val as Date);
                                        },
                                    },
                                ],
                            })}
                        </div>
                        <div className={classes.tradeDetailSectionLabel}>{`All FEC's in ${capitalizeFirstLetter(
                            trade?.type?.toLocaleLowerCase() || '',
                        )}`}</div>
                        <div className={classes.tradeDetailFormSection}>
                            {EntityTable({
                                classes: classes,
                                currentEntityID: trade?.id || '',
                                entities: (otherTransactionTrades as unknown) as Array<
                                    Record<keyof Trade, string | number | Date>
                                >,
                                noLinkedText: 'No Trades in Transaction',
                                onClick: (lt) => setSelectedLinkedTrade(lt as Trade),
                                cols: [
                                    {
                                        accessor: 'externalReference',
                                        signAccessor: '',
                                        header: 'External Reference',
                                    },
                                    {
                                        accessor: 'availableBalance',
                                        signAccessor: '',
                                        header: 'Available Balance',
                                        customFormatFunc: (val) => {
                                            return displayAmount(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'effectiveRate',
                                        signAccessor: '',
                                        header: 'Effective Rate',
                                        customFormatFunc: (val) => {
                                            return displayRateSixDecimalPlaces(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'dealRate',
                                        signAccessor: '',
                                        header: 'Deal Rate',
                                        customFormatFunc: (val) => {
                                            return displayRateSixDecimalPlaces(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'maturityDate',
                                        signAccessor: '',
                                        header: 'Maturity Date',
                                        customFormatFunc: (val) => {
                                            return displayDate(val as Date);
                                        },
                                    },
                                ],
                            })}
                        </div>
                        <div className={classes.tradeDetailSectionLabel}>{`Child FEC's`}</div>
                        <div className={classes.tradeDetailFormSection}>
                            {EntityTable({
                                classes: classes,
                                currentEntityID: trade?.id || '',
                                entities: (linkedChildTrades as unknown) as Array<
                                    Record<string, string | number | Date>
                                >,
                                noLinkedText: 'No Linked Child Trades',
                                onClick: (lt) => setSelectedLinkedTrade(lt as Trade),
                                cols: [
                                    {
                                        accessor: 'externalReference',
                                        signAccessor: '',
                                        header: 'External Reference',
                                    },
                                    {
                                        accessor: 'availableBalance',
                                        signAccessor: '',
                                        header: 'Available Balance',
                                        customFormatFunc: (val) => {
                                            return displayAmount(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'effectiveRate',
                                        signAccessor: '',
                                        header: 'Effective Rate',
                                        customFormatFunc: (val) => {
                                            return displayRateSixDecimalPlaces(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'dealRate',
                                        signAccessor: '',
                                        header: 'Deal\xa0 Rate',
                                        customFormatFunc: (val) => {
                                            return displayRateSixDecimalPlaces(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'linkAmount',
                                        signAccessor: '',
                                        header: 'Link Amount',
                                        customFormatFunc: (val) => {
                                            return displayAmount(val as number);
                                        },
                                    },
                                    {
                                        accessor: 'maturityDate',
                                        signAccessor: '',
                                        header: 'Maturity Date',
                                        customFormatFunc: (val) => {
                                            return displayDate(val as Date);
                                        },
                                    },
                                ],
                            })}
                        </div>
                        <div className={classes.tradeDetailSectionLabel}>Linked Invoices</div>
                        <div className={classes.tradeDetailFormSection}>
                            {EntityTable({
                                classes: classes,
                                entities: (linkedInvoices as unknown) as Array<Record<string, string | number | Date>>,
                                currentEntityID: trade?.id || '',
                                noLinkedText: 'No Linked Invoices',
                                onClick: (li) => setSelectedInvoice(li as Invoice),
                                cols: [
                                    {
                                        accessor: 'externalReference',
                                        signAccessor: '',
                                        header: 'External Reference',
                                    },
                                    {
                                        accessor: 'linkAmount',
                                        signAccessor: 'type',
                                        header: 'Link Amount',
                                        customFormatFunc: (val) => {
                                            return parseFloat(val.toString()).toFixed(2);
                                        },
                                    },
                                ],
                            })}
                        </div>
                    </div>
                </div>
                <div className={classes.tradeDetailLayout}>
                    <div
                        className={cx(
                            css({
                                backgroundColor: theme.palette.background.paper,
                            }),
                            classes.tradeDetailTitleLayout2,
                        )}
                    >
                        <div className={classes.tradeDetailTitle}>FEC Details</div>
                        {!disallowEditing && (
                            <div className={classes.tradeDetailTitleActions}>{renderControlIcons()}</div>
                        )}
                    </div>
                    <div className={classes.tradeDetailFormLayout} style={{ height: '100%' }}>
                        {renderTradeDetailsLayout()}
                    </div>
                </div>
            </div>
        );
    };

    const RenderField = (
        field: keyof Trade,
        altTooltip?: string,
        variant?: 'white' | 'red' | 'accent' | 'importExport' | 'importExportWhite',
    ): ReactElement => {
        if (!trade) {
            return <div />;
        }

        const linkedEntities =
            linkedInvoices.length > 0 ||
            linkedChildTrades.length > 0 ||
            otherTransactionTrades.length > 1 || // take this trade into account
            linkedParentTrades.length > 0;

        const tradeValidated = originalTrade.matched === true && originalTrade.validated === true;
        const canEditTrades = IsFieldEditable(field, linkedEntities, tradeValidated, transactionFeesFilled);

        let tooltip = '';
        if (!canEditTrades) {
            tooltip = 'Cannot edit';
        }

        const fieldsToRoundToSix: Array<keyof Trade> = [
            'dealRate',
            'forwardPoints',
            'spotPrice',
            'effectiveRate',
            'interbankRate',
            'capturedSpotRate',
            'bankRate',
        ];
        const fieldsToRoundToFour: Array<keyof Trade> = ['intermediaryMargin'];
        const fieldsToRoundToTwo: Array<keyof Trade> = [
            'realisedPnl',
            'availableBalance',
            'acmBalance',
            'quoteAmount',
            'notionalAmount',
            'netCashFlow',
            'adminFee',
            'clientFee',
            'billedToBank',
        ];
        const dateFields: Array<keyof Trade> = ['captureDate', 'maturityDate', 'tradeDate'];
        let value = getKeyValue<Trade, keyof Trade>(editedTrade, field);
        const isNumeric = typeof editedTrade[field] === 'number';

        const setTradeType = async (t: Trade) => {
            if (t.type === TradeType.FORWARD || t.type === TradeType.SPOT) {
                try {
                    const sd = await getSpotDateFor({
                        currencyPair: t.currencyPair,
                        date: getUnixTime(parseISO(t.tradeDate?.toString() || '')),
                    });
                    if (t.type && t.maturityDate && fromUnixTime(sd.spotDate)) {
                        if (
                            isBefore(
                                parseISO(t.maturityDate?.toString() || ''),
                                parseISO(
                                    moment
                                        .unix(sd.spotDate || 0)
                                        .endOf('day')
                                        .toISOString(),
                                ),
                            )
                        ) {
                            t.type = TradeType.SPOT;
                        } else {
                            t.type = TradeType.FORWARD;
                        }
                    }
                    setEditedTrade(t);
                } catch (e) {
                    console.error('error finding spot trade', e);
                    setActiveState(Event.errorEvent);
                }
            } else {
                setEditedTrade(t);
            }
        };
        const setCalculatedFields = (t: Trade, field: string): Trade => {
            if (field === 'direction') {
                if (t.direction == TradeDirection.BUY) {
                    t.importExport = ImportExport.IMPORT;
                } else if (t.direction == TradeDirection.SELL) {
                    t.importExport = ImportExport.EXPORT;
                }
            }
            if (field === 'notionalAmount' || 'quoteAmount') {
                if (t.notionalAmount && t.quoteAmount) {
                    const notionalAmount = Number(t.notionalAmount.toFixed(2)) || 0.0;
                    const quoteAmount = Number(t.quoteAmount.toFixed(2)) || 0.0;
                    t.dealRate = Number((quoteAmount / notionalAmount).toFixed(6) || 0.0);
                }
            }
            if (field === 'dealRate' || 'spotPrice') {
                if (t.spotPrice && t.dealRate) {
                    t.forwardPoints = t.dealRate - t.spotPrice;
                }
            }
            if (field === 'billingType' || field === 'intermediaryMargin') {
                if (t.intermediaryMargin !== 0) {
                    calculateDefaultRevenue(t);
                } else {
                    calculateDefaultRevenue.cancel();
                    t.billedToBank = 0;
                    t.clientFee = 0;
                    t.bankRate = 0;
                    t.billingType = undefined;
                }
            }
            return t;
        };
        const checkIfMarginNotesNeeded = (t: Trade, field: string) => {
            if (
                field === 'billingType' ||
                field === 'intermediaryMargin' ||
                field === 'adminFee' ||
                field === 'marginNotes'
            ) {
                setMarginNotesNeeded(() => {
                    return (
                        t.marginNotes == '' &&
                        (t.intermediaryMargin !== defaultRevenue.intermediaryMargin ||
                            t.billingType !== defaultRevenue.billingType ||
                            t.adminFee !== defaultRevenue.adminFee)
                    );
                });
            }
        };
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const handleChange = (value: any) => {
            let newTrade = objectCopy(editedTrade);
            if (isNumeric) {
                try {
                    newTrade[field] = value ? Big(value || 0).toNumber() : 0;
                } catch (e) {
                    return;
                }
            } else {
                newTrade[field] = value;
            }
            checkIfMarginNotesNeeded(newTrade, field);
            newTrade = setCalculatedFields(newTrade, field);
            setEditedTrade(newTrade);
        };

        switch (typeof value) {
            case 'number':
                if (fieldsToRoundToTwo.includes(field)) {
                    value = value?.toFixed(2);
                    break;
                }
                if (fieldsToRoundToFour.includes(field)) {
                    value = value?.toFixed(4);
                    break;
                }
                if (fieldsToRoundToSix.includes(field)) {
                    value = value?.toFixed(6);
                    break;
                }
                break;
            case 'string':
                if (dateFields.includes(field)) {
                    value = displayDate(value);
                    break;
                }
                break;
            case 'boolean':
                if (value) {
                    value = 'TRUE';
                } else {
                    value = 'FALSE';
                }
                break;
            default:
                value = '-';
                break;
        }

        const selectFields: Array<keyof Trade> = [
            'currencyPair',
            'bank',
            'direction',
            'financialYear',
            'billingType',
            'season',
            'product',
            'trader',
            'status',
            'traderOrganisation',
        ];
        const dateField: Array<keyof Trade> = ['tradeDate', 'maturityDate'];
        const selectOptions: Record<string, Array<{ value: string; label: string }>> = {
            currencyPair: appContext.currencyPairs
                ? appContext.currencyPairs.map((ccyPair) => ({
                      value: ccyPair.name,
                      label: ccyPair.name,
                  }))
                : [],
            bank: processingBanks.map((pb) => ({
                value: pb.partyCode,
                label: pb.name,
            })),
            direction: TradeDirections,
            status: TradeStatuses,
            billingType: BillingTypes.map((bt) => ({
                value: bt,
                label: bt,
            })),
            financialYear: financialYears.map((fy) => ({
                value: fy,
                label: fy,
            })),
            season: seasons.map((s) => ({
                value: s,
                label: s,
            })),
            product: ((appContext.party as Client)?.products || []).map((p) => ({
                value: p,
                label: p,
            })),
            trader: (parentParty?.traders || []).map((t) => ({
                value: t,
                label: t,
            })),
            traderOrganisation: (() => {
                const options = [
                    {
                        value: party?.partyCode || '-',
                        label: party?.name || '-',
                    },
                ];
                if (parentParty) {
                    options.push({
                        value: parentParty?.partyCode || '-',
                        label: parentParty?.name || '-',
                    });
                }
                if (trade.traderOrganisation == 'SYS') {
                    options.push({
                        value: 'SYS',
                        label: 'System',
                    });
                }
                return options;
            })(),
        };

        const inputLabelProps = {
            classes: {
                focused: classes.focusedInputLabel,
                root: classes.inputLabel,
            },
            shrink: true,
            style: (() => {
                switch (variant) {
                    case 'importExport':
                    case 'accent':
                        return { color: theme.palette.text.disabled };
                    case 'importExportWhite':
                        return { color: theme.palette.text.secondary };
                    case 'red':
                        if (marginNotesNeeded && activeState === State.editingExisting) {
                            return { color: theme.palette.error.main };
                        }
                        return { color: theme.palette.text.secondary };
                    default:
                        return undefined;
                }
            })(),
        };

        const fieldSelectOptions = selectOptions[field];

        if (canEditTrades && activeState === State.editingExisting) {
            switch (true) {
                case selectFields.includes(field):
                    return (
                        <FormControl aria-describedby={field} className={classes.formField}>
                            <InputLabel
                                classes={{
                                    focused: classes.focusedInputLabel,
                                    root: classes.inputLabel,
                                }}
                                htmlFor={field}
                                style={{ width: 150, color: theme.palette.text.disabled }}
                            >
                                {fieldLabels[field]}
                            </InputLabel>
                            <Select
                                disableUnderline={activeState !== State.editingExisting}
                                id={field}
                                input={
                                    <Input
                                        classes={{
                                            underline: classes.line,
                                        }}
                                    />
                                }
                                inputProps={{
                                    readOnly: activeState !== State.editingExisting,
                                }}
                                onChange={(
                                    event: React.ChangeEvent<{
                                        name?: string | undefined;
                                        value: unknown;
                                    }>,
                                ) => {
                                    const value = event.target.value;
                                    if (!trade) {
                                        return;
                                    }
                                    let newTrade = objectCopy(editedTrade);
                                    newTrade[field] = value;
                                    checkIfMarginNotesNeeded(newTrade, field);
                                    newTrade = setCalculatedFields(newTrade, field);
                                    setEditedTrade(newTrade);
                                }}
                                style={{ width: 150 }}
                                value={value}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {(fieldSelectOptions || []).map((opt, idx) => {
                                    return (
                                        <MenuItem key={idx} value={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    );
                case dateField.includes(field):
                    return (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ paddingBottom: '12px' }}
                                autoOk
                                disableToolbar
                                maskChar={'_'}
                                variant={'inline'}
                                margin={'none'}
                                value={value}
                                placeholder={'yyyy-MM-dd'}
                                label={fieldLabels[field]}
                                initialFocusedDate={new Date()}
                                onChange={(date: MaterialUiPickersDate) => {
                                    const newTrade = objectCopy(editedTrade);
                                    const newDate = date as Date;
                                    if (!isWeekend(newDate) && !isHoliday(newDate, nonTradingDays)) {
                                        newTrade[field] = newDate.toISOString();
                                        setTradeType(newTrade).finally();
                                    }
                                }}
                                format={'yyyy-MM-dd'}
                                KeyboardButtonProps={{
                                    size: 'small',
                                }}
                                renderDay={(
                                    day: MaterialUiPickersDate,
                                    selectedDate: MaterialUiPickersDate,
                                    isInCurrentMonth: boolean,
                                    dayComponent: ReactElement,
                                ) => {
                                    const date = getMidDay(day as Date);
                                    if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                        return cloneElement(dayComponent, { disabled: true });
                                    }
                                    return dayComponent;
                                }}
                                TextFieldComponent={(params) => (
                                    <BaseTextField
                                        id={'TradeDetailDialog/legs.0.tradeDate'}
                                        {...params}
                                        margin={'dense'}
                                        variant={'standard'}
                                    />
                                )}
                                disabled={activeState !== State.editingExisting}
                            />
                        </MuiPickersUtilsProvider>
                    );
            }
        }

        // not editing
        if (isNumeric) {
            return (
                <Tooltip enterDelay={500} title={altTooltip || tooltip}>
                    <span>
                        <BaseNumberField
                            disabled={!canEditTrades || activeState !== State.editingExisting}
                            classes={{
                                root: cx(
                                    {
                                        [classes.whiteTextField]: variant === 'white',
                                        [classes.accentTextField]: variant === 'accent',
                                    },
                                    classes.fullWidth,
                                ),
                            }}
                            className={classes.formField}
                            id={field}
                            label={fieldLabels[field]}
                            value={(value as unknown) as number}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleChange(event.target.value);
                            }}
                        />
                    </span>
                </Tooltip>
            );
        }

        const importExportVariant = variant == 'importExport' || variant === 'importExportWhite';
        const isImport = value === 'BUY' || value == 'Import';
        const isExport = value === 'SELL' || value == 'Export';

        return (
            <Tooltip enterDelay={500} title={altTooltip || tooltip}>
                <span>
                    <BaseTextField
                        classes={{
                            root: cx(
                                {
                                    [classes.whiteTextField]: variant === 'white',
                                    [classes.accentTextField]: variant === 'accent',
                                    [classes.importTextField]: importExportVariant && isImport,
                                    [classes.exportTextField]: importExportVariant && isExport,
                                },
                                classes.fullWidth,
                            ),
                        }}
                        InputLabelProps={inputLabelProps}
                        disabled={!canEditTrades || activeState !== State.editingExisting}
                        className={classes.formField}
                        id={field}
                        label={fieldLabels[field]}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event.target.value);
                        }}
                        value={value}
                    />
                </span>
            </Tooltip>
        );
    };

    return (
        <Dialog classes={{ paper: classes.dialogPaper }} fullScreen open={open}>
            {selectedInvoice && (
                <InvoiceDetailDialogV2
                    counterparties={[]}
                    invoice={selectedInvoice}
                    onClose={() => setSelectedInvoice(undefined)}
                    readOnly
                    show
                />
            )}
            {selectedLinkedTrade && (
                <TradeDetailDialog
                    closeDialog={closeDialog}
                    open={selectedLinkedTrade as boolean}
                    trade={selectedLinkedTrade}
                    amendSuccess={amendSuccess}
                />
            )}
            <BaseAppBar title={'Trade summary'} onClose={() => closeDialog()} showCloseButton={closePageVisible} />
            <DialogContent classes={{ root: classes.rootOverride }}>
                {((): ReactElement => {
                    switch (activeState) {
                        case State.nop:
                        case State.editingExisting:
                        case State.loading:
                            return renderLayout();
                        case State.error:
                            return RenderError(errorMessage);
                        default:
                            return <div>Error</div>;
                    }
                })()}
            </DialogContent>
            <Dialog
                BackdropProps={{ classes: { root: classes.progressSpinnerDialogBackdrop } }}
                PaperProps={{ classes: { root: classes.progressSpinnerDialog } }}
                className={classes.progressSpinnerDialog}
                open={activeState === State.loading}
            >
                <Spinner loading={activeState === State.loading} />
            </Dialog>
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                onConfirm={confirmationMethod}
                successMessage={successMessage}
                warningMessage={warningMessage}
            />
            <History
                onHide={() => setActiveState(Event.exitHistory)}
                open={activeState === State.showingHistory}
                trade={trade}
            />
        </Dialog>
    );
};
export default TradeDetailDialog;

const RenderError = (message?: string): ReactElement => {
    return (
        <div>
            <div>
                <Typography color={'error'}>
                    <b>Error</b>
                </Typography>
            </div>
            <div>
                <ErrorIcon color={'error'} />
            </div>
            <div>
                <Typography color={'error'}>{message}</Typography>
            </div>
        </div>
    );
};

const getKeyValue = function <T, U extends keyof T>(obj: T, key: U) {
    return obj[key];
};

type EntityTableProps<T> = {
    entities: Array<T>;
    noLinkedText: string;
    cols: Array<{
        accessor: string;
        signAccessor: string;
        header: string;
        customFormatFunc?: (val: string | number | Date) => string | number;
    }>;
    onClick?: (entity: T) => void;
    currentEntityID: string;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    classes: any;
};
const EntityTable = ({
    entities,
    cols,
    noLinkedText,
    onClick,
    currentEntityID,
    classes,
}: EntityTableProps<Record<string, string | number | Date>>): ReactElement => {
    if (entities.length === 0) {
        return <div className={classes.noEntriesText}>{noLinkedText}</div>;
    }

    return (
        <MUITable size="small">
            <TableBody>
                <TableRow>
                    {cols.map((col) => {
                        return (
                            <TableCell classes={{ root: cx(classes.cellBorder, classes.fontBold) }} key={col.accessor}>
                                {col.header}
                            </TableCell>
                        );
                    })}
                    <TableCell classes={{ root: classes.cellBorder }} />
                </TableRow>
                {(entities || []).map((entry, idx) => {
                    const isCurrent = currentEntityID === entry.id;
                    return (
                        <TableRow
                            style={{
                                opacity: isCurrent ? 1 : 0.6,
                            }}
                            key={idx}
                        >
                            {cols.map((col) => {
                                let val = col.accessor in entry && entry[col.accessor];
                                val = col.customFormatFunc ? col.customFormatFunc(val as string | number | Date) : val;
                                if (
                                    'SALES INVOICE' === entry[col.signAccessor] ||
                                    'PURCHASE INVOICE' === entry[col.signAccessor]
                                ) {
                                    val = col.customFormatFunc
                                        ? col.customFormatFunc(val as string | number | Date)
                                        : val;
                                } else if (
                                    'SALES CREDIT NOTE' === entry[col.signAccessor] ||
                                    'PURCHASE CREDIT NOTE' === entry[col.signAccessor]
                                ) {
                                    if (isNumber(entry[col.accessor])) {
                                        val = Number(entry[col.accessor]) * -1;
                                    }
                                }
                                return (
                                    <TableCell
                                        classes={{ root: cx({ [classes.accentColor]: isCurrent }, classes.cellBorder) }}
                                        key={col.accessor}
                                    >
                                        {val.toString()}
                                    </TableCell>
                                );
                            })}
                            {!isCurrent ? (
                                <TableCell classes={{ root: cx(classes.cellBorder, classes.iconPadding) }}>
                                    <Tooltip title={'View Details'}>
                                        <IconButton
                                            key={'view'}
                                            onClick={() => {
                                                onClick && onClick(entry);
                                            }}
                                            style={{
                                                padding: '0px',
                                            }}
                                        >
                                            <RemoveRedEye fontSize={'small'} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            ) : (
                                <TableCell classes={{ root: cx(classes.cellBorder, classes.iconPadding) }} />
                            )}
                        </TableRow>
                    );
                })}
            </TableBody>
        </MUITable>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    fullWidth: {
        width: '100%',
    },
    iconPadding: {
        paddingLeft: '0px',
    },
    fontBold: {
        fontWeight: 'bold',
    },
    cellBorder: {
        borderBottom: `1px solid ${HexToRGBA(theme.palette.text.primary, 0.6)}`,
        padding: theme.spacing(1),
    },
    accentColor: {
        color: theme.palette.primary.main,
    },
    noEntriesText: {
        fontSize: '14px',
        color: theme.palette.text.disabled,
        padding: theme.spacing(2),
    },
    whiteTextField: {
        '& .Mui-disabled': {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    redTextField: {
        '& .Mui-disabled': {
            color: theme.palette.error.main,
            opacity: 1,
        },
    },
    accentTextField: {
        '& .Mui-disabled': {
            color: theme.palette.primary.main,
            opacity: 1,
        },
    },
    importTextField: {
        '& .Mui-disabled': {
            color: theme.palette.custom.import.main,
            opacity: 1,
        },
    },
    exportTextField: {
        '& .Mui-disabled': {
            color: theme.palette.custom.export.main,
            opacity: 1,
        },
    },
    formField: {
        height: '60px',
    },
    dialogPaper: {
        backgroundColor: theme.palette.background.default,
        overflow: 'hidden',
    },
    progressSpinnerDialog: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'hidden',
    },
    progressSpinnerDialogBackdrop: {
        backgroundColor: 'transparent',
    },
    rootOverride: {
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr',
        overflow: 'hidden',
    },
    errorDisplay: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        alignItems: 'center',
        justifyItems: 'center',
        margin: '15px',
    },
    errorIcon: {
        fontSize: 80,
    },
    detailLayout: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        paddingTop: theme.spacing(),
        gridColumnGap: '8px',
        overflowY: 'auto',
        height: 'calc(100vh - 100px)',
    },
    tradeDetailLayout: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.75)',
        display: 'grid',
        gridTemplateRows: '48px 1fr',
    },
    fecBreakdownDetailLayout: {
        background: theme.palette.custom.paperExtended.paper5,
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.75)',
        display: 'grid',
        gridTemplateRows: '48px 1fr',
        overflowX: 'hidden',
        minWidth: '620px',
        padding: '0px',
    },
    tradeDetailTitleLayout: {
        paddingLeft: '13px',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
    },
    tradeDetailTitleLayout2: {
        paddingLeft: '16px',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
    },
    tradeDetailTitle: {
        fontSize: '17px',
        lineHeight: '17px',
        fontWeight: 'bold',
    },
    tradeDetailTitleActions: {
        justifySelf: 'end',
        display: 'flex',
        flexDirection: 'row',
    },
    tradeDetailFormLayout: {
        overflowX: 'hidden',
        background: theme.palette.custom.paperExtended.paper5,
    },
    tradeDetailFormSection2: {
        padding: theme.spacing(),
        paddingLeft: '16px',
        paddingTop: '16px',
        background: theme.palette.custom.paperExtended.paper5,
    },
    tradeDetailFormSection: {
        background: theme.palette.custom.paperExtended.paper5,
        paddingBottom: theme.spacing(2),
    },
    tradeDetailSectionLabel: {
        height: '39px',
        backgroundColor: `${HexToRGBA(theme.palette.custom.paperExtended.paper2, 1)}`,
        display: 'grid',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        fontSize: '14px',
        lineHeight: '14px',
        fontWeight: 500,
    },
    tradeDetailSectionLabel2: {
        backgroundColor: `${HexToRGBA(theme.palette.custom.paperExtended.paper2, 1)}`,
        padding: theme.spacing(),
        paddingLeft: '16px',
        paddingTop: '16px',
    },
    inputLabel: {
        color: theme.palette.text.disabled,
    },
    focusedInputLabel: {
        color: theme.palette.primary.light + '!important',
    },
    line: {
        '&:before': {
            borderBottom: `1px solid  ${HexToRGBA(theme.palette.text.primary, '0.13')}`,
        },
        '&:after': {
            borderBottom: `2px solid  ${theme.palette.primary.light}`,
        },
    },
    numberList: {
        width: '400px',
        height: '10px',
    },
    validationVerifiedWrapper: {
        display: 'flex',
        height: '24px',
        width: 'relative',
        backgroundColor: theme.palette.success.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    validationUnmatchedWrapper: {
        display: 'flex',
        height: '24px',
        width: 'relative',
        backgroundColor: theme.palette.warning.light,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    validationErrorWrapper: {
        display: 'flex',
        height: '24px',
        width: 'relative',
        backgroundColor: theme.palette.error.main,
        borderRadius: '12px',
        fontSize: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.default,
        columnGap: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
}));

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
