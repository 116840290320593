import FileSaver from 'file-saver';

export const exportReport = (response: unknown, documentName: string): void => {
    // convert base64 to byte array
    const binData = atob((response as { excelData: string }).excelData);
    const bytes = new Array(binData.length);
    for (let i = 0; i < binData.length; i++) {
        bytes[i] = binData.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(bytes)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
    });
    FileSaver.saveAs(blob, `${documentName}.xlsx`);
};
