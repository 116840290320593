import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { FindRequest } from 'popcorn-js';
import { Criteria } from 'popcorn-js/search';
import { marshalCriteria } from 'popcorn-js/search/marshaller';

export type DownloadRequest = FindRequest;
export type DownloadResponse = {
    data: string;
};

export const Downloader = {
    ServiceProviderName: 'Invoice-Exporter',
    downloadInvoices(request: DownloadRequest): Promise<DownloadResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<DownloadRequest, DownloadResponse>({
            url: config.get('apiURL'),
            method: `${Downloader.ServiceProviderName}.Export`,
            request: { ...request, criteria: serializedCriteria },
        });
    },
};
