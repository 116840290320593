import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Option, OptionDirection, OptionType } from 'popcorn-js/options';
import { useServiceSync } from 'hooks/useService';
import { financialYears, FindRequest, FindResponse } from 'popcorn-js';
import { Handler } from 'popcorn-js/options/handler';
import { Criteria, CriteriaType, Criterion, Query, QueryOrderT } from 'popcorn-js/search';
import OptionDetailDialog from 'components/Option/OptionDetailDialog';
import Table from 'components/Table/Table';
import RecordOptionTicket from 'components/Option/Tickets/RecordOptionTicket';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { useStyletron } from 'styletron-react';
import { useTheme } from '@material-ui/core/styles';
import { CustomTheme } from 'theme/custom';
import { ImportExport, TradeDirection, TradeStatus } from 'popcorn-js/tradeV2';
import { displayAmount, displayDate, displayRateSixDecimalPlaces } from 'utils';
import { AppContext, AppContextT } from 'context';
import { SwitchContextLink } from './SwitchContextLink';
import { Counterparty } from 'popcorn-js/counterparty';
import { Client, ProcessingOrg } from 'popcorn-js/party';
import { Handler as ClientHandler } from 'popcorn-js/party/client/handler';
import { Recordkeeper as ProcessingOrgRecordkeeper } from 'popcorn-js/party/processingOrg/recordkeeper';

const importExportOptions = [{ value: ImportExport.IMPORT }, { value: ImportExport.EXPORT }];

const tradeStatuses = [
    {
        value: TradeStatus.OPEN,
        label: TradeStatus.OPEN,
    },
    {
        value: TradeStatus.EXPIRED,
        label: TradeStatus.EXPIRED,
    },
    {
        value: TradeStatus.EXERCISED,
        label: TradeStatus.EXERCISED,
    },
];

const tradeTypesForColumn = [{ value: OptionType.CALL }, { value: OptionType.PUT }];

const tradeDirections = [
    {
        value: TradeDirection.BUY,
        label: TradeDirection.BUY,
    },
    {
        value: TradeDirection.SELL,
        label: TradeDirection.SELL,
    },
];

export const OptionsFECTableTab = (props: {
    handleCloseColConfig?: () => void;
    handleOptionsFilterChange?: (criteria: Criteria) => void;
    colConfigOpen?: boolean;
    showFilterRow?: boolean;
    showNewOptionsTicket?: boolean;
    newOptionType?: OptionType;
    newOptionCurrencyPair?: CurrencyPair;
    onCloseNewOptionsTicket?: () => void;
    selected?: Option;
    criteria?: Criterion[];
    setSelected?: (options: Option | undefined) => void;
    detailDialogOpen?: boolean;
    setDetailDialogOpen?: (open: boolean) => void;
    reloadTriggerState?: boolean;
    parentParty?: string;
}): ReactElement => {
    const {
        showFilterRow,
        handleCloseColConfig,
        colConfigOpen,
        onCloseNewOptionsTicket,
        showNewOptionsTicket,
        newOptionType,
        newOptionCurrencyPair,
        selected,
        criteria,
        setSelected,
        detailDialogOpen,
        setDetailDialogOpen,
        reloadTriggerState,
        handleOptionsFilterChange,
        parentParty,
    } = props;
    const appContext = useContext<AppContextT>(AppContext);
    const currencyPairs = appContext.currencyPairs || [];
    // notificationss
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [warningMessage, setWarningMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [confirmationMethod, setConfirmationMethod] = useState<undefined | (() => void)>(undefined);
    // state
    const [loading, setLoading] = useState<boolean>(false);

    const [optionsTotal, setOptionsTotal] = useState<number>(0);
    const [optionsResponse, setOptionsResponse] = useState<Option[] | undefined>();

    useEffect(() => {
        const newQuery: Query = {
            ...query,
            offset: 0,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    }, [reloadTriggerState, criteria]);

    const [query, setQuery] = useState<Query>({
        sortBy: ['expiryDate'],
        order: ['asc'],
        limit: 50,
        offset: 0,
    });

    // services
    const [findOptions] = useServiceSync<FindRequest, FindResponse<Option>>(Handler.Find);
    const [findClient] = useServiceSync<FindRequest, FindResponse<Client>>(ClientHandler.find);
    const [findProcessingOrg] = useServiceSync<FindRequest, FindResponse<ProcessingOrg>>(
        ProcessingOrgRecordkeeper.find,
    );

    const findOptionsProcessingOrgs = async (_options: Option[]): Promise<ProcessingOrg[]> => {
        const proOrgFindCrit: Criteria = _options
            .filter((o) => o.processingOrgPartyCode != '')
            .map((o) => ({
                type: CriteriaType.ExactCriterion,
                field: 'partyCode',
                text: o.processingOrgPartyCode,
            }));
        const response = await findProcessingOrg({
            criteria: proOrgFindCrit,
        });
        return response.records;
    };

    const findOptionsTradingParties = async (_options: Option[]): Promise<Client[]> => {
        const tradingpartyFindCrit: Criteria = _options
            .filter((o) => o.tradingPartyCode !== '')
            .map((o) => ({ type: CriteriaType.ExactCriterion, field: 'partyCode', text: o.tradingPartyCode }));
        const response = await findClient({
            criteria: tradingpartyFindCrit,
        });
        return response.records;
    };

    const findEntities = async (_criteria?: Criteria, _query?: Query) => {
        setLoading(true);
        try {
            const result = await findOptions({
                criteria: _criteria || criteria,
                query: _query || query,
            });
            const clients = await findOptionsTradingParties(result.records);
            const proOrgs = await findOptionsProcessingOrgs(result.records);
            setOptionsResponse(
                result.records.map((i: Option) => ({
                    ...i,
                    tradingPartyName: clients.find((c: Client) => c.partyCode === i.tradingPartyCode)?.name,
                    processingOrgName: proOrgs.find((c: ProcessingOrg) => c.partyCode === i.processingOrgPartyCode)
                        ?.name,
                })),
            );
            setOptionsTotal(result.total);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const StyledMaturingValue = (props: { t: Option }) => {
        const maturity = displayDate(props.t.expiryDate?.toString()).slice(0, 10);

        const getDay = new Date();
        const today = getDay.toISOString().slice(0, 10);

        const tomorrow = new Date(getDay);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const nextD = tomorrow.toISOString().slice(0, 10);

        const inTwoDays = new Date(getDay);
        inTwoDays.setDate(inTwoDays.getDate() + 2);
        const twoDaysAfter = inTwoDays.toISOString().slice(0, 10);

        const [css] = useStyletron();
        const theme = useTheme<CustomTheme>();
        let color = 'inherit';
        if (maturity === today) {
            color = theme.palette.custom.data.overdue.dark;
        }
        if (maturity === nextD || maturity === twoDaysAfter) {
            color = theme.palette.custom.stellcapBrand1.light;
        }
        return (
            <div>
                <span className={css({ color })}>{displayDate(props.t.expiryDate?.toString())}</span>
            </div>
        );
    };

    useEffect(() => {
        findEntities().finally();
    }, []);

    const handleCloseDialog = async () => {
        setDetailDialogOpen && setDetailDialogOpen(false);
        setSelected && setSelected(undefined);
        await findEntities(criteria, query);
    };

    const closeTicket = async () => {
        onCloseNewOptionsTicket && onCloseNewOptionsTicket();
        setSelected && setSelected(undefined);
        await findEntities(criteria, query);
    };

    const generateProcessingOrgOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findProcessingOrg({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const generateClientOptions = async (text?: string): Promise<Counterparty[]> => {
        try {
            const criteria: Criteria = text ? [{ type: CriteriaType.TextCriterion, field: 'name', text }] : [];
            const response = await findClient({
                criteria,
                query: { sortBy: ['name'], order: ['asc'], offset: 0 },
            });
            return response.records;
        } catch (e) {
            setErrorMessage(e.message || e);
            return [];
        }
    };

    const renderTickets = (): React.ReactElement => {
        switch (true) {
            case showNewOptionsTicket:
                return (
                    <RecordOptionTicket
                        closeTicket={closeTicket}
                        show={!!showNewOptionsTicket}
                        currencyPairToTrade={newOptionCurrencyPair}
                        initialDirection={OptionDirection.BUY}
                        initialType={newOptionType || OptionType.PUT}
                    />
                );
            default:
                return <span />;
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        const offset = query.limit ? query.limit * newPage : 0;
        const newQuery = {
            ...query,
            offset,
        };
        setQuery(newQuery);
        findEntities(criteria, newQuery).finally();
    };

    const handleChangeSorting = async (sortBy: string, order: QueryOrderT) => {
        const newQuery: Query = {
            ...query,
            sortBy: [sortBy],
            order: [order],
        };
        setQuery(newQuery);
        await findEntities(criteria, newQuery);
    };

    const handleSelectRow = (rowData: Option) => {
        const newSelected = { ...rowData };
        if (newSelected.id === selected?.id) {
            setSelected && setSelected(undefined);
        } else {
            setSelected && setSelected(newSelected);
        }
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setWarningMessage(undefined);
        setConfirmationMethod(undefined);
    };
    const amendSuccess = async () => {
        if (!selected) {
            return;
        }
        const seletectedTradeId = selected.id;
        await findEntities(criteria, query);
        const selectedTrades = optionsResponse?.filter((trd) => trd.id === seletectedTradeId);
        const selectedTrade = selectedTrades?.length === 1 ? selectedTrades[0] : undefined;
        if (!selectedTrade) {
            handleCloseDialog();
            return;
        }
        setSelected && setSelected(selectedTrade);
    };

    return (
        <div id="fecManagementStationRoot">
            {detailDialogOpen && selected && (
                <OptionDetailDialog
                    closeDialog={handleCloseDialog}
                    open={detailDialogOpen}
                    option={selected ? selected : undefined}
                    amendSuccess={amendSuccess}
                />
            )}
            {renderTickets()}
            <Table
                colConfigCloseFromCard={handleCloseColConfig}
                colConfigOpenFromCard={colConfigOpen}
                columns={[
                    {
                        title: 'Processing Org',
                        field: 'processingOrgPartyCode',
                        filter: {
                            asyncOptionsFetcher: generateProcessingOrgOptions,
                            displayAccessor: 'name',
                            valueAccessor: 'partyCode',
                            type: CriteriaType.TextCriterion,
                        },
                        render: (rowData: Option) => (
                            <SwitchContextLink
                                counterparty={rowData.processingOrgName || parentParty || ''}
                                partyCode={rowData.processingOrgPartyCode || ''}
                            />
                        ),
                    },
                    {
                        title: 'Client',
                        field: 'tradingPartyCode',
                        filter: {
                            asyncOptionsFetcher: generateClientOptions,
                            displayAccessor: 'name',
                            valueAccessor: 'partyCode',
                            type: CriteriaType.TextCriterion,
                        },
                        render: (rowData: Option) => (
                            <SwitchContextLink
                                counterparty={rowData.tradingPartyName || ''}
                                partyCode={rowData.tradingPartyCode || ''}
                            />
                        ),
                    },
                    {
                        title: 'Number',
                        field: 'number',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Option) => {
                            if (rowData.number) {
                                return rowData.number;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'External Ref',
                        field: 'externalReference',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Option) => {
                            if (rowData) {
                                return rowData.externalReference;
                            } else {
                                return '-';
                            }
                        },
                        width: '100px',
                    },
                    {
                        title: 'Transaction ID',
                        field: 'transactionID',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Option) => {
                            if (rowData.transactionID) {
                                return rowData.transactionID;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Capture Date',
                        field: 'captureDate',
                        filter: { type: CriteriaType.TimeCriterion },
                        render: (rowData: Option) => {
                            if (rowData.captureDate) {
                                return displayDate((rowData.captureDate as unknown) as string);
                            } else {
                                return '-';
                            }
                        },
                        width: '85px',
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        filter: {
                            options: tradeStatuses,
                            displayAccessor: 'value',
                            valueAccessor: 'value',
                            type: CriteriaType.TextCriterion,
                        },
                        render: (rowData: Option) => {
                            if (rowData.status) {
                                return rowData.status;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Call Amount',
                        field: 'callAmount.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.callAmount) {
                                return displayAmount(rowData.callAmount);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Put Amount',
                        field: 'putAmount.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.putAmount) {
                                return displayAmount(rowData.putAmount);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Notional Amount',
                        field: 'notionalAmount.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.notionalAmount) {
                                return displayAmount(rowData.notionalAmount);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Quote Amount',
                        field: 'quoteAmount.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.quoteAmount) {
                                return displayAmount(rowData.quoteAmount);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Currency',
                        field: 'currencyPair',
                        filter: {
                            options: currencyPairs,
                            displayAccessor: 'name',
                            valueAccessor: 'name',
                            type: CriteriaType.TextCriterion,
                        },
                        render: (rowData: Option) => {
                            if (rowData.currencyPair) {
                                return rowData.currencyPair;
                            } else {
                                return '-';
                            }
                        },
                        width: '57px',
                    },
                    {
                        title: 'Premium',
                        field: 'premium.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.premium) {
                                return displayRateSixDecimalPlaces(rowData.premium);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Expiry',
                        field: 'expiryDate',
                        filter: { type: CriteriaType.TimeCriterion },
                        render: (rowData: Option) => {
                            if (rowData.expiryDate) {
                                return <StyledMaturingValue t={rowData} />;
                            } else {
                                return '-';
                            }
                        },
                        width: '70px',
                    },
                    {
                        title: 'Delivery',
                        field: 'deliveryDate',
                        filter: { type: CriteriaType.TimeCriterion },
                        render: (rowData: Option) => {
                            if (rowData.deliveryDate) {
                                return displayDate((rowData.deliveryDate as unknown) as string);
                            } else {
                                return '-';
                            }
                        },
                        width: '70px',
                    },
                    {
                        title: 'Strike Price',
                        field: 'strikePrice.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.strikePrice) {
                                return displayRateSixDecimalPlaces(rowData.strikePrice);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Effective Rate',
                        field: 'effectiveRate.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.effectiveRate) {
                                return displayAmount(rowData.effectiveRate);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Type',
                        field: 'type',
                        filter: {
                            type: CriteriaType.TextCriterion,
                            options: tradeTypesForColumn,
                            displayAccessor: 'value',
                            valueAccessor: 'value',
                        },
                        render: (rowData: Option) => {
                            if (rowData) {
                                return rowData.type;
                            } else {
                                return '-';
                            }
                        },
                        width: '55px',
                    },
                    {
                        title: 'Direction',
                        field: 'direction',
                        filter: {
                            type: CriteriaType.TextCriterion,
                            displayAccessor: 'value',
                            valueAccessor: 'value',
                            options: tradeDirections,
                        },
                        render: (rowData: Option) => {
                            if (rowData.direction) {
                                return rowData.direction;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'IM/EX',
                        field: 'importExport',
                        filter: {
                            type: CriteriaType.TextCriterion,
                            displayAccessor: 'value',
                            valueAccessor: 'value',
                            options: importExportOptions,
                        },
                        // eslint-disable-next-line react/display-name
                        render: (rowData: Option) => <StyledImExValue option={rowData} />,
                        width: '38px',
                    },
                    {
                        title: 'Bank',
                        field: 'bank',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Option) => {
                            return rowData.bank;
                        },
                        width: '31px',
                    },
                    {
                        title: 'Captured Spot Rate',
                        field: 'capturedSpotRate.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.capturedSpotRate) {
                                return displayAmount(rowData.capturedSpotRate);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Interbank Rate',
                        field: 'interbankRate.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.interbankRate) {
                                return rowData.interbankRate.toFixed(4);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Bank Rate',
                        field: 'bankRate.value',
                        filter: { type: CriteriaType.NumberCriterion },
                        render: (rowData: Option) => {
                            if (rowData.bankRate) {
                                return rowData.bankRate.toFixed(4);
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Trader',
                        field: 'trader',
                        filter: { type: CriteriaType.TextCriterion },
                    },
                    {
                        title: 'Financial Year',
                        field: 'financialYear',
                        filter: {
                            options: financialYears.map((f) => ({ name: f })),
                            displayAccessor: 'name',
                            valueAccessor: 'name',
                            type: CriteriaType.TextCriterion,
                        },
                        render: (rowData: Option) => {
                            if (rowData.financialYear) {
                                return rowData.financialYear;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Trader Organisation',
                        field: 'traderOrganisation',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Option) => {
                            if (rowData.traderOrganisation) {
                                return rowData.traderOrganisation;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Notes',
                        field: 'notes',
                        filter: { type: CriteriaType.TextCriterion },
                        render: (rowData: Option) => {
                            if (rowData.notes) {
                                return rowData.notes;
                            } else {
                                return '-';
                            }
                        },
                    },
                    {
                        title: 'Trade Date',
                        field: 'tradeDate',
                        filter: { type: CriteriaType.TimeCriterion },
                        render: (rowData: Option) => {
                            if (rowData.tradeDate) {
                                return displayDate((rowData.tradeDate as unknown) as string);
                            } else {
                                return '-';
                            }
                        },
                        width: '70px',
                    },
                ]}
                count={optionsTotal}
                data={optionsResponse || []}
                defaultColConfig={[
                    { header: 'IM/EX', visible: true },
                    { header: 'External Ref', visible: true },
                    { header: 'Type', visible: true },
                    { header: 'Direction', visible: true },
                    { header: 'Currency', visible: true },
                    { header: 'Capture Date', visible: true },
                    { header: 'Call Amount', visible: true },
                    { header: 'Put Amount', visible: true },
                    { header: 'Expiry', visible: true },
                    { header: 'Strike Price', visible: true },
                    { header: 'Premium', visible: true },
                    { header: 'Bank', visible: true },
                    { header: 'Number', visible: false },
                    { header: 'Transaction ID', visible: false },
                    { header: 'Status', visible: false },
                    { header: 'Notional Amount', visible: false },
                    { header: 'Quote Amount', visible: false },
                    { header: 'Delivery', visible: false },
                    { header: 'Effective Rate', visible: false },
                    { header: 'Trade Date', visible: false },
                    { header: 'Captured Spot Rate', visible: false },
                    { header: 'Interbank Rate', visible: false },
                    { header: 'Bank Rate', visible: false },
                    { header: 'Trader', visible: false },
                    { header: 'Financial Year', visible: false },
                    { header: 'Trader Organisation', visible: false },
                    { header: 'Notes', visible: false },
                    { header: 'Processing Org', visible: false },
                    { header: 'Client', visible: false },
                ]}
                handleChangePage={handleChangePage}
                loading={loading}
                onChangeSorting={handleChangeSorting}
                onFilterChange={handleOptionsFilterChange}
                onRowCheck={handleSelectRow}
                order={query.order && query.order.length > 0 ? query.order[0] : undefined}
                page={Math.ceil(query.limit && query.offset ? query.offset / query.limit : 0)}
                rowClickAction={handleSelectRow}
                rowDoubleClickAction={(rowData: Option) => {
                    setSelected && setSelected(rowData);
                    setDetailDialogOpen && setDetailDialogOpen(true);
                }}
                rowsPerPage={query.limit}
                rowsPerPageOptions={[5, 10, 12, 17, 20, 25, 30]}
                onSelectAll={() => (selected ? setSelected && setSelected(undefined) : undefined)}
                selected={selected ? [selected] : []}
                showCheckboxes
                showFilterRow={showFilterRow}
                sortBy={query.sortBy && query.sortBy.length > 0 ? query.sortBy[0] : undefined}
                tableID={'FEC-OPTIONS-table'}
                title={'Options'}
            />
            <NotificationSweetAlert
                errorMessage={errorMessage}
                onClose={handleHideAlert}
                onConfirm={confirmationMethod}
                successMessage={successMessage}
                warningMessage={warningMessage}
            />
        </div>
    );
};

const StyledImExValue = (props: { option: Option }) => {
    const [css] = useStyletron();
    const theme = useTheme<CustomTheme>();
    let color = 'inherit';
    if (props.option.importExport === 'Import') {
        color = theme.palette.custom.import.main;
    }
    if (props.option.importExport === 'Export') {
        color = theme.palette.custom.export.main;
    }
    return <span className={css({ color })}>{props.option.importExport?.toUpperCase()}</span>;
};
