import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CustomTheme } from 'theme/custom';
import { makeStyles, useTheme } from '@material-ui/core';
import { ScaleLoader as Spinner } from 'react-spinners';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import { Client } from 'popcorn-js/party';
import { useServiceSync } from 'hooks/useService';
import { FindRequest, FindResponse } from 'popcorn-js';
import { Handler } from 'popcorn-js/party/client/handler';
import { Cell } from 'components/Cell/Cell';
import { useStyletron } from 'styletron-react';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'popcorn-js/party/processingOrg/recordkeeper';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { CriteriaType } from 'popcorn-js/search';
import { AppContext, AppContextT } from 'context';
import { displayAmount } from 'utils';

type newClient = {
    name: string;
    processingOrg: string;
    partyCode: string;
    monthlyFee: number;
};

const FixedFeeRevenueCard = (): ReactElement => {
    const appContext = useContext<AppContextT>(AppContext);
    const currentContext = appContext.currentContext?.partyType;
    const [css] = useStyletron();
    const classes = useStyles();
    const theme = useTheme<CustomTheme>();
    const [clients, setClients] = useState<newClient[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();
    const [showFooter, setShowFooter] = useState<boolean>();
    const [showProOrg, setShowProOrg] = useState<boolean>();

    const [findClients] = useServiceSync<FindRequest, FindResponse<Client>>(Handler.find);
    const [findProOrg] = useServiceSync<RetrieveRequest, RetrieveResponse>(ProcessingOrgRecordkeeper.retrieve);

    const truncate = (t: string, length: number): string => {
        if (t.length > length) {
            return `${t.substr(0, length)}...`;
        }
        return t;
    };

    const getProOrgName = async (code: string): Promise<string> => {
        try {
            const name = await findProOrg({
                identifier: {
                    type: IdentifierType.PARTY_CODE_IDENTIFIER,
                    partyCode: code,
                },
            });
            return String(name.processingOrg.name);
        } catch (e) {
            console.error('error finding processing organisation', e);
            return '';
        }
    };

    const setClientsList = async (clients: Client[]) => {
        const newClientsList: newClient[] = [];
        const length = currentContext === 'SYSTEM' ? 17 : 40;
        for (const client of clients) {
            const proOrgName = await getProOrgName(client.parentPartyCode);
            newClientsList.push({
                name: truncate(client.name, length),
                processingOrg: truncate(proOrgName, length),
                partyCode: client.partyCode,
                monthlyFee: client.monthlyFee,
            } as newClient);
        }
        setClients(newClientsList);
        setShowFooter(() => {
            return newClientsList.length > 4;
        });
    };

    useEffect(() => {
        setShowProOrg(() => {
            return currentContext === 'SYSTEM';
        });
        findClients({
            criteria: [
                {
                    type: CriteriaType.NumberCriterion,
                    field: 'monthlyFee.value',
                    lessThan: {
                        ignore: true,
                    },
                    moreThan: {
                        amount: 0.0004,
                        inclusive: false,
                    },
                },
            ],
            query: { sortBy: ['name'], order: ['asc'] },
            deleted: false,
        })
            .then((response) => setClientsList(response.records))
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className={classes.loadingLayout}>
                <Spinner color={theme.palette.primary.main} loading={loading} />
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.loadingLayout}>
                <ErrorIcon className={classes.errorIcon} />
                <div className={classes.errorText}>Failed to load report</div>
            </div>
        );
    }

    return (
        <div className={css({ paddingTop: '15px' })}>
            <div className={classes.fixedFeeTitle}>Fixed Monthly Fee Revenue</div>
            <div className={css({ padding: '68px 8px 0px 0px' })}>
                <div className={classes.row} /*--- heading ---*/>
                    <Cell
                        variant={'title'}
                        border={{ bottom: { weight: 'normal', color: 'default' } }}
                        height={'50px'}
                        width={showProOrg ? '150px' : '300px'}
                        text={'Client'}
                        color={'yellow'}
                        align={'left'}
                    />
                    {showProOrg && (
                        <Cell
                            variant={'title'}
                            border={{ bottom: { weight: 'normal', color: 'default' } }}
                            height={'50px'}
                            width={'150px'}
                            text={'Process. Org.'}
                            color={'yellow'}
                            align={'left'}
                        />
                    )}
                    <Cell
                        variant={'title'}
                        border={{ bottom: { weight: 'normal', color: 'default' } }}
                        height={'50px'}
                        width={'50px'}
                        text={'Code'}
                        color={'yellow'}
                        align={'right'}
                    />
                    <Cell
                        variant={'title'}
                        border={{ bottom: { weight: 'normal', color: 'default' } }}
                        height={'50px'}
                        width={'150px'}
                        text={'Fee'}
                        color={'yellow'}
                        align={'right'}
                    />
                    <Cell border={{ bottom: { weight: 'normal', color: 'default' } }} height={'50px'} width={'25px'} />
                </div>
            </div>
            <div className={classes.fixedFeeTable}>
                {clients.map((client: newClient, k: number) => (
                    <div key={k} className={classes.row} /*--- content ---*/>
                        <Cell
                            variant={'value'}
                            border={{ bottom: { weight: 'normal', color: 'default' } }}
                            height={'50px'}
                            width={showProOrg ? '150px' : '300px'}
                            text={client.name}
                            color={'white'}
                            align={'left'}
                        />
                        {showProOrg && (
                            <Cell
                                variant={'value'}
                                border={{ bottom: { weight: 'normal', color: 'default' } }}
                                height={'50px'}
                                width={'150px'}
                                text={client.processingOrg}
                                color={'white'}
                                align={'left'}
                            />
                        )}
                        <Cell
                            variant={'value'}
                            border={{ bottom: { weight: 'normal', color: 'default' } }}
                            height={'50px'}
                            width={'52px'}
                            text={client.partyCode}
                            color={'grey'}
                            align={'right'}
                        />
                        <Cell
                            variant={'value'}
                            border={{ bottom: { weight: 'normal', color: 'default' } }}
                            height={'50px'}
                            width={'150px'}
                            text={client.monthlyFee ? `R${displayAmount(client.monthlyFee)}` : '-'}
                            color={'grey'}
                            align={'right'}
                        />
                        <Cell
                            border={{ bottom: { weight: 'normal', color: 'default' } }}
                            height={'50px'}
                            width={'25px'}
                        />
                    </div>
                ))}
            </div>
            {showFooter && <div className={classes.fixedFeeFooter}>Scroll for more...</div>}
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    fixedFeeTitle: {
        paddingLeft: '25px',
        fontWeight: 'bold',
        fontSize: '18px',
    },
    fixedFeeTable: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxHeight: '200px',
        overflowY: 'auto',
    },
    fixedFeeClientView: {
        height: '100%',
        width: '220px',
        display: 'flex',
        flexDirection: 'column',
        gap: '52px',
        padding: '70px 0px 0px 25px',
    },
    fixedFeeFooter: {
        paddingTop: '15px',
        paddingLeft: '25px',
        fontSize: '12px',
        color: theme.palette.custom.text.label,
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    loadingLayout: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));

export default FixedFeeRevenueCard;
