export const text = `<html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List href="SOL_PrivacyPolicy.fld/filelist.xml">
<link rel=Edit-Time-Data href="SOL_PrivacyPolicy.fld/editdata.mso">
<!--[if !mso]>
<style>
v\\:* {behavior:url(#default#VML);}
o\\:* {behavior:url(#default#VML);}
w\\:* {behavior:url(#default#VML);}
.shape {behavior:url(#default#VML);}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:DocumentProperties>
  <o:Author>Microsoft Office User</o:Author>
  <o:LastAuthor>Microsoft Office User</o:LastAuthor>
  <o:Revision>2</o:Revision>
  <o:TotalTime>20</o:TotalTime>
  <o:Created>2021-06-30T19:49:00Z</o:Created>
  <o:LastSaved>2021-06-30T19:49:00Z</o:LastSaved>
  <o:Pages>1</o:Pages>
  <o:Words>3257</o:Words>
  <o:Characters>18567</o:Characters>
  <o:Lines>154</o:Lines>
  <o:Paragraphs>43</o:Paragraphs>
  <o:CharactersWithSpaces>21781</o:CharactersWithSpaces>
  <o:Version>16.00</o:Version>
 </o:DocumentProperties>
 <o:OfficeDocumentSettings>
  <o:AllowPNG/>
 </o:OfficeDocumentSettings>
</xml><![endif]-->
<link rel=themeData href="SOL_PrivacyPolicy.fld/themedata.thmx">
<link rel=colorSchemeMapping href="SOL_PrivacyPolicy.fld/colorschememapping.xml">
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:Zoom>146</w:Zoom>
  <w:SpellingState>Clean</w:SpellingState>
  <w:GrammarState>Clean</w:GrammarState>
  <w:TrackMoves>false</w:TrackMoves>
  <w:TrackFormatting/>
  <w:PunctuationKerning/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>EN-ZA</w:LidThemeOther>
  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
  <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:BreakWrappedTables/>
   <w:SnapToGridInCell/>
   <w:WrapTextWithPunct/>
   <w:UseAsianBreakRules/>
   <w:DontGrowAutofit/>
   <w:SplitPgBreakAndParaMark/>
   <w:EnableOpenTypeKerning/>
   <w:DontFlipMirrorIndents/>
   <w:OverrideTableStyleHps/>
  </w:Compatibility>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="&#45;-"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="376">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
 </w:LatentStyles>
</xml><![endif]-->
<style>
<!--
 /* Font Definitions */
 @font-face
\t{font-family:Helvetica;
\tpanose-1:0 0 0 0 0 0 0 0 0 0;
\tmso-font-charset:0;
\tmso-generic-font-family:auto;
\tmso-font-pitch:variable;
\tmso-font-signature:-536870145 1342208091 0 0 415 0;}
@font-face
\t{font-family:"Cambria Math";
\tpanose-1:2 4 5 3 5 4 6 3 2 4;
\tmso-font-charset:0;
\tmso-generic-font-family:roman;
\tmso-font-pitch:variable;
\tmso-font-signature:-536870145 1107305727 0 0 415 0;}
@font-face
\t{font-family:Calibri;
\tpanose-1:2 15 5 2 2 2 4 3 2 4;
\tmso-font-charset:0;
\tmso-generic-font-family:swiss;
\tmso-font-pitch:variable;
\tmso-font-signature:-536859905 -1073732485 9 0 511 0;}
@font-face
\t{font-family:"Arial Unicode MS";
\tpanose-1:2 11 6 4 2 2 2 2 2 4;
\tmso-font-charset:128;
\tmso-generic-font-family:swiss;
\tmso-font-pitch:variable;
\tmso-font-signature:-134238209 -371195905 63 0 4129279 0;}
@font-face
\t{font-family:"Helvetica Neue";
\tpanose-1:2 0 5 3 0 0 0 2 0 4;
\tmso-font-alt:"﷽﷽﷽﷽﷽﷽﷽﷽a Neue";
\tmso-font-charset:0;
\tmso-generic-font-family:auto;
\tmso-font-pitch:variable;
\tmso-font-signature:-452984065 1342208475 16 0 1 0;}
@font-face
\t{font-family:MinionPro-Regular;
\tpanose-1:2 11 6 4 2 2 2 2 2 4;
\tmso-font-charset:77;
\tmso-generic-font-family:auto;
\tmso-font-pitch:auto;
\tmso-font-signature:3 0 0 0 1 0;}
@font-face
\t{font-family:"Helvetica Neue Light";
\tpanose-1:2 0 4 3 0 0 0 2 0 4;
\tmso-font-alt:"﷽﷽﷽﷽﷽﷽﷽﷽A NEUE LIGHT";
\tmso-font-charset:0;
\tmso-generic-font-family:auto;
\tmso-font-pitch:variable;
\tmso-font-signature:-1610611969 1342185563 2 0 7 0;}
@font-face
\t{font-family:"\\@Arial Unicode MS";
\tpanose-1:2 11 6 4 2 2 2 2 2 4;
\tmso-font-charset:128;
\tmso-generic-font-family:swiss;
\tmso-font-pitch:variable;
\tmso-font-signature:-134238209 -371195905 63 0 4129279 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
\t{mso-style-unhide:no;
\tmso-style-qformat:yes;
\tmso-style-parent:"";
\tmargin:0cm;
\tmso-pagination:widow-orphan;
\tfont-size:11.0pt;
\tmso-bidi-font-size:12.0pt;
\tfont-family:"Arial",sans-serif;
\tmso-fareast-font-family:"Times New Roman";
\tmso-bidi-font-family:"Times New Roman";}
p.MsoHeader, li.MsoHeader, div.MsoHeader
\t{mso-style-priority:99;
\tmso-style-link:"Header Char";
\tmargin-top:2.0pt;
\tmargin-right:0cm;
\tmargin-bottom:0cm;
\tmargin-left:0cm;
\tmso-pagination:widow-orphan;
\ttab-stops:center 225.65pt right 451.3pt;
\tfont-size:10.0pt;
\tmso-bidi-font-size:12.0pt;
\tfont-family:"Arial",sans-serif;
\tmso-fareast-font-family:"Times New Roman";
\tmso-ansi-language:EN-US;}
a:link, span.MsoHyperlink
\t{mso-style-noshow:yes;
\tmso-style-priority:99;
\tcolor:#0563C1;
\tmso-themecolor:hyperlink;
\ttext-decoration:underline;
\ttext-underline:single;}
a:visited, span.MsoHyperlinkFollowed
\t{mso-style-noshow:yes;
\tmso-style-priority:99;
\tcolor:#954F72;
\tmso-themecolor:followedhyperlink;
\ttext-decoration:underline;
\ttext-underline:single;}
p
\t{mso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-margin-top-alt:auto;
\tmargin-right:0cm;
\tmso-margin-bottom-alt:auto;
\tmargin-left:0cm;
\tmso-pagination:widow-orphan;
\tfont-size:11.0pt;
\tmso-bidi-font-size:12.0pt;
\tfont-family:"Arial",sans-serif;
\tmso-fareast-font-family:"Times New Roman";
\tmso-bidi-font-family:"Times New Roman";}
span.HeaderChar
\t{mso-style-name:"Header Char";
\tmso-style-priority:99;
\tmso-style-unhide:no;
\tmso-style-locked:yes;
\tmso-style-link:Header;
\tmso-ansi-font-size:10.0pt;
\tfont-family:"Arial",sans-serif;
\tmso-ascii-font-family:Arial;
\tmso-fareast-font-family:"Times New Roman";
\tmso-hansi-font-family:Arial;
\tmso-bidi-font-family:Arial;
\tmso-ansi-language:EN-US;
\tmso-fareast-language:EN-GB;}
p.Body, li.Body, div.Body
\t{mso-style-name:Body;
\tmso-style-priority:99;
\tmso-style-unhide:no;
\tmso-style-parent:"";
\tmargin:0cm;
\tmso-pagination:widow-orphan;
\tfont-size:11.0pt;
\tfont-family:"Helvetica Neue";
\tmso-fareast-font-family:"Arial Unicode MS";
\tmso-bidi-font-family:"Arial Unicode MS";
\tcolor:black;
\tmso-style-textoutline-type:none;
\tmso-style-textoutline-outlinestyle-dpiwidth:0pt;
\tmso-style-textoutline-outlinestyle-linecap:flat;
\tmso-style-textoutline-outlinestyle-join:bevel;
\tmso-style-textoutline-outlinestyle-pctmiterlimit:0%;
\tmso-style-textoutline-outlinestyle-dash:solid;
\tmso-style-textoutline-outlinestyle-align:center;
\tmso-style-textoutline-outlinestyle-compound:simple;
\tmso-ansi-language:EN-US;}
p.BasicParagraph, li.BasicParagraph, div.BasicParagraph
\t{mso-style-name:"\\[Basic Paragraph\\]";
\tmso-style-priority:99;
\tmso-style-unhide:no;
\tmargin:0cm;
\tline-height:120%;
\tmso-pagination:widow-orphan;
\tmso-layout-grid-align:none;
\ttext-autospace:none;
\tfont-size:11.0pt;
\tmso-bidi-font-size:12.0pt;
\tfont-family:MinionPro-Regular;
\tmso-fareast-font-family:Calibri;
\tmso-fareast-theme-font:minor-latin;
\tmso-bidi-font-family:MinionPro-Regular;
\tcolor:black;
\tmso-ansi-language:EN-GB;
\tmso-fareast-language:EN-US;}
span.SpellE
\t{mso-style-name:"";
\tmso-spl-e:yes;}
.MsoChpDefault
\t{mso-style-type:export-only;
\tmso-default-props:yes;
\tfont-family:"Calibri",sans-serif;
\tmso-ascii-font-family:Calibri;
\tmso-ascii-theme-font:minor-latin;
\tmso-fareast-font-family:Calibri;
\tmso-fareast-theme-font:minor-latin;
\tmso-hansi-font-family:Calibri;
\tmso-hansi-theme-font:minor-latin;
\tmso-bidi-font-family:"Times New Roman";
\tmso-bidi-theme-font:minor-bidi;
\tmso-fareast-language:EN-US;}
 /* Page Definitions */
 @page
\t{mso-footnote-separator:url("SOL_PrivacyPolicy.fld/header.html") fs;
\tmso-footnote-continuation-separator:url("SOL_PrivacyPolicy.fld/header.html") fcs;
\tmso-endnote-separator:url("SOL_PrivacyPolicy.fld/header.html") es;
\tmso-endnote-continuation-separator:url("SOL_PrivacyPolicy.fld/header.html") ecs;}
@page WordSection1
\t{size:595.3pt 841.9pt;
\tmargin:72.0pt 72.0pt 3.0cm 72.0pt;
\tmso-header-margin:35.45pt;
\tmso-footer-margin:35.45pt;
\tmso-header:url("SOL_PrivacyPolicy.fld/header.html") h1;
\tmso-footer:url("SOL_PrivacyPolicy.fld/header.html") f1;
\tmso-paper-source:0;}
div.WordSection1
\t{page:WordSection1;}
 /* List Definitions */
 @list l0
\t{mso-list-id:265772108;
\tmso-list-template-ids:-586515944;
\tmso-list-style-priority:99;
\tmso-list-style-name:"Current List2";}
@list l0:level1
\t{mso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:18.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:bold;
\tmso-bidi-font-weight:bold;}
@list l0:level2
\t{mso-level-text:"%1\\.%2";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:36.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:bold;}
@list l0:level3
\t{mso-level-text:"%1\\.%2\\.%3";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:54.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l0:level4
\t{mso-level-text:"%1\\.%2\\.%3\\.%4";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:72.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l0:level5
\t{mso-level-number-format:alpha-lower;
\tmso-level-text:"\\(%5\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:90.0pt;
\ttext-indent:-18.0pt;}
@list l0:level6
\t{mso-level-number-format:roman-lower;
\tmso-level-text:"\\(%6\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:108.0pt;
\ttext-indent:-18.0pt;}
@list l0:level7
\t{mso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:126.0pt;
\ttext-indent:-18.0pt;}
@list l0:level8
\t{mso-level-number-format:alpha-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:144.0pt;
\ttext-indent:-18.0pt;}
@list l0:level9
\t{mso-level-number-format:roman-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:162.0pt;
\ttext-indent:-18.0pt;}
@list l1
\t{mso-list-id:1380008250;
\tmso-list-template-ids:1003020632;
\tmso-list-style-priority:99;
\tmso-list-style-name:"Current List3";}
@list l1:level1
\t{mso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:2.85pt;
\ttext-indent:-2.85pt;
\tmso-ansi-font-weight:bold;
\tmso-bidi-font-weight:bold;}
@list l1:level2
\t{mso-level-text:"%1\\.%2";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:36.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:bold;}
@list l1:level3
\t{mso-level-text:"%1\\.%2\\.%3";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:54.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l1:level4
\t{mso-level-text:"%1\\.%2\\.%3\\.%4";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:72.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l1:level5
\t{mso-level-number-format:alpha-lower;
\tmso-level-text:"\\(%5\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:90.0pt;
\ttext-indent:-18.0pt;}
@list l1:level6
\t{mso-level-number-format:roman-lower;
\tmso-level-text:"\\(%6\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:108.0pt;
\ttext-indent:-18.0pt;}
@list l1:level7
\t{mso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:126.0pt;
\ttext-indent:-18.0pt;}
@list l1:level8
\t{mso-level-number-format:alpha-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:144.0pt;
\ttext-indent:-18.0pt;}
@list l1:level9
\t{mso-level-number-format:roman-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:162.0pt;
\ttext-indent:-18.0pt;}
@list l2
\t{mso-list-id:1575779250;
\tmso-list-template-ids:911751808;}
@list l2:level1
\t{mso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:2.85pt;
\ttext-indent:-2.85pt;
\tmso-ansi-font-weight:bold;
\tmso-bidi-font-weight:bold;}
@list l2:level2
\t{mso-level-text:"%1\\.%2";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:2.85pt;
\ttext-indent:2.8pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:bold;}
@list l2:level3
\t{mso-level-text:"%1\\.%2\\.%3";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:54.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l2:level4
\t{mso-level-text:"%1\\.%2\\.%3\\.%4";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:72.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l2:level5
\t{mso-level-number-format:alpha-lower;
\tmso-level-text:"\\(%5\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:90.0pt;
\ttext-indent:-18.0pt;}
@list l2:level6
\t{mso-level-number-format:roman-lower;
\tmso-level-text:"\\(%6\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:108.0pt;
\ttext-indent:-18.0pt;}
@list l2:level7
\t{mso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:126.0pt;
\ttext-indent:-18.0pt;}
@list l2:level8
\t{mso-level-number-format:alpha-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:144.0pt;
\ttext-indent:-18.0pt;}
@list l2:level9
\t{mso-level-number-format:roman-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:162.0pt;
\ttext-indent:-18.0pt;}
@list l3
\t{mso-list-id:1722947387;
\tmso-list-template-ids:2094532006;
\tmso-list-style-priority:99;
\tmso-list-style-name:"Current List1";}
@list l3:level1
\t{mso-level-text:"Clause %1";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:18.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:bold;
\tmso-bidi-font-weight:bold;}
@list l3:level2
\t{mso-level-text:"%1\\.%2";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:36.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:bold;}
@list l3:level3
\t{mso-level-text:"%1\\.%2\\.%3";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:54.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l3:level4
\t{mso-level-text:"%1\\.%2\\.%3\\.%4";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:72.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l3:level5
\t{mso-level-number-format:alpha-lower;
\tmso-level-text:"\\(%5\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:90.0pt;
\ttext-indent:-18.0pt;}
@list l3:level6
\t{mso-level-number-format:roman-lower;
\tmso-level-text:"\\(%6\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:108.0pt;
\ttext-indent:-18.0pt;}
@list l3:level7
\t{mso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:126.0pt;
\ttext-indent:-18.0pt;}
@list l3:level8
\t{mso-level-number-format:alpha-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:144.0pt;
\ttext-indent:-18.0pt;}
@list l3:level9
\t{mso-level-number-format:roman-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:162.0pt;
\ttext-indent:-18.0pt;}
@list l4
\t{mso-list-id:1850094449;
\tmso-list-template-ids:691813446;
\tmso-list-style-priority:99;
\tmso-list-style-name:"Current List4";}
@list l4:level1
\t{mso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:2.85pt;
\ttext-indent:-2.85pt;
\tmso-ansi-font-weight:bold;
\tmso-bidi-font-weight:bold;}
@list l4:level2
\t{mso-level-text:"%1\\.%2";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:2.85pt;
\ttext-indent:15.15pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:bold;}
@list l4:level3
\t{mso-level-text:"%1\\.%2\\.%3";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:54.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l4:level4
\t{mso-level-text:"%1\\.%2\\.%3\\.%4";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:72.0pt;
\ttext-indent:-18.0pt;
\tmso-ansi-font-weight:normal;
\tmso-bidi-font-weight:normal;}
@list l4:level5
\t{mso-level-number-format:alpha-lower;
\tmso-level-text:"\\(%5\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:90.0pt;
\ttext-indent:-18.0pt;}
@list l4:level6
\t{mso-level-number-format:roman-lower;
\tmso-level-text:"\\(%6\\)";
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:108.0pt;
\ttext-indent:-18.0pt;}
@list l4:level7
\t{mso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:126.0pt;
\ttext-indent:-18.0pt;}
@list l4:level8
\t{mso-level-number-format:alpha-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:144.0pt;
\ttext-indent:-18.0pt;}
@list l4:level9
\t{mso-level-number-format:roman-lower;
\tmso-level-tab-stop:none;
\tmso-level-number-position:left;
\tmargin-left:162.0pt;
\ttext-indent:-18.0pt;}
ol
\t{margin-bottom:0cm;}
ul
\t{margin-bottom:0cm;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
\t{mso-style-name:"Table Normal";
\tmso-tstyle-rowband-size:0;
\tmso-tstyle-colband-size:0;
\tmso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-style-parent:"";
\tmso-padding-alt:0cm 5.4pt 0cm 5.4pt;
\tmso-para-margin:0cm;
\tmso-pagination:widow-orphan;
\tfont-size:12.0pt;
\tfont-family:"Calibri",sans-serif;
\tmso-ascii-font-family:Calibri;
\tmso-ascii-theme-font:minor-latin;
\tmso-hansi-font-family:Calibri;
\tmso-hansi-theme-font:minor-latin;
\tmso-bidi-font-family:"Times New Roman";
\tmso-bidi-theme-font:minor-bidi;
\tmso-fareast-language:EN-US;}
</style>
<![endif]-->
</head>

<body lang=EN-ZA link="#0563C1" vlink="#954F72" style='tab-interval:36.0pt;
word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:0cm;line-height:115%'><a name="OLE_LINK12"></a><a
name="OLE_LINK13"><span style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p>&nbsp;</o:p></span></span></a></p>

<p class=MsoNormal align=center style='margin-top:12.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:center;line-height:115%'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><a
name="OLE_LINK1"></a><a name="OLE_LINK2"><span style='mso-bookmark:OLE_LINK1'></span></a><span
class=SpellE><span style='mso-bookmark:OLE_LINK2'><span style='mso-bookmark:
OLE_LINK1'><b><span style='font-size:12.0pt;line-height:115%;mso-fareast-font-family:
"Helvetica Neue";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:white;mso-themecolor:background1'>FXFlow</span></b></span></span></span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bookmark:OLE_LINK2'><span style='mso-bookmark:OLE_LINK1'><b><span
style='font-size:12.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'> Online</span></b></span></span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><b><span
style='font-size:12.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'> Website Privacy Policy<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal align=center style='margin-top:12.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:center;line-height:115%'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Read this policy carefully before browsing this
website or using any of&nbsp;our&nbsp;services. Your continued use of this
website&nbsp;and our services&nbsp;indicates that you have both read and agree
to the terms of this website privacy policy. You cannot use this website or our
services <a name="_Hlk71025160">which are accessed through or by means of this
website </a>if you do not accept this policy. All sections of this policy are
applicable to users of this website unless the section expressly states
otherwise.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Introduction<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>The
FXFlow Website Privacy Policy (“the Privacy Policy”) applies <a
name="_Hlk71022422"></a><span class=SpellE><span style='mso-bookmark:_Hlk71022422'>FXFlow</span></span><span
style='mso-bookmark:_Hlk71022422'> (Pty) Ltd</span>, including any of their
affiliates not mentioned herein. FXFlow is committed to protecting the
privacy and security of your personal information which is obtained by us when
you access our website and we will only use your personal information in
accordance with this Privacy Policy and the applicable laws<b style='mso-bidi-font-weight:
normal'><o:p></o:p></b></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>FXFlow recognises the importance of protecting your privacy in relation to your
personal information which we collect when you visit our website currently
located at:</span></span></span><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:white;mso-themecolor:background1'> </span><span style='color:white;
mso-themecolor:background1'>https://app.fxflow.co</span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'> (referred to as “the Website”).<b
style='mso-bidi-font-weight:normal'><o:p></o:p></b></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>For
the purposes of this Privacy Policy, the term “Personal Information” will be
understood in accordance with the definition provided in the Protection of
Personal Information Act 4 of 2013 (&quot;POPIA&quot;) and in accordance with
the definition of “Personal Data” in Article 4(1) of the General Data
Protection Regulation (EU) 2016/679 (“GDPR”) and </span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
lang=EN-GB style='mso-bidi-font-size:11.0pt;line-height:115%;mso-bidi-font-family:
Helvetica;color:white;mso-themecolor:background1;mso-ansi-language:EN-GB'>and
in accordance with the definition of “personal data”<span
style='mso-spacerun:yes'>  </span>provided in Section 2 of the Republic of
Mauritius Data Protection Act 2017 </span></span></span><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>to the extent applicable and shall refer to your
personal information which we acquire as a result of your use of the Website.
We also subscribe to the principles for electronically collecting personal
information outlined in POPIA, and the further legislation referred to therein.<b
style='mso-bidi-font-weight:normal'><o:p></o:p></b></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
endeavour to ensure the quality, accuracy and confidentiality of Personal
Information in our possession </span></span></span><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:white;mso-themecolor:background1'>and we recognise the importance of
protecting your privacy in respect of your Personal Information which is
collected when you visit the Website. We are committed to protecting and
preserving the Personal Information of all visitors to the Website</span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>.<b style='mso-bidi-font-weight:normal'><o:p></o:p></b></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>1.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:white;mso-themecolor:background1'>We reserve the right to modify this
Privacy Policy from time to time, in particular, to reflect changes in relevant
laws and regulatory requirements. In the event that we modify this Privacy
Policy, any new version shall be effective 14 days immediately after it is
first published on our Website.</span></span></span><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><b style='mso-bidi-font-weight:
normal'><span style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:
"Helvetica Neue Light";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:white;mso-themecolor:background1'><o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>1.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:white;mso-themecolor:background1'>This Privacy Policy should not be
viewed in isolation and must be read together with any other applicable terms
of use of the Website and with the terms of any agreement/s entered into
between us and you.</span></span></span><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>1.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>By using/accessing the Website, you agree to us
processing your Personal Information for the purposes and to the extent as
stated in this Privacy Policy and on our Website. If you do not agree to the
processing of your Personal Information as described in this Privacy Policy,
please do not use/access the Website.<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Privacy Policy<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>In
adopting this Privacy Policy, we wish to balance our legitimate business
interests and your reasonable expectation of privacy in relation to the
processing by us of your Personal Information which is obtained by us as a
result of you having visited or accessed the Website. Accordingly, we will take
all reasonable steps to prevent unauthorised access to, or unlawful disclosure
of your Personal Information. <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>In
utilising our Website, utilising our services</span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'> through or by means of our Website,</span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'> or otherwise if your information is submitted to
us through a lead generation service you may be asked to provide the following
information (all of which could comprise of Personal Information), your:<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:70.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.2.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>First Name;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.2.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Surname;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.2.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Email;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.2.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Mobile Number;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.2.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Company/CC/Business Name;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.2.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Company/CC/Business Registration Number;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
shall take reasonably practicable steps to ensure that the Personal Information
is complete, accurate, not misleading and updated where necessary. <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
will attempt to limit the types of Personal Information we process to only that
to which you consent to (for example, in the context of online registration,
newsletters, message boards, surveys, polls, professional announcements, SMS,
lead generation, MMS and other mobile services) save for as otherwise lawfully
permitted. Where necessary, we will seek to obtain your specific consent in
future instances should we deem same required by law and where your consent
herein might not be lawfully sufficient.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>You
hereby consent to the processing of the Personal Information for purposes of
direct marketing by means of any form of electronic communication as
contemplated in section 69 of POPIA and/or Article 7 of the GDPR (whichever is
applicable).<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><a name="_Hlk71189756"><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:
bold'><span style='mso-list:Ignore'>2.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
will not process, collect, use or disclose your special personal information
(as defined in POPIA) or other sensitive information (which pertains to but is
not limited to information concerning<span style='mso-spacerun:yes'> 
</span>racial or ethnic origins or religious beliefs, where relevant) except with
your specific consent or in the other circumstances permitted by POPIA or
otherwise in terms of the applicable law</span></a></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>By
agreeing to the terms contained in this Privacy Policy, you consent to the<span
style='mso-spacerun:yes'>  </span>processing of your Personal Information in
relation to:<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:70.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.7.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>The provision and performance of our services to
you;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.7.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Informing you of changes made to our Website;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.7.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>The provision of marketing related services by us
to you; <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.7.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Responding to any queries or requests you may have;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.7.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Developing a more direct and substantial
relationship with you;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.7.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Developing an online user profile;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.7.7<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Understanding general user trends and patterns;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.7.8<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>For security, administrative and legal purposes;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>2.7.9<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>The creation and development of market data
profiles;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-42.5pt;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>2.7.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>In
the engagement and response to any employment position for which you may have applied.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Although
absolute security cannot be guaranteed on the internet, we have implemented
up-to-date, appropriate reasonable technical and organisational security
measures in an effort to ensure the integrity and confidentiality of<span
style='mso-spacerun:yes'>  </span>your Personal Information obtained by us as a
result of your use of the Website in an effort to prevent the loss of, damage
to, or the unauthorised destruction of your Personal Information and in an
effort to prevent the unlawful access to, or processing of your Personal Information.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>While
we cannot warrant the security of any Personal Information you provide us, we
will take reasonable measures to<span style='mso-spacerun:yes'> 
</span>continue to identify all reasonably foreseeable internal and external
risks to your Personal Information in our possession or under our control,
shall establish and maintain appropriate safeguards against the risks
identified, shall regularly verify that our safeguards are effectively
implemented and shall ensure that our safeguards are continually updated in
response to new risks or deficiencies in previously implemented safeguards. We
shall have due regard to generally accepted information security </span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:Calibri;mso-bidi-theme-font:
minor-latin;color:white;mso-themecolor:background1;mso-font-kerning:12.0pt'>practices&nbsp;and
procedures which may apply to us generally, or be required in terms of specific
industry or professional rules and regulations.</span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
store your Personal Information directly, or alternatively, store your Personal
Information on, and transfer your Personal Information to, a central database.
If the location of the central database is located in a country that does not
have substantially similar laws which provide for the protection of Personal
Information, we will take the necessary steps to ensure that your Personal
Information is adequately protected in that jurisdiction by means of ensuring
that standard data protection clauses are enforced as envisaged by POPIA and by
the GDPR (to the extent applicable).<span style='mso-spacerun:yes'>   </span><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.11<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Your
Personal Information will not be stored for longer than is necessary for the
purposes described in this Privacy Policy or as required by applicable
legislation. We shall destroy or delete records of Personal Information, or
deidentify such Personal Information as soon as reasonably practicable after we
are no longer lawfully authorised to retain the applicable record. <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.12<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>The
Personal Information we collect from users shall only be accessed by our
employees, representatives, contractors and third parties on a need-to-know
basis, and subject to reasonable confidentiality obligations binding such
persons.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.13<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
shall have the right, but shall not be obliged, to monitor or examine any information
and materials including any website link that you publish or submit to us for
publishing on the Website. You shall be solely responsible for the contents of
all material published by yourself.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.14<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
constantly review our systems and data to ensure the best possible service to
our users. We do not accept any users, or representatives of users, under 18
years of age or who otherwise does not have the relevant capacity to be bound
by this Privacy Policy.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>2.15<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
will not sell, share, or rent your Personal Information to any third party or
use your e-mail address for unsolicited mail. Any emails sent by us will only
be in connection with the provision of our services and/or the marketing
thereof.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Your Rights<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Every
user of this Website has the right:<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:106.35pt;margin-bottom:.0001pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>3.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>To
be notified that the Personal Information is being collected;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:106.35pt;margin-bottom:.0001pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>3.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>To
be notified in the event that the Personal Information has been accessed or
acquired by an unauthorised person;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:106.35pt;margin-bottom:.0001pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>3.1.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>To
request us to confirm whether or not we hold any Personal Information
pertaining to such user, as well as the right to request us to provide the
record or a description of the Personal Information about the applicable user
held by us.&nbsp;This may be done by emailing us at the address referred to in
clause&nbsp;11.4 below;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>3.1.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>To
request us to correct or delete the Personal Information that we hold on a
user's behalf, in instances where such Personal Information is incorrect,
incomplete, irrelevant, excessive, inaccurate, out of date, misleading or which
has been obtained unlawfully;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>3.1.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>To
request us to destroy or delete such user’s Personal Information (&quot;right
to be forgotten&quot;) if such information is no longer needed for the original
processing purpose, alternatively if a user withdraws their consent and there
is no other reason or justification to retain such Personal Information,
further alternatively, if a user has objected to such Personal Information
being processed and there is no justified reason for the processing of such
Personal Information;<span style='mso-spacerun:yes'>  </span><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;line-height:115%;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p>&nbsp;</o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><a
name="_Hlk71193006"><![if !supportLists]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>3.1.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:Calibri;mso-bidi-theme-font:
minor-latin;color:white;mso-themecolor:background1;mso-font-kerning:12.0pt'>Not
to be subject, under certain circumstances, to a decision which is based solely
on the basis of the automated processing of the Personal Information intended
to provide a profile of such person;</span></a></span></span><span
style='mso-bookmark:_Hlk71193006'></span><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>3.1.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>If
we breach any of the terms of this Privacy Policy, to submit a complaint to <a
name="_Hlk71193121">the duly appointed data protection authority in the
applicable territory and who has jurisdiction over the matter</a>;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>3.1.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>To
withdraw their consent <a name="_Hlk71193209">to the processing of the Personal
Information at any time, to the extent that they previously provided such
consent</a>;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>3.1.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>To
object<a name="_Hlk71193260">, on reasonable grounds relating to the particular
situation of the user, to the processing of the Personal Information unless the
applicable legislation allows or requires such processing</a>;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><a
name="_Hlk71193319"><![if !supportLists]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>3.1.10<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:Calibri;mso-bidi-theme-font:
minor-latin;color:white;mso-themecolor:background1;mso-font-kerning:12.0pt'>Not
to have the Personal Information processed for purposes of direct marketing by
means of unsolicited electronic communications except as allowed in terms of
the applicable legislation and subject to the consent provided in clause 2.5
above</span></a></span></span><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:white;mso-themecolor:background1;mso-font-kerning:12.0pt'>;</span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>3.1.11<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>To
object to the processing of Personal Information for direct marketing purposes <a
name="_Hlk71193429">and to object to the processing of Personal </a></span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bookmark:_Hlk71193429'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Times New Roman";mso-fareast-theme-font:
minor-fareast;mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:white;mso-themecolor:background1;mso-font-kerning:12.0pt'>Information processed
for purposes of direct marketing by means of unsolicited electronic
communications</span></span></span></span><span style='mso-bookmark:_Hlk71193429'></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;text-indent:
-35.45pt;line-height:115%;mso-list:l2 level3 lfo1;tab-stops:92.15pt 106.35pt'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><a
name="_Hlk71193493"><![if !supportLists]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>3.1.12<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:Calibri;mso-bidi-theme-font:
minor-latin;color:white;mso-themecolor:background1;mso-font-kerning:12.0pt'>To
institute civil proceedings regarding the alleged interference with the
protection of the Personal Information</span></a></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Times New Roman";
mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:Calibri;mso-bidi-theme-font:
minor-latin;color:white;mso-themecolor:background1;mso-font-kerning:12.0pt'>.</span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Disclosure of Personal Information<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>4.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
may disclose your Personal Information to the following persons for the
purposes of us rendering our services to you (to the extent that you have
accessed such services through our Website) and/or for purposes of enabling
your use of the Website, and for those reasons recorded in clauses 2.4, 2.5 and
2.7above, our:<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:0cm;line-height:115%;
mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>4.1.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Suppliers/service providers;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:0cm;line-height:115%;
mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>4.1.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Contractors; and<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:0cm;line-height:115%;
mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>4.1.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Affiliated Partners.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>If
our services are provided in conjunction with, or by involvement of third parties,
such third parties may require access to your Personal Information in order to
fulfil your request.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>4.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>In
addition, we may also disclose your Personal Information where such disclosure
is necessary to protect our legitimate interests or as required or permitted by
law.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><a name="_Hlk71268547"><![if !supportLists]><b><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>GDPR
and the Mauritius Data Protection Act 2017 (“the DPA”)</span></b></a></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bookmark:_Hlk71268547'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'><o:p></o:p></span></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bookmark:_Hlk71268547'><a
name="_Hlk71268680"><![if !supportLists]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>To
the extent that the provisions of the GDPR and/or the DPA apply to the
processing of your Personal Information by us, as detailed in this Policy, we
shall endeavour to ensure that any additional applicable requirements of the
GDPR and/or the DPA which extend beyond the requirements of POPIA, are duly met
by us</span></a></span></span></span><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bookmark:_Hlk71268547'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>. <o:p></o:p></span></span></span></span></p>

<span style='mso-bookmark:_Hlk71268547'></span>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Indemnity<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>You
hereby indemnify and hold FXFlow harmless from any loss, damages or
injury that you may incur as a result of any unintentional disclosures of your
Personal Information to unauthorised persons or resulting from the provision of
incorrect or incomplete personal information to FXFlow </span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1;
'>and which losses, damages or injuries were outside of the
control of FXFlow (i.e. could not have been reasonably prevented or
anticipated by it) and/or were not caused by its gross negligence or wilful
default</span></span></span><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Log Files<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>7.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>When
you visit the Website, even if you do not create an account, we may collect information,
such as your IP address, the name of your ISP (Internet Service Provider), your
browser, the website from which you visit us, the pages on our Website that you
visit and in what sequence, the date and length of your visit, and other
information concerning your computer's operating system, language settings, and
broad demographic information. This information is aggregated as anonymous data
and does not identify you specifically. However, you acknowledge that this data
may be able to be used to identify you if it is aggregated with other Personal
Information that you supply to us. This information is not shared with third
parties and is used only within the company on a need-to-know basis. Any
individually identifiable information related to this data will never be used
in any way different to that stated above, without your explicit permission.
&nbsp;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Cookie Notice<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
use cookies. A cookie is a small text file that a website saves to your
computer or smart device when you visit the site. They allow us to remember
your actions or preferences over a period of time. Cookies are not used in any
way to identify you personally and it in no way gives us access to your
computer or any personal information about you, other than the information you
choose to share with us.<span style='mso-spacerun:yes'>  </span>The two types
of cookies used on the Website are described below:<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:70.9pt;margin-bottom:.0001pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>8.1.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>&quot;Session cookies&quot;: These are used to
maintain a so-called 'session state' <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:108.0pt;line-height:115%'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>and only last for the duration of your use of the
Website. A session cookie expires when you close your browser, or if you have
not visited the server for a certain period of time. Session cookies are
required for the website to function optimally. <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:108.0pt;line-height:115%'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p>&nbsp;</o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>8.1.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>“Persistent cookies&quot;: These cookies
permanently store a unique code <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:108.0pt;text-align:justify;line-height:
115%'><span style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>on your computer or smart device hard drive in
order to identify you as an individual user. No Personal Information is stored
in persistent cookies. You can view persistent cookies by looking in the
cookies directory of your browser installation. These persistent cookies are
not required for the Website to work, but may enhance your browsing experience.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;line-height:
115%'><span style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p>&nbsp;</o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
0cm;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>8.1.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>We use cookies and other tracking technologies to
collect information <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:105.25pt;text-align:justify;text-indent:
1.1pt;line-height:115%'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>that
allows us to: <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
34.35pt;line-height:115%;mso-list:l2 level4 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.1.3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Track
site usage and browsing behaviour;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
34.35pt;line-height:115%;mso-list:l2 level4 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.1.3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Allow
you to sign into your account and browse through the <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:142.35pt;text-align:justify;text-indent:
1.65pt;line-height:115%'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>website
<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
34.35pt;line-height:115%;mso-list:l2 level4 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.1.3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Customise
our website’s functionality to you personally by<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:140.7pt;text-align:justify;text-indent:
1.65pt;line-height:115%'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>letting
us remember your preferences <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
34.35pt;line-height:115%;mso-list:l2 level4 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.1.3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Analyse
your use of our products, services or applications;<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
34.35pt;line-height:115%;mso-list:l2 level4 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.1.3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Improve
how our website performs <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
34.35pt;line-height:115%;mso-list:l2 level4 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.1.3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Allow
third parties to provide services to our website <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
34.35pt;line-height:115%;mso-list:l2 level4 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.1.3.7<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Assist
and Monitor the effectiveness of our promotions and <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:142.35pt;text-align:justify;text-indent:
1.65pt;line-height:115%'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>advertising
(including behavioural advertising) and <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;text-indent:
34.35pt;line-height:115%;mso-list:l2 level4 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.1.3.8<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Mitigate
risk enhance security and help prevent fraud. <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:106.35pt;text-align:justify;line-height:
115%'><span style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p>&nbsp;</o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:2.85pt;text-align:justify;text-indent:2.85pt;line-height:115%;
mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'><span
style='mso-spacerun:yes'> </span>Third-Party cookies <o:p></o:p></span></b></span></span></p>

<p style='margin-left:54.0pt;text-indent:-18.0pt;line-height:115%;mso-list:
l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:
OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>8.2.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1'>We use both first-party and third-party
cookies on our website.<o:p></o:p></span></span></span></p>

<p style='margin-left:53.85pt;mso-add-space:auto;text-indent:-18.0pt;
line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>8.2.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1'>First-party cookies are cookies issued
from the https://app.fxflow.co</span></span></span><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:
11.0pt;line-height:115%;color:white;mso-themecolor:background1'> </span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1'>&nbsp;domain that are generally used to
identify language and location preferences or render basic site functionality.<o:p></o:p></span></span></span></p>

<p style='margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;line-height:
115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>8.2.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1'>Third-party cookies belong to and are
managed by other parties, such as <o:p></o:p></span></span></span></p>

<p style='margin-left:72.0pt;mso-add-space:auto;line-height:115%'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
class=SpellE><span style='mso-bidi-font-size:11.0pt;line-height:115%;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'>FXFlow</span></span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1'> Online service providers. <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.0pt;
margin-left:2.85pt;text-align:justify;text-indent:2.85pt;line-height:115%;
mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>8.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><b><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>How
to control cookies <o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-left:54.0pt;text-align:justify;text-indent:
-18.0pt;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Overall,
cookies assist us in providing a better website to you by <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;line-height:
115%'><span style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>allowing us to track which pages you find beneficial
and which you do not. A cookie does not allow us access to your computer or any
information about you other than the information you choose to contribute.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:54.0pt;text-align:justify;text-indent:
-18.0pt;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Some
elements and functionality of our services can only be accessed by using <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;line-height:
115%'><span style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>cookies. By creating a <span class=SpellE>FXFlow</span> account or continuing to use our website, you consent to the use of
cookies as described in this policy. If your browser or browser add-on allows
it, you can refuse our cookies, however this may prevent you from using <span
class=SpellE>FXFlow</span> services.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:54.0pt;text-align:justify;text-indent:
-18.0pt;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-bidi-font-family:Arial;color:white;mso-themecolor:background1;
'>You can choose to reject or the cookies set by </span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;line-height:
115%'><span style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1;'>virtue of your visit
to the <span class=SpellE>FXFlow</span> website by selecting the
“Reject” option on our website, when prompted. The use of the cookies set by <span
class=SpellE>FXFlow</span> is for authorisation and authentication
purposes, and rejection thereof will result on your being logged out.</span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:54.0pt;text-align:justify;text-indent:
-18.0pt;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-bidi-font-family:Arial;color:white;mso-themecolor:background1;
'>Please note that most browsers automatically accept cookies. </span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:72.0pt;text-align:justify;line-height:
115%'><span style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-bidi-font-family:Arial;
color:white;mso-themecolor:background1;'>Therefore, if you do
not wish cookies to be used, you may need to actively delete or block the
cookies. If you reject the use of cookies, you will still be able to visit our
websites but some of the functions may not work correctly. </span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:54.0pt;text-align:justify;text-indent:
-18.0pt;line-height:115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>8.3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-bidi-font-family:Arial;color:white;mso-themecolor:background1;
'>You may also visit&nbsp;www.allaboutcookies.org&nbsp;for
details on how to delete </span></span></span><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;text-align:justify;text-indent:
1.1pt;line-height:115%'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-bidi-font-family:Arial;color:white;mso-themecolor:background1;
'>or reject cookies and for further information on cookies
generally. By using our website without deleting or rejecting some or all
cookies, you agree that we can place those cookies that you have not deleted or
rejected on your device</span></span></span><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:2.0cm;text-align:justify;text-indent:-2.0cm;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Links from the Website<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>The
services available through the Website, may contain links to other third-party
websites, including (without limitation) social media platforms, communication
platforms, payment gateways, appointment scheduling, human resources platforms
and/or live chat platforms (&quot;Third-Party Websites&quot;). If you select a
link to any Third-Party Websites, you may be subject to such Third-Party
Websites’ terms and conditions and/or other policies, which are not under our
control, nor are we responsible therefore.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Hyperlinks
to Third-Party Websites are provided &quot;as is&quot;, and we do not
necessarily agree with, edit or sponsor the content on Third-Party Websites.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
do not monitor or review the content of any Third-Party Websites. Opinions
expressed or material appearing on such websites are not necessarily shared or
endorsed by us and we should not be regarded as the publisher of such opinions
or material. Please be aware that we are not responsible for the privacy
practices, or content, of other websites, either.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>9.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Users
should evaluate the security and trustworthiness of any Third-Party Websites
before disclosing any personal information to them. We do not accept any
responsibility for any loss or damage in whatever manner, howsoever caused,
resulting from your disclosure to third parties of personal information. We are
not responsible, nor will we be held liable for any Third-Party Websites that
contains a virus or malware.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Application of The Electronic Communications and
Transactions Act 25 Of 2002 (&quot;ECT Act&quot;)<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Data
Messages (as defined in the ECT Act) will be deemed to have been received by us
if and when we respond to the Data Messages.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>10.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Data
Messages sent by us to a user will be deemed to have been received by such user
in terms of the provisions specified in section 23(b) of the ECT Act.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>10.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Users
acknowledge that electronic signatures, encryption and/or authentication are
not required for valid electronic communications between us and users.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>10.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Users
warrant that Data Messages sent to us from any electronic device, used by such
user, from time to time or owned by such user, were sent and or authorised by
such user, personally.<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;mso-add-space:auto;text-align:
justify;text-indent:-70.9pt;line-height:115%;mso-list:l2 level1 lfo1'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Contact
Information<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;mso-add-space:auto;text-align:
justify;line-height:115%'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><b><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'><span
style='mso-tab-count:1'> </span></span></b></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:70.9pt;mso-add-space:auto;text-align:
justify;text-indent:-70.9pt;line-height:115%;mso-list:l2 level2 lfo1'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:
bold'><span style='mso-list:Ignore'>11.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>For
the purposes of the ECT Act, <span class=SpellE>FXFlow’s</span> information
is as follows, which should <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;line-height:115%'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>be read in conjunction with its product descriptions
and other terms and conditions contained herein: <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-left:53.85pt;mso-add-space:auto;text-align:
justify;text-indent:-18.0pt;line-height:115%;mso-list:l2 level3 lfo1'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:Arial;
mso-bidi-font-family:Arial;color:white;mso-themecolor:background1'><span
style='mso-list:Ignore'>11.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;ø
</span></span></span><![endif]><b><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>Full
name:</span></b></span></span><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>
<span class=SpellE>FX Flow</span> (Pty) Ltd, a private company registered in
South Africa with registration number 2017/040046/07<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='text-align:justify;line-height:115%'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p>&nbsp;</o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:
115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>11.1.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></span><![endif]><b><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Physical address for receipt of legal service (also
postal and street address)</span></b></span></span><span style='mso-bookmark:
OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>: 8a Jellicoe Avenue
Rosebank,
Johannesburg,
2196,
South Africa <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:
115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>11.1.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></span><![endif]><b><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Office bearers: <o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:
115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>11.1.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></span><![endif]><b><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Contact Number:</span></b></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'> +2711 042 7182<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:
115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>11.1.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></span><![endif]><b><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Website located at:</span></b></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'> </span><a name="bookmark=id.gjdgxs"></a><span
style='color:white;mso-themecolor:background1'>https://app.fxflow.co</span></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'><o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:
115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>11.1.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></span><![endif]><b><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Email address:</span></b></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'> info@fxflow.co<o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:54.0pt;text-align:justify;text-indent:-18.0pt;line-height:
115%;mso-list:l2 level3 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>11.1.7<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></span><![endif]><b><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>PAIA Manual:</span></b></span></span><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'> the manual published in terms of section 51 of the
Promotion of Access to Information Act 2000 may be downloaded from here. <o:p></o:p></span></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level1 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><b><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1'><span style='mso-list:Ignore'>12.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
style='mso-bidi-font-size:11.0pt;line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:white;
mso-themecolor:background1'>Lodging of Complaints<o:p></o:p></span></b></span></span></p>

<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:70.9pt;text-align:justify;text-indent:-70.9pt;line-height:
115%;mso-list:l2 level2 lfo1'><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><![if !supportLists]><span style='mso-bidi-font-size:
11.0pt;line-height:115%;mso-fareast-font-family:Arial;mso-bidi-font-family:
Arial;color:white;mso-themecolor:background1;mso-bidi-font-weight:bold'><span
style='mso-list:Ignore'>12.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
line-height:115%;mso-fareast-font-family:"Helvetica Neue Light";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:white;mso-themecolor:background1'>We
shall only process your Personal Information obtained by us as a result of your
use of the Website in compliance with this Privacy Policy and in accordance
with the relevant applicable data protection laws. If, however you wish to
raise a complaint regarding the processing of your Personal Information or are
unsatisfied with how we have handled your Personal Information, you have the
right to lodge a complaint with the Information Regulator (South Africa), or
such other supervisory authority which may have jurisdiction in relation to the
processing of your Personal Information in the applicable instance.<o:p></o:p></span></span></span></p>

<p class=Body style='tab-stops:1.0cm 2.0cm 3.0cm 4.0cm 5.0cm 6.0cm 7.0cm 8.0cm 9.0cm 10.0cm 11.0cm 12.0cm 13.0cm 14.0cm 15.0cm 16.0cm'><span
style='mso-bookmark:OLE_LINK13'><span style='mso-bookmark:OLE_LINK12'><span
lang=EN-GB style='font-size:10.0pt;color:white;mso-themecolor:background1;
mso-ansi-language:EN-GB'><o:p>&nbsp;</o:p></span></span></span></p>

<p class=MsoNormal><span style='mso-bookmark:OLE_LINK13'><span
style='mso-bookmark:OLE_LINK12'><span lang=EN-US style='mso-bidi-font-size:
11.0pt;mso-bidi-font-family:Arial;color:white;mso-themecolor:background1;
mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></span></span></p>

<span style='mso-bookmark:OLE_LINK12'></span><span style='mso-bookmark:OLE_LINK13'></span>

<p class=MsoNormal><span style='color:white;mso-themecolor:background1'><o:p>&nbsp;</o:p></span></p>

</div>

</body>

</html>

`;
