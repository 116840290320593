import config from 'react-global-configuration';
import { jsonRPC } from 'utils/jsonrpc';

export type UploadExcelRequest = {
    // base64 encoded XLSX file
    data: string;
};

export type UploadExcelResponse = {
    /* empty */
};

export type DownloadExcelTemplateRequest = {
    /* empty */
};

export type DownloadExcelTemplateResponse = {
    // base64 encoded XLSX file
    data: string;
};

export const Uploader = {
    ServiceProviderName: 'TradingDayException-Uploader',
    downloadExcelTemplate(request: DownloadExcelTemplateRequest): Promise<DownloadExcelTemplateResponse> {
        return jsonRPC<DownloadExcelTemplateRequest, DownloadExcelTemplateResponse>({
            url: config.get('apiURL'),
            method: `${Uploader.ServiceProviderName}.DownloadExcelTemplate`,
            request,
        });
    },
    uploadExcel(request: UploadExcelRequest): Promise<UploadExcelResponse> {
        return jsonRPC<UploadExcelRequest, UploadExcelResponse>({
            url: config.get('apiURL'),
            method: `${Uploader.ServiceProviderName}.UploadExcel`,
            request,
        });
    },
};
