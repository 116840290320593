import { Trade } from 'popcorn-js/tradeV2';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Divider, Typography } from '@material-ui/core';
import { ComponentLevelError } from 'components/Error/Error';
import { AppContext, AppContextT } from 'context';
import { CurrencyPair } from 'popcorn-js/currencyPair';
import { Criteria, CriteriaType } from 'popcorn-js/search';
import { Handler as TradeHandler } from 'popcorn-js/tradeV2/handler';
import { HistoryLayout } from 'components/history/HistoryLayout';
import { FieldType, ItemEntry } from 'components/history';
import { SystemDateTimeFormat } from 'constants/formats';
import { displayDate, processUnixDateForViewing } from 'utils';

export const History = (props: { trade?: Trade; onHide?: () => void; open: boolean }): ReactElement => {
    if (!props.trade) {
        return (
            <div>
                <Typography color={'error'}>
                    <b>Error</b>
                </Typography>
                <ComponentLevelError errorMessage={'no trade provided '} />;
            </div>
        );
    }
    const appContext = useContext<AppContextT>(AppContext);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [trade, setTrade] = useState<Trade>(props.trade);
    const [history, setHistory] = useState<Trade[]>([]);

    const currencyPairs = appContext.currencyPairs || ([] as CurrencyPair[]);

    useEffect(() => {
        if (props.trade) {
            load(props.trade).finally();
        }
    }, [props.trade]);

    const load = async (i: Trade) => {
        try {
            setIsLoading(true);
            const _history = await retrieveHistory(i);
            setTrade(modifyFields(i));
            setHistory(modifyMultiple(_history));
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    const retrieveHistory = async (trd: Trade): Promise<Trade[]> => {
        const criteria: Criteria = [{ type: CriteriaType.ExactCriterion, field: 'id', text: trd.id }];
        const retrieveResponse = await TradeHandler.RetrieveHistory({
            criteria: criteria,
        });
        return retrieveResponse.history;
    };

    const modifyMultiple = (trades: Trade[]): Trade[] => {
        return trades.map((t: Trade) => modifyFields(t));
    };

    const modifyFields = (trade: Trade): Trade => {
        return {
            ...trade,
            currencyPair: (currencyPairs.find((c: CurrencyPair) => c.name === trade.currencyPair) || {}).name,
        };
    };

    return (
        <HistoryLayout
            entity={trade}
            entityFields={TradeFields}
            entityHistory={history}
            entityName={'Trade'}
            loading={isLoading}
            onHide={props.onHide}
            open={props.open}
        />
    );
};

const styles = {
    root: {
        flexGrow: 1,
        rowGap: '8px',
        boxShadow: '',
        zIndex: 1,
    },
};

export const TradeFields = (trade: Trade, fieldType: FieldType, changedFields = [] as string[]): ReactElement => {
    return (
        <div>
            <div style={styles.root}>
                <ItemEntry
                    changed={changedFields.includes('number')}
                    fieldType={fieldType}
                    label={'Trade number'}
                    value={trade.number || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('externalReference')}
                    fieldType={fieldType}
                    label={'External Reference'}
                    value={trade.externalReference || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('tradingPartyCode')}
                    fieldType={fieldType}
                    label={'Trading Party'}
                    value={trade.tradingPartyCode}
                />
                <ItemEntry
                    changed={changedFields.includes('cancellation')}
                    fieldType={fieldType}
                    label={'Cancellation'}
                    value={trade.cancellation}
                />
                <ItemEntry
                    changed={changedFields.includes('transactionID')}
                    fieldType={fieldType}
                    label={'transactionID'}
                    value={trade.transactionID}
                />
                <ItemEntry
                    changed={changedFields.includes('type')}
                    fieldType={fieldType}
                    label={'type'}
                    value={trade.type}
                />
                <ItemEntry
                    changed={changedFields.includes('date')}
                    fieldType={fieldType}
                    label={'Date'}
                    value={displayDate((trade.captureDate as unknown) as string)}
                />
                <ItemEntry
                    changed={changedFields.includes('status')}
                    fieldType={fieldType}
                    label={'Trade Status'}
                    value={trade.status}
                />
                <ItemEntry
                    changed={changedFields.includes('currencyPair')}
                    fieldType={fieldType}
                    label={'Currency Pair'}
                    value={trade.currencyPair}
                />
                <ItemEntry
                    changed={changedFields.includes('importExport')}
                    fieldType={fieldType}
                    label={'Import/Export'}
                    value={trade.importExport}
                />
                <ItemEntry
                    changed={changedFields.includes('acm')}
                    fieldType={fieldType}
                    label={'acm'}
                    value={trade.acm}
                />
                <ItemEntry
                    changed={changedFields.includes('financialYear')}
                    fieldType={fieldType}
                    label={'Financial Year'}
                    value={trade.financialYear}
                />
                <ItemEntry
                    changed={changedFields.includes('bank')}
                    fieldType={fieldType}
                    label={'bank'}
                    value={trade.bank}
                />
                <ItemEntry
                    changed={changedFields.includes('direction')}
                    fieldType={fieldType}
                    label={'direction'}
                    value={trade.direction}
                />
                <ItemEntry
                    changed={changedFields.includes('maturityDate')}
                    fieldType={fieldType}
                    label={'maturityDate'}
                    value={displayDate((trade.maturityDate as unknown) as string)}
                />
                <ItemEntry
                    changed={changedFields.includes('tradeDate')}
                    fieldType={fieldType}
                    label={'tradeDate'}
                    value={displayDate((trade.tradeDate as unknown) as string)}
                />
                <ItemEntry
                    changed={changedFields.includes('availableBalance')}
                    fieldType={fieldType}
                    label={'availableBalance'}
                    value={trade.availableBalance}
                />
                <ItemEntry
                    changed={changedFields.includes('ACMBalance')}
                    fieldType={fieldType}
                    label={'ACMBalance'}
                    value={trade.acmBalance}
                />
                <ItemEntry
                    changed={changedFields.includes('capturedSpotRate')}
                    fieldType={fieldType}
                    label={'capturedSpotRate'}
                    value={trade.capturedSpotRate}
                />
                <ItemEntry
                    changed={changedFields.includes('interbankRate')}
                    fieldType={fieldType}
                    label={'interbankRate'}
                    value={trade.interbankRate}
                />
                <ItemEntry
                    changed={changedFields.includes('bankRate')}
                    fieldType={fieldType}
                    label={'bankRate'}
                    value={trade.bankRate}
                />
                <ItemEntry
                    changed={changedFields.includes('quoteAmount')}
                    fieldType={fieldType}
                    label={'quoteAmount'}
                    value={trade.quoteAmount}
                />
                <ItemEntry
                    changed={changedFields.includes('notionalAmount')}
                    fieldType={fieldType}
                    label={'notionalAmount'}
                    value={trade.notionalAmount}
                />
                <ItemEntry
                    changed={changedFields.includes('spotPrice')}
                    fieldType={fieldType}
                    label={'spotPrice'}
                    value={trade.spotPrice}
                />
                <ItemEntry
                    changed={changedFields.includes('forwardPoints')}
                    fieldType={fieldType}
                    label={'forwardPoints'}
                    value={trade.forwardPoints}
                />
                <ItemEntry
                    changed={changedFields.includes('dealRate')}
                    fieldType={fieldType}
                    label={'dealRate'}
                    value={trade.dealRate}
                />
                <ItemEntry
                    changed={changedFields.includes('effectiveRate')}
                    fieldType={fieldType}
                    label={'effectiveRate'}
                    value={trade.effectiveRate}
                />
                <ItemEntry
                    changed={changedFields.includes('netCashFlow')}
                    fieldType={fieldType}
                    label={'netCashFlow'}
                    value={trade.netCashFlow}
                />
                <ItemEntry
                    changed={changedFields.includes('realisedPnl')}
                    fieldType={fieldType}
                    label={'realisedPnl'}
                    value={trade.realisedPnl}
                />
                <ItemEntry
                    changed={changedFields.includes('trader')}
                    fieldType={fieldType}
                    label={'trader'}
                    value={trade.trader}
                />
                <ItemEntry
                    changed={changedFields.includes('traderOrganisation')}
                    fieldType={fieldType}
                    label={'traderOrganisation'}
                    value={trade.traderOrganisation}
                />
                <ItemEntry
                    changed={changedFields.includes('portfolioManager')}
                    fieldType={fieldType}
                    label={'portfolioManager'}
                    value={trade.portfolioManager}
                />
                <ItemEntry
                    changed={changedFields.includes('relationshipManager')}
                    fieldType={fieldType}
                    label={'relationshipManager'}
                    value={trade.relationshipManager}
                />
                <ItemEntry
                    changed={changedFields.includes('notes')}
                    fieldType={fieldType}
                    label={'notes'}
                    value={trade.notes}
                />
                <Divider
                    light
                    style={{
                        height: '2px',
                        margin: '20px 20px',
                    }}
                />

                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(trade.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(trade.auditEntry || {}).action || '-'}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={
                        (trade.auditEntry || {}).time
                            ? processUnixDateForViewing((trade.auditEntry || {}).time, SystemDateTimeFormat)
                            : ''
                    }
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(trade.auditEntry || {}).version || 0}
                />
            </div>
        </div>
    );
};
