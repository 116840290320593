import React, { ReactElement } from 'react';
import { FieldType, Header, ItemEntry } from 'components/history';
import { Divider } from '@material-ui/core';
import { processUnixDateForViewing } from 'utils';
import { SystemDateFormat, SystemDateTimeFormat } from 'constants/formats';
import { OrderH } from './History';
import moment from 'moment';

const styles = {
    root: {
        display: 'grid',
        flexGrow: 1,
        rowGap: '2px',
        boxShadow: '',
        // paddingTop: '25px',
        zIndex: 1,
        gridTemplateRows: (() => {
            let space = '';
            for (let i = 0; i < 20; i++) {
                space = space + '32px ';
            }
            return space;
        })(),
    },
    divider: {
        height: '2px',
        margin: '20px 20px',
    },
    subDivider: {
        height: '1px',
        margin: '16px 40px',
    },
};

export const OrderFields = (order: OrderH, fieldType: FieldType, changedFields = [] as string[]): ReactElement => {
    return (
        <div>
            <main style={styles.root}>
                <Header fieldType={fieldType} label={'Order Information'} />
                {/**
         the search field used in the 'changedFields.includes()'of ItemEntry has
         to be the nested json key of the field, as it is used to compare fields
         eg. contactDetail.physicalAddress.line1
         **/}
                <ItemEntry
                    changed={changedFields.includes('type')}
                    fieldType={fieldType}
                    label={'type'}
                    value={order.type || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('name')}
                    fieldType={fieldType}
                    label={'Number'}
                    value={order.number || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('counterpartyId')}
                    fieldType={fieldType}
                    label={'Counterparty'}
                    value={order.counterpartyName || order.counterpartyId || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('originalAmountDue')}
                    fieldType={fieldType}
                    label={'Original Amount Due'}
                    value={order.originalAmountDue || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('exposureBalance')}
                    fieldType={fieldType}
                    label={'Exposure Balance'}
                    value={order.exposureBalance || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('invoicedAmount')}
                    fieldType={fieldType}
                    label={'Invoiced Amount'}
                    value={order.invoicedAmount || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('currency')}
                    fieldType={fieldType}
                    label={'Currency'}
                    value={order.currencyIsoCode || order.currency || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('costCurrency')}
                    fieldType={fieldType}
                    label={'Cost Currency'}
                    value={order.costCurrencyIsoCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('costingRate')}
                    fieldType={fieldType}
                    label={'Costing Rate'}
                    value={order.costingRate || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('captureRate')}
                    fieldType={fieldType}
                    label={'Capture Rate'}
                    value={order.captureRate || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('captureSpotRate')}
                    fieldType={fieldType}
                    label={'Capture Spot Rate'}
                    value={order.captureSpotRate}
                />
                <ItemEntry
                    changed={changedFields.includes('originalCaptureRate')}
                    fieldType={fieldType}
                    label={'Original Capture Rate'}
                    value={order.originalCaptureRate}
                />
                <ItemEntry
                    changed={changedFields.includes('captureDate')}
                    fieldType={fieldType}
                    label={'Capture Date'}
                    value={moment(order.captureDate).format(SystemDateFormat)}
                />
                <ItemEntry
                    changed={changedFields.includes('dueDate')}
                    fieldType={fieldType}
                    label={'Due Date'}
                    value={processUnixDateForViewing(order.dueDate, SystemDateTimeFormat)}
                />
                <ItemEntry
                    changed={changedFields.includes('shippingDate')}
                    fieldType={fieldType}
                    label={'Shipping Date'}
                    value={processUnixDateForViewing(order.shippingDate, SystemDateTimeFormat)}
                />
                <ItemEntry
                    changed={changedFields.includes('shipmentReference')}
                    fieldType={fieldType}
                    label={'Shipment Reference'}
                    value={order.shipmentReference}
                />
                <ItemEntry
                    changed={changedFields.includes('notes')}
                    fieldType={fieldType}
                    label={'Notes'}
                    value={order.notes || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('status')}
                    fieldType={fieldType}
                    label={'Status'}
                    value={order.status || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('partyCode')}
                    fieldType={fieldType}
                    label={'Party Code'}
                    value={order.partyCode || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('issueDate')}
                    fieldType={fieldType}
                    label={'Issue Date'}
                    value={processUnixDateForViewing(order.issueDate, SystemDateTimeFormat)}
                />
                <Divider style={styles.divider} />
                <Header fieldType={fieldType} label={'Audit Entry'} />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.username')}
                    fieldType={fieldType}
                    label={'Username'}
                    value={(order.auditEntry || {}).username || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.action')}
                    fieldType={fieldType}
                    label={'Action'}
                    value={(order.auditEntry || {}).action || ''}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.time')}
                    fieldType={fieldType}
                    label={'Time'}
                    value={processUnixDateForViewing((order.auditEntry || {}).time, SystemDateTimeFormat)}
                />
                <ItemEntry
                    changed={changedFields.includes('auditEntry.version')}
                    fieldType={fieldType}
                    label={'Version'}
                    value={(order.auditEntry || {}).version}
                />
            </main>
        </div>
    );
};
