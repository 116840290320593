/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHelperText, Grid, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { HexToRGBA } from 'utils';

const useStyles = makeStyles((theme) => ({
    root: {
        color: HexToRGBA(theme.palette.text.primary, 0.5),
    },
}));

export const ItemWrapper = (props: {
    label: string;
    children?: any;
    field?: string;
    input?: boolean;
    validationErrors?: any[];
    textArea?: boolean;
    value?: string | number;
}): ReactElement => {
    const classes = useStyles();
    const fieldError = props.validationErrors
        ? props.validationErrors.find((fe) => fe.field === props.field)
        : undefined;
    return (
        <Grid className={classes.root} item>
            <div
                style={{
                    display: 'grid',
                    gridGap: '20px',
                    gridTemplateColumns: '170px 1fr',
                    marginLeft: '40px',
                    marginRight: '40px',
                    alignItems: 'center',
                    justifyItems: 'end',
                }}
            >
                <div>{props.label}</div>
                <div>
                    <div aria-describedby={props.label} style={{ width: '170px' }}>
                        {props.children}
                        {fieldError && (
                            <FormHelperText error id="helperText">
                                {fieldError.reason}
                            </FormHelperText>
                        )}
                    </div>
                </div>
            </div>
        </Grid>
    );
};
