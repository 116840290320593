import React, { ChangeEvent, ReactElement, useContext } from 'react';
import { makeStyles, Card, MenuItem, FormControl } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { TradeValues, Actions } from './index';
import { LightCheckbox, LightFormControlLabel, LightSelect, LightTextField } from './styledComponents';
import { seasons } from 'popcorn-js';
import { AppContext, AppContextT } from 'context';
import { Client } from 'popcorn-js/party';

export const TradeReferences = ({
    uuid,
    trade,
    tradeDispatch,
    autoFillMode,
    optionExercise,
    enableACMOPtion,
}: {
    uuid: string;
    trade: TradeValues;
    tradeDispatch: Actions;
    autoFillMode: boolean;
    optionExercise: boolean;
    enableACMOPtion?: boolean;
}): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const clientProduct = (appContext.party as Client)?.products;

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelDark}>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Season'}
                            disabled={autoFillMode}
                            value={trade.season}
                            style={{ width: '100%' }}
                            onChange={(
                                event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) => tradeDispatch.setSeason(uuid, event.target.value as string)}
                        >
                            {seasons.map(
                                (s) =>
                                    s && (
                                        <MenuItem key={s} value={s}>
                                            {s}
                                        </MenuItem>
                                    ),
                            )}
                            <MenuItem key={'b'} value={''}>
                                {'blank'}
                            </MenuItem>
                        </LightSelect>
                    </FormControl>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Product'}
                            style={{ width: '100%' }}
                            value={trade.product}
                            onChange={(
                                event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) => {
                                tradeDispatch.setProduct(uuid, event.target.value as string);
                            }}
                            disabled={(clientProduct?.length ? false : true) || autoFillMode}
                        >
                            <MenuItem key={'n'} value={''}>
                                {'None'}
                            </MenuItem>
                            {(clientProduct || []).map(
                                (p) =>
                                    p && (
                                        <MenuItem key={p} value={p}>
                                            {p}
                                        </MenuItem>
                                    ),
                            )}
                        </LightSelect>
                    </FormControl>
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightTextField
                        label={'Client Reference'}
                        disabled={autoFillMode}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setClientReference(uuid, event.target.value)
                        }
                        value={trade.clientReference}
                    />
                    <LightTextField
                        label={'Client Notes'}
                        disabled={autoFillMode}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setClientNotes(uuid, event.target.value)
                        }
                        value={trade.clientNotes}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightTextField
                        label={'Option Reference'}
                        disabled={autoFillMode || optionExercise}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.changeOptionReference(uuid, event.target.value)
                        }
                        value={trade.optionReference}
                    />
                    <LightFormControlLabel
                        disabled={autoFillMode || !enableACMOPtion}
                        control={
                            <LightCheckbox
                                checked={trade.acm}
                                name="acmTrade"
                                onChange={() => tradeDispatch.setACM(uuid, !trade.acm)}
                            />
                        }
                        label="ACM Trade"
                    />
                </div>
            </div>
        </Card>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    detailsPanelDark: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper2,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
}));
