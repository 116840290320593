import { Identifier, marshalIdentifier } from 'popcorn-js/search/identifier';
import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { Invoice } from '.';
import { FindRequest, FindResponse, RetrieveHistoryRequest, RetrieveHistoryResponse } from 'popcorn-js';
import { Criteria } from 'popcorn-js/search';

export type DeleteRequest = {
    identifier?: Identifier | string;
};
export type DeleteResponse = {
    invoice: Invoice;
};
export type CreateRequest = {
    invoice: Invoice;
};
export type CreateResponse = {
    invoice: Invoice;
};
export type CreateBatchRequest = {
    invoices: Invoice[];
};
export type CreateBatchResponse = {
    succeeded: Invoice[];
    failed: Invoice[];
};
export type DeleteForeverRequest = {
    identifier?: Identifier | string;
};
export type DeleteForeverResponse = {
    invoice: Invoice;
};
export type RetrieveRequest = {
    identifier?: Identifier | string;
    deleted?: boolean;
};
export type RetrieveResponse = {
    invoice: Invoice;
};
export type RestoreRequest = {
    identifier?: Identifier | string;
};
export type RestoreResponse = {
    invoice: Invoice;
};
export type UpdateRequest = {
    identifier?: Identifier | string;
    invoice?: Invoice;
};
export type UpdateResponse = {
    invoice: Invoice;
};
export type Update = {
    identifier?: Identifier | string;
    invoice?: Invoice;
};
export type UpdateBatchRequest = {
    updates: Update[];
};
export type UpdateBatchResponse = {
    succeeded: Invoice[];
    failed: Invoice[];
};
export type Duplicate = {
    oldInvoice: Invoice;
    newInvoice: Invoice;
};
export type InvalidReason = {
    reason: string;
    field: string;
    data: unknown;
};
export type Invalid = {
    invoice: Invoice;
    reasons: InvalidReason[];
};
export type ValidateBatchRequest = {
    invoices?: Invoice[];
};
export type ValidateBatchResponse = {
    duplicate?: Duplicate[];
    deleted?: Duplicate[];
    invalid?: Invalid[];
    unique?: Invoice[];
};
export type ValidateRequest = {
    invoice?: Invoice;
};
export type InvalidInvoice = {
    invoice?: Invoice;
    reasons?: InvalidReason[];
};
export type ReasonInvalid = {
    reason: string;
    field: string;
};
export type ValidateResponse = {
    reasons?: ReasonInvalid[];
};
export type DeleteBatchRequest = {
    externalReferences?: string[];
};
export type DeleteBatchResponse = {
    invoices: Invoice[];
};

export type DeleteBatch = {
    identifier?: Identifier | string;
    invoice?: Invoice;
};

export type DeleteBatchForeverRequest = {
    deleteBatch: DeleteBatch[];
};
export type DeleteBatchForeverResponse = {
    invoices: Invoice[];
};

export type RestoreBatch = {
    identifier?: Identifier | string;
    invoice?: Invoice;
};

export type RestoreBatchRequest = {
    restoreBatch: RestoreBatch[];
};
export type RestoreBatchResponse = {
    invoices: Invoice[];
};
export const Recordkeeper = {
    ServiceProviderName: 'Invoice-Recordkeeper',
    find(request: FindRequest): Promise<FindResponse<Invoice>> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<FindRequest, FindResponse<Invoice>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Find`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
    retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RetrieveRequest, RetrieveResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Retrieve`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    delete(request: DeleteRequest): Promise<DeleteResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<DeleteRequest, DeleteResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Delete`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    create(request: CreateRequest): Promise<CreateResponse> {
        return jsonRPC<CreateRequest, CreateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Create`,
            request: { ...request },
        });
    },
    createBatch(request: CreateBatchRequest): Promise<CreateBatchResponse> {
        return jsonRPC<CreateBatchRequest, CreateBatchResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.CreateBatch`,
            request: { ...request },
        });
    },
    validate(request: ValidateRequest): Promise<ValidateResponse> {
        return jsonRPC<ValidateRequest, ValidateResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Validate`,
            request: { ...request },
        });
    },
    restore(request: RestoreRequest): Promise<RestoreResponse> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RestoreRequest, RestoreResponse>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.Restore`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
    retrieveHistory(request: RetrieveHistoryRequest): Promise<RetrieveHistoryResponse<Invoice>> {
        const serializedIdentifier = request.identifier
            ? marshalIdentifier(request.identifier as Identifier)
            : undefined;
        return jsonRPC<RetrieveHistoryRequest, RetrieveHistoryResponse<Invoice>>({
            url: config.get('apiURL'),
            method: `${Recordkeeper.ServiceProviderName}.RetrieveHistory`,
            request: {
                ...request,
                identifier: serializedIdentifier,
            },
        });
    },
};
