/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react';
import { DropTarget } from 'react-dnd';

const listItemHolderTarget = {
    canDrop(props: any) {
        return !props.children;
    },

    drop(props: any, monitor: any) {
        const { onItemDrop } = props;
        onItemDrop && onItemDrop(monitor.getItem());
    },
};

function collect(connect: any, monitor: any) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    };
}

const ListItemHolder = (props: {
    onItemDrop: (props: any) => void;
    connectDropTarget?: (props: any) => ReactElement;
    holderHeight?: number;
    isOver?: boolean;
    children?: ReactElement;
}): ReactElement => {
    const { connectDropTarget, isOver, holderHeight } = props;

    if (props.children) {
        return connectDropTarget ? connectDropTarget(<div>{props.children}</div>) : <div />;
    } else {
        return connectDropTarget ? (
            connectDropTarget(
                <div
                    style={{
                        padding: holderHeight ? holderHeight : isOver ? 13 : 4,
                    }}
                />,
            )
        ) : (
            <div />
        );
    }
};

const StyledListItemHolder = DropTarget('listItem', listItemHolderTarget, collect)(ListItemHolder);

export default StyledListItemHolder;
