import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from 'theme/custom';
import { HexToRGBA } from 'utils';

type CreateSwapLinkProps = {
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    text: string;
};

const CreateSwapLink = (props: CreateSwapLinkProps): React.ReactElement => {
    const classes = useStyles();

    return (
        <div onClick={(e) => (props.onClick ? props.onClick(e) : undefined)} className={classes.swapText}>
            {props.text}
        </div>
    );
};

export default CreateSwapLink;

const useStyles = makeStyles((theme: CustomTheme) => ({
    swapText: {
        justifySelf: 'center',
        alignSelf: 'right',
        fontSize: '12px',
        opacity: 0.6,
        color: theme.palette.text.secondary,
        borderBottom: `0.5px solid ${HexToRGBA(theme.palette.text.secondary, 0.1)}`,
        '&:hover': {
            cursor: 'pointer',
            opacity: 1,
        },
        margin: '16px 0px 16px 35px',
    },
}));
