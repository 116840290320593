/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { ReactElement } from 'react';

// ComponentLevelError is an Error icon than can be implemented nested within components. It does not render over the whole of the viewport.
// 100% width and default height of 200px and font size of 80
const ComponentLevelError = (props: { errorMessage: string | undefined; style?: any }): ReactElement => {
    return (
        <div
            style={{
                height: '400px',
                ...props.style,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr auto',
                alignItems: 'center',
                justifyItems: 'center',
                textAlign: 'center',
            }}
        >
            <div>
                <ErrorIcon
                    style={{
                        fontSize: 80,
                    }}
                />
                <Typography variant={'overline'}>{props.errorMessage || 'Whoops.. Something went wrong'}</Typography>
            </div>
        </div>
    );
};

export { ComponentLevelError };
