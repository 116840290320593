import React, { ReactElement } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { CustomTheme } from 'theme/custom';
import { makeStyles } from '@material-ui/core';
import { Info as HelpIcon } from '@material-ui/icons';
import LightTooltip from 'views/Client/LightTooltip';

export type GraphLegendItemProps = {
    icon: 'orange' | 'teal' | 'red' | 'yellow';
    align?: 'left' | 'right' | 'center';
    title?: string;
    value?: number | string;
    tooltipTitle: string;
    width?: string;
};

export const GraphLegendItem = (props: GraphLegendItemProps): ReactElement => {
    const theme = useTheme<CustomTheme>();
    const classes = useStyles();
    const commonIconProps = {
        height: '14px',
        width: '14px',
        borderRadius: '50%',
    };
    const icons = {
        orange: {
            ...commonIconProps,
            backgroundColor: theme.palette.custom.import.main,
        },
        teal: {
            ...commonIconProps,
            backgroundColor: theme.palette.custom.export.main,
        },
        red: {
            ...commonIconProps,
            backgroundColor: theme.palette.custom.data.graphB,
        },
        yellow: {
            ...commonIconProps,
            backgroundColor: theme.palette.custom.data.graphC,
        },
    };

    return (
        <div
            style={{
                display: 'flex',
                width: props.width || '',
            }}
        >
            <div
                style={{
                    padding: theme.spacing(1),
                    flexGrow: 1,
                    display: 'flex',
                }}
            >
                <div style={{ ...icons[props.icon] }} />
                <div className={classes.title}>{props.title}</div>
                <div className={classes.value}>
                    {props.value}
                    <div className={classes.gap}>
                        <LightTooltip placement={'bottom-end'} title={props.tooltipTitle}>
                            <span className={classes.helpIconWrapper}>
                                <HelpIcon className={classes.helpIcon} />
                            </span>
                        </LightTooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    helpIconWrapper: {
        width: '14px',
        height: '14px',
        color: theme.palette.grey[200],
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.text.primary,
        },
        alignItems: 'left',
    },
    helpIcon: {
        width: '14px',
        height: '14px',
    },
    title: {
        fontSize: '14px',
        fontWeight: 'bold',
        marginRight: '10px',
        width: '145px',
        paddingLeft: '10px',
        alignItems: 'left',
    },
    value: {
        fontSize: '14px',
        marginRight: '5px',
        width: '115px',
        alignItems: 'right',
        display: 'flex',
    },
    gap: {
        marginLeft: '10px',
        alignItems: 'left',
    },
}));
