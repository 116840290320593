import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { Dialog, IconButton, makeStyles, Step, StepButton, Stepper, Tooltip } from '@material-ui/core';
import Table from 'components/Table/Table';
import { CustomTheme } from 'theme/custom';
import { BaseAppBar } from 'components/BaseAppBar/BaseAppBar';
import { FullPageLoader as Loader } from 'components/Loader/FullPageLoader';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { BaseButton, COLOR, SIZE, VARIANT } from 'components/BaseButton';
import { Description, Info } from '@material-ui/icons';
import { File as LocalFile } from 'components/upload';
import { InvalidTradeConfirmation, TradeConfirmation } from 'popcorn-js/tradeV3';
import { translate } from './index';
import { useServiceSync } from 'hooks/useService';
import {
    CreateTradeConfirmationsRequest,
    CreateTradeConfirmationsResponse,
    DefaultConfirmationHandler,
    ValidateBatchRequest,
    ValidateBatchResponse,
} from 'popcorn-js/tradeV3/confirmationHandler';
import { StandardCard } from 'components/Card/Card';
import { ITEM_VARIATION } from 'components/CardHeader/StandardCardHeader';
import { SystemDateFormat } from 'constants/formats';
import moment from 'moment';
import { displayAmount } from 'utils';
import { Task } from '@mui/icons-material';
import NotificationSweetAlert from 'components/Notification/NotificationSweetAlert';

export const ConfirmationsUploader = ({ show, onClose }: { show: boolean; onClose: () => void }): ReactElement => {
    const classes = useStyles();
    const fileRef = useRef<HTMLInputElement>(null);
    const triggerInputClick = () => fileRef?.current?.click();

    const [validConfirmations, setValidConfirmations] = useState<TradeConfirmation[]>([] as TradeConfirmation[]);
    const [errors, setErrors] = useState<InvalidTradeConfirmation[]>([] as InvalidTradeConfirmation[]);
    const [loading, setLoading] = useState<boolean>(false);
    const [state, setState] = useState<string>(State.SelectFile);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [tabValue, setTabValue] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const [validateBatchTradeConfirmations] = useServiceSync<ValidateBatchRequest, ValidateBatchResponse>(
        DefaultConfirmationHandler.ValidateBatchTradeConfirmations,
    );
    const [createBatch] = useServiceSync<CreateTradeConfirmationsRequest, CreateTradeConfirmationsResponse>(
        DefaultConfirmationHandler.CreateTradeConfirmations,
    );

    const handleFileDrop = (acceptedFiles: FileList | null) => {
        setLoading(true);
        if (!acceptedFiles?.length) {
            console.error('no accepted files found');
            setLoading(false);
            return;
        }
        //Find File extension
        const extPos = acceptedFiles[0].name.search(/\./);
        if (extPos < 0) {
            console.error('falied to find file extension');
            setLoading(false);
            return;
        }
        let fileExt: string;
        try {
            fileExt = acceptedFiles[0].name.slice(extPos + 1);
        } catch (e) {
            console.error('failed to get file extension', e);
            setLoading(false);
            return;
        }
        const reader = new FileReader();
        reader.onload = async (event) => {
            const _file: LocalFile = {
                name: acceptedFiles[0].name,
                data: event?.target?.result,
                ext: fileExt,
            };
            try {
                const [confirmations, error] = translate(_file);
                if (error != null) {
                    throw new Error(error);
                }
                const result = await validateBatchTradeConfirmations({
                    confirmations: confirmations,
                });
                setValidConfirmations(result.validConfirmations);
                setErrors(result.invalidConfirmations);
                setState(State.Verify);
            } catch (e) {
                const m = 'failed to parse file';
                console.error(`${m}:`, e);
                setErrorMessage(e.message);
                setState(State.Verify);
            }
            setLoading(false);
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
            return;
        };
        reader.onerror = (err) => {
            setLoading(false);
            const m = 'failed to load file';
            console.error(`${m}:`, err);
            if (fileRef?.current?.files) {
                fileRef.current.files = null;
            }
            throw new Error(m);
        };
        //Start File Read
        reader.readAsBinaryString(acceptedFiles[0]);
    };

    const handleMerge = async () => {
        setLoading(true);
        try {
            await createBatch({
                confirmation: validConfirmations,
            });
            setState(State.Done);
        } catch (e) {
            setErrorMessage(e);
        }
        setLoading(false);
    };

    const checkForErrors = (
        rowData: InvalidTradeConfirmation,
        field: string,
        item: string | number | undefined,
    ): React.SVGProps<SVGTextElement> => {
        let result = <text>{item ? item : '-'}</text>;
        rowData.invalidReasons?.forEach((reason) => {
            if (reason.field === field) {
                result = (
                    <React.Fragment>
                        <text className={classes.errorText}>{item} </text>
                        <Tooltip title={reason.reason}>
                            <IconButton tabIndex={-1} size={'small'}>
                                <Info htmlColor={'red'} />
                            </IconButton>
                        </Tooltip>
                    </React.Fragment>
                );
            }
        });
        return result;
    };

    const handleHideAlert = () => {
        setErrorMessage(undefined);
        setState(State.SelectFile);
    };

    useEffect(() => {
        const idx = Steps.indexOf(state);
        if (idx >= 0) {
            setActiveStep(idx);
        }
    }, [state]);

    return (
        <Dialog onClose={onClose} open={show} scroll={'paper'} fullScreen>
            <BaseAppBar title={'Upload Trade Confirmations'} onClose={onClose} showCloseButton />
            {loading && <Loader />}
            <ErrorBoundary type={'Import'} id={'entityTypeDescription'} errorComponent={<div>Oh no!</div>}>
                <div className={classes.dialogContent} id="importDialogRoot">
                    <Stepper activeStep={activeStep} className={classes.stepper} nonLinear>
                        {Steps.map((label, idx) => {
                            return (
                                <Step key={idx}>
                                    <StepButton completed={activeStep > idx}>{label}</StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div className={classes.stepContentWrapper}>
                        {state === State.SelectFile && (
                            <div className={classes.stepLayoutCenter}>
                                <span className={classes.uploadFileText}>Upload Your File</span>
                                <div className={classes.lightCircle}>
                                    <Description className={classes.fileIcon} />
                                </div>
                                <BaseButton
                                    id={'selectFileBtn'}
                                    width={'150px'}
                                    variant={VARIANT.CONTAINED}
                                    color={COLOR.ACTION}
                                    size={SIZE.MEDIUM}
                                    onClick={triggerInputClick}
                                    text={'Select File'}
                                    marginTop={'25px'}
                                />
                                <input
                                    className={classes.hiddenInput}
                                    ref={fileRef}
                                    type={'file'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFileDrop(event.target.files)
                                    }
                                />
                            </div>
                        )}
                        {state === State.Verify && (
                            <div className={classes.stepLayoutTable}>
                                <StandardCard
                                    cardHeaderProps={{
                                        itemsLeft: [
                                            {
                                                id: 'uploadConfirmationsTabs',
                                                type: ITEM_VARIATION.TABS,
                                                options: (() => {
                                                    if (errors.length > 0 && validConfirmations.length > 0) {
                                                        return [
                                                            {
                                                                id: 'Errors',
                                                                label: `Errors (${errors.length})`,
                                                                value: 'errors',
                                                            },
                                                            {
                                                                id: 'Valid Confirmations',
                                                                label: `Valid Confirmations (${validConfirmations.length})`,
                                                                value: 'validConfirmations',
                                                            },
                                                        ];
                                                    } else if (errors.length > 0) {
                                                        return [
                                                            {
                                                                id: 'Errors',
                                                                label: `Errors (${errors.length})`,
                                                                value: 'errors',
                                                            },
                                                        ];
                                                    } else {
                                                        return [
                                                            {
                                                                id: 'Valid Confirmations',
                                                                label: `Valid Confirmations (${validConfirmations.length})`,
                                                                value: 'validConfirmations',
                                                            },
                                                        ];
                                                    }
                                                })(),
                                                onChange: (_event: ChangeEvent<unknown>, value: number) => {
                                                    setTabValue(value);
                                                },
                                                value: tabValue,
                                            },
                                        ],
                                    }}
                                >
                                    <div>
                                        {((): React.ReactNode => {
                                            return (
                                                <>
                                                    {tabValue === 0 && errors.length > 0 && (
                                                        <Table
                                                            tableID={'errorsTab'}
                                                            disableFooter
                                                            columns={[
                                                                {
                                                                    title: 'External Reference',
                                                                    field: 'externalReference',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'externalReference',
                                                                            rowData.externalReference,
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Bank Client',
                                                                    field: 'bankClient',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'bankClient',
                                                                            rowData.bankClient,
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Trade Date',
                                                                    field: 'tradeDate',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'tradeDate',
                                                                            moment(rowData.tradeDate).format(
                                                                                SystemDateFormat,
                                                                            ),
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'LCY Amount',
                                                                    field: 'lcyAmount.value',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'lcyAmount',
                                                                            displayAmount(rowData.lcyAmount),
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'FX Amount',
                                                                    field: 'fxAmount.value',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'fxAmount',
                                                                            displayAmount(rowData.fxAmount),
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Bank',
                                                                    field: 'bank',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'bank',
                                                                            rowData.bank,
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Spot Price',
                                                                    field: 'spotPrice.value',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'spotPrice',
                                                                            displayAmount(rowData.spotPrice),
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Currency Pair',
                                                                    field: 'currencyPair',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'currencyPair',
                                                                            rowData.currencyPair,
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Maturity Date',
                                                                    field: 'maturityDate',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'maturityDate',
                                                                            moment(rowData.maturityDate).format(
                                                                                SystemDateFormat,
                                                                            ),
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Created Date',
                                                                    field: 'createdDate',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'createdDate',
                                                                            moment(rowData.createdDate).format(
                                                                                SystemDateFormat,
                                                                            ),
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Deal Status',
                                                                    field: 'dealStatusIndicator',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'dealStatusIndicator',
                                                                            rowData.dealStatusIndicator,
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Direction',
                                                                    field: 'direction',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'direction',
                                                                            rowData.direction,
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Financial Year',
                                                                    field: 'financialYear',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'financialYear',
                                                                            rowData.financialYear,
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Parent trade',
                                                                    field: 'parentTradeReference',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'parentTradeReference',
                                                                            rowData.parentTradeReference,
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Notes',
                                                                    field: 'notes',
                                                                    render: (rowData: InvalidTradeConfirmation) => {
                                                                        return checkForErrors(
                                                                            rowData,
                                                                            'notes',
                                                                            rowData.notes,
                                                                        );
                                                                    },
                                                                },
                                                            ]}
                                                            data={errors || []}
                                                        />
                                                    )}
                                                    {(tabValue === 1 || errors.length === 0) && (
                                                        <Table
                                                            tableID={'validTab'}
                                                            disableFooter
                                                            columns={[
                                                                {
                                                                    title: 'External Reference',
                                                                    field: 'externalReference',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.externalReference) {
                                                                            return rowData.externalReference;
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Bank Client',
                                                                    field: 'bankClient',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.bankClient) {
                                                                            return rowData.bankClient;
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Trade Date',
                                                                    field: 'tradeDate',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.tradeDate) {
                                                                            return moment(rowData.tradeDate).format(
                                                                                SystemDateFormat,
                                                                            );
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'LCY Amount',
                                                                    field: 'lcyAmount.value',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.lcyAmount) {
                                                                            return displayAmount(rowData.lcyAmount);
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'FX Amount',
                                                                    field: 'fxAmount.value',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.fxAmount) {
                                                                            return displayAmount(rowData.fxAmount);
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Bank',
                                                                    field: 'bank',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.bank) {
                                                                            return rowData.bank;
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Spot Price',
                                                                    field: 'spotPrice.value',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.spotPrice) {
                                                                            return rowData.spotPrice.toFixed(4);
                                                                        } else {
                                                                            return '0.00';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Currency Pair',
                                                                    field: 'currencyPair',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.currencyPair) {
                                                                            return rowData.currencyPair;
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Maturity Date',
                                                                    field: 'maturityDate',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.maturityDate) {
                                                                            return moment(rowData.maturityDate).format(
                                                                                SystemDateFormat,
                                                                            );
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Created Date',
                                                                    field: 'createdDate',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.createdDate) {
                                                                            return moment(rowData.createdDate).format(
                                                                                SystemDateFormat,
                                                                            );
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Deal Status',
                                                                    field: 'dealStatusIndicator',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.dealStatusIndicator) {
                                                                            return rowData.dealStatusIndicator;
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Direction',
                                                                    field: 'direction',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.direction) {
                                                                            return rowData.direction;
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Financial Year',
                                                                    field: 'financialYear',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.financialYear) {
                                                                            return rowData.financialYear;
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Parent trade',
                                                                    field: 'parentTradeReference',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.parentTradeReference) {
                                                                            return rowData.parentTradeReference;
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    title: 'Notes',
                                                                    field: 'notes',
                                                                    render: (rowData: TradeConfirmation) => {
                                                                        if (rowData.notes) {
                                                                            return rowData.notes;
                                                                        } else {
                                                                            return '-';
                                                                        }
                                                                    },
                                                                },
                                                            ]}
                                                            data={validConfirmations || []}
                                                        />
                                                    )}
                                                </>
                                            );
                                        })()}
                                    </div>
                                </StandardCard>
                                <NotificationSweetAlert errorMessage={errorMessage} onClose={handleHideAlert} />
                                <div className={classes.buttonsWrapper}>
                                    <BaseButton
                                        id={'restartBtn'}
                                        width={'120px'}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        onClick={() => {
                                            setValidConfirmations([]);
                                            setErrors([]);
                                            setState(State.SelectFile);
                                        }}
                                        text={'Restart'}
                                    />
                                    <BaseButton
                                        id={'confirmBtn'}
                                        width={'120px'}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        onClick={handleMerge}
                                        text={'Confirm'}
                                        disabled={errors.length > 0}
                                    />
                                </div>
                            </div>
                        )}
                        {state === State.Done && (
                            <div className={classes.stepLayoutCenter}>
                                <span className={classes.uploadFileText}>Upload Complete</span>
                                <div className={classes.lightCircle}>
                                    <Task className={classes.fileIcon} fontSize={'inherit'} />
                                </div>
                                <div className={classes.buttonsWrapper}>
                                    <BaseButton
                                        id={'uploadMoreBtn'}
                                        width={'150px'}
                                        variant={VARIANT.OUTLINED}
                                        color={COLOR.WHITE}
                                        size={SIZE.MEDIUM}
                                        onClick={() => {
                                            setValidConfirmations([]);
                                            setErrors([]);
                                            setState(State.SelectFile);
                                        }}
                                        text={'Upload More'}
                                    />
                                    <BaseButton
                                        id={'doneBtn'}
                                        width={'120px'}
                                        variant={VARIANT.CONTAINED}
                                        color={COLOR.ACTION}
                                        size={SIZE.MEDIUM}
                                        onClick={onClose}
                                        text={'Done'}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ErrorBoundary>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    dialogContent: {
        backgroundColor: theme.palette.primary.contrastText,
        height: '100%',
        weight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    stepper: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: '5 5 5 5',
    },
    stepContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    stepLayoutCenter: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        rowGap: theme.spacing(2),
    },
    stepLayoutTable: {
        height: 'calc(100vh - 100px)',
        overflowY: 'scroll',
        paddingLeft: '10px',
        paddingRight: '5px',
        justifyItems: 'center',
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingTop: '25px',
        columnGap: theme.spacing(2),
    },
    uploadFileText: {
        fontSize: '34px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.palette.text.primary,
        width: '100%',
    },
    lightCircle: {
        display: 'flex',
        borderRadius: '50%',
        marginTop: '25px',
        marginBottom: '17px',
        backgroundColor: theme.palette.custom.paperExtended.paper3,
        height: '173px',
        width: '173px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 90,
    },
    fileIcon: {
        fontSize: 90,
        color: theme.palette.custom.paperExtended.paper4,
        width: '100%',
    },
    errorText: {
        color: 'red',
    },
    hiddenInput: {
        visibility: 'hidden',
    },
}));

const State = {
    SelectFile: 'Select File',
    Verify: 'Verify',
    Done: 'Done',
};

const Steps = [State.SelectFile, State.Verify, State.Done];
