import React from 'react';
import { processUnixDateForViewing } from 'utils';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, ReferenceLine } from 'recharts';
import { roundScaleAndFormatNumber, calculateTooltipOffset } from 'views/Client/SharedGraphComponents';
import { CustomTooltipSection, CustomTooltipSectionLine, CustomTooltip } from '../RechartsCustomTooltip';
import { useTheme } from '@material-ui/core/styles';
import { Currency } from 'popcorn-js/currency';
import moment from 'moment';
import { Aggregation, GenerateWeekViewExposureReportForCurrencyResponse } from 'popcorn-js/report';
import { CustomTheme } from 'theme/custom';

interface Response {
    data: Array<Record<string, number | string>>;
}

const transformMonthViewData = (
    cashFlowResponse: GenerateWeekViewExposureReportForCurrencyResponse,
    dateFormat: string,
): Response => {
    let data: Array<Record<string, number | string>> = [];

    if (!cashFlowResponse.aggregations) {
        return {
            data,
        };
    }

    data = cashFlowResponse.aggregations.map(function (p: Aggregation): Record<string, number | string> {
        const dateInfo = p.categoryName.split('_');
        const date = moment(`${dateInfo[0]}-${dateInfo[1]}-01`).unix();
        return {
            name: processUnixDateForViewing(date, dateFormat),
            date: date,

            // For Tooltip:  Total purchase exposure
            purchaseExposureTotal: (p.invoiceTotalImport?.total || {}).currencyAmount,

            // For Tooltip:  Total sales exposure
            salesExposureTotal: (p.invoiceTotalExport.total || {}).currencyAmount,

            // Note: Currency breakdown no longer on graph neither in tooltip
            // For Graph: Import Invoice Overdue Series
            invoiceOverdueImport: p.invoiceOverdueImport.total.currencyAmount,
            // For Graph: Export Invoice Overdue Series
            invoiceOverdueExport: p.invoiceOverdueExport.total.currencyAmount,
            // For Graph: Import Invoice Due Series
            invoiceUnRealisedImport: p.invoiceUnRealisedImport.total.currencyAmount,
            // For Graph: Export Invoice Due Series
            invoiceUnRealisedExport: p.invoiceUnRealisedExport.total.currencyAmount,

            // For Tooltip and graph: Import trade open balances
            openTradeBalanceImport: p.openTradeBalanceImport,

            // For Tooltip and graph: Export trade open balances
            openTradeBalanceExport: p.openTradeBalanceExport,
        };
    });

    return {
        data,
    };
};

interface ExposureGraphProps {
    currency: Currency;
    data: GenerateWeekViewExposureReportForCurrencyResponse | undefined;
    width: number;
}

export const ExposureGraph: React.FC<ExposureGraphProps> = (props: ExposureGraphProps) => {
    if (!props.data) {
        return <div />;
    }
    const dateFormat = 'MMM-YY';
    const theme = useTheme<CustomTheme>();
    const graphData = transformMonthViewData(props.data, dateFormat);

    const TickFormatter = (tick: number): string => {
        // return (tick / scaleToNumber(scale)) //.toFixed(0)
        return roundScaleAndFormatNumber(tick || 0, currencyCode);
    };

    const currencyCode = props.currency ? props.currency.symbol : '';
    const tooltip: React.ReactElement = (
        <CustomTooltip
            currency={props.currency}
            valueFormatter={(val: number): string => {
                return roundScaleAndFormatNumber(val || 0, currencyCode);
            }}
        >
            <CustomTooltipSection
                heading={'Purchase Exposure'}
                hideIfZero
                style={{ color: theme.palette.custom.import.main }}
            >
                <CustomTooltipSectionLine dataKey={'purchaseExposureTotal'} heading={'Total'} />
            </CustomTooltipSection>
            <CustomTooltipSection
                heading={'Sales Exposure'}
                hideIfZero
                style={{ color: theme.palette.custom.export.main }}
            >
                <CustomTooltipSectionLine dataKey={'salesExposureTotal'} heading={'Total'} />
            </CustomTooltipSection>
            <CustomTooltipSection
                heading={'FECs Maturing'}
                hideIfZero
                style={{ color: theme.palette.custom.data.graphC }}
            >
                <CustomTooltipSectionLine dataKey={'openTradeBalanceImport'} heading={'Imports'} />
                <CustomTooltipSectionLine dataKey={'openTradeBalanceExport'} heading={'Exports'} />
            </CustomTooltipSection>
        </CustomTooltip>
    );

    const ImportStack = 'ImportStack';
    const ExportStack = 'ExportStack';

    return (
        <div>
            <BarChart
                barCategoryGap={'15%'}
                data={graphData.data || []}
                height={360}
                margin={{
                    top: 15,
                    right: 30,
                    left: 35,
                    bottom: 10,
                }}
                stackOffset={'sign'}
                width={props.width || 1000}
            >
                <Tooltip
                    content={tooltip}
                    cursor={{ fill: theme.palette.info.contrastText, opacity: 0.5 }}
                    offset={calculateTooltipOffset(props.width, graphData?.data?.length)}
                />
                <CartesianGrid stroke={theme.palette.action.disabled} vertical={false} />
                <XAxis
                    axisLine={false}
                    dataKey="name"
                    interval={0}
                    orientation={'top'}
                    tickLine={false}
                    tickMargin={20}
                    stroke={'white'}
                    angle={-45}
                    tick={{ fontSize: '12px' }}
                />
                <YAxis
                    allowDecimals
                    axisLine={false}
                    interval={0}
                    tick={{ fill: theme.palette.grey[200] }}
                    tickFormatter={TickFormatter}
                    tickLine={false}
                />
                <Bar
                    dataKey={'invoiceOverdueImport'}
                    fill={theme.palette.custom.data.graphB}
                    stackId={ImportStack}
                    stroke={theme.palette.custom.data.graphB}
                />
                <Bar
                    dataKey={'invoiceOverdueExport'}
                    fill={theme.palette.custom.data.graphB}
                    stackId={ExportStack}
                    stroke={theme.palette.custom.data.graphB}
                />
                <Bar
                    dataKey={'invoiceUnRealisedImport'}
                    fill={theme.palette.custom.import.main}
                    stackId={ImportStack}
                    stroke={theme.palette.custom.import.main}
                />
                <Bar
                    dataKey={'invoiceUnRealisedExport'}
                    fill={theme.palette.custom.export.main}
                    stackId={ExportStack}
                    stroke={theme.palette.custom.export.main}
                />
                <Bar
                    dataKey={'openTradeBalanceImport'}
                    fill={theme.palette.custom.data.graphC}
                    stackId={ImportStack}
                    stroke={theme.palette.custom.data.graphC}
                />
                <Bar
                    dataKey={'openTradeBalanceExport'}
                    fill={theme.palette.custom.data.graphC}
                    stackId={ExportStack}
                    stroke={theme.palette.custom.data.graphC}
                />
                <ReferenceLine stroke={theme.palette.text.disabled} y={0} />
            </BarChart>
        </div>
    );
};
