import XLSX from 'xlsx';
import moment from 'moment';
import { TradeConfirmation } from 'popcorn-js/tradeV3';
import { File } from 'components/upload';

export type rowT = {
    'External Reference': string;
    'Trade Date': string;
    'Created Date': string;
    Bank: string;
    'Bank Client': string;
    'Foreign Currency Amount': number;
    'Local Currency Amount': number;
    'Currency Pair': string;
    'Maturity Date': string;
    'Parent Trade': string;
    'Deal Status': string;
    Direction: string;
    'Financial Year': string;
    'Spot Price': number;
    Notes: string;
};

export const downloadTemplate = (): void => {
    const workbook = XLSX.utils.book_new();
    workbook.SheetNames.push('Confirmations');
    const data = [
        [
            'External Reference',
            'Trade Date',
            'Created Date',
            'Bank',
            'Bank Client',
            'Foreign Currency Amount',
            'Local Currency Amount',
            'Currency Pair',
            'Maturity Date',
            'Parent Trade',
            'Deal Status',
            'Direction',
            'Financial Year',
            'Spot Price',
            'Notes',
        ],
    ];
    workbook.Sheets['Confirmations'] = XLSX.utils.aoa_to_sheet(data);
    XLSX.writeFile(workbook, 'confirmations.xlsx');
};

const rowToConfirmation = (row: rowT): [confirmation: TradeConfirmation, error: string | null] => {
    const error: string[] = [];

    if (row['Trade Date'] && !moment(row['Trade Date'] as string, 'M/D/YY', true).isValid()) {
        if (error.length == 0) {
            error.push(`\nExternal Reference '${row['External Reference']}':\nUnreadable date format for Trade date.`);
        } else {
            error.push('Unreadable date format for Trade date.');
        }
    }
    if (row['Created Date'] && !moment(row['Created Date'] as string, 'M/D/YY', true).isValid()) {
        if (error.length == 0) {
            error.push(
                `\nExternal Reference '${row['External Reference']}':\nUnreadable date format for Created Date.`,
            );
        } else {
            error.push('Unreadable date format for Created Date.');
        }
    }
    if (row['Maturity Date'] && !moment(row['Maturity Date'] as string, 'M/D/YY', true).isValid()) {
        if (error.length == 0) {
            error.push(
                `\nExternal Reference '${row['External Reference']}':\nUnreadable date format for Maturity Date.`,
            );
        } else {
            error.push('Unreadable date format for Maturity Date.');
        }
    }

    if (row['Foreign Currency Amount'] && isNaN(row['Foreign Currency Amount'])) {
        if (error.length == 0) {
            error.push(
                `\nExternal Reference '${row['External Reference']}':\nUnreadable number format for Foreign Currency Amount.`,
            );
        } else {
            error.push('Unreadable number format for Foreign Currency Amount.');
        }
    }
    if (row['Local Currency Amount'] && isNaN(row['Local Currency Amount'])) {
        if (error.length == 0) {
            error.push(
                `\nExternal Reference '${row['External Reference']}':\nUnreadable number format for Local Currency Amount.`,
            );
        } else {
            error.push('Unreadable number format for Local Currency Amount.');
        }
    }
    if (row['Spot Price'] && isNaN(row['Spot Price'])) {
        if (error.length == 0) {
            error.push(
                `\nExternal Reference '${row['External Reference']}':\nUnreadable number format for Spot price.`,
            );
        } else {
            error.push('Unreadable number format for Spot price.');
        }
    }

    if (error.length > 0) {
        return [[] as TradeConfirmation, error.join('\n')];
    } else {
        const confirmation: TradeConfirmation = {
            ExternalReference: row['External Reference'],
            TradeDate: moment(row['Trade Date']).local().toISOString(),
            CreatedDate: moment(row['Created Date']).local().toISOString(),
            Bank: row['Bank'],
            BankClient: row['Bank Client'],
            fxAmount: row['Foreign Currency Amount'],
            lcyAmount: row['Local Currency Amount'],
            CurrencyPair: row['Currency Pair'],
            MaturityDate: moment(row['Maturity Date']).local().toISOString(),
            ParentTrade: row['Parent Trade'],
            DealStatus: row['Deal Status'],
            Direction: row['Direction'],
            FinancialYear: row['Financial Year'],
            SpotPrice: row['Spot Price'],
            Notes: row['Notes'],
        } as TradeConfirmation;
        return [confirmation, null];
    }
};

export const translate = (file: File): [confirmation: TradeConfirmation[], error: string | null] => {
    switch (file.ext) {
        case 'xlsx':
        case 'xls':
            try {
                const workbook = XLSX.read(file.data, { type: 'binary' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const rows = XLSX.utils.sheet_to_json<rowT>(worksheet, { raw: false });
                let confirmationError: string[] = [];
                let error: string | null = null;
                if (rows.length === 0) {
                    const m = 'no records found';
                    console.error(`${m}`);
                    confirmationError = [m];
                }
                const confirmations: TradeConfirmation[] = [];
                rows.forEach((row: rowT) => {
                    const [confirmation, error] = rowToConfirmation(row);
                    if (confirmation) {
                        confirmations.push(confirmation);
                    }
                    if (error) {
                        confirmationError.push(error);
                    }
                });
                if (confirmationError.length > 0) {
                    error = confirmationError.join('\n');
                }
                return [confirmations, error];
            } catch (e) {
                const m = 'failed to extract data';
                console.error(`${m}:`, e);
                throw new Error(e);
            }
        default:
            throw new Error(`files with extension '${file.ext}' are not supported`);
    }
};
