import React, { ErrorInfo, ReactNode } from 'react';
import { AppContext } from 'context';

type Props = {
    type: string;
    id: string;
    errorComponent: React.ReactElement;
    onCatch?: (error: Error, throwUp: (error: Error, errorInfo: ErrorInfo) => void) => void;
    children?: ReactNode;
};
type State = { hasError: boolean };

export class ErrorBoundary extends React.Component<Props, State> {
    static contextType = AppContext;

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    componentDidCatch(error: Error): void {
        this.props.onCatch &&
            this.props.onCatch(error, (error) => {
                throw error;
            });
    }

    render(): React.ReactNode {
        return this.state.hasError ? this.props.errorComponent : this.props.children;
    }
}
