export interface Query {
    sortBy?: string[];
    order?: QueryOrderT[];
    limit?: number;
    offset?: number;
}

export type QueryOrderT = 'asc' | 'desc';

export enum CriteriaType {
    BlankCriterion = 'BLANK_CRITERION',
    TextCriterion = 'TEXT_CRITERION',
    ExactCriterion = 'EXACT_CRITERION',
    NumberCriterion = 'NUMBER_CRITERION',
    BoolCriterion = 'BOOL_CRITERION',
    TextNeCriterion = 'TEXT_NE_CRITERION',
    DateCriterion = 'DATE_CRITERION',
    TimeCriterion = 'TIME_CRITERION',
    InvoiceOverpaidCriterion = 'INVOICE_OVERPAID_CRITERION',
}

export type BlankCriterionValue = {
    field?: string;
};
export type BlankCriterion = {
    type: CriteriaType.BlankCriterion;
    value?: BlankCriterionValue;
} & BlankCriterionValue;

export type DateSearch = {
    ignore?: boolean;
    date?: number;
    inclusive?: boolean;
};

export type DateSearchString = {
    ignore?: boolean;
    date?: string;
    inclusive?: boolean;
};

export type InvoiceOverpaidCriterionValue = {
    field?: string; // Just a placeholder
    overPaid?: boolean;
};
export type InvoiceOverpaidCriterion = {
    type: CriteriaType.InvoiceOverpaidCriterion;
    value?: InvoiceOverpaidCriterionValue;
} & InvoiceOverpaidCriterionValue;

export type ExactCriterionValue = {
    field?: string;
    text?: string;
};
export type ExactCriterion = {
    type: CriteriaType.ExactCriterion;
    value?: ExactCriterionValue;
} & ExactCriterionValue;

export type TextNeCriterionValue = {
    field?: string;
    text?: string;
};
export type TextNeCriterion = {
    type: CriteriaType.TextNeCriterion;
    value?: TextNeCriterionValue;
} & TextNeCriterionValue;

export type TextCriterionValue = {
    field?: string;
    text?: string;
};
export type BoolCriterion = {
    type: CriteriaType.BoolCriterion;
} & BoolCriterionValue;

export type BoolCriterionValue = {
    field?: string;
    value?: boolean;
};
export type TextCriterion = {
    type: CriteriaType.TextCriterion;
    value?: TextCriterionValue;
} & TextCriterionValue;

export type DateCriterionValue = {
    field?: string;
    startDate?: DateSearch;
    endDate?: DateSearch;
};
export type DateCriterion = {
    type: CriteriaType.DateCriterion;
    value?: DateCriterionValue;
} & DateCriterionValue;

export type TimeCriterionValue = {
    field?: string;
    startDate?: DateSearchString;
    endDate?: DateSearchString;
};
export type TimeCriterion = {
    type: CriteriaType.TimeCriterion;
    value?: TimeCriterionValue;
} & TimeCriterionValue;

export type NumberCriterionValue = {
    field?: string;
    lessThan?: {
        ignore?: boolean;
        amount?: number;
        inclusive?: boolean;
    };
    moreThan?: {
        ignore?: boolean;
        amount?: number;
        inclusive?: boolean;
    };
};
export type NumberCriterion = {
    type: CriteriaType.NumberCriterion;
    value?: NumberCriterionValue;
} & NumberCriterionValue;

export type Criterion =
    | ExactCriterion
    | TextCriterion
    | TextNeCriterion
    | DateCriterion
    | InvoiceOverpaidCriterion
    | NumberCriterion
    | BoolCriterion
    | BlankCriterion
    | TimeCriterion;

export type Criteria = Criterion[];

export enum CriterionFilterType {
    Or = 'OR',
    And = 'AND',
}
