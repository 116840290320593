import React, { ChangeEvent, cloneElement, ReactElement, useContext, useEffect, useState, useMemo } from 'react';
import { Actions, TradeValues, Transaction } from './index';
import { makeStyles } from '@material-ui/styles';
import { CustomTheme } from 'theme/custom';
import { Card, FormControl, IconButton, MenuItem, Tooltip } from '@material-ui/core';
import { LightNumberField, LightSelect, LightTextField } from './styledComponents';
import { TradeType } from 'popcorn-js/tradeV2';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addDays, formatISO, fromUnixTime, isAfter, isBefore, isEqual, isValid, isWeekend, parseISO } from 'date-fns';
import { FormatNumber, getMidDay } from 'utils';
import { LightDatePicker } from 'components/Option/Tickets/styledComponents';
import { Client, ProcessingBank, ProcessingOrg } from 'popcorn-js/party';
import { AppContext, AppContextT } from 'context';
import { useServiceSync } from 'hooks/useService';
import {
    Recordkeeper as ProcessingOrgRecordkeeper,
    RetrieveRequest,
    RetrieveResponse,
} from 'popcorn-js/party/processingOrg/recordkeeper';
import { IdentifierType } from 'popcorn-js/search/identifier';
import { Info } from '@material-ui/icons';
import Big from 'big.js';

export const TradeDetails = ({
    uuid,
    trade,
    initTrader,
    spotPrice,
    nonTradingDays,
    optionExercise,
    tradeDispatch,
    autoFillMode,
    autoFillSuccessState,
    iconButtonVisible,
    showParentTradesSection,
    tradeType,
    transactionState,
}: {
    uuid: string;
    trade: TradeValues;
    initTrader?: string;
    spotPrice: string;
    nonTradingDays: Date[];
    optionExercise: boolean;
    tradeDispatch: Actions;
    autoFillMode: boolean;
    autoFillSuccessState: boolean;
    iconButtonVisible: boolean;
    showParentTradesSection: boolean;
    tradeType: TradeType;
    transactionState: Transaction;
}): ReactElement => {
    const classes = useStyles();
    const appContext = useContext<AppContextT>(AppContext);
    const { maturityDate, tradeDate, maturityDateMin, maturityDateMax } = trade;
    const { setTradeDate, setMaturityDate, changeTradeType, setBank } = tradeDispatch;
    const [traderValue, setTrader] = useState<string>(initTrader || '');
    const [parentParty, setParentParty] = useState<ProcessingOrg | undefined>();
    const [forwardPoints, setForwardPoints] = useState<Big>(Big(0));
    const party = (appContext.party || {}) as Client | ProcessingOrg;

    useEffect(() => {
        retrieveParentParty().finally();
    }, []);

    const [processingOrgRecordkeeperRetrieve] = useServiceSync<RetrieveRequest, RetrieveResponse>(
        ProcessingOrgRecordkeeper.retrieve,
    );
    const retrieveParentParty = async () => {
        try {
            const retrieveParentPartyResponse = await processingOrgRecordkeeperRetrieve({
                identifier: { type: IdentifierType.PARTY_CODE_IDENTIFIER, partyCode: party.parentPartyCode },
            });
            setParentParty(retrieveParentPartyResponse.processingOrg);
        } catch (e) {
            console.error('error finding parent party', e);
        }
    };

    useEffect(() => {
        if (!showParentTradesSection) {
            if (maturityDate && tradeDate && isBefore(maturityDate, tradeDate)) {
                setMaturityDate(uuid, null);
                changeTradeType(uuid, TradeType.SPOT);
            }
            if (tradeDate === null) {
                setMaturityDate(uuid, null);
                changeTradeType(uuid, TradeType.SPOT);
            }
            if (maturityDate && tradeDate && isAfter(maturityDate, addDays(tradeDate, 5))) {
                changeTradeType(uuid, TradeType.FORWARD);
            }
            if (maturityDate && tradeDate && isBefore(maturityDate, addDays(tradeDate, 3))) {
                changeTradeType(uuid, TradeType.SPOT);
            }
        }
        if (maturityDate && tradeDate && isBefore(maturityDate, tradeDate)) {
            setMaturityDate(uuid, null);
        }
    }, [maturityDate, tradeDate, showParentTradesSection]);

    useEffect(() => {
        if (showParentTradesSection) {
            changeTradeType(uuid, tradeType);
            const parentBank = transactionState.transactionParents[0].parentBank;
            setBank(uuid, parentBank);
        }
    }, [showParentTradesSection]);

    useEffect(() => {
        trade.spotPrice = spotPrice;
        setForwardPoints(spotPrice ? Big(trade.dealRate || 0).sub(Big(spotPrice)) : Big(0));
    }, [spotPrice, trade.dealRate]);

    // field access control
    const disableEditTradeType = useMemo(() => showParentTradesSection || autoFillMode, [
        showParentTradesSection,
        autoFillMode,
    ]);

    return (
        <Card className={classes.details} elevation={0}>
            <div className={classes.detailsPanelLight}>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Trade Type'}
                            disabled={disableEditTradeType}
                            onChange={(
                                event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) => changeTradeType(uuid, event.target.value as string)}
                            style={{ width: '100%' }}
                            value={trade.tradeType}
                        >
                            {!showParentTradesSection && (
                                <MenuItem key={TradeType.SPOT} value={TradeType.SPOT}>
                                    {'Spot'}
                                </MenuItem>
                            )}
                            {!showParentTradesSection && (
                                <MenuItem key={TradeType.FORWARD} value={TradeType.FORWARD}>
                                    {'Forward'}
                                </MenuItem>
                            )}
                            {showParentTradesSection && (
                                <MenuItem key={TradeType.CANCELLATION} value={TradeType.CANCELLATION}>
                                    {'Cancellation'}
                                </MenuItem>
                            )}
                            {showParentTradesSection && (
                                <MenuItem key={TradeType.DRAWDOWN} value={TradeType.DRAWDOWN}>
                                    {'Drawdown'}
                                </MenuItem>
                            )}
                            {showParentTradesSection && (
                                <MenuItem key={TradeType.EXTENSION} value={TradeType.EXTENSION}>
                                    {'Extension'}
                                </MenuItem>
                            )}
                        </LightSelect>
                    </FormControl>
                    <LightDatePicker
                        format={'yyyy-MM-dd'}
                        label={'Trade Date'}
                        disabled={autoFillMode || autoFillSuccessState}
                        error={!!trade.fieldErrors.tradeDate}
                        onChange={(day: MaterialUiPickersDate, value: string | null | undefined) => {
                            if (value && isValid(parseISO(value))) {
                                const date = getMidDay(day as Date);
                                if (
                                    isWeekend(date) ||
                                    isHoliday(date, nonTradingDays) ||
                                    (new Date() && isAfter(date, getMidDay(new Date())))
                                ) {
                                    setTradeDate(uuid, null);
                                    setMaturityDate(uuid, new Date());
                                    return;
                                }
                                const newTradeDate = getMidDay(date as Date);
                                setTradeDate(uuid, newTradeDate);
                                if (maturityDate) {
                                    const currentMaturityDate = getMidDay(maturityDate);
                                    if (isBefore(currentMaturityDate, newTradeDate)) {
                                        setTradeDate(uuid, newTradeDate);
                                    }
                                }
                            } else {
                                setTradeDate(uuid, null);
                            }
                        }}
                        renderDay={(
                            day: MaterialUiPickersDate,
                            selectedDate: MaterialUiPickersDate,
                            isInCurrentMonth: boolean,
                            dayComponent: ReactElement,
                        ) => {
                            const date = getMidDay(day as Date);
                            if (
                                isWeekend(date) ||
                                isHoliday(date, nonTradingDays) ||
                                (new Date() && isAfter(date, getMidDay(new Date())))
                            ) {
                                return cloneElement(dayComponent, { disabled: true });
                            }
                            return dayComponent;
                        }}
                        value={tradeDate}
                        inputValue={tradeDate ? formatISO(tradeDate, { representation: 'date' }) : undefined}
                    />
                    <LightDatePicker
                        format={'yyyy-MM-dd'}
                        label={'Maturity Date'}
                        disabled={autoFillMode || autoFillSuccessState}
                        error={!!trade.fieldErrors.maturityDate}
                        onChange={(day: MaterialUiPickersDate, value: string | null | undefined) => {
                            if (value && isValid(parseISO(value))) {
                                const date = getMidDay(day as Date);
                                if (isWeekend(date) || isHoliday(date, nonTradingDays)) {
                                    tradeDispatch.setMaturityDate(uuid, null);
                                    return;
                                }
                                const newTradeDate = getMidDay(date as Date);
                                setMaturityDate(uuid, newTradeDate);
                                if (maturityDate) {
                                    const currentMaturityDate = getMidDay(maturityDate);
                                    if (isBefore(currentMaturityDate, newTradeDate)) {
                                        setMaturityDate(uuid, newTradeDate);
                                    }
                                }
                            } else {
                                setMaturityDate(uuid, null);
                            }
                        }}
                        renderDay={(
                            day: MaterialUiPickersDate,
                            selectedDate: MaterialUiPickersDate,
                            isInCurrentMonth: boolean,
                            dayComponent: ReactElement,
                        ) => {
                            const date = getMidDay(day as Date);

                            if (
                                isWeekend(date) ||
                                isHoliday(date, nonTradingDays) ||
                                (maturityDateMax && isAfter(date, maturityDateMax)) ||
                                (maturityDateMin && isBefore(date, maturityDateMin)) ||
                                (new Date() && isBefore(date, new Date())) ||
                                // disable spot date
                                isEqual(date, getMidDay(fromUnixTime(trade.spotDate || 0)))
                            ) {
                                return cloneElement(dayComponent, { disabled: true });
                            }
                            return dayComponent;
                        }}
                        value={maturityDate}
                        inputValue={maturityDate ? formatISO(maturityDate, { representation: 'date' }) : undefined}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Bank'}
                            disabled={optionExercise || autoFillMode || autoFillSuccessState}
                            error={!!trade.fieldErrors.bank}
                            style={{ width: '100%' }}
                            onChange={(
                                event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) => tradeDispatch.setBank(uuid, event.target.value as string)}
                            value={trade.bank}
                            startAdornment={
                                iconButtonVisible ? (
                                    <Tooltip title={'Transaction fees not loaded for bank!'}>
                                        <IconButton tabIndex={-1} size={'small'}>
                                            <Info htmlColor={'red'} />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    ''
                                )
                            }
                        >
                            {appContext.processingBanks?.map((bank: ProcessingBank) => (
                                <MenuItem key={bank.partyCode} value={bank.partyCode}>
                                    {bank.name}
                                </MenuItem>
                            ))}
                        </LightSelect>
                    </FormControl>
                    <FormControl style={{ width: '100%' }}>
                        <LightSelect
                            label={'Trader'}
                            disabled={autoFillMode}
                            style={{ width: '100%' }}
                            onChange={(
                                event: ChangeEvent<{
                                    name?: string | undefined;
                                    value: unknown;
                                }>,
                            ) => {
                                tradeDispatch.updateTrader(event.target.value as string);
                                setTrader(event.target.value as string);
                            }}
                            fullWidth
                            error={!trade.trader && !initTrader}
                            value={traderValue}
                        >
                            {parentParty?.traders?.map((trader, i) => (
                                <MenuItem key={i} value={trader}>
                                    {trader}
                                </MenuItem>
                            ))}
                        </LightSelect>
                    </FormControl>
                    <LightTextField
                        id={'traderNotes'}
                        label={'Trader Notes'}
                        disabled={autoFillMode}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setTradeNotes(uuid, event.target.value)
                        }
                        value={trade.notes}
                    />
                </div>
                <div className={classes.detailsPanelColumn}>
                    <LightNumberField
                        label={'Spot Rate'}
                        disabled={autoFillMode}
                        style={{ width: '100%' }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            tradeDispatch.setSpotPrice(uuid, event.target.value)
                        }
                        value={FormatNumber(spotPrice, true, true, 6)}
                    />
                    <LightNumberField
                        error={!!trade.fieldErrors.forwardPoints}
                        label={'Forward Points'}
                        style={{ width: '100%' }}
                        disabled
                        value={FormatNumber(forwardPoints.toFixed(6), true, true, 6)}
                    />
                    <LightNumberField
                        label={'Deal Rate'}
                        disabled
                        style={{ width: '100%' }}
                        value={FormatNumber(trade.dealRate, true, true, 6)}
                    />
                </div>
            </div>
        </Card>
    );
};

const isHoliday = (d: Date, nonTradingDays: Date[]): boolean => {
    for (const ntd of nonTradingDays) {
        if (ntd.getDate() === d.getDate() && ntd.getMonth() === d.getMonth() && ntd.getFullYear() === d.getFullYear()) {
            return true;
        }
    }
    return false;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    details: {
        display: 'flex',
        flexDirection: 'row',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    detailsPanelLight: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: theme.spacing(4),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.custom.paperExtended.paper5,
    },
    detailsPanelColumn: {
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
    },
}));
