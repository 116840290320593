import { Criteria } from 'popcorn-js/search';
import { marshalCriteria } from 'popcorn-js/search/marshaller';
import { jsonRPC } from 'utils/jsonrpc';
import config from 'react-global-configuration';
import { FindRequest } from 'popcorn-js';

export type DownloadListRequest = FindRequest;
export type DownloadListResponse = {
    data: string;
};
export const Downloader = {
    ServiceProviderName: 'Operations-Downloader',
    DownloadList(request: DownloadListRequest): Promise<DownloadListResponse> {
        const serializedCriteria = request.criteria ? marshalCriteria(request.criteria as Criteria) : undefined;
        return jsonRPC<DownloadListRequest, DownloadListResponse>({
            url: config.get('apiURL'),
            method: `${Downloader.ServiceProviderName}.DownloadList`,
            request: {
                ...request,
                criteria: serializedCriteria,
            },
        });
    },
};

export default Downloader;
