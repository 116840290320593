import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { CustomTheme } from 'theme/custom';
import { COLOR, HeaderField } from 'components/HeaderField/HeaderField';
import { AppContext, AppContextT } from 'context';
import Generator, { GenerateTradeCashFlowReportRequest, GenerateTradeCashFlowReportResponse } from 'popcorn-js/report';
import { useServiceSync } from 'hooks/useService';
import { ScaleLoader as Spinner } from 'react-spinners';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import { displayAmount } from './util';

const TradeCashFlowCard = (): ReactElement => {
    const classes = useStyles();

    const theme = useTheme<CustomTheme>();

    const appContext = useContext<AppContextT>(AppContext);
    const ccy = appContext.localCurrency?.isoCode;

    const [response, setResponse] = useState<GenerateTradeCashFlowReportResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();

    const [generate] = useServiceSync<GenerateTradeCashFlowReportRequest, GenerateTradeCashFlowReportResponse>(
        Generator.GenerateTradeCashFlowReport,
    );

    useEffect(() => {
        generate({})
            .then(setResponse)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className={classes.tradeCashFlowCardWrapper}>
                <div className={classes.loadingLayout}>
                    <Spinner color={theme.palette.primary.main} loading={loading} />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.tradeCashFlowCardWrapper}>
                <div className={classes.loadingLayout}>
                    <ErrorIcon className={classes.errorIcon} />
                    <div className={classes.errorText}>Failed to load report</div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.tradeCashFlowCardWrapper}>
            <div className={classes.tradeCashFlowCardLayout}>
                <div className={classes.tradeCashFlowSectionHeading}>TRADE CASH FLOWS FOR THE REST OF THE MONTH</div>
                <HeaderField
                    heading={'Net Trade Cash Flows (Committed)'}
                    value={displayAmount(ccy, response?.committed || 0, 'code')}
                    color={COLOR.YELLOW}
                />
                <HeaderField
                    heading={'FECs Maturing'}
                    value={displayAmount(ccy, response?.maturing || 0, 'code')}
                    color={COLOR.GREY}
                />
                <HeaderField
                    heading={'Net Trade Cash Flows if FECs extended'}
                    value={displayAmount(ccy, response?.extended || 0, 'code')}
                    color={COLOR.YELLOW}
                />
            </div>
        </div> // CARD 1 END
    );
};

const useStyles = makeStyles((theme: CustomTheme) => ({
    // Net Position Section
    tradeCashFlowCardWrapper: {
        width: '200px',
        height: '100%',
        backgroundColor: theme.palette.custom.paperExtended.paper2,
        display: 'flex',
        flexDirection: 'column',
    },
    tradeCashFlowCardLayout: {
        flexGrow: 1,
        display: 'flex',
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        padding: theme.spacing(1),
        marginTop: '8px',
    },
    tradeCashFlowSectionHeading: {
        color: theme.palette.text.primary,
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: 'bold',
        marginLeft: '8px',
    },
    loadingLayout: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
    },
    errorIcon: {
        fontSize: 80,
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.text.primary,
    },
}));

export default TradeCashFlowCard;
